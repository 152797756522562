import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox, CheckboxGroup } from "@chakra-ui/react";

import StudentPortalSidebar from "../../reusable/StudentPortalSidebar";
import StudentPortalTopbar from "../../reusable/StudentPortalTopbar";
import Loader from "../../reusable/Loader";
import useScreens from "../../hooks/useScreens";

import { useGetToken } from "../../hooks/useGetToken";

const Offers = () => {
  // store state handlers
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getOffersData, loading } = useSelector(
    (state) => state.common.getOffers
  );

  const [isChecked, setIsChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    setTimeout(() => {
      isChecked && navigate("/chat");
    }, 500);
  }, [isChecked, navigate]);

  const { isMobile } = useScreens();

  const { studentToken } = useGetToken();

  const [isOpen, setIsOpen] = useState(false);

  const [isPageLoading, setIsPageLoading] = useState(true);

  //open close modals
  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!studentToken && typeof studentToken !== "undefined") {
      navigate("/student-auth");
    }
    if (
      studentToken &&
      studentToken?.length > 0 &&
      typeof studentToken !== "undefined"
    ) {
      setIsPageLoading(false);
    }
    if (studentToken) {
      console.log(studentToken, "data");
      // dispatch(getAllApplication({ token: studentToken }));
    }
  }, [studentToken]);

  return (
    <div className="w-full bg-[#F1F4FA]">
      {isPageLoading ? (
        <Loader />
      ) : (
        <>
          {!isMobile && <StudentPortalTopbar />}
          {isMobile && (
            <StudentPortalSidebar isOpen={isOpen} onClose={onClose} />
          )}
          <div className="w-full tabanddesk:px-10 mobile:px-5 desktop:bg-[#F1F4FA] min-h-screen">
            {console.log(getOffersData,'getOffersData')}
            {getOffersData.map((bank, index) => (
              <div
                key={index}
                className="rounded-md mt-10 bg-white flex items-center justify-between"
                style={{
                  border: "1px solid #F0EFEF",
                  padding: "16px 24px",
                  boxShadow: "0px 4px 4px rgba(226, 226, 226, 0.25)",
                }}
              >
                <div className="flex items-center">
                  <img src="/univ_logo.svg" alt="University Logo" />
                  <span className="pl-[12px] font-gola font-semibold text-base">
                    {bank.bank_branch}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="font-gola text-[20px] font-semibold ml-[40px]">
                    ₹ {bank.max_loan_offer}
                  </span>
                </div>
              </div>
            ))}
            <div className="font-gola  font-semibold mt-[40px]">
              <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>
                Chat With Available Bankers
              </Checkbox>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Offers;
