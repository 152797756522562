export const universityData = {
    "united states of america": [
        {
            "abilene_christian_university": "Abilene Christian University"
        },
        {
            "academy_of_art_university": "Academy of Art University"
        },
        {
            "adelphi_university": "Adelphi University"
        },
        {
            "alabama_state_university": "Alabama State University"
        },
        {
            "alaska_pacific_university": "Alaska Pacific University"
        },
        {
            "albany_college_of_pharmacy_and_health_sciences": "Albany College of Pharmacy and Health Sciences"
        },
        {
            "alcorn_state_university": "Alcorn State University"
        },
        {
            "alfred_university": "Alfred University"
        },
        {
            "alliant_international_university": "Alliant International University"
        },
        {
            "alvernia_university": "Alvernia University"
        },
        {
            "alverno_college": "Alverno College"
        },
        {
            "american_international_college": "American International College"
        },
        {
            "american_jewish_university": "American Jewish University"
        },
        {
            "anderson_university_(south_carolina)": "Anderson University (South Carolina)"
        },
        {
            "american_university_washington_dc": "American University Washington DC"
        },
        {
            "anderson_university_-_indiana": "Anderson University - Indiana"
        },
        {
            "andrews_university": "Andrews University"
        },
        {
            "appalachian_state_university": "Appalachian State University"
        },
        {
            "antioch_university": "Antioch University"
        },
        {
            "antioch_university": "Antioch University"
        },
        {
            "arizona_state_university": "Arizona State University"
        },
        {
            "arcadia_university": "Arcadia University"
        },
        {
            "arizona_state_university": "Arizona State University"
        },
        {
            "arizona_state_university": "Arizona State University"
        },
        {
            "arizona_state_university": "Arizona State University"
        },
        {
            "arizona_state_university": "Arizona State University"
        },
        {
            "arkansas_state_university": "Arkansas State University"
        },
        {
            "arizona_state_university": "Arizona State University"
        },
        {
            "art_center_college_of_design": "Art Center College of Design"
        },
        {
            "arkansas_tech_university": "Arkansas Tech University"
        },
        {
            "asbury_theological_seminary": "Asbury Theological Seminary"
        },
        {
            "ashland_university": "Ashland University"
        },
        {
            "assumption_college": "Assumption College"
        },
        {
            "atlantis_university": "Atlantis University"
        },
        {
            "auburn_university_at_montgomery": "Auburn University at Montgomery"
        },
        {
            "auburn_university": "Auburn University"
        },
        {
            "augusta_university": "Augusta University"
        },
        {
            "augsburg_university": "Augsburg University"
        },
        {
            "avila_university": "Avila University"
        },
        {
            "azusa_pacific_university": "Azusa Pacific University"
        },
        {
            "babson_college": "Babson College"
        },
        {
            "babson_college": "Babson College"
        },
        {
            "baker_university": "Baker University"
        },
        {
            "bakke_graduate_university": "Bakke Graduate University"
        },
        {
            "baldwin_wallace_university": "Baldwin Wallace University"
        },
        {
            "bard_college": "Bard College"
        },
        {
            "ball_state_university": "Ball State University"
        },
        {
            "barry_university": "Barry University"
        },
        {
            "bay_atlantic_university": "Bay Atlantic University"
        },
        {
            "baylor_university": "Baylor University"
        },
        {
            "bellarmine_university": "Bellarmine University"
        },
        {
            "bemidji_state_university": "Bemidji State University"
        },
        {
            "bellevue_university": "Bellevue University"
        },
        {
            "belmont_university": "Belmont University"
        },
        {
            "benedictine_university": "Benedictine University"
        },
        {
            "bentley_university": "Bentley University"
        },
        {
            "berkeley_college": "Berkeley College"
        },
        {
            "binghamton_university": "Binghamton University"
        },
        {
            "biola_university": "Biola University"
        },
        {
            "boise_state_university": "Boise State University"
        },
        {
            "bloomsburg_university_of_pennsylvania": "Bloomsburg University of Pennsylvania"
        },
        {
            "boston_university": "Boston University"
        },
        {
            "bradley_university": "Bradley University"
        },
        {
            "boston_college": "Boston College"
        },
        {
            "bowling_green_state_university": "Bowling Green State University"
        },
        {
            "brandeis_university": "Brandeis University"
        },
        {
            "bridgewater_state_university": "Bridgewater State University"
        },
        {
            "brigham_young_university": "Brigham Young University"
        },
        {
            "brown_university": "Brown University"
        },
        {
            "brooklyn_law_school": "Brooklyn Law School"
        },
        {
            "bryant_university": "Bryant University"
        },
        {
            "bucknell_university": "Bucknell University"
        },
        {
            "bryn_mawr_college": "Bryn Mawr College"
        },
        {
            "suny_buffalo": "SUNY Buffalo"
        },
        {
            "butler_university": "Butler University"
        },
        {
            "california_college_of_the_arts": "California College of the Arts"
        },
        {
            "california_baptist_university": "California Baptist University"
        },
        {
            "california_institute_of_integral_studies": "California Institute of Integral Studies"
        },
        {
            "california_institute_of_technology_-_caltech": "California Institute of Technology - Caltech"
        },
        {
            "california_lutheran_university": "California Lutheran University"
        },
        {
            "california_state_university_channel_islands": "California State University Channel Islands"
        },
        {
            "california_miramar_university": "California Miramar University"
        },
        {
            "california_state_university_chico": "California State University Chico"
        },
        {
            "california_state_university_east_bay": "California State University East Bay"
        },
        {
            "california_state_university_fullerton": "California State University Fullerton"
        },
        {
            "california_state_university_long_beach": "California State University Long Beach"
        },
        {
            "california_state_university_northridge": "California State University Northridge"
        },
        {
            "california_state_university_monterey_bay": "California State University Monterey Bay"
        },
        {
            "california_state_university_los_angeles": "California State University Los Angeles"
        },
        {
            "california_state_university_sacramento": "California State University Sacramento"
        },
        {
            "california_state_university_san_bernardino": "California State University San Bernardino"
        },
        {
            "california_state_university_san_marcos": "California State University San Marcos"
        },
        {
            "california_state_university_stanislaus": "California State University Stanislaus"
        },
        {
            "california_state_university,_fresno": "California State University, Fresno"
        },
        {
            "california_state_university,_bakersfield": "California State University, Bakersfield"
        },
        {
            "california_state_university,_dominguez_hills": "California State University, Dominguez Hills"
        },
        {
            "california_university_of_management_and_sciences": "California University of Management and Sciences"
        },
        {
            "california_university_of_pennsylvania": "California University of Pennsylvania"
        },
        {
            "cambridge_college": "Cambridge College"
        },
        {
            "canisius_college": "Canisius College"
        },
        {
            "cameron_university": "Cameron University"
        },
        {
            "carnegie_mellon_university": "Carnegie Mellon University"
        },
        {
            "capital_university": "Capital University"
        },
        {
            "carroll_university": "Carroll University"
        },
        {
            "catholic_university_of_america": "Catholic University of America"
        },
        {
            "carson-newman_university": "Carson-Newman University"
        },
        {
            "case_western_reserve_university": "Case Western Reserve University"
        },
        {
            "cedar_crest_college": "Cedar Crest College"
        },
        {
            "central_connecticut_state_university": "Central Connecticut State University"
        },
        {
            "central_michigan_university": "Central Michigan University"
        },
        {
            "chapman_university": "Chapman University"
        },
        {
            "central_washington_university": "Central Washington University"
        },
        {
            "charles_drew_university_of_medicine_&_science": "Charles Drew University of Medicine & Science"
        },
        {
            "chicago_state_university": "Chicago State University"
        },
        {
            "chatham_university": "Chatham University"
        },
        {
            "christian_brothers_university": "Christian Brothers University"
        },
        {
            "christopher_newport_university": "Christopher Newport University"
        },
        {
            "claflin_university": "Claflin University"
        },
        {
            "clarion_university_of_pennsylvania": "Clarion University of Pennsylvania"
        },
        {
            "claremont_graduate_university_(cgu)": "Claremont Graduate University (CGU)"
        },
        {
            "clark_atlanta_university": "Clark Atlanta University"
        },
        {
            "clark_university": "Clark University"
        },
        {
            "clayton_state_university": "Clayton State University"
        },
        {
            "clarkson_university": "Clarkson University"
        },
        {
            "clemson_university": "Clemson University"
        },
        {
            "college_of_saint_elizabeth": "College of Saint Elizabeth"
        },
        {
            "cleveland_state_university_(csu)": "Cleveland State University (CSU)"
        },
        {
            "coastal_carolina_university": "Coastal Carolina University"
        },
        {
            "college_of_charleston": "College of Charleston"
        },
        {
            "college_of_saint_rose": "College of Saint Rose"
        },
        {
            "college_of_william_&_mary": "College of William & Mary"
        },
        {
            "colorado_school_of_mines": "Colorado School of Mines"
        },
        {
            "colorado_mesa_university": "Colorado Mesa University"
        },
        {
            "columbus_state_university": "Columbus State University"
        },
        {
            "colorado_state_university": "Colorado State University"
        },
        {
            "columbia_university": "Columbia University"
        },
        {
            "concordia_university_of_chicago": "Concordia University of Chicago"
        },
        {
            "concordia_university_wisconsin": "Concordia University Wisconsin"
        },
        {
            "coppin_state_university": "Coppin State University"
        },
        {
            "cornell_university": "Cornell University"
        },
        {
            "concordia_university,_st._paul": "Concordia University, St. Paul"
        },
        {
            "creighton_university": "Creighton University"
        },
        {
            "d\u00e2\u20ac\u2122youville_college": "D\u00e2\u20ac\u2122Youville College"
        },
        {
            "cumberland_university": "Cumberland University"
        },
        {
            "dakota_state_university": "Dakota State University"
        },
        {
            "dallas_baptist_university": "Dallas Baptist University"
        },
        {
            "dartmouth_college": "Dartmouth College"
        },
        {
            "depaul_university": "DePaul University"
        },
        {
            "delaware_state_university": "Delaware State University"
        },
        {
            "delaware_state_university": "Delaware State University"
        },
        {
            "davenport_university": "Davenport University"
        },
        {
            "delta_state_university": "Delta State University"
        },
        {
            "digipen_institute_of_technology": "DigiPen Institute of Technology"
        },
        {
            "dominican_college": "Dominican College"
        },
        {
            "dominican_university_of_california": "Dominican University of California"
        },
        {
            "drake_university": "Drake University"
        },
        {
            "drew_university": "Drew University"
        },
        {
            "drew_university": "Drew University"
        },
        {
            "east_stroudsburg_university": "East Stroudsburg University"
        },
        {
            "duquesne_university": "Duquesne University"
        },
        {
            "drury_university": "Drury University"
        },
        {
            "drexel_university": "Drexel University"
        },
        {
            "duke_university": "Duke University"
        },
        {
            "east_tennessee_state_university": "East Tennessee State University"
        },
        {
            "eastern_connecticut_state_university": "Eastern Connecticut State University"
        },
        {
            "eastern_illinois_university": "Eastern Illinois University"
        },
        {
            "eastern_kentucky_university": "Eastern Kentucky University"
        },
        {
            "eastern_mennonite_university": "Eastern Mennonite University"
        },
        {
            "ecpi_university": "ECPI University"
        },
        {
            "eastern_michigan_universityecpi_university": "Eastern Michigan UniversityECPI University"
        },
        {
            "eastern_washington_university": "Eastern Washington University"
        },
        {
            "eastern_oregon_university": "Eastern Oregon University"
        },
        {
            "ecpi_university": "ECPI University"
        },
        {
            "elmhurst_college": "Elmhurst College"
        },
        {
            "elmira_college": "Elmira College"
        },
        {
            "elon_university": "Elon University"
        },
        {
            "embry-riddle_aeronautical_university": "Embry-Riddle Aeronautical University"
        },
        {
            "elms_college": "Elms College"
        },
        {
            "emporia_state_university": "Emporia State University"
        },
        {
            "emory_university": "Emory University"
        },
        {
            "endicott_college": "Endicott College"
        },
        {
            "evergreen_state_college": "Evergreen State College"
        },
        {
            "fairfax_university_of_america": "Fairfax University of America"
        },
        {
            "fashion_institute_of_technology_suny": "Fashion Institute of Technology SUNY"
        },
        {
            "fayetteville_state_university": "Fayetteville State University"
        },
        {
            "fairfield_university": "Fairfield University"
        },
        {
            "fairleigh_dickinson_university": "Fairleigh Dickinson University"
        },
        {
            "ferris_state_university": "Ferris State University"
        },
        {
            "fisher_college": "Fisher College"
        },
        {
            "fielding_graduate_university": "Fielding Graduate University"
        },
        {
            "fisk_university": "Fisk University"
        },
        {
            "florida_atlantic_university": "Florida Atlantic University"
        },
        {
            "fitchburg_state_university": "Fitchburg State University"
        },
        {
            "florida_gulf_coast_university": "Florida Gulf Coast University"
        },
        {
            "florida_agricultural_and_mechanical_university": "Florida Agricultural and Mechanical University"
        },
        {
            "florida_institute_of_technology": "Florida Institute of Technology"
        },
        {
            "florida_international_university": "Florida International University"
        },
        {
            "florida_national_university-main_campus": "Florida National University-Main Campus"
        },
        {
            "florida_state_university": "Florida State University"
        },
        {
            "florida_polytechnic_university": "Florida Polytechnic University"
        },
        {
            "fordham_university": "Fordham University"
        },
        {
            "florida_state_university": "Florida State University"
        },
        {
            "fort_hays_state_university": "Fort Hays State University"
        },
        {
            "fort_lewis_college": "Fort Lewis College"
        },
        {
            "framingham_state_university": "Framingham State University"
        },
        {
            "franciscan_university_of_steubenville": "Franciscan University of Steubenville"
        },
        {
            "franklin_university": "Franklin University"
        },
        {
            "full_sail_university": "Full Sail University"
        },
        {
            "fresno_pacific_university": "Fresno Pacific University"
        },
        {
            "frostburg_state_university": "Frostburg State University"
        },
        {
            "furman_university": "Furman University"
        },
        {
            "gallaudet_university": "Gallaudet University"
        },
        {
            "gannon_university": "Gannon University"
        },
        {
            "george_mason_university": "George Mason University"
        },
        {
            "george_washington_university": "George Washington University"
        },
        {
            "georgetown_university": "Georgetown University"
        },
        {
            "george_southern_university": "George southern university"
        },
        {
            "georgia_institute_of_technology": "Georgia Institute of Technology"
        },
        {
            "golden_gate_university": "Golden Gate University"
        },
        {
            "georgia_state_university": "Georgia State University"
        },
        {
            "goddard_college": "Goddard College"
        },
        {
            "golden_gate_university": "Golden Gate University"
        },
        {
            "goldey-beacom_college": "Goldey-Beacom College"
        },
        {
            "goucher_college": "Goucher College"
        },
        {
            "gonzaga_university": "Gonzaga University"
        },
        {
            "grand_valley_state_university": "Grand Valley State University"
        },
        {
            "grand_valley_state_university": "Grand Valley State University"
        },
        {
            "greenville_university": "Greenville University"
        },
        {
            "guilford_college": "Guilford College"
        },
        {
            "hamline_university": "Hamline University"
        },
        {
            "harrisburg_university_of_science_and_technology": "Harrisburg University of Science and Technology"
        },
        {
            "hampton_university": "Hampton University"
        },
        {
            "harvard_university": "Harvard University"
        },
        {
            "harvard_kennedy_school": "Harvard Kennedy School"
        },
        {
            "heidelberg_university": "Heidelberg University"
        },
        {
            "hawai'i_pacific_university": "Hawai'i Pacific University"
        },
        {
            "hendrix_college": "Hendrix College"
        },
        {
            "hillsdale_college": "Hillsdale College"
        },
        {
            "hofstra_university": "Hofstra University"
        },
        {
            "holy_names_university": "Holy Names University"
        },
        {
            "hood_college": "Hood College"
        },
        {
            "howard_university": "Howard University"
        },
        {
            "houston_baptist_university": "Houston Baptist University"
        },
        {
            "hult_international_business_school": "Hult International Business School"
        },
        {
            "hult_international_business_school": "Hult International Business School"
        },
        {
            "humboldt_state_university": "Humboldt State University"
        },
        {
            "hult_international_business_school": "Hult International Business School"
        },
        {
            "hussian_college_los_angeles": "Hussian College Los Angeles"
        },
        {
            "husson_university": "Husson University"
        },
        {
            "icahn_school_of_medicine_at_mount_sinai": "Icahn School of Medicine at Mount Sinai"
        },
        {
            "idaho_state_university": "Idaho State University"
        },
        {
            "iglobal_university": "IGlobal University"
        },
        {
            "indiana_institute_of_technology": "Indiana Institute of Technology"
        },
        {
            "indiana_university_-_purdue_university_indianapolis": "Indiana University - Purdue University Indianapolis"
        },
        {
            "illinois_institute_of_technology": "Illinois Institute of Technology"
        },
        {
            "illinois_state_university": "Illinois State University"
        },
        {
            "indiana_university_bloomington": "Indiana University Bloomington"
        },
        {
            "indiana_university_kokomo": "Indiana University Kokomo"
        },
        {
            "indiana_university_northwest": "Indiana University Northwest"
        },
        {
            "indiana_university_south_bend": "Indiana University South Bend"
        },
        {
            "indiana_university_of_pennsylvania": "Indiana University of Pennsylvania"
        },
        {
            "international_technological_university": "International Technological University"
        },
        {
            "indiana_university_southeast": "Indiana University Southeast"
        },
        {
            "ithaca_college": "Ithaca College"
        },
        {
            "iona_college": "Iona College"
        },
        {
            "iowa_state_university": "Iowa State University"
        },
        {
            "jackson_state_university": "Jackson State University"
        },
        {
            "james_madison_university": "James Madison University"
        },
        {
            "jacksonville_state_university": "Jacksonville State University"
        },
        {
            "jacksonville_university": "Jacksonville University"
        },
        {
            "john_brown_university": "John Brown University"
        },
        {
            "john_carroll_university": "John Carroll University"
        },
        {
            "johns_hopkins_university": "Johns Hopkins University"
        },
        {
            "johns_hopkins_university": "Johns Hopkins University"
        },
        {
            "johnson_&_wales_university": "Johnson & Wales University"
        },
        {
            "johns_hopkins_university": "Johns Hopkins University"
        },
        {
            "johnson_&_wales_university": "Johnson & Wales University"
        },
        {
            "johnson_university": "Johnson University"
        },
        {
            "kansas_state_university": "Kansas State University"
        },
        {
            "kean_university": "Kean University"
        },
        {
            "kennesaw_state_university": "Kennesaw State University"
        },
        {
            "keck_graduate_institute": "Keck Graduate Institute"
        },
        {
            "kent_state_university": "Kent State University"
        },
        {
            "kutztown_university": "Kutztown University"
        },
        {
            "kentucky_state_university": "Kentucky State University"
        },
        {
            "la_roche_university": "La Roche University"
        },
        {
            "la_salle_university": "La Salle University"
        },
        {
            "la_sierra_university": "La Sierra University"
        },
        {
            "lamar_university": "Lamar University"
        },
        {
            "lasell_college": "Lasell College"
        },
        {
            "lawrence_technological_university": "Lawrence Technological University"
        },
        {
            "le_moyne_college": "Le Moyne College"
        },
        {
            "lehigh_university": "Lehigh University"
        },
        {
            "lesley_university": "Lesley University"
        },
        {
            "lewis_and_clark_college": "Lewis and Clark College"
        },
        {
            "lewis_university": "Lewis University"
        },
        {
            "lincoln_university_pennsylvania": "Lincoln University Pennsylvania"
        },
        {
            "liberty_university": "Liberty University"
        },
        {
            "lim_college": "LIM College"
        },
        {
            "lincoln_university": "Lincoln University"
        },
        {
            "liu_post": "LIU Post"
        },
        {
            "lindenwood_university": "Lindenwood University"
        },
        {
            "lipscomb_university": "Lipscomb University"
        },
        {
            "long_island_university_(liu)": "Long Island University (LIU)"
        },
        {
            "louisiana_state_university_in_shreveport": "Louisiana State University in Shreveport"
        },
        {
            "loyola_university_new_orleans": "Loyola University New Orleans"
        },
        {
            "louisiana_state_university": "Louisiana State University"
        },
        {
            "louisiana_tech_university": "Louisiana Tech University"
        },
        {
            "loyola_marymount_university": "Loyola Marymount University"
        },
        {
            "loyola_university_chicago": "Loyola University Chicago"
        },
        {
            "lynn_university": "Lynn University"
        },
        {
            "madonna_university": "Madonna University"
        },
        {
            "manhattan_college": "Manhattan College"
        },
        {
            "manhattanville_college": "Manhattanville College"
        },
        {
            "marietta_college": "Marietta College"
        },
        {
            "marist_college": "Marist College"
        },
        {
            "marlboro_college": "Marlboro College"
        },
        {
            "marquette_university": "Marquette University"
        },
        {
            "mars_hill_university": "Mars Hill University"
        },
        {
            "marshall_university": "Marshall University"
        },
        {
            "massachusetts_college_of_art_and_design": "Massachusetts College of Art and Design"
        },
        {
            "marymount_university": "Marymount University"
        },
        {
            "marywood_university": "Marywood University"
        },
        {
            "massachusetts_college_of_pharmacy_and_health_sciences": "Massachusetts College of Pharmacy and Health Sciences"
        },
        {
            "massachusetts_college_of_liberal_arts": "Massachusetts College of Liberal Arts"
        },
        {
            "massachusetts_institute_of_technology_(mit)": "Massachusetts Institute of Technology (MIT)"
        },
        {
            "mcdaniel_college": "McDaniel College"
        },
        {
            "mcneese_state_university": "McNeese State University"
        },
        {
            "mercer_university": "Mercer University"
        },
        {
            "mercy_college_new_york": "Mercy College New York"
        },
        {
            "mercer_university": "Mercer University"
        },
        {
            "metropolitan_state_university_of_denver": "Metropolitan State University of Denver"
        },
        {
            "merrimack_college": "Merrimack College"
        },
        {
            "michigan_state_university": "Michigan State University"
        },
        {
            "metropolitan_state_university": "Metropolitan State University"
        },
        {
            "miami_university": "Miami University"
        },
        {
            "michigan_technological_university": "Michigan Technological University"
        },
        {
            "middle_tennessee_state_university": "Middle Tennessee State University"
        },
        {
            "midwestern_state_university": "Midwestern State University"
        },
        {
            "mills_college": "Mills College"
        },
        {
            "middlebury_institute_of_international_studies": "Middlebury Institute of International Studies"
        },
        {
            "milwaukee_school_of_engineering": "Milwaukee School of Engineering"
        },
        {
            "millersville_university_of_pennsylvania": "Millersville University of Pennsylvania"
        },
        {
            "minnesota_state_university_mankato": "Minnesota State University Mankato"
        },
        {
            "minnesota_state_university_moorhead": "Minnesota State University Moorhead"
        },
        {
            "christian_university": "christian university"
        },
        {
            "missouri_state_university": "Missouri State University"
        },
        {
            "mississippi_state_university": "Mississippi State University"
        },
        {
            "mississippi_university_for_women": "Mississippi University for Women"
        },
        {
            "missouri_university_of_science_and_technology": "Missouri University of Science and Technology"
        },
        {
            "monroe_college": "Monroe College"
        },
        {
            "montana_state_university": "Montana State University"
        },
        {
            "montana_state_university_billings": "Montana State University Billings"
        },
        {
            "montana_technological_university": "Montana Technological University"
        },
        {
            "montclair_state_university": "Montclair State University"
        },
        {
            "moody_bible_institute": "Moody Bible Institute"
        },
        {
            "moravian_college": "Moravian College"
        },
        {
            "morgan_state_university": "Morgan State University"
        },
        {
            "morehead_state_university": "Morehead State University"
        },
        {
            "morehouse_school_of_medicine": "Morehouse School of Medicine"
        },
        {
            "mount_holyoke_college": "Mount Holyoke College"
        },
        {
            "murray_state_university": "Murray State University"
        },
        {
            "naropa_university": "Naropa University"
        },
        {
            "new_england_college": "New England College"
        },
        {
            "national_louis_university": "National Louis University"
        },
        {
            "naval_postgraduate_school": "Naval Postgraduate School"
        },
        {
            "new_england_institute_of_technology": "New England Institute of Technology"
        },
        {
            "new_england_law_|_boston": "New England Law | Boston"
        },
        {
            "new_mexico_highlands_university": "New Mexico Highlands University"
        },
        {
            "new_jersey_institute_of_technology": "New Jersey Institute of Technology"
        },
        {
            "new_york_institute_of_technology": "New York Institute of Technology"
        },
        {
            "new_mexico_institute_of_mining_and_technology": "New Mexico Institute of Mining and Technology"
        },
        {
            "new_mexico_state_university": "New Mexico State University"
        },
        {
            "new_york_university": "New York University"
        },
        {
            "niagara_university": "niagara university"
        },
        {
            "newschool_of_architecture_and_design": "Newschool of Architecture and Design"
        },
        {
            "nichols_college": "Nichols College"
        },
        {
            "north_carolina_central_university": "North Carolina Central University"
        },
        {
            "north_carolina_agricultural_&_technical_state_university": "North Carolina Agricultural & Technical State University"
        },
        {
            "norfolk_state_university": "Norfolk State University"
        },
        {
            "north_carolina_state_university": "North Carolina State University"
        },
        {
            "north_dakota_state_university": "North Dakota State University"
        },
        {
            "northeastern_university": "Northeastern University"
        },
        {
            "northeastern_illinois_university": "Northeastern Illinois University"
        },
        {
            "northeastern_university": "Northeastern University"
        },
        {
            "northern_arizona_university": "Northern Arizona University"
        },
        {
            "northern_kentucky_university": "Northern Kentucky University"
        },
        {
            "northern_illinois_university": "Northern Illinois University"
        },
        {
            "northern_michigan_university": "Northern Michigan University"
        },
        {
            "northern_state_university": "Northern State University"
        },
        {
            "northwest_missouri_state_university": "Northwest Missouri State University"
        },
        {
            "northwestern_university": "Northwestern University"
        },
        {
            "northwest_university": "Northwest University"
        },
        {
            "ohio_university": "Ohio University"
        },
        {
            "nova_southeastern_university": "Nova Southeastern University"
        },
        {
            "nyack_college": "Nyack College"
        },
        {
            "oakland_university": "Oakland University"
        },
        {
            "ohio_university": "ohio university"
        },
        {
            "oklahoma_christian_university": "Oklahoma Christian University"
        },
        {
            "oklahoma_city_university": "Oklahoma City University"
        },
        {
            "old_dominion_university": "Old Dominion University"
        },
        {
            "oklahoma_state_university": "Oklahoma State University"
        },
        {
            "oregon_institute_of_technology_": "oregon institute of technology "
        },
        {
            "oregon_health_&_science_university": "Oregon Health & Science University"
        },
        {
            "oregon_state_university": "Oregon State University"
        },
        {
            "pacific_university_oregon": "Pacific University Oregon"
        },
        {
            "pace_university": "Pace University"
        },
        {
            "pace_university": "Pace University"
        },
        {
            "palm_beach_atlantic_university": "Palm Beach Atlantic University"
        },
        {
            "pennsylvania_state_university": "Pennsylvania State University"
        },
        {
            "pacific_university_oregon": "Pacific University Oregon"
        },
        {
            "park_university": "Park University"
        },
        {
            "pennsylvania_state_university": "Pennsylvania State University"
        },
        {
            "pennsylvania_state_university": "Pennsylvania State University"
        },
        {
            "pfeiffer_university": "pfeiffer university"
        },
        {
            "pepperdine_university": "Pepperdine University"
        },
        {
            "philadelphia_college_of_osteopathic_medicine": "Philadelphia College of Osteopathic Medicine"
        },
        {
            "philadelphia_college_of_osteopathic_medicine": "Philadelphia College of Osteopathic Medicine"
        },
        {
            "point_park_university": "Point Park University"
        },
        {
            "pittsburg_state_university": "Pittsburg State University"
        },
        {
            "portland_state_university": "portland state university"
        },
        {
            "prairie_view_a&m_university": "Prairie View A&M University"
        },
        {
            "pratt_institute": "Pratt Institute"
        },
        {
            "providence_college": "Providence College"
        },
        {
            "princeton_university": "Princeton University"
        },
        {
            "quinnipiac_university": "Quinnipiac University"
        },
        {
            "purdue_university": "Purdue University"
        },
        {
            "radford_university": "Radford University"
        },
        {
            "purdue_university_fort_wayne": "Purdue University Fort Wayne"
        },
        {
            "purdue_university_northwest": "Purdue University Northwest"
        },
        {
            "ramapo_college_of_new_jersey": "Ramapo College of New Jersey"
        },
        {
            "regent_university": "Regent University"
        },
        {
            "rensselaer_polytechnic_institute": "Rensselaer Polytechnic Institute"
        },
        {
            "regis_university": "Regis University"
        },
        {
            "rhode_island_school_of_design": "Rhode Island School of Design"
        },
        {
            "rhode_island_college": "Rhode Island College"
        },
        {
            "rice_university": "Rice University"
        },
        {
            "rhodes_college": "Rhodes College"
        },
        {
            "rider_university": "Rider University"
        },
        {
            "robert_morris_university": "Robert Morris University"
        },
        {
            "rochester_institute_of_technology": "Rochester Institute of Technology"
        },
        {
            "rockford_university": "Rockford University"
        },
        {
            "rockhurst_university": "Rockhurst University"
        },
        {
            "rollins_college": "Rollins College"
        },
        {
            "roger_williams_university": "Roger Williams University"
        },
        {
            "rosalind_franklin_university_of_medicine_and_science": "Rosalind Franklin University of Medicine and Science"
        },
        {
            "roosevelt_university": "Roosevelt University"
        },
        {
            "rose-hulman_institute_of_technology": "Rose-Hulman Institute of Technology"
        },
        {
            "rowan_university": "Rowan University"
        },
        {
            "rutgers_university": "Rutgers University"
        },
        {
            "sacred_heart_university": "Sacred Heart University"
        },
        {
            "rutgers_university": "Rutgers University"
        },
        {
            "rutgers_university": "Rutgers University"
        },
        {
            "saginaw_valley_state_university": "Saginaw Valley State University"
        },
        {
            "saint_cloud_state_university": "Saint Cloud State University"
        },
        {
            "saint_ambrose_university": "Saint Ambrose University"
        },
        {
            "saint_joseph's_university": "Saint Joseph's University"
        },
        {
            "saint_leo_university": "Saint Leo University"
        },
        {
            "saint_louis_university": "Saint Louis University"
        },
        {
            "saint_mary's_college_of_california": "Saint Mary's College of California"
        },
        {
            "saint_louis_university": "Saint Louis University"
        },
        {
            "saint_mary's_university_of_minnesota": "Saint Mary's University of Minnesota"
        },
        {
            "saint_peter's_university": "Saint Peter's University"
        },
        {
            "saint_vincent_college": "Saint Vincent College"
        },
        {
            "saint_xavier_university": "Saint Xavier University"
        },
        {
            "salem_international_university": "Salem International University"
        },
        {
            "salem_state_university": "Salem State University"
        },
        {
            "sam_houston_state_university": "Sam Houston State University"
        },
        {
            "salisbury_university": "Salisbury University"
        },
        {
            "salve_regina_university": "Salve Regina University"
        },
        {
            "samford_university": "Samford University"
        },
        {
            "san_diego_state_university": "San Diego State University"
        },
        {
            "san_jos\u00e3\u00a9_state_university": "San Jos\u00c3\u00a9 State University"
        },
        {
            "san_jos\u00e3\u00a9_state_university": "San Jos\u00c3\u00a9 State University"
        },
        {
            "san_francisco_state_university": "San Francisco State University"
        },
        {
            "savannah_college_of_art_and_design": "Savannah College of Art and Design"
        },
        {
            "santa_clara_university": "Santa Clara University"
        },
        {
            "sarah_lawrence_college": "Sarah Lawrence College"
        },
        {
            "savannah_state_university": "Savannah State University"
        },
        {
            "saybrook_university": "Saybrook University"
        },
        {
            "school_for_international_training": "School for International Training"
        },
        {
            "school_of_the_art_institute_of_chicago": "School of the Art Institute of Chicago"
        },
        {
            "school_of_visual_arts": "School of Visual Arts"
        },
        {
            "seattle_university": "Seattle University"
        },
        {
            "seton_hall_university": "Seton Hall University"
        },
        {
            "seattle_pacific_university": "Seattle Pacific University"
        },
        {
            "seton_hill_university": "Seton Hill University"
        },
        {
            "shedoah_university": "Shedoah University"
        },
        {
            "shepherd_university": "Shepherd University"
        },
        {
            "shippensburg_university_of_pennsylvania": "Shippensburg University of Pennsylvania"
        },
        {
            "slippery_rock_university": "Slippery Rock University"
        },
        {
            "sofia_university": "Sofia University"
        },
        {
            "simmons_university": "Simmons University"
        },
        {
            "sonoma_state_university": "Sonoma State University"
        },
        {
            "soka_university_of_america": "Soka University of America"
        },
        {
            "sotheby's_institute_of_art": "Sotheby's Institute of Art"
        },
        {
            "sotheby's_institute_of_art_": "sotheby's institute of art "
        },
        {
            "south_carolina_state_university": "South Carolina State University"
        },
        {
            "south_university,_tampa": "South University, Tampa"
        },
        {
            "south_dakota_school_of_mines_&_technology": "South Dakota School of Mines & Technology"
        },
        {
            "southeast_missouri_state_university": "southeast missouri state university"
        },
        {
            "south_dakota_state_university": "South Dakota State University"
        },
        {
            "southeastern_louisiana_university": "Southeastern Louisiana University"
        },
        {
            "southern_california_institute_of_architecture": "Southern California Institute of Architecture"
        },
        {
            "southern_arkansas_university": "Southern Arkansas University"
        },
        {
            "southern_connecticut_state_university": "Southern Connecticut State University"
        },
        {
            "southern_illinois_university": "Southern Illinois University"
        },
        {
            "southern_nazarene_university": "Southern Nazarene University"
        },
        {
            "southern_new_hampshire_university": "Southern New Hampshire University"
        },
        {
            "southern_methodist_university": "Southern Methodist University"
        },
        {
            "southern_oregon_university": "Southern Oregon University"
        },
        {
            "southern_university_and_a&m_college": "Southern University and A&M College"
        },
        {
            "southern_university_at_new_orleans_(suno)": "Southern University at New Orleans (SUNO)"
        },
        {
            "southwest_minnesota_state_university": "Southwest Minnesota State University"
        },
        {
            "southern_utah_university": "Southern Utah University"
        },
        {
            "springfield_college": "Springfield College"
        },
        {
            "southwestern_adventist_university": "Southwestern Adventist University"
        },
        {
            "southwestern_oklahoma_state_university": "Southwestern Oklahoma State University"
        },
        {
            "st._edward's_university": "St. Edward's University"
        },
        {
            "st._john's_university": "St. John's University"
        },
        {
            "state_university_of_new_york_at_geneseo": "State University of New York at Geneseo"
        },
        {
            "stanford_university": "Stanford University"
        },
        {
            "st._mary's_university": "St. Mary's University"
        },
        {
            "state_university_of_new_york_at_new_paltz": "State University of New York at New Paltz"
        },
        {
            "state_university_of_new_york_at_oswego": "State University of New York at Oswego"
        },
        {
            "state_university_of_new_york_fredonia": "State University of New York Fredonia"
        },
        {
            "state_university_of_new_york_plattsburgh": "State University of New York Plattsburgh"
        },
        {
            "stephen_f._austin_state_university": "Stephen F. Austin State University"
        },
        {
            "state_university_of_new_york_polytechnic_institute": "State University of New York Polytechnic Institute"
        },
        {
            "state_university_of_new_york_potsdam": "State University of New York Potsdam"
        },
        {
            "stevens_institute_of_technology": "Stevens Institute of Technology"
        },
        {
            "stetson_university": "Stetson University"
        },
        {
            "stockton_university": "Stockton University"
        },
        {
            "stony_brook_university": "Stony Brook University"
        },
        {
            "suffolk_university": "Suffolk University"
        },
        {
            "suny_-_college_of_environmental_science_and_forestry": "SUNY - College of Environmental Science and Forestry"
        },
        {
            "sullivan_university": "Sullivan University"
        },
        {
            "suny_cortland": "Suny Cortland"
        },
        {
            "suny_maritime_college": "SUNY Maritime College"
        },
        {
            "syracuse_university": "Syracuse University"
        },
        {
            "suny_old_westbury": "SUNY Old Westbury"
        },
        {
            "tarleton_state_university": "Tarleton State University"
        },
        {
            "teachers_college_at_columbia_university": "Teachers College at Columbia University"
        },
        {
            "temple_university": "Temple University"
        },
        {
            "tennessee_tech_university": "Tennessee Tech University"
        },
        {
            "tennessee_state_university": "Tennessee State University"
        },
        {
            "texas_a&m_international_university_": "Texas A&M international university "
        },
        {
            "texas_a&m_university": "Texas A&M University"
        },
        {
            "texas_a&m_san_antonio": "Texas A&M San Antonio"
        },
        {
            "texas_christian_university": "Texas Christian University"
        },
        {
            "texas_a&m_university_-_kingsville": "Texas A&M University - Kingsville"
        },
        {
            "texas_a&m_university_central_texas": "Texas A&M University Central Texas"
        },
        {
            "texas_a&m_university-corpus_christi": "Texas A&M University-Corpus Christi"
        },
        {
            "texas_southern_university_(tsu)": "Texas Southern University (TSU)"
        },
        {
            "texas_tech_university": "Texas Tech University"
        },
        {
            "texas_state_university": "Texas State University"
        },
        {
            "texas_tech_university": "Texas Tech University"
        },
        {
            "the_aga_khan_university": "The Aga Khan University"
        },
        {
            "texas_woman's_university": "Texas Woman's University"
        },
        {
            "texas_tech_university_health_sciences_center": "Texas Tech University Health Sciences Center"
        },
        {
            "the_citadel_-_the_military_college_of_south_carolina": "The Citadel - The Military College of South Carolina"
        },
        {
            "the_city_university_of_new_york": "The City University of New York"
        },
        {
            "the_college_of_new_jersey": "The College of New Jersey"
        },
        {
            "the_new_school": "The New School"
        },
        {
            "the_john_marshall_law_school": "The John Marshall Law School"
        },
        {
            "the_university_of_alabama": "The University of Alabama"
        },
        {
            "the_ohio_state_university": "The Ohio State University"
        },
        {
            "the_university_of_akron": "The University of Akron"
        },
        {
            "the_university_of_iowa": "The University of Iowa"
        },
        {
            "the_university_of_alabama_at_birmingham": "The University of Alabama at Birmingham"
        },
        {
            "the_university_of_maine": "The University of Maine"
        },
        {
            "the_university_of_montana": "The University of Montana"
        },
        {
            "the_university_of_new_mexico": "The University of New Mexico"
        },
        {
            "the_university_of_north_carolina_at_charlotte": "The University of North Carolina at Charlotte"
        },
        {
            "the_university_of_tampa": "The University of Tampa"
        },
        {
            "the_university_of_st._thomas_(ust)": "The University of St. Thomas (UST)"
        },
        {
            "the_university_of_tennessee_at_chattanooga": "The University of Tennessee at Chattanooga"
        },
        {
            "the_university_of_texas_at_san_antonio": "The University of Texas at San Antonio"
        },
        {
            "the_university_of_texas_at_arlington": "The University of Texas at Arlington"
        },
        {
            "the_university_of_texas_at_tyler": "The University of Texas at Tyler"
        },
        {
            "the_university_of_texas_health_science_center_at_houston": "The University of Texas Health Science Center at Houston"
        },
        {
            "the_university_of_toledo": "The University of Toledo"
        },
        {
            "the_university_of_texas_health_science_center_at_san_antonio": "The University of Texas Health Science Center at San Antonio"
        },
        {
            "the_university_of_vermont": "The University of Vermont"
        },
        {
            "the_university_of_texas_rio_grande_valley": "The University of Texas Rio Grande Valley"
        },
        {
            "thomas_jefferson_university": "Thomas Jefferson University"
        },
        {
            "thunderbird_school_of_global_management": "Thunderbird School of Global Management"
        },
        {
            "trinity_college": "Trinity College"
        },
        {
            "towson_university": "Towson University"
        },
        {
            "trinity_international_university": "Trinity International University"
        },
        {
            "trinity_university_san_antonio": "Trinity University San Antonio"
        },
        {
            "troy_university": "Troy University"
        },
        {
            "tufts_university": "Tufts University"
        },
        {
            "truman_state_university": "Truman State University"
        },
        {
            "tulane_university": "Tulane University"
        },
        {
            "tuskegee_university": "Tuskegee University"
        },
        {
            "united_states_university": "United States University"
        },
        {
            "umass_boston_global_student_success_program": "UMass Boston Global Student Success Program"
        },
        {
            "university_at_albany": "University at Albany"
        },
        {
            "university_of_advancing_technology": "University Of Advancing Technology"
        },
        {
            "university_at_buffalo": "University at Buffalo"
        },
        {
            "university_of_alabama_huntsville": "University of Alabama Huntsville"
        },
        {
            "university_at_buffalo_suny_-_school_of_engineering_and_applied_sciences": "University at Buffalo SUNY - School of Engineering and Applied Sciences"
        },
        {
            "university_of_alaska_anchorage": "University of Alaska Anchorage"
        },
        {
            "university_of_alaska_fairbanks": "University of Alaska Fairbanks"
        },
        {
            "university_of_arizona": "University of Arizona"
        },
        {
            "university_of_arizona": "University of Arizona"
        },
        {
            "university_of_arkansas": "University of Arkansas"
        },
        {
            "university_of_arkansas_at_little_rock": "University of Arkansas at Little Rock"
        },
        {
            "university_of_bridgeport": "University of Bridgeport"
        },
        {
            "university_of_arkansas_at_pine_bluff": "University of Arkansas at Pine Bluff"
        },
        {
            "university_of_baltimore": "University of Baltimore"
        },
        {
            "university_of_california,_berkeley": "University of California, Berkeley"
        },
        {
            "university_of_california,_davis": "University of California, Davis"
        },
        {
            "university_of_california,_irvine_(uci)": "University of California, Irvine (UCI)"
        },
        {
            "university_of_california,_los_angeles_(ucla)": "University of California, Los Angeles (UCLA)"
        },
        {
            "university_of_california,_riverside": "University of California, Riverside"
        },
        {
            "university_of_california,_merced": "University of California, Merced"
        },
        {
            "university_of_california,_san_diego": "University of California, San Diego"
        },
        {
            "university_of_california,_santa_barbara": "University of California, Santa Barbara"
        },
        {
            "university_of_california,_santa_cruz": "University of California, Santa Cruz"
        },
        {
            "university_of_central_arkansas": "University of Central Arkansas"
        },
        {
            "university_of_central_florida": "University of Central Florida"
        },
        {
            "university_of_central_missouri": "University of Central Missouri"
        },
        {
            "university_of_central_missouri": "University of Central Missouri"
        },
        {
            "university_of_central_oklahoma": "University of Central Oklahoma"
        },
        {
            "university_of_central_missouri": "University of Central Missouri"
        },
        {
            "university_of_chicago": "University of Chicago"
        },
        {
            "university_of_cincinnati": "University of Cincinnati"
        },
        {
            "university_of_colorado_at_boulder": "University of Colorado at Boulder"
        },
        {
            "university_of_colorado_denver": "University of Colorado Denver"
        },
        {
            "university_of_colorado_colorado_springs_(uccs)": "University of Colorado Colorado Springs (UCCS)"
        },
        {
            "university_of_connecticut": "University of Connecticut"
        },
        {
            "university_of_dayton": "University of Dayton"
        },
        {
            "university_of_dallas": "University of Dallas"
        },
        {
            "university_of_delaware": "University of Delaware"
        },
        {
            "university_of_detroit_mercy": "University of Detroit Mercy"
        },
        {
            "university_of_denver": "University of Denver"
        },
        {
            "university_of_evansville": "University of Evansville"
        },
        {
            "university_of_findlay": "University of Findlay"
        },
        {
            "university_of_hartford": "University of Hartford"
        },
        {
            "university_of_florida": "University of Florida"
        },
        {
            "university_of_georgia": "University of Georgia"
        },
        {
            "university_of_hawaii_at_hilo": "University of Hawaii at Hilo"
        },
        {
            "university_of_houston": "University of Houston"
        },
        {
            "university_of_hawaii_at_manoa": "University of Hawaii at Manoa"
        },
        {
            "university_of_houston_clear_lake": "University of Houston Clear Lake"
        },
        {
            "university_of_houston_downtown": "University of Houston Downtown"
        },
        {
            "university_of_idaho": "University of Idaho"
        },
        {
            "university_of_houston_victoria": "University of Houston Victoria"
        },
        {
            "university_of_illinois_at_chicago": "University of Illinois at Chicago"
        },
        {
            "university_of_illinois_at_urbana_champaign": "University of Illinois at Urbana Champaign"
        },
        {
            "university_of_illinois_springfield": "University of Illinois Springfield"
        },
        {
            "university_of_kansas": "University of Kansas"
        },
        {
            "university_of_kansas_medical_center": "University of Kansas Medical Center"
        },
        {
            "university_of_indianapolis": "University of Indianapolis"
        },
        {
            "university_of_kansas": "University of Kansas"
        },
        {
            "university_of_la_verne": "University of La Verne"
        },
        {
            "university_of_kentucky": "University of Kentucky"
        },
        {
            "university_of_louisiana_at_lafayette": "University of Louisiana at Lafayette"
        },
        {
            "university_of_louisville": "University of Louisville"
        },
        {
            "university_of_mary_washington": "University of Mary Washington"
        },
        {
            "university_of_lynchburg": "University of Lynchburg"
        },
        {
            "university_of_mary_hardin_baylor": "University of Mary Hardin Baylor"
        },
        {
            "university_of_maryland": "University of Maryland"
        },
        {
            "university_of_maryland_eastern_shore": "University of Maryland Eastern Shore"
        },
        {
            "university_of_maryland_baltimore_county_(umbc)": "University of Maryland Baltimore County (UMBC)"
        },
        {
            "university_of_maryland_center_for_environmental_science": "University of Maryland Center for Environmental Science"
        },
        {
            "university_of_massachusetts_amherst": "University of Massachusetts Amherst"
        },
        {
            "university_of_massachusetts_boston": "University of Massachusetts Boston"
        },
        {
            "university_of_memphis": "University of Memphis"
        },
        {
            "university_of_miami": "University of Miami"
        },
        {
            "university_of_michigan": "University of Michigan"
        },
        {
            "university_of_massachusetts_dartmouth": "University of Massachusetts Dartmouth"
        },
        {
            "university_of_massachusetts_lowell": "University of Massachusetts Lowell"
        },
        {
            "university_of_miami_school_of_law": "University of Miami School of Law"
        },
        {
            "university_of_michigan_-_dearborn": "University of Michigan - Dearborn"
        },
        {
            "university_of_michigan_flint": "University of Michigan Flint"
        },
        {
            "university_of_minnesota": "University of Minnesota"
        },
        {
            "university_of_mississippi": "University of Mississippi"
        },
        {
            "university_of_minnesota": "University of Minnesota"
        },
        {
            "university_of_missouri\u00e2\u20ac\u201cst._louis": "University of Missouri\u00e2\u20ac\u201cSt. Louis"
        },
        {
            "university_of_missouri_-_kansas_city": "University of Missouri - Kansas City"
        },
        {
            "university_of_missouri_-_columbia": "University of Missouri - Columbia"
        },
        {
            "university_of_nebraska_kearney": "University of Nebraska Kearney"
        },
        {
            "university_of_nebraska_omaha": "University of Nebraska Omaha"
        },
        {
            "university_of_nebraska-lincoln": "University of Nebraska-Lincoln"
        },
        {
            "university_of_nevada,_reno": "University of Nevada, Reno"
        },
        {
            "university_of_new_england_maine": "University of New England Maine"
        },
        {
            "university_of_nevada,_las_vegas": "University of Nevada, Las Vegas"
        },
        {
            "university_of_new_hampshire": "University of New Hampshire"
        },
        {
            "university_of_north_alabama": "University of North Alabama"
        },
        {
            "university_of_new_haven": "University of New Haven"
        },
        {
            "university_of_new_orleans": "University of New Orleans"
        },
        {
            "university_of_north_carolina_chapel_hill": "University of North Carolina Chapel Hill"
        },
        {
            "university_of_north_carolina_wilmington": "University of North Carolina Wilmington"
        },
        {
            "university_of_north_carolina_greensboro": "University of North Carolina Greensboro"
        },
        {
            "university_of_north_florida": "University of North Florida"
        },
        {
            "university_of_north_dakota": "University of North Dakota"
        },
        {
            "university_of_north_georgia": "University of North Georgia"
        },
        {
            "university_of_north_texas": "University of North Texas"
        },
        {
            "university_of_northern_colorado": "University of Northern Colorado"
        },
        {
            "university_of_north_texas_health_science_center": "University of North Texas Health Science Center"
        },
        {
            "university_of_northern_iowa": "University of Northern Iowa"
        },
        {
            "university_of_oklahoma": "University of Oklahoma"
        },
        {
            "university_of_notre_dame": "University of Notre Dame"
        },
        {
            "university_of_pennsylvania": "University of Pennsylvania"
        },
        {
            "university_of_oregon": "University of Oregon"
        },
        {
            "university_of_phoenix": "University of Phoenix"
        },
        {
            "university_of_pittsburgh": "University of Pittsburgh"
        },
        {
            "university_of_richmond": "University of Richmond"
        },
        {
            "university_of_portland": "University of Portland"
        },
        {
            "university_of_redlands": "University of Redlands"
        },
        {
            "university_of_rhode_island": "University of Rhode Island"
        },
        {
            "university_of_rochester": "University of Rochester"
        },
        {
            "university_of_saint_francis": "University of Saint Francis"
        },
        {
            "university_of_saint_thomas_saint_paul": "University of Saint Thomas Saint Paul"
        },
        {
            "university_of_san_diego": "University of San Diego"
        },
        {
            "university_of_south_alabama": "University of South Alabama"
        },
        {
            "university_of_san_francisco": "University of San Francisco"
        },
        {
            "university_of_scranton": "University of Scranton"
        },
        {
            "university_of_south_dakota": "University of South Dakota"
        },
        {
            "university_of_south_carolina_upstate": "University of South Carolina Upstate"
        },
        {
            "university_of_south_carolina": "University of South Carolina"
        },
        {
            "university_of_south_florida": "University of South Florida"
        },
        {
            "university_of_south_florida": "University of South Florida"
        },
        {
            "university_of_south_florida_st_petersburg": "University of South Florida St Petersburg"
        },
        {
            "university_of_southern_indiana": "University of Southern Indiana"
        },
        {
            "university_of_southern_california": "University of Southern California"
        },
        {
            "university_of_southern_mississippi": "University of Southern Mississippi"
        },
        {
            "university_of_st_francis": "University of St Francis"
        },
        {
            "university_of_tennessee_knoxville": "University of Tennessee Knoxville"
        },
        {
            "university_of_tennessee_space_institute": "University of Tennessee Space Institute"
        },
        {
            "university_of_texas_el_paso": "University of Texas El Paso"
        },
        {
            "university_of_texas_at_austin": "University of Texas at Austin"
        },
        {
            "university_of_texas_at_dallas": "University of Texas at Dallas"
        },
        {
            "university_of_the_district_of_columbia": "University of the District of Columbia"
        },
        {
            "university_of_texas_permian_basin": "University of Texas Permian Basin"
        },
        {
            "university_of_the_incarnate_word": "University of the Incarnate Word"
        },
        {
            "university_of_the_pacific": "University of the Pacific"
        },
        {
            "university_of_the_west": "University of the West"
        },
        {
            "university_of_tulsa": "University of Tulsa"
        },
        {
            "university_of_utah": "University of Utah"
        },
        {
            "university_of_washington": "University of Washington"
        },
        {
            "university_of_virginia": "University of Virginia"
        },
        {
            "university_of_washington": "University of Washington"
        },
        {
            "university_of_washington_bothell": "University of Washington Bothell"
        },
        {
            "university_of_west_alabama": "University of West Alabama"
        },
        {
            "university_of_west_florida": "University of West Florida"
        },
        {
            "university_of_wisconsin_la_crosse": "University of Wisconsin La Crosse"
        },
        {
            "university_of_wisconsin_green_bay": "University of Wisconsin Green Bay"
        },
        {
            "university_of_west_georgia": "University of West Georgia"
        },
        {
            "university_of_wisconsin_-_whitewater": "University of Wisconsin - Whitewater"
        },
        {
            "university_of_wisconsin_-_river_falls": "University of Wisconsin - River Falls"
        },
        {
            "university_of_wisconsin_madison": "University of Wisconsin Madison"
        },
        {
            "university_of_wisconsin_milwaukee": "University of Wisconsin Milwaukee"
        },
        {
            "university_of_wisconsin_stout": "University of Wisconsin Stout"
        },
        {
            "university_of_wisconsin-parkside": "University of Wisconsin-Parkside"
        },
        {
            "university_of_wyoming": "University of Wyoming"
        },
        {
            "utah_state_university": "Utah State University"
        },
        {
            "valparaiso_university": "Valparaiso University"
        },
        {
            "utica_university_": "Utica university "
        },
        {
            "utah_valley_university": "Utah Valley University"
        },
        {
            "valdosta_state_university": "Valdosta State University"
        },
        {
            "vanderbilt_university": "Vanderbilt University"
        },
        {
            "vanguard_university": "Vanguard University"
        },
        {
            "vermont_technical_college": "Vermont Technical College"
        },
        {
            "villanova_university": "Villanova University"
        },
        {
            "virginia_commonwealth_university": "Virginia Commonwealth University"
        },
        {
            "virginia_state_university": "Virginia State University"
        },
        {
            "virginia_wesleyan_college": "Virginia Wesleyan College"
        },
        {
            "virginia_tech": "Virginia Tech"
        },
        {
            "wake_forest_university": "Wake Forest University"
        },
        {
            "walsh_college": "Walsh College"
        },
        {
            "walsh_university": "Walsh University"
        },
        {
            "washburn_university": "Washburn University"
        },
        {
            "washington_state_university": "Washington State University"
        },
        {
            "washington_university_in_st._louis": "Washington University in St. Louis"
        },
        {
            "washington_adventist_university": "Washington Adventist University"
        },
        {
            "wayne_state_university": "Wayne State University"
        },
        {
            "webster_university": "Webster University"
        },
        {
            "webster_university": "Webster University"
        },
        {
            "weill_cornell_medical_college": "Weill Cornell Medical College"
        },
        {
            "west_virginia_state_university": "West Virginia State University"
        },
        {
            "wentworth_institute_of_technology": "Wentworth Institute of Technology"
        },
        {
            "wesleyan_university": "Wesleyan University"
        },
        {
            "yeshiva_university": "Yeshiva University"
        },
        {
            "west_virginia_university": "West Virginia University"
        },
        {
            "west_chester_university": "West Chester University"
        },
        {
            "western_carolina_university": "Western Carolina University"
        },
        {
            "western_connecticut_state_university": "Western Connecticut State University"
        },
        {
            "western_illinois_university": "Western Illinois University"
        },
        {
            "western_kentucky_university_": "Western Kentucky University "
        },
        {
            "western_michigan_university": "Western Michigan University"
        },
        {
            "western_new_england_university": "Western New England University"
        },
        {
            "western_oregon_university": "Western Oregon University"
        },
        {
            "western_university_of_health_sciences": "Western University of Health Sciences"
        },
        {
            "westminster_college_salt_lake_city": "Westminster College Salt Lake City"
        },
        {
            "western_washington_university": "Western Washington University"
        },
        {
            "wichita_state_university": "Wichita State University"
        },
        {
            "wheaton_college_illinois": "Wheaton College Illinois"
        },
        {
            "widener_university": "Widener University"
        },
        {
            "wilmington_university": "Wilmington University"
        },
        {
            "wilkes_university": "Wilkes University"
        },
        {
            "wingate_university": "Wingate University"
        },
        {
            "willamette_university": "Willamette University"
        },
        {
            "william_paterson_university_of_new_jersey": "William Paterson University of New Jersey"
        },
        {
            "winthrop_university": "Winthrop University"
        },
        {
            "winona_state_university": "Winona State University"
        },
        {
            "winston-salem_state_university": "Winston-Salem State University"
        },
        {
            "woodbury_university": "Woodbury University"
        },
        {
            "wittenberg_university": "Wittenberg University"
        },
        {
            "worcester_polytechnic_institute": "Worcester Polytechnic Institute"
        },
        {
            "yale_university": "Yale University"
        },
        {
            "yeshiva_university": "Yeshiva University"
        },
        {
            "worcester_state_university": "Worcester State University"
        },
        {
            "wright_state_university": "Wright State University"
        },
        {
            "youngstown_state_university_(ysu)": "Youngstown State University (YSU)"
        },
        {
            "york_university": "York University"
        },
        {
            "university_of_california_san_francisco": "University of California San Francisco"
        },
        {
            "baylor_college_of_medicine": "Baylor College of Medicine"
        },
        {
            "university_of_texas_southwestern_medical_center_at_dallas": "University of Texas Southwestern Medical Center at Dallas"
        },
        {
            "university_of_colorado_anschutz_medical_campus": "University of Colorado Anschutz Medical Campus"
        },
        {
            "university_of_massachusetts_medical_school_at_worcester": "University of Massachusetts Medical School at Worcester"
        },
        {
            "medical_university_of_south_carolina": "Medical University of South Carolina"
        },
        {
            "university_of_maryland_baltimore_county": "University of Maryland Baltimore County"
        },
        {
            "rockefeller_university": "Rockefeller University"
        },
        {
            "medical_college_of_wisconsin": "Medical College of Wisconsin"
        },
        {
            "city_university_of_new_york_-_graduate_center": "City University of New York - Graduate Center"
        },
        {
            "university_of_nebraska_medical_center": "University of Nebraska Medical Center"
        },
        {
            "university_of_texas_medical_branch_galveston": "University of Texas Medical Branch Galveston"
        },
        {
            "albert_einstein_college_of_medicine": "Albert Einstein College of Medicine"
        },
        {
            "east_carolina_university": "East Carolina University"
        },
        {
            "city_university_of_new_york_-_the_city_college_of_new_york": "City University of New York - The City College of New York"
        },
        {
            "university_of_arkansas_for_medical_sciences": "University of Arkansas for Medical Sciences"
        },
        {
            "california_polytechnic_state_university_cal_poly": "California Polytechnic State University Cal Poly"
        },
        {
            "cuny_hunter_college": "CUNY Hunter College"
        },
        {
            "university_of_oklahoma_health_sciences_center": "University of Oklahoma Health Sciences Center"
        },
        {
            "northwest_university": "Northwest University"
        },
        {
            "university_of_minnesota_twin_cities": "University of Minnesota Twin Cities"
        },
        {
            "university_of_mississippi_medical_center": "University of Mississippi Medical Center"
        },
        {
            "uniformed_services_university_of_the_health_sciences": "Uniformed Services University of the Health Sciences"
        },
        {
            "city_university_of_new_york_-_brooklyn_college": "City University of New York - Brooklyn College"
        },
        {
            "city_university_of_new_york_-_queens_college": "City University of New York - Queens College"
        },
        {
            "state_university_of_new_york_upstate_medical_university": "State University of New York Upstate Medical University"
        },
        {
            "wellesley_college": "Wellesley College"
        },
        {
            "city_university_of_new_york_-_baruch_college": "City University of New York - Baruch College"
        },
        {
            "smith_college": "Smith College"
        },
        {
            "swarthmore_college": "Swarthmore College"
        },
        {
            "williams_college": "Williams College"
        },
        {
            "carleton_college": "Carleton College"
        },
        {
            "united_states_naval_academy": "United States Naval Academy"
        },
        {
            "new_york_medical_college": "New York Medical College"
        },
        {
            "southern_illinois_university_edwardsville": "Southern Illinois University Edwardsville"
        },
        {
            "city_university_of_new_york_-_john_jay_college_of_criminal_justice": "City University of New York - John Jay College of Criminal Justice"
        },
        {
            "middlebury_college": "Middlebury College"
        },
        {
            "amherst_college": "Amherst College"
        },
        {
            "oberlin_college": "Oberlin College"
        },
        {
            "california_polytechnic_state_university_cal_poly_pomona": "California Polytechnic State University Cal Poly Pomona"
        },
        {
            "colby_college": "Colby College"
        },
        {
            "university_of_colorado_system": "University of Colorado System"
        },
        {
            "colgate_university": "Colgate University"
        },
        {
            "bowdoin_college": "Bowdoin College"
        },
        {
            "pomona_college": "Pomona College"
        },
        {
            "city_university_of_new_york_-_college_of_staten_island": "City University of New York - College of Staten Island"
        },
        {
            "barnard_college_columbia_university": "Barnard College Columbia University"
        },
        {
            "eastern_virginia_medical_school": "Eastern Virginia Medical School"
        },
        {
            "haverford_college": "Haverford College"
        },
        {
            "macalester_college": "Macalester College"
        },
        {
            "vassar_college": "Vassar College"
        },
        {
            "indiana_state_university": "Indiana State University"
        },
        {
            "university_of_wisconsin_eau_claire": "University of Wisconsin Eau Claire"
        },
        {
            "kenyon_college": "Kenyon College"
        },
        {
            "reed_college": "Reed College"
        },
        {
            "minnesota_state_university": "Minnesota State University"
        },
        {
            "loyola_university_maryland": "Loyola University Maryland"
        },
        {
            "bates_college": "Bates College"
        },
        {
            "skidmore_college": "Skidmore College"
        },
        {
            "harvey_mudd_college": "Harvey Mudd College"
        },
        {
            "hobart_and_william_smith_colleges": "Hobart and William Smith Colleges"
        },
        {
            "davidson_college": "Davidson College"
        },
        {
            "weber_state_university": "Weber State University"
        },
        {
            "lafayette_college": "Lafayette College"
        },
        {
            "southern_illinois_university_school_of_medicine": "Southern Illinois University School of Medicine"
        },
        {
            "gettysburg_college": "Gettysburg College"
        },
        {
            "hope_college": "Hope College"
        },
        {
            "occidental_college": "Occidental College"
        },
        {
            "franklin_&_marshall_college": "Franklin & Marshall College"
        },
        {
            "walden_university_(national_technological_university)": "Walden University (National Technological University)"
        },
        {
            "university_of_wisconsin_oshkosh": "University of Wisconsin Oshkosh"
        },
        {
            "union_college_schenectady_ny": "Union College Schenectady NY"
        },
        {
            "hamilton_college": "Hamilton College"
        },
        {
            "united_states_military_academy_at_west_point": "United States Military Academy at West Point"
        },
        {
            "calvin_university": "Calvin University"
        },
        {
            "grinnell_college": "Grinnell College"
        },
        {
            "monmouth_university": "Monmouth University"
        },
        {
            "dickinson_college": "Dickinson College"
        },
        {
            "midwestern_university": "Midwestern University"
        },
        {
            "colorado_college": "Colorado College"
        },
        {
            "rush_university": "Rush University"
        },
        {
            "washington_&_lee_university": "Washington & Lee University"
        },
        {
            "whitman_college": "Whitman College"
        },
        {
            "claremont_mckenna_college": "Claremont Mckenna College"
        },
        {
            "university_of_wisconsin_stevens_point": "University of Wisconsin Stevens Point"
        },
        {
            "high_point_university": "High Point University"
        },
        {
            "college_of_the_holy_cross": "College of the Holy Cross"
        },
        {
            "alabama_a&m_university": "Alabama A&M University"
        },
        {
            "connecticut_college": "Connecticut College"
        },
        {
            "northeast_ohio_medical_university_neomed": "Northeast Ohio Medical University NEOMED"
        },
        {
            "a_t_still_university": "A T Still University"
        },
        {
            "university_of_puget_sound": "University of Puget Sound"
        },
        {
            "cuny_new_york_city_college_of_technology": "CUNY New York City College of Technology"
        },
        {
            "emerson_college": "Emerson College"
        },
        {
            "denison_university": "Denison University"
        },
        {
            "kettering_university": "Kettering University"
        },
        {
            "college_of_wooster": "College of Wooster"
        },
        {
            "campbell_university": "Campbell University"
        },
        {
            "university_of_hawaii_system": "University of Hawaii System"
        },
        {
            "saint_olaf_college": "Saint Olaf College"
        },
        {
            "university_of_north_carolina_asheville": "University of North Carolina Asheville"
        },
        {
            "texas_a&m_university_galveston": "Texas A&M University Galveston"
        },
        {
            "indiana_university_system": "Indiana University System"
        },
        {
            "toyota_technological_institute_chicago": "Toyota Technological Institute Chicago"
        },
        {
            "johns_hopkins_university_bloomberg_school_of_public_health": "Johns Hopkins University Bloomberg School of Public Health"
        },
        {
            "depauw_university": "Depauw University"
        },
        {
            "meharry_medical_college": "Meharry Medical College"
        },
        {
            "pacific_lutheran_university": "Pacific Lutheran University"
        },
        {
            "ohio_northern_university": "Ohio Northern University"
        },
        {
            "xavier_university_of_louisiana": "Xavier University of Louisiana"
        },
        {
            "saint_lawrence_university": "Saint Lawrence University"
        },
        {
            "allegheny_college": "Allegheny College"
        },
        {
            "gustavus_adolphus_college": "Gustavus Adolphus College"
        },
        {
            "harvard_university_harvard_business_school": "Harvard University Harvard Business School"
        },
        {
            "st_catherine_university_(college_of_saint_catherine)": "St Catherine University (College of Saint Catherine)"
        },
        {
            "southwestern_university": "Southwestern University"
        },
        {
            "illinois_wesleyan_university": "Illinois Wesleyan University"
        },
        {
            "albion_college": "Albion College"
        },
        {
            "university_of_louisiana_at_monroe": "University of Louisiana at Monroe"
        },
        {
            "palo_alto_university_(pacific_graduate_school_of_psychology)": "Palo Alto University (Pacific Graduate School of Psychology)"
        },
        {
            "fuller_theological_seminary": "Fuller Theological Seminary"
        },
        {
            "university_of_tennessee_martin": "University of Tennessee Martin"
        },
        {
            "m_d_anderson_cancer_center_university_of_texas": "M D Anderson Cancer Center University of Texas"
        },
        {
            "berry_college": "Berry College"
        },
        {
            "virginia_military_institute": "Virginia Military Institute"
        },
        {
            "georgia_college_&_state_university": "Georgia College & State University"
        },
        {
            "university_of_maine_system": "University of Maine System"
        },
        {
            "susquehanna_university": "Susquehanna University"
        },
        {
            "university_of_alaska_system": "University of Alaska System"
        },
        {
            "city_university_of_new_york_-_lehman_college": "City University of New York - Lehman College"
        },
        {
            "purchase_college_suny": "Purchase College SUNY"
        },
        {
            "eckerd_college": "Eckerd College"
        },
        {
            "elizabethtown_college": "Elizabethtown College"
        },
        {
            "education_and_research_at_mayo_clinic": "Education and Research at Mayo Clinic"
        },
        {
            "austin_peay_state_university": "Austin Peay State University"
        },
        {
            "university_of_california_hastings_college_of_the_law": "University of California Hastings College of the Law"
        },
        {
            "louisiana_state_university_health_sciences_center_shreveport": "Louisiana State University Health Sciences Center Shreveport"
        },
        {
            "harvard-mit_division_of_health_sciences_and_technology": "Harvard-MIT Division of Health Sciences and Technology"
        },
        {
            "george_fox_university": "George Fox University"
        },
        {
            "west_texas_a&m_university": "West Texas A&M University"
        },
        {
            "brigham_young_university_idaho": "Brigham Young University Idaho"
        },
        {
            "new_college_of_florida": "New College of Florida"
        },
        {
            "muhlenberg_college": "Muhlenberg College"
        },
        {
            "ursinus_college": "Ursinus College"
        },
        {
            "bethel_university_minnesota": "Bethel University Minnesota"
        },
        {
            "kalamazoo_college": "Kalamazoo College"
        },
        {
            "angelo_state_university": "Angelo State University"
        },
        {
            "johns_hopkins_university_applied_physics_laboratory": "Johns Hopkins University Applied Physics Laboratory"
        },
        {
            "university_of_chicago_booth_school_of_business": "University of Chicago Booth School of Business"
        },
        {
            "nicholls_state_university": "Nicholls State University"
        },
        {
            "lincoln_memorial_university": "lincoln memorial university"
        },
        {
            "spelman_college": "Spelman College"
        },
        {
            "governors_state_university": "Governors State University"
        },
        {
            "missouri_western_state_university": "Missouri Western State University"
        },
        {
            "luther_college_decorah": "Luther College Decorah"
        },
        {
            "hampshire_college": "Hampshire College"
        },
        {
            "beloit_college": "Beloit College"
        },
        {
            "florida_a&m_university_and_florida_state_university_famu_fsu_college_of_engineering": "Florida A&M University and Florida State University FAMU FSU College of Engineering"
        },
        {
            "keene_state_college": "Keene State College"
        },
        {
            "cuny_york_college": "CUNY York College"
        },
        {
            "university_of_minnesota_morris": "University of Minnesota Morris"
        },
        {
            "bowie_state_university": "Bowie State University"
        },
        {
            "king's_college_wilkes-barre": "King's College Wilkes-Barre"
        },
        {
            "pitzer_college": "Pitzer College"
        },
        {
            "max_planck_florida_institute_for_neuroscience": "Max Planck Florida Institute for Neuroscience"
        },
        {
            "ohio_wesleyan_university": "Ohio Wesleyan University"
        },
        {
            "centre_college": "Centre College"
        },
        {
            "virginia_maryland_regional_college_of_veterinary_medicine": "Virginia Maryland Regional College of Veterinary Medicine"
        },
        {
            "francis_marion_university": "Francis Marion University"
        },
        {
            "university_of_southern_maine": "University of Southern Maine"
        },
        {
            "national_defense_university": "National Defense University"
        },
        {
            "university_of_alaska_southeast": "University of Alaska Southeast"
        },
        {
            "university_of_south_carolina_aiken": "University of South Carolina Aiken"
        },
        {
            "stonehill_college": "Stonehill College"
        },
        {
            "institute_for_advanced_study": "Institute for Advanced Study"
        },
        {
            "louisiana_state_university_health_sciences_center_new_orleans": "Louisiana State University Health Sciences Center New Orleans"
        },
        {
            "sewanee_the_university_of_the_south": "Sewanee The University of the South"
        },
        {
            "university_of_tennessee_health_science_center_memphis": "University of Tennessee Health Science Center Memphis"
        },
        {
            "colorado_state_university_pueblo": "Colorado State University Pueblo"
        },
        {
            "university_of_missouri_system": "University of Missouri System"
        },
        {
            "university_of_california_system": "University of California System"
        },
        {
            "augustana_college_rock_island": "Augustana College Rock Island"
        },
        {
            "grand_canyon_university": "Grand Canyon University"
        },
        {
            "oklahoma_state_university_oklahoma_city": "Oklahoma State University Oklahoma City"
        },
        {
            "college_of_william_&_mary_virginia_institute_of_marine_science": "College of William & Mary Virginia Institute of Marine Science"
        },
        {
            "saint_john_fisher_college": "Saint John Fisher College"
        },
        {
            "eastern_new_mexico_university": "Eastern New Mexico University"
        },
        {
            "franklin_w_olin_college_of_engineering": "Franklin W Olin College of Engineering"
        },
        {
            "new_jersey_city_university": "New Jersey City University"
        },
        {
            "stony_brook_medicine": "Stony Brook Medicine"
        },
        {
            "santa_fe_institute": "Santa Fe Institute"
        },
        {
            "desert_research_institute": "Desert Research Institute"
        },
        {
            "college_at_brockport_suny": "College at Brockport SUNY"
        },
        {
            "rutgers_the_state_university_of_new_jersey_camden": "Rutgers The State University of New Jersey Camden"
        },
        {
            "cuny_laguardia_community_college": "CUNY LaGuardia Community College"
        },
        {
            "lake_forest_college": "Lake Forest College"
        },
        {
            "university_of_wisconsin_platteville": "University of Wisconsin Platteville"
        },
        {
            "lawrence_university": "Lawrence University"
        },
        {
            "university_of_north_carolina_at_pembroke": "University of North Carolina at Pembroke"
        },
        {
            "capella_university": "Capella University"
        },
        {
            "edinboro_university": "Edinboro University"
        },
        {
            "touro_college": "Touro College"
        },
        {
            "national_university": "National University"
        },
        {
            "university_of_south_carolina_beaufort": "University of South Carolina Beaufort"
        },
        {
            "university_of_maryland_global_campus": "University of Maryland Global Campus"
        },
        {
            "saint_mary's_college_of_maryland": "Saint Mary's College of Maryland"
        },
        {
            "lake_erie_college": "Lake Erie College"
        },
        {
            "earlham_college": "Earlham College"
        },
        {
            "cuny_medgar_evers_college": "CUNY Medgar Evers College"
        },
        {
            "colorado_technical_university": "Colorado Technical University"
        },
        {
            "albany_medical_college": "Albany Medical College"
        },
        {
            "university_of_health_sciences_and_pharmacy_in_st._louis": "University of Health Sciences and Pharmacy in St. Louis"
        },
        {
            "california_state_university_system": "California State University System"
        },
        {
            "vermont_law_school": "Vermont Law School"
        },
        {
            "air_force_institute_of_technology": "Air Force Institute of Technology"
        },
        {
            "western_governors_university": "Western Governors University"
        },
        {
            "suny_college_at_oneonta": "SUNY College at Oneonta"
        },
        {
            "georgia_gwinnett_college": "Georgia Gwinnett College"
        },
        {
            "princeton_theological_seminary": "Princeton Theological Seminary"
        },
        {
            "columbia_college_chicago": "Columbia College Chicago"
        },
        {
            "state_university_of_new_york_empire_state_college": "State University of New York Empire State College"
        },
        {
            "illinois_college": "Illinois College"
        },
        {
            "eastern_university": "Eastern University"
        },
        {
            "johns_hopkins_university_school_of_medicine": "Johns Hopkins University School of Medicine"
        },
        {
            "texas_wesleyan_university": "Texas Wesleyan University"
        },
        {
            "wheaton_college_norton_massachusetts": "Wheaton College Norton Massachusetts"
        },
        {
            "marymount_manhattan_college": "Marymount Manhattan College"
        },
        {
            "farmingdale_state_college_suny": "Farmingdale State College SUNY"
        },
        {
            "gordon_college": "Gordon College"
        },
        {
            "nassau_community_college": "Nassau Community College"
        },
        {
            "molloy_college": "Molloy College"
        },
        {
            "austin_community_college": "Austin Community College"
        },
        {
            "miami_dade_college": "Miami Dade College"
        },
        {
            "us_naval_war_college": "US Naval War College"
        },
        {
            "rutgers_the_state_university_of_new_jersey_newark": "Rutgers The State University of New Jersey Newark"
        },
        {
            "northcentral_university": "Northcentral University"
        },
        {
            "dominican_university_river_forest_il": "Dominican University River Forest IL"
        },
        {
            "marshall_b_ketchum_university_(southern_california_college_of_optometry)": "Marshall B Ketchum University (Southern California College of Optometry)"
        },
        {
            "cooper_union_for_the_advancement_of_science_and_art": "Cooper Union for the Advancement of Science and Art"
        },
        {
            "northeastern_state_university": "Northeastern State University"
        },
        {
            "university_of_tennessee_system": "University of Tennessee System"
        },
        {
            "des_moines_university": "Des Moines University"
        },
        {
            "new_york_law_school": "New York Law School"
        },
        {
            "adventhealth_university": "AdventHealth University"
        },
        {
            "plymouth_state_university": "Plymouth State University"
        },
        {
            "augustana_university_sioux_falls": "Augustana University Sioux Falls"
        },
        {
            "washington_college": "Washington College"
        },
        {
            "agnes_scott_college": "Agnes Scott College"
        },
        {
            "messiah_college": "Messiah College"
        },
        {
            "otterbein_university": "Otterbein University"
        },
        {
            "florida_southern_college": "Florida Southern College"
        },
        {
            "linfield_college": "Linfield College"
        },
        {
            "saint_norbert_college": "Saint Norbert College"
        },
        {
            "norwich_university": "Norwich University"
        },
        {
            "bastyr_university_(seattle_midwifery_school)": "Bastyr University (Seattle Midwifery School)"
        },
        {
            "west_virginia_university_institute_of_technology": "West Virginia University Institute of Technology"
        },
        {
            "emmanuel_college_boston": "Emmanuel College Boston"
        },
        {
            "claremont_colleges": "Claremont Colleges"
        },
        {
            "wofford_college": "Wofford College"
        },
        {
            "chicago_school_of_professional_psychology": "Chicago School of Professional Psychology"
        },
        {
            "art_institute_of_chicago": "Art Institute Of Chicago"
        },
        {
            "roanoke_college": "Roanoke College"
        },
        {
            "new_england_college_of_optometry": "New England College of Optometry"
        },
        {
            "university_of_st_augustine_for_health_sciences": "University of St Augustine for Health Sciences"
        },
        {
            "hollins_university": "Hollins University"
        },
        {
            "juniata_college": "Juniata College"
        },
        {
            "city_colleges_of_chicago": "City Colleges of Chicago"
        },
        {
            "cedarville_university": "Cedarville University"
        },
        {
            "concordia_university_irvine": "Concordia University Irvine"
        },
        {
            "kansas_city_university_of_medicine_and_biosciences": "Kansas City University of Medicine and Biosciences"
        },
        {
            "millikin_university": "Millikin University"
        },
        {
            "north_dakota_university_system": "North Dakota University System"
        },
        {
            "westfield_state_university": "Westfield State University"
        },
        {
            "palomar_college": "Palomar College"
        },
        {
            "mgh_institute_of_health_professions": "Mgh Institute of Health Professions"
        },
        {
            "college_of_saint_benedict_&_saint_john's_university": "College of Saint Benedict & Saint John's University"
        },
        {
            "university_of_illinois_system": "University of Illinois System"
        },
        {
            "lock_haven_university_of_pennsylvania": "Lock Haven University of Pennsylvania"
        },
        {
            "state_university_of_new_york": "State University of New York"
        },
        {
            "morehouse_college": "Morehouse College"
        },
        {
            "lake_superior_state_university": "Lake Superior State University"
        },
        {
            "wheeling_university": "Wheeling University"
        },
        {
            "whittier_college": "Whittier College"
        },
        {
            "university_system_of_georgia": "University System of Georgia"
        },
        {
            "american_film_institute": "American Film Institute"
        },
        {
            "berklee_college_of_music": "Berklee College of Music"
        },
        {
            "point_loma_nazarene_university": "Point Loma Nazarene University"
        },
        {
            "saint_michael's_college": "Saint Michael's College"
        },
        {
            "university_of_texas_health_center_at_tyler": "University of Texas Health Center at Tyler"
        },
        {
            "portland_community_college": "Portland Community College"
        },
        {
            "tacoma_community_college": "Tacoma Community College"
        },
        {
            "ripon_college": "Ripon College"
        },
        {
            "union_university_tennessee": "Union University Tennessee"
        },
        {
            "northwestern_state_university_of_louisiana": "Northwestern State University of Louisiana"
        },
        {
            "california_western_school_of_law": "California Western School of Law"
        },
        {
            "lake_erie_college_of_osteopathic_medicine": "Lake Erie College of Osteopathic Medicine"
        },
        {
            "gemological_institute_of_america": "Gemological Institute of America"
        },
        {
            "university_of_saint_thomas_houston": "University of Saint Thomas Houston"
        },
        {
            "aims_community_college": "AIMS Community College"
        },
        {
            "saint_bonaventure_university": "Saint Bonaventure University"
        },
        {
            "rasmussen_college_(webster_college_&_aakers_college)": "Rasmussen College (Webster College & Aakers College)"
        },
        {
            "carlos_albizu_university": "Carlos Albizu University"
        },
        {
            "american_public_university_system": "American Public University System"
        },
        {
            "university_of_wisconsin_system": "University of Wisconsin System"
        },
        {
            "western_state_colorado_university": "Western State Colorado University"
        },
        {
            "randolph_macon_college": "Randolph Macon College"
        },
        {
            "roseman_university_of_health_sciences_(university_of_southern_nevada)": "Roseman University of Health Sciences (University of Southern Nevada)"
        },
        {
            "clark_college": "Clark College"
        },
        {
            "keiser_university": "Keiser University"
        },
        {
            "knox_college": "Knox College"
        },
        {
            "hartwick_college": "Hartwick College"
        },
        {
            "daemen_college": "Daemen College"
        },
        {
            "wagner_college": "Wagner College"
        },
        {
            "hiram_college": "Hiram College"
        },
        {
            "maryville_university_of_saint_louis": "Maryville University of Saint Louis"
        },
        {
            "sweet_briar_college": "Sweet Briar College"
        },
        {
            "mansfield_university_of_pennsylvania": "Mansfield University of Pennsylvania"
        },
        {
            "university_of_hawai\u00e2\u20ac\u02dci_school_of_ocean_and_earth_science_&_technology": "University of Hawai\u00e2\u20ac\u02dci School of Ocean and Earth Science & Technology"
        },
        {
            "saint_joseph_university_connecticut": "Saint Joseph University Connecticut"
        },
        {
            "yavapai_college": "Yavapai College"
        },
        {
            "quincy_university": "Quincy University"
        },
        {
            "hanover_college": "Hanover College"
        },
        {
            "carthage_college": "Carthage College"
        },
        {
            "wabash_college": "Wabash College"
        },
        {
            "art_institutes": "Art Institutes"
        },
        {
            "thomas_university": "Thomas University"
        },
        {
            "nebraska_wesleyan_university": "Nebraska Wesleyan University"
        },
        {
            "champlain_college": "Champlain College"
        },
        {
            "northern_virginia_community_college": "Northern Virginia Community College"
        },
        {
            "westmont_college": "Westmont College"
        },
        {
            "whitworth_university": "Whitworth University"
        },
        {
            "college_of_dupage": "College of DuPage"
        },
        {
            "mesa_community_college": "Mesa Community College"
        },
        {
            "meredith_college": "Meredith College"
        },
        {
            "bellevue_college_washington": "Bellevue College Washington"
        },
        {
            "central_new_mexico_community_college": "Central New Mexico Community College"
        },
        {
            "berea_college": "Berea College"
        },
        {
            "cabrillo_college": "Cabrillo College"
        },
        {
            "california_institute_of_the_arts_calarts": "California Institute of the Arts CalArts"
        },
        {
            "culinary_institute_of_america": "Culinary Institute of America"
        },
        {
            "lebanon_valley_college": "Lebanon Valley College"
        },
        {
            "valencia_college": "Valencia College"
        },
        {
            "tarrant_county_college": "Tarrant County College"
        },
        {
            "oral_roberts_university": "Oral Roberts University"
        },
        {
            "marian_university_indianapolis": "Marian University Indianapolis"
        },
        {
            "houston_community_college_system": "Houston Community College System"
        },
        {
            "university_of_arkansas_fort_smith": "University of Arkansas Fort Smith"
        },
        {
            "university_of_texas_system": "University of Texas System"
        },
        {
            "rocky_mountain_university_of_health_professions": "Rocky Mountain University of Health Professions"
        },
        {
            "bank_street_college_of_education": "Bank Street College of Education"
        },
        {
            "rosemont_college": "Rosemont College"
        },
        {
            "north_park_university": "North Park University"
        },
        {
            "excelsior_college": "Excelsior College"
        },
        {
            "dixie_state_university": "Dixie State University"
        },
        {
            "santa_monica_college": "Santa Monica College"
        },
        {
            "aquinas_college_grand_rapids_michigan": "Aquinas College Grand Rapids Michigan"
        },
        {
            "lindsey_wilson_college": "Lindsey Wilson College"
        },
        {
            "college_of_central_florida": "College of Central Florida"
        },
        {
            "college_of_lake_county": "College of Lake County"
        },
        {
            "purdue_university_global:_(kaplan_university)": "Purdue University Global: (Kaplan University)"
        },
        {
            "olivet_nazarene_university": "Olivet Nazarene University"
        },
        {
            "devry_university": "Devry University"
        },
        {
            "columbia_college_of_missouri": "Columbia College of Missouri"
        },
        {
            "westminster_college_new_wilmington": "Westminster College New Wilmington"
        },
        {
            "mount_st_mary's_university_emmitsburg": "Mount St Mary's University Emmitsburg"
        },
        {
            "cornell_college": "Cornell College"
        },
        {
            "illinois_college_of_optometry": "Illinois College of Optometry"
        },
        {
            "dallas_college": "Dallas College"
        },
        {
            "newman_university": "Newman University"
        },
        {
            "concordia_university_portland": "Concordia University Portland"
        },
        {
            "bennington_college": "Bennington College"
        },
        {
            "harper_college": "Harper College"
        },
        {
            "monroe_community_college": "Monroe Community College"
        },
        {
            "oakton_community_college": "Oakton Community College"
        },
        {
            "turtle_mountain_community_college": "Turtle Mountain Community College"
        },
        {
            "juilliard_school": "Juilliard School"
        },
        {
            "kirkwood_community_college": "Kirkwood Community College"
        },
        {
            "green_river_community_college": "Green River Community College"
        },
        {
            "middle_georgia_state_university": "Middle Georgia State University"
        },
        {
            "butte_college": "Butte College"
        },
        {
            "west_valley_college": "West Valley College"
        },
        {
            "chaminade_university_of_honolulu": "Chaminade University of Honolulu"
        },
        {
            "howard_community_college": "Howard Community College"
        },
        {
            "pennsylvania_college_of_technology": "Pennsylvania College of Technology"
        },
        {
            "saint_anselm_college": "Saint Anselm College"
        },
        {
            "saint_louis_community_college": "Saint Louis Community College"
        },
        {
            "brookdale_community_college": "Brookdale Community College"
        },
        {
            "new_york_film_academy_acting_&_film_school": "New York Film Academy Acting & Film School"
        },
        {
            "linn_benton_community_college": "Linn Benton Community College"
        },
        {
            "pasadena_city_college": "Pasadena City College"
        },
        {
            "broward_college": "Broward College"
        },
        {
            "saint_john's_college": "Saint John's College"
        },
        {
            "harding_university": "Harding University"
        },
        {
            "southeastern_oklahoma_state_university": "Southeastern Oklahoma State University"
        },
        {
            "lane_community_college": "Lane Community College"
        },
        {
            "united_tribes_technical_college": "United Tribes Technical College"
        },
        {
            "chandler_gilbert_community_college": "Chandler Gilbert Community College"
        },
        {
            "montgomery_college": "Montgomery College"
        },
        {
            "seattle_central_community_college": "Seattle Central Community College"
        },
        {
            "carroll_college_helena_montana": "Carroll College Helena Montana"
        },
        {
            "madison_area_technical_college": "Madison Area Technical College"
        },
        {
            "penn_foster_college": "Penn Foster College"
        },
        {
            "philander_smith_college": "Philander Smith College"
        },
        {
            "community_college_of_southern_nevada": "Community College of Southern Nevada"
        },
        {
            "santa_rosa_junior_college": "Santa Rosa Junior College"
        },
        {
            "northwest_nazarene_university": "Northwest Nazarene University"
        },
        {
            "lone_star_college": "Lone Star College"
        },
        {
            "college_of_saint_scholastica": "College of Saint Scholastica"
        },
        {
            "columbus_state_community_college": "Columbus State Community College"
        },
        {
            "bucks_county_community_college": "Bucks County Community College"
        },
        {
            "university_of_florida_health": "University of Florida Health"
        },
        {
            "shorter_university": "Shorter University"
        },
        {
            "barton_college": "Barton College"
        },
        {
            "foothill_college": "Foothill College"
        },
        {
            "bluffton_university": "Bluffton University"
        },
        {
            "voorhees_college": "Voorhees College"
        },
        {
            "community_college_of_philadelphia": "Community College of Philadelphia"
        },
        {
            "johnson_county_community_college": "Johnson County Community College"
        },
        {
            "university_of_montana_western": "University of Montana Western"
        },
        {
            "alamo_community_colleges": "Alamo Community Colleges"
        },
        {
            "unity_college": "Unity College"
        },
        {
            "united_states_air_force_academy": "United States Air Force Academy"
        },
        {
            "east_georgia_state_college": "East Georgia State College"
        },
        {
            "citrus_college": "Citrus College"
        },
        {
            "sierra_college": "Sierra College"
        },
        {
            "baker_college": "Baker College"
        },
        {
            "grove_city_college": "Grove City College"
        },
        {
            "cypress_college": "Cypress College"
        },
        {
            "central_methodist_university": "Central Methodist University"
        },
        {
            "university_of_north_carolina": "University of North Carolina"
        },
        {
            "university_of_the_people": "University of the People"
        },
        {
            "minnesota_state_colleges_and_universities": "Minnesota State Colleges and Universities"
        },
        {
            "red_rocks_community_college": "Red Rocks Community College"
        },
        {
            "indiana_wesleyan_university": "Indiana Wesleyan University"
        },
        {
            "national_american_university": "National American University"
        },
        {
            "sacramento_city_college": "Sacramento City College"
        },
        {
            "bakersfield_college": "Bakersfield College"
        },
        {
            "long_beach_city_college": "Long Beach City College"
        },
        {
            "john_a_logan_college": "John A Logan College"
        },
        {
            "highline_community_college": "Highline Community College"
        },
        {
            "fresno_city_college": "Fresno City College"
        },
        {
            "chamberlain_university": "Chamberlain University"
        },
        {
            "goshen_college": "Goshen College"
        },
        {
            "university_of_arizona_global_campus": "University of Arizona Global Campus"
        },
        {
            "middlesex_community_college": "Middlesex Community College"
        },
        {
            "vincennes_university": "Vincennes University"
        },
        {
            "university_of_north_carolina_school_of_the_arts": "University of North Carolina School of the Arts"
        },
        {
            "central_piedmont_community_college": "Central Piedmont Community College"
        },
        {
            "miracosta_college": "Miracosta College"
        },
        {
            "delaware_technical_&_community_college": "Delaware Technical & Community College"
        },
        {
            "bridgewater_college": "Bridgewater College"
        },
        {
            "ivy_tech_state_college": "Ivy Tech State College"
        },
        {
            "maryland_institute_college_of_art": "Maryland Institute College of Art"
        },
        {
            "macomb_community_college": "Macomb Community College"
        },
        {
            "king_university": "King University"
        },
        {
            "saint_mary's_college_notre_dame": "Saint Mary's College Notre Dame"
        },
        {
            "cuyahoga_community_college": "Cuyahoga Community College"
        },
        {
            "albright_college": "Albright College"
        },
        {
            "central_maine_community_college": "Central Maine Community College"
        },
        {
            "skyline_college": "Skyline College"
        },
        {
            "southern_adventist_university": "Southern Adventist University"
        },
        {
            "polk_state_college": "Polk State College"
        },
        {
            "pima_community_college": "Pima Community College"
        },
        {
            "del_mar_college": "Del Mar College"
        },
        {
            "louisiana_state_university_agricultural_center": "Louisiana State University Agricultural Center"
        },
        {
            "northwood_university": "Northwood University"
        },
        {
            "prince_george's_community_college": "Prince George's Community College"
        },
        {
            "orange_coast_college": "Orange Coast College"
        },
        {
            "city_college_of_san_francisco": "City College of San Francisco"
        },
        {
            "thomas_more_college": "Thomas More College"
        },
        {
            "everett_community_college": "Everett Community College"
        },
        {
            "university_of_arkansas_division_of_agriculture": "University of Arkansas Division of Agriculture"
        },
        {
            "fullerton_college": "Fullerton College"
        },
        {
            "strayer_university": "Strayer University"
        },
        {
            "seminole_state_college_of_florida": "Seminole State College of Florida"
        },
        {
            "southwestern_college_california": "Southwestern College California"
        },
        {
            "lander_university": "Lander University"
        },
        {
            "metropolitan_community_college_nebraska": "Metropolitan Community College Nebraska"
        },
        {
            "union_county_college": "Union County College"
        },
        {
            "ventura_college": "Ventura College"
        },
        {
            "washington_&_jefferson_college": "Washington & Jefferson College"
        },
        {
            "imperial_valley_college": "Imperial Valley College"
        },
        {
            "becker_college": "Becker College"
        },
        {
            "florida_memorial_university": "Florida Memorial University"
        },
        {
            "parkland_college": "Parkland College"
        },
        {
            "lourdes_university": "Lourdes University"
        },
        {
            "san_joaquin_delta_college": "San Joaquin Delta College"
        },
        {
            "thomas_college_": "thomas college "
        },
        {
            "quinsigamond_community_college": "Quinsigamond Community College"
        },
        {
            "delta_college": "Delta College"
        },
        {
            "rogers_state_university": "Rogers State University"
        },
        {
            "usaf_air_university": "USAF Air University"
        },
        {
            "minneapolis_college_of_art_&_design": "Minneapolis College of Art & Design"
        },
        {
            "mercer_county_community_college": "Mercer County Community College"
        },
        {
            "cerritos_college": "Cerritos College"
        },
        {
            "clackamas_community_college": "Clackamas Community College"
        },
        {
            "university_of_wisconsin_superior": "University of Wisconsin Superior"
        },
        {
            "william_carey_university": "William Carey University"
        },
        {
            "benedictine_college": "Benedictine College"
        },
        {
            "prescott_college": "Prescott College"
        },
        {
            "doane_university": "Doane University"
        },
        {
            "mercyhurst_university": "Mercyhurst University"
        },
        {
            "warren_wilson_college": "Warren Wilson College"
        },
        {
            "wayne_state_college": "Wayne State College"
        },
        {
            "saddleback_college": "Saddleback College"
        },
        {
            "mount_san_antonio_college": "Mount San Antonio College"
        },
        {
            "austin_college": "Austin College"
        },
        {
            "collin_college": "Collin College"
        },
        {
            "wallace_community_college_dothan": "Wallace Community College Dothan"
        },
        {
            "chaffey_college": "Chaffey College"
        },
        {
            "gavilan_college": "Gavilan College"
        },
        {
            "san_diego_mesa_college": "San Diego Mesa College"
        },
        {
            "county_college_of_morris": "County College of Morris"
        },
        {
            "catawba_college": "Catawba College"
        },
        {
            "glendale_community_college_california": "Glendale Community College California"
        },
        {
            "merced_college": "Merced College"
        },
        {
            "evangel_university_(central_bible_college,_missouri_christian_colleges)": "Evangel University (Central Bible College, Missouri Christian Colleges)"
        },
        {
            "saint_petersburg_college": "Saint Petersburg College"
        },
        {
            "fort_valley_state_university": "Fort Valley State University"
        },
        {
            "community_college_of_baltimore_county": "Community College of Baltimore County"
        },
        {
            "diablo_valley_college": "Diablo Valley College"
        },
        {
            "shoreline_community_college": "Shoreline Community College"
        },
        {
            "queens_university_of_charlotte": "Queens University of Charlotte"
        },
        {
            "sinclair_community_college": "Sinclair Community College"
        },
        {
            "sterling_college_kansas": "Sterling College Kansas"
        },
        {
            "spring_arbor_university": "Spring Arbor University"
        },
        {
            "north_central_college": "North Central College"
        },
        {
            "luther_seminary": "Luther Seminary"
        },
        {
            "piedmont_college": "Piedmont College"
        },
        {
            "otis_college_of_art_&_design": "Otis College of Art & Design"
        },
        {
            "owens_community_college": "Owens Community College"
        },
        {
            "central_arizona_college": "Central Arizona College"
        },
        {
            "lee_college": "Lee College"
        },
        {
            "de_anza_college": "De Anza College"
        },
        {
            "edgewood_college": "Edgewood College"
        },
        {
            "north_seattle_community_college": "North Seattle Community College"
        },
        {
            "santa_fe_college_fl": "Santa Fe College FL"
        },
        {
            "ashworth_college": "Ashworth College"
        },
        {
            "university_of_hawai\u00e2\u20ac\u02dci_college_of_tropical_agriculture_and_human_resources": "University of Hawai\u00e2\u20ac\u02dci College of Tropical Agriculture and Human Resources"
        },
        {
            "anne_arundel_community_college": "Anne Arundel Community College"
        },
        {
            "salem_college": "Salem College"
        },
        {
            "medaille_college": "Medaille College"
        },
        {
            "southern_baptist_theological_seminary": "Southern Baptist Theological Seminary"
        },
        {
            "houghton_college": "Houghton College"
        },
        {
            "alma_college": "Alma College"
        },
        {
            "brandman_university_online": "Brandman University Online"
        },
        {
            "dordt_college": "Dordt College"
        },
        {
            "grossmont_college": "Grossmont College"
        },
        {
            "glendale_community_college_arizona": "Glendale Community College Arizona"
        },
        {
            "lackawanna_college": "Lackawanna College"
        },
        {
            "estrella_mountain_community_college": "Estrella Mountain Community College"
        },
        {
            "centenary_college_of_louisiana": "Centenary College of Louisiana"
        },
        {
            "college_of_southern_idaho": "College of Southern Idaho"
        },
        {
            "lincoln_institute_of_land_policy": "Lincoln Institute of Land Policy"
        },
        {
            "oglala_lakota_college": "Oglala Lakota College"
        },
        {
            "mott_community_college": "Mott Community College"
        },
        {
            "mckendree_university": "McKendree University"
        },
        {
            "edmonds_community_college": "Edmonds Community College"
        },
        {
            "loyola_law_school_los_angeles": "Loyola Law School Los Angeles"
        },
        {
            "northwestern_michigan_college": "Northwestern Michigan College"
        },
        {
            "arkansas_state_university_newport": "Arkansas State University Newport"
        },
        {
            "flagler_college": "Flagler College"
        },
        {
            "palm_beach_state_college": "Palm Beach State College"
        },
        {
            "western_new_mexico_university": "Western New Mexico University"
        },
        {
            "morningside_college": "Morningside College"
        },
        {
            "century_college_usa": "Century College USA"
        },
        {
            "franklin_college": "Franklin College"
        },
        {
            "kapi'olani_community_college": "Kapi'olani Community College"
        },
        {
            "moberly_area_community_college": "Moberly Area Community College"
        },
        {
            "georgetown_college": "Georgetown College"
        },
        {
            "metropolitan_community_college_kansas_city": "Metropolitan Community College Kansas City"
        },
        {
            "california_northstate_university": "California Northstate University"
        },
        {
            "walla_walla_university": "Walla Walla University"
        },
        {
            "muskingum_university": "Muskingum University"
        },
        {
            "phoenix_college": "Phoenix College"
        },
        {
            "lees_mcrae_college": "Lees Mcrae College"
        },
        {
            "grace_college_and_grace_graduate_school": "Grace College and Grace Graduate School"
        },
        {
            "union_college_lincoln_ne": "Union College Lincoln NE"
        },
        {
            "presbyterian_college": "Presbyterian College"
        },
        {
            "mendocino_college": "Mendocino College"
        },
        {
            "minot_state_university": "Minot State University"
        },
        {
            "misericordia_university": "Misericordia University"
        },
        {
            "montgomery_county_community_college": "Montgomery County Community College"
        },
        {
            "jackson_community_college": "Jackson Community College"
        },
        {
            "brenau_university": "Brenau University"
        },
        {
            "northern_essex_community_college": "Northern Essex Community College"
        },
        {
            "national_university_of_natural_medicine": "National University of Natural Medicine"
        },
        {
            "iowa_valley_community_college_district": "Iowa Valley Community College District"
        },
        {
            "bob_jones_university": "Bob Jones University"
        },
        {
            "cuesta_college": "Cuesta College"
        },
        {
            "heritage_university": "Heritage University"
        },
        {
            "san_jacinto_college": "San Jacinto College"
        },
        {
            "regis_college": "Regis College"
        },
        {
            "western_technical_college": "Western Technical College"
        },
        {
            "santa_barbara_city_college": "Santa Barbara City College"
        },
        {
            "scripps_college": "Scripps College"
        },
        {
            "university_of_mary": "University of Mary"
        },
        {
            "life_university": "Life University"
        },
        {
            "colorado_mountain_college": "Colorado Mountain College"
        },
        {
            "geneva_college": "Geneva College"
        },
        {
            "graceland_university": "Graceland University"
        },
        {
            "chemeketa_community_college": "Chemeketa Community College"
        },
        {
            "mcpherson_college": "McPherson College"
        },
        {
            "northwestern_college_iowa": "Northwestern College Iowa"
        },
        {
            "boston_architectural_college": "Boston Architectural College"
        },
        {
            "butler_community_college": "Butler Community College"
        },
        {
            "herzing_university": "Herzing University"
        },
        {
            "des_moines_area_community_college": "Des Moines Area Community College"
        },
        {
            "st_clair_county_community_college": "St Clair County Community College"
        },
        {
            "stevenson_university_(villa_julie_college)": "Stevenson University (Villa Julie College)"
        },
        {
            "san_bernardino_valley_college": "San Bernardino Valley College"
        },
        {
            "salt_lake_community_college": "Salt Lake Community College"
        },
        {
            "talladega_college": "Talladega College"
        },
        {
            "university_of_the_arts_in_philadelphia": "University of the Arts in Philadelphia"
        },
        {
            "northern_new_mexico_community_college": "Northern New Mexico Community College"
        },
        {
            "ohlone_college": "Ohlone College"
        },
        {
            "roberts_wesleyan_college": "Roberts Wesleyan College"
        },
        {
            "hawkeye_community_college": "Hawkeye Community College"
        },
        {
            "umpqua_community_college": "Umpqua Community College"
        },
        {
            "pierce_college": "Pierce College"
        },
        {
            "methodist_university": "Methodist University"
        },
        {
            "spalding_university": "Spalding University"
        },
        {
            "chadron_state_college": "Chadron State College"
        },
        {
            "wake_technical_community_college": "Wake Technical Community College"
        },
        {
            "hebrew_union_college": "Hebrew Union College"
        },
        {
            "huntingdon_college": "Huntingdon College"
        },
        {
            "state_university_of_new_york_suffolk_county_community_college": "State University of New York Suffolk County Community College"
        },
        {
            "pensacola_christian_college": "Pensacola Christian College"
        },
        {
            "rock_valley_college": "Rock Valley College"
        },
        {
            "university_system_of_maryland": "University System of Maryland"
        },
        {
            "dallas_theological_seminary": "Dallas Theological Seminary"
        },
        {
            "brevard_college": "Brevard College"
        },
        {
            "belhaven_university": "Belhaven University"
        },
        {
            "monmouth_college": "Monmouth College"
        },
        {
            "coconino_community_college": "Coconino Community College"
        },
        {
            "concord_university": "Concord University"
        },
        {
            "lee_university": "Lee University"
        },
        {
            "south_texas_college": "South Texas College"
        },
        {
            "northampton_community_college": "Northampton Community College"
        },
        {
            "rose_state_college": "Rose State College"
        },
        {
            "black_hawk_college": "Black Hawk College"
        },
        {
            "mayo_clinic_college_of_medicine_and_science": "Mayo Clinic College of Medicine and Science"
        },
        {
            "pueblo_community_college": "Pueblo Community College"
        },
        {
            "university_of_montevallo": "University of Montevallo"
        },
        {
            "louisiana_college": "Louisiana College"
        },
        {
            "lewis_and_clark_community_college": "Lewis and Clark Community College"
        },
        {
            "anoka_ramsey_community_college": "Anoka Ramsey Community College"
        },
        {
            "university_of_maine_farmington": "University of Maine Farmington"
        },
        {
            "salus_university_(pennsylvania_college_of_optometry)": "Salus University (Pennsylvania College of Optometry)"
        },
        {
            "ave_maria_university": "Ave Maria University"
        },
        {
            "central_texas_college": "Central Texas College"
        },
        {
            "southwestern_college_kansas": "Southwestern College Kansas"
        },
        {
            "delaware_valley_university": "Delaware Valley University"
        },
        {
            "grand_rapids_community_college": "Grand Rapids Community College"
        },
        {
            "whatcom_community_college": "Whatcom Community College"
        },
        {
            "northland_college": "Northland College"
        },
        {
            "south_seattle_community_college": "South Seattle Community College"
        },
        {
            "delaware_county_community_college": "Delaware County Community College"
        },
        {
            "jewish_theological_seminary": "Jewish Theological Seminary"
        },
        {
            "pacific_college_of_oriental_medicine": "Pacific College of Oriental Medicine"
        },
        {
            "front_range_community_college": "Front Range Community College"
        },
        {
            "los_angeles_city_college": "Los Angeles City College"
        },
        {
            "college_of_the_ozarks": "College of the Ozarks"
        },
        {
            "university_of_the_cumberlands": "University of the Cumberlands"
        },
        {
            "immaculata_university": "Immaculata University"
        },
        {
            "state_university_of_new_york_onondaga_community_college": "State University of New York Onondaga Community College"
        },
        {
            "northeast_wisconsin_technical_college": "Northeast Wisconsin Technical College"
        },
        {
            "iowa_lakes_community_college": "Iowa Lakes Community College"
        },
        {
            "loras_college": "Loras College"
        },
        {
            "american_college_of_education": "American College of Education"
        },
        {
            "adrian_college": "Adrian College"
        },
        {
            "alvin_community_college": "Alvin Community College"
        },
        {
            "bethel_university_indiana": "Bethel University Indiana"
        },
        {
            "arizona_western_college": "Arizona Western College"
        },
        {
            "belmont_abbey_college": "Belmont Abbey College"
        },
        {
            "south_university": "South University"
        },
        {
            "brigham_young_university_hawaii": "Brigham Young University Hawaii"
        },
        {
            "pennsylvania_academy_of_the_fine_arts": "Pennsylvania Academy of the Fine Arts"
        },
        {
            "maryville_university": "Maryville university"
        },
        {
            "pierce_college_los_angeles": "Pierce College Los Angeles"
        },
        {
            "saint_thomas_university_miami": "Saint Thomas University Miami"
        },
        {
            "bay_state_college": "Bay State College"
        },
        {
            "athens_state_university": "Athens State University"
        },
        {
            "aurora_university": "Aurora University"
        },
        {
            "indian_river_state_college": "Indian River State College"
        },
        {
            "blackburn_college": "Blackburn College"
        },
        {
            "casper_college": "Casper College"
        },
        {
            "college_of_the_atlantic": "College of the Atlantic"
        },
        {
            "bethune_cookman_university": "Bethune Cookman University"
        },
        {
            "taylor_university": "Taylor University"
        },
        {
            "florida_state_college_at_jacksonville": "Florida State College at Jacksonville"
        },
        {
            "post_university": "Post University"
        },
        {
            "felician_university": "Felician University"
        },
        {
            "labette_community_college": "Labette Community College"
        },
        {
            "rowan_college_at_burlington_county": "Rowan College at Burlington County"
        },
        {
            "albany_state_university": "Albany State University"
        },
        {
            "west_hills_community_college": "West Hills Community College"
        },
        {
            "covenant_college": "Covenant College"
        },
        {
            "tulsa_community_college": "Tulsa Community College"
        },
        {
            "university_of_arkansas_pulaski_technical_college": "University of Arkansas Pulaski Technical College"
        },
        {
            "weatherford_college": "Weatherford College"
        },
        {
            "erie_community_college": "Erie Community College"
        },
        {
            "illinois_central_college": "Illinois Central College"
        },
        {
            "west_los_angeles_college": "West Los Angeles College"
        },
        {
            "oxnard_college": "Oxnard College"
        },
        {
            "porterville_college": "Porterville College"
        },
        {
            "rogue_community_college": "Rogue Community College"
        },
        {
            "american_college_of_nurse_midwives": "American College of Nurse Midwives"
        },
        {
            "montana_state_university_northern": "Montana State University Northern"
        },
        {
            "coastal_alabama_community_college_(jefferson_davis_community_college)": "Coastal Alabama Community College (Jefferson Davis Community College)"
        },
        {
            "peninsula_college": "Peninsula College"
        },
        {
            "schoolcraft_college": "Schoolcraft College"
        },
        {
            "shaw_university": "Shaw University"
        },
        {
            "stephens_college": "Stephens College"
        },
        {
            "great_basin_college": "Great Basin College"
        },
        {
            "pacific_union_college": "Pacific Union College"
        },
        {
            "university_of_charleston": "University of Charleston"
        },
        {
            "mount_hood_community_college": "Mount Hood Community College"
        },
        {
            "wells_college": "Wells College"
        },
        {
            "bryan_college": "Bryan College"
        },
        {
            "colorado_christian_university": "Colorado Christian University"
        },
        {
            "central_state_university": "Central State University"
        },
        {
            "roane_state_community_college": "Roane State Community College"
        },
        {
            "sessions_college_for_professional_design": "Sessions College for Professional Design"
        },
        {
            "state_university_of_new_york_delhi": "State University of New York Delhi"
        },
        {
            "landmark_college": "Landmark College"
        },
        {
            "defense_language_institute_foreign_language_center": "Defense Language Institute Foreign Language Center"
        },
        {
            "state_university_of_new_york_westchester_commmunity_college": "State University of New York Westchester Commmunity College"
        },
        {
            "caldwell_university": "Caldwell University"
        },
        {
            "bloomfield_college": "Bloomfield College"
        },
        {
            "palmer_college_of_chiropractic": "Palmer College of Chiropractic"
        },
        {
            "desales_university": "Desales University"
        },
        {
            "moorpark_college": "Moorpark College"
        },
        {
            "inver_hills_community_college": "Inver Hills Community College"
        },
        {
            "thomas_edison_state_university": "Thomas Edison State University"
        },
        {
            "solano_community_college": "Solano Community College"
        },
        {
            "el_paso_community_college": "El Paso Community College"
        },
        {
            "western_nevada_college": "Western Nevada College"
        },
        {
            "maharishi_international_university": "Maharishi International University"
        },
        {
            "lakeland_university_in_wisconsin": "Lakeland University in Wisconsin"
        },
        {
            "santa_ana_college": "Santa Ana College"
        },
        {
            "paul_smith's_college": "Paul Smith's College"
        },
        {
            "camden_county_college": "Camden County College"
        },
        {
            "clover_park_technical_college": "Clover Park Technical College"
        },
        {
            "trident_technical_college": "Trident Technical College"
        },
        {
            "oglethorpe_university": "Oglethorpe University"
        },
        {
            "central_oregon_community_college": "Central Oregon Community College"
        },
        {
            "united_states_merchant_marine_academy": "United States Merchant Marine Academy"
        },
        {
            "bennett_college": "Bennett College"
        },
        {
            "state_university_of_new_york_orange_county_community_college": "State University of New York Orange County Community College"
        },
        {
            "pikes_peak_community_college": "Pikes Peak Community College"
        },
        {
            "las_positas_college": "Las Positas College"
        },
        {
            "lancaster_bible_college_and_graduate_school": "Lancaster Bible College and Graduate School"
        },
        {
            "eastern_nazarene_college": "Eastern Nazarene College"
        },
        {
            "birmingham_southern_college": "Birmingham Southern College"
        },
        {
            "hampden_sydney_college": "Hampden Sydney College"
        },
        {
            "foothill-de_anza_community_college_district": "Foothill-De Anza Community College District"
        },
        {
            "keuka_college": "Keuka College"
        },
        {
            "gardner_webb_university": "Gardner Webb University"
        },
        {
            "northwest_mississippi_community_college": "Northwest Mississippi Community College"
        },
        {
            "arapahoe_community_college": "Arapahoe Community College"
        },
        {
            "capital_community_college": "Capital Community College"
        },
        {
            "william_jessup_university": "William Jessup University"
        },
        {
            "touro_law_center": "Touro Law Center"
        },
        {
            "rio_salado_college": "Rio Salado College"
        },
        {
            "university_of_the_ozarks": "University of the Ozarks"
        },
        {
            "wesley_college_delaware": "Wesley College Delaware"
        },
        {
            "san_diego_city_college": "San Diego City College"
        },
        {
            "university_of_dubuque": "University of Dubuque"
        },
        {
            "durham_technical_community_college": "Durham Technical Community College"
        },
        {
            "olympic_college": "Olympic College"
        },
        {
            "touro_university": "Touro University"
        },
        {
            "bluefield_college": "Bluefield College"
        },
        {
            "spokane_community_college": "Spokane Community College"
        },
        {
            "frederick_community_college": "Frederick Community College"
        },
        {
            "concordia_college_moorhead": "Concordia College Moorhead"
        },
        {
            "albertus_magnus_college": "Albertus Magnus College"
        },
        {
            "santiago_canyon_college": "Santiago Canyon College"
        },
        {
            "texas_a&m_university_system": "Texas A&M University System"
        },
        {
            "gulf_coast_community_college": "Gulf Coast Community College"
        },
        {
            "york_technical_college": "York Technical College"
        },
        {
            "columbia_college_sc": "Columbia College SC"
        },
        {
            "ringling_college_of_art_&_design": "Ringling College of Art & Design"
        },
        {
            "randolph_college": "Randolph College"
        },
        {
            "san_francisco_art_institute": "San Francisco Art Institute"
        },
        {
            "letourneau_university": "Letourneau University"
        },
        {
            "denver_center_for_performing_arts": "Denver Center for Performing Arts"
        },
        {
            "southern_college_of_optometry": "Southern College of Optometry"
        },
        {
            "thiel_college": "Thiel College"
        },
        {
            "northland_community_and_technical_college": "Northland Community and Technical College"
        },
        {
            "hartnell_college": "Hartnell College"
        },
        {
            "lewis_&_clark_state_college": "Lewis & Clark State College"
        },
        {
            "community_college_of_denver": "Community College of Denver"
        },
        {
            "richland_community_college": "Richland Community College"
        },
        {
            "mississippi_valley_state_university": "Mississippi Valley State University"
        },
        {
            "buena_vista_university": "Buena Vista University"
        },
        {
            "cornerstone_university": "Cornerstone University"
        },
        {
            "newberry_college": "Newberry College"
        },
        {
            "gwinnett_technical_college": "Gwinnett Technical College"
        },
        {
            "state_college_of_florida_(manatee_community_college)": "State College of Florida (Manatee Community College)"
        },
        {
            "ca\u00e3\u00b1ada_college": "Ca\u00c3\u00b1ada College"
        },
        {
            "crown_college": "Crown College"
        },
        {
            "illinois_valley_community_college": "Illinois Valley Community College"
        },
        {
            "bluegrass_community_and_technical_college": "Bluegrass Community and Technical College"
        },
        {
            "community_college_of_vermont": "Community College of Vermont"
        },
        {
            "coe_college": "Coe College"
        },
        {
            "odessa_college": "Odessa College"
        },
        {
            "mount_san_jacinto_college": "Mount San Jacinto College"
        },
        {
            "holyoke_community_college": "Holyoke Community College"
        },
        {
            "dalton_state_college": "Dalton State College"
        },
        {
            "bismarck_state_college": "Bismarck State College"
        },
        {
            "monterey_peninsula_college": "Monterey Peninsula College"
        },
        {
            "lubbock_christian_university": "Lubbock Christian University"
        },
        {
            "holy_family_university": "Holy Family University"
        },
        {
            "brescia_university": "Brescia University"
        },
        {
            "central_college": "Central College"
        },
        {
            "alice_lloyd_college": "Alice Lloyd College"
        },
        {
            "milwaukee_institute_of_art_and_design": "Milwaukee Institute of Art and Design"
        },
        {
            "spring_hill_college": "Spring Hill College"
        },
        {
            "modesto_junior_college": "Modesto Junior College"
        },
        {
            "fashion_institute_of_design_&_merchandising_los_angeles": "Fashion Institute of Design & Merchandising Los Angeles"
        },
        {
            "gordon-conwell_theological_seminary": "Gordon-Conwell Theological Seminary"
        },
        {
            "salish_kootenai_college": "Salish Kootenai College"
        },
        {
            "adams_state_university": "Adams State University"
        },
        {
            "northwest_arkansas_community_college": "Northwest Arkansas Community College"
        },
        {
            "warner_pacific_college": "Warner Pacific College"
        },
        {
            "hocking_college": "Hocking College"
        },
        {
            "san_juan_college": "San Juan College"
        },
        {
            "laney_college": "Laney College"
        },
        {
            "state_university_of_new_york_morrisville_state_college": "State University of New York Morrisville State College"
        },
        {
            "columbus_technical_college": "Columbus Technical College"
        },
        {
            "oklahoma_panhandle_state_university": "Oklahoma Panhandle State University"
        },
        {
            "paul_quinn_college": "Paul Quinn College"
        },
        {
            "community_college_of_rhode_island": "Community College of Rhode Island"
        },
        {
            "university_of_minnesota_rochester": "University of Minnesota Rochester"
        },
        {
            "concordia_university_nebraska": "Concordia University Nebraska"
        },
        {
            "southeastern_university_florida": "Southeastern University Florida"
        },
        {
            "manchester_university": "Manchester University"
        },
        {
            "ursuline_college": "Ursuline College"
        },
        {
            "keystone_college": "Keystone College"
        },
        {
            "sage_colleges": "Sage Colleges"
        },
        {
            "dickinson_state_university": "Dickinson State University"
        },
        {
            "sterling_college_vermont": "Sterling College Vermont"
        },
        {
            "tidewater_community_college": "Tidewater Community College"
        },
        {
            "pensacola_state_college_(pensacola_junior_college)": "Pensacola State College (Pensacola Junior College)"
        },
        {
            "judson_university_christian_college": "Judson University Christian College"
        },
        {
            "centralia_college": "Centralia College"
        },
        {
            "christendom_college": "Christendom College"
        },
        {
            "cleveland_institute_of_art": "Cleveland Institute of Art"
        },
        {
            "north_central_university": "North Central University"
        },
        {
            "university_system_of_new_hampshire": "University System of New Hampshire"
        },
        {
            "hudson_valley_community_college": "Hudson Valley Community College"
        },
        {
            "eureka_college": "Eureka College"
        },
        {
            "bethany_college_bethany": "Bethany College Bethany"
        },
        {
            "bunker_hill_community_college": "Bunker Hill Community College"
        },
        {
            "lycoming_college": "Lycoming College"
        },
        {
            "columbus_school_of_law": "Columbus School of Law"
        },
        {
            "lincoln_land_community_college": "Lincoln Land Community College"
        },
        {
            "cazenovia_college": "Cazenovia College"
        },
        {
            "peru_state_college": "Peru State College"
        },
        {
            "universal_technical_institute": "Universal Technical Institute"
        },
        {
            "heartland_community_college": "Heartland Community College"
        },
        {
            "hope_international_university": "Hope International University"
        },
        {
            "new_england_conservatory_of_music": "New England Conservatory of Music"
        },
        {
            "chestnut_hill_college": "Chestnut Hill College"
        },
        {
            "lakeland_community_college_ohio": "Lakeland Community College Ohio"
        },
        {
            "olivet_college": "Olivet College"
        },
        {
            "j_sargeant_reynolds_community_college": "J Sargeant Reynolds Community College"
        },
        {
            "patrick_henry_college": "Patrick Henry College"
        },
        {
            "vaughn_college_of_aeronautics_and_technology": "Vaughn College of Aeronautics and Technology"
        },
        {
            "capitol_technology_university": "Capitol Technology University"
        },
        {
            "cape_fear_community_college": "Cape Fear Community College"
        },
        {
            "northeastern_oklahoma_agricultural_and_mechanics_college_neo_a&m_college": "Northeastern Oklahoma Agricultural and Mechanics College NEO A&M College"
        },
        {
            "central_pennsylvania_college": "Central Pennsylvania College"
        },
        {
            "central_lakes_college_brainerd": "Central Lakes College Brainerd"
        },
        {
            "minneapolis_community_and_technical_college": "Minneapolis Community and Technical College"
        },
        {
            "north_carolina_wesleyan_college": "North Carolina Wesleyan College"
        },
        {
            "minnesota_state_community_and_technical_college": "Minnesota State Community and Technical College"
        },
        {
            "horry_georgetown_technical_college": "Horry Georgetown Technical College"
        },
        {
            "defiance_college": "Defiance College"
        },
        {
            "faulkner_university": "Faulkner University"
        },
        {
            "fox_valley_technical_college": "Fox Valley Technical College"
        },
        {
            "hagerstown_community_college": "Hagerstown Community College"
        },
        {
            "mission_college_santa_clara_california": "Mission College Santa Clara California"
        },
        {
            "our_lady_of_the_lake_university": "Our Lady of the Lake University"
        },
        {
            "william_penn_university": "William Penn University"
        },
        {
            "ottawa_university": "Ottawa University"
        },
        {
            "ouachita_baptist_university": "Ouachita Baptist University"
        },
        {
            "clarkson_college": "Clarkson College"
        },
        {
            "cochise_college": "Cochise College"
        },
        {
            "university_of_science_&_arts_of_oklahoma": "University of Science & Arts of Oklahoma"
        },
        {
            "principia_college": "Principia College"
        },
        {
            "henderson_state_university": "Henderson State University"
        },
        {
            "marian_university_wisconsin": "Marian University Wisconsin"
        },
        {
            "west_georgia_technical_college": "West Georgia Technical College"
        },
        {
            "langston_university": "Langston University"
        },
        {
            "maryland_university_of_integrative_health": "Maryland University of Integrative Health"
        },
        {
            "crowder_college": "Crowder College"
        },
        {
            "riverside_community_college": "Riverside Community College"
        },
        {
            "city_university_of_seattle": "City University of Seattle"
        },
        {
            "mitchell_hamline_school_of_law": "Mitchell Hamline School of Law"
        },
        {
            "big_bend_community_college": "Big Bend Community College"
        },
        {
            "malone_university": "Malone University"
        },
        {
            "edward_waters_college": "Edward Waters College"
        },
        {
            "texarkana_college": "Texarkana College"
        },
        {
            "columbia_southern_university": "Columbia Southern University"
        },
        {
            "piedmont_technical_college": "Piedmont Technical College"
        },
        {
            "lansing_community_college": "Lansing Community College"
        },
        {
            "tusculum_university": "Tusculum University"
        },
        {
            "curtis_institute_of_music": "Curtis Institute of Music"
        },
        {
            "blue_mountain_community_college": "Blue Mountain Community College"
        },
        {
            "concordia_university_texas": "Concordia University Texas"
        },
        {
            "pacific_oaks_college": "Pacific Oaks College"
        },
        {
            "el_camino_college": "El Camino College"
        },
        {
            "glenville_state_college": "Glenville State College"
        },
        {
            "hudson_county_community_college": "Hudson County Community College"
        },
        {
            "hilbert_college": "Hilbert College"
        },
        {
            "los_angeles_harbor_college": "Los Angeles Harbor College"
        },
        {
            "bethel_university": "Bethel University"
        },
        {
            "williamson_christian_college": "Williamson Christian College"
        },
        {
            "elizabeth_city_state_university": "Elizabeth City State University"
        },
        {
            "st_andrews_university_(saint_andrews_presbyterian_college)": "St Andrews University (Saint Andrews Presbyterian College)"
        },
        {
            "college_of_the_canyons": "College of the Canyons"
        },
        {
            "gaston_college": "Gaston College"
        },
        {
            "state_fair_community_college": "State Fair Community College"
        },
        {
            "oakland_city_university": "Oakland City University"
        },
        {
            "saint_martin's_university": "Saint Martin's University"
        },
        {
            "cerro_coso_community_college": "Cerro Coso Community College"
        },
        {
            "reedley_college": "Reedley College"
        },
        {
            "greenfield_community_college": "Greenfield Community College"
        },
        {
            "hannibal_lagrange_university": "Hannibal Lagrange University"
        },
        {
            "american_college": "American College"
        },
        {
            "froedtert_medical_college": "Froedtert Medical College"
        },
        {
            "washtenaw_community_college": "Washtenaw Community College"
        },
        {
            "university_of_cincinnati_blue_ash_college_(raymond_walters_college)": "University of Cincinnati Blue Ash College (Raymond Walters College)"
        },
        {
            "bergen_community_college": "Bergen Community College"
        },
        {
            "martin_luther_college": "Martin Luther College"
        },
        {
            "great_bay_community_college": "Great Bay Community College"
        },
        {
            "musicians_institute": "Musicians Institute"
        },
        {
            "notre_dame_of_maryland_university": "Notre Dame of Maryland University"
        },
        {
            "university_of_maine_augusta": "University of Maine Augusta"
        },
        {
            "simpson_college_indianola": "Simpson College Indianola"
        },
        {
            "mount_aloysius_college": "Mount Aloysius College"
        },
        {
            "napa_valley_college": "Napa Valley College"
        },
        {
            "manor_college": "Manor College"
        },
        {
            "honolulu_community_college": "Honolulu Community College"
        },
        {
            "upper_iowa_university": "Upper Iowa University"
        },
        {
            "texas_state_technical_college": "Texas State Technical College"
        },
        {
            "lawson_state_community_college": "Lawson State Community College"
        },
        {
            "manhattan_school_of_music": "Manhattan School of Music"
        },
        {
            "campbellsville_university": "Campbellsville University"
        },
        {
            "alfred_state_college_suny_college_of_technology": "Alfred State College SUNY College of Technology"
        },
        {
            "university_of_the_southwest": "University of the Southwest"
        },
        {
            "milwaukee_area_technical_college": "Milwaukee Area Technical College"
        },
        {
            "bevill_state_community_college": "Bevill State Community College"
        },
        {
            "wilberforce_university": "Wilberforce University"
        },
        {
            "victoria_college": "Victoria College"
        },
        {
            "five_towns_college": "Five Towns College"
        },
        {
            "dunwoody_college_of_technology": "Dunwoody College of Technology"
        },
        {
            "southern_virginia_university": "Southern Virginia University"
        },
        {
            "black_hills_state_university": "Black Hills State University"
        },
        {
            "concordia_theological_seminary_fort_wayne": "Concordia Theological Seminary Fort Wayne"
        },
        {
            "university_of_massachusetts_system": "University of Massachusetts System"
        },
        {
            "urbana_university": "Urbana University"
        },
        {
            "mount_st._joseph_university": "Mount St. Joseph University"
        },
        {
            "gadsden_state_community_college": "Gadsden State Community College"
        },
        {
            "lyon_college": "Lyon College"
        },
        {
            "grambling_state_university": "Grambling State University"
        },
        {
            "villa_maria_college_buffalo": "Villa Maria College Buffalo"
        },
        {
            "chipola_college": "Chipola College"
        },
        {
            "transylvania_university": "Transylvania University"
        },
        {
            "reading_area_community_college": "Reading Area Community College"
        },
        {
            "cranbrook_academy_of_art": "Cranbrook Academy of Art"
        },
        {
            "viterbo_university": "Viterbo University"
        },
        {
            "missouri_southern_state_university": "Missouri Southern State University"
        },
        {
            "new_hampshire_technical_institute": "New Hampshire Technical Institute"
        },
        {
            "bard_college_at_simon's_rock": "Bard College at Simon's Rock"
        },
        {
            "trinity_washington_university": "Trinity Washington University"
        },
        {
            "housatonic_community_college": "Housatonic Community College"
        },
        {
            "saint_francis_university": "Saint Francis University"
        },
        {
            "potomac_state_college_west_virginia_university": "Potomac State College West Virginia University"
        },
        {
            "westminster_theological_seminary": "Westminster Theological Seminary"
        },
        {
            "greensboro_college": "Greensboro College"
        },
        {
            "belmont_(technical)_college": "Belmont (Technical) College"
        },
        {
            "morton_college": "Morton College"
        },
        {
            "truckee_meadows_community_college": "Truckee Meadows Community College"
        },
        {
            "hardin_simmons_university": "Hardin Simmons University"
        },
        {
            "georgian_court_university": "Georgian Court University"
        },
        {
            "suny_college_of_optometry": "SUNY College of Optometry"
        },
        {
            "clarke_university": "Clarke University"
        },
        {
            "bethany_college_linsdborg": "Bethany College Linsdborg"
        },
        {
            "nevada_state_college": "Nevada State College"
        },
        {
            "ozarks_technical_community_college": "Ozarks Technical Community College"
        },
        {
            "college_of_southern_maryland": "College of Southern Maryland"
        },
        {
            "highland_community_college_northeast_kansas": "Highland Community College Northeast Kansas"
        },
        {
            "american_conservatory_theater": "American Conservatory Theater"
        },
        {
            "bacone_college": "Bacone College"
        },
        {
            "mid_plains_community_college": "Mid Plains Community College"
        },
        {
            "new_river_community_college": "New River Community College"
        },
        {
            "daytona_state_college_(daytona_beach_community_college)": "Daytona State College (Daytona Beach Community College)"
        },
        {
            "united_states_sports_academy": "United States Sports Academy"
        },
        {
            "augusta_technical_college": "Augusta Technical College"
        },
        {
            "georgia_southwestern_state_university": "Georgia Southwestern State University"
        },
        {
            "blinn_college": "Blinn College"
        },
        {
            "trine_university_(tri_state_university)": "Trine University (Tri State University)"
        },
        {
            "treasure_valley_community_college": "Treasure Valley Community College"
        },
        {
            "millsaps_college": "Millsaps College"
        },
        {
            "north_central_state_college": "North Central State College"
        },
        {
            "oklahoma_city_community_college": "Oklahoma City Community College"
        },
        {
            "rivier_university": "Rivier University"
        },
        {
            "state_university_of_new_york_college_of_technology_canton": "State University of New York College of Technology Canton"
        },
        {
            "university_of_sioux_falls": "University of Sioux Falls"
        },
        {
            "bethel_college_kansas": "Bethel College Kansas"
        },
        {
            "harrisburg_area_community_college": "Harrisburg Area Community College"
        },
        {
            "american_river_college": "American River College"
        },
        {
            "emory_&_henry_college": "Emory & Henry College"
        },
        {
            "state_university_of_new_york_jefferson_community_college": "State University of New York Jefferson Community College"
        },
        {
            "manchester_community_college_nh": "Manchester Community College NH"
        },
        {
            "pennsylvania_highlands_community_college": "Pennsylvania Highlands Community College"
        },
        {
            "university_of_mount_union": "University of Mount Union"
        },
        {
            "southwestern_baptist_theological_seminary": "Southwestern Baptist Theological Seminary"
        },
        {
            "university_of_silicon_valley": "University of Silicon Valley"
        },
        {
            "west_liberty_university": "West Liberty University"
        },
        {
            "columbus_college_of_art_&_design": "Columbus College of Art & Design"
        },
        {
            "tougaloo_college": "Tougaloo College"
        },
        {
            "unitek_college": "UNITEK College"
        },
        {
            "curry_college": "Curry College"
        },
        {
            "shawnee_state_university": "Shawnee State University"
        },
        {
            "springfield_technical_community_college": "Springfield Technical Community College"
        },
        {
            "montreat_college": "Montreat College"
        },
        {
            "nicolet_college": "Nicolet College"
        },
        {
            "galen_college_of_nursing": "Galen College of Nursing"
        },
        {
            "community_college_of_allegheny_county": "Community College of Allegheny County"
        },
        {
            "clovis_community_college": "Clovis Community College"
        },
        {
            "triton_college": "Triton College"
        },
        {
            "california_state_university_california_maritime_academy": "California State University California Maritime Academy"
        },
        {
            "carrington_college_(apollo_college)": "Carrington College (Apollo College)"
        },
        {
            "east_central_university": "East Central University"
        },
        {
            "erikson_institute": "Erikson Institute"
        },
        {
            "wor_wic_community_college": "Wor Wic Community College"
        },
        {
            "highland_community_college_northwest_illinois": "Highland Community College Northwest Illinois"
        },
        {
            "college_for_creative_studies": "College for Creative Studies"
        },
        {
            "john_f_kennedy_university": "John F Kennedy University"
        },
        {
            "elgin_community_college": "Elgin Community College"
        },
        {
            "rutgers_the_state_university_of_new_jersey_new_brunswick": "Rutgers The State University of New Jersey New Brunswick"
        },
        {
            "louisburg_college": "Louisburg College"
        },
        {
            "grays_harbor_college": "Grays Harbor College"
        },
        {
            "peralta_colleges": "Peralta Colleges"
        },
        {
            "saint_joseph's_college_of_maine": "Saint Joseph's College of Maine"
        },
        {
            "emmanuel_college_georgia": "Emmanuel College Georgia"
        },
        {
            "college_of_the_mainland": "College of the Mainland"
        },
        {
            "mary_baldwin_university": "Mary Baldwin University"
        },
        {
            "university_of_pittsburgh_johnstown": "University of Pittsburgh Johnstown"
        },
        {
            "rio_hondo_community_college": "Rio Hondo Community College"
        },
        {
            "kentucky_mountain_bible_college": "Kentucky Mountain Bible College"
        },
        {
            "oklahoma_baptist_university": "Oklahoma Baptist University"
        },
        {
            "adler_school_of_professional_psychology": "Adler School of Professional Psychology"
        },
        {
            "charleston_southern_university": "Charleston Southern University"
        },
        {
            "montana_state_university_great_falls_college": "Montana State University Great Falls College"
        },
        {
            "south_texas_college_of_law": "South Texas College of Law"
        },
        {
            "joliet_junior_college": "Joliet Junior College"
        },
        {
            "ferrum_college": "Ferrum College"
        },
        {
            "north_central_michigan_college": "North Central Michigan College"
        },
        {
            "pellissippi_state_technical_community_college": "Pellissippi State Technical Community College"
        },
        {
            "feather_river_college": "Feather River College"
        },
        {
            "suny_cobleskill": "SUNY Cobleskill"
        },
        {
            "mineral_area_college": "Mineral Area College"
        },
        {
            "life_pacific_college": "Life Pacific College"
        },
        {
            "sierra_nevada_college": "Sierra Nevada College"
        },
        {
            "moraine_valley_community_college": "Moraine Valley Community College"
        },
        {
            "state_university_of_new_york_dutchess_community_college": "State University of New York Dutchess Community College"
        },
        {
            "cleveland_community_college": "Cleveland Community College"
        },
        {
            "pacific_northwest_college_of_art": "Pacific Northwest College of Art"
        },
        {
            "raritan_valley_community_college": "Raritan Valley Community College"
        },
        {
            "institute_of_world_politics": "Institute of World Politics"
        },
        {
            "state_university_of_new_york_rockland_community_college": "State University of New York Rockland Community College"
        },
        {
            "southwestern_law_school": "Southwestern Law School"
        },
        {
            "east_los_angeles_college": "East Los Angeles College"
        },
        {
            "oakwood_university": "Oakwood University"
        },
        {
            "trenholm_state_technical_college": "Trenholm State Technical College"
        },
        {
            "gwynedd_mercy_university": "Gwynedd Mercy University"
        },
        {
            "eastern_florida_state_college_(brevard_community_college)": "Eastern Florida State College (Brevard Community College)"
        },
        {
            "sul_ross_state_university": "Sul Ross State University"
        },
        {
            "klamath_community_college": "Klamath Community College"
        },
        {
            "lorain_county_community_college": "Lorain County Community College"
        },
        {
            "los_angeles_trade_technical_college": "Los Angeles Trade Technical College"
        },
        {
            "clark_state_community_college": "Clark State Community College"
        },
        {
            "southern_arkansas_university_tech": "Southern Arkansas University Tech"
        },
        {
            "southeastern_baptist_theological_seminary": "Southeastern Baptist Theological Seminary"
        },
        {
            "huntington_university": "Huntington University"
        },
        {
            "institute_of_american_indian_and_alaska_native_culture": "Institute of American Indian and Alaska Native Culture"
        },
        {
            "cardinal_stritch_university": "Cardinal Stritch University"
        },
        {
            "school_for_field_studies": "School for Field Studies"
        },
        {
            "martin_university": "Martin University"
        },
        {
            "lamar_state_college_port_arthur": "Lamar State College Port Arthur"
        },
        {
            "lincoln_tech": "Lincoln Tech"
        },
        {
            "bryant_&_stratton_college": "Bryant & Stratton College"
        },
        {
            "cabrini_university": "Cabrini University"
        },
        {
            "tallahassee_community_college": "Tallahassee Community College"
        },
        {
            "johnson_c_smith_university": "Johnson C Smith University"
        },
        {
            "concordia_seminary_saint_louis": "Concordia Seminary Saint Louis"
        },
        {
            "maine_college_of_art": "Maine College of Art"
        },
        {
            "snow_college": "Snow College"
        },
        {
            "benedict_college": "Benedict College"
        },
        {
            "hazard_community_and_technical_college": "Hazard Community and Technical College"
        },
        {
            "bay_path_university": "Bay Path University"
        },
        {
            "southwest_texas_junior_college": "Southwest Texas Junior College"
        },
        {
            "university_of_arkansas_system": "University of Arkansas System"
        },
        {
            "delgado_community_college": "Delgado Community College"
        },
        {
            "fontbonne_university": "Fontbonne University"
        },
        {
            "southern_west_virginia_community_and_technical_college": "Southern West Virginia Community and Technical College"
        },
        {
            "dillard_university": "Dillard University"
        },
        {
            "western_dakota_technical_institute": "Western Dakota Technical Institute"
        },
        {
            "college_of_san_mateo": "College of San Mateo"
        },
        {
            "university_of_minnesota_crookston": "University of Minnesota Crookston"
        },
        {
            "toccoa_falls_college": "Toccoa Falls College"
        },
        {
            "northern_vermont_university": "Northern Vermont University"
        },
        {
            "antelope_valley_college": "Antelope Valley College"
        },
        {
            "volunteer_state_community_college": "Volunteer State Community College"
        },
        {
            "texas_a&m_university_texarkana": "Texas A&M University Texarkana"
        },
        {
            "albany_law_school": "Albany Law School"
        },
        {
            "carlow_university": "Carlow University"
        },
        {
            "los_angeles_valley_college": "Los Angeles Valley College"
        },
        {
            "new_brunswick_theological_seminary": "New Brunswick Theological Seminary"
        },
        {
            "american_baptist_college": "American Baptist College"
        },
        {
            "college_of_the_redwoods": "College of the Redwoods"
        },
        {
            "wisconsin_lutheran_college": "Wisconsin Lutheran College"
        },
        {
            "asbury_university": "Asbury University"
        },
        {
            "union_theological_seminary": "Union Theological Seminary"
        },
        {
            "mohawk_valley_community_college_utica_branch": "Mohawk Valley Community College Utica Branch"
        },
        {
            "united_states_coast_guard_academy": "United States Coast Guard Academy"
        },
        {
            "bladen_community_college": "Bladen Community College"
        },
        {
            "lenoir_rhyne_university": "Lenoir Rhyne University"
        },
        {
            "maccormac_college": "MacCormac College"
        },
        {
            "davis_college_new_york": "Davis College New York"
        },
        {
            "ocean_county_college": "Ocean County College"
        },
        {
            "university_of_arkansas_monticello": "University of Arkansas Monticello"
        },
        {
            "north_idaho_college": "North Idaho College"
        },
        {
            "broome_community_college_suny": "Broome Community College SUNY"
        },
        {
            "saint_augustine's_university": "Saint Augustine's University"
        },
        {
            "washburn_university_school_of_law": "Washburn University School of Law"
        },
        {
            "new_mexico_military_institute": "New Mexico Military Institute"
        },
        {
            "national_university_of_health_sciences": "National University of Health Sciences"
        },
        {
            "college_of_the_desert": "College of the Desert"
        },
        {
            "asheville_buncombe_technical_community_college": "Asheville Buncombe Technical Community College"
        },
        {
            "milligan_college": "Milligan College"
        },
        {
            "hinds_community_college": "Hinds Community College"
        },
        {
            "master's_university": "Master's University"
        },
        {
            "carl_albert_state_college": "Carl Albert State College"
        },
        {
            "southwest_tennessee_community_college_memphis": "Southwest Tennessee Community College Memphis"
        },
        {
            "midlands_technical_college": "Midlands Technical College"
        },
        {
            "neumann_university": "Neumann University"
        },
        {
            "fairmont_state_university": "Fairmont State University"
        },
        {
            "richard_bland_college_of_the_college_of_william_and_mary": "Richard Bland College of the College of William and Mary"
        },
        {
            "william_jewell_college": "William Jewell College"
        },
        {
            "wayland_baptist_university": "Wayland Baptist University"
        },
        {
            "franklin_pierce_university": "Franklin Pierce University"
        },
        {
            "logan_university": "Logan University"
        },
        {
            "muskegon_community_college": "Muskegon Community College"
        },
        {
            "mcmurry_university": "McMurry University"
        },
        {
            "northwest_technical_institute": "Northwest Technical Institute"
        },
        {
            "colby_sawyer_college": "Colby Sawyer College"
        },
        {
            "college_of_the_sequoias": "College of the Sequoias"
        },
        {
            "finlandia_university": "Finlandia University"
        },
        {
            "mount_saint_mary_college": "Mount Saint Mary College"
        },
        {
            "southwest_baptist_university": "Southwest Baptist University"
        },
        {
            "saint_joseph's_college_new_york": "Saint Joseph's College New York"
        },
        {
            "laredo_community_college": "Laredo Community College"
        },
        {
            "grantham_university": "Grantham University"
        },
        {
            "stillman_college": "Stillman College"
        },
        {
            "master's_seminary": "Master's Seminary"
        },
        {
            "white_mountains_community_college": "White Mountains Community College"
        },
        {
            "bethany_lutheran_college": "Bethany Lutheran College"
        },
        {
            "columbia_international_university": "Columbia International University"
        },
        {
            "helena_college": "Helena College"
        },
        {
            "notre_dame_de_namur_university": "Notre Dame de Namur University"
        },
        {
            "northwest_technical_college": "Northwest Technical College"
        },
        {
            "abraham_baldwin_agricultural_college": "Abraham Baldwin Agricultural College"
        },
        {
            "georgia_military_college": "Georgia Military College"
        },
        {
            "tabor_college_kansas": "Tabor College Kansas"
        },
        {
            "thomas_aquinas_college": "Thomas Aquinas College"
        },
        {
            "oregon_coast_community_college": "Oregon Coast Community College"
        },
        {
            "cleveland_institute_of_music": "Cleveland Institute of Music"
        },
        {
            "lassen_community_college": "Lassen Community College"
        },
        {
            "mount_saint_mary's_university_los_angeles": "Mount Saint Mary's University Los Angeles"
        },
        {
            "manchester_community_college_ct": "Manchester Community College CT"
        },
        {
            "castleton_university": "Castleton University"
        },
        {
            "cincinnati_christian_university": "Cincinnati Christian University"
        },
        {
            "freed_hardeman_university": "Freed Hardeman University"
        },
        {
            "genesee_community_college": "Genesee Community College"
        },
        {
            "graduate_theological_union": "Graduate Theological Union"
        },
        {
            "los_angeles_film_school": "Los Angeles Film School"
        },
        {
            "marylhurst_university": "Marylhurst University"
        },
        {
            "midamerica_nazarene_university": "Midamerica Nazarene University"
        },
        {
            "pacifica_graduate_institute": "Pacifica Graduate Institute"
        },
        {
            "maine_maritime_academy": "Maine Maritime Academy"
        },
        {
            "san_francisco_conservatory_of_music": "San Francisco Conservatory of Music"
        },
        {
            "tiffin_university": "Tiffin University"
        },
        {
            "university_of_mobile": "University of Mobile"
        },
        {
            "mount_mercy_university": "Mount Mercy University"
        },
        {
            "atlantic_international_university": "Atlantic International University"
        },
        {
            "trevecca_nazarene_university": "Trevecca Nazarene University"
        },
        {
            "chesapeake_college": "Chesapeake College"
        },
        {
            "kalamazoo_valley_community_college": "Kalamazoo Valley Community College"
        },
        {
            "georgia_piedmont_technical_college_(dekalb_technical_college)": "Georgia Piedmont Technical College (Dekalb Technical College)"
        },
        {
            "los_angeles_mission_college": "Los Angeles Mission College"
        },
        {
            "converse_college": "Converse College"
        },
        {
            "samuel_merritt_university": "Samuel Merritt University"
        },
        {
            "friends_university": "Friends University"
        },
        {
            "oakland_community_college": "Oakland Community College"
        },
        {
            "rocky_mountain_college": "Rocky Mountain College"
        },
        {
            "atlantic_cape_community_college": "Atlantic Cape Community College"
        },
        {
            "chattanooga_state_technical_community_college": "Chattanooga State Technical Community College"
        },
        {
            "cornish_college_of_the_arts": "Cornish College of the Arts"
        },
        {
            "thomas_m_cooley_law_school": "Thomas M Cooley Law School"
        },
        {
            "missouri_baptist_university": "Missouri Baptist University"
        },
        {
            "technical_college_of_the_lowcountry": "Technical College of the Lowcountry"
        },
        {
            "trident_(tuiu_touro)_university": "Trident (TUIU Touro) University"
        },
        {
            "massachusetts_maritime_academy": "Massachusetts Maritime Academy"
        },
        {
            "wilkes_community_college": "Wilkes Community College"
        },
        {
            "tyler_junior_college": "Tyler Junior College"
        },
        {
            "mesabi_range_community_and_technical_college": "Mesabi Range Community and Technical College"
        },
        {
            "reinhardt_university": "Reinhardt University"
        },
        {
            "college_of_idaho": "College of Idaho"
        },
        {
            "columbia_basin_college": "Columbia Basin College"
        },
        {
            "irvine_valley_college": "Irvine Valley College"
        },
        {
            "guilford_technical_community_college": "Guilford Technical Community College"
        },
        {
            "macmurray_college_of_the_highlanders": "MacMurray College of the Highlanders"
        },
        {
            "pitt_community_college": "Pitt Community College"
        },
        {
            "schreiner_university": "Schreiner University"
        },
        {
            "king's_college_new_york": "King's College New York"
        },
        {
            "chabot_college": "Chabot College"
        },
        {
            "gateway_technical_college": "Gateway Technical College"
        },
        {
            "northwest_college_wyoming": "Northwest College Wyoming"
        },
        {
            "waubonsee_community_college": "Waubonsee Community College"
        },
        {
            "kansas_city_art_institute": "Kansas City Art Institute"
        },
        {
            "normandale_community_college": "Normandale Community College"
        },
        {
            "montana_university_system": "Montana University System"
        },
        {
            "harford_community_college": "Harford Community College"
        },
        {
            "carolina_university": "Carolina University"
        },
        {
            "hodges_university": "Hodges University"
        },
        {
            "pima_medical_institute": "Pima Medical Institute"
        },
        {
            "calhoun_community_college": "Calhoun Community College"
        },
        {
            "virginia_union_university": "Virginia Union University"
        },
        {
            "parker_university_(college_of_chiropractic)": "Parker University (College of Chiropractic)"
        },
        {
            "siena_heights_university": "Siena Heights University"
        },
        {
            "notre_dame_college": "Notre Dame College"
        },
        {
            "pine_manor_college": "Pine Manor College"
        },
        {
            "fortis_colleges": "Fortis Colleges"
        },
        {
            "moore_college_of_art_&_design": "Moore College of Art & Design"
        },
        {
            "mount_vernon_nazarene_university": "Mount Vernon Nazarene University"
        },
        {
            "southern_california_university_of_health_sciences": "Southern California University of Health Sciences"
        },
        {
            "texas_lutheran_university": "Texas Lutheran University"
        },
        {
            "university_of_houston_system": "University of Houston System"
        },
        {
            "clinton_community_college": "Clinton Community College"
        },
        {
            "louisiana_state_university_eunice": "Louisiana State University Eunice"
        },
        {
            "wilmington_college_of_ohio": "Wilmington College of Ohio"
        },
        {
            "forsyth_technical_community_college": "Forsyth Technical Community College"
        },
        {
            "scottsdale_community_college": "Scottsdale Community College"
        },
        {
            "college_of_marin": "College of Marin"
        },
        {
            "stratford_university": "Stratford University"
        },
        {
            "state_university_of_new_york_jamestown_community_college": "State University of New York Jamestown Community College"
        },
        {
            "welch_college_(free_will_baptist_bible_college)": "Welch College (Free Will Baptist Bible College)"
        },
        {
            "remington_college": "Remington College"
        },
        {
            "northwestern_health_sciences_university": "Northwestern Health Sciences University"
        },
        {
            "dean_college": "Dean College"
        },
        {
            "finger_lakes_community_college": "Finger Lakes Community College"
        },
        {
            "florida_southwestern_state_college_(edison_state_college)": "Florida SouthWestern State College (Edison State College)"
        },
        {
            "cincinnati_state_technical_and_community_college": "Cincinnati State Technical and Community College"
        },
        {
            "nazarene_bible_college": "Nazarene Bible College"
        },
        {
            "miles_college": "Miles College"
        },
        {
            "averett_university": "Averett University"
        },
        {
            "cayuga_community_college": "Cayuga Community College"
        },
        {
            "skagit_valley_college": "Skagit Valley College"
        },
        {
            "leeward_community_college": "Leeward Community College"
        },
        {
            "marine_corps_university": "Marine Corps University"
        },
        {
            "eastern_iowa_community_college_district": "Eastern Iowa Community College District"
        },
        {
            "menlo_college": "Menlo College"
        },
        {
            "ohio_dominican_university": "Ohio Dominican University"
        },
        {
            "oklahoma_wesleyan_university": "Oklahoma Wesleyan University"
        },
        {
            "spokane_falls_community_college": "Spokane Falls Community College"
        },
        {
            "tompkins_cortland_community_college": "Tompkins Cortland Community College"
        },
        {
            "dakota_wesleyan_university": "Dakota Wesleyan University"
        },
        {
            "river_valley_community_college": "River Valley Community College"
        },
        {
            "midland_college": "Midland College"
        },
        {
            "midway_university": "Midway University"
        },
        {
            "kentucky_wesleyan_college": "Kentucky Wesleyan College"
        },
        {
            "baltimore_hebrew_institute": "Baltimore Hebrew Institute"
        },
        {
            "central_christian_college": "Central Christian College"
        },
        {
            "hastings_college": "Hastings College"
        },
        {
            "corban_university": "Corban University"
        },
        {
            "mchenry_county_college": "McHenry County College"
        },
        {
            "university_of_northwestern_saint_paul_(northwestern_college_minnesota)": "University of Northwestern Saint Paul (Northwestern College Minnesota)"
        },
        {
            "anna_maria_college": "Anna Maria College"
        },
        {
            "university_of_jamestown_(jamestown_college)": "University of Jamestown (Jamestown College)"
        },
        {
            "limestone_college": "Limestone College"
        },
        {
            "east_texas_baptist_university": "East Texas Baptist University"
        },
        {
            "greenville_technical_college": "Greenville Technical College"
        },
        {
            "rainy_river_community_college": "Rainy River Community College"
        },
        {
            "saint_joseph's_college_of_indiana": "Saint Joseph's College of Indiana"
        },
        {
            "vermilion_community_college": "Vermilion Community College"
        },
        {
            "antioch_college": "Antioch College"
        },
        {
            "denver_seminary": "Denver Seminary"
        },
        {
            "southwestern_assemblies_of_god_university": "Southwestern Assemblies of God University"
        },
        {
            "university_of_maine_fort_kent": "University of Maine Fort Kent"
        },
        {
            "huston_tillotson_university": "Huston Tillotson University"
        },
        {
            "saint_charles_community_college": "Saint Charles Community College"
        },
        {
            "santa_fe_community_college_nm": "Santa Fe Community College NM"
        },
        {
            "allan_hancock_college": "Allan Hancock College"
        },
        {
            "appalachian_college_of_pharmacy": "Appalachian College of Pharmacy"
        },
        {
            "waynesburg_university": "Waynesburg University"
        },
        {
            "walla_walla_community_college": "Walla Walla Community College"
        },
        {
            "college_of_the_siskiyous": "College of the Siskiyous"
        },
        {
            "college_of_mount_saint_vincent": "College of Mount Saint Vincent"
        },
        {
            "johnston_community_college": "Johnston Community College"
        },
        {
            "west_virginia_wesleyan_college": "West Virginia Wesleyan College"
        },
        {
            "goodwin_college": "Goodwin College"
        },
        {
            "thomas_nelson_community_college": "Thomas Nelson Community College"
        },
        {
            "southern_maine_community_college": "Southern Maine Community College"
        },
        {
            "valley_city_state_university": "Valley City State University"
        },
        {
            "montgomery_community_college": "Montgomery Community College"
        },
        {
            "southwestern_illinois_college": "Southwestern Illinois College"
        },
        {
            "cosumnes_river_college": "Cosumnes River College"
        },
        {
            "golden_west_college": "Golden West College"
        },
        {
            "shasta_college": "Shasta College"
        },
        {
            "trinity_christian_college": "Trinity Christian College"
        },
        {
            "community_college_of_aurora": "Community College of Aurora"
        },
        {
            "rocky_mountain_college_of_art_+_design": "Rocky Mountain College of Art + Design"
        },
        {
            "centura_college_(tidewater_tech)": "Centura College (Tidewater Tech)"
        },
        {
            "chattahoochee_technical_college_(appalacchian_tech_&_north_metro_tech)": "Chattahoochee Technical College (Appalacchian Tech & North Metro Tech)"
        },
        {
            "southeast_community_college_area": "Southeast Community College Area"
        },
        {
            "jefferson_college": "Jefferson College"
        },
        {
            "howard_payne_university": "Howard Payne University"
        },
        {
            "lake_washington_institute_of_technology_(technical_college)": "Lake Washington Institute of Technology (Technical College)"
        },
        {
            "texas_tech_university_system": "Texas Tech University System"
        },
        {
            "cuyamaca_college": "Cuyamaca College"
        },
        {
            "william_peace_university": "William Peace University"
        },
        {
            "cheyney_university_of_pennsylvania": "Cheyney University of Pennsylvania"
        },
        {
            "indian_hills_college": "Indian hills college"
        },
        {
            "middlesex_county_college": "Middlesex County College"
        },
        {
            "fayetteville_technical_community_college": "Fayetteville Technical Community College"
        },
        {
            "union_institute_&_university": "Union Institute & University"
        },
        {
            "hesston_college": "Hesston College"
        },
        {
            "indiana_university_east": "Indiana University East"
        },
        {
            "methodist_college_josie_harper_campus": "Methodist College Josie Harper Campus"
        },
        {
            "mississippi_public_universities": "Mississippi Public Universities"
        },
        {
            "laramie_county_community_college": "Laramie County Community College"
        },
        {
            "kilgore_college": "Kilgore College"
        },
        {
            "waukesha_county_technical_college": "Waukesha County Technical College"
        },
        {
            "north_shore_community_college": "North Shore Community College"
        },
        {
            "coker_college": "Coker College"
        },
        {
            "stark_state_college": "Stark State College"
        },
        {
            "university_of_rio_grande": "University of Rio Grande"
        },
        {
            "university_of_saint_mary": "University of Saint Mary"
        },
        {
            "saint_mary_of_the_woods_college": "Saint Mary of the Woods College"
        },
        {
            "university_of_maine_school_of_law": "University of Maine School of Law"
        },
        {
            "victor_valley_college": "Victor Valley College"
        },
        {
            "le_cordon_bleu_schools_north_america": "Le Cordon Bleu Schools North America"
        },
        {
            "essex_county_college": "Essex County College"
        },
        {
            "lagrange_college": "Lagrange College"
        },
        {
            "william_woods_university": "William Woods University"
        },
        {
            "paine_college": "Paine College"
        },
        {
            "ensign_college": "Ensign College"
        },
        {
            "gateway_community_college": "Gateway Community College"
        },
        {
            "kellogg_community_college": "Kellogg Community College"
        },
        {
            "university_of_the_potomac": "University of the Potomac"
        },
        {
            "briar_cliff_university": "Briar Cliff University"
        },
        {
            "cleary_university": "Cleary University"
        },
        {
            "evergreen_valley_college": "Evergreen Valley College"
        },
        {
            "mount_mary_university": "Mount Mary University"
        },
        {
            "wesleyan_college": "Wesleyan College"
        },
        {
            "craven_community_college": "Craven Community College"
        },
        {
            "new_york_chiropractic_college": "New York Chiropractic College"
        },
        {
            "north_hennepin_community_college": "North Hennepin Community College"
        },
        {
            "south_college_tennessee": "South College Tennessee"
        },
        {
            "spertus_institute_of_jewish_studies": "Spertus Institute of Jewish Studies"
        },
        {
            "saint_paul's_school": "Saint Paul's School"
        },
        {
            "state_university_of_new_york_columbia_greene_community_college": "State University of New York Columbia Greene Community College"
        },
        {
            "kansas_city_kansas_community_college": "Kansas City Kansas Community College"
        },
        {
            "mississippi_gulf_coast_community_college": "Mississippi Gulf Coast Community College"
        },
        {
            "new_orleans_baptist_theological_seminary": "New Orleans Baptist Theological Seminary"
        },
        {
            "passaic_county_community_college": "Passaic County Community College"
        },
        {
            "southern_wesleyan_university": "Southern Wesleyan University"
        },
        {
            "taft_college": "Taft College"
        },
        {
            "luzerne_county_community_college": "Luzerne County Community College"
        },
        {
            "altierus_(everest_university_florida_metropolitan_university)": "Altierus (Everest University Florida Metropolitan University)"
        },
        {
            "chowan_university": "Chowan University"
        },
        {
            "thomas_jefferson_school_of_law": "Thomas Jefferson School of Law"
        },
        {
            "erskine_college": "Erskine College"
        },
        {
            "midwestern_baptist_theological_seminary": "Midwestern Baptist Theological Seminary"
        },
        {
            "spartanburg_community_(technical)_college": "Spartanburg Community (Technical) College"
        },
        {
            "floyd_college": "Floyd College"
        },
        {
            "concorde_career_colleges": "Concorde Career Colleges"
        },
        {
            "central_carolina_community_college": "Central Carolina Community College"
        },
        {
            "mclennan_community_college": "McLennan Community College"
        },
        {
            "mount_wachusett_community_college": "Mount Wachusett Community College"
        },
        {
            "miramar_college_san_diego": "Miramar College San Diego"
        },
        {
            "christian_theological_seminary": "Christian Theological Seminary"
        },
        {
            "state_university_of_new_york_schenectady_county_community_college": "State University of New York Schenectady County Community College"
        },
        {
            "sampson_community_college": "Sampson Community College"
        },
        {
            "alderson_broaddus_university": "Alderson Broaddus University"
        },
        {
            "beacon_college": "Beacon College"
        },
        {
            "waldorf_university": "Waldorf University"
        },
        {
            "aviation_institute_of_maintenance": "Aviation Institute of Maintenance"
        },
        {
            "concordia_college_new_york": "Concordia College New York"
        },
        {
            "central_ohio_technical_college": "Central Ohio Technical College"
        },
        {
            "catholic_theological_union": "Catholic Theological Union"
        },
        {
            "chippewa_valley_technical_college": "Chippewa Valley Technical College"
        },
        {
            "sandhills_community_college": "Sandhills Community College"
        },
        {
            "north_central_kansas_technical_college": "North Central Kansas Technical College"
        },
        {
            "northcentral_technical_college": "Northcentral Technical College"
        },
        {
            "mid_michigan_community_college": "Mid Michigan Community College"
        },
        {
            "west_virginia_university_at_parkersburg": "West Virginia University at Parkersburg"
        },
        {
            "lehigh_carbon_community_college": "Lehigh Carbon Community College"
        },
        {
            "college_of_saint_mary_omaha": "College of Saint Mary Omaha"
        },
        {
            "asa_institute_of_business_and_computer_technology": "ASA Institute of Business and Computer Technology"
        },
        {
            "san_joaquin_valley_college": "San Joaquin Valley College"
        },
        {
            "navarro_college": "Navarro College"
        },
        {
            "saint_thomas_aquinas_college": "Saint Thomas Aquinas College"
        },
        {
            "state_university_of_new_york_ulster_county": "State University of New York Ulster County"
        },
        {
            "lutheran_school_of_theology_chicago": "Lutheran School of Theology Chicago"
        },
        {
            "rowan_college_of_south_jersey": "Rowan College of South Jersey"
        },
        {
            "baltimore_city_community_college": "Baltimore City Community College"
        },
        {
            "iowa_western_community_college": "Iowa Western Community College"
        },
        {
            "saint_paul_community_and_technical_college": "Saint Paul Community and Technical College"
        },
        {
            "university_of_north_texas_at_dallas": "University of North Texas at Dallas"
        },
        {
            "north_greenville_university": "North Greenville University"
        },
        {
            "culver_stockton_college": "Culver Stockton College"
        },
        {
            "harris-stowe_state_university": "Harris-Stowe State University"
        },
        {
            "iowa_central_community_college": "Iowa Central Community College"
        },
        {
            "spartan_college_of_aeronautics_and_technology": "Spartan College of Aeronautics and Technology"
        },
        {
            "marygrove_college": "Marygrove College"
        },
        {
            "jackson_state_community_college": "Jackson State Community College"
        },
        {
            "john_tyler_community_college": "John Tyler Community College"
        },
        {
            "coastline_community_college": "Coastline Community College"
        },
        {
            "southwest_wisconsin_technical_college": "Southwest Wisconsin Technical College"
        },
        {
            "central_community_college": "Central Community College"
        },
        {
            "west_coast_university": "West Coast University"
        },
        {
            "pittsburgh_institute_of_mortuary_science": "Pittsburgh Institute of Mortuary Science"
        },
        {
            "river_parishes_community_college": "River Parishes Community College"
        },
        {
            "alamance_community_college": "Alamance Community College"
        },
        {
            "american_musical_and_dramatic_academy": "American Musical and Dramatic Academy"
        },
        {
            "charter_college": "Charter College"
        },
        {
            "state_university_of_new_york_adirondack_community_college": "State University of New York Adirondack Community College"
        },
        {
            "northwestern_oklahoma_state_university": "Northwestern Oklahoma State University"
        },
        {
            "galveston_college": "Galveston College"
        },
        {
            "brazosport_college": "Brazosport College"
        },
        {
            "cascadia_community_college": "Cascadia Community College"
        },
        {
            "contra_costa_college": "Contra Costa College"
        },
        {
            "atlantic_union_college": "Atlantic Union College"
        },
        {
            "sherman_college_of_chiropractic": "Sherman College of Chiropractic"
        },
        {
            "nashville_state_community_college": "Nashville State Community College"
        },
        {
            "owensboro_community_and_technical_college": "Owensboro Community and Technical College"
        },
        {
            "lake_sumter_state_college": "Lake Sumter State College"
        },
        {
            "wallace_state_community_college_hanceville": "Wallace State Community College Hanceville"
        },
        {
            "college_of_coastal_georgia": "College of Coastal Georgia"
        },
        {
            "frontier_nursing_university_(school_of_midwifery_and_family_nursing)": "Frontier Nursing University (School of Midwifery and Family Nursing)"
        },
        {
            "ohio_christian_university_(circleville_bible_college)": "Ohio Christian University (Circleville Bible College)"
        },
        {
            "northwestern_connecticut_community_college": "Northwestern Connecticut Community College"
        },
        {
            "concordia_university_ann_arbor": "Concordia University Ann Arbor"
        },
        {
            "connecticut_state_university_system": "Connecticut State University System"
        },
        {
            "westminster_theological_seminary_in_california": "Westminster Theological Seminary in California"
        },
        {
            "north_central_texas_college": "North Central Texas College"
        },
        {
            "wisconsin_indianhead_technical_college": "Wisconsin Indianhead Technical College"
        },
        {
            "william_james_college": "William James College"
        },
        {
            "flathead_valley_community_college": "Flathead Valley Community College"
        },
        {
            "benjamin_franklin_institute_of_technology": "Benjamin Franklin Institute of Technology"
        },
        {
            "schiller_international_university": "Schiller International University"
        },
        {
            "university_of_mount_olive": "University of Mount Olive"
        },
        {
            "shelton_state_community_college": "Shelton State Community College"
        },
        {
            "independence_university": "Independence University"
        },
        {
            "dakota_county_technical_college": "Dakota County Technical College"
        },
        {
            "union_college_barbourville_ky": "Union College Barbourville KY"
        },
        {
            "san_jos\u00e3\u00a9_city_college": "San Jos\u00c3\u00a9 City College"
        },
        {
            "university_of_maine_presque_isle": "University of Maine Presque Isle"
        },
        {
            "ogden_weber_applied_technology_college": "Ogden Weber Applied Technology College"
        },
        {
            "piedmont_virginia_community_college": "Piedmont Virginia Community College"
        },
        {
            "bossier_parish_community_college": "Bossier Parish Community College"
        },
        {
            "catawba_valley_community_college": "Catawba Valley Community College"
        },
        {
            "ave_maria_school_of_law": "Ave Maria School of Law"
        },
        {
            "panola_college": "Panola College"
        },
        {
            "sheridan_college": "Sheridan College"
        },
        {
            "south_plains_college": "South Plains College"
        },
        {
            "cleveland_university_kansas_city": "Cleveland University Kansas City"
        },
        {
            "new_york_school_of_interior_design": "New York School of Interior Design"
        },
        {
            "vance_granville_community_college": "Vance Granville Community College"
        },
        {
            "oregon_state_university_cascades": "Oregon State University Cascades"
        },
        {
            "hutchinson_community_college": "Hutchinson Community College"
        },
        {
            "yakima_valley_community_college": "Yakima Valley Community College"
        },
        {
            "haskell_indian_nations_university": "Haskell Indian Nations University"
        },
        {
            "hill_college": "Hill College"
        },
        {
            "university_of_pittsburgh_at_bradford": "University of Pittsburgh at Bradford"
        },
        {
            "wayne_county_community_college_district": "Wayne County Community College District"
        },
        {
            "lake_area_technical_institute": "Lake Area Technical Institute"
        },
        {
            "southwestern_community_college": "Southwestern Community College"
        },
        {
            "carroll_community_college": "Carroll Community College"
        },
        {
            "university_of_western_states_(western_states_chiropractic_college)": "University of Western States (Western States Chiropractic College)"
        },
        {
            "miami_ad_school": "Miami Ad School"
        },
        {
            "grand_view_university": "Grand View University"
        },
        {
            "virginia_western_community_college": "Virginia Western Community College"
        },
        {
            "hennepin_technical_college": "Hennepin Technical College"
        },
        {
            "south_mountain_community_college": "South Mountain Community College"
        },
        {
            "ridgewater_college": "Ridgewater College"
        },
        {
            "brown_mackie_college": "Brown Mackie College"
        },
        {
            "jefferson_state_community_college": "Jefferson State Community College"
        },
        {
            "germanna_community_college": "Germanna Community College"
        },
        {
            "granite_state_college": "Granite State College"
        },
        {
            "herkimer_county_community_college": "Herkimer County Community College"
        },
        {
            "metropolitan_college_of_new_york": "Metropolitan College of New York"
        },
        {
            "arizona_christian_university_(southwestern_college_arizona)": "Arizona Christian University (Southwestern College Arizona)"
        },
        {
            "berkshire_community_college": "Berkshire Community College"
        },
        {
            "wayne_community_college": "Wayne Community College"
        },
        {
            "caldwell_community_college_and_technical_institute": "Caldwell Community College and Technical Institute"
        },
        {
            "southwestern_oregon_community_college": "Southwestern Oregon Community College"
        },
        {
            "university_of_alabama_system": "University of Alabama System"
        },
        {
            "morris_brown_college": "Morris Brown College"
        },
        {
            "massachusetts_bay_community_college": "Massachusetts Bay Community College"
        },
        {
            "wharton_county_junior_college": "Wharton County Junior College"
        },
        {
            "south_florida_community_college": "South Florida Community College"
        },
        {
            "university_of_hawaii_west_oahu": "University of Hawaii West Oahu"
        },
        {
            "central_wyoming_college": "Central Wyoming College"
        },
        {
            "multnomah_university": "Multnomah University"
        },
        {
            "charter_oak_state_college": "Charter Oak State College"
        },
        {
            "cape_cod_community_college": "Cape Cod Community College"
        },
        {
            "northwest_florida_state_college_(okaloosa_walton_college)": "Northwest Florida State College (Okaloosa Walton College)"
        },
        {
            "gordon_state_college_wenham": "Gordon State College Wenham"
        },
        {
            "kankakee_community_college": "Kankakee Community College"
        },
        {
            "lake_land_college_illinois": "Lake Land College Illinois"
        },
        {
            "lake_superior_college": "Lake Superior College"
        },
        {
            "mayville_state_university": "Mayville State University"
        },
        {
            "city_college_florida": "City College Florida"
        },
        {
            "tulsa_welding_school": "Tulsa Welding School"
        },
        {
            "cambridge_junior_college": "Cambridge Junior College"
        },
        {
            "new_york_academy_of_art": "New York Academy of Art"
        },
        {
            "illinois_eastern_community_colleges": "Illinois Eastern Community Colleges"
        },
        {
            "john_a_burns_school_of_medicine": "John A Burns School of Medicine"
        },
        {
            "york_college_nebraska": "York College Nebraska"
        },
        {
            "missouri_valley_college_of_the_vikings": "Missouri Valley College of the Vikings"
        },
        {
            "din\u00e3\u00a9_college": "Din\u00c3\u00a9 College"
        },
        {
            "vermont_college_of_fine_arts": "Vermont College of Fine Arts"
        },
        {
            "bristol_community_college": "Bristol Community College"
        },
        {
            "bryn_athyn_college": "Bryn Athyn College"
        },
        {
            "tri_county_technical_college": "Tri County Technical College"
        },
        {
            "temple_college": "Temple College"
        },
        {
            "tunxis_community_college": "Tunxis Community College"
        },
        {
            "university_of_florida_health_jacksonville": "University of Florida Health Jacksonville"
        },
        {
            "florida_college": "Florida College"
        },
        {
            "mohave_community_college": "Mohave Community College"
        },
        {
            "point_university_(atlanta_christian_college)": "Point University (Atlanta Christian College)"
        },
        {
            "state_university_of_new_york_sullivan_county_community_college": "State University of New York Sullivan County Community College"
        },
        {
            "northeast_community_college": "Northeast Community College"
        },
        {
            "grayson_county_college": "Grayson County College"
        },
        {
            "boston_graduate_school_of_psychoanalysis": "Boston Graduate School of Psychoanalysis"
        },
        {
            "columbia_college_hollywood": "Columbia College Hollywood"
        },
        {
            "claremont_school_of_theology": "Claremont School of Theology"
        },
        {
            "young_harris_college": "Young Harris College"
        },
        {
            "ohio_technical_college": "Ohio Technical College"
        },
        {
            "northeast_iowa_community_college_calmar": "Northeast Iowa Community College Calmar"
        },
        {
            "barton_community_college": "Barton Community College"
        },
        {
            "northland_pioneer_college": "Northland Pioneer College"
        },
        {
            "stevens_henager_college": "Stevens Henager College"
        },
        {
            "judson_college_alabama": "Judson College Alabama"
        },
        {
            "deep_springs_college": "Deep Springs College"
        },
        {
            "eastern_arizona_college": "Eastern Arizona College"
        },
        {
            "moraine_park_technical_college": "Moraine Park Technical College"
        },
        {
            "clatsop_community_college": "Clatsop Community College"
        },
        {
            "edgecombe_community_college": "Edgecombe Community College"
        },
        {
            "trinity_college_florida": "Trinity College Florida"
        },
        {
            "south_central_college": "South Central College"
        },
        {
            "southern_state_community_college": "Southern State Community College"
        },
        {
            "broadview_university": "Broadview University"
        },
        {
            "virginia_highlands_community_college": "Virginia Highlands Community College"
        },
        {
            "western_iowa_tech_community_college": "Western Iowa Tech Community College"
        },
        {
            "north_iowa_area_community_college": "North Iowa Area Community College"
        },
        {
            "allegany_college_of_maryland": "Allegany College of Maryland"
        },
        {
            "aquinas_college_tennessee": "Aquinas College Tennessee"
        },
        {
            "pearl_river_community_college": "Pearl River Community College"
        },
        {
            "maine_media_college": "Maine Media College"
        },
        {
            "fulton_montgomery_community_college": "Fulton Montgomery Community College"
        },
        {
            "calumet_college_of_saint_joseph": "Calumet College of Saint Joseph"
        },
        {
            "warner_university_(southern_college)": "Warner University (Southern College)"
        },
        {
            "monroe_county_community_college": "Monroe County Community College"
        },
        {
            "baton_rouge_community_college": "Baton Rouge Community College"
        },
        {
            "walters_state_community_college": "Walters State Community College"
        },
        {
            "arkansas_state_university_beebe": "Arkansas State University Beebe"
        },
        {
            "bay_de_noc_community_college": "Bay de Noc Community College"
        },
        {
            "rochester_community_and_technical_college": "Rochester Community and Technical College"
        },
        {
            "midland_university_(lutheran_college)": "Midland University (Lutheran College)"
        },
        {
            "lake_michigan_college": "Lake Michigan College"
        },
        {
            "southern_states_university": "Southern States University"
        },
        {
            "florida_coastal_school_of_law": "Florida Coastal School of Law"
        },
        {
            "southern_university_shreveport": "Southern University Shreveport"
        },
        {
            "american_academy_of_dramatic_arts": "American Academy of Dramatic Arts"
        },
        {
            "montserrat_college_of_art": "Montserrat College of Art"
        },
        {
            "lord_fairfax_community_college": "Lord Fairfax Community College"
        },
        {
            "bluefield_state_college": "Bluefield State College"
        },
        {
            "central_georgia_technical_college": "Central Georgia Technical College"
        },
        {
            "university_of_pikeville": "university of Pikeville"
        },
        {
            "appalachian_school_of_law": "Appalachian School of Law"
        },
        {
            "isothermal_community_college": "Isothermal Community College"
        },
        {
            "east_central_college": "East Central College"
        },
        {
            "cowley_college": "Cowley College"
        },
        {
            "lanier_technical_college": "Lanier Technical College"
        },
        {
            "mid_america_christian_university": "Mid America Christian University"
        },
        {
            "sussex_county_community_college": "Sussex County Community College"
        },
        {
            "shawnee_community_college": "Shawnee Community College"
        },
        {
            "jefferson_technical_college": "Jefferson Technical College"
        },
        {
            "university_of_maine_machias": "University of Maine Machias"
        },
        {
            "motlow_state_community_college": "Motlow State Community College"
        },
        {
            "livingstone_college": "Livingstone College"
        },
        {
            "wsu_tech": "WSU Tech"
        },
        {
            "blue_ridge_community_college_virginia": "Blue Ridge Community College Virginia"
        },
        {
            "lower_columbia_college": "Lower Columbia College"
        },
        {
            "blue_ridge_community_college_north_carolina": "Blue Ridge Community College North Carolina"
        },
        {
            "alexandria_technical_college": "Alexandria Technical College"
        },
        {
            "butler_county_community_college": "Butler County Community College"
        },
        {
            "lincoln_college_illinois": "Lincoln College Illinois"
        },
        {
            "crafton_hills_college": "Crafton Hills College"
        },
        {
            "south_arkansas_community_college": "South Arkansas Community College"
        },
        {
            "hebrew_college": "Hebrew College"
        },
        {
            "east_mississippi_community_college": "East Mississippi Community College"
        },
        {
            "west_kentucky_community_&_technical_college": "West Kentucky Community & Technical College"
        },
        {
            "longy_school_of_music": "Longy School of Music"
        },
        {
            "massasoit_community_college": "Massasoit Community College"
        },
        {
            "wiley_college": "Wiley College"
        },
        {
            "pacific_school_of_religion": "Pacific School of Religion"
        },
        {
            "empire_college_school_of_business": "Empire College School of Business"
        },
        {
            "berkeley_city_college_(vista_college)": "Berkeley City College (Vista College)"
        },
        {
            "kirtland_community_college": "Kirtland Community College"
        },
        {
            "norwalk_community_college": "Norwalk Community College"
        },
        {
            "folsom_lake_college": "Folsom Lake College"
        },
        {
            "hawaii_community_college": "Hawaii Community College"
        },
        {
            "allen_university": "Allen University"
        },
        {
            "art_academy_of_cincinnati": "Art Academy of Cincinnati"
        },
        {
            "mid_state_technical_college": "Mid State Technical College"
        },
        {
            "howard_college": "Howard College"
        },
        {
            "pasco_hernando_state_college": "Pasco Hernando State College"
        },
        {
            "platt_college_san_diego": "Platt College San Diego"
        },
        {
            "mitchell_college": "Mitchell College"
        },
        {
            "northern_oklahoma_college": "Northern Oklahoma College"
        },
        {
            "lemoyne_owen_college": "LeMoyne Owen College"
        },
        {
            "stanly_community_college": "Stanly Community College"
        },
        {
            "merritt_college": "Merritt College"
        },
        {
            "north_dakota_state_college_of_science": "North Dakota State College of Science"
        },
        {
            "lake_tahoe_community_college": "Lake Tahoe Community College"
        },
        {
            "trinity_valley_community_college": "Trinity Valley Community College"
        },
        {
            "union_theological_seminary_and_presbyterian_school_of_christian_education": "Union Theological Seminary and Presbyterian School of Christian Education"
        },
        {
            "american_national_university": "American National University"
        },
        {
            "lincoln_christian_university": "Lincoln Christian University"
        },
        {
            "mount_marty_college_in_south_dakota": "Mount Marty College in South Dakota"
        },
        {
            "holy_cross_college": "Holy Cross College"
        },
        {
            "colby_community_college": "Colby Community College"
        },
        {
            "southern_university_law_center": "Southern University Law Center"
        },
        {
            "trinidad_state_junior_college": "Trinidad State Junior College"
        },
        {
            "cleveland_state_community_college": "Cleveland State Community College"
        },
        {
            "danville_area_community_college": "Danville Area Community College"
        },
        {
            "sitting_bull_college": "Sitting Bull College"
        },
        {
            "thomas_more_college_of_liberal_arts": "Thomas More College of Liberal Arts"
        },
        {
            "college_of_health_care_professions": "College of Health Care Professions"
        },
        {
            "faith_baptist_bible_college_&_theological_seminary": "Faith Baptist Bible College & Theological Seminary"
        },
        {
            "north_country_community_college_suny": "North Country Community College SUNY"
        },
        {
            "florence_darlington_technical_college": "Florence Darlington Technical College"
        },
        {
            "northwest_career_college": "Northwest Career College"
        },
        {
            "the_principia": "The Principia"
        },
        {
            "roxbury_community_college": "Roxbury Community College"
        },
        {
            "lane_college": "Lane College"
        },
        {
            "minnesota_west_community_and_technical_college": "Minnesota West Community and Technical College"
        },
        {
            "relay_graduate_school_of_education": "Relay Graduate School of Education"
        },
        {
            "terra_community_college": "Terra Community College"
        },
        {
            "southeast_technical_institute": "Southeast Technical Institute"
        },
        {
            "south_suburban_college": "South Suburban College"
        },
        {
            "college_of_menominee_nation": "College of Menominee Nation"
        },
        {
            "community_college_of_beaver_county": "Community College of Beaver County"
        },
        {
            "cecil_college": "Cecil College"
        },
        {
            "calvary_university": "Calvary University"
        },
        {
            "appalachian_bible_college": "Appalachian Bible College"
        },
        {
            "southern_university_system": "Southern University System"
        },
        {
            "jarvis_christian_college": "Jarvis Christian College"
        },
        {
            "carl_sandburg_college": "Carl Sandburg College"
        },
        {
            "college_of_western_idaho": "College of Western Idaho"
        },
        {
            "athens_technical_college": "Athens Technical College"
        },
        {
            "central_baptist_college": "Central Baptist College"
        },
        {
            "allen_college": "Allen College"
        },
        {
            "texas_college": "Texas College"
        },
        {
            "neosho_county_community_college": "Neosho County Community College"
        },
        {
            "riverland_community_college": "Riverland Community College"
        },
        {
            "naugatuck_valley_community_college": "Naugatuck Valley Community College"
        },
        {
            "everglades_university": "Everglades University"
        },
        {
            "holmes_community_college": "Holmes Community College"
        },
        {
            "mitchell_community_college": "Mitchell Community College"
        },
        {
            "surry_community_college": "Surry Community College"
        },
        {
            "hellenic_college_&_holy_cross_greek_orthodox_school_of_theology": "Hellenic College & Holy Cross Greek Orthodox School of Theology"
        },
        {
            "saint_meinrad_school_of_theology": "Saint Meinrad School of Theology"
        },
        {
            "meridian_community_college": "Meridian Community College"
        },
        {
            "coastal_bend_college": "Coastal Bend College"
        },
        {
            "west_virginia_school_of_osteopathic_medicine": "West Virginia School of Osteopathic Medicine"
        },
        {
            "hobe_sound_bible_college": "Hobe Sound Bible College"
        },
        {
            "northeast_state_technical_community_college": "Northeast State Technical Community College"
        },
        {
            "fond_du_lac_tribal_and_community_college": "Fond du Lac Tribal and Community College"
        },
        {
            "copper_mountain_college": "Copper Mountain College"
        },
        {
            "georgia_northwestern_technical_college": "Georgia Northwestern Technical College"
        },
        {
            "san_diego_christian_college": "San Diego Christian College"
        },
        {
            "randolph_community_college": "Randolph Community College"
        },
        {
            "western_nebraska_community_college": "Western Nebraska Community College"
        },
        {
            "miller_motte_technical_college": "Miller Motte Technical College"
        },
        {
            "east_west_university": "East West University"
        },
        {
            "amberton_university": "Amberton University"
        },
        {
            "arkansas_northeastern_college": "Arkansas Northeastern College"
        },
        {
            "florida_technical_college": "Florida Technical College"
        },
        {
            "rend_lake_college": "Rend Lake College"
        },
        {
            "jones_county_junior_college": "Jones County Junior College"
        },
        {
            "bay_mills_community_college": "Bay Mills Community College"
        },
        {
            "pennsylvania_college_of_art_&_design": "Pennsylvania College of Art & Design"
        },
        {
            "copiah_lincoln_community_college": "Copiah Lincoln Community College"
        },
        {
            "south_piedmont_community_college": "South Piedmont Community College"
        },
        {
            "florida_gateway_college_(lake_city_community_college)": "Florida Gateway College (Lake City Community College)"
        },
        {
            "new_england_culinary_institute": "New England Culinary Institute"
        },
        {
            "new_york_college_of_health_professions": "New York College of Health Professions"
        },
        {
            "bishop_state_community_college": "Bishop State Community College"
        },
        {
            "atlanta_institute_of_music_and_media": "Atlanta Institute of Music and Media"
        },
        {
            "seward_county_community_college": "Seward County Community College"
        },
        {
            "haywood_community_college": "Haywood Community College"
        },
        {
            "southwest_virginia_community_college": "Southwest Virginia Community College"
        },
        {
            "central_carolina_technical_college": "Central Carolina Technical College"
        },
        {
            "south_georgia_state_college_(waycross_college)": "South Georgia State College (Waycross College)"
        },
        {
            "dyersburg_state_community_college": "Dyersburg State Community College"
        },
        {
            "amridge_university_(southern_christian_university)": "Amridge University (Southern Christian University)"
        },
        {
            "oregon_college_of_art_&_craft": "Oregon College of Art & Craft"
        },
        {
            "presentation_college": "Presentation College"
        },
        {
            "lenoir_community_college": "Lenoir Community College"
        },
        {
            "colorado_northwestern_community_college": "Colorado Northwestern Community College"
        },
        {
            "gage_academy_of_art_(seattle_academy_of_fine_art)": "Gage Academy of Art (Seattle Academy of Fine Art)"
        },
        {
            "northeast_texas_community_college": "Northeast Texas Community College"
        },
        {
            "angelina_college": "Angelina College"
        },
        {
            "el_camino_college_compton_center": "El Camino College Compton Center"
        },
        {
            "anoka_technical_college": "Anoka Technical College"
        },
        {
            "aiken_technical_college": "Aiken Technical College"
        },
        {
            "daymar_college_(paducah_technical_college,_southeastern_business_college)": "Daymar College (Paducah Technical College, Southeastern Business College)"
        },
        {
            "mitchell_technical_institute": "Mitchell Technical Institute"
        },
        {
            "northwestern_polytechnic_university": "Northwestern Polytechnic University"
        },
        {
            "eastern_maine_community_college": "Eastern Maine Community College"
        },
        {
            "asnuntuck_community_college": "Asnuntuck Community College"
        },
        {
            "new_mexico_junior_college": "New Mexico Junior College"
        },
        {
            "trocaire_college": "Trocaire College"
        },
        {
            "orangeburg_calhoun_technical_college": "Orangeburg Calhoun Technical College"
        },
        {
            "morris_college": "Morris College"
        },
        {
            "wytheville_community_college": "Wytheville Community College"
        },
        {
            "john_wood_community_college": "John Wood Community College"
        },
        {
            "itawamba_community_college": "Itawamba Community College"
        },
        {
            "eastern_oklahoma_state_college": "Eastern Oklahoma State College"
        },
        {
            "texas_chiropractic_college": "Texas Chiropractic College"
        },
        {
            "iliff_school_of_theology": "ILIFF School of Theology"
        },
        {
            "james_a_rhodes_state_college": "James A Rhodes State College"
        },
        {
            "carteret_community_college": "Carteret Community College"
        },
        {
            "southeast_technical_winona_minnesota_state_college": "Southeast Technical Winona Minnesota State College"
        },
        {
            "eastern_gateway_community_college": "Eastern Gateway Community College"
        },
        {
            "cox_college": "Cox College"
        },
        {
            "ohio_valley_university": "Ohio Valley University"
        },
        {
            "barclay_college": "Barclay College"
        },
        {
            "garrett-evangelical_theological_seminary": "Garrett-Evangelical Theological Seminary"
        },
        {
            "harcum_college": "Harcum College"
        },
        {
            "wilson_community_college": "Wilson Community College"
        },
        {
            "coahoma_community_college": "Coahoma Community College"
        },
        {
            "piedmont_community_college": "Piedmont Community College"
        },
        {
            "truett_mcconnell_university": "Truett McConnell University"
        },
        {
            "albany_technical_college": "Albany Technical College"
        },
        {
            "nash_community_college": "Nash Community College"
        },
        {
            "wallace_community_college_selma": "Wallace Community College Selma"
        },
        {
            "coastal_carolina_community_college": "Coastal Carolina Community College"
        },
        {
            "centenary_university": "Centenary University"
        },
        {
            "kaskaskia_college": "Kaskaskia College"
        },
        {
            "louisiana_community_&_technical_college_system": "Louisiana Community & Technical College System"
        },
        {
            "texas_southern_university_thurgood_marshall_school_of_law": "Texas Southern University Thurgood Marshall School of Law"
        },
        {
            "davis_&_elkins_college": "Davis & Elkins College"
        },
        {
            "california_college_san_diego": "California College San Diego"
        },
        {
            "university_of_valley_forge": "University of Valley Forge"
        },
        {
            "college_of_the_albemarle": "College of the Albemarle"
        },
        {
            "great_lakes_christian_college": "Great Lakes Christian College"
        },
        {
            "lakeshore_technical_college": "Lakeshore Technical College"
        },
        {
            "munson_williams_proctor_arts_institute": "Munson Williams Proctor Arts Institute"
        },
        {
            "yuba_college": "Yuba College"
        },
        {
            "garrett_college": "Garrett College"
        },
        {
            "new_york_college_of_podiatric_medicine": "New York College of Podiatric Medicine"
        },
        {
            "emily_griffith_technical_college": "Emily Griffith Technical College"
        },
        {
            "otero_junior_college": "Otero Junior College"
        },
        {
            "pittsburgh_technical_college_(pittsburgh_technical_institute)": "Pittsburgh Technical College (Pittsburgh Technical Institute)"
        },
        {
            "navajo_technical_university": "Navajo Technical University"
        },
        {
            "emmaus_bible_college": "Emmaus Bible College"
        },
        {
            "nashua_community_college": "Nashua Community College"
        },
        {
            "webb_institute": "Webb Institute"
        },
        {
            "mercy_college_ohio": "Mercy College Ohio"
        },
        {
            "mercy_college_of_health_sciences": "Mercy College of Health Sciences"
        },
        {
            "saint_johns_river_state_college": "Saint Johns River State College"
        },
        {
            "arkansas_baptist_college": "Arkansas Baptist College"
        },
        {
            "paradise_valley_community_college": "Paradise Valley Community College"
        },
        {
            "ranken_technical_college": "Ranken Technical College"
        },
        {
            "western_state_university_college_of_law": "Western State University College of Law"
        },
        {
            "phillips_community_college_university_of_arkansas": "Phillips Community College University of Arkansas"
        },
        {
            "saint_john's_seminary_camarillo": "Saint John's Seminary Camarillo"
        },
        {
            "pittsburgh_institute_of_aeronautics": "Pittsburgh Institute of Aeronautics"
        },
        {
            "edward_via_college_of_osteopathic_medicine": "Edward Via College of Osteopathic Medicine"
        },
        {
            "college_for_financial_planning": "College for Financial Planning"
        },
        {
            "cottey_college": "Cottey College"
        },
        {
            "north_georgia_technical_college": "North Georgia Technical College"
        },
        {
            "pratt_community_college": "Pratt Community College"
        },
        {
            "northwest_shoals_community_college": "Northwest Shoals Community College"
        },
        {
            "goldfarb_school_of_nursing_at_barnes_jewish_college": "Goldfarb School of Nursing at Barnes Jewish College"
        },
        {
            "thaddeus_stevens_college_of_technology": "Thaddeus Stevens College of Technology"
        },
        {
            "laguna_college_of_art_&_design": "Laguna College of Art & Design"
        },
        {
            "brookline_college_(international_institute_of_the_americas)": "Brookline College (International Institute of the Americas)"
        },
        {
            "church_divinity_school_of_the_pacific": "Church Divinity School of the Pacific"
        },
        {
            "frank_phillips_college": "Frank Phillips College"
        },
        {
            "lurleen_b_wallace_community_college": "Lurleen B Wallace Community College"
        },
        {
            "austin_presbyterian_theological_seminary": "Austin Presbyterian Theological Seminary"
        },
        {
            "lamar_community_college": "Lamar Community College"
        },
        {
            "iowa_wesleyan_university": "Iowa Wesleyan University"
        },
        {
            "sowela_technical_community_college": "Sowela Technical Community College"
        },
        {
            "blue_mountain_college": "Blue Mountain College"
        },
        {
            "bushnell_university": "Bushnell University"
        },
        {
            "southern_california_university": "Southern California University"
        },
        {
            "york_technical_institute": "York Technical Institute"
        },
        {
            "gateway_community_and_technical_college": "Gateway Community and Technical College"
        },
        {
            "university_of_saint_mary_of_the_lake": "University of Saint Mary of the Lake"
        },
        {
            "washington_state_community_college": "Washington State Community College"
        },
        {
            "manhattan_christian_college": "Manhattan Christian College"
        },
        {
            "south_georgia_technical_college": "South Georgia Technical College"
        },
        {
            "university_of_cincinnati_clermont_college": "University of Cincinnati Clermont College"
        },
        {
            "paris_junior_college": "Paris Junior College"
        },
        {
            "east_central_community_college": "East Central Community College"
        },
        {
            "criswell_college": "Criswell College"
        },
        {
            "fort_scott_community_college": "Fort Scott Community College"
        },
        {
            "northern_maine_community_college": "Northern Maine Community College"
        },
        {
            "pardee_rand_graduate_school": "Pardee Rand Graduate School"
        },
        {
            "garden_city_community_college": "Garden City Community College"
        },
        {
            "columbia_college_sonora": "Columbia College Sonora"
        },
        {
            "college_of_alameda": "College of Alameda"
        },
        {
            "the_king's_university": "The King's University"
        },
        {
            "northwest_state_community_college": "Northwest State Community College"
        },
        {
            "connors_state_college": "Connors State College"
        },
        {
            "university_of_antelope_valley": "University of Antelope Valley"
        },
        {
            "grossmont_cuyamaca_community_college": "Grossmont Cuyamaca Community College"
        },
        {
            "american_academy_of_art": "American Academy of Art"
        },
        {
            "marymount_california_university": "Marymount California University"
        },
        {
            "northeastern_junior_college": "Northeastern Junior College"
        },
        {
            "new_mexico_state_university_alamogordo": "New Mexico State University Alamogordo"
        },
        {
            "j_f_drake_state_technical_college": "J F Drake State Technical College"
        },
        {
            "elizabethtown_community_&_technical_college": "Elizabethtown Community & Technical College"
        },
        {
            "rappahannock_community_college": "Rappahannock Community College"
        },
        {
            "southeastern_illinois_college": "Southeastern Illinois College"
        },
        {
            "somerset_community_college": "Somerset Community College"
        },
        {
            "allen_community_college": "Allen Community College"
        },
        {
            "north_arkansas_college": "North Arkansas College"
        },
        {
            "holy_apostles_college_&_seminary": "Holy Apostles College & Seminary"
        },
        {
            "western_wyoming_community_college": "Western Wyoming Community College"
        },
        {
            "kennebec_valley_community_college": "Kennebec Valley Community College"
        },
        {
            "southern_crescent_technical_college_(flint_river_technical_college)": "Southern Crescent Technical College (Flint River Technical College)"
        },
        {
            "shimer_college": "Shimer College"
        },
        {
            "presidio_graduate_school": "Presidio Graduate School"
        },
        {
            "davidson_county_community_college": "Davidson County Community College"
        },
        {
            "lincoln_university_california": "Lincoln University California"
        },
        {
            "southeastern_iowa_community_college": "Southeastern Iowa Community College"
        },
        {
            "central_virginia_community_college": "Central Virginia Community College"
        },
        {
            "patten_university": "Patten University"
        },
        {
            "platt_college_huntington_beach": "Platt College Huntington Beach"
        },
        {
            "mississippi_delta_community_college": "Mississippi Delta Community College"
        },
        {
            "southwestern_community_college_iowa": "Southwestern Community College Iowa"
        },
        {
            "gogebic_community_college": "Gogebic Community College"
        },
        {
            "barstow_college": "Barstow College"
        },
        {
            "harbor_ucla_medical_center_graduate_medical_education": "Harbor UCLA Medical Center Graduate Medical Education"
        },
        {
            "new_york_institute_of_art_and_design_(sheffield_school_of_interior_design)": "New York Institute of Art and Design (Sheffield School of Interior Design)"
        },
        {
            "oklahoma_state_university_center_for_health_sciences": "Oklahoma State University Center for Health Sciences"
        },
        {
            "richmond_community_college": "Richmond Community College"
        },
        {
            "new_river_community_and_technical_college": "New River Community and Technical College"
        },
        {
            "western_texas_college": "Western Texas College"
        },
        {
            "mount_carmel_college_of_nursing": "Mount Carmel College of Nursing"
        },
        {
            "york_county_community_college": "York County Community College"
        },
        {
            "heritage_christian_university": "Heritage Christian University"
        },
        {
            "dominican_school_of_philosophy_&_theology": "Dominican School of Philosophy & Theology"
        },
        {
            "baptist_college_of_florida": "Baptist College of Florida"
        },
        {
            "west_virginia_northern_community_college": "West Virginia Northern Community College"
        },
        {
            "newbury_college": "Newbury College"
        },
        {
            "southern_union_state_community_college": "Southern Union State Community College"
        },
        {
            "three_rivers_community_college": "Three Rivers Community College"
        },
        {
            "southwest_institute_of_healing_arts": "Southwest Institute of Healing Arts"
        },
        {
            "dodge_city_community_college": "Dodge City Community College"
        },
        {
            "dallas_christian_college": "Dallas Christian College"
        },
        {
            "rocky_vista_university": "Rocky Vista University"
        },
        {
            "eastern_wyoming_college": "Eastern Wyoming College"
        },
        {
            "hopkinsville_community_college": "Hopkinsville Community College"
        },
        {
            "college_of_biblical_studies_houston": "College of Biblical Studies Houston"
        },
        {
            "central_christian_college_of_the_bible": "Central Christian College of the Bible"
        },
        {
            "southeast_arkansas_college": "Southeast Arkansas College"
        },
        {
            "big_sandy_community_and_technical_college": "Big Sandy Community and Technical College"
        },
        {
            "arkansas_state_university_mountain_home": "Arkansas State University Mountain Home"
        },
        {
            "northwest_iowa_community_college": "Northwest Iowa Community College"
        },
        {
            "southside_virginia_community_college": "Southside Virginia Community College"
        },
        {
            "west_shore_community_college": "West Shore Community College"
        },
        {
            "lamar_state_college_orange": "Lamar State College Orange"
        },
        {
            "gratz_college": "Gratz College"
        },
        {
            "maria_college_of_albany": "Maria College of Albany"
        },
        {
            "coffeyville_community_college_and_area_technical_school": "Coffeyville Community College and Area Technical School"
        },
        {
            "southwestern_college_santa_fe": "Southwestern College Santa Fe"
        },
        {
            "ogeechee_technical_college": "Ogeechee Technical College"
        },
        {
            "atlanta's_john_marshall_law_school": "Atlanta's John Marshall Law School"
        },
        {
            "kettering_college_(of_medical_arts)": "Kettering College (of Medical Arts)"
        },
        {
            "mercersburg_academy": "Mercersburg Academy"
        },
        {
            "design_institute_of_san_diego": "Design Institute of San Diego"
        },
        {
            "enterprise_state_community_college": "Enterprise State Community College"
        },
        {
            "mildred_elley_school": "Mildred Elley School"
        },
        {
            "north_central_missouri_college": "North Central Missouri College"
        },
        {
            "episcopal_theological_seminary_of_the_southwest": "Episcopal Theological Seminary of the Southwest"
        },
        {
            "rockingham_community_college": "Rockingham Community College"
        },
        {
            "lakes_region_community_college": "Lakes Region Community College"
        },
        {
            "ashland_community_&_technical_college": "Ashland Community & Technical College"
        },
        {
            "hibbing_community_college": "Hibbing Community College"
        },
        {
            "saint_mary's_seminary_&_university": "Saint Mary's Seminary & University"
        },
        {
            "wiregrass_georgia_technical_college_(east_central_technical_college)": "Wiregrass Georgia Technical College (East Central Technical College)"
        },
        {
            "taft_law_school": "Taft Law School"
        },
        {
            "massachusetts_school_of_law": "Massachusetts School of Law"
        },
        {
            "pierpont_community_and_technical_college": "Pierpont Community and Technical College"
        },
        {
            "marion_technical_college": "Marion Technical College"
        },
        {
            "maranatha_baptist_university": "Maranatha Baptist University"
        },
        {
            "university_of_arkansas_community_college_batesville": "University of Arkansas Community College Batesville"
        },
        {
            "southeastern_community_college": "Southeastern Community College"
        },
        {
            "jones_college": "Jones College"
        },
        {
            "athenaeum_of_ohio": "Athenaeum of Ohio"
        },
        {
            "mesalands_community_college": "Mesalands Community College"
        },
        {
            "southern_technical_college_(southwest_florida_college)": "Southern Technical College (Southwest Florida College)"
        },
        {
            "state_technical_college_of_missouri_(linn_state_technical_college)": "State Technical College of Missouri (Linn State Technical College)"
        },
        {
            "baptist_health_sciences_university": "Baptist Health Sciences University"
        },
        {
            "beaufort_county_community_college": "Beaufort County Community College"
        },
        {
            "texas_southmost_college": "Texas Southmost College"
        },
        {
            "humphreys_college": "Humphreys College"
        },
        {
            "warren_county_community_college": "Warren County Community College"
        },
        {
            "interactive_college_of_technology": "Interactive College of Technology"
        },
        {
            "cisco_junior_college": "Cisco Junior College"
        },
        {
            "western_oklahoma_state_college": "Western Oklahoma State College"
        },
        {
            "morgan_community_college": "Morgan Community College"
        },
        {
            "california_coast_university": "California Coast University"
        },
        {
            "labour\u00e3\u00a9_college": "Labour\u00c3\u00a9 College"
        },
        {
            "sinte_gleska_university": "Sinte Gleska University"
        },
        {
            "saint_paul_school_of_theology": "Saint Paul School of Theology"
        },
        {
            "western_piedmont_community_college": "Western Piedmont Community College"
        },
        {
            "fort_peck_community_college": "Fort Peck Community College"
        },
        {
            "franciscan_university_(our_lady_of_the_lake_college)": "Franciscan University (Our Lady of the Lake College)"
        },
        {
            "clarendon_college": "Clarendon College"
        },
        {
            "clarks_summit_university": "Clarks Summit University"
        },
        {
            "college_of_business_and_technology": "College of Business and Technology"
        },
        {
            "james_sprunt_community_college": "James Sprunt Community College"
        },
        {
            "santa_barbara_business_college": "Santa Barbara Business College"
        },
        {
            "woodland_community_college": "Woodland Community College"
        },
        {
            "rochester_university": "Rochester University"
        },
        {
            "blue_ridge_community_&_technical_college": "Blue Ridge Community & Technical College"
        },
        {
            "watkins_college_of_art_design_&_film": "Watkins College of Art Design & Film"
        },
        {
            "redlands_community_college": "Redlands Community College"
        },
        {
            "college_of_westchester": "College of Westchester"
        },
        {
            "dominican_house_of_studies": "Dominican House of Studies"
        },
        {
            "westmoreland_county_community_college": "Westmoreland County Community College"
        },
        {
            "oblate_school_of_theology": "Oblate School of Theology"
        },
        {
            "murray_state_college": "Murray State College"
        },
        {
            "glen_oaks_community_college": "Glen Oaks Community College"
        },
        {
            "neumont_university": "Neumont University"
        },
        {
            "nunez_community_college": "Nunez Community College"
        },
        {
            "kenai_peninsula_college": "Kenai Peninsula College"
        },
        {
            "chattahoochee_valley_community_college": "Chattahoochee Valley Community College"
        },
        {
            "salem_community_college": "Salem Community College"
        },
        {
            "meadville_lombard_theological_school": "Meadville Lombard Theological School"
        },
        {
            "national_intelligence_university_(national_defense_intelligence_college)": "National Intelligence University (National Defense Intelligence College)"
        },
        {
            "robeson_community_college": "Robeson Community College"
        },
        {
            "cincinnati_college_of_mortuary_science": "Cincinnati College of Mortuary Science"
        },
        {
            "southeast_kentucky_community_&_technical_college": "Southeast Kentucky Community & Technical College"
        },
        {
            "trinity_baptist_college": "Trinity Baptist College"
        },
        {
            "montcalm_community_college": "Montcalm Community College"
        },
        {
            "oregon_college_of_oriental_medicine": "Oregon College of Oriental Medicine"
        },
        {
            "hebrew_theological_college": "Hebrew Theological College"
        },
        {
            "kaua'i_community_college": "Kaua'i Community College"
        },
        {
            "itasca_community_college": "Itasca Community College"
        },
        {
            "north_florida_community_college": "North Florida Community College"
        },
        {
            "vandercook_college_of_music": "Vandercook College of Music"
        },
        {
            "loyola_academy_illinois": "Loyola Academy Illinois"
        },
        {
            "seminole_state_college_oklahoma": "Seminole State College Oklahoma"
        },
        {
            "ozarka_college": "Ozarka College"
        },
        {
            "lake_region_state_college": "Lake Region State College"
        },
        {
            "michigan_school_of_professional_psychology": "Michigan School of Professional Psychology"
        },
        {
            "donnelly_college": "Donnelly College"
        },
        {
            "spoon_river_college": "Spoon River College"
        },
        {
            "madisonville_community_college": "Madisonville Community College"
        },
        {
            "halifax_community_college": "Halifax Community College"
        },
        {
            "louisiana_delta_community_college": "Louisiana Delta Community College"
        },
        {
            "dakota_college_(minot_state_university)_bottineau": "Dakota College (Minot State University) Bottineau"
        },
        {
            "institute_of_business_and_medical_careers": "Institute of Business and Medical Careers"
        },
        {
            "maysville_community_&_technical_college": "Maysville Community & Technical College"
        },
        {
            "marion_military_institute": "Marion Military Institute"
        },
        {
            "central_alabama_community_college": "Central Alabama Community College"
        },
        {
            "snead_state_community_college": "Snead State Community College"
        },
        {
            "southeastern_technical_college_(swainsboro_technical_college)": "Southeastern Technical College (Swainsboro Technical College)"
        },
        {
            "independence_community_college": "Independence Community College"
        },
        {
            "lakeview_college_of_nursing": "Lakeview College of Nursing"
        },
        {
            "santa_fe_university_of_art_and_design_(college_of_santa_fe)": "Santa Fe University of Art and Design (College of Santa Fe)"
        },
        {
            "god's_bible_school_and_college": "God's Bible School and College"
        },
        {
            "southwestern_indian_polytechnic_institute": "Southwestern Indian Polytechnic Institute"
        },
        {
            "oak_hills_christian_college": "Oak Hills Christian College"
        },
        {
            "fletcher_technical_community_college": "Fletcher Technical Community College"
        },
        {
            "washington_county_community_college": "Washington County Community College"
        },
        {
            "miles_community_college": "Miles Community College"
        },
        {
            "national_hispanic_university": "National Hispanic University"
        },
        {
            "williston_state_college": "Williston State College"
        },
        {
            "atlantic_college": "Atlantic College"
        },
        {
            "southwestern_christian_college": "Southwestern Christian College"
        },
        {
            "grace_christian_university": "Grace Christian University"
        },
        {
            "southwestern_christian_university": "Southwestern Christian University"
        },
        {
            "kuyper_college": "Kuyper College"
        },
        {
            "northwestern_college_chicago": "Northwestern College Chicago"
        },
        {
            "pennsylvania_institute_of_technology": "Pennsylvania Institute of Technology"
        },
        {
            "luna_community_college": "Luna Community College"
        },
        {
            "national_park_college": "National Park College"
        },
        {
            "bates_technical_college": "Bates Technical College"
        },
        {
            "methodist_theological_school_ohio": "Methodist Theological School Ohio"
        },
        {
            "pennsylvania_college_of_health_sciences_(lancaster_general_college_of_nursing_&_health_science)": "Pennsylvania College of Health Sciences (Lancaster General College of Nursing & Health Science)"
        },
        {
            "arizona_college_of_allied_health": "Arizona College of Allied Health"
        },
        {
            "westcliff_university": "Westcliff University"
        },
        {
            "wright_institute": "Wright Institute"
        },
        {
            "john_paul_the_great_catholic_university_(new_catholic_university)": "John Paul the Great Catholic University (New Catholic University)"
        },
        {
            "st_augustine_college": "St Augustine College"
        },
        {
            "northshore_technical_community_college_(louisiana_technical_college_sullivan)": "Northshore Technical Community College (Louisiana Technical College Sullivan)"
        },
        {
            "university_of_providence_(university_of_great_falls)": "University of Providence (University of Great Falls)"
        },
        {
            "andrew_college": "Andrew College"
        },
        {
            "conway_school_of_landscape_design": "Conway School of Landscape Design"
        },
        {
            "mountwest_community_and_technical_college": "Mountwest Community and Technical College"
        },
        {
            "university_of_arkansas_community_college_morrilton": "University of Arkansas Community College Morrilton"
        },
        {
            "mcdowell_technical_community_college": "McDowell Technical Community College"
        },
        {
            "eastern_shore_community_college": "Eastern Shore Community College"
        },
        {
            "unification_theological_seminary": "Unification Theological Seminary"
        },
        {
            "east_arkansas_community_college": "East Arkansas Community College"
        },
        {
            "southwest_mississippi_community_college": "Southwest Mississippi Community College"
        },
        {
            "aquinas_institute_of_theology": "Aquinas Institute of Theology"
        },
        {
            "education_corporation_of_america_colleges": "Education Corporation of America Colleges"
        },
        {
            "north_american_university": "North American University"
        },
        {
            "chief_dull_knife_college": "chief dull knife college"
        },
        {
            "mti_college_of_business_and_technology": "MTI College of Business and Technology"
        },
        {
            "university_of_management_and_technology": "University of Management and Technology"
        },
        {
            "johnson_college": "Johnson College"
        },
        {
            "washburn_institute_of_technology": "Washburn Institute of Technology"
        },
        {
            "palo_verde_college": "Palo Verde College"
        },
        {
            "paul_d_camp_community_college": "Paul D Camp Community College"
        },
        {
            "pontifical_college_josephinum": "Pontifical College Josephinum"
        },
        {
            "dawson_community_college": "Dawson Community College"
        },
        {
            "denmark_technical_college": "Denmark Technical College"
        },
        {
            "florida_career_college": "Florida Career College"
        },
        {
            "western_international_university": "Western International University"
        },
        {
            "oconee_fall_line_technical_college_(sandersville_+_heart_of_georgia)": "Oconee Fall Line Technical College (Sandersville + Heart of Georgia)"
        },
        {
            "blessing_rieman_college_of_nursing": "Blessing Rieman College of Nursing"
        },
        {
            "tri_county_community_college": "Tri County Community College"
        },
        {
            "intellitec_college": "IntelliTec College"
        },
        {
            "lake_forest_graduate_school_of_management": "Lake Forest Graduate School of Management"
        },
        {
            "maine_community_college_system": "Maine Community College System"
        },
        {
            "delaware_college_of_art_and_design": "Delaware College of Art and Design"
        },
        {
            "university_of_holy_cross": "University of Holy Cross"
        },
        {
            "trinity_bible_college": "Trinity Bible College"
        },
        {
            "jamestown_business_college": "Jamestown Business College"
        },
        {
            "hallmark_university": "Hallmark University"
        },
        {
            "ranger_college": "Ranger College"
        },
        {
            "costal_pines_technical_college_(altamaha_+_okefenokee)": "Costal Pines Technical College (Altamaha + Okefenokee)"
        },
        {
            "san_joaquin_college_of_law": "San Joaquin College of Law"
        },
        {
            "mccann_school_of_business_and_technology": "McCann School of Business and Technology"
        },
        {
            "kishwaukee_college": "Kishwaukee College"
        },
        {
            "bridgevalley_community_and_technical_college": "BridgeValley Community and Technical College"
        },
        {
            "university_of_arkansas_hope_texarkana": "University of Arkansas Hope Texarkana"
        },
        {
            "gateway_seminary": "Gateway Seminary"
        },
        {
            "martin_community_college": "Martin Community College"
        },
        {
            "eagle_gate_college": "Eagle Gate College"
        },
        {
            "san_diego_university_for_integrative_studies_sduis": "San Diego University for Integrative Studies SDUIS"
        },
        {
            "pinnacle_career_institute": "Pinnacle Career Institute"
        },
        {
            "franciscan_school_of_theology": "Franciscan School of Theology"
        },
        {
            "westminster_college_fulton": "Westminster College Fulton"
        },
        {
            "provo_college": "Provo College"
        },
        {
            "alaska_bible_college": "Alaska Bible College"
        },
        {
            "davis_technical_college_in_utah_(davis_applied_technology_college)": "Davis Technical College in Utah (Davis Applied Technology College)"
        },
        {
            "tennessee_wesleyan_university": "Tennessee Wesleyan University"
        },
        {
            "swedish_institute_college_of_health_sciences": "Swedish Institute College of Health Sciences"
        },
        {
            "roanoke_chowan_community_college": "Roanoke Chowan Community College"
        },
        {
            "flint_hills_technical_college": "Flint Hills Technical College"
        },
        {
            "baptist_bible_college_springfield_missouri": "Baptist Bible College Springfield Missouri"
        },
        {
            "mid-atlantic_christian_university_(roanoke_bible_college)": "Mid-Atlantic Christian University (Roanoke Bible College)"
        },
        {
            "the_seattle_school_(mars_hill_graduate_school)": "The Seattle School (Mars Hill Graduate School)"
        },
        {
            "professional_golfers_career_college": "Professional Golfers Career College"
        },
        {
            "virginia_university_of_lynchburg": "Virginia University of Lynchburg"
        },
        {
            "memphis_theological_seminary": "Memphis Theological Seminary"
        },
        {
            "ohio_business_college": "Ohio Business College"
        },
        {
            "nashotah_house_theological_seminary": "Nashotah House Theological Seminary"
        },
        {
            "new_hope_christian_college_(eugene_bible_college)": "New Hope Christian College (Eugene Bible College)"
        },
        {
            "little_big_horn_college": "Little Big Horn College"
        },
        {
            "chatfield_college": "Chatfield College"
        },
        {
            "lac_courte_oreilles_ojibwa_community_college": "Lac Courte Oreilles Ojibwa Community College"
        },
        {
            "research_college_of_nursing": "Research College of Nursing"
        },
        {
            "american_indian_college_of_the_assemblies_of_god": "American Indian College of the Assemblies of God"
        },
        {
            "community_care_college": "Community Care College"
        },
        {
            "college_of_eastern_idaho": "College of Eastern Idaho"
        },
        {
            "pickens_technical_college": "Pickens Technical College"
        },
        {
            "gerstner_sloan-kettering_graduate_school_of_biomedical_sciences": "Gerstner Sloan-Kettering Graduate School of Biomedical Sciences"
        },
        {
            "southcentral_kentucky_community_&_technical_college_(bowling_green_technical_college)": "Southcentral Kentucky Community & Technical College (Bowling Green Technical College)"
        },
        {
            "triangle_tech": "Triangle Tech"
        },
        {
            "south_hills_school_of_business_and_technology": "South Hills School of Business and Technology"
        },
        {
            "arkansas_state_university_mid_south": "Arkansas State University Mid South"
        },
        {
            "lincoln_college_of_new_england_(clemens/briarwood_college)": "Lincoln College of New England (Clemens/Briarwood College)"
        },
        {
            "northwest_kansas_technical_college": "Northwest Kansas Technical College"
        },
        {
            "colgate_rochester_crozer_divinity_school": "Colgate Rochester Crozer Divinity School"
        },
        {
            "delta_college_of_arts_&_technology": "Delta College of Arts & Technology"
        },
        {
            "ilisagvik_college": "Ilisagvik College"
        },
        {
            "matanuska_susitna_college_mat_su": "Matanuska Susitna College Mat Su"
        },
        {
            "interior_designers_institute": "Interior Designers Institute"
        },
        {
            "pine_technical_&_community_college": "Pine Technical & Community College"
        },
        {
            "career_college_of_northern_nevada": "Career College of Northern Nevada"
        },
        {
            "leech_lake_tribal_college": "Leech Lake Tribal College"
        },
        {
            "beckfield_college": "Beckfield College"
        },
        {
            "blackfeet_community_college": "Blackfeet Community College"
        },
        {
            "manhattan_area_technical_college": "Manhattan Area Technical College"
        },
        {
            "trinity_college_of_nursing_&_health_sciences": "Trinity College of Nursing & Health Sciences"
        },
        {
            "boise_bible_college": "Boise Bible College"
        },
        {
            "cankdeska_cikana_community_college": "Cankdeska Cikana Community College"
        },
        {
            "sisseton_wahpeton_college": "Sisseton Wahpeton College"
        },
        {
            "sentara_college_of_health_sciences": "Sentara College of Health Sciences"
        },
        {
            "south_florida_bible_college_&_theological_seminary": "South Florida Bible College & Theological Seminary"
        },
        {
            "eastern_west_virginia_community_&_technical_college": "Eastern West Virginia Community & Technical College"
        },
        {
            "gutenberg_college": "Gutenberg College"
        },
        {
            "nossi_college_of_art": "NOSSI College of Art"
        },
        {
            "luther_rice_university": "Luther Rice University"
        },
        {
            "stautzenberger_college": "Stautzenberger College"
        },
        {
            "saint_luke's_college": "Saint Luke's College"
        },
        {
            "marian_college": "Marian College"
        },
        {
            "divine_mercy_university": "Divine Mercy University"
        },
        {
            "academy_college": "Academy College"
        },
        {
            "danville_community_college": "Danville Community College"
        },
        {
            "university_of_west_los_angeles": "University of West Los Angeles"
        },
        {
            "williamsburg_technical_college": "Williamsburg Technical College"
        },
        {
            "shasta_bible_college_&_graduate_school": "Shasta Bible College & Graduate School"
        },
        {
            "berks_technical_institute_pennsylvania_career_school": "Berks Technical Institute Pennsylvania Career School"
        },
        {
            "mid_america_baptist_theological_seminary": "Mid America Baptist Theological Seminary"
        },
        {
            "institute_for_clinical_social_work": "Institute for Clinical Social Work"
        },
        {
            "rockford_career_college": "Rockford Career College"
        },
        {
            "colorado_heights_university_(teikyo_loretto_heights)": "Colorado Heights University (Teikyo Loretto Heights)"
        },
        {
            "academy_of_vocal_arts": "Academy of Vocal Arts"
        },
        {
            "christ_college_of_nursing_&_health_sciences": "Christ College of Nursing & Health Sciences"
        },
        {
            "dallas_institute_of_funeral_service": "Dallas Institute of Funeral Service"
        },
        {
            "baptist_university_of_the_americas": "Baptist University of the Americas"
        },
        {
            "williams_baptist_university": "Williams Baptist University"
        },
        {
            "pennco_tech": "Pennco Tech"
        },
        {
            "wade_college": "Wade College"
        },
        {
            "richmont_graduate_university": "Richmont Graduate University"
        },
        {
            "salina_area_technical_college": "Salina Area Technical College"
        },
        {
            "acting_school_kd_studio": "Acting School KD Studio"
        },
        {
            "midwest_institute_for_medical_assistants": "Midwest Institute for Medical Assistants"
        },
        {
            "mid_america_college_of_funeral_service": "Mid America College of Funeral Service"
        },
        {
            "rosedale_technical_institute": "Rosedale Technical Institute"
        },
        {
            "christ_the_king_seminary": "Christ the King Seminary"
        },
        {
            "good_samaritan_college_of_nursing_&_health_science": "Good Samaritan College of Nursing & Health Science"
        },
        {
            "boston_baptist_college": "Boston Baptist College"
        },
        {
            "colorado_school_of_trades_gunsmithing_school": "Colorado School of Trades Gunsmithing School"
        },
        {
            "beal_college": "Beal College"
        },
        {
            "millennia_atlantic_university": "Millennia Atlantic University"
        },
        {
            "california_institute_for_human_science": "California Institute for Human Science"
        },
        {
            "harding_school_of_theology": "Harding School of Theology"
        },
        {
            "forrest_(junior)_college": "Forrest (Junior) College"
        },
        {
            "beulah_heights_university": "Beulah Heights University"
        },
        {
            "aaniiih_nakoda_college_(fort_belknap_college)": "Aaniiih Nakoda College (Fort Belknap College)"
        },
        {
            "crown_college_of_the_bible": "Crown College of the Bible"
        },
        {
            "blue_cliff_college": "Blue Cliff College"
        },
        {
            "notre_dame_seminary_graduate_school_of_theology": "Notre Dame Seminary Graduate School of Theology"
        },
        {
            "middle_tennessee_school_of_anesthesia": "Middle Tennessee School of Anesthesia"
        },
        {
            "north_central_institute": "North Central Institute"
        },
        {
            "advantage_career_institute": "Advantage Career Institute"
        },
        {
            "pentecostal_theological_seminary_(church_of_god_theological_seminary)": "Pentecostal Theological Seminary (Church of God Theological Seminary)"
        },
        {
            "john_a_gupton_college": "John A Gupton College"
        },
        {
            "kodiak_college_university_of_alaska": "Kodiak College University of Alaska"
        },
        {
            "magdalen_college": "Magdalen College"
        },
        {
            "randall_university_(hillsdale_free_will_baptist_college)": "Randall University (Hillsdale Free Will Baptist College)"
        },
        {
            "tennessee_college_of_applied_technology_memphis": "Tennessee College of Applied Technology Memphis"
        },
        {
            "new_castle_school_of_trades": "New Castle School of Trades"
        },
        {
            "tennessee_college_of_applied_technology_nashville": "Tennessee College of Applied Technology Nashville"
        },
        {
            "american_business_&_technology_university": "American Business & Technology University"
        },
        {
            "spencerian_college": "Spencerian College"
        },
        {
            "east_san_gabriel_valley_regional_occupational_program_&_technical_center": "East San Gabriel Valley Regional Occupational Program & Technical Center"
        },
        {
            "tohono_o'odham_community_college": "Tohono O'Odham Community College"
        },
        {
            "tennessee_college_of_applied_technology_knoxville": "Tennessee College of Applied Technology Knoxville"
        },
        {
            "providence_christian_college": "Providence Christian College"
        },
        {
            "huntington_junior_college": "Huntington Junior College"
        },
        {
            "southeastern_baptist_college": "Southeastern Baptist College"
        },
        {
            "patrick_henry_community_college": "Patrick Henry Community College"
        },
        {
            "southern_california_institute_of_technology": "Southern California Institute of Technology"
        },
        {
            "reid_state_technical_college": "Reid State Technical College"
        },
        {
            "saint_louis_christian_college": "Saint Louis Christian College"
        },
        {
            "saint_patrick's_seminary_&_university": "Saint Patrick's Seminary & University"
        },
        {
            "college_of_court_reporting": "College of Court Reporting"
        },
        {
            "knox_theological_seminary": "Knox Theological Seminary"
        },
        {
            "upper_institute_of_optics": "Upper Institute of Optics"
        },
        {
            "nyc_acting_school_for_tv_film_and_theater": "NYC Acting School for TV Film and Theater"
        },
        {
            "barber_scotia_college": "Barber Scotia College"
        },
        {
            "fine_mortuary_college_(funeral_institute_of_the_northeast)": "FINE Mortuary College (Funeral Institute of the Northeast)"
        },
        {
            "houston_graduate_school_of_theology": "Houston Graduate School of Theology"
        },
        {
            "prism_career_institute_(pja_school)": "Prism Career Institute (PJA School)"
        },
        {
            "long_island_business_institute": "Long Island Business Institute"
        },
        {
            "fox_college": "Fox College"
        },
        {
            "bethesda_university": "Bethesda University"
        },
        {
            "nueta_hidatsa_sahnish_college_(fort_berthold_community_college)": "Nueta Hidatsa Sahnish College (Fort Berthold Community College)"
        },
        {
            "denver_college_of_nursing": "Denver College of Nursing"
        },
        {
            "northpoint_bible_college_(zion_bible_college)": "Northpoint Bible College (Zion Bible College)"
        },
        {
            "oak_point_university": "Oak Point University"
        },
        {
            "university_of_arkansas_rich_mountain": "University of Arkansas Rich Mountain"
        },
        {
            "gupton_jones_college_of_funeral_service": "Gupton Jones College of Funeral Service"
        },
        {
            "indiana_university_fort_wayne": "Indiana University Fort Wayne"
        },
        {
            "american_academy_mcallister_institute_of_funeral_services": "American Academy Mcallister Institute of Funeral Services"
        },
        {
            "stevens_institute_of_business_and_arts_(patricia_stevens_college)": "Stevens Institute of Business and Arts (Patricia Stevens College)"
        },
        {
            "tennessee_college_of_applied_technology_murfreesboro": "Tennessee College of Applied Technology Murfreesboro"
        },
        {
            "sunbridge_institute": "Sunbridge Institute"
        },
        {
            "tillamook_bay_community_college": "Tillamook Bay Community College"
        },
        {
            "birthingway_college_of_midwifery": "Birthingway College of Midwifery"
        },
        {
            "saint_louis_college_of_health_careers": "Saint Louis College of Health Careers"
        },
        {
            "monterey_college_of_law": "Monterey College of Law"
        },
        {
            "rosedale_bible_college": "Rosedale Bible College"
        },
        {
            "messenger_college": "Messenger College"
        },
        {
            "uintah_basin_applied_technology_college": "Uintah Basin Applied Technology College"
        },
        {
            "college_of_the_florida_keys": "College of the Florida Keys"
        },
        {
            "international_business_college_indianapolis": "International Business College Indianapolis"
        },
        {
            "moore_tech_college_of_technology": "Moore Tech College of Technology"
        },
        {
            "tennessee_college_of_applied_technology_dickson": "Tennessee College of Applied Technology Dickson"
        },
        {
            "north_coast_college": "North Coast College"
        },
        {
            "phillips_graduate_university": "Phillips Graduate University"
        },
        {
            "orleans_tech_institute": "Orleans Tech Institute"
        },
        {
            "taylor_business_institute": "Taylor Business Institute"
        },
        {
            "tennessee_college_of_applied_technology_jackson": "Tennessee College of Applied Technology Jackson"
        },
        {
            "greenleaf_university": "Greenleaf University"
        },
        {
            "ohio_valley_college_of_technology": "Ohio Valley College of Technology"
        },
        {
            "assumption_college_for_sisters": "Assumption College for Sisters"
        },
        {
            "technical_college_of_the_rockies_(delta_montrose_area_vocational_technical_center)": "Technical College of the Rockies (Delta Montrose Area Vocational Technical Center)"
        },
        {
            "glendale_university_college_of_law": "Glendale University College of Law"
        },
        {
            "eti_technical_college_of_niles": "ETI Technical College of Niles"
        },
        {
            "morrison_institute_of_technology": "Morrison Institute of Technology"
        },
        {
            "heartland_baptist_bible_college": "Heartland Baptist Bible College"
        },
        {
            "university_of_tennessee_institute_for_public_service": "University of Tennessee Institute for Public Service"
        },
        {
            "marconi_internatioinal_university": "Marconi Internatioinal University"
        },
        {
            "california_national_university": "California National University"
        },
        {
            "commonwealth_institute_of_funeral_service": "Commonwealth Institute of Funeral Service"
        },
        {
            "prince_william_sound_college": "Prince William Sound College"
        },
        {
            "pillar_college_(somerset_christian_college)": "Pillar College (Somerset Christian College)"
        },
        {
            "tennessee_college_of_applied_technology_pulaski": "Tennessee College of Applied Technology Pulaski"
        },
        {
            "tennessee_college_of_applied_technology_crossville": "Tennessee College of Applied Technology Crossville"
        },
        {
            "oxford_graduate_school_dayton": "Oxford Graduate School Dayton"
        },
        {
            "jna_institute_of_culinary_arts": "JNA Institute of Culinary Arts"
        },
        {
            "tennessee_college_of_applied_technology_livingston": "Tennessee College of Applied Technology Livingston"
        },
        {
            "midwest_university": "Midwest University"
        },
        {
            "tennessee_college_of_applied_technology_athens": "Tennessee College of Applied Technology Athens"
        },
        {
            "tennessee_college_of_applied_technology_crump": "Tennessee College of Applied Technology Crump"
        },
        {
            "stefan_university": "Stefan University"
        },
        {
            "tennessee_college_of_applied_technology_hartsville": "Tennessee College of Applied Technology Hartsville"
        },
        {
            "tennessee_college_of_applied_technology_onedia": "Tennessee College of Applied Technology Onedia"
        },
        {
            "california_christian_college": "California Christian College"
        },
        {
            "camelot_college": "Camelot College"
        },
        {
            "prince_institute_of_professional_studies_(denver_academy_of_court_reporting)": "Prince Institute of Professional Studies (Denver Academy of Court Reporting)"
        },
        {
            "mech_tech_college": "Mech Tech College"
        },
        {
            "tennessee_college_of_applied_technology_harriman": "Tennessee College of Applied Technology Harriman"
        },
        {
            "tennessee_college_of_applied_technology_ripley": "Tennessee College of Applied Technology Ripley"
        },
        {
            "upper_valley_educators_institute": "Upper Valley Educators Institute"
        },
        {
            "tennessee_college_of_applied_technology_jacksboro": "Tennessee College of Applied Technology Jacksboro"
        },
        {
            "lamson_institute": "Lamson Institute"
        },
        {
            "city_university_los_angeles": "City University Los Angeles"
        },
        {
            "tennessee_college_of_applied_technology_whiteville": "Tennessee College of Applied Technology Whiteville"
        },
        {
            "myotherapy_institute": "Myotherapy Institute"
        },
        {
            "montessori_education_center_of_the_rockies": "Montessori Education Center of the Rockies"
        },
        {
            "ici_university": "ICI University"
        },
        {
            "westbrook_university": "Westbrook University"
        },
        {
            "family_of_faith_christian_university": "Family of Faith Christian University"
        },
        {
            "tennessee_college_of_applied_technology_mckenzie": "Tennessee College of Applied Technology McKenzie"
        },
        {
            "southern_methodist_college": "Southern Methodist College"
        },
        {
            "paier_college_of_art": "Paier College of Art"
        },
        {
            "sackler_school_of_medicine_new_york_state_american_program_of_tel_aviv_university": "Sackler School of Medicine New York State American Program of Tel Aviv University"
        },
        {
            "tri-college_university_program": "Tri-College University Program"
        },
        {
            "virginia_theological_university": "Virginia Theological University"
        },
        {
            "ihm_academy": "IHM Academy"
        },
        {
            "saint_basil_seminary": "Saint Basil Seminary"
        },
        {
            "california_university_of_management_and_sciences_virginia": "California University of Management and Sciences Virginia"
        },
        {
            "senior_college_of_san_carlos": "Senior College of San Carlos"
        },
        {
            "georgia_christian_university": "Georgia Christian University"
        },
        {
            "pace_university": "Pace University"
        }
    ],
    "new york city": [
        {
            "nazareth_college": "nazareth college"
        }
    ],
    "canada": [
        {
            "concordia_university_montreal": "Concordia University Montreal"
        },
        {
            "mcgill_university": "McGill University"
        },
        {
            "university_of_alberta": "University of Alberta"
        },
        {
            "queen's_university": "Queen's University"
        },
        {
            "york_university": "York University"
        },
        {
            "university_of_waterloo": "University of Waterloo"
        },
        {
            "dalhousie_university": "Dalhousie University"
        },
        {
            "mcmaster_university": "McMaster University"
        },
        {
            "simon_fraser_university": "Simon Fraser University"
        },
        {
            "university_of_saskatchewan": "University of Saskatchewan"
        },
        {
            "memorial_university_of_newfoundland": "Memorial University of Newfoundland"
        },
        {
            "memorial_university_of_newfoundland": "Memorial University of Newfoundland"
        },
        {
            "simon_fraser_university": "Simon Fraser University"
        },
        {
            "university_of_victoria": "University of Victoria"
        },
        {
            "university_of_guelph": "University of Guelph"
        },
        {
            "metropolian_university": "metropolian university"
        },
        {
            "university_of_toronto": "University of Toronto"
        },
        {
            "university_of_british_columbia": "University of British Columbia"
        },
        {
            "carleton_university": "Carleton University"
        },
        {
            "university_of_windsor": "University of Windsor"
        },
        {
            "university_of_british_columbia": "University of British Columbia"
        },
        {
            "university_of_manitoba": "University of Manitoba"
        },
        {
            "university_of_northern_british_columbia": "University of Northern British Columbia"
        },
        {
            "university_of_ottawa": "University of Ottawa"
        },
        {
            "new_york_institute_of_technology": "New York Institute of Technology"
        },
        {
            "university_of_new_brunswick": "University of New Brunswick"
        },
        {
            "brock_university": "Brock University"
        },
        {
            "university_of_lethbridge": "University of Lethbridge"
        },
        {
            "university_of_regina": "University of Regina"
        },
        {
            "wilfrid_laurier_university": "Wilfrid Laurier University"
        },
        {
            "university_of_winnipeg": "University of Winnipeg"
        },
        {
            "university_of_lethbridge": "University of Lethbridge"
        },
        {
            "british_columbia_institute_of_technology": "British Columbia Institute of Technology"
        },
        {
            "trent_university": "Trent University"
        },
        {
            "lakehead_university": "Lakehead University"
        },
        {
            "acadia_university": "Acadia University"
        },
        {
            "thompson_rivers_university": "Thompson Rivers University"
        },
        {
            "saint_mary's_university": "Saint Mary's University"
        },
        {
            "university_of_prince_edward_island": "University of Prince Edward Island"
        },
        {
            "seneca_college": "Seneca College"
        },
        {
            "\u00e9cole_de_technologie_sup\u00e9rieure": "\u00c9cole de technologie sup\u00e9rieure"
        },
        {
            "humber_college": "Humber College"
        },
        {
            "royal_roads_university": "Royal Roads University"
        },
        {
            "centennial_college": "Centennial College"
        },
        {
            "bishop's_university": "Bishop's University"
        },
        {
            "cape_breton_university": "Cape Breton University"
        },
        {
            "nipissing_university": "Nipissing University"
        },
        {
            "concordia_university_of_edmonton": "Concordia University of Edmonton"
        },
        {
            "royal_military_college_of_canada": "Royal Military College of Canada"
        },
        {
            "university_canada_west": "University Canada West"
        },
        {
            "hec_montreal_university": "HEC Montreal University"
        },
        {
            "university_of_montreal": "University of Montreal"
        },
        {
            "laurentian_university": "Laurentian University"
        },
        {
            "niagara_college_-_toronto": "Niagara College - Toronto"
        },
        {
            "ocad_university": "OCAD University"
        },
        {
            "st._francis_xavier_university_(stfx)": "St. Francis Xavier University (StFX)"
        },
        {
            "trebas_institute": "Trebas Institute"
        },
        {
            "university_of_ontario_institute_of_technology": "University of Ontario Institute of Technology"
        },
        {
            "western_university_-_canada": "Western University - Canada"
        },
        {
            "university_of_calgary": "University of Calgary"
        },
        {
            "laval_university": "Laval University"
        },
        {
            "university_of_quebec_montreal": "University of Quebec Montreal"
        },
        {
            "universit\u00e3\u00a9_de_sherbrooke": "Universit\u00c3\u00a9 de Sherbrooke"
        },
        {
            "polytechnic_school_of_montreal": "Polytechnic School of Montreal"
        },
        {
            "inrs_university_national_institute_for_scientific_research": "INRS University National Institute for Scientific Research"
        },
        {
            "university_of_quebec_trois-rivieres": "University of Quebec Trois-Rivieres"
        },
        {
            "trent_university": "Trent University"
        },
        {
            "university_of_quebec_chicoutimi": "University of Quebec Chicoutimi"
        },
        {
            "university_of_quebec": "University of Quebec"
        },
        {
            "athabasca_university": "Athabasca University"
        },
        {
            "university_of_moncton": "University of Moncton"
        },
        {
            "mount_allison_university": "Mount Allison University"
        },
        {
            "university_of_quebec_rimouski": "University of Quebec Rimouski"
        },
        {
            "mount_royal_university": "Mount Royal University"
        },
        {
            "university_of_quebec_outaouais": "University of Quebec Outaouais"
        },
        {
            "macewan_university_(grant_macewan_college)": "MacEwan University (Grant MacEwan College)"
        },
        {
            "brandon_university": "Brandon University"
        },
        {
            "mount_saint_vincent_university": "Mount Saint Vincent University"
        },
        {
            "northern_ontario_school_of_medicine_(laurentian_university_lakehead_university)": "Northern Ontario School of Medicine (Laurentian University Lakehead University)"
        },
        {
            "trinity_western_university": "Trinity Western University"
        },
        {
            "university_of_quebec_abitibi-temiscamingue": "University of Quebec Abitibi-Temiscamingue"
        },
        {
            "vancouver_island_university_(malaspina)": "Vancouver Island University (Malaspina)"
        },
        {
            "canadian_memorial_chiropractic_college": "Canadian Memorial Chiropractic College"
        },
        {
            "university_of_the_fraser_valley": "University of the Fraser Valley"
        },
        {
            "saint_thomas_university": "Saint Thomas University"
        },
        {
            "kwantlen_polytechnic_university_(kwantlen_university_college)": "Kwantlen Polytechnic University (Kwantlen University College)"
        },
        {
            "sheridan_college_institute_of_technology_and_advanced_learning": "Sheridan College Institute of Technology and Advanced Learning"
        },
        {
            "tele-university_teluq": "Tele-University TELUQ"
        },
        {
            "saint_paul_university": "Saint Paul University"
        },
        {
            "okanagan_college": "Okanagan College"
        },
        {
            "conestoga_college": "Conestoga College"
        },
        {
            "algoma_university": "Algoma University"
        },
        {
            "george_brown_college": "George Brown College"
        },
        {
            "douglas_college": "Douglas College"
        },
        {
            "trinity_college_in_the_university_of_toronto": "Trinity College in the University of Toronto"
        },
        {
            "algonquin_college": "Algonquin College"
        },
        {
            "mohawk_college": "Mohawk College"
        },
        {
            "langara_college": "Langara College"
        },
        {
            "southern_alberta_institute_of_technology": "Southern Alberta Institute of Technology"
        },
        {
            "bow_valley_college": "Bow Valley College"
        },
        {
            "emily_carr_university_of_art_+_design": "Emily Carr University of Art + Design"
        },
        {
            "fanshawe_college": "Fanshawe College"
        },
        {
            "nothern_alberta_institute_of_technology": "Nothern Alberta Institute of Technology"
        },
        {
            "capilano_university": "Capilano University"
        },
        {
            "georgian_college": "Georgian College"
        },
        {
            "national_school_of_public_administration": "National School of Public Administration"
        },
        {
            "niagara_college": "Niagara College"
        },
        {
            "vancouver_film_school": "Vancouver Film School"
        },
        {
            "vancouver_community_college": "Vancouver Community College"
        },
        {
            "king's_university_college": "King's University College"
        },
        {
            "camosun_college": "Camosun College"
        },
        {
            "red_river_college": "Red River College"
        },
        {
            "durham_college": "Durham College"
        },
        {
            "fleming_college": "Fleming College"
        },
        {
            "master's_college_and_seminary": "Master's College and Seminary"
        },
        {
            "nova_scotia_community_college": "Nova Scotia Community College"
        },
        {
            "c\u00e3\u00a9gep_du_vieux_montreal": "C\u00c3\u00a9gep du Vieux Montreal"
        },
        {
            "marianopolis_college": "Marianopolis College"
        },
        {
            "vanier_college": "Vanier College"
        },
        {
            "dawson_college_westmount_montreal": "Dawson College Westmount Montreal"
        },
        {
            "selkirk_college": "Selkirk College"
        },
        {
            "canadian_mennonite_university": "Canadian Mennonite University"
        },
        {
            "saint_lawrence_college_brockville": "Saint Lawrence College Brockville"
        },
        {
            "saint_clair_college": "Saint Clair College"
        },
        {
            "college_of_new_caledonia": "College of New Caledonia"
        },
        {
            "lambton_college": "Lambton College"
        },
        {
            "victoria_university_in_the_university_of_toronto": "Victoria University in the University of Toronto"
        },
        {
            "cambrian_college_of_applied_arts_and_technology": "Cambrian College of Applied Arts and Technology"
        },
        {
            "saskatchewan_polytechnic": "Saskatchewan Polytechnic"
        },
        {
            "c\u00e3\u00a9gep_de_rimouski": "C\u00c3\u00a9gep de Rimouski"
        },
        {
            "red_deer_college": "Red Deer College"
        },
        {
            "confederation_college": "Confederation College"
        },
        {
            "north_island_college": "North Island College"
        },
        {
            "regent_college_in_vancouver": "Regent College in Vancouver"
        },
        {
            "nscad_university_(nova_scotia_college_of_art_&_design)": "NSCAD University (Nova Scotia College of Art & Design)"
        },
        {
            "olds_college": "Olds College"
        },
        {
            "tyndale_university_college_&_seminary": "Tyndale University College & Seminary"
        },
        {
            "yukon_university": "Yukon University"
        },
        {
            "justice_institute_of_british_columbia": "Justice Institute of British Columbia"
        },
        {
            "college_of_the_rockies": "College of the Rockies"
        },
        {
            "norquest_college": "NorQuest College"
        },
        {
            "medicine_hat_college.": "Medicine Hat College."
        },
        {
            "college_of_the_north_atlantic": "College of the North Atlantic"
        },
        {
            "grande_prairie_regional_college": "Grande Prairie Regional College"
        },
        {
            "lethbridge_college": "Lethbridge College"
        },
        {
            "boreal_college": "Boreal college"
        },
        {
            "the_collegiate_city": "The Collegiate City"
        },
        {
            "quebec_tourism_and_hospitality_institute": "Quebec Tourism and Hospitality Institute"
        },
        {
            "northern_lights_college": "Northern Lights College"
        },
        {
            "university_of_saint-boniface_(university_college_of_saint_boniface)": "University of Saint-Boniface (University College of Saint Boniface)"
        },
        {
            "university_of_king's_college": "University of King's College"
        },
        {
            "john_abbott_college": "John Abbott College"
        },
        {
            "gaelic_college": "Gaelic College"
        },
        {
            "university_of_st_michael's_college": "University of St Michael's College"
        },
        {
            "northern_college_of_applied_arts_and_technology": "Northern College of Applied Arts and Technology"
        },
        {
            "lakeland_college_canada": "Lakeland College Canada"
        },
        {
            "canadian_college_of_naturopathic_medicine": "Canadian College of Naturopathic Medicine"
        },
        {
            "lasalle_college": "Lasalle College"
        },
        {
            "loyalist_college": "Loyalist College"
        },
        {
            "new_brunswick_community_college": "New Brunswick Community College"
        },
        {
            "canadore_college_of_applied_arts_and_technology": "Canadore College of Applied Arts and Technology"
        },
        {
            "holland_college": "Holland College"
        },
        {
            "quest_university": "Quest University"
        },
        {
            "sault_college_of_applied_arts_&_technology": "Sault College of Applied Arts & Technology"
        },
        {
            "redeemer_university": "Redeemer University"
        },
        {
            "ambrose_university_(alliance_&_nazarene)": "Ambrose University (Alliance & Nazarene)"
        },
        {
            "prairie_college": "Prairie College"
        },
        {
            "assiniboine_community_college": "Assiniboine Community College"
        },
        {
            "alberta_university_of_the_arts": "Alberta University of the Arts"
        },
        {
            "michener_institute_for_applied_health_sciences": "Michener Institute for Applied Health Sciences"
        },
        {
            "coast_mountain_college": "Coast Mountain College"
        },
        {
            "king's_university": "King's University"
        },
        {
            "national_theatre_school_of_canada": "National Theatre School of Canada"
        },
        {
            "cegep_de_sherbrooke": "Cegep de Sherbrooke"
        },
        {
            "yorkville_university": "Yorkville University"
        },
        {
            "ahuntsic_college": "Ahuntsic College"
        },
        {
            "first_nations_university_of_canada": "First Nations University of Canada"
        },
        {
            "brescia_university_college": "Brescia University College"
        },
        {
            "sprott_shaw_college": "Sprott Shaw College"
        },
        {
            "cegep_of_three_rivers": "Cegep of Three Rivers"
        },
        {
            "cdi_college": "CDI College"
        },
        {
            "saint_jerome's_university": "Saint Jerome's University"
        },
        {
            "columbia_college": "Columbia College"
        },
        {
            "vancouver_school_of_theology": "Vancouver School of Theology"
        },
        {
            "lasalle_college_montreal": "Lasalle College Montreal"
        },
        {
            "university_of_guelph_humber": "University of Guelph Humber"
        },
        {
            "ashton_college": "Ashton College"
        },
        {
            "montmorency_college": "Montmorency College"
        },
        {
            "rosemont_college": "Rosemont College"
        },
        {
            "academy_of_learning_college": "Academy of Learning College"
        },
        {
            "c\u00e3\u00a9gep_edward_montpetit": "C\u00c3\u00a9gep Edward Montpetit"
        },
        {
            "college_lionel_groulx": "College Lionel Groulx"
        },
        {
            "c\u00e3\u00a9gep_limoilou": "C\u00c3\u00a9gep Limoilou"
        },
        {
            "manitoba_institute_of_trades_and_technology": "Manitoba Institute of Trades and Technology"
        },
        {
            "luther_college_university_of_regina": "Luther College University of Regina"
        },
        {
            "c\u00e3\u00a9gep_de_jonqui\u00e3\u00a8re": "C\u00c3\u00a9gep de Jonqui\u00c3\u00a8re"
        },
        {
            "vancouver_institute_of_media_arts": "Vancouver Institute of Media Arts"
        },
        {
            "c\u00e3\u00a9gep_marie_victorin": "C\u00c3\u00a9gep Marie Victorin"
        },
        {
            "herzing_college_montreal": "Herzing College Montreal"
        },
        {
            "alexander_college": "Alexander College"
        },
        {
            "burman_university_(canadian_university_college)": "Burman University (Canadian University College)"
        },
        {
            "national_circus_school_montreal": "National Circus School Montreal"
        },
        {
            "nicola_valley_institute_of_technology": "Nicola Valley Institute of Technology"
        },
        {
            "balsillie_school_of_international_affairs": "Balsillie School of International Affairs"
        },
        {
            "providence_university_college": "Providence University College"
        },
        {
            "conservatory_of_music_and_dramatic_art_of_quebec": "Conservatory of Music and Dramatic Art of Quebec"
        },
        {
            "lasalle_college_vancouver": "Lasalle College Vancouver"
        },
        {
            "university_college_of_the_north": "University College of the North"
        },
        {
            "aurora_college.": "Aurora College."
        },
        {
            "briercrest_college_and_seminary": "Briercrest College and Seminary"
        },
        {
            "portage_college.": "Portage College."
        },
        {
            "college_of_bois_de_boulogne": "College of Bois de Boulogne"
        },
        {
            "saint_thomas_more_college": "Saint Thomas More College"
        },
        {
            "toronto_school_of_management": "Toronto School of Management"
        },
        {
            "saint_mary's_university_college": "Saint Mary's University College"
        },
        {
            "blyth_academy": "Blyth Academy"
        },
        {
            "college_fran\u00e3\u00a7ois_xavier_garneau": "College Fran\u00c3\u00a7ois Xavier Garneau"
        },
        {
            "cegep_of_gasp\u00e3\u00a9sie_and_islands": "CEGEP of Gasp\u00c3\u00a9sie and Islands"
        },
        {
            "new_brunswick_community_college": "New Brunswick Community College"
        },
        {
            "coquitlam_college": "Coquitlam College"
        },
        {
            "canadian_college": "Canadian College"
        },
        {
            "cegep_de_chicoutimi": "Cegep de Chicoutimi"
        },
        {
            "kingswood_university": "Kingswood University"
        },
        {
            "stenberg_college": "Stenberg College"
        },
        {
            "parkland_college_melville": "Parkland College Melville"
        },
        {
            "c\u00e3\u00a9gep_de_saint_laurent": "C\u00c3\u00a9gep de Saint Laurent"
        },
        {
            "private_training_institutions_(mti_community_college,_ashton_college)": "Private Training Institutions (MTI Community College, Ashton College)"
        },
        {
            "jean_de_br\u00e3\u00a9beuf_college": "Jean de Br\u00c3\u00a9beuf College"
        },
        {
            "university_of_sudbury": "University of Sudbury"
        },
        {
            "nunavut_arctic_college": "Nunavut Arctic College"
        },
        {
            "c\u00e3\u00a9gep_de_saint_hyacinthe": "C\u00c3\u00a9gep de Saint Hyacinthe"
        },
        {
            "robertson_college": "Robertson College"
        },
        {
            "c\u00e3\u00a9gep_de_l'abitibi_t\u00e3\u00a9miscamingue": "C\u00c3\u00a9gep de l'Abitibi T\u00c3\u00a9miscamingue"
        },
        {
            "c\u00e3\u00a9gep_de_matane": "C\u00c3\u00a9gep de Matane"
        },
        {
            "booth_university_college": "Booth University College"
        },
        {
            "pacific_link_college.": "Pacific Link College."
        },
        {
            "heritage_college_hull_gatineau": "Heritage College Hull Gatineau"
        },
        {
            "c\u00e9gep_de_l'outaouais": "C\u00e9gep de l'Outaouais"
        },
        {
            "huron_university_college_at_western": "Huron University College at Western"
        },
        {
            "cegep_lanaudiere_": "cegep lanaudiere "
        },
        {
            "atlantic_school_of_theology": "Atlantic School of Theology"
        },
        {
            "regis_college_university_of_toronto": "Regis College University of Toronto"
        },
        {
            "new_brunswick_college_of_craft_and_design": "New Brunswick College of Craft and Design"
        },
        {
            "saskatchewan_indian_institute_of_technologies": "Saskatchewan Indian Institute of Technologies"
        },
        {
            "lafleche_college": "Lafleche College"
        },
        {
            "six_nations_polytechnic": "Six Nations Polytechnic"
        },
        {
            "codecore_college": "CodeCore College"
        },
        {
            "university_of_hearst": "University of Hearst"
        },
        {
            "seminar_of_sherbrooke": "Seminar of Sherbrooke"
        },
        {
            "cornerstone_international_community_college": "Cornerstone International Community College"
        },
        {
            "newman_theological_college": "Newman Theological College"
        },
        {
            "college_m\u00e3\u00a9rici": "College M\u00c3\u00a9rici"
        },
        {
            "jci_institute": "JCI Institute"
        },
        {
            "champlain_regional_college_saint_lawrence_campus": "Champlain Regional College Saint Lawrence Campus"
        },
        {
            "great_plains_college": "Great Plains College"
        },
        {
            "sorel_tracy_cegep": "Sorel Tracy Cegep"
        },
        {
            "saint_stephen's_university": "Saint Stephen's University"
        },
        {
            "collegiale_sainte_anne": "Collegiale Sainte Anne"
        },
        {
            "c\u00e3\u00a9gep_de_loup_river": "C\u00c3\u00a9gep de Loup River"
        },
        {
            "first_nations_technical_institute": "First Nations Technical Institute"
        },
        {
            "valleyfield_college": "Valleyfield College"
        },
        {
            "vanwest_college": "VanWest College"
        },
        {
            "cumberland_college_canada": "Cumberland College Canada"
        },
        {
            "canada_college_montreal": "Canada College Montreal"
        },
        {
            "academy_of_applied_pharmaceutical_sciences": "Academy of Applied Pharmaceutical Sciences"
        },
        {
            "acsenda_school_of_management": "Acsenda School of Management"
        },
        {
            "cegeplapocatiere": "cegeplapocatiere"
        },
        {
            "oulton_college": "Oulton College"
        },
        {
            "g\u00e3\u00a9rald_godin_college": "G\u00c3\u00a9rald Godin College"
        },
        {
            "southeast_college": "Southeast College"
        },
        {
            "musitechnic_educational_services_montreal": "Musitechnic Educational Services Montreal"
        },
        {
            "carlton_trail_regional_college": "Carlton Trail Regional College"
        },
        {
            "cimt_college": "CIMT College"
        },
        {
            "dominican_university_college": "Dominican University College"
        },
        {
            "educacentre_college": "Educacentre College"
        },
        {
            "cegep_of_shawinigan": "Cegep of Shawinigan"
        },
        {
            "cegepde_saint_felicien": "Cegepde Saint Felicien"
        },
        {
            "academy_of_learning_career_college": "Academy of Learning Career College"
        },
        {
            "sainte-foy_cegep": "Sainte-Foy Cegep"
        },
        {
            "c\u00e3\u00a9gep_de_victoriaville": "C\u00c3\u00a9gep de Victoriaville"
        },
        {
            "champlain_college_lennoxville": "Champlain College Lennoxville"
        },
        {
            "c\u00e3\u00a9gep_de_sept_\u00e3\u017eles": "C\u00c3\u00a9gep de Sept \u00c3\u017dles"
        },
        {
            "think_tank_training_centre": "Think Tank Training Centre"
        },
        {
            "o'sullivan_college_of_montreal": "O'Sullivan College of Montreal"
        },
        {
            "eton_college_vancouver": "Eton College Vancouver"
        },
        {
            "teccart_institute": "Teccart Institute"
        },
        {
            "arbutus_college": "Arbutus College"
        },
        {
            "horizon_college_and_seminary_saskatoon": "Horizon College and Seminary Saskatoon"
        },
        {
            "c\u00e3\u00a9gep_de_thetford": "C\u00c3\u00a9gep de Thetford"
        },
        {
            "assumption_university": "Assumption University"
        },
        {
            "c\u00e9gep_de_baie-comeau": "C\u00e9gep de Baie-Comeau"
        },
        {
            "international_college_marie_de_france": "International College Marie de France"
        },
        {
            "vincent_of_indy_music_school": "Vincent of Indy music school"
        },
        {
            "montreal_technical_college": "Montreal Technical College"
        },
        {
            "champlain_regional_college": "Champlain Regional College"
        },
        {
            "maritime_college_of_forest_technology": "Maritime College of Forest Technology"
        },
        {
            "bart_college": "Bart College"
        },
        {
            "thorneloe_university": "Thorneloe University"
        },
        {
            "college_mathieu": "College Mathieu"
        },
        {
            "mckenzie_college": "McKenzie College"
        },
        {
            "northlands_college.": "Northlands College."
        },
        {
            "north_west_college_(north_west_regional_college)": "North West College (North West Regional College)"
        },
        {
            "coll\u00e3\u00a8ge_de_l'immobilier": "Coll\u00c3\u00a8ge de l'immobilier"
        },
        {
            "o'sullivan_college_in_quebec_city": "O'Sullivan College in Quebec City"
        },
        {
            "bethany_college": "Bethany College"
        },
        {
            "tav_college_montreal": "TAV College Montreal"
        },
        {
            "new_brunswick_bible_institute": "New Brunswick Bible Institute"
        },
        {
            "college_salette_montreal": "College Salette Montreal"
        },
        {
            "north_american_college_of_pharmaceutical_technology_nacpt": "North American College of Pharmaceutical Technology NACPT"
        },
        {
            "ellis_college": "Ellis College"
        },
        {
            "eston_college": "Eston College"
        },
        {
            "seminary_of_christ_the_king": "Seminary of Christ the King"
        },
        {
            "contemporary_dance_school_of_montreal": "Contemporary Dance School of Montreal"
        },
        {
            "matrix_college_of_management_technology_and_healthcare_inc": "Matrix College of Management Technology and Healthcare Inc"
        },
        {
            "maritime_christian_college": "Maritime Christian College"
        },
        {
            "huntington_university_canada": "Huntington University Canada"
        },
        {
            "ogwehoweh_skills_and_trades_training_centre": "Ogwehoweh Skills and Trades Training Centre"
        },
        {
            "nipawin_bible_college": "Nipawin Bible College"
        },
        {
            "biztech_college": "BizTech College"
        },
        {
            "dance_school_of_quebec": "Dance School of Quebec"
        },
        {
            "focus_college": "Focus College"
        },
        {
            "grasset_montreal_institute": "Grasset Montreal Institute"
        },
        {
            "higher_institute_of_computer_science_montreal": "Higher Institute of Computer Science Montreal"
        },
        {
            "canpacific_college_of_business_&_english_toronto": "CanPacific College of Business & English Toronto"
        },
        {
            "kenjgewin_teg": "Kenjgewin Teg"
        },
        {
            "april_fortier_college": "APRIL Fortier College"
        },
        {
            "centre_for_nursing_studies": "Centre for Nursing Studies"
        },
        {
            "western_academy_broadcasting_college_saskatoon": "Western Academy Broadcasting College Saskatoon"
        },
        {
            "commonwealth_college": "Commonwealth College"
        },
        {
            "null": null
        },
        {
            "pera_college": "Pera College"
        },
        {
            "multihexa_college": "MultiHexa College"
        },
        {
            "nordic_francophone_college": "Nordic Francophone College"
        },
        {
            "college_radio_television_of_quebec": "College Radio Television of Quebec"
        },
        {
            "college_city_marie": "College City Marie"
        },
        {
            "academy_canada": "Academy Canada"
        },
        {
            "academy_of_learning_college_prince_edward_island": "Academy of Learning College Prince Edward Island"
        },
        {
            "academy_of_quebec_entrepreneurship": "Academy of Quebec entrepreneurship"
        }
    ],
    "france": [
        {
            "centralesupelec": "CentraleSupelec"
        },
        {
            "audencia": "Audencia"
        },
        {
            "burgundy_school_of_business": "Burgundy School of Business"
        },
        {
            "cy_tech": "CY TECH"
        },
        {
            "burgundy_school_of_business": "Burgundy School of Business"
        },
        {
            "edc_paris_business_school": "EDC Paris Business School"
        },
        {
            "centrale_lille": "Centrale Lille"
        },
        {
            "ecole_des_ponts_paristech": "Ecole des Ponts ParisTech"
        },
        {
            "edhec_business_school": "EDHEC Business School"
        },
        {
            "edhec_business_school": "EDHEC Business School"
        },
        {
            "emlyon_business_school": "Emlyon Business School"
        },
        {
            "esc_clermont_business_school": "ESC Clermont Business School"
        },
        {
            "escp_business_school": "ESCP Business School"
        },
        {
            "esdes_lyon_business_school": "ESDES Lyon Business School"
        },
        {
            "grenoble_ecole_de_management": "Grenoble Ecole de Management"
        },
        {
            "essec_business_school": "ESSEC Business School"
        },
        {
            "grenoble_ecole_de_management": "Grenoble Ecole de Management"
        },
        {
            "grenoble_inp_institute_of_engineering_univ._grenoble_alpes": "Grenoble INP Institute of engineering Univ. Grenoble Alpes"
        },
        {
            "grenoble_inp_institute_of_engineering_univ._grenoble_alpes": "Grenoble INP Institute of engineering Univ. Grenoble Alpes"
        },
        {
            "hec_paris": "HEC Paris"
        },
        {
            "ieseg_school_of_management": "IESEG School of Management"
        },
        {
            "ieseg_school_of_management": "IESEG School of Management"
        },
        {
            "imt_atlantique_-_graduate_engineering_school": "IMT Atlantique - Graduate Engineering School"
        },
        {
            "insa_toulouse_-_institut_national_des_sciences_appliqu\u00e3\u00a9es": "INSA Toulouse - Institut National des Sciences Appliqu\u00c3\u00a9es"
        },
        {
            "kedge_business_school": "KEDGE Business School"
        },
        {
            "imt_atlantique_-_graduate_engineering_school": "IMT Atlantique - Graduate Engineering School"
        },
        {
            "jean_monnet_university": "Jean Monnet University"
        },
        {
            "kedge_business_school": "KEDGE Business School"
        },
        {
            "kedge_business_school": "KEDGE Business School"
        },
        {
            "montpellier_business_school": "Montpellier Business School"
        },
        {
            "neoma_business_school": "NEOMA Business School"
        },
        {
            "insead_-_the_business_school_for_the_world": "INSEAD - The Business School for the World"
        },
        {
            "inseec_u.": "INSEEC U."
        },
        {
            "institut_polytechnique_de_paris": "Institut Polytechnique de Paris"
        },
        {
            "pantheon-sorbonne_university": "Pantheon-Sorbonne University"
        },
        {
            "ipag_business_school": "IPAG Business School"
        },
        {
            "psb_paris_school_of_business": "PSB Paris School of Business"
        },
        {
            "science_po": "Science po"
        },
        {
            "skema_business_school": "SKEMA Business School"
        },
        {
            "skema_business_school": "SKEMA Business School"
        },
        {
            "skema_business_school": "SKEMA Business School"
        },
        {
            "university_de_lyon": "University de Lyon"
        },
        {
            "sorbonne_university": "Sorbonne University"
        },
        {
            "university_de_rennes": "University de Rennes"
        },
        {
            "university_grenoble_alpes": "University Grenoble Alpes"
        },
        {
            "university_of_bordeaux": "University of Bordeaux"
        },
        {
            "university_of_cote_d'azur": "University of Cote d'Azur"
        },
        {
            "university_of_cote_d'azur": "University of Cote d'Azur"
        },
        {
            "university_of_cote_d'azur": "University of cote d'Azur"
        },
        {
            "university_of_lille": "University of Lille"
        },
        {
            "university_of_lorraine": "University of Lorraine"
        },
        {
            "university_of_lorraine": "University of Lorraine"
        },
        {
            "university_of_technology_of_troyes": "University of Technology of Troyes"
        },
        {
            "university_of_lyon_i_(claude_bernard_university)": "University of Lyon I (Claude Bernard University)"
        },
        {
            "university_of_strasbourg": "University of Strasbourg"
        },
        {
            "university_of_aix_marseille": "University of Aix Marseille"
        },
        {
            "university_of_paris_(ipgp_descartes_diderot)": "University of Paris (IPGP Descartes Diderot)"
        },
        {
            "psl_university_paris": "PSL University Paris"
        },
        {
            "university_paris_saclay": "University Paris Saclay"
        },
        {
            "university_of_montpellier": "University of Montpellier"
        },
        {
            "university_of_nantes": "University of Nantes"
        },
        {
            "lyon_normal_school_(university_of_lyon)": "Lyon Normal School (University of Lyon)"
        },
        {
            "university_of_toulouse_iii_(toulouse_university_paul_sabatier)": "University of Toulouse III (Toulouse University Paul Sabatier)"
        },
        {
            "central_school_of_lyon": "Central School of Lyon"
        },
        {
            "university_of_tours_(university_fran\u00e3\u00a7ois_rabelais)": "University of Tours (University Fran\u00c3\u00a7ois Rabelais)"
        },
        {
            "university_of_burgundy_(university_of_dijon)": "University of Burgundy (University of Dijon)"
        },
        {
            "clermont_university_auvergne": "Clermont University Auvergne"
        },
        {
            "national_institute_of_applied_sciences_of_lyon_(insa_lyon)": "National Institute of Applied Sciences of Lyon (INSA Lyon)"
        },
        {
            "sorbonne_university_paris_nord_(university_of_paris_13)": "Sorbonne University Paris Nord (University of Paris 13)"
        },
        {
            "university_of_versailles_(university_of_versailles_saint_quentin_en_yvelines)": "University of Versailles (University of Versailles Saint Quentin en Yvelines)"
        },
        {
            "university_of_besan\u00e3\u00a7on_(university_of_franche_county)": "University of Besan\u00c3\u00a7on (University of Franche County)"
        },
        {
            "federal_university_of_toulouse_midi_pyr\u00e3\u00a9n\u00e3\u00a9es": "Federal University of Toulouse Midi Pyr\u00c3\u00a9n\u00c3\u00a9es"
        },
        {
            "university_of_caen_(university_caen_normandie)": "University of Caen (University Caen Normandie)"
        },
        {
            "coll\u00e3\u00a8ge_de_france_(psl_university)": "Coll\u00c3\u00a8ge de France (PSL University)"
        },
        {
            "university_of_brest_(university_of_western_brittany)": "University of Brest (University of Western Brittany)"
        },
        {
            "university_of_paris_xii_(paris_east_creteil_val_de_marne_university)": "University of Paris XII (Paris East Creteil Val de Marne University)"
        },
        {
            "university_of_chamb\u00e3\u00a9ry_(university_savoie_mont_blanc)": "University of Chamb\u00c3\u00a9ry (University Savoie Mont Blanc)"
        },
        {
            "university_of_rouen_(normandy_commue_university)": "University of Rouen (Normandy Commue University)"
        },
        {
            "agroparistech_science_and_environmental_institute": "AgroParisTech Science and Environmental Institute"
        },
        {
            "universit\u00e3\u00a9_d'angers": "Universit\u00c3\u00a9 d'Angers"
        },
        {
            "university_of_orleans_(com\u00e3\u00a9_center-val_de_loire)": "University of Orleans (Com\u00c3\u00a9 Center-Val de Loire)"
        },
        {
            "school_of_higher_education_in_social_sciences_ehess_(psl_university)": "School of Higher Education in Social Sciences EHESS (PSL University)"
        },
        {
            "school_normal_school_ens_paris_(psl_university)": "School Normal School Ens Paris (PSL University)"
        },
        {
            "university_of_mulhouse_(university_of_upper_alsace)": "University of Mulhouse (University of Upper Alsace)"
        },
        {
            "university_of_toulouse_ii_(toulouse_university_jean_jaures)": "University of Toulouse II (Toulouse University Jean Jaures)"
        },
        {
            "university_of_picardie_jules_verne_(university_of_amiens)": "University of Picardie Jules Verne (University of Amiens)"
        },
        {
            "national_conservatory_of_arts_and_crafts_cnam_(hesam_university)": "National Conservatory of Arts and Crafts CNAM (Hesam University)"
        },
        {
            "university_of_toulon": "University of Toulon"
        },
        {
            "university_of_perpignan": "University of Perpignan"
        },
        {
            "university_of_reims_(university_of_reims_champagne_ardenne)": "University of Reims (University of Reims Champagne Ardenne)"
        },
        {
            "university_of_montpellier_iii_(paul_val\u00e3\u00a9ry_university)": "University of Montpellier III (Paul Val\u00c3\u00a9ry University)"
        },
        {
            "university_of_pau_(university_of_pau_and_pays_de_l'adour)": "University of Pau (University of Pau and Pays de l'Adour)"
        },
        {
            "central_school_of_nantes": "Central School of Nantes"
        },
        {
            "eurecom_institute_engineering_school_in_communication_systems": "EURECOM Institute Engineering School in Communication Systems"
        },
        {
            "national_school_of_telecommunications_(telecom_paristech)": "National School of Telecommunications (Telecom Paristech)"
        },
        {
            "university_of_paris_x_(university_paris_west_nanterre_la_d\u00e3\u00a9fense)": "University of Paris X (University Paris West Nanterre La D\u00c3\u00a9fense)"
        },
        {
            "higher_national_school_of_caen_(ensi_caen)": "Higher National School of Caen (ENSI Caen)"
        },
        {
            "university_of_avignon_(university_of_avignon_and_pays_de_vaucluse)": "University of Avignon (University of Avignon and Pays de Vaucluse)"
        },
        {
            "university_of_le_mans_(university_of_maine)": "University of Le Mans (University of Maine)"
        },
        {
            "senior_normale_school_paris_saclay_(ens_cachan)": "Senior Normale School Paris Saclay (ENS Cachan)"
        },
        {
            "university_of_lyon_ii_(lumi\u00e3\u00a8re_lyon_ii_university)": "University of Lyon II (Lumi\u00c3\u00a8re Lyon II University)"
        },
        {
            "university_of_brittany_south": "University of Brittany South"
        },
        {
            "university_of_rennes_ii_(university_rennes_ii_upper_brittany)": "University of Rennes II (University Rennes II Upper Brittany)"
        },
        {
            "university_of_reunion": "University of Reunion"
        },
        {
            "agrocampus_ouest_(higher_institute_of_agronomic_agronomic_sciences_horticultural_and_landscape)": "Agrocampus Ouest (Higher Institute of Agronomic Agronomic Sciences Horticultural and Landscape)"
        },
        {
            "university_of_evry_val_d'essonne": "University of Evry Val d'Essonne"
        },
        {
            "polytechnic_hauts-de-france_(university_of_valenciennes)": "Polytechnic Hauts-de-France (University of Valenciennes)"
        },
        {
            "university_of_toulouse_i_(toulouse_capitole_university)": "University of Toulouse I (Toulouse Capitole University)"
        },
        {
            "cy_cergy_paris_university": "CY CERGY Paris University"
        },
        {
            "national_institute_of_applied_sciences_of_rennes_(insa_rennes)": "National Institute of Applied Sciences of Rennes (INSA Rennes)"
        },
        {
            "university_of_littoral_(littoral_university_opal_coast)": "University of Littoral (Littoral University Opal Coast)"
        },
        {
            "national_institute_of_applied_sciences_of_rouen_(insa_rouen)": "National Institute of Applied Sciences of Rouen (INSA Rouen)"
        },
        {
            "practical_high_education_ecole_(psl_university)": "Practical High Education Ecole (PSL University)"
        },
        {
            "university_of_artois": "University of Artois"
        },
        {
            "telecom_sudparis_national_institute_of_telecommunications": "Telecom Sudparis National Institute of Telecommunications"
        },
        {
            "vet_agro_sup_(institute_of_higher_education_and_food_research_animal_health_agronomic_and_environmental_sciences)": "Vet Agro Sup (Institute of Higher Education and Food Research Animal Health Agronomic and Environmental Sciences)"
        },
        {
            "school_of_higher_public_health_ehesp_(alliance_sorbonne_paris_city)": "School of Higher Public Health EHESP (Alliance Sorbonne Paris City)"
        },
        {
            "national_school_of_chemistry_of_montpellier_enscm": "National School of Chemistry of Montpellier ENSCM"
        },
        {
            "toulouse_school_of_economics": "Toulouse School of Economics"
        },
        {
            "national_veterinary_school_of_toulouse_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "National Veterinary School of Toulouse (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "national_school_of_advanced_techniques_(ensta_paris)": "National School of Advanced Techniques (ENSTA Paris)"
        },
        {
            "university_of_bordeaux_iii_(university_bordeaux_montaigne)": "University of Bordeaux III (University Bordeaux Montaigne)"
        },
        {
            "national_school_of_mechanics_and_aerotechnics_of_poitiers_isae_enma_(comude_confederal_university_leonardo_da_vinci)": "National School of Mechanics and Aerotechnics of Poitiers Isae Enma (Comude Confederal University Leonardo da Vinci)"
        },
        {
            "university_of_le_havre_(university_le_havre_normandie)": "University of Le Havre (University Le Havre Normandie)"
        },
        {
            "university_of_limoges_(com\u00e3\u00a9_confederal_university_leonardo_da_vinci)": "University of Limoges (Com\u00c3\u00a9 Confederal University Leonardo da Vinci)"
        },
        {
            "central_school_of_marseille": "Central School of Marseille"
        },
        {
            "national_school_of_public_works_of_the_state_entap_(universit\u00e3\u00a9_university_de_lyon)": "National School of Public Works of the State Entap (Universit\u00c3\u00a9 University de Lyon)"
        },
        {
            "mines_paristech_(psl_university)": "Mines ParisTech (PSL University)"
        },
        {
            "university_of_lyon_iii_(university_jean_moulin_lyon_iii)": "University of Lyon III (University Jean Moulin Lyon III)"
        },
        {
            "arts_and_trades_paristech_(national_school_of_arts_and_crafts)": "Arts and Trades PARISTECH (National School of Arts and Crafts)"
        },
        {
            "university_of_paris_iii_(new_sorbonne_university)": "University of Paris III (New Sorbonne University)"
        },
        {
            "agrosup_dijon_(national_institute_of_agronomic_sciences_of_food_and_environment)": "AgroSup Dijon (National Institute of Agronomic Sciences of Food and Environment)"
        },
        {
            "national_institute_of_agronomic_graduate_studies_of_montpellier_(montpellier_sup_agro)": "National Institute of Agronomic Graduate Studies of Montpellier (Montpellier Sup Agro)"
        },
        {
            "national_school_of_civil_aviation_enac_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "National School of Civil Aviation ENAC (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "university_of_paris_viii_(vincennes_university_saint_denis)": "University of Paris VIII (Vincennes University Saint Denis)"
        },
        {
            "chemistry_paristech_(national_school_of_chemistry_of_paris_enscp)": "Chemistry ParisTech (National School of Chemistry of Paris ENSCP)"
        },
        {
            "national_veterinary_school_of_alfort_enva_(comude_university_paris_east)": "National Veterinary School of Alfort Enva (Comude University Paris East)"
        },
        {
            "gustave_eiffel_university_(paris_east_marne_la_vall\u00e3\u00a9e)": "Gustave Eiffel University (Paris East Marne La Vall\u00c3\u00a9e)"
        },
        {
            "university_of_la_rochelle_(comue_confederal_university_leonardo_da_vinci)": "University of La Rochelle (Comue Confederal University Leonardo da Vinci)"
        },
        {
            "national_agribusiness_veterinary_school_and_nantes_atlantique_oniris": "National Agribusiness Veterinary School and Nantes Atlantique Oniris"
        },
        {
            "national_school_of_electronics_and_its_applications_ensa_(polytechnic_institute_of_greater_paris)_(cy_cergy_paris_university)": "National School of Electronics and its Applications ENSA (Polytechnic Institute of Greater Paris) (CY CERGY Paris University)"
        },
        {
            "national_school_of_statistics_and_economic_administration_ensae_paristech_(polytechnic_institute_of_paris)": "National School of Statistics and Economic Administration ENSAE ParisTech (Polytechnic Institute of Paris)"
        },
        {
            "rennes_school_of_business": "Rennes School of Business"
        },
        {
            "bordeaux_inp_(polytechnic_institute_of_bordeaux)": "Bordeaux INP (Polytechnic Institute of Bordeaux)"
        },
        {
            "national_school_of_mines_de_saint_stephen": "National School of Mines de Saint Stephen"
        },
        {
            "catholic_university_of_lille_(university_federation_and_polytechnic_lille)": "Catholic University of Lille (University Federation and Polytechnic Lille)"
        },
        {
            "antilles_university": "Antilles University"
        },
        {
            "national_institute_of_applied_sciences_of_strasbourg_(insa_strasbourg)": "National Institute of Applied Sciences of Strasbourg (INSA Strasbourg)"
        },
        {
            "national_school_of_mechanics_and_microtechnics_of_besan\u00e3\u00a7on_ensmm_(comue_university_burgundy_franche_comt\u00e3\u00a9)": "National School of Mechanics and Microtechnics of Besan\u00c3\u00a7on Ensmm (Comue University Burgundy Franche Comt\u00c3\u00a9)"
        },
        {
            "american_university_of_paris": "American University of Paris"
        },
        {
            "national_institute_of_eastern_languages_\u00e2\u20ac\u2039\u00e2\u20ac\u2039and_civilizations_inalco_(alliance_sorbonne_paris_city)": "National Institute of Eastern Languages \u00e2\u20ac\u2039\u00e2\u20ac\u2039and Civilizations Inalco (Alliance Sorbonne Paris City)"
        },
        {
            "school_of_electronic_physics_electronic_chemistry_of_lyon_cpe_lyon": "School of Electronic Physics Electronic Chemistry of Lyon CPE Lyon"
        },
        {
            "normandy_university_(normandy_commune_university)": "Normandy University (Normandy Commune University)"
        },
        {
            "bordeaux_sciences_agro_(national_school_of_agronomic_sciences)": "Bordeaux Sciences Agro (National School of Agronomic Sciences)"
        },
        {
            "university_of_paris_ii_(university_panth\u00e3\u00a9on_assas)": "University of Paris II (University Panth\u00c3\u00a9on Assas)"
        },
        {
            "community_of_universities_and_institutions_lille_nord_de_france": "Community of universities and institutions Lille Nord de France"
        },
        {
            "national_school_of_chemistry_of_lille": "National School of Chemistry of Lille"
        },
        {
            "sciences_po_bordeaux_institute_of_political_studies_of_bordeaux": "Sciences Po Bordeaux Institute of Political Studies of Bordeaux"
        },
        {
            "sigma_clermont_engineering_school": "SIGMA Clermont Engineering School"
        },
        {
            "university_of_corsica_pasquale_paoli": "University of Corsica Pasquale Paoli"
        },
        {
            "institute_of_physics_of_the_globe_of_paris_ipgp_(alliance_sorbonne_paris_cit\u00e3\u00a9)": "Institute of Physics of the Globe of Paris IPGP (Alliance Sorbonne Paris Cit\u00c3\u00a9)"
        },
        {
            "national_school_of_strasbourg_water_and_environment_genie_and_environment": "National School of Strasbourg Water and Environment Genie and Environment"
        },
        {
            "isen_school_of_general_engineer_in_high_technology_and_digital": "ISEN School of General Engineer in High Technology and Digital"
        },
        {
            "national_school_of_formation_of_agricultural_education_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "National School of Formation of Agricultural Education (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "albi_carmaux_mining_school_imt_albi_carmaux_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "Albi Carmaux Mining School IMT Albi Carmaux (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "national_school_of_engineers_of_metz": "National School of Engineers of Metz"
        },
        {
            "university_paris_dauphine_(university_paris_9)": "University Paris Dauphine (University Paris 9)"
        },
        {
            "national_school_of_engineers_of_tarbes_enit_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "National School of Engineers of Tarbes Enit (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "national_school_of_chemistry_of_rennes_enscr": "National School of Chemistry of Rennes ENSCR"
        },
        {
            "national_school_of_arts_and_textile_industries": "National School of Arts and Textile Industries"
        },
        {
            "higher_institute_of_aeronautics_and_espace_isae_supaero": "Higher Institute of Aeronautics and Espace Isae Supaero"
        },
        {
            "higher_institute_of_electronics_of_paris": "Higher Institute of Electronics of Paris"
        },
        {
            "neoma_business_school_(rouen_business_school)": "NEOMA Business School (Rouen Business School)"
        },
        {
            "esiee_paris_(comue_university_paris_east)": "ESIEE Paris (Comue University Paris East)"
        },
        {
            "university_of_new_caledonia": "University of New Caledonia"
        },
        {
            "georgia_tech_lorraine": "Georgia Tech Lorraine"
        },
        {
            "toulouse_business_school_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "Toulouse Business School (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "university_paris_east_(comude_university_paris_east)": "University Paris East (Comude University Paris East)"
        },
        {
            "school_of_business_sciences_of_angers": "School of Business Sciences of Angers"
        },
        {
            "public_school_and_transdisciplinary_engineering_of_limoges_ensil_ensci": "Public School and Transdisciplinary Engineering of Limoges Ensil ENSCI"
        },
        {
            "unilasalle": "UniLaSalle"
        },
        {
            "cesi_center_engineering_school_for_industrial_studies": "CESI Center Engineering School for Industrial Studies"
        },
        {
            "international_space_university": "International Space University"
        },
        {
            "junia_isen_lille": "Junia ISEN Lille"
        },
        {
            "imt_lille_douai_(mines_douai_+_telecom_lille)": "IMT Lille Douai (Mines Douai + Telecom Lille)"
        },
        {
            "national_school_of_architecture_of_nantes_ensan": "National School of Architecture of Nantes ENSAN"
        },
        {
            "central_school_of_electronics_ece_paris": "Central School of Electronics ECE Paris"
        },
        {
            "university_of_nimes": "University of Nimes"
        },
        {
            "naval_school": "Naval school"
        },
        {
            "l\u00e3\u00a9onard_university_of_vinci": "L\u00c3\u00a9onard University of Vinci"
        },
        {
            "sciences_po_lyon_(institute_of_political_studies_of_lyon)": "Sciences Po Lyon (Institute of Political Studies of Lyon)"
        },
        {
            "agronomic_national_school_of_toulouse_inp_ensat_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "Agronomic National School of Toulouse Inp Ensat (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "catholic_university_of_lyon": "Catholic University of Lyon"
        },
        {
            "university_of_guyana": "University of Guyana"
        },
        {
            "national_school_of_engineers_of_brest_brittany_enib": "National School of Engineers of Brest Brittany ENIB"
        },
        {
            "great_engineering_school_in_computer_science_and_electronics_a_angers": "Great Engineering School in Computer Science and Electronics A Angers"
        },
        {
            "telecom_mining_institute_(telecom_sudparis_+_telecom_paristech)_(polytechnic_institute_of_paris)": "Telecom Mining Institute (Telecom Sudparis + Telecom Paristech) (Polytechnic Institute of Paris)"
        },
        {
            "school_of_advanced_industrial_technologies": "School of Advanced Industrial Technologies"
        },
        {
            "national_school_of_information_science_and_library": "National School of Information Science and Library"
        },
        {
            "catholic_institute_of_paris": "Catholic Institute of Paris"
        },
        {
            "national_school_of_statistics_and_information_analysis_ensai": "National School of Statistics and Information Analysis ENSAI"
        },
        {
            "engineering_school_isen_brest": "Engineering School ISEN Brest"
        },
        {
            "academy_of_orleans_tours": "Academy of Orleans Tours"
        },
        {
            "national_distance_teaching_center_cned": "National Distance Teaching Center CNED"
        },
        {
            "institute_of_higher_scientific_studies_ihes_(comue_university_paris_saclay)": "Institute of Higher Scientific Studies IHES (Comue University Paris Saclay)"
        },
        {
            "national_school_of_architecture_of_grenoble_ensag_(community_grenoble_university_alps)": "National School of Architecture of Grenoble ENSAG (Community Grenoble University Alps)"
        },
        {
            "higher_institute_of_modeling_informatics_and_applications": "Higher Institute of Modeling Informatics and Applications"
        },
        {
            "observatory_of_paris_psl": "Observatory of Paris PSL"
        },
        {
            "paris_school_of_economics": "Paris School of Economics"
        },
        {
            "polytechnic_school_lille_polytech_lille": "Polytechnic School Lille Polytech Lille"
        },
        {
            "national_institute_of_art_history_inha_(hesam_university)": "National Institute of Art History inha (Hesam University)"
        },
        {
            "estaca_engineering_school": "ESTACA engineering school"
        },
        {
            "national_school_of_ensad_decorative_arts_(psl_university)": "National School of Ensad Decorative Arts (PSL University)"
        },
        {
            "national_institute_of_university_jean_fran\u00e3\u00a7ois_champollion_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "National Institute of University Jean Fran\u00c3\u00a7ois Champollion (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "west_catholic_university": "West Catholic University"
        },
        {
            "national_school_of_administration_ena": "National School of Administration ena"
        },
        {
            "gobelins_image_school": "Gobelins Image School"
        },
        {
            "institute_mining-telecom_business_school_(telecom_management_school)": "Institute Mining-Telecom Business School (Telecom Management School)"
        },
        {
            "european_business_school_paris": "European Business School Paris"
        },
        {
            "supinfo_international_university_informatique_higher_school": "Supinfo International University Informatique Higher School"
        },
        {
            "icn_school_of_management_of_nancy": "ICN School of Management of Nancy"
        },
        {
            "french_institute_of_education_(pedagogical_research)": "French Institute of Education (Pedagogical Research)"
        },
        {
            "acad\u00e3\u00a9mie_de_france_in_rome_dite_villa_medici": "Acad\u00c3\u00a9mie de France in Rome Dite Villa Medici"
        },
        {
            "paris_college_of_art": "Paris College of Art"
        },
        {
            "national_school_of_industrial_creation_ensci_les_ateliers_(hesam_university)": "National School of Industrial Creation Ensci Les Ateliers (Hesam University)"
        },
        {
            "national_school_of_geographic_sciences": "National School of Geographic Sciences"
        },
        {
            "senior_national_conservatory_of_music_and_dance_of_paris_cnsmd_paris_(psl_university)": "Senior National Conservatory of Music and Dance of Paris CNSMD Paris (PSL University)"
        },
        {
            "normandy_school_of_management_(em_normandy)": "Normandy School of Management (EM Normandy)"
        },
        {
            "nantes_atlantic_design_school": "Nantes Atlantic design school"
        },
        {
            "national_institute_of_sport_expertise_and_performance": "National Institute of Sport Expertise and Performance"
        },
        {
            "national_higher_education_center_cine": "National Higher Education Center Cine"
        },
        {
            "paul_bocuse_lyon_institute": "Paul Bocuse Lyon Institute"
        },
        {
            "ipsa_school_of_engineers_in_aeronautics_and_space": "IPSA School of Engineers in Aeronautics and Space"
        },
        {
            "efrei_paris_engineering_school_of_digital_tegnologies": "Efrei Paris Engineering School of Digital Tegnologies"
        },
        {
            "esmod_international": "ESMOD international"
        },
        {
            "french_extreme_east_school_(psl_university)": "French Extreme East School (PSL University)"
        },
        {
            "french_school_of_athenes": "French school of Athenes"
        },
        {
            "national_engineering_school_of_saint_etienne_enise_(university_of_lyon)": "National Engineering School of Saint Etienne Enise (University of Lyon)"
        },
        {
            "national_school_of_architecture_of_lyon_ensal": "National School of Architecture of Lyon ENSAL"
        },
        {
            "international_center_for_mathematics": "International Center for Mathematics"
        },
        {
            "national_school_of_fine_arts_(psl_university)": "National School of Fine Arts (PSL University)"
        },
        {
            "the_fresnoy_national_studio_of_contemporary_arts": "The Fresnoy National Studio of Contemporary Arts"
        },
        {
            "sciences_po_grenoble_institute_of_political_studies_of_grenoble_(community_university_grenoble_alps)": "Sciences Po Grenoble Institute of Political Studies of Grenoble (Community University Grenoble Alps)"
        },
        {
            "catholic_institute_of_arts_and_trades": "Catholic Institute of Arts and Trades"
        },
        {
            "louvre_school_(hesam_university)": "Louvre School (Hesam University)"
        },
        {
            "national_charter_school_(psl_university)": "National Charter School (PSL University)"
        },
        {
            "training_center_for_journalists": "Training Center for Journalists"
        },
        {
            "school_of_journalism_of_lille": "School of Journalism of Lille"
        },
        {
            "special_school_of_public_works_of_building_and_industry": "Special school of public works of building and industry"
        },
        {
            "school_of_agriculture_of_angers_esa": "School of Agriculture of Angers ESA"
        },
        {
            "national_institute_of_inp_heritage_(hesam_university)": "National Institute of InP Heritage (Hesam University)"
        },
        {
            "national_school_of_art_of_nice_villa_arson__(c\u00e3\u00b4te_d'azur_university)": "National School of Art of Nice Villa Arson  (C\u00c3\u00b4te d'Azur University)"
        },
        {
            "national_institute_of_training_and_research_for_education_of_young_disabled_and_adapted_teachings_in_hea": "National Institute of Training and Research for Education of Young Disabled and Adapted Teachings IN HEA"
        },
        {
            "national_and_university_library_of_strasbourg": "National and University Library of Strasbourg"
        },
        {
            "sciences_po_toulouse_institute_of_political_studies_of_toulouse_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "Sciences Po Toulouse Institute of Political Studies of Toulouse (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "institute_of_higher_studies_of_the_ihemi_ministry_of_the_interior": "Institute of Higher Studies of the Ihemi Ministry of the Interior"
        },
        {
            "women's_polytechnic_school": "Women's Polytechnic School"
        },
        {
            "normal_school_of_rennes_(ens_rennes)": "Normal School of Rennes (ENS Rennes)"
        },
        {
            "national_school_of_petrole_and_engines": "National School of Petrole and Engines"
        },
        {
            "sorbonne_universities_(comue_sorbonne_universities)": "Sorbonne Universities (Comue Sorbonne Universities)"
        },
        {
            "international_trade_school": "International trade school"
        },
        {
            "academy_of_technology": "Academy of Technology"
        },
        {
            "higher_institute_of_commerce_business_school": "Higher Institute of Commerce Business School"
        },
        {
            "rennes_school_of_business": "Rennes School of Business"
        },
        {
            "institute_of_higher_national_defense_studies_ihedn": "Institute of Higher National Defense Studies IHEDN"
        },
        {
            "high_school_of_rhine_arts_(school_of_art_and_design_strasbourg)": "High School of Rhine Arts (School of Art and Design Strasbourg)"
        },
        {
            "national_school_of_magistracy_enm": "National School of Magistracy ENM"
        },
        {
            "boulle_paris_school": "Boulle Paris School"
        },
        {
            "national_school_of_architecture_marseille": "National School of Architecture Marseille"
        },
        {
            "school_of_communication_professions": "School of communication professions"
        },
        {
            "national_school_of_landscape_of_versailles_ensp_(cy_cergy_paris_university)": "National School of Landscape of Versailles ENSP (CY CERGY Paris University)"
        },
        {
            "strasbourg_management_school": "Strasbourg management school"
        },
        {
            "national_school_of_applied_sciences_and_technology": "National School of Applied Sciences and Technology"
        },
        {
            "french_school_of_rome": "French School of Rome"
        },
        {
            "iseg_business_school": "ISEG business school"
        },
        {
            "higher_school_of_commerce_school_of_communication_idrac": "Higher School of Commerce School of Communication IDRAC"
        },
        {
            "senior_national_school_louis_light": "Senior National School Louis Light"
        },
        {
            "higher_national_conservatory_of_music_and_dance_of_lyon_cnsmd_lyon": "Higher National Conservatory of Music and Dance of Lyon CNSMD Lyon"
        },
        {
            "schools_of_saint-cyr_co\u00e3\u00abtquidan": "Schools of Saint-Cyr Co\u00c3\u00abtquidan"
        },
        {
            "visual_communication_school": "Visual communication school"
        },
        {
            "national_school_of_informatics_and_applied_mathematics_of_grenoble": "National School of Informatics and Applied Mathematics of Grenoble"
        },
        {
            "catholic_school_of_arts_and_crafts_ecam_lyon": "Catholic School of Arts and Crafts ECAM Lyon"
        },
        {
            "sciences_po_aix_institute_of_political_studies_of_aix_en_provence": "Sciences Po Aix Institute of Political Studies of Aix en Provence"
        },
        {
            "school_of_engineers_esigelec_irseem": "School of Engineers ESIGELEC IRSEEM"
        },
        {
            "national_school_of_architecture_of_paris_la_villette_(ensaplv_(hesam_university)": "National School of Architecture of Paris La Villette (ENSAPLV (Hesam University)"
        },
        {
            "nancy_school_of_mines": "Nancy School of Mines"
        },
        {
            "university_institute_of_france": "University Institute of France"
        },
        {
            "national_school_of_informatics_for_industry_and_company": "National School of Informatics for Industry and Company"
        },
        {
            "sorbonne_university_paris_city_(alliance_sorbonne_paris_city)": "Sorbonne University Paris City (Alliance Sorbonne Paris City)"
        },
        {
            "international_studies_center_of_industrial_property_strasbourg": "International Studies Center of Industrial Property Strasbourg"
        },
        {
            "special_school_of_mechanics_and_electricity_esme_sudria": "Special School of Mechanics and Electricity Esme Sudria"
        },
        {
            "great_school_of_digital": "Great School of Digital"
        },
        {
            "sciences_po_lille_institute_of_political_studies_of_lille": "Sciences Po Lille Institute of Political Studies of Lille"
        },
        {
            "national_school_of_fine_arts_in_lyon": "National School of Fine Arts in Lyon"
        },
        {
            "inspe_of_the_paris_academy": "Inspe of the Paris Academy"
        },
        {
            "school_of_audiovisual_establishment_cinema_animation_esra_(c\u00e3\u00b4te_d'azur_university)": "School of Audiovisual Establishment Cinema Animation ESRA (C\u00c3\u00b4te d'Azur University)"
        },
        {
            "national_audiovisual_institute": "National Audiovisual Institute"
        },
        {
            "school_of_engineering_in_physics,_electronics,_materials_grenoble_phelma": "School of Engineering in Physics, Electronics, Materials Grenoble Phelma"
        },
        {
            "special_school_of_architecture_of_paris": "Special School of Architecture of Paris"
        },
        {
            "engineering_school_paris-defense_esilv_university_pole_leonardo_da_vinci": "Engineering School Paris-Defense Esilv University Pole Leonardo da Vinci"
        },
        {
            "higher_institute_of_agriculture_and_agribusiness_rh\u00e3\u00b4ne_alpes_isara_lyon": "Higher Institute of Agriculture and Agribusiness Rh\u00c3\u00b4ne Alpes Isara Lyon"
        },
        {
            "school_of_engineers_of_the_city_of_paris": "School of Engineers of the City of Paris"
        },
        {
            "higher_school_of_commerce_pau": "Higher School of Commerce Pau"
        },
        {
            "franco-european_institute_of_chiropractic_paris": "Franco-European Institute of Chiropractic Paris"
        },
        {
            "hotel_school_vatel_(vatel_senior_school_of_commerce_hospitality_and_tourism)": "Hotel School Vatel (Vatel Senior School of Commerce Hospitality and Tourism)"
        },
        {
            "cond\u00e3\u00a9_schools_of_arts": "Cond\u00c3\u00a9 Schools of Arts"
        },
        {
            "strate_school_of_design": "Strate School of Design"
        },
        {
            "escem_higher_school_of_commerce_and_management": "ESCEM Higher School of Commerce and Management"
        },
        {
            "burgundy_university_franche_county_(comue_university_burgundy_franche_comt\u00e3\u00a9)": "Burgundy University Franche County (Comue University Burgundy Franche Comt\u00c3\u00a9)"
        },
        {
            "international_institute_of_the_fragrance_of_cosmetics_and_food_aromatics_isipca_(cy_cergy_paris_university)": "International Institute of the Fragrance of Cosmetics and Food Aromatics ISIPCA (CY CERGY Paris University)"
        },
        {
            "national_school_of_architecture_of_paris_belleville": "National School of Architecture of Paris Belleville"
        },
        {
            "high_engineering_studies": "High engineering studies"
        },
        {
            "upper_european_school_of_art_of_brittany": "Upper European School of Art of Brittany"
        },
        {
            "national_conservatory_of_arts_and_crafts_regional_center_pays_de_la_loire": "National Conservatory of Arts and Crafts Regional Center Pays de la Loire"
        },
        {
            "audiovisual_cinema_school_of_sound_and_multimedia": "Audiovisual cinema school of sound and multimedia"
        },
        {
            "higher_school_of_commerce_and_development_3a": "Higher School of Commerce and Development 3a"
        },
        {
            "cinema_school_paris_eicar": "Cinema School Paris Eicar"
        },
        {
            "institute_of_higher_studies_for_ihest_science_and_technology": "Institute of Higher Studies for IHEST Science and Technology"
        },
        {
            "sciences_po_strasbourg_institute_of_political_studies_of_strasbourg": "Sciences Po Strasbourg Institute of Political Studies of Strasbourg"
        },
        {
            "aix_en_provence_art_school": "Aix en Provence Art School"
        },
        {
            "higher_school_of_the_bank": "Higher School of the Bank"
        },
        {
            "national_school_of_architecture_of_nancy": "National School of Architecture of Nancy"
        },
        {
            "normal_music_school_of_paris_alfred_cortot": "Normal Music School of Paris Alfred Cortot"
        },
        {
            "university_institutes_of_technology": "University Institutes of Technology"
        },
        {
            "french_school_of_stationery_and_graphic_industries": "French school of stationery and graphic industries"
        },
        {
            "institute_of_internet_and_multimedia_iim_university_pole_leonardo_da_vinci": "Institute of Internet and Multimedia Iim University Pole Leonardo da Vinci"
        },
        {
            "national_institutes_of_applied_sciences": "National Institutes of Applied Sciences"
        },
        {
            "international_college_of_philosophy_ciph_paris": "International College of Philosophy CIPH Paris"
        },
        {
            "catholic_institute_of_graduate_studies_of_vend\u00e3\u00a9e_ice": "Catholic Institute of Graduate Studies of Vend\u00c3\u00a9e Ice"
        },
        {
            "business_school_management_gesti\u00e3\u00b3n_and_marketing": "Business School Management Gesti\u00c3\u00b3n and Marketing"
        },
        {
            "school_of_art_and_saint_etienne_design": "School of Art and Saint Etienne Design"
        },
        {
            "national_school_of_arts_appliques_and_crafts_olivier_of_serres": "National School of Arts Appliques and Crafts Olivier of Serres"
        },
        {
            "ifg_executive_education_groupe_inseec": "IFG Executive Education Groupe INSEEC"
        },
        {
            "national_school_of_architecture_of_toulouse_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "National School of Architecture of Toulouse (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "national_prison_administration_school_enap": "National Prison Administration School enap"
        },
        {
            "institute_of_graduate_studies_of_arts": "Institute of Graduate Studies of Arts"
        },
        {
            "higher_institute_of_paris_biotechnology": "Higher Institute of Paris Biotechnology"
        },
        {
            "icd_international_business_school": "ICD International Business School"
        },
        {
            "esg_business_schools": "ESG Business Schools"
        },
        {
            "esgi_alternance_computer_school": "ESGI alternance computer school"
        },
        {
            "national_school_of_architecture_of_paris_malaquais": "National School of Architecture of Paris Malaquais"
        },
        {
            "national_conservatory_national_dramatic_art_cnsad_(psl_university)": "National Conservatory National Dramatic Art CNSAD (PSL University)"
        },
        {
            "national_school_of_architecture_of_strasbourg": "National School of Architecture of Strasbourg"
        },
        {
            "domuni_university": "Domuni University"
        },
        {
            "novancia_(advancia_and_negotia)_business_school_paris": "Novancia (Advancia and Negotia) Business School Paris"
        },
        {
            "campus_condorcet_(pres)": "Campus Condorcet (PRES)"
        },
        {
            "post-bac_business_school_in_paris_emlv_university_pole_leonardo_da_vinci": "Post-bac business school in Paris EMLV University Pole Leonardo da Vinci"
        },
        {
            "luxury_hotel_school_paris": "Luxury Hotel School Paris"
        },
        {
            "superior_school_of_fine_arts_of_nantes_m\u00e3\u00a9tropole": "Superior School of Fine Arts of Nantes M\u00c3\u00a9tropole"
        },
        {
            "national_school_of_arts_and_technical_techniques": "National School of Arts and Technical Techniques"
        },
        {
            "esaip_school_computer_engineer": "ESAIP School Computer Engineer"
        },
        {
            "national_school_of_architecture_montpellier": "National School of Architecture Montpellier"
        },
        {
            "hesam_university_(high_schools_sorbonne_arts_and_crafts)": "HESAM University (High Schools Sorbonne Arts and Crafts)"
        },
        {
            "upper_national_school_of_photography_ensp_arles": "Upper National School of Photography Ensp Arles"
        },
        {
            "national_school_of_art_and_design_of_tours_angers_le_mans_talm": "National School of Art and Design of Tours Angers Le Mans Talm"
        },
        {
            "engineering_school_of_purpan_inp_purpan_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "Engineering School of Purpan Inp Purpan (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "free_school_of_commercial_sciences_applied_eslsca_business_school_paris": "Free School of Commercial Sciences Applied Eslsca Business School Paris"
        },
        {
            "polytech_montpellier": "Polytech Montpellier"
        },
        {
            "national_institute_of_applied_sciences_center_val_de_loire_/_insa_center_val_de_loire_(com\u00e3\u00a9_center-val_de_loire)": "National Institute of Applied Sciences Center Val de Loire / INSA Center Val de Loire (Com\u00c3\u00a9 Center-Val de Loire)"
        },
        {
            "national_school_of_architecture_and_landscape_of_lille": "National School of Architecture and Landscape of Lille"
        },
        {
            "higher_institute_of_the_arts_of_toulouse_isdat_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "Higher Institute of the Arts of Toulouse ISDAT (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "national_school_of_arts_of_cergy_pontoise_ensapc_(cy_cergy_paris_university)": "National School of Arts of Cergy Pontoise ENSAPC (CY CERGY Paris University)"
        },
        {
            "superior_school_of_nantes": "Superior School of Nantes"
        },
        {
            "ecricome_phd_complex": "Ecricome phd complex"
        },
        {
            "brest_business_school": "Brest Business School"
        },
        {
            "school_of_art_and_design_esad_de_reims_(comue_university_of_champagne)": "School of Art and Design Esad de Reims (Comue University of Champagne)"
        },
        {
            "college_of_engineers": "College of Engineers"
        },
        {
            "osteopathic_school": "Osteopathic School"
        },
        {
            "caen_cherbourg_arts_&_media_school": "Caen Cherbourg Arts & Media School"
        },
        {
            "school_of_architecture_of_the_city_&_territories_paris-est": "School of architecture of the city & territories Paris-Est"
        },
        {
            "polytech_tours": "Polytech Tours"
        },
        {
            "mba_esg": "MBA ESG"
        },
        {
            "school_of_engineers_of_the_construction_of_the_construction_of_caen": "School of Engineers of the construction of the construction of Caen"
        },
        {
            "regional_institute_of_social_work_of_lorraine": "Regional Institute of Social Work of Lorraine"
        },
        {
            "national_school_of_ensg_geology": "National School of Ensg Geology"
        },
        {
            "efb_bars_training_school": "EFB bars training school"
        },
        {
            "isae_supmeca_higher_institute_of_paris_mechanics": "Isae Supmeca Higher Institute of Paris Mechanics"
        },
        {
            "la_rochelle_business_school": "La Rochelle Business School"
        },
        {
            "national_conservatory_of_arts_and_crafts_cnam_ile_de_france_versailles": "National Conservatory of Arts and Crafts Cnam Ile de France Versailles"
        },
        {
            "school_of_trade_management_and_esup_marketing": "School of Trade Management and ESUP Marketing"
        },
        {
            "val_de_grace_school": "Val de Grace School"
        },
        {
            "european_chemistry_school,_polymers_and_strasbourg_materials": "European Chemistry School, Polymers and Strasbourg Materials"
        },
        {
            "college_of_paris": "College of Paris"
        },
        {
            "3il_school_of_engineering": "3iL School of Engineering"
        },
        {
            "american_graduate_school_in_paris": "American Graduate School in Paris"
        },
        {
            "higher_school_of_organic_chemistry_and_mineral": "Higher School of Organic Chemistry and Mineral"
        },
        {
            "ebi_industrial_biology_school_(cy_cergy_paris_university)": "Ebi Industrial Biology School (CY CERGY Paris University)"
        },
        {
            "nancy_national_art_school": "NANCY National Art School"
        },
        {
            "university_institute_of_tarbes_technology": "University Institute of Tarbes Technology"
        },
        {
            "telecom_physics_strasbourg_(national_school_of_physics)_university_of_strasbourg": "Telecom Physics Strasbourg (National School of Physics) University of Strasbourg"
        },
        {
            "national_school_of_architecture_of_brittany_ensab": "National School of Architecture of Brittany ENSAB"
        },
        {
            "protestant_institute_of_theology": "Protestant Institute of Theology"
        },
        {
            "school_of_art_and_design_le_havre_rouen_esadhar": "School of Art and Design Le Havre Rouen Esadhar"
        },
        {
            "ecole_psychologists_practitioners_catholic_university_of_paris": "Ecole Psychologists practitioners Catholic University of Paris"
        },
        {
            "university_institute_of_nimes_technology": "University Institute of Nimes Technology"
        },
        {
            "university_institute_of_valencia_technology": "University Institute of Valencia Technology"
        },
        {
            "national_ski_and_mountaineering_school": "National Ski and Mountaineering School"
        },
        {
            "rodez_university_institute_of_technology": "Rodez University Institute of Technology"
        },
        {
            "national_school_of_nancy_chemical_industries": "National School of Nancy Chemical Industries"
        },
        {
            "superior_school_of_fine_arts_of_bordeaux_ebabx": "Superior School of Fine Arts of Bordeaux Ebabx"
        },
        {
            "free_conservatory_cinema_school_of_french_cinema": "Free Conservatory Cinema School of French Cinema"
        },
        {
            "sciences_po_saint-germain-en-laye_institute_of_political_studies_of_saint_germain_en_laye": "Sciences Po Saint-Germain-en-Laye Institute of Political Studies of Saint Germain en Laye"
        },
        {
            "air_force_school": "Air Force School"
        },
        {
            "great_school_of_agronomist_engineering_and_food_industries": "Great School of Agronomist Engineering and Food Industries"
        },
        {
            "school_of_biotechnology_of_strasbourg": "School of Biotechnology of Strasbourg"
        },
        {
            "higher_institute_of_psychomotor_reeducation": "Higher Institute of Psychomotor Reeducation"
        },
        {
            "data_sciencetech_institute": "Data ScienceTech Institute"
        },
        {
            "college_of_paris_financial_business_school": "College of Paris Financial Business School"
        },
        {
            "senior_school_of_cinematographic_studies_of_paris_esec": "Senior School of Cinematographic Studies of Paris ESEC"
        },
        {
            "international_center_for_higher_mediterranean_agronyan_agronyan_institute_mediterranean_institute_of_montpellier_ciheam_iamm": "International Center for Higher Mediterranean Agronyan Agronyan Institute Mediterranean Institute of Montpellier Ciheam IAMM"
        },
        {
            "telecom_nancy_(school_of_informatics_and_applications_of_lorraine)": "Telecom Nancy (School of Informatics and Applications of Lorraine)"
        },
        {
            "languedoc-roussillon_universities_(languedoc_roussillon_universities_ex_university_montpellier_sud_de_france)": "Languedoc-Roussillon Universities (Languedoc Roussillon Universities Ex University Montpellier Sud de France)"
        },
        {
            "upper_school_of_art_and_orleans_design": "Upper School of Art and Orleans Design"
        },
        {
            "great_trade_school_of_amiens": "Great Trade School of Amiens"
        },
        {
            "itescia_management_school_(cy_cergy_paris_university)": "ITESCIA Management School (CY Cergy Paris University)"
        },
        {
            "college_of_paris_ecema": "College of Paris ECEMA"
        },
        {
            "university_paris_lights_(comude_university_paris_lights)": "University Paris Lights (Comude University Paris Lights)"
        },
        {
            "ecam_rennes_louis_de_broglie": "ECAM Rennes Louis de Broglie"
        },
        {
            "national_assurance_school_cnam_enass": "National Assurance School CNAM ENASS"
        },
        {
            "isba_besan\u00e3\u00a7on_(higher_institute_of_fine_arts_of_besan\u00e3\u00a7on_franche_county)": "ISBA Besan\u00c3\u00a7on (Higher Institute of Fine Arts of Besan\u00c3\u00a7on Franche County)"
        },
        {
            "ecam_strasbourg": "ECAM Strasbourg"
        },
        {
            "ecole_de_cinema_&_audiovisual_in_nantes_cinecreatis": "Ecole de cinema & Audiovisual in Nantes Cinecreatis"
        },
        {
            "polytech_clermont-ferrand": "Polytech Clermont-Ferrand"
        },
        {
            "mediterranean_institute_for_training_and_social_work_research": "Mediterranean Institute for Training and Social Work Research"
        },
        {
            "mayotte_university_training_and_research_center": "MAYOTTE University Training and Research Center"
        },
        {
            "clermont_m\u00e3\u00a9tropole_art_higher_school_(art_clermont_community_higher_school)": "Clermont M\u00c3\u00a9tropole Art Higher School (Art Clermont Community Higher School)"
        },
        {
            "university_institute_of_technology_michel_de_montaigne_bordeaux": "University Institute of Technology Michel de Montaigne Bordeaux"
        },
        {
            "inspe_bretagne": "INSPE Bretagne"
        },
        {
            "school_of_art_of_the_pyrenees_pau_tarbes": "School of Art of the Pyrenees Pau Tarbes"
        },
        {
            "pgsm_large_school_of_business_and_management": "PGSM Large School of Business and Management"
        },
        {
            "marine_academy": "Marine academy"
        },
        {
            "center_for_resource,_expertise_and_sports_performance_creps_(federal_university_toulouse_midi-pyr\u00e3\u00a9n\u00e3\u00a9es)": "Center for Resource, Expertise and Sports Performance CREPS (Federal University Toulouse Midi-Pyr\u00c3\u00a9n\u00c3\u00a9es)"
        },
        {
            "bachelor_egc": "Bachelor EGC"
        },
        {
            "institut_regional_du_travaile_de_provence-alpes_cote_d'azur_et_corse": "Institut Regional du Travaile de Provence-Alpes Cote d'Azur et Corse"
        },
        {
            "higher_school_of_sports_professions_(free_institute_of_higher_physical_education_ileps)": "Higher School of Sports Professions (Free Institute of Higher Physical Education ILEPS)"
        },
        {
            "college_of_paris_school_conte": "College of Paris School Conte"
        },
        {
            "schools_of_higher_studies_in_journalism_political_science_and_international_relations_heip": "Schools of Higher Studies in Journalism Political Science and International Relations Heip"
        },
        {
            "regional_and_european_institute_of_social_intervention_trades": "Regional and European Institute of Social Intervention Trades"
        },
        {
            "european_school_of_social_intervention_eseis": "European School of Social Intervention ESEIS"
        },
        {
            "catholic_institute_of_the_mediterranean_(institute_of_science_and_theology_of_religions)": "Catholic Institute of the Mediterranean (Institute of Science and Theology of Religions)"
        },
        {
            "nice_regional_radiation_conservatory_(c\u00e3\u00b4te_d'azur_university)": "Nice Regional Radiation Conservatory (C\u00c3\u00b4te d'Azur University)"
        },
        {
            "sud_management_management_and_business_school": "Sud Management Management and Business School"
        },
        {
            "confederal_university_leonardo_de_vinci_(limousin_poitou_charentes_+_center_val_de_loire_university)": "Confederal University Leonardo de Vinci (Limousin Poitou Charentes + Center Val de Loire University)"
        },
        {
            "regional_institute_of_social_work_of_paris_ile_de_france": "Regional Institute of Social Work of Paris Ile de France"
        },
        {
            "school_of_engineering_engineering_and_construction_metz": "School of Engineering Engineering and Construction Metz"
        },
        {
            "faculty_jean_calvin_(faculty_free_for_reformed_theology)": "Faculty Jean Calvin (Faculty Free for Reformed Theology)"
        },
        {
            "south_champagne_business_school": "South Champagne Business School"
        },
        {
            "journalism_school_of_toulouse": "Journalism School of Toulouse"
        },
        {
            "school_of_aerospace_engineers_elisa_aerospace": "School of Aerospace Engineers Elisa Aerospace"
        },
        {
            "community_of_universities_and_establishments_of_aquitaine_(aquitaine_comude)": "Community of Universities and Establishments of Aquitaine (Aquitaine Comude)"
        },
        {
            "higher_school_of_nord-pas_de_calais_cambrai": "Higher School of Nord-Pas de Calais Cambrai"
        },
        {
            "marne_la_vallee_school_of_management": "Marne la Vallee School of Management"
        },
        {
            "3il_engineers_school_of_computer_engineering": "3iL Engineers School of Computer Engineering"
        },
        {
            "school_of_avignon_art": "School of Avignon Art"
        },
        {
            "school_of_international_trade_of_marne_la_valle_paris": "School of International Trade of Marne La Valle Paris"
        },
        {
            "higher_study_center_music_and_poitou-charentes_dance": "Higher Study Center Music and Poitou-Charentes Dance"
        },
        {
            "catholic_institute_of_rennes": "Catholic Institute of Rennes"
        },
        {
            "free_faculties_of_philosophy_and_psychology": "Free Faculties of Philosophy and Psychology"
        },
        {
            "practical_school_of_social_service_epss_(cy_cergy_paris_university)": "Practical School of Social Service EPSS (CY CERGY Paris University)"
        },
        {
            "school_of_art_toulon_provence_mediterranean": "School of Art Toulon Provence Mediterranean"
        },
        {
            "practical_school_marseille": "Practical School Marseille"
        },
        {
            "regional_institute_of_labor_socia_institute_of_social_development_normandy": "Regional Institute of Labor Socia Institute of Social Development Normandy"
        },
        {
            "center_for_industrial_training_of_the_paris_chamber_of_commerce_and_industry": "Center for Industrial Training of the Paris Chamber of Commerce and Industry"
        },
        {
            "regional_institute_of_social_work_hauts_de_france_irtshdf": "Regional Institute of Social Work Hauts de France IRTSHDF"
        },
        {
            "national_school_of_nord_art_pas_de_calais": "National School of Nord Art Pas de Calais"
        },
        {
            "superior_school_of_fine_arts_in_n\u00e3\u00aemes": "Superior School of Fine Arts in N\u00c3\u00aemes"
        },
        {
            "catholic_university_catholic_center_of_burgundy": "Catholic University Catholic Center of Burgundy"
        },
        {
            "school_of_littoral_engineers_opal_coast_(pas_de_calais)": "School of Littoral Engineers Opal Coast (Pas de Calais)"
        },
        {
            "french_language_institute": "French Language Institute"
        },
        {
            "european_institute_of_humanities": "European Institute of Humanities"
        },
        {
            "school_of_foundry_and_forge": "School of Foundry and Forge"
        },
        {
            "esg_executive_education": "ESG Executive Education"
        },
        {
            "college_of_paris_digital_college": "College of Paris Digital College"
        },
        {
            "western_optics_school": "Western Optics School"
        },
        {
            "higher_institute_of_the_building_and_public_works_isba": "Higher Institute of the Building and Public Works ISBA"
        },
        {
            "cefedem_rhone-alpes": "Cefedem Rhone-Alpes"
        },
        {
            "regional_institute_of_social_work_of_new_aquitaine": "Regional Institute of Social Work of New Aquitaine"
        },
        {
            "college_of_paris_sup_of_hr": "College of Paris Sup of HR"
        },
        {
            "regional_institute_of_social_work_from_normandy_caen": "Regional Institute of Social Work from Normandy Caen"
        },
        {
            "school_media_arts_fructidor": "School Media Arts Fructidor"
        },
        {
            "school_of_cinema_trades_and_audiovisual_in_lyon_arfis": "School of Cinema Trades and Audiovisual In Lyon Arfis"
        },
        {
            "esarc_evolution_school_of_commerce_and_management_montpellier_toulouse_aix": "ESARC Evolution School of Commerce and Management Montpellier Toulouse Aix"
        },
        {
            "school_of_arts_and_techniques": "School of Arts and Techniques"
        },
        {
            "private_french_prosthesis_school": "Private French Prosthesis School"
        },
        {
            "institute_for_educational_and_social_work": "Institute for Educational and Social Work"
        },
        {
            "egc_of_occitania": "EGC of Occitania"
        },
        {
            "school_of_art_and_visual_communication_atep": "School of Art and Visual Communication ATEP"
        },
        {
            "bts_audiovisual_lycee_rene_cassin_bayonne": "BTS Audiovisual Lycee Rene Cassin Bayonne"
        },
        {
            "senior_trade_and_computer_science_institute_of_orsay_iscio": "Senior Trade and Computer Science Institute of Orsay Iscio"
        },
        {
            "national_school_of_mountain_sports": "National School of Mountain Sports"
        },
        {
            "near_burgundy_franche-comt\u00e3\u00a9_esth-innovation_university": "Near Burgundy Franche-Comt\u00c3\u00a9 Esth-Innovation University"
        },
        {
            "college_of_paris_french_decor_school": "College of Paris French Decor School"
        },
        {
            "school_of_engineers_isen_rennes": "School of Engineers Isen Rennes"
        },
        {
            "agro_institute": "Agro Institute"
        },
        {
            "notarial_vocational_training_center_of_rennes_and_angers": "Notarial Vocational Training Center of Rennes and Angers"
        },
        {
            "school_of_engineers_isen_nantes": "School of Engineers Isen Nantes"
        }
    ],
    "united kingdom": [
        {
            "aberystwyth_university": "Aberystwyth University"
        },
        {
            "alliance_manchester_business_school": "Alliance Manchester Business School"
        },
        {
            "anglia_ruskin_university": "Anglia Ruskin University"
        },
        {
            "anglia_ruskin_university": "Anglia Ruskin University"
        },
        {
            "birkbeck,_university_of_london": "Birkbeck, University of London"
        },
        {
            "anglia_ruskin_university": "Anglia Ruskin University"
        },
        {
            "bangor_university": "Bangor University"
        },
        {
            "bath_spa_university": "Bath Spa University"
        },
        {
            "aston_university": "Aston University"
        },
        {
            "birmingham_city_university": "Birmingham City University"
        },
        {
            "bournemouth_university": "Bournemouth University"
        },
        {
            "brunel_university_london": "Brunel University London"
        },
        {
            "brunel_university_london": "Brunel University London"
        },
        {
            "cranfield_school_of_management": "Cranfield School of Management"
        },
        {
            "cardiff_metropolitan_university": "Cardiff Metropolitan University"
        },
        {
            "coventry_university": "Coventry University"
        },
        {
            "cardiff_university": "Cardiff University"
        },
        {
            "city,_university_of_london": "City, University of London"
        },
        {
            "coventry_university": "Coventry University"
        },
        {
            "de_montfort_university": "De Montfort University"
        },
        {
            "cranfield_university": "Cranfield University"
        },
        {
            "durham_university": "Durham University"
        },
        {
            "edinburgh_napier_university": "Edinburgh Napier University"
        },
        {
            "glasgow_caledonian_university": "Glasgow Caledonian University"
        },
        {
            "imperial_college_london": "Imperial College London"
        },
        {
            "heriot-watt_university": "Heriot-Watt University"
        },
        {
            "kingston_university": "Kingston University"
        },
        {
            "keele_university": "Keele University"
        },
        {
            "heriot-watt_university": "Heriot-Watt University"
        },
        {
            "goldsmiths,_university_of_london": "Goldsmiths, University of London"
        },
        {
            "king's_college_london": "King's College London"
        },
        {
            "lancaster_university": "Lancaster University"
        },
        {
            "leeds_beckett_university": "Leeds Beckett University"
        },
        {
            "london_business_school": "London Business School"
        },
        {
            "liverpool_john_moores_university": "Liverpool John Moores University"
        },
        {
            "london_south_bank_university": "London South Bank University"
        },
        {
            "london_metropolitan_university": "London Metropolitan University"
        },
        {
            "london_school_of_economics_and_political_science": "London School of Economics and Political Science"
        },
        {
            "london_school_of_hygiene_and_tropical_medicine": "London School of Hygiene and Tropical Medicine"
        },
        {
            "loughborough_university": "Loughborough University"
        },
        {
            "manchester_metropolitan_university": "Manchester Metropolitan University"
        },
        {
            "loughborough_university": "Loughborough University"
        },
        {
            "loughborough_university_london": "Loughborough University London"
        },
        {
            "middlesex_university": "Middlesex University"
        },
        {
            "nottingham_trent_university": "Nottingham Trent University"
        },
        {
            "northumbria_university": "Northumbria University"
        },
        {
            "newcastle_university": "Newcastle University"
        },
        {
            "oxford_brookes_university": "Oxford Brookes University"
        },
        {
            "queen_margaret_university": "Queen Margaret University"
        },
        {
            "queen_mary_university_of_london": "Queen Mary University of London"
        },
        {
            "queen's_university_belfast": "Queen's University Belfast"
        },
        {
            "robert_gordon_university": "Robert Gordon University"
        },
        {
            "royal_college_of_art": "Royal College of Art"
        },
        {
            "royal_holloway_university_of_london": "Royal Holloway University of London"
        },
        {
            "sheffield_hallam_university": "Sheffield Hallam University"
        },
        {
            "soas_university_of_london": "SOAS University of London"
        },
        {
            "teesside_university": "Teesside University"
        },
        {
            "st_george's,_university_of_london": "St George's, University of London"
        },
        {
            "swansea_university": "Swansea University"
        },
        {
            "the_university_of_exeter": "The University of Exeter"
        },
        {
            "the_university_of_edinburgh": "The University of Edinburgh"
        },
        {
            "the_university_of_exeter": "The University of Exeter"
        },
        {
            "manchester_university": "manchester university"
        },
        {
            "ulster_university": "Ulster University"
        },
        {
            "ulster_university": "Ulster University"
        },
        {
            "ulster_university": "Ulster University"
        },
        {
            "london_global_university": "london global university"
        },
        {
            "university_of_aberdeen": "University of Aberdeen"
        },
        {
            "university_of_bath": "University of Bath"
        },
        {
            "university_of_bedfordshire": "University of Bedfordshire"
        },
        {
            "university_of_birmingham": "University of Birmingham"
        },
        {
            "university_of_bedfordshire": "University of Bedfordshire"
        },
        {
            "university_of_brighton": "University of Brighton"
        },
        {
            "university_of_bristol": "University of Bristol"
        },
        {
            "university_of_central_lancashire_(uclan)": "University of Central Lancashire (UCLan)"
        },
        {
            "university_of_cambridge": "University of Cambridge"
        },
        {
            "university_of_chester": "University of Chester"
        },
        {
            "university_of_chester": "University of Chester"
        },
        {
            "university_of_derby": "University of Derby"
        },
        {
            "university_of_dundee": "University of Dundee"
        },
        {
            "university_of_east_anglia": "University of East Anglia"
        },
        {
            "university_of_essex": "University of Essex"
        },
        {
            "university_of_bradford": "University of Bradford"
        },
        {
            "university_of_glasgow": "University of Glasgow"
        },
        {
            "university_of_greenwich": "University of Greenwich"
        },
        {
            "university_of_greenwich": "University of Greenwich"
        },
        {
            "university_of_hertfordshire": "University of Hertfordshire"
        },
        {
            "university_of_huddersfield": "University of Huddersfield"
        },
        {
            "university_of_hull": "University of Hull"
        },
        {
            "university_of_kent": "University of Kent"
        },
        {
            "university_of_kent": "University of Kent"
        },
        {
            "university_of_leeds": "University of Leeds"
        },
        {
            "university_of_leicester": "University of Leicester"
        },
        {
            "university_of_lincoln": "University of Lincoln"
        },
        {
            "university_of_nottingham": "University of Nottingham"
        },
        {
            "university_of_nottingham": "University of Nottingham"
        },
        {
            "university_of_plymouth": "University of Plymouth"
        },
        {
            "university_of_oxford": "University of Oxford"
        },
        {
            "university_of_portsmouth": "University of Portsmouth"
        },
        {
            "university_of_reading": "University of Reading"
        },
        {
            "university_of_roehampton": "University of Roehampton"
        },
        {
            "university_of_salford": "university of salford"
        },
        {
            "university_of_sheffield": "University of Sheffield"
        },
        {
            "university_of_salford": "University of Salford"
        },
        {
            "university_of_south_wales": "University of South Wales"
        },
        {
            "university_of_southampton": "University of Southampton"
        },
        {
            "university_of_stirling": "University of Stirling"
        },
        {
            "university_of_st_andrews": "University of St Andrews"
        },
        {
            "university_of_strathclyde": "University of Strathclyde"
        },
        {
            "university_of_sussex": "University of Sussex"
        },
        {
            "university_of_surrey": "University of Surrey"
        },
        {
            "university_of_the_arts_london_(ual)": "University of the Arts London (UAL)"
        },
        {
            "university_of_westminster": "University of Westminster"
        },
        {
            "university_of_the_west_of_scotland": "University of the West of Scotland"
        },
        {
            "university_of_the_west_of_scotland": "University of the West of Scotland"
        },
        {
            "university_of_the_west_of_england_(uwe_bristol)": "University of the West of England (UWE Bristol)"
        },
        {
            "university_of_the_west_of_scotland": "University of the West of Scotland"
        },
        {
            "university_of_warwick": "University of Warwick"
        },
        {
            "university_of_warwick": "University of Warwick"
        },
        {
            "university_of_east_london": "university of east london"
        },
        {
            "university_of_wolverhampton": "University of Wolverhampton"
        },
        {
            "university_of_wolverhampton": "University of Wolverhampton"
        },
        {
            "university_of_york": "University of York"
        },
        {
            "university_of_liverpool": "University of Liverpool"
        },
        {
            "open_university_uk": "Open University UK"
        },
        {
            "university_of_ulster": "University of Ulster"
        },
        {
            "royal_veterinary_college_university_of_london": "Royal Veterinary College University of London"
        },
        {
            "liverpool_school_of_tropical_medicine": "Liverpool School of Tropical Medicine"
        },
        {
            "edge_hill_university": "Edge Hill University"
        },
        {
            "staffordshire_university": "Staffordshire University"
        },
        {
            "canterbury_christ_church_university": "Canterbury Christ Church University"
        },
        {
            "university_of_sunderland": "University of Sunderland"
        },
        {
            "university_of_northampton": "University of Northampton"
        },
        {
            "university_of_the_highlands_and_islands": "University of the Highlands and Islands"
        },
        {
            "university_of_gloucestershire": "University of gloucestershire"
        },
        {
            "university_of_abertay": "University of Abertay"
        },
        {
            "university_of_winchester": "University of Winchester"
        },
        {
            "university_of_west_london_(thames_valley_university)": "University of West London (Thames Valley University)"
        },
        {
            "liverpool_hope_university": "Liverpool Hope University"
        },
        {
            "university_of_bolton": "University of Bolton"
        },
        {
            "york_saint_john_university": "York Saint John University"
        },
        {
            "school_of_advanced_study_university_of_london": "School of Advanced Study University of London"
        },
        {
            "southampton_solent_university": "Southampton Solent University"
        },
        {
            "harper_adams_university": "Harper Adams University"
        },
        {
            "university_of_cumbria": "University of Cumbria"
        },
        {
            "saint_mary's_university_twickenham": "Saint Mary's University Twickenham"
        },
        {
            "university_of_chichester": "University of Chichester"
        },
        {
            "university_of_wales_trinity_saint_david_(swansea_metropolitan_university)": "University of Wales Trinity Saint David (Swansea Metropolitan University)"
        },
        {
            "university_of_buckingham": "University of Buckingham"
        },
        {
            "university_of_suffolk": "University of Suffolk"
        },
        {
            "glynd\u00e5\u00b5r_university": "Glynd\u00c5\u00b5r University"
        },
        {
            "university_of_worcester": "University of Worcester"
        },
        {
            "scottish_rural_college_(scottish_agricultural_college)": "Scottish Rural College (Scottish Agricultural College)"
        },
        {
            "royal_college_of_surgeons_of_england": "Royal College of Surgeons of England"
        },
        {
            "falmouth_university_(dartington_college_of_arts)": "Falmouth University (Dartington College of Arts)"
        },
        {
            "institute_of_cancer_research_university_of_london": "Institute of Cancer Research University of London"
        },
        {
            "bucks_(buckinghamshire_chilterns)_new_university": "Bucks (Buckinghamshire Chilterns) New University"
        },
        {
            "newman_university": "Newman University"
        },
        {
            "leeds_trinity_university": "Leeds Trinity University"
        },
        {
            "regent_s_university_london": "Regent s University London"
        },
        {
            "plymouth_marjon_university": "Plymouth Marjon University"
        },
        {
            "courtauld_institute_of_art": "Courtauld Institute of Art"
        },
        {
            "royal_agricultural_university": "Royal Agricultural University"
        },
        {
            "institute_of_historical_research": "Institute of Historical Research"
        },
        {
            "henley_business_school_university_of_reading": "Henley Business School University of Reading"
        },
        {
            "scottish_association_for_marine_science": "Scottish Association for Marine Science"
        },
        {
            "university_for_the_creative_arts": "University for the Creative Arts"
        },
        {
            "brighton_and_sussex_medical_school": "Brighton and Sussex Medical School"
        },
        {
            "bishop_grosseteste_university": "Bishop Grosseteste University"
        },
        {
            "hull_york_medical_school": "Hull York Medical School"
        },
        {
            "royal_college_of_psychiatrists": "Royal College of Psychiatrists"
        },
        {
            "university_college_of_birmingham_(college_of_food_tourism_and_creative_studies)": "University College of Birmingham (College of Food Tourism and Creative Studies)"
        },
        {
            "royal_college_of_physicians": "Royal College of Physicians"
        },
        {
            "sae_institute": "SAE Institute"
        },
        {
            "royal_college_of_nursing": "Royal College of Nursing"
        },
        {
            "architectural_association_school_of_architecture": "Architectural Association School of Architecture"
        },
        {
            "glasgow_school_of_art": "Glasgow School of Art"
        },
        {
            "bpp_university_college_of_professional_studies": "BPP University College of Professional Studies"
        },
        {
            "royal_academy_of_music": "Royal Academy of Music"
        },
        {
            "royal_college_of_music": "Royal College of Music"
        },
        {
            "richmond_american_international_university_in_london": "Richmond American International University in London"
        },
        {
            "guildhall_school_of_music_and_drama": "Guildhall School of Music and Drama"
        },
        {
            "royal_northern_college_of_music": "Royal Northern College of Music"
        },
        {
            "greater_brighton_metropolitan_college_(city_college_brighton_+_northbrook_college_sussex)": "Greater Brighton Metropolitan College (City College Brighton + Northbrook College Sussex)"
        },
        {
            "stranmillis_university_college": "Stranmillis University College"
        },
        {
            "university_of_law_(college_of_law)": "University of Law (College of Law)"
        },
        {
            "trinity_laban": "Trinity Laban"
        },
        {
            "london_school_of_business_and_finance": "London School of Business and Finance"
        },
        {
            "central_school_of_speech_and_drama_university_of_london": "Central School of Speech and Drama University of London"
        },
        {
            "arts_university_bournemouth": "Arts University Bournemouth"
        },
        {
            "ravensbourne_university_london": "Ravensbourne University London"
        },
        {
            "royal_conservatoire_of_scotland_glasgow_(formerly_rsamd)": "Royal Conservatoire of Scotland Glasgow (formerly RSAMD)"
        },
        {
            "royal_academy_of_dramatic_art": "Royal Academy of Dramatic Art"
        },
        {
            "wessex_institute_of_technology": "Wessex Institute of Technology"
        },
        {
            "london_institute_of_banking_&_finance": "London Institute of Banking & Finance"
        },
        {
            "all_nations_christian_college": "All Nations Christian College"
        },
        {
            "kaplan_uk": "Kaplan UK"
        },
        {
            "sotheby's_institute_of_art_london": "Sotheby's Institute of Art London"
        },
        {
            "royal_welsh_college_of_music_&_drama": "Royal Welsh College of Music & Drama"
        },
        {
            "national_film_and_television_school": "National Film and Television School"
        },
        {
            "pearson_college_london": "Pearson College London"
        },
        {
            "london_academy_of_music_&_dramatic_art_lamda": "London Academy of Music & Dramatic Art LAMDA"
        },
        {
            "arden_university": "Arden University"
        },
        {
            "norwich_university_of_the_arts": "Norwich University of the Arts"
        },
        {
            "the_prince's_foundation_school_of_traditional_arts": "The Prince's Foundation School of Traditional Arts"
        },
        {
            "liverpool_institute_for_performing_arts": "Liverpool Institute for Performing Arts"
        },
        {
            "leeds_arts_university": "Leeds Arts University"
        },
        {
            "city_of_glasgow_college_(glasgow_metropolitan_college,_college_of_nautical_studies)": "City of Glasgow College (Glasgow Metropolitan College, College of Nautical Studies)"
        },
        {
            "bimm_(tech_music_school)": "BIMM (Tech Music School)"
        },
        {
            "newcastle_college": "Newcastle College"
        },
        {
            "hartpury_college": "Hartpury College"
        },
        {
            "nottingham_college": "Nottingham College"
        },
        {
            "plymouth_college_of_art_and_design": "Plymouth College of Art and Design"
        },
        {
            "aecc_university_college": "AECC University College"
        },
        {
            "cornwall_college": "Cornwall College"
        },
        {
            "the_manchester_college": "The Manchester College"
        },
        {
            "bradford_college": "Bradford College"
        },
        {
            "new_college_of_the_humanities": "New College of the Humanities"
        },
        {
            "leeds_college_of_music": "Leeds College of Music"
        },
        {
            "rose_bruford_college": "Rose Bruford College"
        },
        {
            "writtle_university_college": "Writtle University College"
        },
        {
            "myerscough_college": "Myerscough College"
        },
        {
            "city_college_norwich": "City College Norwich"
        },
        {
            "academy_of_contemporary_music": "Academy of Contemporary Music"
        },
        {
            "blackpool_and_the_fylde_college": "Blackpool and The Fylde College"
        },
        {
            "leeds_college_of_business_management_and_technology": "Leeds College of Business Management and Technology"
        },
        {
            "fife_college_(adam_smith_college)": "Fife College (Adam Smith College)"
        },
        {
            "belfast_metropolitan_college": "Belfast Metropolitan College"
        },
        {
            "westminster_kingsway_college": "Westminster Kingsway College"
        },
        {
            "loughborough_college": "Loughborough College"
        },
        {
            "gateshead_college": "Gateshead College"
        },
        {
            "blackburn_college_uk": "Blackburn College UK"
        },
        {
            "south_gloucestershire_and_stroud_college_(filton_college)": "South Gloucestershire and Stroud College (Filton College)"
        },
        {
            "grimsby_institute_of_further_&_higher_education": "Grimsby Institute of Further & Higher Education"
        },
        {
            "croydon_college": "Croydon College"
        },
        {
            "west_suffolk_college": "West Suffolk College"
        },
        {
            "university_college_of_estate_management": "University College of Estate Management"
        },
        {
            "south_devon_college": "South Devon College"
        },
        {
            "arts_educational_schools": "Arts Educational Schools"
        },
        {
            "northern_school_of_contemporary_dance": "Northern School of Contemporary Dance"
        },
        {
            "southern_regional_college": "Southern Regional College"
        },
        {
            "bristol_old_vic_theatre_school_in_bristol": "Bristol Old Vic Theatre School in Bristol"
        },
        {
            "south_eastern_regional_college": "South Eastern Regional College"
        },
        {
            "carmarthenshire_college": "Carmarthenshire College"
        },
        {
            "saint_mary's_university_college_belfast": "Saint Mary's University College Belfast"
        },
        {
            "ayrshire_college_(ayr_college)": "Ayrshire College (Ayr College)"
        },
        {
            "stockport_college": "Stockport College"
        },
        {
            "wirral_metropolitan_college": "Wirral Metropolitan College"
        },
        {
            "london_college_of_science_&_technology": "London College of Science & Technology"
        },
        {
            "northern_regional_college": "Northern Regional College"
        },
        {
            "farnborough_college_of_technology": "Farnborough College of Technology"
        },
        {
            "ruskin_college": "Ruskin College"
        },
        {
            "london_south_east_colleges": "London South East Colleges"
        },
        {
            "royal_drawing_school": "Royal Drawing School"
        },
        {
            "salford_city_college": "Salford City College"
        },
        {
            "south_tyneside_college": "South Tyneside College"
        },
        {
            "european_school_of_economics": "European School of Economics"
        },
        {
            "european_school_of_osteopathy": "European School of Osteopathy"
        },
        {
            "national_centre_for_circus_arts_(the_circus_space_in_london)": "National Centre for Circus Arts (The Circus Space in London)"
        },
        {
            "darlington_college_of_technology": "Darlington College of Technology"
        },
        {
            "british_college_of_osteopathic_medicine": "British College of Osteopathic Medicine"
        },
        {
            "east_coast_college_(great_yarmouth_&_lowestoft)": "East Coast College (Great Yarmouth & Lowestoft)"
        },
        {
            "loreto_college_(futureworks_media_school)": "Loreto College (Futureworks Media School)"
        },
        {
            "selby_college": "Selby College"
        },
        {
            "conservatoire_for_dance_and_drama": "Conservatoire for Dance and Drama"
        },
        {
            "university_college_of_osteopathy": "University College of Osteopathy"
        },
        {
            "coleg_y_cymoedd": "Coleg y Cymoedd"
        },
        {
            "central_school_of_ballet_in_london": "Central School of Ballet in London"
        },
        {
            "rambert_school_of_ballet_and_contemporary_dance": "Rambert School of Ballet and Contemporary Dance"
        },
        {
            "medway_school_of_pharmacy": "Medway School of Pharmacy"
        },
        {
            "redcar_&_cleveland_college": "Redcar & Cleveland College"
        },
        {
            "warnborough_college": "Warnborough College"
        },
        {
            "british_institute_of_technology_&_e-commerce": "British Institute of Technology & E-commerce"
        },
        {
            "bloomsbury_institute": "Bloomsbury Institute"
        },
        {
            "american_university_in_london": "American University in London"
        },
        {
            "interior_design_institute": "Interior Design Institute"
        },
        {
            "fashion_design_university_of_westminster": "Fashion Design University of Westminster"
        },
        {
            "easton_college": "Easton College"
        },
        {
            "bristol_baptist_college": "Bristol Baptist College"
        },
        {
            "london_school_of_theology": "London School of Theology"
        },
        {
            "azad_university_oxford": "Azad University Oxford"
        },
        {
            "doncaster_utc": "Doncaster UTC"
        },
        {
            "university_for_the_creative_arts": "University for the Creative Arts"
        },
        {
            "university_for_the_creative_arts": "University for the Creative Arts"
        },
        {
            "university_of_cumbria": "University of Cumbria"
        },
        {
            "university_of_gibraltar": "University of Gibraltar"
        },
        {
            "university_of_gloucestershire": "University of Gloucestershire"
        },
        {
            "university_of_london": "University of London"
        },
        {
            "university_of_new_hampshire": "University of New Hampshire"
        },
        {
            "university_of_wolverhampton": "University of Wolverhampton"
        },
        {
            "york_st_john_university": "York St John University"
        },
        {
            "wmg_university_of_warwick": "WMG University of Warwick"
        }
    ],
    "ireland": [
        {
            "american_college_dublin": "American College Dublin"
        },
        {
            "carlow_college": "Carlow College"
        },
        {
            "athlone_institute_of_technology": "Athlone Institute of Technology"
        },
        {
            "cork_institute_of_technology": "Cork Institute of Technology"
        },
        {
            "dublin_business_school": "Dublin Business School"
        },
        {
            "dublin_city_university": "Dublin City University"
        },
        {
            "letterkenny_institute_of_technology": "Letterkenny Institute of Technology"
        },
        {
            "maynooth_university": "Maynooth University"
        },
        {
            "national_university_of_ireland,_galway": "National University of Ireland, Galway"
        },
        {
            "trinity_college_dublin": "Trinity College Dublin"
        },
        {
            "limerick_institute_of_technology": "Limerick Institute of Technology"
        },
        {
            "university_of_limerick": "University of Limerick"
        },
        {
            "technological_university_dublin": "Technological University Dublin"
        },
        {
            "university_college_cork": "University College Cork"
        },
        {
            "university_college_dublin": "University College Dublin"
        },
        {
            "royal_college_of_surgeons_of_ireland": "Royal College of Surgeons of Ireland"
        },
        {
            "institute_of_technology_sligo": "Institute of Technology Sligo"
        },
        {
            "dundalk_institute_of_technology": "Dundalk Institute of Technology"
        },
        {
            "galway_may_institute_of_technology_gmit": "Galway May Institute of Technology Gmit"
        },
        {
            "waterford_institute_of_technology": "Waterford Institute of Technology"
        },
        {
            "national_college_of_ireland_nci": "National College of Ireland NCI"
        },
        {
            "national_university_of_ireland": "National University of Ireland"
        },
        {
            "institute_of_technology_carlow": "Institute of Technology Carlow"
        },
        {
            "dublin_institute_for_advanced_studies_dias": "Dublin Institute For Advanced Studies DIAS"
        },
        {
            "digital_marketing_institute": "Digital Marketing Institute"
        },
        {
            "griffith_college_ireland": "Griffith College Ireland"
        },
        {
            "national_college_of_art_and_design": "National College of Art and Design"
        },
        {
            "dun_laoghaire_institute_of_art_design_and_technology_iadt": "Dun Laoghaire Institute of Art Design and Technology IADT"
        },
        {
            "institute_of_technology_tralee": "Institute of Technology Tralee"
        },
        {
            "royal_irish_academy_of_music": "Royal Irish Academy of Music"
        },
        {
            "institute_of_public_administration_ireland": "Institute of Public Administration Ireland"
        },
        {
            "pontifical_university_saint_patrick's_college_maynooth": "Pontifical University Saint Patrick's College Maynooth"
        },
        {
            "dublin_institute_of_design": "Dublin Institute of Design"
        },
        {
            "irish_management_institute": "Irish Management Institute"
        },
        {
            "honorable_society_of_king's_inns_school_of_law": "Honorable Society of King's Inns School of Law"
        },
        {
            "marino_institute_of_education": "Marino Institute of Education"
        },
        {
            "burren_college_of_art": "Burren College of Art"
        },
        {
            "college_of_computer_training": "College of Computer Training"
        },
        {
            "munster_technological_university": "Munster Technological University"
        },
        {
            "cork_college_of_commerce": "Cork College of Commerce"
        },
        {
            "pitman_training_ireland": "Pitman Training Ireland"
        },
        {
            "st_michael's_house": "St Michael's House"
        },
        {
            "portobello_institute": "Portobello Institute"
        },
        {
            "cavan_institute": "Cavan Institute"
        },
        {
            "irish_bible_institute_university_of_wales": "Irish Bible Institute University of Wales"
        },
        {
            "cenit_college": "Cenit College"
        },
        {
            "icd_business_school": "ICD Business School"
        },
        {
            "sallynoggin_college_of_further_education": "Sallynoggin College of Further Education"
        },
        {
            "further_college_of_further_education": "Further College of Further Education"
        },
        {
            "grafton_academy_of_fashion_design": "Grafton Academy of Fashion Design"
        },
        {
            "irish_university_business_school": "Irish University Business School"
        }
    ],
    "australia": [
        {
            "charles_darwin_university": "Charles Darwin University"
        },
        {
            "curtin_university": "Curtin University"
        },
        {
            "edith_cowan_university": "Edith Cowan University"
        },
        {
            "australian_national_university": "Australian National University"
        },
        {
            "charles_darwin_university": "Charles Darwin University"
        },
        {
            "federation_university_australia": "Federation University Australia"
        },
        {
            "edith_cowan_university": "Edith Cowan University"
        },
        {
            "federation_university_australia": "Federation University Australia"
        },
        {
            "flinders_university": "Flinders University"
        },
        {
            "griffith_university": "Griffith University"
        },
        {
            "james_cook_university": "James Cook University"
        },
        {
            "macquarie_university": "Macquarie University"
        },
        {
            "la_trobe_university": "La Trobe University"
        },
        {
            "macquarie_university": "Macquarie University"
        },
        {
            "monash_university": "Monash University"
        },
        {
            "monash_university": "Monash University"
        },
        {
            "murdoch_university": "Murdoch University"
        },
        {
            "southern_cross_university": "Southern Cross University"
        },
        {
            "queensland_university_of_technology": "Queensland University of Technology"
        },
        {
            "swinburne_university_of_technology": "Swinburne University of Technology"
        },
        {
            "the_university_of_western_australia": "The University of Western Australia"
        },
        {
            "rmit_university": "RMIT University"
        },
        {
            "university_of_adelaide": "University of Adelaide"
        },
        {
            "university_of_canberra": "University of Canberra"
        },
        {
            "university_of_new_england": "University of New England"
        },
        {
            "university_of_new_south_wales": "University of New South Wales"
        },
        {
            "university_of_new_south_wales": "University of New South Wales"
        },
        {
            "university_of_queensland": "University of Queensland"
        },
        {
            "university_of_newcastle": "University of Newcastle"
        },
        {
            "university_of_newcastle": "University of Newcastle"
        },
        {
            "university_of_queensland": "University of Queensland"
        },
        {
            "university_of_southern_queensland": "University of Southern Queensland"
        },
        {
            "university_of_south_australia": "University of South Australia"
        },
        {
            "university_of_sydney": "University of Sydney"
        },
        {
            "university_of_tasmania": "University of Tasmania"
        },
        {
            "university_of_technology_sydney": "University of Technology Sydney"
        },
        {
            "university_of_wollongong": "University of Wollongong"
        },
        {
            "victoria_university_sydney": "Victoria University Sydney"
        },
        {
            "victoria_university": "Victoria University"
        },
        {
            "university_of_tasmania": "University of Tasmania"
        },
        {
            "western_sydney_university": "Western Sydney University"
        },
        {
            "western_sydney_university": "Western Sydney University"
        },
        {
            "western_sydney_university": "Western Sydney University"
        },
        {
            "university_of_melbourne": "University of Melbourne"
        },
        {
            "deakin_university": "Deakin University"
        },
        {
            "charles_sturt_university": "Charles Sturt University"
        },
        {
            "australian_catholic_university": "Australian Catholic University"
        },
        {
            "southern_cross_university": "Southern Cross University"
        },
        {
            "university_of_the_sunshine_coast": "University of the Sunshine Coast"
        },
        {
            "bond_university": "Bond University"
        },
        {
            "menzies_school_of_health_research": "Menzies School of Health Research"
        },
        {
            "university_of_notre_dame_australia": "University of Notre Dame Australia"
        },
        {
            "australian_maritime_college": "Australian Maritime College"
        },
        {
            "torrens_university_australia": "Torrens University Australia"
        },
        {
            "melbourne_business_school": "Melbourne Business School"
        },
        {
            "open_colleges": "Open Colleges"
        },
        {
            "tafe_nsw_degrees": "TAFE NSW Degrees"
        },
        {
            "blue_mountains_(australian)_international_hotel_management_school": "Blue Mountains (Australian) International Hotel Management School"
        },
        {
            "australian_defence_force_academy_at_unsw": "Australian Defence Force Academy at UNSW"
        },
        {
            "melbourne_institute_of_technology": "Melbourne Institute of Technology"
        },
        {
            "tafe_queensland": "TAFE Queensland"
        },
        {
            "adelaide_institute_of_tafe": "Adelaide Institute of TAFE"
        },
        {
            "ridley_college": "Ridley College"
        },
        {
            "eastern_college_australia": "Eastern College Australia"
        },
        {
            "canberra_institute_of_technology": "Canberra Institute of Technology"
        },
        {
            "australian_film_television_and_radio_school": "Australian Film Television and Radio School"
        },
        {
            "box_hill_institute": "Box Hill Institute"
        },
        {
            "australia_college_of_nursing": "Australia College of Nursing"
        },
        {
            "holmesglen_institute": "Holmesglen Institute"
        },
        {
            "kangan_batman_institute": "Kangan Batman Institute"
        },
        {
            "national_institute_of_dramatic_art": "National Institute of Dramatic Art"
        },
        {
            "avondale_university_": "avondale university "
        },
        {
            "college_of_christian_higher_education": "College of Christian Higher Education"
        },
        {
            "the_tax_institute": "The Tax Institute"
        },
        {
            "chisholm_institute": "Chisholm Institute"
        },
        {
            "sae_institute_qantm_college": "SAE Institute Qantm College"
        },
        {
            "william_angliss_institute": "William Angliss Institute"
        },
        {
            "australian_institute_of_business": "Australian Institute of Business"
        },
        {
            "acs_distance_education": "ACS Distance Education"
        },
        {
            "jmc_academy": "JMC Academy"
        },
        {
            "south_metropolitan_tafe_(polytechnic_west)": "South Metropolitan TAFE (Polytechnic West)"
        },
        {
            "international_college_of_management_sydney": "International College of Management Sydney"
        },
        {
            "endeavour_college_of_natural_health": "Endeavour College of Natural Health"
        },
        {
            "north_metropolitan_tafe": "North Metropolitan TAFE"
        },
        {
            "the_college_of_law": "The College of Law"
        },
        {
            "university_of_divinity": "University of Divinity"
        },
        {
            "gordon_geelong_institute_of_tafe": "Gordon Geelong Institute of TAFE"
        },
        {
            "australian_college_of_applied_psychology": "Australian College of Applied Psychology"
        },
        {
            "governance_institute_of_australia": "Governance Institute of Australia"
        },
        {
            "alphacrucis_college_(harvest_bible_college)": "Alphacrucis College (Harvest Bible College)"
        },
        {
            "engineering_institute_of_technology": "Engineering Institute of Technology"
        },
        {
            "monash_college": "Monash College"
        },
        {
            "billy_blue_college": "Billy Blue College"
        },
        {
            "holmes_institute": "Holmes Institute"
        },
        {
            "christian_heritage_college": "Christian Heritage College"
        },
        {
            "kaplan_business_school": "Kaplan Business School"
        },
        {
            "carnegie_mellon_university_australia": "Carnegie Mellon University Australia"
        },
        {
            "national_art_school_(darlinghurst_school_of_art_&_design)": "National Art School (Darlinghurst School of Art & Design)"
        },
        {
            "think:_colleges": "Think: Colleges"
        },
        {
            "excelsia_college_(wesley_institute)": "Excelsia College (Wesley Institute)"
        },
        {
            "uts_college": "UTS College"
        },
        {
            "batchelor_institute_of_indigenous_tertiary_education": "Batchelor Institute of Indigenous Tertiary Education"
        },
        {
            "moore_theological_college": "Moore Theological College"
        },
        {
            "australian_institute_of_higher_education_aih": "Australian Institute of Higher Education AIH"
        },
        {
            "nan_road_institute": "Nan Road Institute"
        },
        {
            "deakin_college_(formerly_melbourne_institute_of_business_and_technology)": "Deakin College (formerly Melbourne Institute of Business and Technology)"
        },
        {
            "edith_cowan_college_(perth_institute_of_business_and_technology)": "Edith Cowan College (Perth Institute of Business and Technology)"
        },
        {
            "australian_college_of_physical_education": "Australian College of Physical Education"
        },
        {
            "victorian_institute_of_technology": "Victorian Institute of Technology"
        },
        {
            "australian_institute_of_music": "Australian Institute of Music"
        },
        {
            "king's_own_institute": "King's Own Institute"
        },
        {
            "international_college_of_hotel_management": "International College of Hotel Management"
        },
        {
            "kent_institute_of_business_and_technology": "Kent Institute of Business and Technology"
        },
        {
            "curtin_college": "Curtin College"
        },
        {
            "james_cook_university_brisbane": "James Cook University Brisbane"
        },
        {
            "whitehouse_institute_of_design": "Whitehouse Institute of Design"
        },
        {
            "academy_of_information_technology": "Academy of Information Technology"
        },
        {
            "victorian_institute_of_forensic_medicine": "Victorian Institute of Forensic Medicine"
        },
        {
            "marcus_oldham_college": "Marcus Oldham College"
        },
        {
            "william_blue_college_of_hospitality_and_tourism": "William Blue College of Hospitality and Tourism"
        },
        {
            "collarts": "Collarts"
        },
        {
            "asia_pacific_international_college": "Asia Pacific International College"
        },
        {
            "iibit": "IIBIT"
        },
        {
            "uow_college": "UOW College"
        },
        {
            "top_education_institute": "Top Education Institute"
        },
        {
            "macleay_college": "Macleay College"
        },
        {
            "morling_college": "Morling College"
        },
        {
            "campion_college": "Campion College"
        },
        {
            "murdoch_institute_of_technology": "Murdoch Institute of Technology"
        },
        {
            "nature_care_college": "Nature Care College"
        },
        {
            "australian_ballet_school": "Australian Ballet School"
        },
        {
            "photography_studies_college_melbourne": "Photography Studies College Melbourne"
        },
        {
            "eynesbury_institute_of_business_and_technology": "Eynesbury Institute of Business and Technology"
        },
        {
            "sydney_missionary_and_bible_college": "Sydney Missionary and Bible College"
        },
        {
            "tabor_college_australia": "Tabor College Australia"
        },
        {
            "australian_lutheran_college": "Australian Lutheran College"
        },
        {
            "jansen_newman_institute": "Jansen Newman Institute"
        },
        {
            "sydney_institute_of_traditional_chinese_medicine": "Sydney Institute of Traditional Chinese Medicine"
        },
        {
            "lci_melbourne": "LCI Melbourne"
        },
        {
            "stott's_colleges": "Stott's Colleges"
        },
        {
            "leo_cussen_centre_for_law": "Leo Cussen Centre for Law"
        },
        {
            "australian_national_college": "Australian National College"
        },
        {
            "australian_college_of_theology": "Australian College of Theology"
        },
        {
            "mayfield_education": "Mayfield Education"
        },
        {
            "jazz_music_institute": "Jazz Music Institute"
        },
        {
            "sydney_college_of_divinity": "Sydney College of Divinity"
        },
        {
            "australian_institute_of_police_management": "Australian Institute of Police Management"
        },
        {
            "adelaide_college_of_divinity": "Adelaide College of Divinity"
        },
        {
            "australian_college_of_technology": "Australian College of Technology"
        },
        {
            "catholic_theological_college": "Catholic Theological College"
        },
        {
            "reformed_theological_college": "Reformed Theological College"
        },
        {
            "universal_business_school_sydney_ubss": "Universal Business School Sydney UBSS"
        },
        {
            "trinity_theological_college": "Trinity Theological College"
        },
        {
            "bible_college_of_south_australia": "Bible College of South Australia"
        },
        {
            "australian_institute_of_technical_training": "Australian Institute of Technical Training"
        },
        {
            "international_institute_of_business_and_technology_australia": "International Institute of Business and Technology Australia"
        },
        {
            "perth_bible_college": "Perth Bible College"
        },
        {
            "nazarene_theological_college": "Nazarene Theological College"
        },
        {
            "elite_education_institute": "Elite Education Institute"
        },
        {
            "academy_of_music_and_performing_arts_(australian_international_conservatorium_of_music)": "Academy of Music and Performing Arts (Australian International Conservatorium of Music)"
        },
        {
            "heti_health_education_&_training": "HETI Health Education & Training"
        },
        {
            "oceanic_polytechnic": "Oceanic Polytechnic"
        },
        {
            "fbi_fashion_college": "FBI Fashion College"
        },
        {
            "worldview_centre_for_inter_cultural_studies": "Worldview Centre for Inter Cultural Studies"
        },
        {
            "presbyterian_theological_college": "Presbyterian Theological College"
        },
        {
            "imperial_college_of_australia": "Imperial College of Australia"
        },
        {
            "christ_college": "Christ College"
        },
        {
            "youthworks_college": "Youthworks College"
        },
        {
            "williams_business_college": "Williams Business College"
        },
        {
            "australian_college_of_christian_studies": "Australian College of Christian Studies"
        },
        {
            "brisbane_school_of_theology": "Brisbane School of Theology"
        },
        {
            "centre_for_pavement_engineering_education": "Centre for Pavement Engineering Education"
        },
        {
            "institute_of_technology_australia": "Institute of Technology Australia"
        },
        {
            "montessori_world_educational_institute": "Montessori World Educational Institute"
        },
        {
            "summer_institute_of_linguistics_australia": "Summer Institute of Linguistics Australia"
        }
    ],
    "switzerland": [
        {
            "swiss_federal_institute_of_technology_(eth_zurich)": "Swiss Federal Institute of Technology (ETH Zurich)"
        },
        {
            "federal_institute_of_technology_in_lausanne": "Federal Institute of Technology in Lausanne"
        },
        {
            "universsitat_zurich": "Universsitat zurich"
        },
        {
            "university_of_geneve": "University of Geneve"
        },
        {
            "university_of_bern": "University of Bern"
        },
        {
            "university_of_basel": "University of Basel"
        },
        {
            "university_of_lausanne": "University of Lausanne"
        },
        {
            "universit\u00e3\u00a9_de_fribourg": "Universit\u00c3\u00a9 de Fribourg"
        },
        {
            "university_of_saint_gallen": "University of Saint Gallen"
        },
        {
            "university_of_italian_switzerland_lugano": "University of Italian Switzerland Lugano"
        },
        {
            "university_of_neuch\u00e3\u00a2tel": "University of Neuch\u00c3\u00a2tel"
        },
        {
            "zhaw_zurich_university_of_applied_sciences": "ZHAW Zurich University of Applied Sciences"
        },
        {
            "university_of_applied_sciences_northwestern_switzerland": "University of Applied Sciences Northwestern Switzerland"
        },
        {
            "bfh_berner_fachhochschule": "BFH Berner Fachhochschule"
        },
        {
            "graduate_institute_of_international_studies_geneva": "Graduate Institute of International Studies Geneva"
        },
        {
            "professional_school_of_italian_switzerland": "Professional school of Italian Switzerland"
        },
        {
            "hochschule_luzern": "Hochschule Luzern"
        },
        {
            "university_of_lucerne": "University of Lucerne"
        },
        {
            "specialized_school_of_western_switzerland": "Specialized School of Western Switzerland"
        },
        {
            "hotelschool_of_lausanne": "Hotelschool of Lausanne"
        },
        {
            "international_institute_for_management_development": "International Institute for Management Development"
        },
        {
            "high_specialized_schools_geneve_(high_social_work_school)": "High Specialized Schools Geneve (High Social Work School)"
        },
        {
            "zurcher_university_of_arts": "Zurcher University of Arts"
        },
        {
            "eastern_switzerland_university_of_applied_sciences_east": "Eastern Switzerland University of Applied Sciences East"
        },
        {
            "webster_university_geneva": "Webster University Geneva"
        },
        {
            "high_school_valaisanne": "High school valaisanne"
        },
        {
            "haute_\u00e3\u2030cole_d'ing\u00e3\u00a9nierie_et_de_gestion_du_canton_de_vaud": "Haute \u00c3\u2030cole d'Ing\u00c3\u00a9nierie et de Gestion du Canton de Vaud"
        },
        {
            "european_graduate_school": "European Graduate School"
        },
        {
            "haute_\u00e9cole_p\u00e9dagogique_du_canton_de_vaud_": "Haute \u00e9cole p\u00e9dagogique du canton de Vaud "
        },
        {
            "kalaidos_university_of_applied_sciences_(fachhochschule_private_university_of_economics)": "Kalaidos University of Applied Sciences (Fachhochschule Private University of Economics)"
        },
        {
            "pedagogical_university_of_zurich": "Pedagogical University of Zurich"
        },
        {
            "pedagogical_university_of_bern": "Pedagogical University of Bern"
        },
        {
            "business_school_lausanne": "Business School Lausanne"
        },
        {
            "university_of_applied_sciences_eastern_switzerland": "University of Applied Sciences Eastern Switzerland"
        },
        {
            "university_of_applied_sciences_graub\u00e3\u00bcnden": "University of Applied Sciences Graub\u00c3\u00bcnden"
        },
        {
            "eu_business_school": "EU Business School"
        },
        {
            "glion_institute_of_higher_education": "Glion Institute of Higher Education"
        },
        {
            "les_roches_international_school_of_hotel_management": "les roches international school of hotel management"
        },
        {
            "intercantonal_university_of_heil_education_zurich": "Intercantonal University of Heil Education Zurich"
        },
        {
            "arc_high_school": "ARC High School"
        },
        {
            "zurich_school_of_economics": "Zurich School of Economics"
        },
        {
            "world_trade_institute_berne": "World Trade Institute Berne"
        },
        {
            "high_school_of_engineering_and_architecture_of_friborg": "High school of engineering and architecture of Friborg"
        },
        {
            "geneva_business_school": "Geneva Business School"
        },
        {
            "pedagogical_university_of_lucerne": "Pedagogical University of Lucerne"
        },
        {
            "franklin_university_switzerland": "Franklin University Switzerland"
        },
        {
            "bhms_business_&_hotel_management_school_luzern": "BHMS Business & Hotel Management School Luzern"
        },
        {
            "swiss_hotel_management_school": "Swiss Hotel Management School"
        },
        {
            "swiss_federal_technical_universities_eth_board": "Swiss Federal Technical Universities ETH Board"
        },
        {
            "university_of_teacher_education_of_valais_p\u00e3\u00a4dagogische_hochschule_wallis": "University of Teacher Education of Valais P\u00c3\u00a4dagogische Hochschule Wallis"
        },
        {
            "federal_higher_education_institute_for_vocational_training": "Federal Higher Education Institute for Vocational Training"
        },
        {
            "distance_university_of_applied_sciences_switzerland": "Distance University of Applied Sciences Switzerland"
        },
        {
            "commercial_professional_and_training_center_saint_gallen": "Commercial professional and training center Saint Gallen"
        },
        {
            "pedagogical_university_of_schaffhausen": "Pedagogical University of Schaffhausen"
        },
        {
            "imi_university_center_international_hotel_management_institute": "IMI University Center International Hotel Management Institute"
        },
        {
            "c\u00e3\u00a9sar_ritz_colleges": "C\u00c3\u00a9sar Ritz Colleges"
        },
        {
            "music_academy_basel": "Music Academy Basel"
        },
        {
            "sustainability_management_school_sumas": "Sustainability Management School SUMAS"
        },
        {
            "ihtti_school_of_hotel_management": "IHTTI School of Hotel Management"
        },
        {
            "university_distance_studies_switzerland": "University distance studies Switzerland"
        },
        {
            "sbs_swiss_business_school_z\u00e3\u00bcrich": "SBS Swiss Business School Z\u00c3\u00bcrich"
        },
        {
            "high_school_cantonale_vaudoise_of_health": "High School Cantonale Vaudoise of Health"
        },
        {
            "business_school_geneva_international_university": "Business School Geneva International University"
        },
        {
            "university_of_social_work_and_health_lausanne_hetsl": "University of Social Work and Health Lausanne HETSL"
        },
        {
            "international_academy_of_sports_science_&_technology": "International Academy of Sports Science & Technology"
        },
        {
            "conservatory_of_italian_switzerland": "Conservatory of Italian Switzerland"
        },
        {
            "high_school_of_health_la_source_lausanne": "High School of Health La Source Lausanne"
        },
        {
            "geneva_music_conservatory": "Geneva Music Conservatory"
        },
        {
            "kurt_bosch_university_institute": "Kurt Bosch University Institute"
        },
        {
            "swiss_finance_institute": "Swiss Finance Institute"
        },
        {
            "educational_university_of_graubunden": "Educational University of Graubunden"
        },
        {
            "manufacture_school_of_performing_arts": "Manufacture School of Performing Arts"
        },
        {
            "zurcher_university_of_applied_sciences": "Zurcher University of Applied Sciences"
        },
        {
            "switzerland_university_of_business_and_international_studies_geneva": "Switzerland University of Business and International Studies Geneva"
        },
        {
            "school_of_design_and_high_school_of_art_of_valois_edhea": "School of Design and High School of Art of Valois EDHEA"
        },
        {
            "ecole_de_changins": "Ecole de Changins"
        },
        {
            "school_of_management_and_communication_geneva": "School of Management and Communication Geneva"
        },
        {
            "international_institute_of_management_in_technology_university_of_fribourg": "International Institute of Management in Technology University of Fribourg"
        },
        {
            "friborg_university_of_social_work": "Friborg University of Social Work"
        },
        {
            "from_american_university_in_switzerland": "From American University in Switzerland"
        },
        {
            "ceibs_(lorange_institute_of_business_z\u00e3\u00bcrich)": "CEIBS (Lorange Institute of Business Z\u00c3\u00bcrich)"
        },
        {
            "ks_cader_schools_st_gallen_zurich": "KS Cader Schools St Gallen Zurich"
        },
        {
            "victoria_university_neuch\u00e3\u00a2tel": "Victoria University Neuch\u00c3\u00a2tel"
        },
        {
            "educatis_university": "Educatis University"
        },
        {
            "fachschule_f\u00e3\u00bcr_bankwirtschaft": "Fachschule f\u00c3\u00bcr Bankwirtschaft"
        },
        {
            "international_school_of_business_management": "International School of Business Management"
        },
        {
            "higher_college_of_tourism_and_hospitality_cesar_ritz": "Higher College of Tourism and Hospitality Cesar Ritz"
        },
        {
            "alfred_nobel_open_business_school_switzerland": "Alfred Nobel Open Business School Switzerland"
        },
        {
            "crea_-_inseec_u.": "CREA - INSEEC U."
        },
        {
            "lucerne_school_of_business_(lsob)": "Lucerne School of Business (LSoB)"
        },
        {
            "swiss_hotel_management_school_(shms)": "Swiss Hotel Management School (SHMS)"
        },
        {
            "swiss_hotel_management": "Swiss hotel management"
        },
        {
            "swiss_school_of_higher_education": "Swiss School of Higher Education"
        }
    ],
    "china": [
        {
            "tsinghua_university": "Tsinghua University"
        },
        {
            "peking_university": "Peking University"
        },
        {
            "shanghai_jiao_tong_university": "Shanghai Jiao Tong University"
        },
        {
            "zhejiang_university_(national_che_kiang_university)": "Zhejiang University (National Che Kiang University)"
        },
        {
            "university_of_science_&_technology_of_china": "University of Science & Technology of China"
        },
        {
            "fudan_university_(shanghai_medical_university)": "Fudan University (Shanghai Medical University)"
        },
        {
            "huazhong_university_of_science_&_technology": "Huazhong University of Science & Technology"
        },
        {
            "nanjing_university": "Nanjing University"
        },
        {
            "wuhan_university": "Wuhan University"
        },
        {
            "harbin_institute_of_technology": "Harbin Institute of Technology"
        },
        {
            "sun_yat_sen_university_(zhongshan_university)": "Sun Yat Sen University (Zhongshan University)"
        },
        {
            "hong_kong_polytechnic_university": "Hong Kong Polytechnic University"
        },
        {
            "xi'an_jiaotong_university": "Xi'An Jiaotong University"
        },
        {
            "shandong_university": "Shandong University"
        },
        {
            "tianjin_university": "Tianjin University"
        },
        {
            "tongji_university": "Tongji University"
        },
        {
            "central_south_university_(zhongnan_university)": "Central South University (Zhongnan University)"
        },
        {
            "beihang_university_(beijing_university_of_aeronautics_and_astronautics)": "Beihang University (Beijing University of Aeronautics and Astronautics)"
        },
        {
            "southeast_university": "Southeast University"
        },
        {
            "sichuan_university": "Sichuan University"
        },
        {
            "south_china_university_of_technology": "South China University of Technology"
        },
        {
            "beijing_normal_university": "Beijing Normal University"
        },
        {
            "xiamen_university": "Xiamen University"
        },
        {
            "nankai_university": "Nankai University"
        },
        {
            "jilin_university": "Jilin University"
        },
        {
            "dalian_university_of_technology": "Dalian University of Technology"
        },
        {
            "beijing_institute_of_technology": "Beijing Institute of Technology"
        },
        {
            "university_of_electronic_science_&_technology_of_china": "University of Electronic Science & Technology of China"
        },
        {
            "university_of_chinese_academy_of_sciences": "University of Chinese Academy of Sciences"
        },
        {
            "soochow_university": "Soochow University"
        },
        {
            "chongqing_university": "Chongqing University"
        },
        {
            "northwestern_polytechnical_university": "Northwestern Polytechnical University"
        },
        {
            "east_china_normal_university": "East China Normal University"
        },
        {
            "hunan_university": "Hunan University"
        },
        {
            "china_agricultural_university": "China Agricultural University"
        },
        {
            "shenzhen_university": "Shenzhen University"
        },
        {
            "lanzhou_university": "Lanzhou University"
        },
        {
            "china_university_of_geosciences": "China University of Geosciences"
        },
        {
            "xidian_university": "Xidian University"
        },
        {
            "shanghai_university": "Shanghai University"
        },
        {
            "university_of_science_&_technology_beijing": "University of Science & Technology Beijing"
        },
        {
            "jinan_university": "Jinan University"
        },
        {
            "wuhan_university_of_technology": "Wuhan University of Technology"
        },
        {
            "nanjing_university_of_aeronautics_&_astronautics": "Nanjing University of Aeronautics & Astronautics"
        },
        {
            "zhengzhou_university": "Zhengzhou University"
        },
        {
            "nanjing_university_of_science_&_technology": "Nanjing University of Science & Technology"
        },
        {
            "northeastern_university_china": "Northeastern University China"
        },
        {
            "beijing_(northern)_jiaotong_university": "Beijing (Northern) Jiaotong University"
        },
        {
            "east_china_university_of_science_&_technology": "East China University of Science & Technology"
        },
        {
            "beijing_university_of_posts_and_telecommunications": "Beijing University of Posts and Telecommunications"
        },
        {
            "china_university_of_mining_&_technology": "China University of Mining & Technology"
        },
        {
            "beijing_university_of_chemical_technology": "Beijing University of Chemical Technology"
        },
        {
            "jiangsu_university": "Jiangsu University"
        },
        {
            "renmin_university_of_china": "Renmin University of China"
        },
        {
            "hefei_university_of_technology": "Hefei University of Technology"
        },
        {
            "southwest_jiaotong_university": "Southwest Jiaotong University"
        },
        {
            "southwest_university": "Southwest University"
        },
        {
            "huazhong_(central_china)_agriculture_university": "Huazhong (Central China) Agriculture University"
        },
        {
            "china_university_of_petroleum": "China University of Petroleum"
        },
        {
            "fuzhou_university": "Fuzhou University"
        },
        {
            "hohai_university": "Hohai University"
        },
        {
            "south_china_normal_university": "South China Normal University"
        },
        {
            "peking_university_health_science_center_(beijing_medical_university)": "Peking University Health Science Center (Beijing Medical University)"
        },
        {
            "ocean_university_of_china": "Ocean University of China"
        },
        {
            "zhejiang_university_of_technology": "Zhejiang University of Technology"
        },
        {
            "jiangnan_university_(southern_yangtze_university)": "Jiangnan University (Southern Yangtze University)"
        },
        {
            "nanjing_normal_university": "Nanjing Normal University"
        },
        {
            "north_china_electric_power_university": "North China Electric Power University"
        },
        {
            "national_university_of_defense_technology_(national_defense_university)": "National University of Defense Technology (National Defense University)"
        },
        {
            "donghua_university": "Donghua University"
        },
        {
            "nanjing_university_of_information_science_&_technology_(ex-institute_of_meteorology)": "Nanjing University of Information Science & Technology (ex-Institute of Meteorology)"
        },
        {
            "beijing_university_of_technology_(beijing_polytechnic_university)": "Beijing University of Technology (Beijing Polytechnic University)"
        },
        {
            "nanchang_university": "Nanchang University"
        },
        {
            "south_china_agricultural_university": "South China Agricultural University"
        },
        {
            "guangdong_university_of_technology": "Guangdong University of Technology"
        },
        {
            "central_china_normal_university": "Central China Normal University"
        },
        {
            "shaanxi_normal_university": "Shaanxi Normal University"
        },
        {
            "nanjing_medical_university": "Nanjing Medical University"
        },
        {
            "harbin_engineering_university": "Harbin Engineering University"
        },
        {
            "nanjing_tech_university": "Nanjing Tech University"
        },
        {
            "nanjing_university_of_posts_&_telecommunications": "Nanjing University of Posts & Telecommunications"
        },
        {
            "qingdao_university": "Qingdao University"
        },
        {
            "china_university_of_petroleum_beijing": "China University of Petroleum Beijing"
        },
        {
            "yangzhou_university": "Yangzhou University"
        },
        {
            "northeast_normal_university": "Northeast Normal University"
        },
        {
            "south_university_of_science_and_technology_of_china": "South University of Science and Technology of China"
        },
        {
            "hangzhou_dianzi_university": "Hangzhou Dianzi University"
        },
        {
            "ningbo_university": "Ningbo University"
        },
        {
            "taiyuan_university_of_technology": "Taiyuan University of Technology"
        },
        {
            "guangzhou_university": "Guangzhou University"
        },
        {
            "henan_university": "Henan University"
        },
        {
            "anhui_university": "Anhui University"
        },
        {
            "shanxi_university": "Shanxi University"
        },
        {
            "chang'an_university": "Chang'an University"
        },
        {
            "guangxi_university": "Guangxi University"
        },
        {
            "beijing_forestry_university": "Beijing Forestry University"
        },
        {
            "university_of_jinan": "University of Jinan"
        },
        {
            "university_of_shanghai_for_science_&_technology": "University of Shanghai for Science & Technology"
        },
        {
            "china_pharmaceutical_university": "China Pharmaceutical University"
        },
        {
            "yanshan_university": "Yanshan University"
        },
        {
            "nanjing_forestry_university": "Nanjing Forestry University"
        },
        {
            "hunan_normal_university": "Hunan Normal University"
        },
        {
            "shandong_normal_university": "Shandong Normal University"
        },
        {
            "xiangtan_university": "Xiangtan University"
        },
        {
            "shandong_university_of_science_&_technology": "Shandong University of Science & Technology"
        },
        {
            "shanghaitech_university": "ShanghaiTech University"
        },
        {
            "capital_medical_university": "Capital Medical University"
        },
        {
            "yunnan_university": "Yunnan University"
        },
        {
            "qingdao_university_of_science_&_technology": "Qingdao University of Science & Technology"
        },
        {
            "huaqiao_university": "Huaqiao University"
        },
        {
            "southern_medical_university_(first_military_medical_university)": "Southern Medical University (First Military Medical University)"
        },
        {
            "chongqing_university_of_posts_&_telecommunications": "Chongqing University of Posts & Telecommunications"
        },
        {
            "capital_normal_university": "Capital Normal University"
        },
        {
            "the_fourth_military_medical_university": "The Fourth Military Medical University"
        },
        {
            "dalian_maritime_university": "Dalian Maritime University"
        },
        {
            "hebei_university_of_technology": "Hebei University of Technology"
        },
        {
            "wuhan_university_of_science_&_technology": "Wuhan University of Science & Technology"
        },
        {
            "southwest_petroleum_university": "Southwest Petroleum University"
        },
        {
            "zhejiang_normal_university": "Zhejiang Normal University"
        },
        {
            "sichuan_agricultural_university": "Sichuan Agricultural University"
        },
        {
            "shantou_(swatow)_university": "Shantou (Swatow) University"
        },
        {
            "fujian_agriculture_&_forestry_university": "Fujian Agriculture & Forestry University"
        },
        {
            "nantong_university": "Nantong University"
        },
        {
            "hangzhou_normal_university": "Hangzhou Normal University"
        },
        {
            "zhejiang_sci-tech_university": "Zhejiang SCI-TECH University"
        },
        {
            "shanghai_normal_university": "Shanghai Normal University"
        },
        {
            "wenzhou_medical_university": "Wenzhou Medical University"
        },
        {
            "harbin_medical_university": "Harbin Medical University"
        },
        {
            "southwestern_university_of_finance_&_economics": "Southwestern University of Finance & Economics"
        },
        {
            "xi'an_jiaotong-liverpool_university": "Xi'An Jiaotong-Liverpool University"
        },
        {
            "university_of_nottingham_ningbo_china": "University of Nottingham Ningbo China"
        },
        {
            "xi'an_university_of_technology": "Xi'An University of Technology"
        },
        {
            "kunming_university_of_science_&_technology_(yunnan_polytechnic_university)": "Kunming University of Science & Technology (Yunnan Polytechnic University)"
        },
        {
            "chongqing_medical_university": "Chongqing Medical University"
        },
        {
            "hubei_university": "Hubei University"
        },
        {
            "henan_polytechnic_university": "Henan Polytechnic University"
        },
        {
            "changsha_university_of_science_&_technology": "Changsha University of Science & Technology"
        },
        {
            "shanghai_maritime_university": "Shanghai Maritime University"
        },
        {
            "the_second_military_medical_university": "The Second Military Medical University"
        },
        {
            "changzhou_university": "Changzhou University"
        },
        {
            "southwest_university_of_science_&_technology": "Southwest University of Science & Technology"
        },
        {
            "guizhou_university_(institute_of_technology)": "Guizhou University (Institute of Technology)"
        },
        {
            "shandong_agricultural_university": "Shandong Agricultural University"
        },
        {
            "shaanxi_university_of_science_&_technology": "Shaanxi University of Science & Technology"
        },
        {
            "northeast_forestry_university": "Northeast Forestry University"
        },
        {
            "central_university_of_finance_&_economics": "Central University of Finance & Economics"
        },
        {
            "wuhan_institute_of_technology_(institute_of_chemical_technology)": "Wuhan Institute of Technology (Institute of Chemical Technology)"
        },
        {
            "china_jiliang_university": "China Jiliang University"
        },
        {
            "wenzhou_university": "Wenzhou University"
        },
        {
            "china_medical_university": "China Medical University"
        },
        {
            "shandong_university_of_technology": "Shandong University of Technology"
        },
        {
            "chengdu_university_of_technology": "Chengdu University of Technology"
        },
        {
            "third_military_medical_university": "Third Military Medical University"
        },
        {
            "nanjing_agricultural_university": "Nanjing Agricultural University"
        },
        {
            "tianjin_medical_university": "Tianjin Medical University"
        },
        {
            "university_of_international_business_&_economics": "University of International Business & Economics"
        },
        {
            "hebei_university": "Hebei University"
        },
        {
            "xi'an_university_of_architecture_&_technology": "Xi'An University of Architecture & Technology"
        },
        {
            "inner_mongolia_university": "Inner Mongolia University"
        },
        {
            "anhui_normal_university": "Anhui Normal University"
        },
        {
            "qufu_normal_university": "Qufu Normal University"
        },
        {
            "anhui_agricultural_university": "Anhui Agricultural University"
        },
        {
            "guilin_university_of_electronic_technology": "Guilin University of Electronic Technology"
        },
        {
            "northeast_agricultural_university": "Northeast Agricultural University"
        },
        {
            "zhejiang_gongshang_university": "Zhejiang Gongshang University"
        },
        {
            "zhejiang_a_&_f_university": "Zhejiang A & F University"
        },
        {
            "shanghai_ocean_university_(shanghai_fisheries_university)": "Shanghai Ocean University (Shanghai Fisheries University)"
        },
        {
            "sichuan_normal_university": "Sichuan Normal University"
        },
        {
            "tianjin_university_of_science_&_technology": "Tianjin University of Science & Technology"
        },
        {
            "dongguan_university_of_technology": "Dongguan University of Technology"
        },
        {
            "hainan_university_(south_china_tropical_agricultural_university)": "Hainan University (South China Tropical Agricultural University)"
        },
        {
            "hubei_university_of_technology": "Hubei University of Technology"
        },
        {
            "qilu_university_of_technology": "Qilu University of Technology"
        },
        {
            "china_three_gorges_university": "China Three Gorges University"
        },
        {
            "guangxi_normal_university": "Guangxi Normal University"
        },
        {
            "fujian_medical_university": "Fujian Medical University"
        },
        {
            "shanghai_university_of_finance_&_economics": "Shanghai University of Finance & Economics"
        },
        {
            "shanghai_university_of_engineering_science": "Shanghai University of Engineering Science"
        },
        {
            "guilin_university_of_technology": "Guilin University of Technology"
        },
        {
            "tianjin_normal_university": "Tianjin Normal University"
        },
        {
            "guangdong_university_of_foreign_studies": "Guangdong University of Foreign Studies"
        },
        {
            "guangzhou_medical_university": "Guangzhou Medical University"
        },
        {
            "henan_university_of_technology": "Henan University of Technology"
        },
        {
            "jiangxi_university_of_finance_&_economics": "Jiangxi University of Finance & Economics"
        },
        {
            "anhui_university_of_technology": "Anhui University of Technology"
        },
        {
            "yangtze_university": "Yangtze University"
        },
        {
            "tianjin_polytechnic_university": "Tianjin Polytechnic University"
        },
        {
            "beijing_technology_&_business_university": "Beijing Technology & Business University"
        },
        {
            "anhui_medical_university": "Anhui Medical University"
        },
        {
            "henan_normal_university_(xinxiang_teachers_college)": "Henan Normal University (Xinxiang Teachers College)"
        },
        {
            "beijing_university_of_civil_engineering_&_architecture": "Beijing University of Civil Engineering & Architecture"
        },
        {
            "nanchang_hangkong_university_(aviation_university)": "Nanchang Hangkong University (Aviation University)"
        },
        {
            "chongqing_university_of_technology": "Chongqing University of Technology"
        },
        {
            "dongbei_university_of_finance_&_economics": "Dongbei University of Finance & Economics"
        },
        {
            "zhengzhou_university_of_light_industry": "Zhengzhou University of Light Industry"
        },
        {
            "china_europe_international_business_school": "China Europe International Business School"
        },
        {
            "jiangxi_normal_university_(national_chung_cheng_university)": "Jiangxi Normal University (National Chung Cheng University)"
        },
        {
            "henan_university_of_science_&_technology": "Henan University of Science & Technology"
        },
        {
            "heilongjiang_university": "Heilongjiang University"
        },
        {
            "chengdu_university_of_information_technology": "Chengdu University of Information Technology"
        },
        {
            "north_university_of_china": "North University of China"
        },
        {
            "jiangxi_university_of_science_&_technology": "Jiangxi University of Science & Technology"
        },
        {
            "shanghai_university_of_electric_power": "Shanghai University of Electric Power"
        },
        {
            "tianjin_university_of_technology": "Tianjin University of Technology"
        },
        {
            "northwest_normal_university": "Northwest Normal University"
        },
        {
            "guangzhou_university_of_chinese_medicine": "Guangzhou University of Chinese Medicine"
        },
        {
            "xinjiang_university": "Xinjiang University"
        },
        {
            "liaoning_university": "Liaoning University"
        },
        {
            "liaocheng_university": "Liaocheng University"
        },
        {
            "south_central_university_for_nationalities": "South Central University for Nationalities"
        },
        {
            "yantai_university": "Yantai University"
        },
        {
            "suzhou_university_of_science_&_technology": "Suzhou University of Science & Technology"
        },
        {
            "beijing_university_of_chinese_medicine": "Beijing University of Chinese Medicine"
        },
        {
            "shenyang_pharmaceutical_university": "Shenyang Pharmaceutical University"
        },
        {
            "lanzhou_university_of_technology_(gansu_university_of_technology)": "Lanzhou University of Technology (Gansu University of Technology)"
        },
        {
            "chinese_academy_of_medical_sciences_&_peking_union_medical_college": "Chinese Academy of Medical Sciences & Peking Union Medical College"
        },
        {
            "north_china_university_of_technology": "North China University of Technology"
        },
        {
            "harbin_university_of_science_&_technology": "Harbin University of Science & Technology"
        },
        {
            "shihezi_university": "Shihezi University"
        },
        {
            "communication_university_of_china_(beijing_broadcasting_institute)": "Communication University of China (Beijing Broadcasting Institute)"
        },
        {
            "anhui_university_of_science_&_technology": "Anhui University of Science & Technology"
        },
        {
            "chongqing_technology_&_business_university": "Chongqing Technology & Business University"
        },
        {
            "duke_kunshan_university": "Duke Kunshan University"
        },
        {
            "guangxi_medical_university": "Guangxi Medical University"
        },
        {
            "hunan_agricultural_university": "Hunan Agricultural University"
        },
        {
            "xiamen_university_of_technology_(lujiang_university)": "Xiamen University of Technology (Lujiang University)"
        },
        {
            "quingdao_agricultural_university_(laiyang_agricultural_college)": "Quingdao Agricultural University (Laiyang Agricultural College)"
        },
        {
            "hebei_medical_university": "Hebei Medical University"
        },
        {
            "changchun_university_of_science_&_technology": "Changchun University of Science & Technology"
        },
        {
            "chongqing_normal_university": "Chongqing Normal University"
        },
        {
            "hebei_normal_university": "Hebei Normal University"
        },
        {
            "lanzhou_jiaotong_university": "Lanzhou Jiaotong University"
        },
        {
            "shanghai_university_of_traditional_chinese_medicine": "Shanghai University of Traditional Chinese Medicine"
        },
        {
            "xi'an_university_of_science_&_technology": "Xi'An University of Science & Technology"
        },
        {
            "henan_agricultural_university": "Henan Agricultural University"
        },
        {
            "nanjing_audit_university": "Nanjing Audit University"
        },
        {
            "foshan_university": "Foshan University"
        },
        {
            "zhejiang_university_of_finance_&_economics": "Zhejiang University of Finance & Economics"
        },
        {
            "yunnan_normal_university": "Yunnan Normal University"
        },
        {
            "capital_university_of_economics_&_business": "Capital University of Economics & Business"
        },
        {
            "qingdao_university_of_technology": "Qingdao University of Technology"
        },
        {
            "university_of_south_china_(nanhua_university)": "University of South China (Nanhua University)"
        },
        {
            "central_south_university_of_forestry_&_technology": "Central South University of Forestry & Technology"
        },
        {
            "china_west_normal_university": "China West Normal University"
        },
        {
            "jimei_university": "Jimei university"
        },
        {
            "hebei_agricultural_university": "Hebei Agricultural University"
        },
        {
            "east_china_jiaotong_university": "East China Jiaotong University"
        },
        {
            "ningxia_university": "Ningxia University"
        },
        {
            "liaoning_normal_university": "Liaoning Normal University"
        },
        {
            "dalian_medical_university": "Dalian Medical University"
        },
        {
            "shenyang_agricultural_university": "Shenyang Agricultural University"
        },
        {
            "shanghai_international_studies_university": "Shanghai International Studies University"
        },
        {
            "minzu_university_of_china_(central_university_for_nationalities)": "Minzu University of China (Central University for Nationalities)"
        },
        {
            "ludong_university": "Ludong University"
        },
        {
            "shaoxing_university": "Shaoxing University"
        },
        {
            "jiaxing_university": "Jiaxing University"
        },
        {
            "tianjin_university_of_traditional_chinese_medicine": "Tianjin University of Traditional Chinese Medicine"
        },
        {
            "jilin_agricultural_university": "Jilin Agricultural University"
        },
        {
            "yanbian_university": "Yanbian University"
        },
        {
            "xihua_university": "Xihua University"
        },
        {
            "hebei_university_of_science_&_technology": "Hebei University of Science & Technology"
        },
        {
            "jiangxi_agricultural_university": "Jiangxi Agricultural University"
        },
        {
            "chongqing_jiaotong_university": "Chongqing Jiaotong University"
        },
        {
            "guangxi_unviersity_for_nationalities": "Guangxi unviersity for nationalities"
        },
        {
            "beijing_information_science_&_technology_university": "Beijing Information Science & Technology University"
        },
        {
            "shenyang_university_of_technology": "Shenyang University of Technology"
        },
        {
            "jianghan_university": "Jianghan University"
        },
        {
            "zhongnan_university_of_economics_and_law_": "Zhongnan university of economics and law "
        },
        {
            "bohai_university": "Bohai University"
        },
        {
            "huaiyin_normal_university": "Huaiyin Normal University"
        },
        {
            "linyi_university_(linyi_normal_university)": "Linyi University (Linyi Normal University)"
        },
        {
            "nanjing_university_of_chinese_medicine": "Nanjing University of Chinese Medicine"
        },
        {
            "binzhou_medical_college": "Binzhou Medical College"
        },
        {
            "southwest_university_for_nationalities": "Southwest University for Nationalities"
        },
        {
            "taiyuan_university_of_science_&_technology": "Taiyuan University of Science & Technology"
        },
        {
            "qinghai_university": "Qinghai University"
        },
        {
            "shandong_university_of_finance_and_economics": "Shandong University of Finance and Economics"
        },
        {
            "dalian_polytechnic_university": "Dalian Polytechnic University"
        },
        {
            "zhejiang_ocean_university": "Zhejiang Ocean University"
        },
        {
            "wuhan_polytechnic_university": "Wuhan Polytechnic University"
        },
        {
            "guangdong_pharmaceutical_university": "Guangdong Pharmaceutical University"
        },
        {
            "north_china_university_of_water_resources_and_electric_power": "North China University of Water Resources and Electric Power"
        },
        {
            "chengdu_university_of_traditional_chinese_medicine": "Chengdu University of Traditional Chinese Medicine"
        },
        {
            "xi\u00e2\u20ac\u2122an_university_of_posts_&_telecommunications": "Xi\u00e2\u20ac\u2122an University of Posts & Telecommunications"
        },
        {
            "hebei_university_of_engineering": "Hebei University of Engineering"
        },
        {
            "shanxi_medical_university": "Shanxi Medical University"
        },
        {
            "guangdong_ocean_university": "Guangdong Ocean University"
        },
        {
            "beijing_institute_of_petrochemical_technology": "Beijing Institute of Petrochemical Technology"
        },
        {
            "jilin_normal_university": "Jilin Normal University"
        },
        {
            "xinxiang_medical_university": "Xinxiang Medical University"
        },
        {
            "nanjing_institute_of_technology": "Nanjing Institute of Technology"
        },
        {
            "taizhou_university_(taizhou_academy)": "Taizhou University (Taizhou Academy)"
        },
        {
            "shanxi_normal_university": "Shanxi Normal University"
        },
        {
            "yancheng_institute_of_technology": "Yancheng Institute of Technology"
        },
        {
            "liaoning_shihua_university": "Liaoning Shihua University"
        },
        {
            "guizhou_normal_university": "Guizhou Normal University"
        },
        {
            "chengdu_university": "Chengdu University"
        },
        {
            "yunnan_agricultural_university": "Yunnan Agricultural University"
        },
        {
            "xi'an_technological_university": "Xi'An Technological University"
        },
        {
            "jishou_university": "Jishou University"
        },
        {
            "north_china_university_of_science_&_technology": "North China University of Science & Technology"
        },
        {
            "nanyang_normal_university": "Nanyang Normal University"
        },
        {
            "hunan_university_of_technology": "Hunan University of Technology"
        },
        {
            "fujian_university_of_technology": "Fujian University of Technology"
        },
        {
            "zhongyuan_university_of_technology": "Zhongyuan University of Technology"
        },
        {
            "northeast_petroleum_university_/_daqing_petroleum_college": "Northeast Petroleum University / Daqing Petroleum College"
        },
        {
            "hubei_normal_university": "Hubei Normal University"
        },
        {
            "hainan_normal_university": "Hainan Normal University"
        },
        {
            "anyang_normal_university": "Anyang Normal University"
        },
        {
            "zhejiang_university_of_science_&_technology": "Zhejiang University of Science & Technology"
        },
        {
            "kunming_medical_university": "Kunming Medical University"
        },
        {
            "gansu_agricultural_university": "Gansu Agricultural University"
        },
        {
            "shanxi_agricultural_university": "Shanxi Agricultural University"
        },
        {
            "shenyang_aerospace_university_(institute_of_aeronautical_engineering)": "Shenyang Aerospace University (Institute of Aeronautical Engineering)"
        },
        {
            "minjiang_university": "Minjiang University"
        },
        {
            "university_of_science_and_technology_liaoning": "University of Science and Technology Liaoning"
        },
        {
            "zhejiang_chinese_medical_university": "Zhejiang Chinese Medical University"
        },
        {
            "xinyang_normal_university": "Xinyang Normal University"
        },
        {
            "beijing_union_university": "Beijing Union University"
        },
        {
            "nanjing_university_of_finance_&_economics": "Nanjing University of Finance & Economics"
        },
        {
            "jiangxi_science_and_technology_normal_university": "Jiangxi Science and Technology Normal University"
        },
        {
            "shenzhen_polytechnic": "Shenzhen Polytechnic"
        },
        {
            "chongqing_university_of_arts_and_sciences_(western_chongqing_university)": "Chongqing University of Arts and Sciences (Western Chongqing University)"
        },
        {
            "changsha_university": "Changsha University"
        },
        {
            "huaibei_normal_university_(coal_industry_normal_college)": "Huaibei Normal University (Coal Industry Normal College)"
        },
        {
            "henan_institute_of_science_and_technology": "Henan Institute of Science and Technology"
        },
        {
            "dalian_university": "Dalian University"
        },
        {
            "shijiazhuang_tiedao_university_(railway_institute)": "Shijiazhuang Tiedao University (Railway Institute)"
        },
        {
            "shandong_jianzhu_university": "Shandong Jianzhu University"
        },
        {
            "xi'an_shiyou_university": "Xi'An Shiyou University"
        },
        {
            "anhui_polytechnic_university_(anhui_university_of_technology_&_science)": "Anhui Polytechnic University (Anhui University of Technology & Science)"
        },
        {
            "yangtze_normal_university": "Yangtze Normal University"
        },
        {
            "yunnan_minzu_university": "Yunnan Minzu University"
        },
        {
            "ningxia_medical_university": "Ningxia Medical University"
        },
        {
            "jiangsu_university_of_technology": "Jiangsu University of Technology"
        },
        {
            "shenyang_normal_university": "Shenyang Normal University"
        },
        {
            "shenyang_university_of_chemical_technology": "Shenyang University of Chemical Technology"
        },
        {
            "air_force_engineering_university": "Air Force Engineering University"
        },
        {
            "tianjin_university_of_commerce": "Tianjin University of Commerce"
        },
        {
            "liaoning_university_of_technology": "Liaoning University of Technology"
        },
        {
            "wuyi_university": "Wuyi University"
        },
        {
            "qiqihar_university": "Qiqihar University"
        },
        {
            "guiyang_medical_university": "Guiyang Medical University"
        },
        {
            "china_university_of_political_science_&_law": "China University of Political Science & Law"
        },
        {
            "northeast_electric_power_university_(northeast_dianli_university)": "Northeast Electric Power University (Northeast Dianli University)"
        },
        {
            "xi'an_polytechnic_university": "Xi'An Polytechnic University"
        },
        {
            "shenyang_jianzhu_university": "Shenyang Jianzhu University"
        },
        {
            "dalian_ocean_university": "Dalian Ocean University"
        },
        {
            "guangdong_university_of_petrochemical_technology": "Guangdong University of Petrochemical Technology"
        },
        {
            "wuhan_donghu_university": "Wuhan Donghu University"
        },
        {
            "liaoning_technical_university": "Liaoning Technical University"
        },
        {
            "huzhou_university_(huzhou_teachers\u00e2\u20ac\u2122_college)": "Huzhou University (Huzhou Teachers\u00e2\u20ac\u2122 College)"
        },
        {
            "fujian_university_of_traditional_chinese_medicine": "Fujian University of Traditional Chinese Medicine"
        },
        {
            "shandong_university_of_traditional_chinese_medicine": "Shandong University of Traditional Chinese Medicine"
        },
        {
            "yan'an_university": "Yan'An University"
        },
        {
            "anhui_university_of_finance_&_economics": "Anhui University of Finance & Economics"
        },
        {
            "quanzhou_normal_university": "Quanzhou Normal University"
        },
        {
            "xuzhou_medical_university": "Xuzhou Medical University"
        },
        {
            "hainan_medical_university": "Hainan Medical University"
        },
        {
            "shanghai_university_of_sport": "Shanghai University of Sport"
        },
        {
            "inner_mongolia_university_for_nationalities": "Inner Mongolia University for Nationalities"
        },
        {
            "hubei_university_of_medicine_(yunyang_medical_college)": "Hubei University of Medicine (Yunyang Medical College)"
        },
        {
            "hunan_university_of_arts_&_science_(changde_university)": "Hunan University of Arts & Science (Changde University)"
        },
        {
            "nanchang_institute_of_technology": "Nanchang Institute of Technology"
        },
        {
            "weifang_medical_university": "Weifang Medical University"
        },
        {
            "xinjiang_agriculture_university": "Xinjiang Agriculture University"
        },
        {
            "hubei_university_of_chinese_medicine": "Hubei University of Chinese Medicine"
        },
        {
            "nanning_normal_university_(guangxi_teachers_education_university)": "Nanning Normal University (Guangxi Teachers Education University)"
        },
        {
            "shaanxi_sci-tech_university": "Shaanxi Sci-Tech University"
        },
        {
            "wannan_medical_college": "Wannan Medical College"
        },
        {
            "yunnan_university_of_finance_&_economics": "Yunnan University of Finance & Economics"
        },
        {
            "hubei_university_for_nationalities": "Hubei University for Nationalities"
        },
        {
            "gannan_normal_university_(south_jiangxi_normal_university)": "Gannan Normal University (South Jiangxi Normal University)"
        },
        {
            "henan_university_of_chinese_medicine": "Henan University of Chinese Medicine"
        },
        {
            "east_china_university_of_technology": "East China University of Technology"
        },
        {
            "chinese_academy_of_tropical_agricultural_sciences": "Chinese Academy of Tropical Agricultural Sciences"
        },
        {
            "dalian_jiaotong_university_(railway_institute)": "Dalian Jiaotong University (Railway Institute)"
        },
        {
            "hefei_university": "Hefei University"
        },
        {
            "hunan_institute_of_science_&_technology": "Hunan Institute of Science & Technology"
        },
        {
            "qinghai_normal_university": "Qinghai Normal University"
        },
        {
            "guangdong_medical_university": "Guangdong Medical University"
        },
        {
            "yancheng_teachers_university": "Yancheng Teachers University"
        },
        {
            "tibet_university": "Tibet University"
        },
        {
            "tianjin_university_of_technology_&_education": "Tianjin University of Technology & Education"
        },
        {
            "anhui_university_of_chinese_medicine": "Anhui University of Chinese Medicine"
        },
        {
            "baoji_university_of_arts_and_sciences": "Baoji University of Arts and Sciences"
        },
        {
            "zhongkai_university_of_agriculture_and_engineering": "Zhongkai University of Agriculture and Engineering"
        },
        {
            "huangshan_university": "Huangshan University"
        },
        {
            "hunan_institute_of_engineering": "Hunan Institute of Engineering"
        },
        {
            "tianjin_chengjian_university": "Tianjin Chengjian University"
        },
        {
            "beijing_foreign_studies_university": "Beijing Foreign Studies University"
        },
        {
            "jiujiang_university": "Jiujiang University"
        },
        {
            "guangdong_university_of_business_&_economics": "Guangdong University of Business & Economics"
        },
        {
            "henan_university_of_urban_construction_(pingdingshan_institute_of_technology)": "Henan University of Urban Construction (Pingdingshan Institute of Technology)"
        },
        {
            "neijiang_normal_university": "Neijiang Normal University"
        },
        {
            "jingdezhen_ceramic_institute": "Jingdezhen Ceramic Institute"
        },
        {
            "minnan_normal_university": "Minnan Normal University"
        },
        {
            "hubei_engineering_university_(xiaogan_university)": "Hubei Engineering University (Xiaogan University)"
        },
        {
            "hebei_normal_university_of_science_&_technology": "Hebei Normal University of Science & Technology"
        },
        {
            "hubei_university_of_economics": "Hubei University of Economics"
        },
        {
            "datong_university": "Datong University"
        },
        {
            "anhui_jianzhu_university": "Anhui Jianzhu University"
        },
        {
            "shandong_jiaotong_university": "Shandong Jiaotong University"
        },
        {
            "beihua_university_(jilin_medical_college)": "Beihua University (Jilin Medical College)"
        },
        {
            "hengyang_normal_university": "Hengyang Normal University"
        },
        {
            "jiangxi_university_of_traditional_chinese_medicine": "Jiangxi University of Traditional Chinese Medicine"
        },
        {
            "shenyang_ligong_university": "Shenyang Ligong University"
        },
        {
            "zhoukou_normal_university": "Zhoukou Normal University"
        },
        {
            "heilongjiang_university_of_science_&_technology": "Heilongjiang University of Science & Technology"
        },
        {
            "bengbu_medical_college": "Bengbu Medical College"
        },
        {
            "chongqing_university_of_science_&_technology": "Chongqing University of Science & Technology"
        },
        {
            "dali_university": "Dali University"
        },
        {
            "heilongjiang_university_of_chinese_medicine": "Heilongjiang University of Chinese Medicine"
        },
        {
            "jinzhou_medical_university": "Jinzhou Medical University"
        },
        {
            "nanjing_xiaozhuang_university": "Nanjing Xiaozhuang University"
        },
        {
            "beijing_institute_of_graphic_communication": "Beijing Institute of Graphic Communication"
        },
        {
            "luoyang_institute_of_science_&_technology": "Luoyang Institute of Science & Technology"
        },
        {
            "xinjiang_normal_university": "Xinjiang Normal University"
        },
        {
            "dezhou_university": "Dezhou University"
        },
        {
            "north_china_institute_of_science_&_technology": "North China Institute of Science & Technology"
        },
        {
            "chuzhou_university": "Chuzhou University"
        },
        {
            "ningbo_university_of_technology": "Ningbo University of Technology"
        },
        {
            "weifang_university": "Weifang University"
        },
        {
            "inner_mongolia_normal_university": "Inner Mongolia Normal University"
        },
        {
            "shanghai_polytechnic_university": "Shanghai Polytechnic University"
        },
        {
            "xinxiang_university": "Xinxiang University"
        },
        {
            "liaoning_university_of_traditional_chinese_medicine": "Liaoning University of Traditional Chinese Medicine"
        },
        {
            "jilin_institute_of_chemical_technology": "Jilin Institute of Chemical Technology"
        },
        {
            "shaoguan_university": "Shaoguan University"
        },
        {
            "xuchang_university": "Xuchang University"
        },
        {
            "longyan_university": "Longyan University"
        },
        {
            "shanxi_university_of_finance_&_economics": "Shanxi University of Finance & Economics"
        },
        {
            "jining_university": "Jining University"
        },
        {
            "southwest_forestry_university": "Southwest Forestry University"
        },
        {
            "beijing_institute_of_fashion_technology": "Beijing Institute of Fashion Technology"
        },
        {
            "hunan_university_of_chinese_medicine": "Hunan University of Chinese Medicine"
        },
        {
            "hunan_city_university": "Hunan City University"
        },
        {
            "changchun_university_of_chinese_medicine": "Changchun University of Chinese Medicine"
        },
        {
            "jiamusi_university": "Jiamusi University"
        },
        {
            "zhejiang_university_city_college": "Zhejiang University City College"
        },
        {
            "tarim_university": "Tarim University"
        },
        {
            "anqing_normal_university_/_anqing_teachers_college": "Anqing Normal University / Anqing Teachers College"
        },
        {
            "huizhou_university": "Huizhou University"
        },
        {
            "hebei_north_university": "Hebei North University"
        },
        {
            "hebei_geo_university": "Hebei GEO University"
        },
        {
            "shanghai_lixin_university_of_commerce": "Shanghai Lixin University of Commerce"
        },
        {
            "chengdu_medical_college": "Chengdu Medical College"
        },
        {
            "taiyuan_normal_university": "Taiyuan Normal University"
        },
        {
            "yulin_normal_university": "Yulin Normal University"
        },
        {
            "shaanxi_university_of_chinese_medicine": "Shaanxi University of Chinese Medicine"
        },
        {
            "binzhou_university": "Binzhou University"
        },
        {
            "beijing_wuzi_university_(beijing_materials_institute)": "Beijing Wuzi University (Beijing Materials Institute)"
        },
        {
            "shanghai_dianji_university": "Shanghai Dianji University"
        },
        {
            "huanghe_science_&_technology_college": "Huanghe Science & Technology College"
        },
        {
            "hebei_university_of_arts_&_sciences_(xiangfan_university": "Hebei University of Arts & Sciences (Xiangfan University"
        },
        {
            "zhejiang_wanli_university": "Zhejiang Wanli University"
        },
        {
            "zaozhuang_university": "Zaozhuang University"
        },
        {
            "shandong_first_medical_university": "Shandong First Medical University"
        },
        {
            "lishui_university": "Lishui University"
        },
        {
            "shenyang_university": "Shenyang University"
        },
        {
            "harbin_university_of_commerce": "Harbin University of Commerce"
        },
        {
            "anyang_institute_of_technology": "Anyang Institute of Technology"
        },
        {
            "zhaoqing_university": "Zhaoqing University"
        },
        {
            "guangxi_university_of_chinese_medicine": "Guangxi University of Chinese Medicine"
        },
        {
            "chongqing_three_gorges_university": "Chongqing Three Gorges University"
        },
        {
            "beijing_language_and_culture_university": "Beijing Language and Culture University"
        },
        {
            "zunyi_medical_university": "Zunyi Medical University"
        },
        {
            "zunyi_normal_college": "Zunyi Normal College"
        },
        {
            "changshu_institute_of_technology": "Changshu Institute of Technology"
        },
        {
            "taishan_university": "Taishan University"
        },
        {
            "china_university_of_geoscience_beijing": "China University of Geoscience Beijing"
        },
        {
            "beijing_university_of_agriculture": "Beijing University of Agriculture"
        },
        {
            "henan_university_of_economics_and_law_(institute_of_finance_&_economics)": "Henan University of Economics and Law (Institute of Finance & Economics)"
        },
        {
            "yuncheng_university": "Yuncheng University"
        },
        {
            "hexi_university": "Hexi University"
        },
        {
            "shangqiu_normal_university": "Shangqiu Normal University"
        },
        {
            "huanggang_normal_university": "Huanggang Normal University"
        },
        {
            "qujing_normal_university": "Qujing Normal University"
        },
        {
            "kunming_university": "Kunming University"
        },
        {
            "henan_university_of_engineering": "Henan University of Engineering"
        },
        {
            "hunan_first_normal_university": "Hunan First Normal University"
        },
        {
            "hebei_university_of_economics_&_business": "Hebei University of Economics & Business"
        },
        {
            "xijing_university": "Xijing University"
        },
        {
            "yunnan_university_of_traditional_chinese_medicine": "Yunnan University of Traditional Chinese Medicine"
        },
        {
            "inner_mongolia_university_of_science_and_technology": "Inner Mongolia University of Science and Technology"
        },
        {
            "tianjin_agricultural_university": "Tianjin Agricultural University"
        },
        {
            "hanshan_normal_university": "Hanshan Normal University"
        },
        {
            "huanghuai_university_(zhumadian_teachers'_college)": "Huanghuai University (Zhumadian Teachers' College)"
        },
        {
            "dalian_neusoft_institute_of_information": "Dalian Neusoft Institute of Information"
        },
        {
            "hubei_university_of_education_(institute_of_economics_and_management)": "Hubei University of Education (Institute of Economics and Management)"
        },
        {
            "lanzhou_city_university": "Lanzhou City University"
        },
        {
            "hefei_normal_university": "Hefei Normal University"
        },
        {
            "huainan_normal_university": "Huainan Normal University"
        },
        {
            "huaihua_university": "Huaihua University"
        },
        {
            "changzhou_institute_of_technology": "Changzhou Institute of Technology"
        },
        {
            "west_anhui_university": "West Anhui University"
        },
        {
            "shenzhen_institute_of_information_technology": "Shenzhen Institute of Information Technology"
        },
        {
            "hubei_university_of_automotive_technology": "Hubei University of Automotive Technology"
        },
        {
            "beijing_sport_university": "Beijing Sport University"
        },
        {
            "jilin_university_of_finance_and_economics_(changchun_taxation_college)": "Jilin University of Finance and Economics (Changchun Taxation College)"
        },
        {
            "guangdong_university_of_finance": "Guangdong University of Finance"
        },
        {
            "qinghai_nationalities_university": "Qinghai Nationalities University"
        },
        {
            "heze_university": "Heze University"
        },
        {
            "jiaying_university": "Jiaying University"
        },
        {
            "changchun_university": "Changchun University"
        },
        {
            "heilongjiang_bayi_agricultural_university": "Heilongjiang Bayi Agricultural University"
        },
        {
            "southwest_university_of_political_science_and_law": "Southwest University of Political Science and Law"
        },
        {
            "guizhou_minzu_university_(college_for_ethnic_minorities)": "Guizhou Minzu University (College for Ethnic Minorities)"
        },
        {
            "guizhou_university_of_finance_and_economics": "Guizhou University of Finance and Economics"
        },
        {
            "xi'an_university_of_arts_&_science": "Xi'An University of Arts & Science"
        },
        {
            "civil_aviation_flight_university_of_china": "Civil Aviation Flight University of China"
        },
        {
            "northwest_a_&_f_university": "Northwest A & F University"
        },
        {
            "guangdong_polytechnic_normal_university": "Guangdong Polytechnic Normal University"
        },
        {
            "chongqing_university_of_education": "Chongqing University of Education"
        },
        {
            "guiyang_university": "Guiyang University"
        },
        {
            "huaihai_institute_of_technology": "Huaihai Institute of Technology"
        },
        {
            "zhejiang_shuren_university": "Zhejiang Shuren University"
        },
        {
            "yulin_university": "Yulin University"
        },
        {
            "anshan_normal_university": "Anshan Normal University"
        },
        {
            "gansu_university_of_chinese_medicine": "Gansu University of Chinese Medicine"
        },
        {
            "beijing_international_studies_university": "Beijing International Studies University"
        },
        {
            "honghe_university": "Honghe University"
        },
        {
            "guizhou_education_university": "Guizhou Education University"
        },
        {
            "tonghua_normal_university": "Tonghua Normal University"
        },
        {
            "guizhou_institute_of_technology": "Guizhou Institute of Technology"
        },
        {
            "hunan_university_of_technology_and_business": "Hunan University of Technology and Business"
        },
        {
            "shaoyang_university": "Shaoyang University"
        },
        {
            "jinlin_jianzhu_university": "Jinlin Jianzhu University"
        },
        {
            "hubei_university_of_science_&_technology_(xianning_university)": "Hubei University of Science & Technology (Xianning University)"
        },
        {
            "shanghai_university_of_international_business_and_economics": "Shanghai University of International Business and Economics"
        },
        {
            "northern_university_for_nationalities": "Northern University for Nationalities"
        },
        {
            "dalian_university_of_foreign_languages": "Dalian University of Foreign Languages"
        },
        {
            "shanghai_university_of_health_sciences": "Shanghai University of Health Sciences"
        },
        {
            "putian_university": "putian university"
        },
        {
            "chifeng_university": "Chifeng University"
        },
        {
            "fuyang_normal_university": "Fuyang Normal University"
        },
        {
            "sichuan_university_of_arts_and_science": "Sichuan University of Arts and Science"
        },
        {
            "guangxi_university_of_science_and_technology": "Guangxi University of Science and Technology"
        },
        {
            "beijing_electronic_science_&_technology_institute": "Beijing Electronic Science & Technology Institute"
        },
        {
            "the_chinese_university_of_hong_kong_shenzhen": "The Chinese University of Hong Kong Shenzhen"
        },
        {
            "zhejiang_international_studies_university": "ZheJiang International Studies University"
        },
        {
            "zhejiang_university_of_water_resources_and_electric_power": "Zhejiang University of Water Resources and Electric Power"
        },
        {
            "xiangnan_university": "Xiangnan University"
        },
        {
            "mudanjiang_medical_university": "Mudanjiang Medical University"
        },
        {
            "qingdao_binhai_university": "Qingdao Binhai University"
        },
        {
            "qiqihar_medical_university": "Qiqihar Medical University"
        },
        {
            "zhengzhou_normal_university": "Zhengzhou Normal University"
        },
        {
            "hunan_university_of_science_&_engineering": "Hunan University of Science & Engineering"
        },
        {
            "shanghai_jiao_tong_university_school_of_medicine_(second_medical_university)": "Shanghai Jiao Tong University School of Medicine (Second Medical University)"
        },
        {
            "changchun_normal_university": "Changchun Normal University"
        },
        {
            "zhengzhou_university_of_aeronautics": "Zhengzhou University of Aeronautics"
        },
        {
            "shenyang_institute_of_engineering": "Shenyang Institute of Engineering"
        },
        {
            "xianyang_normal_university": "Xianyang Normal University"
        },
        {
            "yantai_nanshan_university": "Yantai Nanshan University"
        },
        {
            "china_university_of_mining_&_technology_beijing": "China University of Mining & Technology Beijing"
        },
        {
            "sanming_university": "Sanming University"
        },
        {
            "fujian_jiangxia_university": "Fujian Jiangxia University"
        },
        {
            "sichuan_college_of_architectural_technology": "Sichuan College of Architectural Technology"
        },
        {
            "tianshui_normal_university": "Tianshui Normal University"
        },
        {
            "handan_university": "Handan University"
        },
        {
            "shangluo_university": "Shangluo University"
        },
        {
            "mudanjiang_normal_university": "Mudanjiang Normal University"
        },
        {
            "yibin_university": "Yibin University"
        },
        {
            "mianyang_teachers'_college": "Mianyang Teachers' College"
        },
        {
            "weinan_normal_university": "Weinan Normal University"
        },
        {
            "beibu_gulf_university": "Beibu Gulf University"
        },
        {
            "beijing_city_university": "Beijing City University"
        },
        {
            "hunan_university_of_humanities_science_&_technology": "Hunan University of Humanities Science & Technology"
        },
        {
            "northwest_university_of_political_science_&_law": "Northwest University of Political Science & Law"
        },
        {
            "guangdong_food_and_drug_vocational_college": "Guangdong Food and Drug Vocational College"
        },
        {
            "jiangsu_normal_university": "Jiangsu Normal University"
        },
        {
            "hechi_university": "Hechi University"
        },
        {
            "henan_university_of_animal_husbandry_economy": "Henan University of Animal Husbandry Economy"
        },
        {
            "anhui_xinhua_university": "Anhui Xinhua University"
        },
        {
            "shanghai_university_of_political_science_and_law": "Shanghai University of Political Science and Law"
        },
        {
            "yuxi_normal_university": "Yuxi Normal University"
        },
        {
            "southwest_medical_university": "Southwest Medical University"
        },
        {
            "westlake_university": "Westlake University"
        },
        {
            "changchun_institute_of_technology": "Changchun Institute of Technology"
        },
        {
            "jiangsu_university_of_science_&_technology": "Jiangsu University of Science & Technology"
        },
        {
            "bnu-hkbu-united_international_college": "BNU-HKBU-United International College"
        },
        {
            "harbin_university": "Harbin University"
        },
        {
            "quzhou_university": "Quzhou University"
        },
        {
            "chinese_people's_police_university": "Chinese People's Police University"
        },
        {
            "shougang_institute_of_technology": "Shougang Institute of Technology"
        },
        {
            "hainan_tropical_ocean_university": "Hainan Tropical Ocean University"
        },
        {
            "shenzhen_graduate_school_peking_university": "Shenzhen Graduate School Peking University"
        },
        {
            "baicheng_normal_university": "Baicheng Normal University"
        },
        {
            "hunan_international_economics_university": "Hunan International Economics University"
        },
        {
            "suzhou_vocational_university": "Suzhou Vocational University"
        },
        {
            "baotou_medical_college": "Baotou Medical College"
        },
        {
            "weifang_university_of_science_and_technology": "Weifang University of Science and Technology"
        },
        {
            "tongling_university": "Tongling University"
        },
        {
            "guangdong_technion_israel_institute_of_technology_gtiit_guangdong_technion": "Guangdong Technion Israel Institute of Technology GTIIT Guangdong Technion"
        },
        {
            "sui_hua_university": "sui hua university"
        },
        {
            "chongqing_college_of_electronic_engineering": "Chongqing College of Electronic Engineering"
        },
        {
            "ningbo_university_of_finance_and_economics": "Ningbo University of Finance and Economics"
        },
        {
            "china_central_academy_of_fine_arts": "China Central Academy of Fine Arts"
        },
        {
            "central_conservatory_of_music": "Central Conservatory of Music"
        },
        {
            "huaiyin_institute_of_technology": "Huaiyin Institute of Technology"
        },
        {
            "hanzhong_vocational_&_technical_college": "Hanzhong Vocational & Technical College"
        },
        {
            "harbin_institute_of_technology_shenzhen_graduate_school": "Harbin Institute of Technology Shenzhen Graduate School"
        },
        {
            "heilongjiang_vocational_college_of_art": "Heilongjiang Vocational College of Art"
        },
        {
            "beijing_film_academy": "Beijing Film Academy"
        },
        {
            "sichuan_conservatory_of_music": "Sichuan Conservatory of Music"
        },
        {
            "china_academy_of_art": "China Academy of Art"
        },
        {
            "sichuan_international_studies_university": "Sichuan International Studies University"
        },
        {
            "eastern_liaoning_university": "Eastern Liaoning University"
        },
        {
            "east_china_university_of_political_science_&_law": "East China University of Political Science & Law"
        },
        {
            "shanghai_conservatory_of_music": "Shanghai Conservatory of Music"
        },
        {
            "hunan_university_of_science_&_technology": "Hunan University of Science & Technology"
        },
        {
            "sichuan_fine_arts_institute": "Sichuan Fine Arts Institute"
        },
        {
            "hainan_college_of_vocation_and_technique": "Hainan College of Vocation and Technique"
        },
        {
            "guangzhou_academy_of_fine_arts": "Guangzhou Academy of Fine Arts"
        },
        {
            "kunming_university_of_science_and_technology": "Kunming University of Science and Technology"
        },
        {
            "inner_mongolia_normal_university_hongde_college": "Inner Mongolia Normal University Hongde College"
        },
        {
            "horqin_vocational_college_of_art": "Horqin Vocational College of Art"
        },
        {
            "chengdu_college_university_of_electronic_science_and_technology_of_china": "Chengdu College University of Electronic Science and Technology of China"
        },
        {
            "peking_university_school_of_continuing_education": "Peking University School of Continuing Education"
        },
        {
            "xi'an_academy_of_fine_arts": "Xi'An Academy of Fine Arts"
        },
        {
            "tianjin_academy_of_fine_arts": "Tianjin Academy of Fine Arts"
        },
        {
            "xi'an_international_studies_university": "Xi'An International Studies University"
        },
        {
            "tianjin_foreign_studies_university": "Tianjin Foreign Studies University"
        },
        {
            "shanghai_theatre_academy": "Shanghai Theatre Academy"
        },
        {
            "daqing_normal_university": "Daqing Normal University"
        },
        {
            "hubei_institute_of_fine_arts": "Hubei Institute of Fine Arts"
        },
        {
            "china_conservatory": "China Conservatory"
        },
        {
            "beijing_dance_academy": "Beijing Dance Academy"
        },
        {
            "tianjin_vocational_institute": "Tianjin Vocational Institute"
        },
        {
            "jinggangshan_university": "Jinggangshan University"
        },
        {
            "wuhan_textile_university": "Wuhan Textile University"
        },
        {
            "shandong_university_of_arts": "Shandong University of Arts"
        },
        {
            "national_academy_of_chinese_theatre_arts": "National Academy of Chinese Theatre Arts"
        },
        {
            "tianjin_conservatory_of_music": "Tianjin Conservatory of Music"
        },
        {
            "shandong_university_of_art_&_design": "Shandong University of Art & Design"
        },
        {
            "north_sichuan_medical_college": "North Sichuan Medical College"
        },
        {
            "yunan_arts_university": "Yunan Arts University"
        },
        {
            "wuhan_conservatory_of_music": "Wuhan Conservatory of Music"
        },
        {
            "university_of_international_relations": "University of International Relations"
        },
        {
            "china_women's_university": "China Women's University"
        },
        {
            "central_academy_of_drama": "Central Academy of Drama"
        },
        {
            "cheung_kong_graduate_school_of_business": "Cheung Kong Graduate School of Business"
        },
        {
            "shenyang_sport_university": "Shenyang Sport University"
        },
        {
            "xi'an_physical_education_university": "Xi'An Physical Education University"
        },
        {
            "shenzhen_university_of_technology": "Shenzhen University of Technology"
        },
        {
            "university_of_science_&_technology_of_anhui": "University of Science & Technology of Anhui"
        },
        {
            "hangzhou_vocational_&_technical_college": "Hangzhou Vocational & Technical College"
        },
        {
            "shandong_sport_university": "Shandong Sport University"
        },
        {
            "loudi_vocational_and_technical_college": "Loudi Vocational and Technical College"
        },
        {
            "xichang_college": "Xichang College"
        },
        {
            "beijing_professional_business_institute": "Beijing Professional Business Institute"
        },
        {
            "open_university_of_china": "Open University of China"
        },
        {
            "shanghai_institute_of_technology": "Shanghai Institute of Technology"
        },
        {
            "quzhou_college_of_technology": "Quzhou College of Technology"
        },
        {
            "shenyang_conservatory_of_music": "Shenyang Conservatory of Music"
        },
        {
            "wuhan_sports_university": "Wuhan Sports University"
        },
        {
            "guangzhou_sport_university": "Guangzhou Sport University"
        },
        {
            "central_academy_of_fine_arts": "Central Academy of Fine Arts"
        },
        {
            "guilin_medical_university": "Guilin Medical University"
        },
        {
            "chengdu_sport_institute": "Chengdu Sport Institute"
        },
        {
            "shanghai_business_school": "Shanghai Business School"
        },
        {
            "harbin_sport_university": "Harbin Sport University"
        },
        {
            "xi'an_eurasia_university": "Xi'An Eurasia University"
        },
        {
            "guangdong_baiyun_university": "Guangdong Baiyun University"
        },
        {
            "wuhan_polytechnic": "Wuhan Polytechnic"
        },
        {
            "xi'an_peihua_university": "Xi'An Peihua University"
        },
        {
            "jining_medical_university": "Jining Medical University"
        },
        {
            "xi'an_international_university": "Xi'An International University"
        },
        {
            "xiangyang_vocational_&_technical_college": "Xiangyang Vocational & Technical College"
        },
        {
            "xuzhou_institute_of_technology": "Xuzhou Institute of Technology"
        },
        {
            "hulunbuir_university": "Hulunbuir University"
        },
        {
            "anhui_vocational_college_of_police_officers": "Anhui Vocational College of Police Officers"
        },
        {
            "xinjiang_arts_university": "Xinjiang Arts University"
        },
        {
            "hebei_institute_of_communications": "Hebei Institute of Communications"
        },
        {
            "jilin_university_of_the_art": "Jilin University of the Art"
        },
        {
            "ningxia_normal_university": "Ningxia Normal University"
        },
        {
            "new_york_university_shanghai": "New York University Shanghai"
        },
        {
            "north_china_institute_of_aerospace_engineering": "North China Institute of Aerospace Engineering"
        },
        {
            "zhejiang_industry_polytechnic_college": "Zhejiang Industry Polytechnic College"
        },
        {
            "sanda_university": "Sanda University"
        },
        {
            "haikou_college_of_economics": "Haikou College of Economics"
        },
        {
            "tongji_medical_college_huazhong_university_of_science_and_technology": "Tongji Medical College Huazhong University of Science and Technology"
        },
        {
            "tangshan_normal_university": "Tangshan Normal University"
        },
        {
            "wuzhou_university": "Wuzhou University"
        },
        {
            "pingdingshan_university": "Pingdingshan University"
        },
        {
            "china_foreign_affairs_university": "China Foreign Affairs University"
        },
        {
            "xinzhou_teachers_university": "Xinzhou Teachers University"
        },
        {
            "guangdong_industry_polytechnic": "Guangdong Industry Polytechnic"
        },
        {
            "hunan_industry_polytechnic_institute_of_technology": "Hunan Industry Polytechnic Institute of Technology"
        },
        {
            "xingtai_university_(senior_teachers_college)": "Xingtai University (Senior Teachers College)"
        },
        {
            "chengde_medical_university": "Chengde Medical University"
        },
        {
            "jiangsu_police_institute": "Jiangsu Police Institute"
        },
        {
            "chuxiong_normal_university": "Chuxiong Normal University"
        },
        {
            "china_youth_university_of_political_studies": "China Youth University of Political studies"
        },
        {
            "yellow_river_conservancy_technical_institute": "Yellow River Conservancy Technical Institute"
        },
        {
            "wuxi_institute_of_technology": "Wuxi Institute of Technology"
        },
        {
            "lu_liang_university": "Lu Liang University"
        },
        {
            "guilin_normal_college": "Guilin Normal College"
        },
        {
            "shanghai_pharmaceutical_school": "Shanghai Pharmaceutical School"
        },
        {
            "hebei_institute_of_physical_education": "Hebei Institute of Physical Education"
        },
        {
            "zhejiang_institute_of_communications_(vocational_&_technical_institute_of_transportation)": "Zhejiang Institute of Communications (Vocational & Technical Institute of Transportation)"
        },
        {
            "wenzhou_vocational_&_technical_college": "Wenzhou Vocational & Technical College"
        },
        {
            "shandong_women's_university": "Shandong Women's University"
        },
        {
            "kaifeng_university": "Kaifeng University"
        },
        {
            "hubei_polytechnic_institute": "Hubei Polytechnic Institute"
        },
        {
            "jinhua_polytecnic": "Jinhua Polytecnic"
        },
        {
            "xinjiang_university_of_finance_&_economics": "Xinjiang University of Finance & Economics"
        },
        {
            "longdong_university": "Longdong University"
        },
        {
            "xinjiang_medical_university": "Xinjiang Medical University"
        },
        {
            "changzhi_medical_college": "Changzhi Medical College"
        },
        {
            "nanjing_sport_institute": "Nanjing Sport Institute"
        },
        {
            "shandong_police_college": "Shandong Police College"
        },
        {
            "changzhou_college_of_information_technology": "Changzhou College of Information Technology"
        },
        {
            "tangshan_university": "Tangshan University"
        },
        {
            "nanjing_university_of_the_arts": "Nanjing University of the Arts"
        },
        {
            "chaohu_university": "Chaohu University"
        },
        {
            "shandong_youth_university_for_political_science": "Shandong Youth University for Political Science"
        },
        {
            "taiyuan_institute_of_technology": "Taiyuan Institute of Technology"
        },
        {
            "shandong_institute_of_commerce_&_technology": "Shandong Institute of Commerce & Technology"
        },
        {
            "liaoning_institute_of_science_&_technology": "Liaoning Institute of Science & Technology"
        },
        {
            "hunan_railway_professional_technology_college": "Hunan Railway Professional Technology College"
        },
        {
            "anshun_university": "Anshun University"
        },
        {
            "lanzhou_petrochemical_polytechnic": "Lanzhou Petrochemical Polytechnic"
        },
        {
            "changjiang_polytechnic": "Changjiang Polytechnic"
        },
        {
            "xi'an_siyuan_university": "Xi'An Siyuan University"
        },
        {
            "chizhou_university": "Chizhou University"
        },
        {
            "hebei_university_of_architecture": "Hebei University of Architecture"
        },
        {
            "communication_university_of_china_nanguang_college": "Communication University of China Nanguang College"
        },
        {
            "guiyang_preschool_education_college": "Guiyang Preschool Education College"
        },
        {
            "tongren_university": "Tongren University"
        },
        {
            "hebei_polytechnic_institute": "Hebei Polytechnic Institute"
        },
        {
            "beijing_normal_university_zhuhai": "Beijing Normal University Zhuhai"
        },
        {
            "zhejiang_vocational_academy_of_art": "Zhejiang Vocational Academy of Art"
        },
        {
            "nanjing_vocational_institute_of_industry_technology": "Nanjing Vocational Institute of Industry Technology"
        },
        {
            "chengdu_agricultural_college": "Chengdu Agricultural College"
        },
        {
            "chengdu_textile_college": "Chengdu Textile College"
        },
        {
            "guangxi_vocational_&_technical_college": "Guangxi Vocational & Technical College"
        },
        {
            "zhejiang_financial_college": "Zhejiang Financial College"
        },
        {
            "hengshui_university": "Hengshui University"
        },
        {
            "xinjiang_agricultural_vocational_technical_college": "Xinjiang Agricultural Vocational Technical College"
        },
        {
            "laiwu_vocational_&_technical_college": "Laiwu Vocational & Technical College"
        },
        {
            "liuzhou_vocational_&_technical_college": "Liuzhou Vocational & Technical College"
        },
        {
            "harbin_institute_of_technology_weihai": "Harbin Institute of Technology Weihai"
        },
        {
            "beijing_vocational_college_of_agriculture": "Beijing Vocational College of Agriculture"
        },
        {
            "sichuan_minzu_college": "Sichuan Minzu College"
        },
        {
            "shanghai_institute_of_visual_arts": "Shanghai Institute of Visual Arts"
        },
        {
            "zhejiang_technical_institue_of_economics": "Zhejiang Technical Institue of Economics"
        },
        {
            "yang-en_university": "Yang-En University"
        },
        {
            "pingdingshan_industrial_college_of_technology": "Pingdingshan Industrial College of Technology"
        },
        {
            "daqing_vocational_college": "Daqing Vocational College"
        },
        {
            "kunming_metallurgy_college": "Kunming Metallurgy College"
        },
        {
            "hezhou_university": "Hezhou University"
        },
        {
            "sichuan_vocational_&_technical_college_of_communications": "Sichuan Vocational & Technical College of Communications"
        },
        {
            "sias_international_university": "SIAS International University"
        },
        {
            "liaoning_provincial_college_of_communications": "Liaoning Provincial College of Communications"
        },
        {
            "qingdao_technical_college": "Qingdao Technical College"
        },
        {
            "wuhan_institute_of_shipbuilding_technology": "Wuhan Institute of Shipbuilding Technology"
        },
        {
            "https://vicu.utoronto.ca/home": "https://vicu.utoronto.ca/home"
        },
        {
            "changsha_medical_university": "Changsha Medical University"
        },
        {
            "guangdong_polytechnic_of_science_and_technology": "Guangdong Polytechnic of Science and Technology"
        },
        {
            "northeast_university_qinhuangdao": "Northeast University Qinhuangdao"
        },
        {
            "zhengzhou_shengda_university_of_economics_business_and_management": "Zhengzhou Shengda University of Economics Business and Management"
        },
        {
            "jiangsu_agri-animal_husbandry_vocational_college": "Jiangsu Agri-animal Husbandry Vocational College"
        },
        {
            "zhejiang_agriculture_and_forestry_university_and_yangyang_college": "Zhejiang Agriculture and Forestry University and Yangyang College"
        },
        {
            "hangzhou_dianzi_university_information_engineering_institute": "Hangzhou Dianzi University Information Engineering Institute"
        },
        {
            "shandong_university_of_political_science_and_law": "Shandong University of Political Science and Law"
        },
        {
            "nanjing_forest_police_college": "Nanjing Forest Police College"
        },
        {
            "changchun_automobile_industry_institute": "Changchun Automobile Industry Institute"
        },
        {
            "suzhou_vocational_&_technical_college": "Suzhou Vocational & Technical College"
        },
        {
            "youjiang_medical_university_for_nationalities": "Youjiang Medical University for Nationalities"
        },
        {
            "guangdong_police_college": "Guangdong Police College"
        },
        {
            "shaanxi_institute_of_international_trade_&_commerce": "Shaanxi Institute of International Trade & Commerce"
        },
        {
            "fujian_chuanzheng_communications_college": "Fujian Chuanzheng Communications College"
        },
        {
            "changsha_social_work_college": "Changsha Social Work College"
        },
        {
            "ningxia_institute_of_science_&_technology": "Ningxia Institute of Science & Technology"
        },
        {
            "industrial_and_commercial_college_hebei_university": "Industrial and Commercial College Hebei University"
        },
        {
            "zhejiang_police_college": "Zhejiang Police College"
        },
        {
            "communication_university_of_shanxi": "Communication University of Shanxi"
        },
        {
            "hunan_vocational_college_of_commerce": "Hunan Vocational College of Commerce"
        },
        {
            "jiaozuo_university": "Jiaozuo University"
        },
        {
            "beijing_vocational_college_of_science_and_technology": "Beijing Vocational College of Science and Technology"
        },
        {
            "qingdao_vocational_and_technical_college_of_hotel_management": "Qingdao Vocational and Technical College of Hotel Management"
        },
        {
            "xing'an_vocational_&_technical_college": "Xing'an Vocational & Technical College"
        },
        {
            "gannan_medical_university": "Gannan Medical University"
        },
        {
            "shunde_polytechnic": "Shunde Polytechnic"
        },
        {
            "rizhao_polytechnic": "Rizhao Polytechnic"
        },
        {
            "nanyang_institute_of_technology": "Nanyang Institute of Technology"
        },
        {
            "jiangxi_vocational_college_of_finance_and_economics": "Jiangxi Vocational College of Finance and Economics"
        },
        {
            "liaoning_university_of_international_business_&_economics": "Liaoning University of International Business & Economics"
        },
        {
            "ningxia_polytechnic": "Ningxia Polytechnic"
        },
        {
            "anhui_medical_college": "Anhui Medical College"
        },
        {
            "xi'an_medical_university": "Xi'An Medical University"
        },
        {
            "nanhua_college_of_industry_and_commerce": "Nanhua College of Industry and Commerce"
        },
        {
            "foshan_polytechnic": "Foshan Polytechnic"
        },
        {
            "huanghe_jiaotong_university": "Huanghe Jiaotong University"
        },
        {
            "huaiyin_teachers_college": "Huaiyin Teachers College"
        },
        {
            "changzhi_university": "Changzhi University"
        },
        {
            "chongqing_university_urban_science_and_technology_college": "Chongqing University Urban Science and Technology College"
        },
        {
            "baoding_university": "Baoding University"
        },
        {
            "changzhou_textile_garment_institute": "Changzhou Textile Garment Institute"
        },
        {
            "guangxi_arts_university": "Guangxi Arts University"
        },
        {
            "pingxiang_university": "Pingxiang University"
        },
        {
            "henan_college_of_finance_and_taxation": "Henan College of Finance and Taxation"
        },
        {
            "suzhou_university": "Suzhou University"
        },
        {
            "dalian_vocational_&_technical_college": "Dalian Vocational & Technical College"
        },
        {
            "anhui_technical_college_of_mechanical_and_electrical_engineering": "Anhui Technical College of Mechanical and Electrical Engineering"
        },
        {
            "baise_university": "Baise University"
        },
        {
            "jiujiang_vocational_&_technical_college": "Jiujiang Vocational & Technical College"
        },
        {
            "hainan_political_and_law_vocational_college": "Hainan Political and Law Vocational College"
        },
        {
            "guangzhou_city_construction_college": "Guangzhou City Construction College"
        },
        {
            "chongqing_city_management_college": "Chongqing City Management College"
        },
        {
            "shanxi_finance_and_tax_college": "Shanxi Finance and Tax College"
        },
        {
            "shijiashuang_institute_of_railway_technology": "Shijiashuang Institute of Railway Technology"
        },
        {
            "jiangsu_vocational_college_of_agriculture_and_forestry": "Jiangsu Vocational College of Agriculture and Forestry"
        },
        {
            "ningxia_justice_police_vocational_college": "Ningxia Justice Police Vocational College"
        },
        {
            "guangxi_polytechnic_of_construction": "Guangxi Polytechnic of Construction"
        },
        {
            "xuancheng_vocational_and_technical_college": "Xuancheng Vocational and Technical College"
        },
        {
            "ankang_university": "Ankang University"
        },
        {
            "sichuan_technology_&_business_college": "Sichuan Technology & Business College"
        },
        {
            "minxi_vocational_&_technical_college": "Minxi Vocational & Technical College"
        },
        {
            "criminal_investigation_police_university_of_china": "Criminal Investigation Police University of China"
        },
        {
            "jiangsu_maritime_institute": "Jiangsu Maritime Institute"
        },
        {
            "private_hualian_college": "Private Hualian College"
        },
        {
            "beijing_information_technology_college": "Beijing Information Technology College"
        },
        {
            "luohe_medical_college": "Luohe Medical College"
        },
        {
            "zhejiang_business_technology_institute": "Zhejiang Business Technology Institute"
        },
        {
            "zhejiang_institute_of_economics_and_trade": "Zhejiang Institute of Economics and Trade"
        },
        {
            "hubei_polytechnic_university": "Hubei Polytechnic University"
        },
        {
            "beijing_geely_university": "Beijing Geely University"
        },
        {
            "taizhou_university": "Taizhou University"
        },
        {
            "zhejiang_changzheng_vocational_&_technical_college": "Zhejiang Changzheng Vocational & Technical College"
        },
        {
            "chengdu_art_vocational_college": "Chengdu Art Vocational College"
        },
        {
            "heyuan_polytechnic": "Heyuan Polytechnic"
        },
        {
            "heilongjiang_agricultural_engineering_vocational_college": "Heilongjiang Agricultural Engineering Vocational College"
        },
        {
            "wuhan_international_culture_university": "Wuhan International Culture University"
        },
        {
            "wuhan_technical_college_of_communications": "Wuhan Technical College of Communications"
        },
        {
            "guangdong_polytechnic_of_industry_and_commerce": "Guangdong Polytechnic of Industry and Commerce"
        },
        {
            "guangxi_normal_university_for_nationalities": "Guangxi Normal University for Nationalities"
        },
        {
            "guangdong_polytechnic_of_water_resources_and_electric_engineering": "Guangdong Polytechnic of Water Resources and Electric Engineering"
        },
        {
            "sichuan_technology_&_business_university": "Sichuan Technology & Business University"
        },
        {
            "fujian_forestry_vocational_technical_college": "Fujian Forestry Vocational Technical College"
        },
        {
            "guangdong_aib_polytechnic": "Guangdong AIB Polytechnic"
        },
        {
            "guangdong_women's_polytechnic_college": "Guangdong Women's Polytechnic College"
        },
        {
            "hunan_communication_polytechnic": "Hunan Communication Polytechnic"
        },
        {
            "nanjing_normal_university_taizhou_college": "Nanjing Normal University Taizhou College"
        },
        {
            "shandong_college_of_traditional_chinese_medicine": "Shandong College of Traditional Chinese Medicine"
        },
        {
            "hubei_three_gorges_polytechnic": "Hubei Three Gorges Polytechnic"
        },
        {
            "wuhan_college_of_media_and_communications_huazhong_normal_university": "Wuhan College of Media and Communications Huazhong Normal University"
        },
        {
            "hunan_electromechanical_vocational_and_technical_college": "Hunan Electromechanical Vocational and Technical College"
        },
        {
            "shanghai_jianqiao_university": "Shanghai Jianqiao University"
        },
        {
            "guizhou_industry_polytechnic_college": "Guizhou Industry Polytechnic College"
        },
        {
            "hunan_industry_polytechnic": "Hunan Industry Polytechnic"
        },
        {
            "yiyang_medical_college": "Yiyang Medical College"
        },
        {
            "zibo_vocational_institute": "Zibo Vocational Institute"
        },
        {
            "qingdao_huanghai_university": "Qingdao Huanghai University"
        },
        {
            "chengdu_engineering_&_technical_college_of_university_of_technology": "Chengdu Engineering & Technical College of University of Technology"
        },
        {
            "changchun_institute_of_architecture": "Changchun Institute of Architecture"
        },
        {
            "henan_polytechnic": "Henan Polytechnic"
        },
        {
            "changchun_vocational_institute_of_technology": "Changchun Vocational Institute of Technology"
        },
        {
            "xianda_college_of_economics_&_humanities_shanghai_international_studies_university": "Xianda College of Economics & Humanities Shanghai International Studies University"
        },
        {
            "university_of_electronic_science_and_technology_of_china_zhongshan_institute": "University of Electronic Science and Technology of China Zhongshan Institute"
        },
        {
            "yangzhou_institute_of_technology": "Yangzhou Institute of Technology"
        },
        {
            "jiangsu_academy_of_agricultural_sciences": "Jiangsu Academy of Agricultural Sciences"
        },
        {
            "shijiazhuang_information_engineering_vocational_college": "Shijiazhuang Information Engineering Vocational College"
        },
        {
            "liaoning_mechatronics_college": "Liaoning Mechatronics College"
        },
        {
            "chengdu_technological_university": "Chengdu Technological University"
        },
        {
            "jiangxi_college_of_foreign_studies": "Jiangxi College of Foreign Studies"
        },
        {
            "jiangxi_tourism_business_college": "Jiangxi Tourism Business College"
        },
        {
            "xinyu_university": "Xinyu University"
        },
        {
            "yibin_vocational_and_technical_college": "Yibin Vocational and Technical College"
        },
        {
            "liaoning_vocational_college": "Liaoning Vocational College"
        },
        {
            "fuzhou_university_of_international_studies_and_trade": "Fuzhou University of International Studies and Trade"
        },
        {
            "guangxi_vocational_college_of_technology_and_business": "Guangxi Vocational College of Technology and Business"
        },
        {
            "zhejiang_construction_vocational_and_technical_college": "Zhejiang Construction Vocational and Technical College"
        },
        {
            "anhui_open_university": "Anhui Open University"
        },
        {
            "gengdan_institute_of_beijing_university_of_technology": "Gengdan Institute of Beijing University of Technology"
        },
        {
            "sanya_university": "Sanya University"
        },
        {
            "the_open_university_of_fujian": "The Open University of Fujian"
        },
        {
            "yunnan_normal_university_business_school": "Yunnan Normal University Business School"
        },
        {
            "liaocheng_vocational_&_technical_college": "Liaocheng Vocational & Technical College"
        },
        {
            "shaanxi_railway_institute": "Shaanxi Railway Institute"
        },
        {
            "nanchang_normal_university": "Nanchang Normal University"
        },
        {
            "jiangxi_industry_polytechnic_college": "Jiangxi Industry Polytechnic College"
        },
        {
            "guangdong_mechanical_&_electrical_polytechnic": "Guangdong Mechanical & Electrical Polytechnic"
        },
        {
            "hetao_college": "Hetao College"
        },
        {
            "shijiazhuang_posts_and_telecommunications_technical_college": "Shijiazhuang Posts and Telecommunications Technical College"
        },
        {
            "kunming_professional_college_of_arts": "Kunming Professional College of Arts"
        },
        {
            "xi'an_fanyi_university": "Xi'An Fanyi University"
        },
        {
            "tianjin_coastal_polytechnice": "Tianjin Coastal Polytechnice"
        },
        {
            "shanghai_industry_and_commerce_foreign_language_college": "Shanghai Industry and Commerce Foreign Language College"
        },
        {
            "shaanxi_college_of_communication_technology": "Shaanxi College of Communication Technology"
        },
        {
            "guangxi_international_business_vocational_college": "Guangxi International Business Vocational College"
        },
        {
            "guangxi_electrical_polytechnic_institute": "Guangxi Electrical Polytechnic Institute"
        },
        {
            "zhengzhou_electric_power_college": "Zhengzhou Electric Power College"
        },
        {
            "lanzhou_university_of_arts_and_science": "Lanzhou University of Arts and Science"
        },
        {
            "xingtai_medical_college": "Xingtai Medical College"
        },
        {
            "fujian_water_conservancy_and_electric_power_vocational_and_technical_college": "Fujian Water Conservancy and Electric Power Vocational and Technical College"
        },
        {
            "henan_polytechnic_institute": "Henan Polytechnic Institute"
        },
        {
            "shandong_foreign_trade_vocational_college": "Shandong Foreign Trade Vocational College"
        },
        {
            "vocational_&_technical_college_of_anshun": "Vocational & Technical College of Anshun"
        },
        {
            "taizhou_polytechnic_college": "Taizhou Polytechnic College"
        },
        {
            "hunan_chemical_vocational_and_technical_college": "Hunan Chemical Vocational and Technical College"
        },
        {
            "anhui_zhongao_vocational_college_of_science_and_technology": "Anhui Zhongao Vocational College of Science and Technology"
        },
        {
            "chengde_petroleum_college": "Chengde Petroleum College"
        },
        {
            "chengdu_institute_sichuan_international_studies_university": "Chengdu Institute Sichuan International Studies University"
        },
        {
            "xiangyang_polytechnic": "Xiangyang Polytechnic"
        },
        {
            "qilu_normal_university": "Qilu Normal University"
        },
        {
            "wuchang_university_of_technology": "Wuchang University of Technology"
        },
        {
            "shaoxing_vocational_&_technical_college": "Shaoxing Vocational & Technical College"
        },
        {
            "zhoukou_vocational_and_technical_college": "Zhoukou Vocational and Technical College"
        },
        {
            "qiannan_polytechnic_for_nationalities": "Qiannan Polytechnic for Nationalities"
        },
        {
            "shandong_polytechnic_(jining_railway_institute_of_technology)": "Shandong Polytechnic (Jining Railway Institute of Technology)"
        },
        {
            "anhui_business_vocational_college": "Anhui Business Vocational College"
        },
        {
            "heze_medical_college": "Heze Medical College"
        },
        {
            "guangxi_agricultural_vocational_&_technical_college": "Guangxi Agricultural Vocational & Technical College"
        },
        {
            "nantong_shipping_college": "Nantong Shipping College"
        },
        {
            "guangzhou_college_south_china_university_of_technology": "Guangzhou College South China University of Technology"
        },
        {
            "zhengzhou_technical_college": "Zhengzhou Technical College"
        },
        {
            "xiamen_huaxia_university": "Xiamen Huaxia University"
        },
        {
            "jining_normal_university": "Jining Normal University"
        },
        {
            "jinan_vocational_college": "Jinan Vocational College"
        },
        {
            "shanghai_national_accounting_institute": "Shanghai National Accounting Institute"
        },
        {
            "peking_university_founder_technology_college": "Peking University Founder Technology College"
        },
        {
            "zhangjiajie_institute_of_aeronautical_engineering": "Zhangjiajie Institute of Aeronautical Engineering"
        },
        {
            "urumqi_vocational_university": "Urumqi Vocational University"
        },
        {
            "cangzhou_technical_college": "Cangzhou Technical College"
        },
        {
            "chongqing_vocational_institute_of_engineering": "Chongqing Vocational Institute of Engineering"
        },
        {
            "hunan_automobile_engineering_professional_college": "Hunan Automobile Engineering Professional College"
        },
        {
            "zhejiang_dongfang_vocational_and_technical_college": "Zhejiang Dongfang Vocational and Technical College"
        },
        {
            "henan_institute_of_economics_and_trade": "Henan Institute of Economics and Trade"
        },
        {
            "yongzhou_vocational_technical_college": "Yongzhou Vocational Technical College"
        },
        {
            "jiangsu_union_technical_institute": "Jiangsu Union Technical Institute"
        },
        {
            "zhixing_college_of_hubei_university": "Zhixing College of Hubei University"
        },
        {
            "inner_mongolia_electronic_information_vocational_technical_college": "Inner Mongolia Electronic Information Vocational Technical College"
        },
        {
            "china_university_of_mining_and_technology_yinchuan_college": "China University of Mining and Technology Yinchuan College"
        },
        {
            "shaanxi_fashion_engineering_university": "Shaanxi Fashion Engineering University"
        },
        {
            "zhejiang_conservatory_of_music": "Zhejiang Conservatory of Music"
        },
        {
            "xiamen_ocean_vocational_college": "Xiamen Ocean Vocational College"
        },
        {
            "xiamen_xingcai_polytechnic": "Xiamen Xingcai Polytechnic"
        },
        {
            "xiangtan_medical_and_health_vocational_and_technical_college": "Xiangtan Medical and Health Vocational and Technical College"
        },
        {
            "yunnan_college_of_business_management": "Yunnan College of Business Management"
        },
        {
            "changsha_commerce_&_tourism_college": "Changsha Commerce & Tourism College"
        },
        {
            "jiangxi_vocational_college_of_science_and_technology": "Jiangxi Vocational College of Science and Technology"
        },
        {
            "jiangsu_vocational_college_of_information_technology": "Jiangsu Vocational College of Information Technology"
        },
        {
            "liaoning_agricultural_college": "Liaoning Agricultural College"
        },
        {
            "xi'an_jiaotong_engineering_college": "Xi'an Jiaotong Engineering College"
        },
        {
            "xinjiang_vocational_university": "Xinjiang Vocational University"
        },
        {
            "jilin_technology_college_of_electronic_information": "Jilin Technology College of Electronic Information"
        },
        {
            "hunan_urban_construction_college": "Hunan Urban Construction College"
        },
        {
            "shandong_business_institute": "Shandong Business Institute"
        },
        {
            "changsha_aeronautical_vocational_&_technical_college": "Changsha Aeronautical Vocational & Technical College"
        },
        {
            "xianyang_vocational_technical_college": "Xianyang Vocational Technical College"
        },
        {
            "beijing_political_and_law_vocational_college": "Beijing Political and Law Vocational College"
        },
        {
            "guilin_university_of_aerospace_technology": "Guilin University of Aerospace Technology"
        },
        {
            "wenhua_college": "Wenhua College"
        },
        {
            "yunnan_agricultural_vocational_and_technical_college": "Yunnan Agricultural Vocational and Technical College"
        },
        {
            "shanxi_institute_of_mechanical_and_electrical_engineering": "Shanxi Institute of Mechanical and Electrical Engineering"
        },
        {
            "tianjin_open_university": "Tianjin Open University"
        },
        {
            "art_&_culture_college_of_tianjin_university_of_sport": "Art & Culture College of Tianjin University of Sport"
        },
        {
            "hubei_communications_technical_college": "Hubei Communications Technical College"
        },
        {
            "beijing_jiaotong_vocational_&_technical_college": "Beijing Jiaotong Vocational & Technical College"
        },
        {
            "jingchu_university_of_technology": "Jingchu University of Technology"
        },
        {
            "jilin_vocational_college_of_industry_and_technology": "Jilin Vocational College of Industry and Technology"
        },
        {
            "weinan_institute_of_science_and_technology": "Weinan Institute of Science and Technology"
        },
        {
            "hunan_college_of_information": "Hunan College of Information"
        },
        {
            "hunan_women's_university": "Hunan Women's University"
        },
        {
            "chongqing_technology_and_business_institute": "Chongqing Technology and Business Institute"
        },
        {
            "shandong_technology_and_business_university": "Shandong Technology and Business University"
        },
        {
            "zibo_normal_college": "Zibo Normal College"
        },
        {
            "hunan_arts_and_crafts_vocational_college": "Hunan Arts and Crafts Vocational College"
        },
        {
            "jilin_railway_technology_college": "Jilin Railway Technology College"
        },
        {
            "dongchang_college_liaocheng_university": "Dongchang College Liaocheng University"
        },
        {
            "lijiang_college_of_guangxi_normal_university": "Lijiang College of Guangxi Normal University"
        },
        {
            "inner_mongolia_police_professional_college": "Inner Mongolia Police Professional College"
        },
        {
            "jiaxing_nanyang_polytechnic_institute": "Jiaxing Nanyang Polytechnic Institute"
        },
        {
            "lanzhou_university_of_finance_and_economics": "Lanzhou University of Finance and Economics"
        },
        {
            "xianning_vocational_technical_college": "Xianning Vocational Technical College"
        },
        {
            "college_of_information_shanxi_agricultural_university": "College of Information Shanxi Agricultural University"
        },
        {
            "hebei_academy_of_fine_arts": "Hebei Academy of Fine Arts"
        },
        {
            "quanzhou_huaguang_vocational_college": "Quanzhou Huaguang Vocational College"
        },
        {
            "hunan_traditional_chinese_medical_college_": "Hunan traditional chinese medical college "
        },
        {
            "bohai_shipbuilding_vocational_college": "Bohai Shipbuilding Vocational College"
        },
        {
            "chongqing_aerospace_polytechnic": "Chongqing Aerospace Polytechnic"
        },
        {
            "sichuan_electromechanical_institute_of_vocation_and_technology": "Sichuan Electromechanical Institute of Vocation and Technology"
        },
        {
            "jiangxi_institute_of_economic_administrators": "Jiangxi Institute of Economic Administrators"
        },
        {
            "jiangyin_polytechnic_college": "Jiangyin Polytechnic College"
        },
        {
            "capital_university_of_physical_education_&_sports": "Capital University of Physical Education & Sports"
        },
        {
            "beijing_college_of_social_administration": "Beijing College of Social Administration"
        },
        {
            "chongqing_institute_of_engineering": "Chongqing Institute of Engineering"
        },
        {
            "suzhou_institute_of_trade_&_commerce": "Suzhou Institute of Trade & Commerce"
        },
        {
            "ningbo_city_college_of_vocational_technology": "Ningbo City College of Vocational Technology"
        },
        {
            "zhejiang_yuying_college_of_vocational_technology": "Zhejiang Yuying College of Vocational Technology"
        },
        {
            "tianjin_medical_college": "Tianjin Medical College"
        },
        {
            "beijing_youth_politics_college": "Beijing Youth Politics College"
        },
        {
            "shanghai_film_art_academy": "Shanghai Film Art Academy"
        },
        {
            "anhui_business_vocational_and_technical_college": "Anhui Business Vocational and Technical College"
        },
        {
            "tianshi_college": "Tianshi College"
        },
        {
            "anhui_wenda_university_of_information_engineering": "Anhui Wenda University of Information Engineering"
        },
        {
            "yichun_vocational_and_technical_college": "Yichun Vocational and Technical College"
        },
        {
            "college_of_mobile_telecommunications_chongqing_university_of_posts_and_telecommunications": "College of Mobile Telecommunications Chongqing University of Posts and Telecommunications"
        },
        {
            "fujian_police_college": "Fujian Police College"
        },
        {
            "beijing_vocational_college_of_labour_and_social_security": "Beijing Vocational College of Labour and Social Security"
        },
        {
            "hunan_vocational_college_of_railway_technology": "Hunan Vocational College of Railway Technology"
        },
        {
            "huasang_college_guangdong_university_of_business_studies": "Huasang College Guangdong University of Business Studies"
        },
        {
            "xiantao_vocational_college": "Xiantao Vocational College"
        },
        {
            "jiujiang_vocational_university": "Jiujiang Vocational University"
        },
        {
            "zhejiang_police_vocational_academy": "Zhejiang Police Vocational Academy"
        },
        {
            "jiangsu_vocational_institute_of_architectural_technology": "Jiangsu Vocational Institute of Architectural Technology"
        },
        {
            "chongqing_creation_vocational_college": "Chongqing Creation Vocational College"
        },
        {
            "nanjing_university_of_information_science_and_technology_binjiang_college": "Nanjing University of Information Science and Technology Binjiang College"
        },
        {
            "baotou_light_industry_vocational_technical_college": "Baotou Light Industry Vocational Technical College"
        },
        {
            "shandong_xiehe_university": "Shandong Xiehe University"
        },
        {
            "jiangxi_application_engineering_vocational_college": "Jiangxi Application Engineering Vocational College"
        },
        {
            "wenzhou-kean_university": "Wenzhou-Kean University"
        },
        {
            "yunnan_university_tourism_and_culture_college": "Yunnan University Tourism and Culture College"
        },
        {
            "changsha_normal_university": "Changsha Normal University"
        },
        {
            "luohe_vocational_technology_college": "Luohe Vocational Technology College"
        },
        {
            "maanshan_teacher's_college": "Maanshan Teacher's College"
        },
        {
            "wuhan_engineering_institute": "Wuhan Engineering Institute"
        },
        {
            "changjiang_institute_of_technology": "Changjiang Institute of Technology"
        },
        {
            "sichuan_vocational_college_of_information_technology": "Sichuan Vocational College of Information Technology"
        },
        {
            "shandong_college_of_tourism_&_hospitality": "Shandong College of Tourism & Hospitality"
        },
        {
            "shanghai_vocational_college_of_agriculture_and_forestry": "Shanghai Vocational College of Agriculture and Forestry"
        },
        {
            "sanquan_college_xinxiang_medical_university": "Sanquan College Xinxiang Medical University"
        },
        {
            "nanchang_university_college_of_science_&_technology": "Nanchang University College of Science & Technology"
        },
        {
            "shanxi_university_of_traditional_chinese_medicine": "Shanxi University of Traditional Chinese Medicine"
        },
        {
            "jiangxi_ceramics_&_art_institute": "Jiangxi Ceramics & Art Institute"
        },
        {
            "jiangxi_university_of_engineering": "Jiangxi University of Engineering"
        },
        {
            "guilin_institute_of_life_and_health_vocational_and_technical_college": "Guilin Institute of Life and Health Vocational and Technical College"
        },
        {
            "hubei_college_of_chinese_medicine": "Hubei College of Chinese Medicine"
        },
        {
            "zhejiang_international_maritime_college": "Zhejiang International Maritime College"
        },
        {
            "tianjin_university_of_commerce_bousted_college": "Tianjin University of Commerce Bousted College"
        },
        {
            "qingdao_hengxing_university_of_science_and_technology": "Qingdao Hengxing University of Science and Technology"
        },
        {
            "suqian_college": "Suqian College"
        },
        {
            "taishan_polytechnic": "Taishan Polytechnic"
        },
        {
            "hebei_college_of_science_and_technology": "Hebei College of Science and Technology"
        },
        {
            "hebei_college_of_industry_and_technology": "Hebei College of Industry and Technology"
        },
        {
            "lanzhou_jiaotong_university_bowen_college": "Lanzhou Jiaotong University Bowen College"
        },
        {
            "guangdong_ocean_university_(cunjin_college)": "Guangdong Ocean University (Cunjin College)"
        },
        {
            "hefei_vocational_and_technical_college": "Hefei Vocational and Technical College"
        },
        {
            "nanjing_institute_of_railway_technology": "Nanjing Institute of Railway Technology"
        },
        {
            "chongqing_foreign_studies_university_chongqing_southern_translation_college": "Chongqing Foreign Studies University Chongqing Southern Translation College"
        },
        {
            "capital_college_of_science_and_technology": "Capital College of Science and Technology"
        },
        {
            "hohhot_minzu_college": "Hohhot Minzu College"
        },
        {
            "jiangxi_environmental_engineering_vocational_college": "Jiangxi Environmental Engineering Vocational College"
        },
        {
            "guangdong_polytechnic_of_science_and_trade": "Guangdong Polytechnic of Science and trade"
        },
        {
            "hubei_ecology_vocational_college": "Hubei Ecology Vocational College"
        },
        {
            "dalian_art_college": "Dalian Art College"
        },
        {
            "xi'an_electric_power_college": "Xi'an Electric Power College"
        },
        {
            "huaian_vocational_college_of_information_technology": "Huaian Vocational College of Information Technology"
        },
        {
            "tianjin_electromechanical_vocational_and_technical_college": "Tianjin Electromechanical Vocational and Technical College"
        },
        {
            "shanxi_institute_of_architecture_and_technology": "Shanxi Institute of Architecture and Technology"
        },
        {
            "cangzhou_medical_college": "Cangzhou Medical College"
        },
        {
            "guizhou_light_industry_technical_college": "Guizhou Light Industry Technical College"
        },
        {
            "jiangxi_college_of_application_science_and_technology": "Jiangxi College of Application Science and Technology"
        },
        {
            "qilu_institute_of_technology": "Qilu Institute of Technology"
        },
        {
            "shanxi_traffic_vocational_and_technical_college": "Shanxi Traffic Vocational and Technical College"
        },
        {
            "institute_of_disaster_prevention": "Institute of Disaster Prevention"
        },
        {
            "jieyang_vocational_&_technical_college": "JieYang Vocational & Technical College"
        },
        {
            "hubei_light_industry_technology_institute": "Hubei Light Industry Technology Institute"
        },
        {
            "jinjiang_college_sichuan_university": "Jinjiang College Sichuan University"
        },
        {
            "shandong_vocational_college_of_foreign_affairs": "Shandong Vocational College of Foreign Affairs"
        },
        {
            "jinzhuo_teachers_training_college": "Jinzhuo Teachers Training College"
        },
        {
            "chenzhou_vocational_technical_college": "Chenzhou Vocational Technical College"
        },
        {
            "changchun_university_of_technology_school_of_humanities_and_information": "Changchun University of Technology School of Humanities and Information"
        },
        {
            "chongqing_information_technology_college": "Chongqing Information Technology College"
        },
        {
            "chinese_people's_armed_police_force_academy": "Chinese People's Armed Police Force Academy"
        },
        {
            "inner_mongolia_technical_college_of_construction": "Inner Mongolia Technical College of Construction"
        },
        {
            "anhui_technical_college_of_industry_and_economy": "Anhui Technical College of Industry and Economy"
        },
        {
            "hotan_institute_of_education": "Hotan Institute of Education"
        },
        {
            "chaoyang_teachers_college": "Chaoyang Teachers College"
        },
        {
            "gansu_forestry_technological_college": "Gansu Forestry Technological College"
        },
        {
            "guangxi_cadres_university_of_economics_and_management": "Guangxi Cadres University of Economics and Management"
        },
        {
            "qindao_technological_university_qindao_college": "Qindao Technological University Qindao College"
        },
        {
            "guangzhou_railway_polytechnic": "Guangzhou Railway Polytechnic"
        },
        {
            "huainan_union_university": "Huainan Union University"
        },
        {
            "shangqiu_medical_college": "Shangqiu Medical College"
        },
        {
            "xi'an_technological_university_northern_college_of_information_engineering": "Xi'an Technological University Northern College of Information Engineering"
        },
        {
            "zhengzhou_railway_vocational_&_technical_college": "Zhengzhou Railway Vocational & Technical College"
        },
        {
            "school_of_science_and_technology_jiangxi_university_of_traditional_chinese_medicine": "School of Science and Technology Jiangxi University of Traditional Chinese Medicine"
        },
        {
            "anhui_industry_polytechnic": "Anhui Industry Polytechnic"
        },
        {
            "hebei_university_of_chinese_medicine": "Hebei University of Chinese Medicine"
        },
        {
            "hunan_polytechnic_college_of_environment_and_biology": "Hunan Polytechnic College of Environment and Biology"
        },
        {
            "shanghai_aurora_college": "Shanghai Aurora College"
        },
        {
            "sichuan_top_it_vocational_institute": "Sichuan Top IT Vocational Institute"
        },
        {
            "suzhou_art_&_design_technology_institute": "Suzhou Art & Design Technology Institute"
        },
        {
            "handan_polytechnic_college": "Handan Polytechnic College"
        },
        {
            "changzhou_vocational_institute_of_mechatronic_technology": "Changzhou Vocational Institute of Mechatronic Technology"
        },
        {
            "bengbu_university": "Bengbu University"
        },
        {
            "jiangsu_vocational_college_of_finance_and_economics_/_jiangsu_university_of_finance_and_economics": "Jiangsu Vocational College of Finance and Economics / Jiangsu University of Finance and Economics"
        },
        {
            "hebei_chemical_&_pharmaceutical_college": "Hebei Chemical & Pharmaceutical College"
        },
        {
            "hebei_political_and_law_vocational_college": "Hebei Political and Law Vocational College"
        },
        {
            "hunan_international_business_vocational_college": "Hunan International Business Vocational College"
        },
        {
            "national_academy_of_education_administration": "National Academy of Education Administration"
        },
        {
            "yunnan_vocational_college_of_mechanical_and_electrical_technology": "Yunnan Vocational College of Mechanical and Electrical Technology"
        },
        {
            "inner_mongolia_university_of_finance_&_economics": "Inner Mongolia University of Finance & Economics"
        },
        {
            "changzhou_vocational_institute_of_light_industry": "Changzhou Vocational Institute of Light Industry"
        },
        {
            "institute_of_technology_east_china_jiao_tong_university": "Institute of Technology East China Jiao tong University"
        },
        {
            "shaanxi_institute_of_industrial_technology": "Shaanxi Institute of Industrial Technology"
        },
        {
            "beijing_bailie_university": "Beijing Bailie University"
        },
        {
            "wenzheng_college_soochow_university": "Wenzheng College Soochow University"
        },
        {
            "hunan_financial_&_industrial_vocational-technical_college": "Hunan Financial & Industrial Vocational-Technical College"
        },
        {
            "guangdong_atv_professional_academy_for_performing_arts": "Guangdong ATV Professional Academy for Performing Arts"
        },
        {
            "shaanxi_aviation_professional_technical_institute": "Shaanxi Aviation Professional Technical Institute"
        },
        {
            "shanghai_university_of_finance_&_economics_zhejiang_college": "Shanghai University of Finance & Economics Zhejiang College"
        },
        {
            "zhejiang_university_of_finance_and_economics_dongfang_college": "Zhejiang University of Finance and Economics Dongfang College"
        },
        {
            "wuhan_college_of_foreign_languages_&_foreign_affairs": "Wuhan College of Foreign Languages & Foreign Affairs"
        },
        {
            "great_wall_of_china_university_of_geosciences": "Great Wall of China University of Geosciences"
        },
        {
            "qinhuangdao_institute_of_technology": "Qinhuangdao Institute of Technology"
        },
        {
            "qiannan_medical_college_for_nationalities": "Qiannan Medical College for Nationalities"
        },
        {
            "wuhan_vocational_college_of_communications_and_publishing": "Wuhan Vocational College of Communications and Publishing"
        },
        {
            "wuxi_south_ocean_college_(wuxi_nanyang_vocational_college)": "Wuxi South Ocean College (Wuxi Nanyang Vocational College)"
        },
        {
            "renai_college_tianjin_university": "Renai College Tianjin University"
        },
        {
            "tieling_normal_college": "Tieling Normal College"
        },
        {
            "weinan_vocational_and_technical_college": "Weinan Vocational and Technical College"
        },
        {
            "xiamen_institute_of_technology": "Xiamen Institute of Technology"
        },
        {
            "yangling_vocational_&_technical_college": "Yangling Vocational & Technical College"
        },
        {
            "liaoning_economic_vocational_and_technical_college": "Liaoning Economic Vocational and Technical College"
        },
        {
            "lanzhou_resources_&_environmet_voc-tech_college": "Lanzhou Resources & Environmet Voc-Tech College"
        },
        {
            "nanchang_institute_of_technology": "Nanchang Institute of Technology"
        },
        {
            "hangzhou_wanxiang_polytechnic": "Hangzhou Wanxiang Polytechnic"
        },
        {
            "hunan_modern_logistics_vocational_and_technical_college": "Hunan Modern Logistics Vocational and Technical College"
        },
        {
            "zhejiang_yuexiu_university_of_foreign_languages": "Zhejiang Yuexiu University of Foreign Languages"
        },
        {
            "beijing_opera_art's_college": "Beijing Opera Art's College"
        },
        {
            "xilingol_vocational_college": "Xilingol Vocational College"
        },
        {
            "hunan_vocational_institute_of_technology": "Hunan Vocational Institute of Technology"
        },
        {
            "anhui_vocational_college_of_press_and_publication": "Anhui Vocational College of Press and Publication"
        },
        {
            "nanning_university": "Nanning University"
        },
        {
            "shandong_university_of_finance_and_economics_oriental_college": "Shandong University of Finance and Economics Oriental College"
        },
        {
            "guangdong_xinhua_collegeinstitute_of_education": "Guangdong Xinhua CollegeInstitute of Education"
        },
        {
            "hunan_institute_of_technology": "Hunan Institute of Technology"
        },
        {
            "huizhou_economics_and_polytechnic_college": "Huizhou Economics and Polytechnic College"
        },
        {
            "jiangxi_vocational_college_of_art": "Jiangxi Vocational College of Art"
        },
        {
            "suzhou_chien-shiung_institute_of_technology": "Suzhou Chien-Shiung Institute of Technology"
        },
        {
            "chengdu_aeronautic_polytechnic": "Chengdu Aeronautic Polytechnic"
        },
        {
            "fuzhou_university_zhicheng_college": "Fuzhou University Zhicheng College"
        },
        {
            "sichuan_tourism_university": "Sichuan Tourism University"
        },
        {
            "liaoning_institute_of_media": "Liaoning Institute of Media"
        },
        {
            "shaanxi_industrial_technology": "Shaanxi Industrial Technology"
        },
        {
            "jiangxi_biotechnology_vocational_college": "Jiangxi Biotechnology Vocational College"
        },
        {
            "guangxi_university_of_foreign_languages": "Guangxi University of Foreign Languages"
        },
        {
            "shandong_labor_vocational_&_technical_college": "Shandong Labor Vocational & Technical College"
        },
        {
            "weifang_vocational_college": "Weifang Vocational College"
        },
        {
            "zhengzhou_university_of_science_and_technology": "Zhengzhou University of Science and Technology"
        },
        {
            "yantai_automobile_engineering_professional_college": "Yantai Automobile Engineering Professional College"
        },
        {
            "inner_mongolia_technical_college_of_mechanics_and_electrics": "Inner Mongolia Technical College of Mechanics and Electrics"
        },
        {
            "jiangxi_health_vocational_college": "Jiangxi Health Vocational College"
        },
        {
            "xiamen_donghai_institute": "Xiamen Donghai Institute"
        },
        {
            "guangxi_university_of_traditional_chinese_medicine_sainsi_new_school_of_medicine": "Guangxi University of Traditional Chinese Medicine Sainsi New School of Medicine"
        },
        {
            "fujian_institute_of_education": "Fujian Institute of Education"
        },
        {
            "guilin_university_of_electronic_science_and_technology": "Guilin University of Electronic Science and Technology"
        },
        {
            "zaozhuang_vocational_college_of_science_&_technology": "Zaozhuang Vocational College of Science & Technology"
        },
        {
            "zhejiang_pharmaceutical_college": "Zhejiang Pharmaceutical College"
        },
        {
            "tianjin_light_industry_vocational_technical_college": "Tianjin Light Industry Vocational Technical College"
        },
        {
            "shandong_xiandai_university": "Shandong Xiandai University"
        },
        {
            "wuxi_vocational_college_of_science_and_technology": "Wuxi Vocational College of Science and Technology"
        },
        {
            "xinjiang_tianshan_vocational_&_technical_college": "Xinjiang Tianshan Vocational & Technical College"
        },
        {
            "jiangsu_jiankang_vocational_college": "Jiangsu Jiankang Vocational College"
        },
        {
            "jiangxi_manufacturing_vocational_and_technical_college": "Jiangxi Manufacturing Vocational and Technical College"
        },
        {
            "kaili_university_(carey_college)": "Kaili University (Carey College)"
        },
        {
            "changsha_environmental_protection_college": "Changsha Environmental Protection College"
        },
        {
            "science_and_technology_college_of_hubei_university_for_nationalities": "Science and Technology College of Hubei University for Nationalities"
        },
        {
            "shanghai_communications_polytechnic": "Shanghai Communications Polytechnic"
        },
        {
            "changsha_nanfang_professional_college": "Changsha Nanfang Professional College"
        },
        {
            "chongqing_energy_college": "Chongqing Energy College"
        },
        {
            "anhui_professional_college_of_art": "Anhui Professional College of Art"
        },
        {
            "chongqing_vocational_college_of_media": "Chongqing Vocational College of Media"
        },
        {
            "shandong_vocational_college_of_economics_and_trade": "Shandong Vocational College of Economics and Trade"
        },
        {
            "shangqiu_polytechnic": "Shangqiu Polytechnic"
        },
        {
            "northwestern_university_modern_college": "Northwestern University Modern College"
        },
        {
            "gansu_vocational_and_technical_college_of_communications": "Gansu Vocational and Technical College of Communications"
        },
        {
            "guangzhou_institute_of_technology": "Guangzhou Institute of Technology"
        },
        {
            "huaibei_vocational_&_technical_college": "Huaibei Vocational & Technical College"
        },
        {
            "hefei_information_technology_university": "Hefei Information Technology University"
        },
        {
            "xiaoxiang_vocational_college": "Xiaoxiang vocational college"
        },
        {
            "anhui_vocactional_&_technical_college_of_industry_&_trade": "Anhui Vocactional & Technical College of Industry & Trade"
        },
        {
            "sichuan_water_conservancy_vocational_college": "Sichuan Water Conservancy Vocational College"
        },
        {
            "shangrao_vocational_&_technical_college": "Shangrao Vocational & Technical College"
        },
        {
            "sichuan_police_college": "Sichuan Police College"
        },
        {
            "liming_vocational_university": "Liming Vocational University"
        },
        {
            "hebei_radio_and_tv_university": "Hebei Radio and TV University"
        },
        {
            "henan_police_college": "Henan Police College"
        },
        {
            "hankou_university": "Hankou University"
        },
        {
            "liaoning_police_college": "Liaoning Police College"
        },
        {
            "shandong_garment_vocational_college": "Shandong Garment Vocational College"
        },
        {
            "zhengzhou_professional_technical_institute_of_electronics_&_information": "Zhengzhou Professional Technical Institute of Electronics & Information"
        },
        {
            "zhejiang_radio_&_television_university": "Zhejiang Radio & Television University"
        },
        {
            "aichi_bunkyo_university": "Aichi Bunkyo University"
        },
        {
            "shaanxi_youth_vocational_college": "Shaanxi Youth Vocational College"
        },
        {
            "shanxi_water_conservancy_vocational_and_technical_college": "Shanxi Water Conservancy Vocational and Technical College"
        },
        {
            "ya'an_polytechnic_college": "Ya'an Polytechnic College"
        },
        {
            "anhui_broadcasting_movie_and_television_college": "Anhui Broadcasting Movie and Television College"
        },
        {
            "shanghai_normal_university_tianhua_college": "Shanghai Normal University Tianhua College"
        },
        {
            "xi'an_haitang_vocational_college": "Xi'An Haitang Vocational College"
        },
        {
            "yunnan_vocational_institute_of_energy_technology": "Yunnan Vocational Institute of Energy Technology"
        },
        {
            "hubei_science_and_technology_college": "Hubei Science and Technology College"
        },
        {
            "sichuan_finance_and_economics_vocational_college": "Sichuan Finance and Economics Vocational College"
        },
        {
            "shandong_college_of_information_technology": "Shandong College of Information Technology"
        },
        {
            "hefei_economic_and_technological_college": "Hefei Economic and Technological College"
        },
        {
            "mingde_college_northwestern_polytechnical_university": "Mingde College Northwestern Polytechnical University"
        },
        {
            "harbin_guangsha_college": "Harbin Guangsha College"
        },
        {
            "tianjin_petroleum_vocational_and_technical_college": "Tianjin Petroleum Vocational and Technical College"
        },
        {
            "binzhou_polytechnic": "BinZhou Polytechnic"
        },
        {
            "shanghai_zhongqiao_college": "Shanghai Zhongqiao College"
        },
        {
            "hunan_national_vocational_college": "Hunan National Vocational College"
        },
        {
            "jiangxi_industrial_and_trade_vocational_and_technical_college": "Jiangxi Industrial and Trade Vocational and Technical College"
        },
        {
            "nanjing_polytechnic_institute": "Nanjing Polytechnic Institute"
        },
        {
            "anhui_vocational_&_technical_college": "Anhui Vocational & Technical College"
        },
        {
            "wuhan_city_vocational_college": "Wuhan City Vocational College"
        },
        {
            "shanghai_lida_polytechnic_institute": "Shanghai Lida Polytechnic Institute"
        },
        {
            "shandong_management_university": "Shandong Management University"
        },
        {
            "harbin_railway_technical_college": "Harbin Railway Technical College"
        },
        {
            "changsha_vocational_&_technical_college": "Changsha Vocational & Technical College"
        },
        {
            "lanzhou_institute_of_technology": "Lanzhou Institute of Technology"
        },
        {
            "jiangxi_college_of_traditional_chinese_medicine": "Jiangxi College of Traditional Chinese Medicine"
        },
        {
            "sichuan_modern_vocational_college": "Sichuan Modern Vocational College"
        },
        {
            "guang'an_vocational_&_technical_college": "Guang'an Vocational & Technical College"
        },
        {
            "guangxi_college_for_preschool_education": "Guangxi College for Preschool Education"
        },
        {
            "guangzhou_panyu_polytechnic": "Guangzhou Panyu Polytechnic"
        },
        {
            "henan_college_of_transportation": "Henan College of Transportation"
        },
        {
            "wuchang_institute_of_technology": "Wuchang Institute of Technology"
        },
        {
            "qiandongnan_national_polytechnic": "Qiandongnan National Polytechnic"
        },
        {
            "guizhou_communication_vocational_college": "Guizhou Communication Vocational College"
        },
        {
            "bowen_college_of_management_guilin_university_of_technology": "Bowen College of Management Guilin University of Technology"
        },
        {
            "chengdu_normal_university": "Chengdu Normal University"
        },
        {
            "jiaozuo_teachers_college": "Jiaozuo Teachers College"
        },
        {
            "zhengzhou_shuqing_medical_college": "Zhengzhou Shuqing Medical College"
        },
        {
            "tianjin_arts_and_crafts_professional_college": "Tianjin Arts and Crafts Professional College"
        },
        {
            "jiangxi_new_energy_technology_vocational_college": "Jiangxi New Energy Technology Vocational College"
        },
        {
            "chengdu_polytechnic": "Chengdu Polytechnic"
        },
        {
            "anhui_international_economy_college": "Anhui International Economy College"
        },
        {
            "hebei_women's_vocational_college": "Hebei Women's Vocational College"
        },
        {
            "communication_university_of_zhejiang": "Communication university of zhejiang"
        },
        {
            "jilin_police_college": "Jilin Police College"
        },
        {
            "fuzhou_software_technology_vocational_college": "Fuzhou Software Technology Vocational College"
        },
        {
            "guangdong_engineering_polytechnic": "Guangdong Engineering Polytechnic"
        },
        {
            "guangzhou_zhujiang_college_of_vocational_technology": "Guangzhou Zhujiang College of Vocational Technology"
        },
        {
            "minbei_vocational_&_technical_college": "Minbei Vocational & Technical College"
        },
        {
            "zhengzhou_electric_power_vocational_and_technical_college": "Zhengzhou Electric Power Vocational and Technical College"
        },
        {
            "jilin_animation_institute": "Jilin Animation Institute"
        },
        {
            "hei_he_university": "Hei He University"
        },
        {
            "hunan_judicial_police_vocational_college": "Hunan Judicial Police Vocational College"
        },
        {
            "art_college_inner_mongolia_university": "Art College Inner Mongolia University"
        },
        {
            "wenzhou_university_oujiang_college": "Wenzhou University Oujiang College"
        },
        {
            "gansu_medical_college": "Gansu Medical College"
        },
        {
            "jinliln_communications_polytechnic": "Jinliln Communications Polytechnic"
        },
        {
            "zhuhai_city_polytechnic": "Zhuhai City Polytechnic"
        },
        {
            "shanghai_vocational_college_of_science_&_technology": "Shanghai Vocational College of Science & Technology"
        },
        {
            "tongling_polytechnic": "Tongling Polytechnic"
        },
        {
            "silicon_lake_college": "Silicon Lake College"
        },
        {
            "shandong_vocational_animal_science_and_veterinary_college": "Shandong Vocational Animal Science and Veterinary College"
        },
        {
            "songda_college_guangzhou_university": "Songda College Guangzhou University"
        },
        {
            "guangdong_construction_polytechnic": "Guangdong Construction Polytechnic"
        },
        {
            "hainan_college_of_economics_and_business": "Hainan College of Economics and Business"
        },
        {
            "changde_vocational_technical_college": "Changde Vocational Technical College"
        },
        {
            "shandong_chemical_vocational_college": "Shandong Chemical Vocational College"
        },
        {
            "yantai_vocational_college_(education_institute_&_television_university)": "Yantai Vocational College (Education Institute & Television University)"
        },
        {
            "yiyang_vocational_&_technical_college": "Yiyang Vocational & Technical College"
        },
        {
            "guangdong_lingnan_institute_of_technology": "Guangdong Lingnan Institute of Technology"
        },
        {
            "shanghai_donghai_vocational_&_technical_college_(east-sea_university)": "Shanghai Donghai Vocational & Technical College (East-Sea University)"
        },
        {
            "anhui_vocational_college_of_metallurgy_and_technology": "Anhui Vocational College of Metallurgy and Technology"
        },
        {
            "hunan_radio_&_tv_university": "Hunan Radio & TV University"
        },
        {
            "shaanxi_electronic_information_institute": "Shaanxi Electronic Information Institute"
        },
        {
            "shanxi_vocational_college_of_information_technology": "Shanxi Vocational College of Information Technology"
        },
        {
            "wuhan_college": "Wuhan College"
        },
        {
            "xi'an_railway_vocational_&_technical_institute": "Xi'an Railway Vocational & Technical Institute"
        },
        {
            "liaoning_advertising_vocational_college": "Liaoning Advertising Vocational College"
        },
        {
            "xi'an_aeronautical_polytechnic_institute": "Xi'an Aeronautical Polytechnic Institute"
        },
        {
            "jiyuan_vocational_&_technical_college": "Jiyuan Vocational & Technical College"
        },
        {
            "longnan_teachers_college": "Longnan Teachers College"
        },
        {
            "liupanshui_normal_university": "Liupanshui Normal University"
        },
        {
            "xuzhou_kindergarten_teachers_college": "Xuzhou Kindergarten Teachers College"
        },
        {
            "heilongjiang_forestry_vocation-_technical_college": "Heilongjiang Forestry Vocation- Technical College"
        },
        {
            "zhangzhou_city_college": "Zhangzhou City College"
        },
        {
            "sanjiang_university": "Sanjiang University"
        },
        {
            "shijiazhuang_medical_college": "Shijiazhuang Medical College"
        },
        {
            "guangzhou_college_of_commerce": "Guangzhou College of Commerce"
        },
        {
            "hunan_university_of_finance_and_economics": "Hunan University of Finance and Economics"
        },
        {
            "sichuan_radio_and_tv_university": "Sichuan Radio and TV University"
        },
        {
            "jiangxi_youth_vocational_college": "Jiangxi Youth Vocational College"
        },
        {
            "hunan_water_resources_and_hydropower_vocational_and_technical_college": "Hunan Water Resources and Hydropower Vocational and Technical College"
        },
        {
            "pu'er_university": "Pu'er University"
        },
        {
            "shanxi_forestry_vocational_and_technical_college": "Shanxi Forestry Vocational and Technical College"
        },
        {
            "tianfu_college_southwestern_university_of_finance_&_economics": "Tianfu College Southwestern University of Finance & Economics"
        },
        {
            "shanghai_customs_college": "Shanghai Customs College"
        },
        {
            "anhui_sanlian_university": "Anhui Sanlian University"
        },
        {
            "wuhan_business_university": "Wuhan Business University"
        },
        {
            "wuchang_shouyi_university": "Wuchang Shouyi University"
        },
        {
            "changchun_finance_college": "Changchun Finance College"
        },
        {
            "shanghai_police_college": "Shanghai Police College"
        },
        {
            "shanghai_institute_of_tourism": "Shanghai Institute of Tourism"
        },
        {
            "yunnan_xinxing_occupations_institute": "Yunnan Xinxing Occupations Institute"
        },
        {
            "shaanxi_technical_college_of_finance_and_economics": "Shaanxi Technical College of Finance and Economics"
        },
        {
            "qingdao_feiyang_vocational_and_technical_college": "Qingdao Feiyang Vocational and Technical College"
        },
        {
            "taiyuan_tourism_college": "Taiyuan Tourism College"
        },
        {
            "wuxi_vocational_institute_of_commerce": "Wuxi Vocational Institute of Commerce"
        },
        {
            "sichuan_vocational_college_of_chemical_technology": "Sichuan Vocational College of Chemical Technology"
        },
        {
            "sanya_polytechnic_vocational_college": "Sanya Polytechnic Vocational College"
        },
        {
            "chongqing_three_gorges_vocational_college": "Chongqing Three Gorges Vocational College"
        },
        {
            "tianjin_transportation_vocational_college": "Tianjin Transportation Vocational College"
        },
        {
            "shandong_college_of_electronic_technology": "Shandong College of Electronic Technology"
        },
        {
            "shaanxi_business_college": "Shaanxi Business College"
        },
        {
            "shanwei_polytechnic": "Shanwei Polytechnic"
        },
        {
            "shanxi_pharmaceutical_college": "ShanXi Pharmaceutical College"
        },
        {
            "tianjin_bohai_vocational_technical_college": "Tianjin Bohai Vocational Technical College"
        },
        {
            "weifang_engineering_vocational_college": "Weifang Engineering Vocational College"
        },
        {
            "jiangxi_industrial_engineering_vocational_and_technical_college": "Jiangxi Industrial Engineering Vocational and Technical College"
        },
        {
            "shanxi_engineering_vocational_college": "Shanxi Engineering Vocational College"
        },
        {
            "liaoning_geology_engineering_vocational_college": "Liaoning Geology Engineering Vocational College"
        },
        {
            "zhumadian_vocational_&_technical_college": "Zhumadian Vocational & Technical College"
        },
        {
            "changchun_sci-tech_university": "Changchun Sci-Tech University"
        },
        {
            "xiamen_huatian_institute": "Xiamen Huatian Institute"
        },
        {
            "southwest_jiaotong_university_hope_college": "Southwest Jiaotong University Hope College"
        },
        {
            "chinese_buddhist_academy": "Chinese Buddhist Academy"
        },
        {
            "china_university_of_labor_relations": "China University of Labor Relations"
        },
        {
            "chizhou_vocational_&_technical_college": "Chizhou Vocational & Technical College"
        },
        {
            "mianyang_polytechnic": "Mianyang Polytechnic"
        },
        {
            "qingdao_qiushi_college": "Qingdao Qiushi College"
        },
        {
            "school_of_applied_science_jiangxi_university_of_science_and_technology": "School of Applied Science Jiangxi University of Science and Technology"
        },
        {
            "shenyang_city_college": "Shenyang City College"
        },
        {
            "beijing_polytechnic": "Beijing Polytechnic"
        },
        {
            "guangzhou_civil_aviation_college": "Guangzhou Civil Aviation College"
        },
        {
            "guizhou_aerospace_vocational_and_technical_college": "Guizhou Aerospace Vocational and Technical College"
        },
        {
            "hubei_industrial_polytechnic": "Hubei Industrial Polytechnic"
        },
        {
            "hunan_electric_vocational_and_technical_college": "Hunan Electric Vocational and Technical College"
        },
        {
            "fujian_health_college": "Fujian Health College"
        },
        {
            "jinken_college_of_technology": "Jinken College of Technology"
        },
        {
            "chongqing_college_of_humanities,_science_&_technology": "Chongqing College of Humanities, Science & Technology"
        },
        {
            "anhui_vocational_college_of_sports": "Anhui Vocational College of Sports"
        },
        {
            "songshan_shaolin_wushu_college": "Songshan Shaolin Wushu College"
        },
        {
            "tongren_polytechnic_college": "Tongren Polytechnic College"
        },
        {
            "hebei_construction_material_vocational_and_technical_college": "Hebei Construction Material Vocational and Technical College"
        },
        {
            "panjin_vocational_&_technical_college": "Panjin Vocational & Technical College"
        },
        {
            "pioneer_college_inner_mongolia_university": "Pioneer College Inner Mongolia University"
        },
        {
            "chengdu_neusoft_university": "Chengdu Neusoft University"
        },
        {
            "sichuan_preschool_educators_college": "Sichuan Preschool Educators College"
        },
        {
            "suzhou_institute_of_industrial_technology": "Suzhou Institute of Industrial Technology"
        },
        {
            "yangzhou_polytechnic_institute": "Yangzhou Polytechnic Institute"
        },
        {
            "college_technology_and_engineering_lanzhou_university_of_technology": "College Technology and Engineering Lanzhou University of Technology"
        },
        {
            "weifang_business_vocational_college": "Weifang Business Vocational College"
        },
        {
            "xi'an_vocational_&_technical_college": "Xi'an Vocational & Technical College"
        },
        {
            "quanzhou_college_of_technology": "Quanzhou College of Technology"
        },
        {
            "sichuan_vocational_&_technical_college": "Sichuan Vocational & Technical College"
        },
        {
            "shandong_vocational_college_of_science_&_technology": "Shandong Vocational College of Science & Technology"
        },
        {
            "harbin_institute_of_information_technology": "Harbin Institute of Information Technology"
        },
        {
            "yunnan_physical_science_and_sports_professional_college": "Yunnan Physical Science and Sports Professional College"
        },
        {
            "fushun_vocational_technical_institute": "Fushun Vocational Technical Institute"
        },
        {
            "guangzhou_institute_of_science_and_technology": "Guangzhou Institute of Science and Technology"
        },
        {
            "guiyang_nursing_vocational_college": "Guiyang Nursing Vocational College"
        },
        {
            "liaoning_petrochemical_vocational_of_technology": "Liaoning Petrochemical Vocational of Technology"
        },
        {
            "suizhou_vocational_&_technical_college": "Suizhou Vocational & Technical College"
        },
        {
            "xiamen_city_university": "Xiamen City University"
        },
        {
            "anhui_lvhai_vocational_college_of_business": "Anhui Lvhai Vocational College of Business"
        },
        {
            "ningbo_institute_of_technology_zhejiang_university": "Ningbo Institute of Technology Zhejiang University"
        },
        {
            "shanxi_vocational_&_technical_college_of_finance_&_trade": "Shanxi Vocational & Technical College of Finance & Trade"
        },
        {
            "changji_university": "Changji University"
        },
        {
            "wenshan_university": "Wenshan University"
        },
        {
            "xizang_minzu_university": "Xizang Minzu University"
        },
        {
            "weifang_nursing_vocational_college": "Weifang Nursing Vocational College"
        },
        {
            "xuzhou_vocational_college_of_industrial_technology": "Xuzhou Vocational College of Industrial Technology"
        },
        {
            "yan'an_vocational_&_technical_college": "Yan'an Vocational & Technical College"
        },
        {
            "nanchang_institute_of_science_&_technology": "Nanchang Institute of Science & Technology"
        },
        {
            "chongqing_business_vocational_college": "Chongqing Business Vocational College"
        },
        {
            "hunan_sanyi_industrial_vocational_and_technical_college": "Hunan Sanyi Industrial Vocational and Technical College"
        },
        {
            "xiamen_national_accounting_institute": "Xiamen National Accounting Institute"
        },
        {
            "chuxiong_medical_and_pharmaceutical_college": "Chuxiong Medical and Pharmaceutical College"
        },
        {
            "chuzhou_city_vocation_college": "Chuzhou City Vocation College"
        },
        {
            "liaoning_finance_and_trade_college": "Liaoning Finance and Trade College"
        },
        {
            "tianjin_vocational_college_of_bioengineering": "Tianjin Vocational College of BioEngineering"
        },
        {
            "guangdong_nanfang_vocational_college": "Guangdong Nanfang Vocational College"
        },
        {
            "guizhou_forerunner_college": "Guizhou Forerunner College"
        },
        {
            "guangzhou_international_economics_college": "Guangzhou International Economics College"
        },
        {
            "yancheng_vocational_institute_of_health_sciences": "Yancheng Vocational Institute of Health Sciences"
        },
        {
            "jizhong_vocational_college": "Jizhong Vocational College"
        },
        {
            "ningxia_construction_vocational_&_technical_college": "Ningxia Construction Vocational & Technical College"
        },
        {
            "yanbian_university_of_science_&_technology": "Yanbian University of Science & Technology"
        },
        {
            "jilin_radio_and_tv_university": "Jilin Radio and TV University"
        },
        {
            "qingdao_harbor_vocational_&_technical_college": "Qingdao Harbor Vocational & Technical College"
        },
        {
            "shandong_drug_and_food_vocational_college": "Shandong Drug and Food Vocational College"
        },
        {
            "jingdezhen_ceramics_vocational_and_technical_college": "Jingdezhen Ceramics Vocational and Technical College"
        },
        {
            "qiqihar_teachers_college": "Qiqihar Teachers College"
        },
        {
            "henan_medical_college": "Henan Medical College"
        },
        {
            "puyang_vocational_&_technical_college": "Puyang Vocational & Technical College"
        },
        {
            "city_university_of_zhengzhou": "City University of Zhengzhou"
        },
        {
            "college_of_technology_and_art_jingdezhen_ceramic_institute": "College of Technology and Art Jingdezhen Ceramic Institute"
        },
        {
            "tourism_college_of_zhejiang": "Tourism College of Zhejiang"
        },
        {
            "guangdong_innovative_technical_college": "Guangdong Innovative Technical College"
        },
        {
            "tianjin_youth_professional_college": "Tianjin Youth Professional College"
        },
        {
            "xi'an_high_tech_university": "Xi'an High Tech University"
        },
        {
            "hebei_energy_college_of_vocation_&_technology": "Hebei Energy College of Vocation & Technology"
        },
        {
            "guangxi_eco-engineering_vocational_and_technical_college": "Guangxi Eco-engineering Vocational and Technical College"
        },
        {
            "baicheng_vocational_&_technical_college": "Baicheng Vocational & Technical College"
        },
        {
            "sichuan_huaxin_modern_vocational_college": "Sichuan Huaxin Modern Vocational College"
        },
        {
            "zhengzhou_college_of_technology": "Zhengzhou College of Technology"
        },
        {
            "shandong_liming_technology_vocational_college": "Shandong Liming Technology Vocational College"
        },
        {
            "tianjin_metallurgical_vocation_technology_institute": "Tianjin Metallurgical Vocation Technology Institute"
        },
        {
            "guangxi_college_of_water_resources_and_electric_power": "Guangxi College of Water Resources and Electric Power"
        },
        {
            "wuhan_technology_and_business_university": "Wuhan Technology and Business University"
        },
        {
            "xuzhou_bioengineering_vocational_and_technical_college": "Xuzhou Bioengineering Vocational and Technical College"
        },
        {
            "shaanxi_aerospace_staffs_&_vocation_university": "Shaanxi Aerospace Staffs & Vocation University"
        },
        {
            "shanxi_old_district_vocational_and_technical_college": "Shanxi Old District Vocational and Technical College"
        },
        {
            "jiangsu_radio_&_tv_university": "Jiangsu Radio & TV University"
        },
        {
            "jiaxing_vocational_&_technical_college": "Jiaxing Vocational & Technical College"
        },
        {
            "hunan_university_of_medicine": "Hunan University of Medicine"
        },
        {
            "heze_domestic_professional_college": "Heze Domestic Professional College"
        },
        {
            "jiaozuo_college_of_industry_and_trade": "Jiaozuo College of Industry and Trade"
        },
        {
            "zhengzhou_preschool_education_college": "Zhengzhou Preschool Education College"
        },
        {
            "university_for_science_&_technology_sichuan": "University for Science & Technology Sichuan"
        },
        {
            "hainan_technology_and_business_college": "Hainan Technology and Business College"
        },
        {
            "wuhan_institute_of_engineering_science_and_technology": "Wuhan Institute of Engineering Science and Technology"
        },
        {
            "qingyuan_polytechnic": "Qingyuan Polytechnic"
        },
        {
            "fuzhou_liming_vocational_&_technical_college": "Fuzhou Liming Vocational & Technical College"
        },
        {
            "jilin_agricultural_science_and_technology_university": "Jilin Agricultural Science and Technology University"
        },
        {
            "liaoning_polytechnic_college": "Liaoning Polytechnic College"
        },
        {
            "chongqing_public_transportation_vocational_college": "Chongqing Public Transportation Vocational College"
        },
        {
            "hebei_software_institute": "Hebei Software Institute"
        },
        {
            "heilongjiang_university_of_finance_and_economics": "Heilongjiang University of Finance and Economics"
        },
        {
            "shanxi_tongwen_vocational_and_technical_college": "Shanxi Tongwen Vocational and Technical College"
        },
        {
            "shanxi_management_vocational_college": "Shanxi Management Vocational College"
        },
        {
            "northern_beijing_vocational_education_institute": "Northern Beijing Vocational Education Institute"
        },
        {
            "gansu_construction_vocational_technical_college": "Gansu Construction Vocational Technical College"
        },
        {
            "jiangsu_open_university": "Jiangsu Open University"
        },
        {
            "shijiazhuang_vocational_college_of_science_&_technology": "Shijiazhuang Vocational College of Science & Technology"
        },
        {
            "shanxi_academy_of_arts": "Shanxi Academy of Arts"
        },
        {
            "guizhou_police_officer_vocational_college": "Guizhou Police Officer Vocational College"
        },
        {
            "changzhi_vocational_&_technical_college": "Changzhi Vocational & Technical College"
        },
        {
            "qilu_medical_university": "Qilu Medical University"
        },
        {
            "hainan_institute_of_science_and_technology": "Hainan Institute of Science and Technology"
        },
        {
            "shandong_communication_&_media_college": "Shandong Communication & Media College"
        },
        {
            "guangxi_city_college": "Guangxi City College"
        },
        {
            "anhui_vocational_&_technical_college_of_forestry": "Anhui Vocational & Technical College of Forestry"
        },
        {
            "anhui_institute_of_information_engineering": "Anhui Institute of Information Engineering"
        },
        {
            "zhuhai_college_beijing_institute_of_technology": "Zhuhai College Beijing Institute of Technology"
        },
        {
            "liaoyuan_vocational_technical_college": "Liaoyuan Vocational Technical College"
        },
        {
            "xiamen_academy_for_performing_arts": "Xiamen Academy for Performing Arts"
        },
        {
            "yunnan_land_and_resources_vocational_college": "Yunnan Land and Resources Vocational College"
        },
        {
            "jiangxi_agricultural_engineering_college": "Jiangxi Agricultural Engineering College"
        },
        {
            "anhui_post_and_telecommunication_college": "Anhui Post and Telecommunication College"
        },
        {
            "jiangsu_second_teachers_college": "Jiangsu Second Teachers College"
        },
        {
            "bozhou_university": "Bozhou University"
        },
        {
            "sichuan_engineering_technical_college": "Sichuan Engineering Technical College"
        },
        {
            "jiangsu_college_of_engineering_and_technology": "Jiangsu College of Engineering and Technology"
        },
        {
            "langfang_yanjing_polytechnic_college": "Langfang Yanjing polytechnic College"
        },
        {
            "zhengzhou_institute_of_technology": "Zhengzhou Institute of Technology"
        },
        {
            "sichuan_institute_of_industrial_technology": "Sichuan Institute of Industrial Technology"
        },
        {
            "xiamen_institute_of_software_technology": "Xiamen Institute of Software Technology"
        },
        {
            "gansu_radio_&_tv_university": "Gansu Radio & TV University"
        },
        {
            "hunan_software_vocational_institute": "Hunan Software Vocational Institute"
        },
        {
            "neusoft_institute_guangdong": "Neusoft Institute Guangdong"
        },
        {
            "jac_university": "JAC university"
        },
        {
            "guangdong_institute_of_arts_and_sciences": "Guangdong institute of Arts and Sciences"
        },
        {
            "hanjiang_normal_university": "Hanjiang Normal University"
        },
        {
            "jiangxi_water_resources_institute": "Jiangxi Water Resources Institute"
        },
        {
            "taiyuan_city_vocational_and_technical_college": "Taiyuan City Vocational and Technical College"
        },
        {
            "qujing_medical_college": "Qujing Medical College"
        },
        {
            "zhejiang_post_and_telecommunication_college": "Zhejiang Post and Telecommunication College"
        },
        {
            "shaoyang_polytechnic": "Shaoyang Polytechnic"
        },
        {
            "guizhou_vocational_technology_institute": "Guizhou Vocational Technology Institute"
        },
        {
            "college_of_technology_hubei_engineering_university": "College of Technology Hubei Engineering University"
        },
        {
            "beijing_huijia_vocational_college": "Beijing Huijia Vocational College"
        },
        {
            "nanjing_university_of_chinese_medicine_hanlin_college": "Nanjing University of Chinese Medicine Hanlin College"
        },
        {
            "qinghai_vocational_and_technical_college_of_animal_husbandry_and_veterinary_medicine": "Qinghai Vocational and Technical College of Animal Husbandry and Veterinary Medicine"
        },
        {
            "songyuan_vocational_&_technical_college": "Songyuan Vocational & Technical College"
        },
        {
            "meizhouwan_vocational_technology_college": "Meizhouwan Vocational Technology College"
        },
        {
            "hebei_vocational_college_of_real_transportation": "Hebei Vocational College of Real Transportation"
        },
        {
            "hunan_nonferrous_metals_vocational_&_technical_college": "Hunan Nonferrous Metals Vocational & Technical College"
        },
        {
            "yantai_engineering_&_technology_college": "Yantai Engineering & Technology College"
        },
        {
            "jiangnan_film_and_television_art_college": "Jiangnan Film and Television Art College"
        },
        {
            "ningxia_vocational_technical_college_of_industry_and_commerce": "Ningxia Vocational Technical College of Industry and Commerce"
        },
        {
            "fuxin_higher_training_college": "Fuxin Higher Training College"
        },
        {
            "botou_vocational_college": "Botou Vocational College"
        },
        {
            "chuzhou_vocational_&_technical_college": "Chuzhou Vocational & Technical College"
        },
        {
            "huali_college_guangdong_university_of_technology": "Huali College Guangdong University of Technology"
        },
        {
            "guangxi_talent_international_college": "Guangxi Talent International College"
        },
        {
            "tianjin_city_vocational_college": "Tianjin City Vocational College"
        },
        {
            "china_university_of_petroleum_victory_college": "China University of Petroleum Victory College"
        },
        {
            "hebei_vocational_college_of_geology": "Hebei Vocational College of Geology"
        },
        {
            "jilin_province_economic_management_cadre_college": "Jilin Province Economic Management Cadre College"
        },
        {
            "shandong_agriculture_and_engineering_university": "Shandong Agriculture and Engineering University"
        },
        {
            "hefei_bibhu_vocational_&_technical_college": "Hefei Bibhu Vocational & Technical College"
        },
        {
            "jiangxi_vocational_college_of_mechanical_&_electrical_technology": "Jiangxi Vocational College of Mechanical & Electrical Technology"
        },
        {
            "yunnan_jiatong_college": "Yunnan Jiatong College"
        },
        {
            "wuhai_vocational_and_technical_college": "Wuhai Vocational and Technical College"
        },
        {
            "wuxi_institute_of_arts_&_technology": "Wuxi Institute of Arts & Technology"
        },
        {
            "anqing_medical_college": "Anqing Medical College"
        },
        {
            "guangzhou_huali_science_&_technology_vocational_college": "Guangzhou Huali Science & Technology Vocational College"
        },
        {
            "inner_mongolia_business_vocational_college": "Inner Mongolia Business Vocational College"
        },
        {
            "shaanxi_xueqian_normal_university": "Shaanxi Xueqian Normal University"
        },
        {
            "guangzhou_huaxia_technical_college": "Guangzhou Huaxia Technical College"
        },
        {
            "huanggang_vocational_college_of_science_and_technology": "Huanggang Vocational College of Science and Technology"
        },
        {
            "harbin_applied_vocational_&_technical_college": "Harbin Applied Vocational & Technical College"
        },
        {
            "qiqihar_institute_of_engineering": "Qiqihar Institute of Engineering"
        },
        {
            "university_of_chinese_academy_of_social_sciences": "University of Chinese Academy of Social Sciences"
        },
        {
            "sichuan_sanhe_college_of_professionals": "Sichuan Sanhe College of Professionals"
        },
        {
            "guizhou_university_of_engineering_science": "Guizhou University of Engineering Science"
        },
        {
            "quanzhou_ocean_institute": "Quanzhou Ocean Institute"
        },
        {
            "meishan_vocational_&_technical_college": "Meishan Vocational & Technical College"
        },
        {
            "wuhan_qingchuan_college": "Wuhan Qingchuan College"
        },
        {
            "shandong_urban_construction_vocational_college": "Shandong Urban Construction Vocational College"
        },
        {
            "suzhou_industrial_park_institute_of_services_outsourcing": "Suzhou Industrial Park Institute of Services Outsourcing"
        },
        {
            "shanxi_vocational_poly-tech_college": "Shanxi Vocational Poly-Tech College"
        },
        {
            "hubei_radio_and_tv_university": "Hubei Radio and TV University"
        },
        {
            "beijing_open_university": "Beijing Open University"
        },
        {
            "anhui_university_jianghuai_college": "Anhui University Jianghuai College"
        },
        {
            "lanzhou_college_of_foreign_studies": "Lanzhou College of Foreign Studies"
        },
        {
            "nanjing_vocational_institute_of_transport_technology": "Nanjing Vocational Institute of Transport Technology"
        },
        {
            "guangdong_polytechnic_of_environmental_protection_engineering": "Guangdong Polytechnic of Environmental Protection Engineering"
        },
        {
            "gansu_administration_institute": "Gansu Administration Institute"
        },
        {
            "paez_college_of_chongqing_technology_and_business_university": "Paez College of Chongqing Technology and Business University"
        },
        {
            "wuchang_polytechnic_college": "Wuchang Polytechnic College"
        },
        {
            "guangzhou_sontan_polytechnic_college": "Guangzhou Sontan Polytechnic College"
        },
        {
            "zhengzhou_industrial_safety_vocational_college": "Zhengzhou Industrial Safety Vocational College"
        },
        {
            "school_of_science_and_technology_ningbo_university": "School of Science and Technology Ningbo University"
        },
        {
            "nantong_normal_college": "Nantong Normal College"
        },
        {
            "yunnan_vocational_college_of_culture_and_art": "Yunnan Vocational College of Culture and Art"
        },
        {
            "lhasa_teachers_college": "Lhasa Teachers College"
        },
        {
            "anhui_public_security_college": "Anhui Public Security College"
        },
        {
            "ezhou_polytechnic": "Ezhou Polytechnic"
        },
        {
            "mianyang_arts_college_of_sichuan_conservatory_of_music": "Mianyang Arts College of Sichuan Conservatory of Music"
        },
        {
            "heilongjiang_sanjiang_arts_vocational_college": "Heilongjiang Sanjiang Arts Vocational College"
        },
        {
            "ningxia_television_university": "Ningxia Television University"
        },
        {
            "dazhou_vocational_and_technical_college": "Dazhou Vocational and Technical College"
        },
        {
            "anhui_water_conservancy_technical_college": "Anhui Water Conservancy Technical College"
        },
        {
            "shijiazhuang_preschool_teachers_college": "Shijiazhuang Preschool Teachers College"
        },
        {
            "guangxi_engineering_vocational_college": "Guangxi Engineering Vocational College"
        },
        {
            "wuyishan_vocational_college": "Wuyishan Vocational College"
        },
        {
            "hainan_college_of_software_technology": "Hainan College of Software Technology"
        },
        {
            "yiwu_industrial_and_commercial_college": "Yiwu Industrial and Commercial College"
        },
        {
            "wuxi_taihu_university": "Wuxi Taihu University"
        },
        {
            "hefei_preschool_education_college": "Hefei Preschool Education College"
        },
        {
            "xingzhi_college_xi'an_university_of_finance_and_economics": "Xingzhi College Xi'an University of Finance and Economics"
        },
        {
            "yancheng_polytechnic_college": "Yancheng Polytechnic College"
        },
        {
            "henan_vocational_college_of_agriculture": "Henan Vocational College of Agriculture"
        },
        {
            "harbin_finance_university": "Harbin Finance University"
        },
        {
            "changbaishan_vocational_&_technical_college": "Changbaishan Vocational & Technical College"
        },
        {
            "shanghai_open_university": "Shanghai Open University"
        },
        {
            "guizhou_university_of_commerce": "Guizhou University of Commerce"
        },
        {
            "hangzhou_medical_college": "Hangzhou Medical College"
        },
        {
            "huangshan_vocational_&_technical_college": "Huangshan Vocational & Technical College"
        },
        {
            "xi'an_university_of_technology_high_school": "Xi'an University of Technology High School"
        },
        {
            "zhuhai_art_vocational_college": "Zhuhai Art Vocational College"
        },
        {
            "nanjing_institute_of_tourism_&_hospitality": "Nanjing Institute of Tourism & Hospitality"
        },
        {
            "yunnan_forestry_technological_college": "Yunnan Forestry Technological College"
        },
        {
            "hubei_minzu_university": "Hubei Minzu University"
        },
        {
            "shenyang_urban_construction_university": "Shenyang Urban Construction University"
        },
        {
            "tongliao_vocational_college": "Tongliao Vocational College"
        },
        {
            "ankang_vocational_and_technical_college": "Ankang Vocational and Technical College"
        },
        {
            "college_of_arts_&_science_jianghan_university": "College of Arts & Science Jianghan University"
        },
        {
            "changchun_guanghua_college": "Changchun Guanghua College"
        },
        {
            "hubei_youth_vocational_college": "Hubei Youth Vocational College"
        },
        {
            "polus_international_college_(sichuan_pivotpoint_college)": "POLUS International College (Sichuan Pivotpoint College)"
        },
        {
            "taihu_college_of_creative_technologies": "Taihu College of Creative Technologies"
        },
        {
            "xinyang_agriculture_and_forestry_university": "Xinyang Agriculture and Forestry University"
        },
        {
            "zhongshan_torch_polytechnic": "Zhongshan Torch Polytechnic"
        },
        {
            "xingyi_normal_university_for_nationalities": "Xingyi Normal University for Nationalities"
        },
        {
            "liupanshui_vocational_&_technical_institute": "Liupanshui Vocational & Technical Institute"
        },
        {
            "chongqing_industry_polytechnic_college": "Chongqing Industry Polytechnic College"
        },
        {
            "jiangsu_urban_and_rural_construction_vocational_college": "Jiangsu Urban and Rural Construction Vocational College"
        },
        {
            "application_technology_college_hunan_institute_of_engineering": "Application Technology College Hunan Institute of Engineering"
        },
        {
            "bengbu_institute_of_business_and_technology": "Bengbu Institute of Business and Technology"
        },
        {
            "anyang_university_(anyang_college)": "Anyang University (Anyang College)"
        },
        {
            "zhengzhou_vocational_college_of_finance_and_taxation": "Zhengzhou Vocational College of Finance and Taxation"
        },
        {
            "xinjiang_institute_of_engineering": "Xinjiang Institute of Engineering"
        },
        {
            "fuzhou_institute_of_technology": "Fuzhou Institute of Technology"
        },
        {
            "changchun_university_tourism_college": "Changchun University Tourism College"
        },
        {
            "changchun_dongfang_professional_college": "Changchun Dongfang Professional College"
        },
        {
            "jiangxi_aviation_vocational_&_technical_college": "Jiangxi Aviation Vocational & Technical College"
        },
        {
            "school_of_art_and_information_engineering_dalian_polytechnic_university": "School of Art and Information Engineering Dalian Polytechnic University"
        },
        {
            "guangdong_justice_police_vocational_college": "Guangdong Justice Police Vocational College"
        },
        {
            "xinxiang_vocational_and_technical_college": "Xinxiang Vocational and Technical College"
        },
        {
            "mudanjiang_university": "Mudanjiang University"
        },
        {
            "zhengzhou_institute_of_finance_and_economics": "Zhengzhou Institute of Finance and Economics"
        },
        {
            "xuchang_electrical_vocational_college": "Xuchang Electrical Vocational College"
        },
        {
            "chongqing_tourism_vocational_college": "Chongqing Tourism Vocational College"
        },
        {
            "shanxi_university_of_finance_and_economics_china_business_school": "Shanxi University of Finance and Economics China Business School"
        },
        {
            "shandong_water_conservancy_vocational_college": "Shandong Water Conservancy Vocational College"
        },
        {
            "guangxi_open_university": "Guangxi Open University"
        },
        {
            "hebei_jiaotong_vocational_&_technical_college": "Hebei Jiaotong Vocational & Technical College"
        },
        {
            "sanya_aviation_&_tourism_college": "Sanya Aviation & Tourism College"
        },
        {
            "leshan_vocational_&_technical_college": "Leshan Vocational & Technical College"
        },
        {
            "guangxi_polytechnic_of_modern": "Guangxi Polytechnic of Modern"
        },
        {
            "zhengzhou_institute_of_business_and_technology": "Zhengzhou Institute of Business and Technology"
        },
        {
            "shanghai_civil_aviation_college": "Shanghai Civil Aviation College"
        },
        {
            "changchun_university_of_science_and_technology_school_of_optoelectronic_information": "Changchun University of Science and Technology School of Optoelectronic Information"
        },
        {
            "jilin_justice_officer_academy": "Jilin Justice Officer Academy"
        },
        {
            "college_of_arts_and_sciences_yunnan_normal_university": "College of Arts and Sciences Yunnan Normal University"
        },
        {
            "shandong_foreign_trade_vocational_college": "Shandong Foreign Trade Vocational College"
        },
        {
            "inner_mongolia_vocational_institute_of_economy_trade_&_foreign_languages": "Inner Mongolia Vocational Institute of Economy Trade & Foreign Languages"
        },
        {
            "fuzhou_institute_of_business_and_technology": "Fuzhou Institute of Business and Technology"
        },
        {
            "henan_technical_college_of_construction": "Henan Technical College of Construction"
        },
        {
            "inner_mongolia_medical_university": "Inner Mongolia Medical University"
        },
        {
            "yunnan_vocational_college_of_judicial_police": "Yunnan Vocational College of Judicial Police"
        },
        {
            "nanyang_medical_college": "Nanyang Medical College"
        },
        {
            "nanjing_normal_university_of_special_education": "Nanjing Normal University of Special Education"
        },
        {
            "yingkou_institute_of_technology": "Yingkou Institute of Technology"
        },
        {
            "shenyang_institute_of_technology": "Shenyang Institute of Technology"
        },
        {
            "huanggang_polytechnic_college": "Huanggang Polytechnic College"
        },
        {
            "henan_vocational_college_of_nursing": "Henan Vocational College of Nursing"
        },
        {
            "jilin_vocational_and_technical_college_of_science_and_technology": "Jilin Vocational and Technical College of Science and Technology"
        },
        {
            "kunming_health_vocational_college": "Kunming Health Vocational College"
        },
        {
            "yangquan_teachers_college": "Yangquan Teachers College"
        },
        {
            "sichuan_electronic_machinery_vocational_&_technical_college": "Sichuan Electronic Machinery Vocational & Technical College"
        },
        {
            "anhui_electrical_engineering_vocational_and_technical_college": "Anhui Electrical Engineering Vocational and Technical College"
        },
        {
            "bao_tou_iron_&_steeel_vocational_technical_college": "Bao Tou Iron & Steeel Vocational Technical College"
        },
        {
            "private_wanbo_technology_vocational_college": "Private Wanbo Technology Vocational College"
        },
        {
            "nanjing_city_vocational_college": "Nanjing City Vocational College"
        },
        {
            "jiangsu_food_&_pharmaceutical_science_college": "Jiangsu Food & Pharmaceutical Science College"
        },
        {
            "shanxi_sports_vocational_college": "Shanxi Sports Vocational College"
        },
        {
            "hebei_youth_cadres_administrative_college": "Hebei Youth Cadres Administrative College"
        },
        {
            "guangxi_vocational_college_of_performing_arts": "Guangxi Vocational College of Performing Arts"
        },
        {
            "hunan_applied_technology_university": "Hunan Applied Technology University"
        },
        {
            "beijing_police_college": "Beijing Police College"
        },
        {
            "bozhou_vocational_and_technical_college": "Bozhou Vocational and Technical College"
        },
        {
            "liaoning_vocational_college_light_industry": "Liaoning Vocational College Light Industry"
        },
        {
            "college_of_humanities_science_guizhou_university_for_nationalities": "College of Humanities Science Guizhou University for Nationalities"
        },
        {
            "shaanxi_artistic_vocational_college": "Shaanxi Artistic Vocational College"
        },
        {
            "hunan_biological_and_electromechanical_polytechnic": "Hunan Biological and Electromechanical Polytechnic"
        },
        {
            "shijiazhuang_engineering_vocational_college": "Shijiazhuang Engineering Vocational College"
        },
        {
            "shandong_maritime_vocational_college": "Shandong Maritime Vocational College"
        },
        {
            "sichuan_post_and_telecommunications_college": "Sichuan Post and Telecommunications College"
        },
        {
            "city_college_of_dongguan_university_of_technology": "City College of Dongguan University of Technology"
        },
        {
            "hebei_vocational_college_for_correctional_police": "Hebei Vocational College for Correctional Police"
        },
        {
            "liaoning_urban_construction_technical_college": "Liaoning Urban Construction Technical College"
        },
        {
            "shanghai_xingjian_college": "Shanghai Xingjian College"
        },
        {
            "wuhan_railway_vocational_college_of_technology": "Wuhan Railway Vocational College of Technology"
        },
        {
            "fujian_vocational_college_of_agriculture": "Fujian Vocational College of Agriculture"
        },
        {
            "quanzhou_arts_and_crafts_vocational_college": "Quanzhou Arts and Crafts Vocational College"
        },
        {
            "southwestern_christian_college": "Southwestern Christian College"
        },
        {
            "xinjiang_institute_of_light_industry_technology": "Xinjiang Institute of Light Industry Technology"
        },
        {
            "yueyang_vocational_technical_college": "Yueyang Vocational Technical College"
        },
        {
            "hubei_engineering_institute": "Hubei Engineering Institute"
        },
        {
            "harbin_petroleum_institute": "Harbin Petroleum Institute"
        },
        {
            "liaoning_vocational_college_of_ecological_engineering": "Liaoning Vocational College of Ecological Engineering"
        },
        {
            "jinan_vocational_college_of_nursing": "Jinan Vocational College of Nursing"
        },
        {
            "shantou_polytechnic": "Shantou Polytechnic"
        },
        {
            "henan_vocational_college_of_information_statistics": "Henan Vocational College of Information Statistics"
        },
        {
            "fuzhou_science_&_technology_college": "Fuzhou Science & Technology College"
        },
        {
            "guizhou_university_of_chinese_medicine": "Guizhou University of Chinese Medicine"
        },
        {
            "hubei_urban_construction_vocational_and_technological_college": "Hubei Urban Construction Vocational and Technological College"
        },
        {
            "lu'an_vocation_technology_college": "Lu'an Vocation Technology College"
        },
        {
            "yingkou_vocational_&_technical_college": "Yingkou Vocational & Technical College"
        },
        {
            "hangzhou_polytechnic": "Hangzhou Polytechnic"
        },
        {
            "xinglin_college_liaoning_university_of_traditional_chinese_medicine": "Xinglin College Liaoning University of Traditional Chinese Medicine"
        },
        {
            "fujian_vocational_college_of_bioengineering": "Fujian Vocational College of Bioengineering"
        },
        {
            "xinjiang_teacher's_college/_\u00e6\u2013\u00b0\u00e7\u2013\u2020\u00e5\u00b8\u02c6\u00e8\u0153\u0192\u00e9\u00ab\u02dc\u00e7\u00ad\u2030\u00e4\u00b8\u201c\u00e7\u00a7\u2018\u00e5\u00ad\u00a6\u00e6\u00a0\u00a1;_xinjiang_education_institute": "Xinjiang Teacher's College/ \u00e6\u2013\u00b0\u00e7\u2013\u2020\u00e5\u00b8\u02c6\u00e8\u0152\u0192\u00e9\u00ab\u02dc\u00e7\u00ad\u2030\u00e4\u00b8\u201c\u00e7\u00a7\u2018\u00e5\u00ad\u00a6\u00e6\u00a0\u00a1; Xinjiang Education Institute"
        },
        {
            "guangdong_vocational_&_technical_college": "Guangdong Vocational & Technical College"
        },
        {
            "xuchang_ceramic_vocational_college": "Xuchang Ceramic Vocational College"
        },
        {
            "suzhou_top_institute_of_information_technology": "Suzhou Top Institute of Information Technology"
        },
        {
            "jiangxi_v&t_college_of_communications": "Jiangxi V&T College of Communications"
        },
        {
            "jiangxi_medical_college": "Jiangxi Medical College"
        },
        {
            "xinghai_conservatory_of_music": "Xinghai Conservatory of Music"
        },
        {
            "hebei_university_of_environmental_engineering": "Hebei University of Environmental Engineering"
        },
        {
            "chongqing_vocational_college_of_light_industry": "Chongqing Vocational College of Light Industry"
        },
        {
            "neijiang_vocational_&_technical_college": "Neijiang Vocational & Technical College"
        },
        {
            "hunan_business_vocational_college": "Hunan Business Vocational College"
        },
        {
            "baotou_teachers'_college": "Baotou Teachers' College"
        },
        {
            "henan_tuina_vocational_college": "Henan Tuina Vocational College"
        },
        {
            "shandong_judicial_police_vocational_college": "Shandong Judicial Police Vocational College"
        },
        {
            "oxbridge_college_kunming_university_of_science_&_technology": "Oxbridge College Kunming University of Science & Technology"
        },
        {
            "zhejiang_security_vocational_and_technical_college": "Zhejiang Security Vocational and Technical College"
        },
        {
            "taizhong_vocational_&_technical_college": "Taizhong Vocational & Technical College"
        },
        {
            "heilongjiang_communications_polytechnic": "Heilongjiang Communications Polytechnic"
        },
        {
            "tianjin_college_of_commerce": "Tianjin College of Commerce"
        },
        {
            "inner_mongolia_northern_occupation_technical_college": "Inner Mongolia Northern Occupation Technical College"
        },
        {
            "wenzhou_business_school": "Wenzhou Business School"
        },
        {
            "hebei_normal_university_for_nationalities": "Hebei Normal University for Nationalities"
        },
        {
            "tianjin_radio_and_film_vocational_college": "Tianjin Radio and Film Vocational College"
        },
        {
            "beijing_college_of_sports": "Beijing College of Sports"
        },
        {
            "chongqing_vocational_college_of_applied_technology": "Chongqing Vocational College of Applied Technology"
        },
        {
            "zhengzhou_business_tourism_vocational_college": "Zhengzhou Business Tourism Vocational College"
        },
        {
            "kunming_vocational_&_technical_college_of_industry": "Kunming Vocational & Technical College of Industry"
        },
        {
            "guangxi_police_college": "Guangxi Police College"
        },
        {
            "zhengzhou_yellow_river_nursing_vocational_college": "Zhengzhou Yellow River Nursing Vocational College"
        },
        {
            "chengdu_industry_and_trade_vocational_and_technical_college": "Chengdu Industry and Trade Vocational and Technical College"
        },
        {
            "chengdu_vocational_&_technical_college_of_industry": "Chengdu Vocational & Technical College of Industry"
        },
        {
            "shanxi_university_business_college": "Shanxi University Business College"
        },
        {
            "tianjin_sino-german_university_of_applied_sciences": "Tianjin Sino-German University of Applied Sciences"
        },
        {
            "jingdezhen_university": "Jingdezhen University"
        },
        {
            "shijiazhuang_vocational_college_of_finance_&_economics": "Shijiazhuang Vocational College of Finance & Economics"
        },
        {
            "jiangsu_nursing_vocational_college": "Jiangsu Nursing Vocational College"
        },
        {
            "ynucheng_advanced_normal_college": "Ynucheng Advanced Normal College"
        },
        {
            "shandong_tv_university": "Shandong TV University"
        },
        {
            "shangqiu_institute_of_technology": "Shangqiu Institute of Technology"
        },
        {
            "hubei_university_of_economics_law_school_of_business": "Hubei University of Economics Law School of Business"
        },
        {
            "zhejiang_normal_university_(xingzhi_college)": "Zhejiang Normal University (Xingzhi College)"
        },
        {
            "qingdao_agricultural_university_haidu_college": "Qingdao Agricultural University Haidu College"
        },
        {
            "qingyang_vocational_and_technical_college": "Qingyang Vocational and Technical College"
        },
        {
            "national_judges_college": "National Judges College"
        },
        {
            "hunan_sports_vocational_college": "Hunan Sports Vocational College"
        },
        {
            "aksu_vocational_&_technical_college": "Aksu Vocational & Technical College"
        },
        {
            "shenyang_northern_software_college_of_information_technology": "Shenyang Northern Software College of Information Technology"
        },
        {
            "ordos_institute_of_applied_technology": "Ordos Institute of Applied Technology"
        },
        {
            "henan_vocational_college_of_water_conservancy_and_environment": "Henan Vocational College of Water Conservancy and Environment"
        },
        {
            "kunlun_tourism_college_heilongjiang_institute_of_technology": "Kunlun Tourism College Heilongjiang Institute of Technology"
        },
        {
            "maotai_college": "Maotai College"
        },
        {
            "dalian_university_of_science_and_technology": "Dalian University of Science and Technology"
        },
        {
            "sichuan_southwest_college_of_civil_aviation": "Sichuan Southwest College of Civil Aviation"
        },
        {
            "suqian_vocational_and_technical_college": "Suqian Vocational and Technical College"
        },
        {
            "guangxi_blue_sky_aviation_vocational_college": "Guangxi Blue Sky Aviation Vocational College"
        },
        {
            "shiyuan_college_of_guangxi_teachers_education_university": "Shiyuan College of Guangxi Teachers Education University"
        },
        {
            "shijiazhuang_vocational_college_of_technology_&_information": "Shijiazhuang Vocational College of Technology & Information"
        },
        {
            "shanghai_minyuan_vocational_and_technical_college": "Shanghai Minyuan Vocational and Technical College"
        },
        {
            "hangzhou_business_school_of_zhejiang_gongshang_university": "Hangzhou Business School of Zhejiang Gongshang University"
        },
        {
            "sichuan_vocational_college_of_health_and_rehabilitation": "Sichuan Vocational College of Health and Rehabilitation"
        },
        {
            "xinjiang_energy_vocational_and_technical_college": "Xinjiang Energy Vocational and Technical College"
        },
        {
            "wanjiang_university_of_technology": "Wanjiang University of Technology"
        },
        {
            "city_college_of_huizhou": "City College of Huizhou"
        },
        {
            "gongqing_institute_of_science_and_technology": "Gongqing Institute of Science and Technology"
        },
        {
            "guangdong_vocational_institute_of_sport": "Guangdong Vocational Institute of Sport"
        },
        {
            "rizhao_navigation_engineering_vocational_college": "Rizhao Navigation Engineering Vocational College"
        },
        {
            "jilin_provincial_institute_of_education": "Jilin Provincial Institute of Education"
        },
        {
            "tianfu_new_district_aviation_tourism_vocational_college": "Tianfu New District Aviation Tourism Vocational College"
        },
        {
            "guangdong_hotel_management_vocational_and_technical_college": "Guangdong Hotel Management Vocational and Technical College"
        },
        {
            "harbin_college_of_science_and_technology_vocational_college": "Harbin College of Science and Technology Vocational College"
        },
        {
            "yunnan_vocational_college_of_transportation": "Yunnan Vocational College of Transportation"
        },
        {
            "jiuzhou_polytechnic": "Jiuzhou Polytechnic"
        },
        {
            "jinzhong_university": "Jinzhong University"
        },
        {
            "xi'an_university_of_science_and_technology": "Xi'an University of Science and Technology"
        },
        {
            "guangxi_economics_&_trade_vocational_institute": "Guangxi Economics & Trade Vocational Institute"
        },
        {
            "weihai_vocational_college": "Weihai Vocational College"
        },
        {
            "henan_university_minsheng_college": "Henan University Minsheng College"
        },
        {
            "sanming_medical_&_polytechnic_technical_college": "Sanming Medical & Polytechnic Technical College"
        },
        {
            "yunnan_tin_vocational_technical_college": "Yunnan Tin Vocational Technical College"
        },
        {
            "vocational_college_daxing'an_mountains": "Vocational College Daxing'an Mountains"
        },
        {
            "hubei_preschool_teachers_college": "Hubei Preschool Teachers College"
        },
        {
            "zhejiang_automotive_vocational_&_technical_college": "Zhejiang Automotive Vocational & Technical College"
        },
        {
            "heilongjiang_college_of_business_and_technology": "Heilongjiang College of Business and Technology"
        },
        {
            "zhaotong_university": "Zhaotong University"
        },
        {
            "jiangxi_hongzhou_vocational_college": "Jiangxi Hongzhou Vocational College"
        },
        {
            "suzhou_college_of_information_technology": "Suzhou College of Information Technology"
        },
        {
            "guangzhou_polytechnic_of_sports": "Guangzhou Polytechnic of Sports"
        },
        {
            "yunnan_finance_and_economics_vocational_college": "Yunnan Finance and Economics Vocational College"
        },
        {
            "nanjing_audit_university_jinshang_college": "Nanjing Audit University Jinshang College"
        },
        {
            "yancheng_preschool_teachers_college": "Yancheng Preschool Teachers College"
        },
        {
            "school_of_information_and_business_north_university_of_china": "School of Information and Business North University of China"
        },
        {
            "xinjiang_institute_of_technology": "Xinjiang Institute of Technology"
        },
        {
            "huizhou_health_vocational_and_technical_college": "Huizhou Health Vocational and Technical College"
        },
        {
            "jiangsu_tourism_vocational_college": "Jiangsu Tourism Vocational College"
        },
        {
            "luoyang_polytechnic": "Luoyang Polytechnic"
        },
        {
            "sichuan_aerospace_vocational_&_technical_college": "Sichuan Aerospace Vocational & Technical College"
        },
        {
            "heilongjiang_vocational_and_technical_college_of_coal": "Heilongjiang Vocational and Technical College of Coal"
        },
        {
            "yantai_gold_vocational_college": "Yantai Gold Vocational College"
        },
        {
            "jiangxi_fenglin_college_of_foreign_economy_and_trade_/_jiangxi_fenglin_foreign_economic_and_trade_college": "Jiangxi Fenglin College of Foreign Economy and Trade / Jiangxi Fenglin Foreign Economic and Trade College"
        },
        {
            "guangdong_business_and_technology_university": "Guangdong Business and Technology University"
        },
        {
            "suzhou_polytechnic_institute_of_agriculture": "Suzhou Polytechnic Institute of Agriculture"
        },
        {
            "jilin_medical_university": "Jilin Medical University"
        },
        {
            "shanghai_art_and_design_academy": "Shanghai Art and Design Academy"
        },
        {
            "guizhou_food_engineering_vocational_college": "Guizhou Food Engineering Vocational College"
        },
        {
            "taishan_nursing_vocational_college": "Taishan Nursing Vocational College"
        },
        {
            "shijiazhuang_city_economic_vocational_college": "Shijiazhuang City Economic Vocational College"
        },
        {
            "chongqing_radio_and_television_university": "Chongqing Radio and Television University"
        },
        {
            "guizhou_city_vocational_college": "Guizhou City Vocational College"
        },
        {
            "sanming_university": "Sanming University"
        },
        {
            "shanxi_youth_vocational_college": "Shanxi Youth Vocational College"
        },
        {
            "shanghai_urban_construction_vocational_college": "Shanghai Urban Construction Vocational College"
        },
        {
            "kunming_railway_vocational_and_technical_college": "Kunming Railway Vocational and Technical College"
        },
        {
            "xuanhua_technology_vocational_college": "Xuanhua Technology Vocational College"
        },
        {
            "nanjing_university_of_posts_and_telecommunications_tongda_college": "Nanjing University of Posts and Telecommunications Tongda College"
        },
        {
            "guangdong_finance_and_trade_vocational_college": "Guangdong Finance and Trade Vocational College"
        },
        {
            "guangxi_college_of_education": "Guangxi College of Education"
        },
        {
            "baoding_infant_normal_college": "Baoding Infant Normal College"
        },
        {
            "beijing_international_mba_at_peking_university": "Beijing International MBA at Peking University"
        },
        {
            "chongqing_water_resources_and_electric_engineering_college": "Chongqing Water Resources and Electric Engineering College"
        },
        {
            "art_vocational_university_in_mongolia": "Art Vocational University in Mongolia"
        },
        {
            "harbin_city_vocational_college": "Harbin City Vocational College"
        },
        {
            "guizhou_radio_and_tv_university": "Guizhou Radio and TV University"
        },
        {
            "guizhou_radio_and_tv_university": "Guizhou Radio and TV University"
        },
        {
            "zhangjiakou_university": "Zhangjiakou University"
        },
        {
            "tianjin_art_vocational_college": "Tianjin Art Vocational College"
        },
        {
            "hebei_finance_university": "Hebei Finance University"
        },
        {
            "shanghai_jing'an_district_college": "Shanghai Jing'an District College"
        },
        {
            "lincang_science_and_technology_normal_university": "Lincang Science and Technology Normal University"
        },
        {
            "linxia_modern_vocational_college": "Linxia Modern Vocational College"
        },
        {
            "jiangsu_aviation_vocational_and_technical_college": "Jiangsu Aviation Vocational and Technical College"
        },
        {
            "changchun_teachers_college": "Changchun Teachers College"
        },
        {
            "tianjin_land_resources_and_house_vocational_college": "Tianjin Land Resources and House Vocational College"
        },
        {
            "zhejiang_business_college": "Zhejiang Business College"
        },
        {
            "shenzhen_open_university": "Shenzhen Open University"
        },
        {
            "shenmu_vocational_&_technical_college": "Shenmu Vocational & Technical College"
        },
        {
            "hunan_institute_of_traffic_engineering": "Hunan Institute of Traffic Engineering"
        },
        {
            "beijing_network_vocational_college": "Beijing Network Vocational College"
        },
        {
            "xinyang_international_vocation_institute": "Xinyang International Vocation Institute"
        },
        {
            "zhejiang_institute_of_mechanic_&_electrical_engineering": "Zhejiang Institute of Mechanic & Electrical Engineering"
        },
        {
            "baiyin_institute_of_mining_&_metallurgy": "Baiyin Institute of Mining & Metallurgy"
        },
        {
            "pingxiang_health_vocational_college": "Pingxiang Health Vocational College"
        },
        {
            "zhanjiang_preschool_teachers_college": "Zhanjiang Preschool Teachers College"
        },
        {
            "langfang_health_vocational_college": "Langfang Health Vocational College"
        },
        {
            "baotou_railway_vocational_and_technical_college": "Baotou Railway Vocational and Technical College"
        },
        {
            "sichuan_business_vocational_college": "Sichuan Business Vocational College"
        },
        {
            "sanmenxia_social_management_vocational_college": "Sanmenxia Social Management Vocational College"
        },
        {
            "henan_forestry_vocational_college": "Henan Forestry Vocational College"
        },
        {
            "henan_mechanical_and_electrical_vocational_college": "Henan Mechanical and Electrical Vocational College"
        },
        {
            "shaanxi_tourism_cuisine_professional_college": "Shaanxi Tourism Cuisine Professional College"
        },
        {
            "sichuan_tianyi_university": "Sichuan Tianyi University"
        },
        {
            "yunnan_sanxin_vocational_&_technical_college": "Yunnan Sanxin Vocational & Technical College"
        },
        {
            "shandong_vocational_college_of_industry": "Shandong Vocational College of Industry"
        },
        {
            "guizhou_engineering_vocational_college": "Guizhou Engineering Vocational College"
        },
        {
            "deyang_urban_rail_transit_vocational_college": "Deyang Urban Rail Transit Vocational College"
        },
        {
            "jincheng_vocational_and_technical_college": "Jincheng Vocational and Technical College"
        },
        {
            "liaoning_radio_and_television_university": "Liaoning Radio and Television University"
        },
        {
            "chifeng_industry_vocational_technology_college": "Chifeng Industry Vocational Technology College"
        },
        {
            "chongqing_jianzhu_college": "Chongqing Jianzhu College"
        },
        {
            "chongqing_safety_technology_vocational_college": "Chongqing Safety Technology Vocational College"
        },
        {
            "sichuan_vocational_college_of_applied_technology": "Sichuan Vocational College of Applied Technology"
        },
        {
            "shanghai_maritime_academy": "Shanghai Maritime Academy"
        },
        {
            "xiangnan_preschool_teachers_college": "Xiangnan Preschool Teachers College"
        },
        {
            "nanchang_vocational_university": "Nanchang Vocational University"
        },
        {
            "nanjing_open_university": "Nanjing Open University"
        },
        {
            "shanxi_police_academy": "Shanxi Police Academy"
        },
        {
            "west_china_medical_center_sichuan_medical_university": "West China Medical Center Sichuan Medical University"
        },
        {
            "yangtze_river_art_engineering_vocational_college": "Yangtze River Art Engineering Vocational College"
        },
        {
            "fujian_business_university": "Fujian Business University"
        },
        {
            "hulunbeir_vocational_&_technical_college": "Hulunbeir Vocational & Technical College"
        },
        {
            "jiangxi_college_of_construction": "Jiangxi College of Construction"
        },
        {
            "south_china_institute_of_software_engineering_guangzhou_university": "South China Institute of Software Engineering Guangzhou University"
        },
        {
            "jingzhou_institute_of_education_(jingzhou_city_radio_and_television_university)": "Jingzhou Institute of Education (Jingzhou City Radio and Television University)"
        },
        {
            "guangdong_dance_and_drama_vocational_college": "Guangdong Dance and Drama Vocational College"
        },
        {
            "harbin_cambridge_university": "Harbin Cambridge University"
        },
        {
            "yangquan_vocational_and_technical_college": "Yangquan Vocational and Technical College"
        },
        {
            "guiyang_vocational_&_technical_college": "Guiyang Vocational & Technical College"
        },
        {
            "liaoning_national_normal_college": "Liaoning National Normal College"
        },
        {
            "hubei_finance_and_taxation_college": "Hubei Finance and Taxation College"
        },
        {
            "guangdong_university_of_science_and_technology": "Guangdong University of Science and Technology"
        },
        {
            "dehong_teachers_college": "Dehong Teachers College"
        },
        {
            "qinghai_institute_of_architecture_and_technology": "Qinghai Institute of Architecture and Technology"
        },
        {
            "shanxi_radio_&_tv_university": "Shanxi Radio & TV University"
        },
        {
            "sichuan_southwest_vocational_college_of_civil_aviation": "Sichuan Southwest Vocational College of Civil Aviation"
        },
        {
            "beijing_pharmaceutical_education_training_center": "Beijing Pharmaceutical Education Training Center"
        },
        {
            "chongqing_chemical_industry_vocational_college": "Chongqing Chemical Industry Vocational College"
        },
        {
            "nanyang_vocational_college_of_agriculture": "Nanyang Vocational College of Agriculture"
        },
        {
            "jinzhou_medical_university_medical_college": "Jinzhou Medical University Medical College"
        },
        {
            "lianyungang_technical_college": "Lianyungang Technical College"
        },
        {
            "luoding_polytechnic": "Luoding Polytechnic"
        },
        {
            "guangzhou_huashang_vocational_college": "Guangzhou Huashang Vocational College"
        },
        {
            "hulunbeier_college": "Hulunbeier College"
        },
        {
            "west_lake_university": "West Lake University"
        },
        {
            "jiangsu_safety_technology_vocational_college": "Jiangsu Safety Technology Vocational College"
        },
        {
            "qiqihar_polytechnic_vocational_college": "Qiqihar Polytechnic Vocational College"
        },
        {
            "xi'an_jiaotong_university_city_college": "Xi'an Jiaotong University City College"
        },
        {
            "hebi_automotive_engineering_professional_college": "Hebi Automotive Engineering Professional College"
        },
        {
            "aviation_industrial_workers_university_of_lanzhou": "Aviation Industrial Workers University of Lanzhou"
        },
        {
            "baotou_vocational_&_technical_college": "Baotou Vocational & Technical College"
        },
        {
            "chongqing_police_college": "Chongqing Police College"
        },
        {
            "wuhan_maritime_vocational_college": "Wuhan Maritime Vocational College"
        },
        {
            "history_culture_and_tourism_college_jiangxi_normal_university": "History Culture and Tourism College Jiangxi Normal University"
        },
        {
            "guangdong_judicial_police_vocational_college_campus_lianjiang": "Guangdong Judicial Police Vocational College Campus Lianjiang"
        },
        {
            "hunan_institue_of_information_technology": "Hunan Institue of Information Technology"
        },
        {
            "hunan_electronic_technology_vocational_college": "Hunan Electronic Technology Vocational College"
        },
        {
            "xinjiang_agricultural_university_college_of_science_and_technology": "Xinjiang Agricultural University College of Science and Technology"
        },
        {
            "huizhou_vocational_college": "Huizhou Vocational College"
        },
        {
            "henan_vocational_university_of_science_and_technology": "Henan Vocational University of Science and Technology"
        },
        {
            "shanghai_xingwei_college": "Shanghai Xingwei College"
        },
        {
            "the_national_university_science_and_technology_park_of_yanshan_university": "The National University Science and Technology Park of Yanshan University"
        },
        {
            "civil_aviation_management_institute_of_china_/_civil_aviation_management_cadre": "Civil Aviation Management Institute of China / Civil Aviation Management Cadre"
        },
        {
            "hunan_labor_and_personnel_vocational_college": "Hunan Labor and Personnel Vocational College"
        },
        {
            "chongqing_vocational_college_of_science_and_technology": "Chongqing Vocational College of Science and Technology"
        },
        {
            "zhejiang_zhoushan_islands_new_district_tourism_and_health_vocational_college": "Zhejiang Zhoushan Islands New District Tourism and Health Vocational College"
        },
        {
            "shanghai_bond_vocational_and_technical_college": "Shanghai Bond Vocational and Technical College"
        },
        {
            "southern_it_college_(guangdong_southern_college)": "Southern IT College (Guangdong Southern College)"
        },
        {
            "jiangsu_province_youth_administrative_cadres_college": "Jiangsu Province Youth Administrative Cadres College"
        },
        {
            "jilin_water_conservancy_and_electric_power_vocational_college": "Jilin Water Conservancy and Electric Power Vocational College"
        },
        {
            "shandong_medical_college_jinan": "Shandong Medical College Jinan"
        },
        {
            "nanchang_film_and_television_communication_vocational_college": "Nanchang Film and Television Communication Vocational College"
        },
        {
            "guangxi_medical_college": "Guangxi Medical College"
        },
        {
            "chongqing_youth_vocational_&_technical_college": "Chongqing Youth Vocational & Technical College"
        },
        {
            "oriental_college_beijing_university_of_chinese_medicine": "Oriental College Beijing University of Chinese Medicine"
        },
        {
            "jilin_vocational_and_technical_college": "Jilin Vocational and Technical College"
        },
        {
            "inner_mongolia_technical_and_vocational_college": "Inner Mongolia Technical and Vocational College"
        },
        {
            "beijing_vocational_college_of_transportation": "Beijing Vocational College of Transportation"
        },
        {
            "beijing_institute_of_business_and_technology": "Beijing Institute of Business and Technology"
        },
        {
            "guilin_shanshui_vocational_college": "Guilin Shanshui Vocational College"
        },
        {
            "sanya_zhongrui_hotel_management_vocational_college": "Sanya Zhongrui Hotel Management Vocational College"
        },
        {
            "quancheng_college_jinan_university": "Quancheng College Jinan University"
        },
        {
            "guangzhou_modern_information_engineering_college": "Guangzhou Modern Information Engineering College"
        },
        {
            "henan_judicial_police_vocational_college": "Henan Judicial Police Vocational College"
        },
        {
            "chongzuo_college_for_prescholar_education": "Chongzuo College for Prescholar Education"
        },
        {
            "changji_vocational_and_technical_college": "Changji Vocational and Technical College"
        },
        {
            "tianmen_vocational_college": "Tianmen Vocational College"
        },
        {
            "inner_mongolia_energy_vocational_college": "Inner Mongolia Energy Vocational College"
        },
        {
            "dazhou_chinese_medicine_vocational_college": "Dazhou Chinese Medicine Vocational College"
        },
        {
            "guizhou_water_resources_and_hydropower_vocational_and_technical_college": "Guizhou Water Resources and Hydropower Vocational and Technical College"
        },
        {
            "xi'an_employees's_university": "Xi'an Employees's University"
        },
        {
            "guangdong_xin'an_vocational_technical_college": "Guangdong Xin'an Vocational Technical College"
        },
        {
            "hebei_institute_of_international_business_and_economics": "Hebei Institute of International Business and Economics"
        },
        {
            "shijiazhuang_economic_vocational_college": "Shijiazhuang Economic Vocational College"
        },
        {
            "datong_coal_vocational_and_technical_college": "Datong Coal Vocational and Technical College"
        },
        {
            "jiangxi_tellhow_animation_career_college": "Jiangxi Tellhow Animation Career College"
        },
        {
            "college_of_humanities_&_sciences_northeast_normal_university": "College of Humanities & Sciences Northeast Normal University"
        },
        {
            "north_sichuan_college_of_preschool_teacher_education": "North Sichuan College of Preschool Teacher Education"
        },
        {
            "chinese_university_of_hong_kong_shenzhen_research_institute": "Chinese University of Hong Kong Shenzhen Research Institute"
        },
        {
            "china_center_of_advanced_science_&_technology": "China Center of Advanced Science & Technology"
        },
        {
            "jinan_engineering_vocational_and_technical_college": "Jinan Engineering Vocational and Technical College"
        },
        {
            "chun'an_vocational_and_technical_college": "Chun'an Vocational and Technical College"
        },
        {
            "tianjin_sports_vocational_college": "Tianjin Sports Vocational College"
        },
        {
            "jilin_city_vocational_and_technical_college": "Jilin City Vocational and Technical College"
        },
        {
            "he_university": "He University"
        },
        {
            "defence_industry_staff_college": "Defence Industry Staff College"
        },
        {
            "haining_college_zhejiang_radio_and_television_university": "Haining College Zhejiang Radio and Television University"
        },
        {
            "weihai_ocean_vocational_college": "Weihai Ocean Vocational College"
        },
        {
            "shanxi_police_college": "Shanxi Police College"
        },
        {
            "huaihua_radio_and_tv_university": "Huaihua Radio and TV University"
        },
        {
            "heilongjiang_energy_vocational_college": "Heilongjiang Energy Vocational College"
        },
        {
            "hami_vocational_and_technical_college": "hami vocational and technical college"
        },
        {
            "liaoning_normal_haihua_huahai_college": "Liaoning Normal Haihua Huahai College"
        },
        {
            "beijing_foreign_trade_school": "Beijing Foreign Trade School"
        },
        {
            "tianjin_electronic_information_college": "Tianjin Electronic Information College"
        },
        {
            "guangdong_vocational_institute_of_public_administration": "Guangdong Vocational Institute of Public Administration"
        },
        {
            "changchun_institute_of_education": "Changchun Institute of Education"
        },
        {
            "tianfu_new_district_general_aviation_vocational_college": "Tianfu New District General Aviation Vocational College"
        },
        {
            "weifang_institute_of_technology": "Weifang Institute of Technology"
        },
        {
            "hainan_health_management_vocational_and_technical_college": "Hainan Health Management Vocational and Technical College"
        },
        {
            "quanzhou_medical_college": "Quanzhou Medical College"
        },
        {
            "wuhan_university_of_textiles_school_of_foreign_trade_and_economics": "Wuhan University of Textiles School of Foreign Trade and Economics"
        },
        {
            "huizhou_engineering_vocational_college": "Huizhou Engineering Vocational College"
        },
        {
            "culture_&_media_college_liaoning_university_of_technology": "Culture & Media College Liaoning University of Technology"
        },
        {
            "minjiang_teachers_college": "Minjiang Teachers College"
        },
        {
            "aba_vocational_college": "Aba Vocational College"
        },
        {
            "technological_vocational_college_of_dezhou": "Technological Vocational College of Dezhou"
        },
        {
            "tianfu_information_vocational_college": "Tianfu Information Vocational College"
        },
        {
            "taiyuan_radio_&_tv_university": "Taiyuan Radio & TV University"
        },
        {
            "changsha_education_college": "Changsha Education College"
        },
        {
            "southwestern_national_vocational_and_technical_college": "Southwestern National Vocational and Technical College"
        },
        {
            "xinzhou_vocational_and_technical_college": "Xinzhou Vocational and Technical College"
        },
        {
            "qinghai_qaidam_vocational_and_technical_college": "Qinghai Qaidam Vocational and Technical College"
        },
        {
            "turpan_vocational_and_technical_college": "Turpan Vocational and Technical College"
        },
        {
            "normal_college_jishou_university": "Normal College Jishou University"
        },
        {
            "zhengzhou_technician_college_of_finance_and_economics": "Zhengzhou Technician College of Finance and Economics"
        },
        {
            "anhui_vocational_college_of_grain_engineering": "Anhui Vocational College of Grain Engineering"
        },
        {
            "guangdong_communication_polytechnic": "Guangdong Communication Polytechnic"
        },
        {
            "fujian_polytechnic_of_information_technology": "Fujian Polytechnic of Information Technology"
        },
        {
            "caofeidian_vocational_and_technical_college": "Caofeidian Vocational and Technical College"
        },
        {
            "anhui_shengzhi_radio_and_television_university": "Anhui Shengzhi Radio and Television University"
        },
        {
            "shanghai_changning_spare-time_university": "Shanghai Changning Spare-time University"
        },
        {
            "lianyungang_normal_college": "Lianyungang Normal College"
        },
        {
            "naval_staff_university": "Naval Staff University"
        },
        {
            "luliang_vocational_and_technical_college": "Luliang Vocational and Technical College"
        },
        {
            "changzhou_health_vocational_&_technical_school_(changzhou_medical_school)": "Changzhou Health Vocational & Technical School (Changzhou Medical School)"
        },
        {
            "luzhou_vocational_&_technical_college": "Luzhou Vocational & Technical College"
        },
        {
            "guizhou_vocational_technology_college_of_electronics_&_information": "Guizhou Vocational Technology College of Electronics & Information"
        },
        {
            "chongqing_nursing_vocational_college": "Chongqing Nursing Vocational College"
        },
        {
            "xinyang_aviation_vocational_college": "Xinyang Aviation Vocational College"
        },
        {
            "shanxi_institute_of_energy_/_shanxi_energy_academy": "Shanxi Institute of Energy / Shanxi Energy Academy"
        },
        {
            "yanbian_radio_and_television_university": "Yanbian Radio and Television University"
        },
        {
            "guangzhou_electronic_information_school": "Guangzhou Electronic Information School"
        },
        {
            "harbin_medical_college": "Harbin Medical College"
        },
        {
            "jiamusi_vocational_college": "Jiamusi Vocational College"
        },
        {
            "chongqing_real_estate_college": "Chongqing Real Estate College"
        },
        {
            "harbin_open_university": "Harbin Open University"
        },
        {
            "liaoning_special_education_teachers_college": "Liaoning Special Education Teachers College"
        },
        {
            "jilin_university_lambton_college": "Jilin University Lambton College"
        },
        {
            "nanyang_vocational_college": "Nanyang Vocational College"
        },
        {
            "xichang_national_early_childhood_teachers_college": "Xichang National Early Childhood Teachers College"
        },
        {
            "shantou_electric_power_industry_university": "Shantou Electric Power Industry University"
        },
        {
            "baoding_radio_&_television_university": "Baoding Radio & Television University"
        },
        {
            "nanchang_radio_and_television_university": "Nanchang Radio and Television University"
        },
        {
            "xiangzhong_preschool_teachers_college": "Xiangzhong Preschool Teachers College"
        },
        {
            "the_open_university_of_fujian_campus_zhangzhou": "The Open University of Fujian Campus Zhangzhou"
        },
        {
            "guilin_staff_college": "Guilin Staff College"
        },
        {
            "chongqing_city_vocational_college": "Chongqing City Vocational College"
        },
        {
            "guizhou_industry_and_trade_vocational_college": "Guizhou Industry and Trade Vocational College"
        },
        {
            "chengdu_workers_university": "Chengdu Workers University"
        },
        {
            "anhui_tv_university_online": "Anhui TV University Online"
        },
        {
            "guangdong_eco-engineering_polytechnic": "Guangdong Eco-engineering Polytechnic"
        },
        {
            "software_institute_of_shenyang_polytechnic_college": "Software Institute of Shenyang Polytechnic College"
        },
        {
            "shanghai_economic_management_cadre_college": "Shanghai Economic Management Cadre College"
        },
        {
            "chongqing_power_workers_university": "Chongqing Power Workers University"
        },
        {
            "dalian_education_college": "Dalian Education College"
        },
        {
            "china_fifth_metallurgical_construction_company_staff_college": "China Fifth Metallurgical Construction Company Staff College"
        },
        {
            "zhumadian_preschool_teachers_college": "Zhumadian Preschool Teachers College"
        },
        {
            "shenyang_machinery_industry_workers_university": "Shenyang Machinery Industry Workers University"
        },
        {
            "guangzhou_open_university_campus_qiaoguang": "Guangzhou Open University Campus Qiaoguang"
        }
    ],
    "singapore": [
        {
            "national_university_of_singapore": "National University of Singapore"
        },
        {
            "nanyang_technological_university": "Nanyang Technological University"
        },
        {
            "singapore_management_university": "Singapore Management University"
        },
        {
            "singapore_university_of_technology_and_design": "Singapore University of Technology and Design"
        },
        {
            "singapore_institute_of_technology": "Singapore Institute of Technology"
        },
        {
            "ngee_ann_polytechnic": "Ngee Ann Polytechnic"
        },
        {
            "singapore_institute_of_management": "Singapore Institute of Management"
        },
        {
            "nanyang_polytechnic": "Nanyang Polytechnic"
        },
        {
            "nanyang_technological_university_national_institute_of_education": "Nanyang Technological University National Institute of Education"
        },
        {
            "duke-nus_graduate_medical_school_singapore": "Duke-NUS Graduate Medical School Singapore"
        },
        {
            "yale_nus_college": "Yale NUS College"
        },
        {
            "james_cook_university_singapore": "James Cook University Singapore"
        },
        {
            "singapore_polytechnic": "Singapore Polytechnic"
        },
        {
            "singapore_university_of_social_sciences_suss_(sim_university)": "Singapore University of Social Sciences SUSS (SIM University)"
        },
        {
            "singapore_mit_alliance_for_research_and_technology": "Singapore MIT Alliance for Research and Technology"
        },
        {
            "republic_polytechnic": "Republic Polytechnic"
        },
        {
            "s_p_jain_center_of_management": "S P Jain Center of Management"
        },
        {
            "lasalle_college_of_arts": "Lasalle College of Arts"
        },
        {
            "management_developement_institute_of_singapore": "Management Developement Institute of Singapore"
        },
        {
            "kaplan_singapore": "Kaplan Singapore"
        },
        {
            "nanyang_academy_of_fine_arts": "Nanyang Academy of Fine Arts"
        },
        {
            "psb_academy_singapore": "PSB Academy Singapore"
        },
        {
            "dimensions_international_college": "Dimensions International College"
        },
        {
            "curtin_university_singapore": "Curtin University Singapore"
        },
        {
            "hwa_chong_institution": "Hwa Chong Institution"
        },
        {
            "academy_of_medicine": "Academy of Medicine"
        },
        {
            "tmc_academy": "TMC Academy"
        },
        {
            "informatics_academy_singapore": "Informatics Academy Singapore"
        },
        {
            "east_asia_institute_of_management": "East Asia Institute of Management"
        },
        {
            "auston_institute": "Auston Institute"
        },
        {
            "singapore_raffles_music_college": "Singapore Raffles Music College"
        },
        {
            "bmc_international_college": "BMC International College"
        },
        {
            "mages_institute_of_exellence": "MAGES Institute of Exellence"
        },
        {
            "the_university_of_adelaide_ngee_ann": "The University of Adelaide Ngee Ann"
        },
        {
            "ftms_global_singapore": "FTMS Global Singapore"
        },
        {
            "marketing_institute_of_singapore": "Marketing Institute of Singapore"
        },
        {
            "mad_school": "MAD School"
        },
        {
            "chatsworth_mediart_academy": "Chatsworth Mediart Academy"
        },
        {
            "university_of_birmingham": "University of Birmingham"
        }
    ],
    "netherlands": [
        {
            "utrecht_university": "Utrecht University"
        },
        {
            "university_of_amsterdam": "University of Amsterdam"
        },
        {
            "university_of_groningen": "University of Groningen"
        },
        {
            "vu_university_of_amsterdam": "VU University of Amsterdam"
        },
        {
            "delft_university_of_technology_tu_delft": "Delft University of Technology TU Delft"
        },
        {
            "wageningen_university_&_research_centre": "Wageningen University & Research Centre"
        },
        {
            "erasmus_university_of_rotterdam": "Erasmus University of Rotterdam"
        },
        {
            "radboud_university": "Radboud University"
        },
        {
            "eindhoven_university_of_technology": "Eindhoven University of Technology"
        },
        {
            "university_of_twente": "University of Twente"
        },
        {
            "maastricht_university": "Maastricht University"
        },
        {
            "tilburg_university": "Tilburg University"
        },
        {
            "leiden_university": "Leiden University"
        },
        {
            "open_university": "Open University"
        },
        {
            "ihe_delft_institute_for_water_education": "IHE Delft Institute for Water Education"
        },
        {
            "amsterdam_university_of_applied_sciences_auas": "Amsterdam University of Applied Sciences AUAS"
        },
        {
            "han_university_of_applied_sciences": "HAN University of Applied Sciences"
        },
        {
            "utrecht_university_of_applied_sciences_(domstad_university_of_applied_sciences)": "Utrecht University of Applied Sciences (Domstad University of Applied Sciences)"
        },
        {
            "hanze_university_groningen": "Hanze University Groningen"
        },
        {
            "saxion_university_of_applied_sciences": "Saxion University of Applied Sciences"
        },
        {
            "windesheim_university_of_applied_sciences": "Windesheim University of Applied Sciences"
        },
        {
            "zuyd_university_of_applied_sciences": "Zuyd University of Applied Sciences"
        },
        {
            "breda_university_of_applied_sciences": "Breda University of Applied Sciences"
        },
        {
            "tinbergen_institute": "Tinbergen Institute"
        },
        {
            "inholland_university_of_applied_sciences": "Inholland University of Applied Sciences"
        },
        {
            "rotterdam_university_of_applied_sciences": "Rotterdam University of Applied Sciences"
        },
        {
            "the_hague_university_of_applied_sciences": "The Hague University of Applied Sciences"
        },
        {
            "rotterdam_school_of_management_rsm_erasmus_university": "Rotterdam School of Management RSM Erasmus University"
        },
        {
            "nhl_stenden_university_of_applied_sciences": "NHL Stenden University of Applied Sciences"
        },
        {
            "fontys_university_of_applied_sciences": "Fontys University of Applied Sciences"
        },
        {
            "utrecht_school_of_the_arts": "Utrecht School of the Arts"
        },
        {
            "leiden_university_of_applied_sciences": "Leiden University of Applied Sciences"
        },
        {
            "nyenrode_business_university": "Nyenrode Business University"
        },
        {
            "faculty_of_geo-information_science_and_earth_observation_university_of_twente": "Faculty of Geo-Information Science and Earth Observation University of Twente"
        },
        {
            "has_university_of_applied_sciences": "HAS University of Applied Sciences"
        },
        {
            "zeeland_university_of_applied_sciences": "Zeeland University of Applied Sciences"
        },
        {
            "academic_centre_for_dentistry": "Academic Centre for Dentistry"
        },
        {
            "university_for_humanistics": "University for Humanistics"
        },
        {
            "maastricht_school_of_management": "Maastricht School of Management"
        },
        {
            "avans_university_of_professional_education": "Avans University of Professional Education"
        },
        {
            "cae_oxford_aviation_academy_(cae_global_academy_amsterdam_flight_school)": "CAE Oxford Aviation Academy (CAE Global Academy Amsterdam Flight School)"
        },
        {
            "amsterdam_school_of_the_arts": "Amsterdam School of the Arts"
        },
        {
            "hotelschool_the_hague": "Hotelschool The Hague"
        },
        {
            "institute_of_the_arts": "Institute of the Arts"
        },
        {
            "design_academy_eindhoven": "Design Academy Eindhoven"
        },
        {
            "gerrit_rietveld_academy": "Gerrit Rietveld Academy"
        },
        {
            "royal_academy_of_art_the_hague": "Royal Academy of Art The Hague"
        },
        {
            "wittenborg_business_university": "Wittenborg Business University"
        },
        {
            "van_hall_larenstein": "Van Hall Larenstein"
        },
        {
            "rotterdams_conservatorium": "Rotterdams Conservatorium"
        },
        {
            "tias_school_for_business_and_society": "TIAS School for Business and Society"
        },
        {
            "nti_distance_education": "NTI Distance Education"
        },
        {
            "protestant_theological_university": "Protestant Theological University"
        },
        {
            "roc_van_twente": "ROC van Twente"
        },
        {
            "ede_christian_university_of_professional_education": "Ede Christian University of Professional Education"
        },
        {
            "tio_university_of_aplied_sciences_tourism_professional_education": "TIO University of Aplied Sciences Tourism Professional Education"
        },
        {
            "aeres_university_of_applied_sciences": "Aeres University of Applied Sciences"
        },
        {
            "royal_academy_of_visual_arts": "Royal Academy of Visual Arts"
        },
        {
            "business_school_netherlands": "Business School Netherlands"
        },
        {
            "roc_aventus": "ROC Aventus"
        },
        {
            "christian_institute_for_higher_professional_education_de_driestar": "Christian Institute for Higher Professional Education de Driestar"
        },
        {
            "erasmus_university_rotterdam_institute_for_housing_and_urban_development_studies": "Erasmus University Rotterdam Institute for Housing and Urban Development Studies"
        },
        {
            "johan_cruyff_institute": "Johan Cruyff Institute"
        },
        {
            "hall_larenstein_college": "Hall Larenstein college"
        },
        {
            "viaa_zwolle_(reformed_university_of_applied_sciences_zwolle)": "VIAA Zwolle (Reformed University of Applied Sciences Zwolle)"
        },
        {
            "webster_university_leiden": "Webster University Leiden"
        },
        {
            "iselinge_university_of_applied_sciences": "Iselinge University of Applied Sciences"
        },
        {
            "dirksen_university_of_professional_education": "Dirksen University of Professional Education"
        },
        {
            "maastricht_hotel_school": "Maastricht Hotel School"
        },
        {
            "thim_university_of_physical_therapy": "Thim University of Physical Therapy"
        },
        {
            "marnix_academy": "Marnix Academy"
        },
        {
            "notenboom_business_school": "Notenboom Business School"
        },
        {
            "theological_university_apeldoorn": "Theological University Apeldoorn"
        },
        {
            "catholic_university_of_professional_education_zwolle": "Catholic University of Professional Education Zwolle"
        },
        {
            "eurocollege_university_of_applied_sciences": "EuroCollege University of Applied Sciences"
        },
        {
            "tmo_university_of_fashion_management": "TMO University of Fashion Management"
        },
        {
            "de_kempel_university": "De Kempel University"
        },
        {
            "novi_university_of_applied_sciences": "Novi University of Applied Sciences"
        },
        {
            "ipabo_amsterdam": "IPABO Amsterdam"
        },
        {
            "university_of_amsterdam_department_of_media_studies": "University of Amsterdam Department of Media Studies"
        },
        {
            "driestar_university_of_applied_sciences": "Driestar University of Applied Sciences"
        },
        {
            "network_university": "Network University"
        },
        {
            "european_pilot_selection_and_training": "European Pilot Selection and Training"
        },
        {
            "interuniversity_center_for_social_science_theory_and_methodology": "Interuniversity Center for Social Science Theory and Methodology"
        },
        {
            "institute_for_psychosynthesis": "Institute For Psychosynthesis"
        },
        {
            "graduate_school_vlag_advanced_studies_in_food_technology_agrobiotechnology_nutrition_and_health_sciences": "Graduate School VLAG Advanced Studies in Food Technology Agrobiotechnology Nutrition and Health Sciences"
        },
        {
            "the_wittenberg_university_of_applied_sciences_and_equipment_center": "The Wittenberg University of Applied Sciences and Equipment Center"
        },
        {
            "inter_college_business_school": "Inter College Business School"
        },
        {
            "c_t_de_wit_graduate_school_for_production_ecology_&_resource_conservation": "C T de Wit Graduate School for Production Ecology & Resource Conservation"
        },
        {
            "netherlands_graduate_research_school_of_science_technology_and_modern_culture": "Netherlands Graduate Research School of Science Technology and Modern Culture"
        },
        {
            "major_seminary_rolduc": "Major Seminary Rolduc"
        },
        {
            "cornerstone_bible_college": "Cornerstone Bible College"
        },
        {
            "casimir_research_school": "Casimir Research School"
        },
        {
            "islamic_university_of_rotterdam": "Islamic University of Rotterdam"
        },
        {
            "new_school_for_information_services": "New School for Information Services"
        },
        {
            "bible_school_foundation_for_life": "Bible School Foundation for Life"
        },
        {
            "graduate_school_experimental_plant_sciences_wageningen": "Graduate School Experimental Plant Sciences Wageningen"
        },
        {
            "holland_research_school_of_molecular_chemistry": "Holland Research School of Molecular Chemistry"
        },
        {
            "j_m_burgerscentrum_research_school_for_fluid_mechanics": "J M Burgerscentrum Research School for Fluid Mechanics"
        },
        {
            "hbo_a&a_school_of_accountancy": "HBO A&A School of Accountancy"
        },
        {
            "national_dutch_graduate_school_of_polymer_science_&_technology": "National Dutch Graduate School of Polymer Science & Technology"
        }
    ],
    "denmark": [
        {
            "university_of_copenhagen": "University of Copenhagen"
        },
        {
            "aarhus_university": "Aarhus University"
        },
        {
            "technical_university_of_denmark": "Technical University of Denmark"
        },
        {
            "aalborg_university": "Aalborg University"
        },
        {
            "university_of_southern_denmark": "University of Southern Denmark"
        },
        {
            "copenhagen_business_school": "Copenhagen Business School"
        },
        {
            "roskilde_university": "Roskilde University"
        },
        {
            "it_university_of_copenhagen": "IT University of Copenhagen"
        },
        {
            "metropolitan_university_college": "Metropolitan University College"
        },
        {
            "via_university_college": "VIA University College"
        },
        {
            "university_college_lilleb\u00e3\u00a6lt.": "University College Lilleb\u00c3\u00a6lt."
        },
        {
            "university_college_nordjylland.": "University College Nordjylland."
        },
        {
            "denmark's_media_and_journalism_college": "Denmark's Media and Journalism College"
        },
        {
            "kea_(bec_design)": "KEA (BEC Design)"
        },
        {
            "aarhus_school_of_architecture": "Aarhus School of Architecture"
        },
        {
            "kolding_design_school": "Kolding Design School"
        },
        {
            "syddansk_erhvervsakademi": "Syddansk Erhvervsakademi"
        },
        {
            "royal_danish_academy_of_music": "Royal Danish Academy of Music"
        },
        {
            "center_of_higher_education_south_jutland": "Center of Higher Education South Jutland"
        },
        {
            "royal_danish_academy_of_fine_arts_school_of_visual_arts": "Royal Danish Academy of Fine Arts School of Visual Arts"
        },
        {
            "next_education_copenhagen": "NEXT Education Copenhagen"
        },
        {
            "absalon_university_college": "Absalon University College"
        },
        {
            "danish_academy_of_business_and_technology_randers": "Danish Academy of Business and Technology Randers"
        },
        {
            "tietgen_business_school": "Tietgen Business School"
        },
        {
            "cph_business_copenhagen_business_academy": "CPH Business Copenhagen Business Academy"
        },
        {
            "international_business_academy": "International Business Academy"
        },
        {
            "national_film_school_of_denmark": "National Film School of Denmark"
        },
        {
            "zealand_business_college_zbc_(handelsskolen_sj\u00e3\u00a6lland_syd)": "Zealand Business College ZBC (Handelsskolen Sj\u00c3\u00a6lland Syd)"
        },
        {
            "business_academy_aarhus": "Business Academy Aarhus"
        },
        {
            "niels_brock_copenhagen_business_college": "Niels Brock Copenhagen Business College"
        },
        {
            "rhythmic_music_conservatory": "Rhythmic Music Conservatory"
        },
        {
            "danish_national_school_of_performing_arts": "Danish National School of Performing Arts"
        },
        {
            "zealand_academy_of_technologies_and_business": "Zealand Academy of Technologies and Business"
        },
        {
            "tech_college_aalborg.": "Tech College Aalborg."
        },
        {
            "business_academy_southwest": "Business Academy SouthWest"
        },
        {
            "celf_(storstroem_business_and_technical_college)": "CELF (Storstroem Business and Technical College)"
        },
        {
            "aalborg_business_college": "Aalborg Business College"
        },
        {
            "dns_international_teacher_training_college": "DNS International Teacher Training College"
        },
        {
            "aarhus_business_college": "Aarhus Business College"
        },
        {
            "uddannelsescenter_holstebro": "Uddannelsescenter Holstebro"
        },
        {
            "kold_college_(dalum_college_of_food_and_technology)": "Kold College (Dalum College of Food and Technology)"
        },
        {
            "tradium_(danish_business_academy": "Tradium (Danish Business Academy"
        },
        {
            "funen_academy_of_fine_arts": "Funen Academy of Fine Arts"
        },
        {
            "jutland_academy_of_fine_arts": "Jutland Academy of Fine Arts"
        },
        {
            "green_academy_aarhus_(vejlby_agricultural_college)": "Green Academy Aarhus (Vejlby Agricultural College)"
        },
        {
            "svendborg_business_school": "Svendborg Business School"
        },
        {
            "the_royal_academy": "The Royal Academy"
        },
        {
            "technical_college_of_jutland": "Technical College of Jutland"
        },
        {
            "campus_velje_(vejle_business_school_+_vuc)": "Campus Velje (Vejle Business School + VUC)"
        }
    ],
    "japan": [
        {
            "university_of_tokyo": "University of Tokyo"
        },
        {
            "kyoto_university": "Kyoto University"
        },
        {
            "osaka_university": "Osaka University"
        },
        {
            "tohoku_university": "Tohoku University"
        },
        {
            "nagoya_university": "Nagoya University"
        },
        {
            "kyushu_university": "Kyushu University"
        },
        {
            "hokkaido_university": "Hokkaido University"
        },
        {
            "tokyo_institute_of_technology": "Tokyo Institute of Technology"
        },
        {
            "university_of_tsukuba": "University of Tsukuba"
        },
        {
            "keio_university": "Keio University"
        },
        {
            "kobe_university": "Kobe University"
        },
        {
            "hiroshima_university": "Hiroshima University"
        },
        {
            "waseda_university": "Waseda University"
        },
        {
            "okayama_university": "Okayama University"
        },
        {
            "shinshu_university": "Shinshu University"
        },
        {
            "kanazawa_university": "Kanazawa University"
        },
        {
            "ritsumeikan_university": "Ritsumeikan University"
        },
        {
            "kumamoto_university": "Kumamoto University"
        },
        {
            "nihon_university": "Nihon University"
        },
        {
            "tokyo_university_of_science": "Tokyo University of Science"
        },
        {
            "kindai_university_(kinki_university)": "Kindai University (Kinki University)"
        },
        {
            "tokyo_metropolitan_university": "Tokyo Metropolitan University"
        },
        {
            "tokyo_medical_and_dental_university": "Tokyo Medical and Dental University"
        },
        {
            "niigata_university": "Niigata University"
        },
        {
            "nagasaki_university": "Nagasaki University"
        },
        {
            "osaka_city_university": "Osaka City University"
        },
        {
            "tokyo_university_of_agriculture_and_technology": "Tokyo University of Agriculture and Technology"
        },
        {
            "yokohama_city_university": "Yokohama City University"
        },
        {
            "nara_institute_of_science_&_technology": "Nara Institute of Science & Technology"
        },
        {
            "juntendo_university": "Juntendo University"
        },
        {
            "university_of_electro-communications": "University of Electro-Communications"
        },
        {
            "gifu_university": "Gifu University"
        },
        {
            "osaka_prefecture_university": "Osaka Prefecture University"
        },
        {
            "yamagata_university": "Yamagata University"
        },
        {
            "yokohama_national_university": "Yokohama National University"
        },
        {
            "shizuoka_university": "Shizuoka University"
        },
        {
            "ehime_university": "Ehime University"
        },
        {
            "gunma_university": "Gunma University"
        },
        {
            "kagoshima_university": "Kagoshima University"
        },
        {
            "university_of_tokushima": "University of Tokushima"
        },
        {
            "mie_university": "Mie University"
        },
        {
            "university_of_toyama": "University of Toyama"
        },
        {
            "okinawa_institute_of_science_&_technology_graduate_university": "Okinawa Institute of Science & Technology Graduate University"
        },
        {
            "japan_advanced_institute_of_science_&_technology": "Japan Advanced Institute of Science & Technology"
        },
        {
            "tokai_university": "Tokai University"
        },
        {
            "kyushu_institute_of_technology": "Kyushu Institute of Technology"
        },
        {
            "university_of_ryukyus": "University of Ryukyus"
        },
        {
            "meiji_university": "Meiji University"
        },
        {
            "university_of_yamanashi": "University of Yamanashi"
        },
        {
            "yamaguchi_university": "Yamaguchi University"
        },
        {
            "united_nations_university": "United Nations University"
        },
        {
            "nagoya_institute_of_technology": "Nagoya Institute of Technology"
        },
        {
            "tokyo_women's_medical_university": "Tokyo Women's Medical University"
        },
        {
            "kitasato_university": "Kitasato University"
        },
        {
            "saitama_university": "Saitama University"
        },
        {
            "tottori_university": "Tottori University"
        },
        {
            "nagoya_city_university": "Nagoya City University"
        },
        {
            "jichi_medical_school": "Jichi Medical School"
        },
        {
            "saga_university": "Saga University"
        },
        {
            "toho_university": "Toho University"
        },
        {
            "chuo_university": "Chuo University"
        },
        {
            "fujita_health_university": "Fujita Health University"
        },
        {
            "shimane_university": "Shimane University"
        },
        {
            "fukuoka_university": "Fukuoka University"
        },
        {
            "nippon_medical_school": "Nippon Medical School"
        },
        {
            "rikkyo_university": "Rikkyo University"
        },
        {
            "teikyo_university": "Teikyo University"
        },
        {
            "hosei_university": "Hosei University"
        },
        {
            "doshisha_university": "Doshisha University"
        },
        {
            "kochi_university": "Kochi University"
        },
        {
            "hirosaki_university": "Hirosaki University"
        },
        {
            "university_of_aizu": "University of Aizu"
        },
        {
            "kagawa_university": "Kagawa University"
        },
        {
            "kansai_university": "Kansai University"
        },
        {
            "university_of_miyazaki": "University of Miyazaki"
        },
        {
            "kwansei_gakuin_university": "Kwansei Gakuin University"
        },
        {
            "ochanomizu_university": "Ochanomizu University"
        },
        {
            "showa_university": "Showa University"
        },
        {
            "kyoto_prefectural_university_of_medicine": "Kyoto Prefectural University of Medicine"
        },
        {
            "nagaoka_university_of_technology": "Nagaoka University of Technology"
        },
        {
            "jikei_university_school_of_medicine": "Jikei University School of Medicine"
        },
        {
            "sophia_university": "Sophia University"
        },
        {
            "chiba_university": "Chiba University"
        },
        {
            "hamamatsu_university_school_of_medicine": "Hamamatsu University School of Medicine"
        },
        {
            "ibaraki_university": "Ibaraki University"
        },
        {
            "toyohashi_university_of_technology": "Toyohashi University of Technology"
        },
        {
            "university_of_hyogo_(himeji_institute_of_technology)": "University of Hyogo (Himeji Institute of Technology)"
        },
        {
            "university_of_fukui": "University of Fukui"
        },
        {
            "shibaura_institute_of_technology": "Shibaura Institute of Technology"
        },
        {
            "kyoto_institute_of_technology": "Kyoto Institute of Technology"
        },
        {
            "fukushima_medical_university": "Fukushima Medical University"
        },
        {
            "chubu_university": "Chubu University"
        },
        {
            "aoyama_gakuin_university": "Aoyama Gakuin University"
        },
        {
            "tokyo_medical_university": "Tokyo Medical University"
        },
        {
            "oita_university": "Oita University"
        },
        {
            "kyoto_sangyo_university": "Kyoto Sangyo University"
        },
        {
            "meijo_university": "Meijo University"
        },
        {
            "akita_university": "Akita University"
        },
        {
            "hitotsubashi_university": "Hitotsubashi University"
        },
        {
            "ryukoku_university": "Ryukoku University"
        },
        {
            "sapporo_medical_university": "Sapporo Medical University"
        },
        {
            "kurume_university": "Kurume University"
        },
        {
            "the_graduate_university_for_advanced_studies": "The Graduate University for Advanced Studies"
        },
        {
            "iwate_university": "Iwate University"
        },
        {
            "kawasaki_university_of_medical_welfare_kawasaki_medical_school": "Kawasaki University of Medical Welfare Kawasaki Medical School"
        },
        {
            "osaka_institute_of_technology": "osaka institute of technology"
        },
        {
            "kansai_medical_university": "Kansai Medical University"
        },
        {
            "kanagawa_university": "Kanagawa University"
        },
        {
            "gakushuin_university": "Gakushuin University"
        },
        {
            "utsunomiya_university": "Utsunomiya University"
        },
        {
            "tokyo_university_of_marine_science_&_technology": "Tokyo University of Marine Science & Technology"
        },
        {
            "kochi_university_of_technology": "Kochi University of Technology"
        },
        {
            "saitama_medical_university": "Saitama Medical University"
        },
        {
            "toyota_technological_institute": "Toyota Technological Institute"
        },
        {
            "konan_university": "Konan University"
        },
        {
            "muroran_institute_of_technology": "Muroran Institute of Technology"
        },
        {
            "shiga_university_of_medical_science": "Shiga University of Medical Science"
        },
        {
            "tokyo_university_of_agriculture": "Tokyo University of Agriculture"
        },
        {
            "tokyo_city_university": "Tokyo City University"
        },
        {
            "international_university_of_health_and_welfare": "International University of Health and Welfare"
        },
        {
            "okayama_university_of_science": "Okayama University of Science"
        },
        {
            "st_marianna_university_school_of_medicine": "St Marianna University School of Medicine"
        },
        {
            "tokyo_denki_university": "Tokyo Denki University"
        },
        {
            "nara_medical_university": "Nara Medical University"
        },
        {
            "fukushima_university": "Fukushima University"
        },
        {
            "wakayama_medical_college": "Wakayama Medical College"
        },
        {
            "aichi_medical_university": "Aichi Medical University"
        },
        {
            "hiroshima_institute_of_technology": "Hiroshima Institute of Technology"
        },
        {
            "wakayama_university": "Wakayama University"
        },
        {
            "nara_women's_university": "Nara Women's University"
        },
        {
            "asahikawa_medical_university": "Asahikawa Medical University"
        },
        {
            "tokyo_university_of_technology": "Tokyo University of Technology"
        },
        {
            "fukuoka_institute_of_technology": "Fukuoka Institute of Technology"
        },
        {
            "national_graduate_institute_for_policy_studies": "National Graduate Institute for Policy Studies"
        },
        {
            "chiba_institute_of_technology": "Chiba Institute of Technology"
        },
        {
            "kogakuin_university": "Kogakuin University"
        },
        {
            "kyorin_university": "Kyorin University"
        },
        {
            "international_christian_university": "International Christian University"
        },
        {
            "hyogo_college_of_medicine": "Hyogo College of Medicine"
        },
        {
            "university_of_occupational_&_environmental_health_japan": "University of Occupational & Environmental Health Japan"
        },
        {
            "kitakyushu_university": "Kitakyushu University"
        },
        {
            "seikei_university": "Seikei University"
        },
        {
            "kanazawa_institute_of_technology": "Kanazawa Institute of Technology"
        },
        {
            "soka_university": "Soka University"
        },
        {
            "ritsumeikan_asia_pacific_university": "Ritsumeikan Asia Pacific University"
        },
        {
            "toyo_university": "Toyo University"
        },
        {
            "nagasaki_institute_of_applied_science": "Nagasaki Institute of Applied Science"
        },
        {
            "tokyo_dental_college": "Tokyo Dental College"
        },
        {
            "dokkyo_university_school_of_medicine": "Dokkyo University School of Medicine"
        },
        {
            "obihiro_university_of_agriculture_and_veterinary_medicine": "Obihiro University of Agriculture and Veterinary Medicine"
        },
        {
            "iwate_medical_university": "Iwate Medical University"
        },
        {
            "iwate_prefectural_university": "Iwate Prefectural University"
        },
        {
            "toyama_prefectural_university": "Toyama Prefectural University"
        },
        {
            "hiroshima_city_university": "Hiroshima City University"
        },
        {
            "tokushima_bunri_university": "Tokushima Bunri University"
        },
        {
            "osaka_medical_college": "Osaka Medical College"
        },
        {
            "akita_prefectural_university": "Akita Prefectural University"
        },
        {
            "kanazawa_medical_university": "Kanazawa Medical University"
        },
        {
            "kitami_institute_of_technology": "Kitami Institute of Technology"
        },
        {
            "university_of_shiga_prefecture": "University of Shiga Prefecture"
        },
        {
            "tokyo_gakugei_university": "Tokyo Gakugei University"
        },
        {
            "tokyo_university_of_pharmacy_&_life_sciences": "Tokyo University of Pharmacy & Life Sciences"
        },
        {
            "ishikawa_prefectural_university": "Ishikawa Prefectural University"
        },
        {
            "tokyo_university_of_foreign_studies": "Tokyo University of Foreign Studies"
        },
        {
            "toin_university_of_yokohama": "Toin University of Yokohama"
        },
        {
            "open_university_of_japan_(university_of_the_air)": "Open University of Japan (University of the Air)"
        },
        {
            "aichi_gakuin_university": "Aichi Gakuin University"
        },
        {
            "miyagi_university_of_education": "Miyagi University of Education"
        },
        {
            "shiga_university": "Shiga University"
        },
        {
            "tohoku_medical_and_pharmaceutical_university": "Tohoku Medical and Pharmaceutical University"
        },
        {
            "asahi_university": "Asahi University"
        },
        {
            "nanzan_university": "Nanzan University"
        },
        {
            "nippon_dental_university": "Nippon Dental University"
        },
        {
            "future_university_hakodate": "Future University Hakodate"
        },
        {
            "kyoto_prefectural_university": "Kyoto Prefectural University"
        },
        {
            "kyushu_sangyo_university": "Kyushu Sangyo University"
        },
        {
            "osaka_kyoiku_university": "Osaka Kyoiku University"
        },
        {
            "senshu_university": "Senshu University"
        },
        {
            "health_sciences_university_of_hokkaido": "Health Sciences University of Hokkaido"
        },
        {
            "chukyo_university": "Chukyo University"
        },
        {
            "meiji_gakuin_university": "Meiji Gakuin University"
        },
        {
            "nihon_fukushi_university": "Nihon Fukushi University"
        },
        {
            "saitama_institute_of_technology": "Saitama Institute of Technology"
        },
        {
            "aichi_university_of_education": "Aichi University of Education"
        },
        {
            "tamagawa_university": "Tamagawa University"
        },
        {
            "japan_women's_university": "Japan Women's University"
        },
        {
            "tohoku_gakuin_university": "Tohoku Gakuin University"
        },
        {
            "aichi_institute_of_technology": "Aichi Institute of Technology"
        },
        {
            "hoshi_university": "Hoshi University"
        },
        {
            "osaka_sangyo_university": "Osaka Sangyo University"
        },
        {
            "mukogawa_women's_university": "Mukogawa Women's University"
        },
        {
            "sojo_university": "Sojo University"
        },
        {
            "azabu_university": "Azabu University"
        },
        {
            "kanto_gakuin_university": "Kanto Gakuin University"
        },
        {
            "rissho_university": "Rissho University"
        },
        {
            "setsunan_university": "Setsunan University"
        },
        {
            "fukui_prefectural_university": "Fukui Prefectural University"
        },
        {
            "rakuno_gakuen_university": "Rakuno Gakuen University"
        },
        {
            "showa_college_of_pharmaceutical_sciences": "Showa College of Pharmaceutical Sciences"
        },
        {
            "kobe_gakuin_university": "Kobe Gakuin University"
        },
        {
            "gifu_pharmaceutical_university": "Gifu Pharmaceutical University"
        },
        {
            "tokyo_national_university_of_fine_arts_and_music": "Tokyo National University of Fine Arts and Music"
        },
        {
            "tokyo_institute_of_polytechnics": "Tokyo Institute of Polytechnics"
        },
        {
            "prefectural_university_of_hiroshima": "Prefectural University of Hiroshima"
        },
        {
            "kyoto_pharmaceutical_university": "Kyoto Pharmaceutical University"
        },
        {
            "meikai_university": "Meikai University"
        },
        {
            "st_luke's_international_university": "St Luke's International University"
        },
        {
            "nagoya_university_of_commerce_and_business": "Nagoya University of Commerce and Business"
        },
        {
            "meiji_pharmaceutical_university": "Meiji Pharmaceutical University"
        },
        {
            "tokyo_international_university": "Tokyo International University"
        },
        {
            "international_university_of_japan": "International University of Japan"
        },
        {
            "meisei_university": "Meisei University"
        },
        {
            "hokkaido_university_of_education": "Hokkaido University of Education"
        },
        {
            "josai_international_university": "Josai International University"
        },
        {
            "hiroshima_international_university": "Hiroshima International University"
        },
        {
            "fukuoka_dental_college": "Fukuoka Dental College"
        },
        {
            "maebashi_institute_of_technology": "Maebashi Institute of Technology"
        },
        {
            "osaka_electro-communication_university": "Osaka Electro-Communication University"
        },
        {
            "niigata_university_of_health_and_welfare": "Niigata University of Health and Welfare"
        },
        {
            "josai_university": "Josai University"
        },
        {
            "prefectural_university_of_kumamoto": "Prefectural University of Kumamoto"
        },
        {
            "university_of_shizuoka": "University of Shizuoka"
        },
        {
            "nara_university_of_education": "Nara University Of Education"
        },
        {
            "kokushikan_university": "Kokushikan University"
        },
        {
            "yasuda_women's_university": "Yasuda Women's University"
        },
        {
            "matsumoto_dental_university": "Matsumoto Dental University"
        },
        {
            "tokyo_keizai_university": "Tokyo Keizai University"
        },
        {
            "tohoku_fukushi_university": "Tohoku Fukushi University"
        },
        {
            "nippon_sport_science_university": "Nippon Sport Science University"
        },
        {
            "tokyo_university_of_information_sciences": "Tokyo University of Information Sciences"
        },
        {
            "nippon_institute_of_technology": "Nippon Institute of Technology"
        },
        {
            "osaka_university_of_pharmaceutical_sciences": "Osaka University of Pharmaceutical Sciences"
        },
        {
            "kobe_pharmaceutical_university": "Kobe Pharmaceutical University"
        },
        {
            "nippon_veterinary_and_life_science_university": "Nippon Veterinary and Life Science University"
        },
        {
            "daito_bunka_university": "Daito Bunka University"
        },
        {
            "tsurumi_university": "Tsurumi University"
        },
        {
            "okayama_prefectural_university": "Okayama Prefectural University"
        },
        {
            "musashino_university": "Musashino University"
        },
        {
            "naruto_university_of_education": "Naruto University of Education"
        },
        {
            "kinjo_gakuin_university": "Kinjo Gakuin University"
        },
        {
            "matsuyama_university": "Matsuyama University"
        },
        {
            "teikyo_heisei_university": "Teikyo Heisei University"
        },
        {
            "tohoku_institute_of_technology": "Tohoku Institute of Technology"
        },
        {
            "niigata_institute_of_technology": "Niigata Institute of Technology"
        },
        {
            "osaka_dental_university": "Osaka Dental University"
        },
        {
            "numazu_national_college_of_technology": "Numazu National College of Technology"
        },
        {
            "otsuma_women's_university": "Otsuma Women's University"
        },
        {
            "kyoto_women's_university": "Kyoto Women's University"
        },
        {
            "seijo_university": "Seijo University"
        },
        {
            "seinan_gakuin_university": "Seinan Gakuin University"
        },
        {
            "osaka_ohtani_university": "Osaka Ohtani University"
        },
        {
            "aichi_prefectural_university": "Aichi Prefectural University"
        },
        {
            "suzuka_university_of_medical_science": "Suzuka University of Medical Science"
        },
        {
            "komazawa_university": "Komazawa University"
        },
        {
            "hokkaido_university_of_science": "Hokkaido University of Science"
        },
        {
            "konan_women's_university": "Konan Women's University"
        },
        {
            "miyagi_university": "Miyagi University"
        },
        {
            "tokyo_kasei_university": "Tokyo Kasei University"
        },
        {
            "aichi_shukutoku_university": "Aichi Shukutoku University"
        },
        {
            "tokyo_woman's_christian_university": "Tokyo Woman's Christian University"
        },
        {
            "niigata_university_of_pharmacy_and_applied_life_sciences": "Niigata University of Pharmacy and Applied Life Sciences"
        },
        {
            "kansai_gaidai_university": "Kansai Gaidai University"
        },
        {
            "bunkyo_university": "Bunkyo University"
        },
        {
            "hokkaido_information_university": "Hokkaido Information University"
        },
        {
            "kyoritsu_women's_university": "Kyoritsu Women's University"
        },
        {
            "kanagawa_institute_of_technology": "Kanagawa Institute of Technology"
        },
        {
            "aichi_university": "Aichi University"
        },
        {
            "hyogo_university_of_teacher_education": "Hyogo University of Teacher Education"
        },
        {
            "kobe_design_university": "Kobe Design University"
        },
        {
            "university_of_niigata_prefecture": "University of Niigata Prefecture"
        },
        {
            "kansai_university_of_social_welfare": "Kansai University of Social Welfare"
        },
        {
            "hiroshima_shudo_university": "Hiroshima Shudo University"
        },
        {
            "shizuoka_institute_of_science_&_technology": "Shizuoka Institute of Science & Technology"
        },
        {
            "kagawa_nutrition_university": "Kagawa Nutrition University"
        },
        {
            "shujitsu_university": "Shujitsu University"
        },
        {
            "reitaku_university": "Reitaku University"
        },
        {
            "hokkai_gakuen_university": "Hokkai Gakuen University"
        },
        {
            "otaru_university_of_commerce": "Otaru University of Commerce"
        },
        {
            "tokoha_gakuen_university": "Tokoha Gakuen University"
        },
        {
            "takushoku_university": "Takushoku University"
        },
        {
            "metropolitan_industrial_technology_college_of_technology": "Metropolitan Industrial Technology College of Technology"
        },
        {
            "kanagawa_dental_university": "Kanagawa Dental University"
        },
        {
            "kanda_university_of_international_studies": "Kanda University of International Studies"
        },
        {
            "fukui_university_of_technology": "Fukui University of Technology"
        },
        {
            "tama_art_university": "Tama Art University"
        },
        {
            "musashi_university": "Musashi University"
        },
        {
            "shonan_institute_of_technology": "Shonan Institute of Technology"
        },
        {
            "taisho_university": "Taisho University"
        },
        {
            "himeji_dokkyo_university": "Himeji Dokkyo University"
        },
        {
            "musashino_art_university": "Musashino Art University"
        },
        {
            "nagaoka_national_college_of_technology": "Nagaoka National College of Technology"
        },
        {
            "joetsu_university_of_education": "Joetsu University of Education"
        },
        {
            "okinawa_international_university": "Okinawa International University"
        },
        {
            "chiba_university_of_commerce": "Chiba University of Commerce"
        },
        {
            "nagasaki_international_university": "Nagasaki International University"
        },
        {
            "sagami_women's_university": "Sagami Women's University"
        },
        {
            "toyama_national_college_of_technology": "Toyama National College of Technology"
        },
        {
            "tenri_university": "Tenri University"
        },
        {
            "fukuoka_university_of_education": "Fukuoka University of Education"
        },
        {
            "fukuoka_women's_university": "Fukuoka Women's University"
        },
        {
            "bunkyo_gakuin_university_(bunkyo_women's_college)": "Bunkyo Gakuin University (Bunkyo Women's College)"
        },
        {
            "chubu_gakuin_university": "Chubu Gakuin University"
        },
        {
            "nihon_pharmaceutical_university": "Nihon Pharmaceutical University"
        },
        {
            "bukkyo_university": "Bukkyo University"
        },
        {
            "ryutsu_keizai_university": "Ryutsu Keizai University"
        },
        {
            "yamanashi_gakuin_university": "Yamanashi Gakuin University"
        },
        {
            "tsukuba_university_of_technology": "Tsukuba University of Technology"
        },
        {
            "ibaraki_prefectural_university_of_health_sciences": "Ibaraki Prefectural University of Health Sciences"
        },
        {
            "sapporo_gakuin_university": "Sapporo Gakuin University"
        },
        {
            "showa_women's_university": "Showa Women's University"
        },
        {
            "sugiyama_jogakuen_university": "Sugiyama Jogakuen University"
        },
        {
            "osaka_gakuin_university": "Osaka Gakuin University"
        },
        {
            "kyoto_university_of_foreign_studies": "Kyoto University of Foreign Studies"
        },
        {
            "seitoku_university": "Seitoku University"
        },
        {
            "fukuyama_university": "Fukuyama University"
        },
        {
            "nagano_national_college_of_technology": "Nagano National College of Technology"
        },
        {
            "nishi_kyushu_university": "Nishi Kyushu University"
        },
        {
            "niigata_university_of_international_and_information_studies": "Niigata University of International and Information Studies"
        },
        {
            "hokuriku_university": "Hokuriku University"
        },
        {
            "gifu_shotoku_gakuen_university": "Gifu Shotoku Gakuen University"
        },
        {
            "surugadai_university_&_junior_college": "Surugadai University & Junior College"
        },
        {
            "kibi_international_university": "Kibi International University"
        },
        {
            "kokugakuin_university": "Kokugakuin University"
        },
        {
            "temple_university_japan": "Temple University Japan"
        },
        {
            "national_institute_of_health_sciences": "National Institute of Health Sciences"
        },
        {
            "ohu_university": "Ohu University"
        },
        {
            "nagoya_gakuin_university": "Nagoya Gakuin University"
        },
        {
            "kyoto_tachibana_women's_university": "Kyoto Tachibana Women's University"
        },
        {
            "dokkyo_university": "Dokkyo University"
        },
        {
            "oita_university_of_nursing_and_health_sciences": "Oita University of Nursing and Health Sciences"
        },
        {
            "aino_university": "Aino University"
        },
        {
            "shukutoku_university": "Shukutoku University"
        },
        {
            "osaka_international_university": "Osaka International University"
        },
        {
            "osaka_university_of_economics": "Osaka University of Economics"
        },
        {
            "yamaguchi_prefectural_university": "Yamaguchi Prefectural University"
        },
        {
            "teikyo_university_of_science_&_technology": "Teikyo University of Science & Technology"
        },
        {
            "kyoto_seika_university": "Kyoto Seika University"
        },
        {
            "tezukayama_university": "Tezukayama University"
        },
        {
            "tokiwa_university": "Tokiwa University"
        },
        {
            "hokusei_gakuen_university": "Hokusei Gakuen University"
        },
        {
            "jissen_women's_university": "Jissen Women's University"
        },
        {
            "sapporo_city_university": "Sapporo City University"
        },
        {
            "gifu_national_college_of_technology": "Gifu National College of Technology"
        },
        {
            "sanno_university_and_graduate_college": "Sanno University and Graduate College"
        },
        {
            "nakamura_gakuen_university": "Nakamura Gakuen University"
        },
        {
            "kurume_institute_of_technology": "Kurume Institute of Technology"
        },
        {
            "kyoto_university_of_art_and_design": "Kyoto University of Art and Design"
        },
        {
            "nippon_bunri_university": "Nippon Bunri University"
        },
        {
            "daido_institute_of_technology": "Daido Institute of Technology"
        },
        {
            "advanced_institute_of_industrial_technology": "Advanced Institute of Industrial Technology"
        },
        {
            "wako_university": "Wako University"
        },
        {
            "national_fisheries_university": "National Fisheries University"
        },
        {
            "osaka_shoin_women's_college": "Osaka Shoin Women's College"
        },
        {
            "j_f_oberlin_university": "J F Oberlin University"
        },
        {
            "mejiro_university": "Mejiro University"
        },
        {
            "kurashiki_university_of_science_and_the_arts": "Kurashiki University of Science and the Arts"
        },
        {
            "aomori_university": "Aomori University"
        },
        {
            "institute_of_advanced_media_arts_and_sciences": "Institute of Advanced Media Arts and Sciences"
        },
        {
            "kyoto_college_of_graduate_studies_for_informatics": "Kyoto College of Graduate Studies for Informatics"
        },
        {
            "kyoto_koka_women's_college": "Kyoto Koka Women's College"
        },
        {
            "bunka_gakuen": "Bunka Gakuen"
        },
        {
            "saitama_prefectural_university": "Saitama Prefectural University"
        },
        {
            "minami_kyushu_university": "Minami Kyushu University"
        },
        {
            "kyushu_kyoritsu_university": "Kyushu Kyoritsu University"
        },
        {
            "akita_international_university": "Akita International University"
        },
        {
            "tohoku_university_of_art_and_design": "Tohoku University of Art and Design"
        },
        {
            "kobe_city_university_of_foreign_studies": "Kobe City University of Foreign Studies"
        },
        {
            "kyoto_city_university_of_arts": "Kyoto City University of Arts"
        },
        {
            "beppu_university": "Beppu University"
        },
        {
            "ibaraki_christian_university": "Ibaraki Christian University"
        },
        {
            "kamakura_women's_college": "Kamakura Women's College"
        },
        {
            "national_institute_of_technology_kochi_college_/_kochi_industry_high_school_college": "National Institute of Technology Kochi College / Kochi Industry High School College"
        },
        {
            "nagoya_women's_university": "Nagoya Women's University"
        },
        {
            "koshien_university": "Koshien University"
        },
        {
            "otani_university": "Otani University"
        },
        {
            "gunma_prefectural_women's_university": "Gunma Prefectural Women's University"
        },
        {
            "kyushu_university_of_health_and_welfare": "Kyushu University of Health and Welfare"
        },
        {
            "globis_university_&_graduate_school_of_management": "GLOBIS University & Graduate School of Management"
        },
        {
            "miyagi_gakuin_women's_college": "Miyagi Gakuin Women's College"
        },
        {
            "osaka_university_of_arts": "Osaka University of Arts"
        },
        {
            "kyoto_bunkyo_university": "Kyoto Bunkyo University"
        },
        {
            "sakushin_gakuin_university": "Sakushin Gakuin University"
        },
        {
            "globis_": "Globis "
        },
        {
            "osaka_college_of_music": "Osaka College of Music"
        },
        {
            "nagoya_university_of_foreign_studies": "Nagoya University of Foreign Studies"
        },
        {
            "kumamoto_national_college_of_technology_(incl_yatsushiro)": "Kumamoto National College of Technology (incl Yatsushiro)"
        },
        {
            "osaka_university_of_health_and_sport_sciences": "Osaka University of Health and Sport Sciences"
        },
        {
            "asia_university": "Asia University"
        },
        {
            "digital_hollywood_university": "Digital Hollywood University"
        },
        {
            "kyoto_notre_dame_university": "Kyoto Notre Dame University"
        },
        {
            "tsuda_college": "Tsuda College"
        },
        {
            "otemon_gakuin_university": "Otemon Gakuin University"
        },
        {
            "osaka_university_of_commerce": "Osaka University of Commerce"
        },
        {
            "joshibi_university_of_art_and_design": "Joshibi University of Art and Design"
        },
        {
            "aichi_university_of_technology": "Aichi University of Technology"
        },
        {
            "doshisha_women's_college_of_liberal_arts": "Doshisha Women's College of Liberal Arts"
        },
        {
            "daiichi_college_of_pharmaceutical_sciences": "Daiichi College of Pharmaceutical Sciences"
        },
        {
            "tokyo_university_and_graduate_school_of_social_welfare": "Tokyo University and Graduate School of Social Welfare"
        },
        {
            "wayo_women's_university": "Wayo Women's University"
        },
        {
            "kumamoto_gakuen_university": "Kumamoto Gakuen University"
        },
        {
            "tama_university": "Tama University"
        },
        {
            "hanazono_university": "Hanazono University"
        },
        {
            "tokyo_zokei_university": "Tokyo Zokei University"
        },
        {
            "wakkanai_hokusei_gakuen_university": "Wakkanai Hokusei Gakuen University"
        },
        {
            "national_institute_of_fitness_and_sports_in_kanoya": "National Institute of Fitness and Sports in Kanoya"
        },
        {
            "hyogo_university": "Hyogo University"
        },
        {
            "aichi_gakusen_university": "Aichi Gakusen University"
        },
        {
            "iryo_sosei_university": "Iryo Sosei University"
        },
        {
            "momoyama_gakuin_university_(st_andrew's_university)": "Momoyama Gakuin University (St Andrew's University)"
        },
        {
            "edogawa_university": "Edogawa University"
        },
        {
            "toyo_gakuen_university": "Toyo Gakuen University"
        },
        {
            "kitakyushu_national_college_of_technology": "Kitakyushu National College of Technology"
        },
        {
            "okayama_shoka_university": "Okayama Shoka University"
        },
        {
            "fukuoka_prefectural_university": "Fukuoka Prefectural University"
        },
        {
            "hannan_university": "Hannan University"
        },
        {
            "tohoku_bunka_gakuen_university": "Tohoku Bunka Gakuen University"
        },
        {
            "bunka_fashion_college": "Bunka Fashion College"
        },
        {
            "sapporo_university": "Sapporo University"
        },
        {
            "otemae_university": "Otemae University"
        },
        {
            "toyo_eiwa_university": "Toyo Eiwa University"
        },
        {
            "senzoku_gakuen_college": "Senzoku Gakuen College"
        },
        {
            "nagasaki_university_of_foreign_studies": "Nagasaki University of Foreign Studies"
        },
        {
            "ube_national_college_of_technology": "Ube National College of Technology"
        },
        {
            "university_of_the_sacred_heart": "University of the Sacred Heart"
        },
        {
            "niigata_seiryo_university": "Niigata Seiryo University"
        },
        {
            "nara_university": "Nara University"
        },
        {
            "atomi_gakuen": "Atomi Gakuen"
        },
        {
            "siebold_university_of_nagasaki": "Siebold University of Nagasaki"
        },
        {
            "keisen_jogakuen_university": "Keisen Jogakuen University"
        },
        {
            "shikoku_university": "Shikoku University"
        },
        {
            "kanazawa_college_of_art": "Kanazawa College of Art"
        },
        {
            "shitennoji_international_buddhist_university": "Shitennoji International Buddhist University"
        },
        {
            "shizuoka_university_of_art_and_culture": "Shizuoka University of Art and Culture"
        },
        {
            "kinjo_university": "Kinjo University"
        },
        {
            "sanyo_gakuen_university": "Sanyo Gakuen University"
        },
        {
            "mode_gakuen": "Mode Gakuen"
        },
        {
            "university_of_shimane": "University of Shimane"
        },
        {
            "university_of_marketing_and_distribution_sciences": "University of Marketing and Distribution Sciences"
        },
        {
            "ferris_university": "Ferris University"
        },
        {
            "fukuoka_jo_gakuin_university": "Fukuoka Jo Gakuin University"
        },
        {
            "ibaraki_national_college_of_technology": "Ibaraki National College of Technology"
        },
        {
            "kobe_college": "Kobe College"
        },
        {
            "nagano_university": "Nagano University"
        },
        {
            "sendai_national_college_of_technology": "Sendai National College of Technology"
        },
        {
            "oita_national_college_of_technology": "Oita National College of Technology"
        },
        {
            "osaka_university_of_economics_and_law": "Osaka University of Economics and Law"
        },
        {
            "kyushu_women's_university": "Kyushu Women's University"
        },
        {
            "komazawa_women's_university": "Komazawa Women's University"
        },
        {
            "musashino_academia_musicae": "Musashino Academia Musicae"
        },
        {
            "nagoya_university_of_arts": "Nagoya University of Arts"
        },
        {
            "yamanashi_prefectural_university": "Yamanashi Prefectural University"
        },
        {
            "niigata_university_of_management": "Niigata University of Management"
        },
        {
            "jumonji_university": "Jumonji University"
        },
        {
            "fukushima_national_college_of_technology": "Fukushima National College of Technology"
        },
        {
            "nagaoka_institute_of_design": "Nagaoka Institute of Design"
        },
        {
            "hachinohe_institute_of_technology": "Hachinohe Institute of Technology"
        },
        {
            "yokohama_college_of_pharmacy": "Yokohama College of Pharmacy"
        },
        {
            "nishogakusha_university": "Nishogakusha University"
        },
        {
            "kagawa_university_faculty_of_medicine": "Kagawa University Faculty of Medicine"
        },
        {
            "meiji_university_of_oriental_medicine": "Meiji University of Oriental Medicine"
        },
        {
            "kyushu_dental_college": "Kyushu Dental College"
        },
        {
            "seisen_university_(tokyo)": "Seisen University (Tokyo)"
        },
        {
            "ishikawa_national_college_of_technology": "Ishikawa National College of Technology"
        },
        {
            "toyota_national_college_of_technology": "Toyota National College of Technology"
        },
        {
            "yantai_engineering_&_technology_college": "Yantai Engineering & Technology College"
        },
        {
            "cyber_university": "Cyber University"
        },
        {
            "kogakkan_university": "Kogakkan University"
        },
        {
            "sonoda_women's_university": "Sonoda Women's University"
        },
        {
            "kobe_shoin_women's_university": "Kobe Shoin Women's University"
        },
        {
            "aichi_prefectural_university_of_fine_arts_and_music": "Aichi Prefectural University of Fine Arts and Music"
        },
        {
            "toho_gakuen_school_of_music": "Toho Gakuen School of Music"
        },
        {
            "okinawa_prefectural_university_of_arts": "Okinawa Prefectural University of Arts"
        },
        {
            "meio_university": "Meio University"
        },
        {
            "institute_of_technologists": "Institute of Technologists"
        },
        {
            "chiba_keizai_university": "Chiba Keizai University"
        },
        {
            "oyama_national_college_of_technology": "Oyama National College of Technology"
        },
        {
            "tezukayama_gakuin_university": "Tezukayama Gakuin University"
        },
        {
            "tokyo_college_of_music": "Tokyo College of Music"
        },
        {
            "chuogakuin_university": "Chuogakuin University"
        },
        {
            "osaka_seikei_university": "Osaka Seikei University"
        },
        {
            "shizuoka_sangyo_university": "Shizuoka Sangyo University"
        },
        {
            "takarazuka_university": "Takarazuka University"
        },
        {
            "kaetsu_university": "Kaetsu University"
        },
        {
            "baika_women's_university": "Baika Women's University"
        },
        {
            "keiai_university": "Keiai University"
        },
        {
            "seinan_university_of_art_and_design": "Seinan University of Art and Design"
        },
        {
            "tokyo_kasei_gakuin_university": "Tokyo Kasei Gakuin University"
        },
        {
            "kobe_women's_university": "Kobe Women's University"
        },
        {
            "kanazawa_gakuin_university": "Kanazawa Gakuin University"
        },
        {
            "okinawa_university": "Okinawa University"
        },
        {
            "koyasan_university": "Koyasan University"
        },
        {
            "hiroshima_jogakuin_university": "Hiroshima Jogakuin University"
        },
        {
            "tokuyama_college_of_technology": "Tokuyama College of Technology"
        },
        {
            "tottori_university_of_environmental_studies": "Tottori University of Environmental Studies"
        },
        {
            "shirayuri_college": "Shirayuri College"
        },
        {
            "tokai_gakuin_university": "Tokai Gakuin University"
        },
        {
            "kyoai_gakuen_university": "Kyoai Gakuen University"
        },
        {
            "kansai_university_of_international_studies": "Kansai University of International Studies"
        },
        {
            "ashikaga_institute_of_technology": "Ashikaga Institute of Technology"
        },
        {
            "fuji_women's_university": "Fuji Women's University"
        },
        {
            "hiroshima-kokusai_gakuin_university": "Hiroshima-Kokusai Gakuin University"
        },
        {
            "uekusa_gakuen_university_&_junior_college": "Uekusa Gakuen University & Junior College"
        },
        {
            "miyazaki_municipal_university": "Miyazaki Municipal University"
        },
        {
            "yokkaichi_university": "Yokkaichi University"
        },
        {
            "seisa_dohto_university": "Seisa Dohto University"
        },
        {
            "international_university_of_kagoshima": "International University of Kagoshima"
        },
        {
            "future_university_of_tokyo": "Future University of Tokyo"
        },
        {
            "chitose_institute_of_science_&_technology": "Chitose Institute of Science & Technology"
        },
        {
            "kyoto_saga_university_of_arts": "Kyoto Saga University of Arts"
        },
        {
            "heian_jogakuin_st_agnes_university": "Heian Jogakuin St Agnes University"
        },
        {
            "tokai_gakuen_university": "Tokai Gakuen University"
        },
        {
            "bunka_gakuen_university": "Bunka Gakuen University"
        },
        {
            "university_of_kochi": "University of Kochi"
        },
        {
            "nagoya_bunri_university": "Nagoya Bunri University"
        },
        {
            "shobi_university": "Shobi University"
        },
        {
            "toyama_university_of_international_studies": "Toyama University of International Studies"
        },
        {
            "university_of_east_asia": "University of East Asia"
        },
        {
            "seigakuin_university": "Seigakuin University"
        },
        {
            "kanazawa_seiryo_university": "Kanazawa Seiryo University"
        },
        {
            "chiba_institute_of_science": "Chiba Institute of Science"
        },
        {
            "shimonoseki_city_university": "Shimonoseki City University"
        },
        {
            "japan_university_of_economics_(fukuoka_university_of_economics)": "Japan University of Economics (Fukuoka University of Economics)"
        },
        {
            "kyushu_international_university": "Kyushu International University"
        },
        {
            "kwassui_women's_college": "Kwassui Women's College"
        },
        {
            "toho_college_of_music": "Toho College of Music"
        },
        {
            "international_pacific_university_japan": "International Pacific University Japan"
        },
        {
            "kio_university": "Kio University"
        },
        {
            "nagoya_zokei_university_of_art_&_design": "Nagoya Zokei University of Art & Design"
        },
        {
            "japan_academy_of_moving_images": "Japan Academy of Moving Images"
        },
        {
            "kansai_university_of_health_sciences": "Kansai University of Health Sciences"
        },
        {
            "bunri_sato_gakuen_university_of_hospitality": "Bunri Sato Gakuen University of Hospitality"
        },
        {
            "asahikawa_university": "Asahikawa University"
        },
        {
            "gakushuin_women's_university": "Gakushuin Women's University"
        },
        {
            "yokohama_college_of_commerce": "Yokohama College of Commerce"
        },
        {
            "hiroshima_bunkyo_women's_college": "Hiroshima Bunkyo Women's College"
        },
        {
            "hokkaido_bunkyo_university": "Hokkaido Bunkyo University"
        },
        {
            "tokuyama_university": "Tokuyama University"
        },
        {
            "matsumoto_university": "Matsumoto University"
        },
        {
            "hijiyama_university": "Hijiyama University"
        },
        {
            "onomichi_city_university": "Onomichi City University"
        },
        {
            "fukui_national_college_of_technology": "Fukui National College of Technology"
        },
        {
            "keiwa_college": "Keiwa College"
        },
        {
            "nagahama_institute_of_bio-science_&_technology": "Nagahama Institute of Bio-Science & Technology"
        },
        {
            "nishinippon_institute_of_technology": "Nishinippon Institute of Technology"
        },
        {
            "hachinohe_university": "Hachinohe University"
        },
        {
            "aomori_university_of_health_and_welfare": "Aomori University of Health and Welfare"
        },
        {
            "toyohashi_sozo_college": "Toyohashi Sozo College"
        },
        {
            "hokuriku_gakuin_university": "Hokuriku Gakuin University"
        },
        {
            "japanese_red_cross_college_of_nursing": "Japanese Red Cross College of Nursing"
        },
        {
            "nara_national_college_of_technology": "Nara National College of Technology"
        },
        {
            "japan_college_of_social_work": "Japan College of Social Work"
        },
        {
            "mimasaka_university": "Mimasaka University"
        },
        {
            "doho_university": "Doho University"
        },
        {
            "tokyo_healthcare_university": "Tokyo Healthcare University"
        },
        {
            "kobe_international_university": "Kobe International University"
        },
        {
            "shiraume_gakuen_university": "Shiraume Gakuen University"
        },
        {
            "aomori_public_university": "Aomori Public University"
        },
        {
            "baiko_gakuin_university": "Baiko Gakuin University"
        },
        {
            "aomori_chuo_gakuin_university": "Aomori Chuo Gakuin University"
        },
        {
            "hokusho_university": "Hokusho University"
        },
        {
            "tokyo_junshin_women's_college": "Tokyo Junshin Women's College"
        },
        {
            "tokyo_fuji_university": "Tokyo Fuji University"
        },
        {
            "tokyo_seitoku_university": "Tokyo Seitoku University"
        },
        {
            "fukuyama_heisei_university": "Fukuyama Heisei University"
        },
        {
            "tohoku_university_of_community_service_and_science": "Tohoku University of Community Service and Science"
        },
        {
            "maizuru_national_college_of_technology": "Maizuru National College of Technology"
        },
        {
            "takachiho_university": "Takachiho University"
        },
        {
            "takasaki_university_of_health_and_welfare": "Takasaki University of Health and Welfare"
        },
        {
            "hakodate_university": "Hakodate University"
        },
        {
            "kenichi_ohmae_graduate_school_of_business": "Kenichi Ohmae Graduate School of Business"
        },
        {
            "kushiro_public_university_of_economics": "Kushiro Public University of Economics"
        },
        {
            "kanto_gakuen_university": "Kanto Gakuen University"
        },
        {
            "ueno_gakuen_university": "Ueno Gakuen University"
        },
        {
            "tsukuba_gakuin_university": "Tsukuba Gakuin University"
        },
        {
            "elisabeth_university_of_music": "Elisabeth University of Music"
        },
        {
            "takamatsu_university_&_junior_college": "Takamatsu University & Junior College"
        },
        {
            "hollywood_university_of_beauty_&_fashion": "Hollywood University of Beauty & Fashion"
        },
        {
            "osaka_jogakuin_college": "Osaka Jogakuin College"
        },
        {
            "tokyo_women's_college_of_physical_education": "Tokyo Women's College of Physical Education"
        },
        {
            "graduate_school_of_project_design": "Graduate School of Project Design"
        },
        {
            "shikoku_gakuin_university": "Shikoku Gakuin University"
        },
        {
            "kushiro_national_college_of_technology": "Kushiro National College of Technology"
        },
        {
            "akita_university_of_art": "Akita University of Art"
        },
        {
            "tokyo_national_college_of_technology": "Tokyo National College of Technology"
        },
        {
            "shumei_university": "Shumei University"
        },
        {
            "aichi_sangyo_university": "Aichi Sangyo University"
        },
        {
            "japan_lutheran_college": "Japan Lutheran College"
        },
        {
            "tsuyama_national_college_of_technology": "Tsuyama National College of Technology"
        },
        {
            "shijonawate_gakuen_university": "Shijonawate Gakuen University"
        },
        {
            "niigata_sangyo_university": "Niigata Sangyo University"
        },
        {
            "yokohama_college_of_art_and_design": "Yokohama College of Art and Design"
        },
        {
            "kanagawa_university_of_human_services": "Kanagawa University of Human Services"
        },
        {
            "shokei_college": "Shokei College"
        },
        {
            "seirei_christopher_university_(college_of_nursing)": "Seirei Christopher University (College of Nursing)"
        },
        {
            "bunsei_university_of_art": "Bunsei University of Art"
        },
        {
            "shokei_gakuin_university": "Shokei Gakuin University"
        },
        {
            "jobu_university": "Jobu University"
        },
        {
            "ashiya_university": "Ashiya University"
        },
        {
            "akashi_national_college_of_technology": "Akashi National College of Technology"
        },
        {
            "kobe_yamate_university": "Kobe Yamate University"
        },
        {
            "institute_of_information_security": "Institute of Information Security"
        },
        {
            "gunma_national_college_of_technology": "Gunma National College of Technology"
        },
        {
            "seisa_university": "Seisa University"
        },
        {
            "heisei_international_university": "Heisei International University"
        },
        {
            "st_catherine_women's_college": "St Catherine Women's College"
        },
        {
            "nagasaki_junshin_catholic_university": "Nagasaki Junshin Catholic University"
        },
        {
            "hyogo_university_of_health_sciences": "Hyogo University of Health Sciences"
        },
        {
            "ichinoseki_national_college_of_technology": "Ichinoseki National College of Technology"
        },
        {
            "yamagata_prefectural_university_of_health_sciences": "Yamagata Prefectural University of Health Sciences"
        },
        {
            "kisarazu_national_college_of_technology": "Kisarazu National College of Technology"
        },
        {
            "shigakukan_university": "Shigakukan University"
        },
        {
            "sapporo_otani_university": "Sapporo Otani University"
        },
        {
            "hakodate_national_college_of_technology": "Hakodate National College of Technology"
        },
        {
            "tenshi_college_(tenshi_women's_junior_college)": "Tenshi College (Tenshi Women's Junior College)"
        },
        {
            "daiichi_institute_of_technology": "Daiichi Institute of Technology"
        },
        {
            "university_of_human_environments": "University of Human Environments"
        },
        {
            "higashi_nippon_international_university_(iwaki_junior_college)": "Higashi Nippon International University (Iwaki Junior College)"
        },
        {
            "ishikawa_prefectural_nursing_university": "Ishikawa Prefectural Nursing University"
        },
        {
            "oita_prefectural_college_of_arts_&_culture": "Oita Prefectural College of Arts & Culture"
        },
        {
            "hirosaki_gakuin_university": "Hirosaki Gakuin University"
        },
        {
            "morioka_university": "Morioka University"
        },
        {
            "shigakkan_university": "Shigakkan University"
        },
        {
            "seinan_jo_gakuin_university": "Seinan Jo Gakuin University"
        },
        {
            "chikushi_jogakuen_university": "Chikushi Jogakuen University"
        },
        {
            "tsukuba_international_university": "Tsukuba International University"
        },
        {
            "matsue_college_of_technology": "Matsue College of Technology"
        },
        {
            "shuchiin_university": "Shuchiin University"
        },
        {
            "aoyama_gakuin": "Aoyama Gakuin"
        },
        {
            "nayoro_city_university": "Nayoro City University"
        },
        {
            "seibi_gakuen_college": "Seibi Gakuen College"
        },
        {
            "shizuoka_eiwa_gakuin_university_and_college": "Shizuoka Eiwa Gakuin University and College"
        },
        {
            "saitama_gakuen_university": "Saitama Gakuen University"
        },
        {
            "sendai_shirayuri_women's_college": "Sendai Shirayuri Women's College"
        },
        {
            "seijoh_university": "Seijoh University"
        },
        {
            "shoin_university": "Shoin University"
        },
        {
            "niihama_national_college_of_technology": "Niihama National College of Technology"
        },
        {
            "nagoya_sangyo_university": "Nagoya Sangyo University"
        },
        {
            "kobe_city_college_of_nursing": "Kobe City College of Nursing"
        },
        {
            "salesian_polytechnic": "Salesian Polytechnic"
        },
        {
            "yuge_national_college_of_maritime_technology": "Yuge National College of Maritime Technology"
        },
        {
            "mie_prefectural_college_of_nursing": "Mie Prefectural College of Nursing"
        },
        {
            "chukyo_gakuin_university": "Chukyo Gakuin University"
        },
        {
            "osaka_aoyama_university": "Osaka Aoyama University"
        },
        {
            "hiroshima_bunka_gakuen_university": "Hiroshima Bunka Gakuen University"
        },
        {
            "okinawa_christian_university": "Okinawa Christian University"
        },
        {
            "hamamatsu_gakuin_university": "Hamamatsu Gakuin University"
        },
        {
            "kyushu_institute_of_information_sciences": "Kyushu Institute of Information Sciences"
        },
        {
            "suzuka_university": "Suzuka University"
        },
        {
            "toba_national_college_of_maritime_technology": "Toba National College of Maritime Technology"
        },
        {
            "kobe_institute_of_computing_graduate_school_of_information_technology": "Kobe Institute of Computing Graduate School of Information Technology"
        },
        {
            "kobe_city_college_of_technology": "Kobe City College of Technology"
        },
        {
            "takasaki_university_of_commerce": "Takasaki University of Commerce"
        },
        {
            "biwako_seikei_sport_college": "Biwako Seikei Sport College"
        },
        {
            "poole_gakuin_university": "Poole Gakuin University"
        },
        {
            "toita_women's_college": "Toita Women's College"
        },
        {
            "ehime_prefectural_university_of_medical_technology": "Ehime Prefectural University of Medical Technology"
        },
        {
            "yonezawa_women's_junior_college": "Yonezawa Women's Junior College"
        },
        {
            "miyazaki_international_college": "Miyazaki International College"
        },
        {
            "gunma_paz_college": "Gunma Paz College"
        },
        {
            "fukuyama_city_university": "Fukuyama City University"
        },
        {
            "okinawa_national_college_of_technology": "Okinawa National College of Technology"
        },
        {
            "saniku_gakuin_college": "Saniku Gakuin College"
        },
        {
            "yamano_college_of_art_and_beauty": "Yamano College of Art and Beauty"
        },
        {
            "yonago_national_college_of_technology": "Yonago National College of Technology"
        },
        {
            "nagaoka_university": "Nagaoka University"
        },
        {
            "shohoku_college": "Shohoku College"
        },
        {
            "tomakomal_national_college_of_technology": "Tomakomal National College of Technology"
        },
        {
            "oshima_national_college_of_maritime_technology": "Oshima National College of Maritime Technology"
        },
        {
            "kagawa_national_college_of_technology": "Kagawa National College of Technology"
        },
        {
            "nagano_college_of_nursing": "Nagano College of Nursing"
        },
        {
            "japan_marine_technical_college": "Japan Marine Technical College"
        },
        {
            "kobe_kaisei_college": "Kobe Kaisei College"
        },
        {
            "health_science_university": "Health Science University"
        },
        {
            "seisen_university_(shiga)": "Seisen University (Shiga)"
        },
        {
            "syokugyodai_polytechnic_university": "Syokugyodai Polytechnic University"
        },
        {
            "urawa_university": "Urawa University"
        },
        {
            "sasebo_national_college_of_technology": "Sasebo National College of Technology"
        },
        {
            "seiwa_gakuen_college": "Seiwa Gakuen College"
        },
        {
            "ryotokuji_university": "Ryotokuji University"
        },
        {
            "higashi_osaka_university": "Higashi Osaka University"
        },
        {
            "ube_frontier_university_college": "Ube Frontier University College"
        },
        {
            "kure_national_college_of_technology": "Kure National College of Technology"
        },
        {
            "nagasaki_wesleyan_university": "Nagasaki Wesleyan University"
        },
        {
            "kinki_health_welfare_university": "Kinki Health Welfare University"
        },
        {
            "gifu_college_of_nursing": "Gifu College of Nursing"
        },
        {
            "den_en_chofu_university": "Den en Chofu University"
        },
        {
            "minobusan_university": "Minobusan University"
        },
        {
            "shizuoka_university_of_welfare": "Shizuoka University of Welfare"
        },
        {
            "hachinohe_national_college_of_technology": "Hachinohe National College of Technology"
        },
        {
            "taisei_gakuin_university": "Taisei Gakuin University"
        },
        {
            "tsuruoka_national_college_of_technology": "Tsuruoka National College of Technology"
        },
        {
            "kyushu_lutheran_college": "Kyushu Lutheran College"
        },
        {
            "tokyo_jogakkan_college": "Tokyo Jogakkan College"
        },
        {
            "akikusa_gakuen_junior_college": "Akikusa Gakuen Junior College"
        },
        {
            "hiroshima_national_college_of_maritime_technology": "Hiroshima National College of Maritime Technology"
        },
        {
            "miyakonojo_national_college_of_technology": "Miyakonojo National College of Technology"
        },
        {
            "st_margaret's_junior_college": "St Margaret's Junior College"
        },
        {
            "naragakuen_university_(nara_sangyo_university)": "Naragakuen University (Nara Sangyo University)"
        },
        {
            "nihon-bunka_university": "Nihon-Bunka University"
        },
        {
            "nakanihon_automotive_college": "Nakanihon Automotive College"
        },
        {
            "bunka_fashion_graduate_university": "Bunka Fashion Graduate University"
        },
        {
            "heisei_college_of_music": "Heisei College of Music"
        },
        {
            "tohto_college_of_health_sciences": "Tohto College of Health Sciences"
        },
        {
            "graduate_school_for_the_creation_of_new_photonics_industries": "Graduate School for the Creation of New Photonics Industries"
        },
        {
            "aikoku_gakuen_university": "Aikoku Gakuen University"
        },
        {
            "asahikawa_national_college_of_technology": "Asahikawa National College of Technology"
        },
        {
            "utsunomiya_kyowa_university": "Utsunomiya Kyowa University"
        },
        {
            "osaka_kawasaki_rehabilitation_university": "Osaka Kawasaki Rehabilitation University"
        },
        {
            "koutoku_gakuen": "Koutoku Gakuen"
        },
        {
            "kurume_shin_ai_women's_college": "Kurume Shin Ai Women's College"
        },
        {
            "kyoto_college_of_arts_and_crafts": "Kyoto College of Arts and Crafts"
        },
        {
            "japanese_red_cross_toyota_college_of_nursing": "Japanese Red Cross Toyota College of Nursing"
        },
        {
            "shubun_university": "Shubun University"
        },
        {
            "tokyo_bunka_junior_college": "Tokyo Bunka Junior College"
        },
        {
            "shinshu_honan_junior_college": "Shinshu Honan Junior College"
        },
        {
            "fuji_university": "Fuji University"
        },
        {
            "shibata_gakuen_university": "Shibata Gakuen University"
        },
        {
            "fukuoka_international_university": "Fukuoka International University"
        },
        {
            "okayama_gakuin_university": "Okayama Gakuin University"
        },
        {
            "tottori_college": "Tottori College"
        },
        {
            "hirosaki_university_of_health_and_welfare": "Hirosaki University of Health and Welfare"
        },
        {
            "takarazuka_university_of_medical_and_health_care": "Takarazuka University of Medical and Health Care"
        },
        {
            "fukushima_college": "Fukushima College"
        },
        {
            "tokiwakai_gakuen_university": "Tokiwakai Gakuen University"
        },
        {
            "saku_university": "Saku University"
        },
        {
            "ogaki_women's_college": "Ogaki Women's College"
        },
        {
            "asahi_gakuen": "Asahi Gakuen"
        },
        {
            "aichi_mizuho_college": "Aichi Mizuho College"
        },
        {
            "kyushu_nutrition_welfare_university": "Kyushu Nutrition Welfare University"
        },
        {
            "sanyo_women's_college": "Sanyo Women's College"
        },
        {
            "toyama_college_(toyama_women's_college)": "Toyama College (Toyama Women's College)"
        },
        {
            "university_of_fukuchiyama": "University of Fukuchiyama"
        },
        {
            "st_mary's_college": "St Mary's College"
        },
        {
            "izumi_junior_college": "Izumi Junior College"
        },
        {
            "kochi_gakuen_college": "Kochi Gakuen College"
        },
        {
            "osaka_university_of_comprehensive_children_education": "Osaka University of Comprehensive Children Education"
        },
        {
            "korea_university_tokyo": "Korea University Tokyo"
        },
        {
            "nagoya_future_culture_junior_college": "Nagoya Future Culture Junior College"
        },
        {
            "iida_women's_junior_college": "Iida Women's Junior College"
        },
        {
            "kagoshima_prefectural_college": "Kagoshima Prefectural College"
        },
        {
            "kyushu_otani_junior_college": "Kyushu Otani Junior College"
        },
        {
            "orio_aishin_junior_college": "Orio Aishin Junior College"
        },
        {
            "nagasaki_women's_junior_college": "Nagasaki Women's Junior College"
        },
        {
            "nara_saho_college": "Nara Saho College"
        },
        {
            "shukugawa_gakuin_college": "Shukugawa Gakuin College"
        },
        {
            "ikuei_junior_college": "Ikuei Junior College"
        },
        {
            "osaka_chiyoda_junior_college": "Osaka Chiyoda Junior College"
        },
        {
            "nara_college_of_arts": "Nara College of Arts"
        },
        {
            "nishi_nippon_junior_college": "Nishi nippon Junior College"
        },
        {
            "kyoto_college_of_medical_science": "Kyoto College of Medical Science"
        },
        {
            "kanazawa_technical_college": "Kanazawa Technical College"
        },
        {
            "kyoto_kacho_university": "Kyoto Kacho University"
        },
        {
            "yokkaichi_nursing_and_medical_care_university": "Yokkaichi Nursing and Medical Care University"
        },
        {
            "komatsu_university": "Komatsu University"
        },
        {
            "aichi_konan_college": "Aichi Konan College"
        },
        {
            "lec_tokyo_legal_mind_university": "LEC Tokyo Legal Mind University"
        },
        {
            "yamato_university": "Yamato University"
        },
        {
            "hakuho_women's_college": "Hakuho Women's College"
        },
        {
            "kameda_college_of_health_sciences": "Kameda College of Health Sciences"
        },
        {
            "osaka_yuhigaoka_gakuen_junior_college": "Osaka Yuhigaoka Gakuen Junior College"
        },
        {
            "sakura_no_seibo_junior_college": "Sakura no Seibo Junior College"
        },
        {
            "hokkaido_musashi_women's_junior_college": "Hokkaido Musashi Women's Junior College"
        },
        {
            "tokyo_rissho_junior_college": "Tokyo Rissho Junior College"
        },
        {
            "st_mary\u00e2\u20ac\u2122s_college_nagoya": "St Mary\u00e2\u20ac\u2122s College Nagoya"
        },
        {
            "beppu_mizobe_gakuen_college": "Beppu Mizobe Gakuen College"
        },
        {
            "nagoya_nsc_college": "Nagoya NSC College"
        },
        {
            "kurashiki_city_college": "Kurashiki City College"
        },
        {
            "kacho_junior_college": "Kacho Junior College"
        },
        {
            "osaka_yukioka_college_of_health_science": "Osaka Yukioka College of Health Science"
        },
        {
            "showa_gakuin_junior_college": "Showa Gakuin Junior College"
        },
        {
            "saitama_women's_junior_college": "Saitama Women's Junior College"
        },
        {
            "kyushu_ryukoku_junior_college": "Kyushu Ryukoku Junior College"
        },
        {
            "fukuoka_women's_junior_college": "Fukuoka Women's Junior College"
        },
        {
            "kyoto_seizan_college": "Kyoto Seizan College"
        },
        {
            "university_of_tokyo_health_sciences": "University of Tokyo Health Sciences"
        },
        {
            "osaka_christian_college": "Osaka Christian College"
        },
        {
            "ohtsuki_city_college": "Ohtsuki City College"
        },
        {
            "musa_shigaoka_college": "Musa Shigaoka College"
        },
        {
            "uyo_gakuen_college": "Uyo Gakuen College"
        },
        {
            "takayama_college_of_car_technology": "Takayama College of Car Technology"
        },
        {
            "sano_college": "Sano College"
        },
        {
            "kokugakuin_junior_college": "Kokugakuin Junior College"
        },
        {
            "tokushima_college_of_technology": "Tokushima College of Technology"
        },
        {
            "seika_women's_junior_college": "Seika Women's Junior College"
        },
        {
            "niijima_gakuen_junior_college": "Niijima Gakuen Junior College"
        },
        {
            "shinseikan_university": "Shinseikan University"
        },
        {
            "tokyo_management_college": "Tokyo Management College"
        },
        {
            "osaka_junior_college_of_social_health_&_welfare": "Osaka Junior College of Social Health & Welfare"
        },
        {
            "imabari_meitoku_junior_college": "Imabari Meitoku Junior College"
        },
        {
            "minatogawa_college": "Minatogawa College"
        },
        {
            "tenri_health_care_university": "Tenri Health Care University"
        },
        {
            "tokoha_gakuen_junior_college": "Tokoha Gakuen Junior College"
        },
        {
            "seibo_jogakuin_junior_college": "Seibo Jogakuin Junior College"
        },
        {
            "utsunomiya_junior_college": "Utsunomiya Junior College"
        },
        {
            "saitama_junshin_junior_college": "Saitama Junshin Junior College"
        },
        {
            "yamaguchi_junior_college": "Yamaguchi Junior College"
        },
        {
            "caritas_woman's_junior_college": "Caritas Woman's Junior College"
        },
        {
            "niimi_university": "Niimi University"
        },
        {
            "shiga_bunkyo_junior_college": "Shiga Bunkyo Junior College"
        },
        {
            "himeji_hinomoto_college": "Himeji Hinomoto College"
        },
        {
            "butsuryo_college_of_osaka": "Butsuryo College of Osaka"
        },
        {
            "junshin_junior_college": "Junshin Junior College"
        },
        {
            "tokiwakai_college": "Tokiwakai College"
        },
        {
            "shoei_junior_college": "Shoei Junior College"
        },
        {
            "niigata_college_of_technology": "Niigata College of Technology"
        },
        {
            "kyoto_international_university": "Kyoto International University"
        },
        {
            "chiba_keiai_junior_college": "Chiba Keiai Junior College"
        },
        {
            "kokusai_junior_college": "Kokusai Junior College"
        },
        {
            "tsuruga_nursing_university": "Tsuruga Nursing University"
        },
        {
            "seibi_university": "Seibi University"
        },
        {
            "kawasaki_city_college_of_nursing": "Kawasaki City College of Nursing"
        },
        {
            "minami_kyushu_junior_college": "Minami Kyushu Junior College"
        },
        {
            "yamamura_gakuen_college": "Yamamura Gakuen College"
        },
        {
            "international_pacific_university_ehime_women's_college": "International Pacific University Ehime Women's College"
        },
        {
            "ashikaga_junior_college": "Ashikaga Junior College"
        },
        {
            "konan_women's_university": "Konan Women's University"
        },
        {
            "tokyo_international_business_college": "Tokyo International Business College"
        },
        {
            "matsumoto_junior_college": "Matsumoto Junior College"
        },
        {
            "kawaguchi_junior_college": "Kawaguchi Junior College"
        },
        {
            "gifu_junior_college_of_health_science": "Gifu Junior College of Health Science"
        },
        {
            "st_cecilia_women's_junior_college": "St Cecilia Women's Junior College"
        },
        {
            "iwakuni_junior_college": "Iwakuni Junior College"
        },
        {
            "seishi_ursula_gakuen_junior_college": "Seishi Ursula Gakuen Junior College"
        },
        {
            "yokohama_women's_junior_college": "Yokohama Women's Junior College"
        },
        {
            "osaka_jikei_gakuen_university": "Osaka Jikei Gakuen University"
        },
        {
            "toyo_college_of_food_technology": "Toyo College of Food Technology"
        },
        {
            "shogen_junior_college": "Shogen Junior College"
        },
        {
            "teikyo_gakuen_junior_college": "Teikyo Gakuen Junior College"
        },
        {
            "fukuoka_social_medical_welfare_university": "Fukuoka Social Medical Welfare University"
        },
        {
            "koen_gakuen_women's_junior_college": "Koen Gakuen Women's Junior College"
        },
        {
            "misono_gakuen_junior_college": "Misono Gakuen Junior College"
        },
        {
            "niigata_chuoh_junior_college": "Niigata Chuoh Junior College"
        },
        {
            "shuko_junior_college": "Shuko Junior College"
        },
        {
            "asuka_beauty_culture_college": "Asuka Beauty Culture College"
        },
        {
            "aikoku_gakuen_junior_college": "Aikoku Gakuen Junior College"
        },
        {
            "setouchi_junior_college": "Setouchi Junior College"
        },
        {
            "nagoya_college_of_science_and_work": "Nagoya College of Science and Work"
        },
        {
            "graduate_school_of_education_of_japan": "Graduate School of Education of Japan"
        },
        {
            "higashi_kyushu_junior_college": "Higashi Kyushu Junior College"
        },
        {
            "aichi_mizuho_junior_college": "Aichi Mizuho Junior College"
        },
        {
            "osaka_kun_ei_women's_college": "Osaka Kun ei Women's College"
        },
        {
            "shinshu_junior_college": "Shinshu Junior College"
        },
        {
            "rissho_university_school_of_psychology": "Rissho University School of Psychology"
        },
        {
            "niigata_nursing_and_medicine_college": "Niigata Nursing and Medicine College"
        },
        {
            "miyazaki_gakuen_junior_college": "Miyazaki Gakuen Junior College"
        },
        {
            "hakodate_otani_college": "Hakodate Otani College"
        },
        {
            "null": null
        }
    ],
    "belgium": [
        {
            "catholic_university_of_leuven_ku_leuven": "Catholic University of Leuven KU Leuven"
        },
        {
            "ghent_university": "Ghent University"
        },
        {
            "catholic_university_of_louvain": "Catholic University of Louvain"
        },
        {
            "universiteit_antwerpen": "universiteit antwerpen"
        },
        {
            "university_of_liege": "University of Liege"
        },
        {
            "free_university_of_brussels": "Free University of Brussels"
        },
        {
            "universit\u00e3\u00a9_libre_de_bruxelles": "Universit\u00c3\u00a9 Libre de Bruxelles"
        },
        {
            "hasselt_university": "Hasselt University"
        },
        {
            "university_of_mons_(university_of_mons-hainaut)": "University of Mons (University of Mons-Hainaut)"
        },
        {
            "university_of_namur": "University of Namur"
        },
        {
            "institute_of_tropical_medicine_antwerp": "Institute of Tropical Medicine Antwerp"
        },
        {
            "vlerick_leuven_gent_management_school": "Vlerick Leuven Gent Management School"
        },
        {
            "highschool_gent": "HighSchool Gent"
        },
        {
            "centre_for_european_policy_studies": "Centre for European Policy Studies"
        },
        {
            "college_of_europe_bruges": "College of Europe Bruges"
        },
        {
            "royal_military_academy_of_brussels": "Royal Military Academy of Brussels"
        },
        {
            "thomas_more_hogeschool_(lessius_hogeschool_k_h_kempen)": "Thomas More Hogeschool (Lessius Hogeschool K H Kempen)"
        },
        {
            "saint_louis_brussels_university": "Saint Louis Brussels University"
        },
        {
            "odisee": "Odisee"
        },
        {
            "higher_institute_of_social_training_and_isfsc_communication": "Higher Institute of Social Training and ISFSC Communication"
        },
        {
            "artesis_plantijn_university_college": "Artesis Plantijn University College"
        },
        {
            "university_college_west_flanders": "University College West Flanders"
        },
        {
            "karel_de_grote-hogeschool": "Karel de Grote-Hogeschool"
        },
        {
            "artevelde_hogeschool": "Artevelde Hogeschool"
        },
        {
            "vives_university_college": "VIVES University College"
        },
        {
            "university_of_applied_sciences_pxl": "University of Applied Sciences PXL"
        },
        {
            "uc_leuven_limburg": "UC Leuven Limburg"
        },
        {
            "antwerp_management_school": "Antwerp Management School"
        },
        {
            "institute_for_european_studies_brussels": "Institute for European Studies Brussels"
        },
        {
            "luca_school_of_arts": "LUCA School of Arts"
        },
        {
            "erasmus_school_of_brussels": "Erasmus School of Brussels"
        },
        {
            "solvay_brussels_school_of_economics_&_management": "Solvay Brussels School of Economics & Management"
        },
        {
            "high_school_leonardo_da_vinci": "High school Leonardo da Vinci"
        },
        {
            "national_school_of_visual_arts_cambre": "National School of Visual Arts Cambre"
        },
        {
            "antwerp_maritime_academy": "Antwerp Maritime Academy"
        },
        {
            "higher_free_school_mosane_isell": "Higher free school Mosane Isell"
        },
        {
            "united_nations_university_institute_on_comparative_regional_integration_studies": "United Nations University Institute on Comparative Regional Integration Studies"
        },
        {
            "european_society_for_engineers_training": "European Society for Engineers Training"
        },
        {
            "royal_academy_of_fine_arts_artesis_hogeschool_antwerpen_fashion_department": "Royal Academy of Fine Arts Artesis Hogeschool Antwerpen Fashion Department"
        },
        {
            "free_institute_of_plastic_arts_graphic_research_school": "Free Institute of Plastic Arts Graphic Research School"
        },
        {
            "royal_conservatory_of_brussels_school_of_arts": "Royal Conservatory of Brussels School of Arts"
        },
        {
            "royal_conservatory_brussels_and_library": "Royal Conservatory Brussels and Library"
        },
        {
            "hainaut_provincial_school_condorcet": "Hainaut Provincial School Condorcet"
        },
        {
            "high_school_of_namur-liege-luxembourg": "High school of Namur-Liege-Luxembourg"
        },
        {
            "center_of_excellence_in_information_and_communication_technologies": "Center of Excellence in Information and Communication Technologies"
        },
        {
            "high_school_louvain_in_hainaut_(heec_+_helho_+_herb)": "High school Louvain in Hainaut (HEEC + Helho + Herb)"
        },
        {
            "high_school_albert_jacquard_namur": "High school Albert Jacquard Namur"
        },
        {
            "queen_elisabeth_music_chapel": "Queen Elisabeth Music Chapel"
        },
        {
            "high_school_brussels_brabant_he2b": "High school Brussels Brabant HE2B"
        },
        {
            "orpheus_institute": "Orpheus Institute"
        },
        {
            "evangelical_theological_faculty_of_leuven": "Evangelical Theological Faculty of Leuven"
        },
        {
            "inter_university_institute_for_high_energies_ull_vub": "Inter University Institute for high energies ull vub"
        },
        {
            "high_school_robert_schuman": "High school robert schuman"
        },
        {
            "high_school_free_school_of_brussels_ilya_prigogin": "High school free school of Brussels Ilya Prigogin"
        },
        {
            "high_school_francisco_ferrer": "High school Francisco Ferrer"
        },
        {
            "arts\u00e2\u00b2_school_of_arts_mons": "Arts\u00c2\u00b2 School of Arts Mons"
        },
        {
            "united_business_institutes_of_brussels": "United Business Institutes of Brussels"
        },
        {
            "ecam_brussels": "ECAM Brussels"
        },
        {
            "galilee_high_school": "Galilee High School"
        },
        {
            "limburg_transnational_university": "Limburg Transnational University"
        },
        {
            "school_of_arts_saint-luc_tournai": "School of Arts Saint-Luc Tournai"
        },
        {
            "higher_school_of_the_arts_of_tournai": "Higher School of the Arts of Tournai"
        }
    ],
    "germany": [
        {
            "technical_university_of_munich": "Technical University of Munich"
        },
        {
            "heidelberg_university": "Heidelberg University"
        },
        {
            "karlsruhe_institute_of_technology_(university_of_karlsruhe)": "Karlsruhe Institute of Technology (University of Karlsruhe)"
        },
        {
            "ludwig_maximilians_university_munich": "Ludwig Maximilians University Munich"
        },
        {
            "university_of_hamburg": "University of Hamburg"
        },
        {
            "university_of_bonn": "University of Bonn"
        },
        {
            "rhenish_westphalian_technical_university_of_aachen": "Rhenish Westphalian Technical University of Aachen"
        },
        {
            "technical_university_dresden": "Technical University Dresden"
        },
        {
            "university_of_tubingen": "University of Tubingen"
        },
        {
            "freie_university_of_berlin": "Freie University of Berlin"
        },
        {
            "university_of_freiburg": "University of Freiburg"
        },
        {
            "university_of_munster": "University of Munster"
        },
        {
            "humboldt_university_of_berlin": "Humboldt University of Berlin"
        },
        {
            "friedrich_alexander_university_erlangen_nuremberg_fau": "Friedrich Alexander University Erlangen Nuremberg Fau"
        },
        {
            "university_of_cologne": "University of Cologne"
        },
        {
            "johannes_gutenberg_university_mainz": "Johannes Gutenberg University Mainz"
        },
        {
            "university_of_wurzburg": "University of Wurzburg"
        },
        {
            "university_of_leipzig": "University of Leipzig"
        },
        {
            "university_of_duisburg-essen": "University of Duisburg-Essen"
        },
        {
            "georg-august-university_goettingen": "Georg-August-University Goettingen"
        },
        {
            "friedrich_schiller_university_of_jena": "Friedrich Schiller University of Jena"
        },
        {
            "university_of_stuttgart": "University of Stuttgart"
        },
        {
            "christian_albrechts_university_to_kiel": "Christian Albrechts University to Kiel"
        },
        {
            "darmstadt_university_of_technology": "Darmstadt University of Technology"
        },
        {
            "university_of_saarland": "University of Saarland"
        },
        {
            "bremen_university": "Bremen University"
        },
        {
            "university_of_ulm": "University of Ulm"
        },
        {
            "university_of_hannover": "University of Hannover"
        },
        {
            "justus-liebig_university_of_giessen": "Justus-Liebig university of Giessen"
        },
        {
            "philipps_university_marburg": "Philipps University Marburg"
        },
        {
            "ruhr-university_bochum": "Ruhr-University Bochum"
        },
        {
            "university_of_potsdam": "University of Potsdam"
        },
        {
            "technical_university_berlin": "Technical University Berlin"
        },
        {
            "martin_luther_university_halle-wittenberg": "Martin Luther University Halle-Wittenberg"
        },
        {
            "bielefeld_university": "Bielefeld University"
        },
        {
            "heinrich_heine_university_dusseldorf": "Heinrich Heine University Dusseldorf"
        },
        {
            "university_of_konstanz": "University of Konstanz"
        },
        {
            "university_of_regensburg": "University of Regensburg"
        },
        {
            "technical_university_dortmund": "Technical university Dortmund"
        },
        {
            "university_of_rostock": "University of Rostock"
        },
        {
            "university_of_bayreuth": "University of Bayreuth"
        },
        {
            "otto_von_guericke_university_magdeburg": "Otto von Guericke University Magdeburg"
        },
        {
            "technical_university_of_kaiserslautern": "Technical University of Kaiserslautern"
        },
        {
            "ernst_moritz_arndt_university_of_greifswald": "Ernst Moritz Arndt University of Greifswald"
        },
        {
            "technical_university_of_braunschweig": "Technical University of Braunschweig"
        },
        {
            "university_of_mannheim": "University of Mannheim"
        },
        {
            "university_of_siegen": "University of Siegen"
        },
        {
            "university_of_hohenheim": "University of Hohenheim"
        },
        {
            "university_of_kassel": "University of Kassel"
        },
        {
            "university_of_wuppertal": "University of Wuppertal"
        },
        {
            "technical_university_of_chemnitz": "Technical University of Chemnitz"
        },
        {
            "university_of_augsburg": "University of Augsburg"
        },
        {
            "university_of_lubeck": "University of Lubeck"
        },
        {
            "university_osnabruck": "University Osnabruck"
        },
        {
            "carl_von_ossietzky_university_oldenburg": "Carl von Ossietzky University Oldenburg"
        },
        {
            "technical_university_ilmenau": "Technical university Ilmenau"
        },
        {
            "leuphana_university_l\u00e3uneburg": "Leuphana University L\u00c3uneburg"
        },
        {
            "technical_university_of_hamburg-harburg": "Technical University of Hamburg-Harburg"
        },
        {
            "jacobs_university_bremen": "Jacobs University Bremen"
        },
        {
            "university_of_otto_friedrich_university_bamberg": "University of Otto Friedrich University Bamberg"
        },
        {
            "university_of_passau": "University of Passau"
        },
        {
            "brandenburg_technical_university_of_cottbus": "Brandenburg Technical University of Cottbus"
        },
        {
            "university_of_witten_herdecke": "University of Witten Herdecke"
        },
        {
            "technical_university_clausthal": "Technical University Clausthal"
        },
        {
            "bauhaus_university_weimar": "Bauhaus University Weimar"
        },
        {
            "university_of_the_bundeswehr_munich": "University of the Bundeswehr Munich"
        },
        {
            "hasso_plattner_institute": "Hasso Plattner Institute"
        },
        {
            "fernuniversit\u00e3\u00a4t_in_hagen": "FernUniversit\u00c3\u00a4t in Hagen"
        },
        {
            "johann_wolfgang_goethe_university_in_frankfurt_am_main": "Johann Wolfgang Goethe University in Frankfurt am Main"
        },
        {
            "hannover_medical_school": "Hannover Medical School"
        },
        {
            "helmut_schmidt_university": "Helmut Schmidt University"
        },
        {
            "hamburg_university_of_applied_sciences": "Hamburg University of Applied Sciences"
        },
        {
            "german_sport_university_cologne": "German Sport University Cologne"
        },
        {
            "university_of_trier": "University of Trier"
        },
        {
            "otto_beisheim_graduate_school_of_management": "Otto Beisheim Graduate School of Management"
        },
        {
            "munich_university_of_applied_sciences": "Munich University of Applied Sciences"
        },
        {
            "catholic_university_eichst\u00e3\u00a4tt_ingolstadt": "Catholic University Eichst\u00c3\u00a4tt Ingolstadt"
        },
        {
            "university_of_erfurt": "University of Erfurt"
        },
        {
            "university_of_hildesheim": "University of Hildesheim"
        },
        {
            "hertie_school_of_governance": "Hertie School of Governance"
        },
        {
            "furtwangen_university": "Furtwangen University"
        },
        {
            "university_of_applied_sciences_berlin": "University of Applied Sciences Berlin"
        },
        {
            "darmstadt_university_of_applied_sciences": "Darmstadt University of Applied Sciences"
        },
        {
            "europe_university_viadrina_frankfurt_oder": "Europe University Viadrina Frankfurt Oder"
        },
        {
            "reutlingen_university": "Reutlingen University"
        },
        {
            "th_k\u00e3\u00b6ln_university_of_applied_sciences": "TH K\u00c3\u00b6ln University of Applied Sciences"
        },
        {
            "university_of_koblenz_landau": "University of Koblenz Landau"
        },
        {
            "university_of_bonn-rhein-sieg": "University of Bonn-Rhein-Sieg"
        },
        {
            "university_of_applied_sciences_m\u00e3\u00bcnster": "University of Applied Sciences M\u00c3\u00bcnster"
        },
        {
            "berlin_school_of_economics_and_law": "Berlin School of Economics and Law"
        },
        {
            "beuth_university_of_applied_sciences_berlin": "Beuth University of Applied Sciences Berlin"
        },
        {
            "university_of_osnabrueck": "University of Osnabrueck"
        },
        {
            "charit\u00e3\u00a9_-_university_medicine_berlin": "Charit\u00c3\u00a9 - University Medicine Berlin"
        },
        {
            "university_of_ingolstadt_(university_of_applied_sciences_ingolstadt)": "University of Ingolstadt (University of Applied Sciences Ingolstadt)"
        },
        {
            "hochschule_niederrhein": "Hochschule Niederrhein"
        },
        {
            "university_of_aalen": "University of Aalen"
        },
        {
            "university_of_applied_sciences,_economics_and_culture_leipzig": "University of Applied Sciences, Economics and Culture Leipzig"
        },
        {
            "zeppelin_university": "Zeppelin University"
        },
        {
            "university_of_fulda": "University of Fulda"
        },
        {
            "hochschule_mannheim": "Hochschule Mannheim"
        },
        {
            "dresden_university_of_applied_sciences": "Dresden University of Applied Sciences"
        },
        {
            "bremen_university_of_applied_sciences": "Bremen University of Applied Sciences"
        },
        {
            "university_of_flensburg": "University of Flensburg"
        },
        {
            "frankfurt_school_of_finance_&_management": "Frankfurt School of Finance & Management"
        },
        {
            "university_of_vechta": "University of Vechta"
        },
        {
            "university_of_applied_sciences_dortmund": "University of Applied Sciences Dortmund"
        },
        {
            "university_of_applied_sciences_sudwestfalen": "University of Applied Sciences Sudwestfalen"
        },
        {
            "europe_university_of_flensburg": "Europe University of Flensburg"
        },
        {
            "university_of_offenburg": "University of Offenburg"
        },
        {
            "university_of_applied_sciences_aachen": "University of Applied Sciences Aachen"
        },
        {
            "hochschule_rheinmain": "Hochschule RheinMain"
        },
        {
            "university_of_karlsruhe": "University of Karlsruhe"
        },
        {
            "university_of_worms": "University of Worms"
        },
        {
            "ohm_university_of_nuremberg": "Ohm University of Nuremberg"
        },
        {
            "university_esslingen": "University Esslingen"
        },
        {
            "international_max_planck_research_school_for_astronomy_and_astrophysics": "International Max Planck Research School for Astronomy and Astrophysics"
        },
        {
            "ernst-abbe-hochschule_jena_(university_of_applied_sciences_jena)": "Ernst-Abbe-Hochschule Jena (University of Applied Sciences Jena)"
        },
        {
            "heidelberg_university_of_education": "Heidelberg University of Education"
        },
        {
            "technical_university_of_mittelhessen_(university_of_applied_sciences_giessen_friedberg)": "Technical University of Mittelhessen (University of Applied Sciences Giessen Friedberg)"
        },
        {
            "hochschule_augsburg": "Hochschule Augsburg"
        },
        {
            "hochschule_harz": "Hochschule Harz"
        },
        {
            "stuttgart_university_of_applied_sciences": "Stuttgart University of Applied Sciences"
        },
        {
            "university_medicine_mainz": "University Medicine Mainz"
        },
        {
            "university_of_media_stuttgart": "University of Media Stuttgart"
        },
        {
            "technical_university_of_wildau": "Technical University of Wildau"
        },
        {
            "university_of_applied_sciences_potsdam": "University of Applied Sciences Potsdam"
        },
        {
            "university_of_neubrandenburg": "University of Neubrandenburg"
        },
        {
            "university_of_wismar": "University of Wismar"
        },
        {
            "university_of_applied_sciences_koblenz": "University of Applied Sciences Koblenz"
        },
        {
            "university_of_applied_sciences_bielefeld": "University of Applied Sciences Bielefeld"
        },
        {
            "university_of_pforzheim": "University of Pforzheim"
        },
        {
            "heilbronn_university": "Heilbronn University"
        },
        {
            "technical_university_of_aschaffenburg": "Technical University of Aschaffenburg"
        },
        {
            "university_of_dusseldorf": "University of Dusseldorf"
        },
        {
            "hochschule_konstanz": "Hochschule Konstanz"
        },
        {
            "university_of_mittweida": "University of Mittweida"
        },
        {
            "anhalt_university": "Anhalt University"
        },
        {
            "deggendorf_institute_of_technology": "Deggendorf Institute of Technology"
        },
        {
            "university_of_applied_sciences_wurzburg-schweinfurt": "University of Applied Sciences Wurzburg-Schweinfurt"
        },
        {
            "k\u00e3\u00bchne_logistics_university": "K\u00c3\u00bchne Logistics University"
        },
        {
            "leipzig_graduate_school_of_management": "Leipzig Graduate School of Management"
        },
        {
            "university_(university_of_applied_sciences)_hanover": "University (University of Applied Sciences) Hanover"
        },
        {
            "westscap_of_socchasule_skickau": "Westscap of Socchasule Skickau"
        },
        {
            "coburg_university_of_applied_sciences": "Coburg University of Applied Sciences"
        },
        {
            "university_of_applied_sciences_fresenius": "University of Applied Sciences Fresenius"
        },
        {
            "university_of_art_in_berlin": "University of Art in Berlin"
        },
        {
            "european_business_school_schloss_reichartshausen": "European Business School Schloss Reichartshausen"
        },
        {
            "international_max_planck_research_school_for_neurosciences": "International Max Planck Research School for Neurosciences"
        },
        {
            "technical_university_of_brandenburg": "Technical University of Brandenburg"
        },
        {
            "pedagogical_university_of_ludwigsburg": "Pedagogical University of Ludwigsburg"
        },
        {
            "university_of_neu-ulm": "University of Neu-Ulm"
        },
        {
            "dual_university_of_baden_w\u00e3\u00bcrttemberg_dhbw": "Dual University of Baden W\u00c3\u00bcrttemberg DHBW"
        },
        {
            "eastern_bavarian_technical_university_of_regensburg": "Eastern Bavarian Technical University of Regensburg"
        },
        {
            "university_of_emden_leer": "University of Emden Leer"
        },
        {
            "rhine_waal_university": "Rhine Waal University"
        },
        {
            "european_school_of_management_and_technology_berlin": "European School of Management and Technology Berlin"
        },
        {
            "university_of_economics_and_environment_n\u00e3\u00bcrtingen-geislingen": "University of Economics and Environment N\u00c3\u00bcrtingen-Geislingen"
        },
        {
            "magdeburg_stendal_university_of_applied_sciences": "Magdeburg Stendal University of Applied Sciences"
        },
        {
            "medical_school_hamburg": "Medical School Hamburg"
        },
        {
            "academy_of_media_arts_cologne": "Academy of Media Arts Cologne"
        },
        {
            "chamber_of_commerce_hamburg": "Chamber of Commerce Hamburg"
        },
        {
            "technical_university_ostwestfalen_lippe": "Technical University Ostwestfalen Lippe"
        },
        {
            "graduate_school_of_neural_and_behavioural_sciences_international_max_planck_research_school": "Graduate School of Neural and Behavioural Sciences International Max Planck Research School"
        },
        {
            "frankfurt_university_of_applied_sciences_(fachhochschule_frankfurt_am_main)": "Frankfurt University of Applied Sciences (Fachhochschule Frankfurt am Main)"
        },
        {
            "ostfalia_university_of_applied_sciences": "Ostfalia University of Applied Sciences"
        },
        {
            "university_of_applied_sciences_kiel": "University of Applied Sciences Kiel"
        },
        {
            "educational_university_of_freiburg": "Educational University of Freiburg"
        },
        {
            "castle_giebichenstein_university_of_art_and_design_halle": "Castle Giebichenstein University of Art and Design Halle"
        },
        {
            "university_of_applied_sciences_erfurt": "University of Applied Sciences Erfurt"
        },
        {
            "fachhochschule_wedel": "Fachhochschule Wedel"
        },
        {
            "hochschule_trier": "Hochschule Trier"
        },
        {
            "mainz_university_of_applied_sciences": "Mainz University of Applied Sciences"
        },
        {
            "hochschule_weihenstephan-triesdorf": "Hochschule Weihenstephan-Triesdorf"
        },
        {
            "state_university_for_design_karlsruhe": "State university for design Karlsruhe"
        },
        {
            "dual_university_of_baden-w\u00e3\u00bcrttemberg_dhwb_stuttgart": "Dual University of Baden-W\u00c3\u00bcrttemberg DHWB Stuttgart"
        },
        {
            "leipzig_academy_of_visual_arts": "Leipzig Academy of Visual Arts"
        },
        {
            "university_of_bochum": "University of Bochum"
        },
        {
            "eberswalde_university_for_sustainable_development": "Eberswalde University for Sustainable Development"
        },
        {
            "hanover_university_of_music_and_theater": "Hanover University of Music and Theater"
        },
        {
            "hawk_university_of_applied_sciences_and_arts": "HAWK University of Applied Sciences and Arts"
        },
        {
            "hamburg_university_of_fine_arts": "Hamburg University of Fine Arts"
        },
        {
            "macromedia_university_of_media_munich": "Macromedia University of Media Munich"
        },
        {
            "state_academy_of_fine_arts_stuttgart": "State Academy of Fine Arts Stuttgart"
        },
        {
            "jade_university": "Jade University"
        },
        {
            "filmakademie_baden_wurttemberg": "Filmakademie Baden Wurttemberg"
        },
        {
            "hafencity_university_of_hamburg": "HafenCity University of Hamburg"
        },
        {
            "university_of_merseburg": "University of Merseburg"
        },
        {
            "alice_salomon_hochschule_berlin": "Alice Salomon Hochschule Berlin"
        },
        {
            "international_max_planck_research_school_for_astronomy_and_cosmic_physics_at_the_university_of_heidelberg": "International Max Planck Research School for Astronomy and Cosmic Physics at the University of Heidelberg"
        },
        {
            "steinbeis_university_berlin": "Steinbeis University Berlin"
        },
        {
            "kaiserslautern_university_of_applied_sciences": "Kaiserslautern University of Applied Sciences"
        },
        {
            "university_of_zittau_gorlitz": "University of Zittau Gorlitz"
        },
        {
            "university_of_music_franz_liszt_weimar": "University of Music Franz Liszt Weimar"
        },
        {
            "braunschweig_university_of_art": "Braunschweig University of Art"
        },
        {
            "srh_hochschule_heidelberg": "SRH Hochschule Heidelberg"
        },
        {
            "alanus_university": "Alanus University"
        },
        {
            "art_school_berlin_weissensee": "Art school Berlin Weissensee"
        },
        {
            "bucerius_law_school": "Bucerius Law School"
        },
        {
            "film_university_babelsberg_konrad_wolf": "Film University Babelsberg Konrad Wolf"
        },
        {
            "landshut_university_of_applied_sciences": "Landshut University of Applied Sciences"
        },
        {
            "technical_university_of_rosenheim": "Technical University of Rosenheim"
        },
        {
            "university_of_bremerhaven": "University of Bremerhaven"
        },
        {
            "srh_berlin_university_of_applied_sciences": "SRH Berlin University of Applied Sciences"
        },
        {
            "educational_university_karlsruhe": "Educational University Karlsruhe"
        },
        {
            "international_school_of_management_dortmund": "International School of Management Dortmund"
        },
        {
            "university_of_applied_sciences_of_the_sme": "University of Applied Sciences of the SME"
        },
        {
            "nordakademie_university_of_economics": "Nordakademie University of Economics"
        },
        {
            "westphalian_university_(gelsenkirchen_university_of_applied_sciences)": "Westphalian University (Gelsenkirchen University of Applied Sciences)"
        },
        {
            "ostbayerische_technical_university_of_technology_amberg_weiden": "Ostbayerische Technical University of Technology Amberg Weiden"
        },
        {
            "technical_university_of_l\u00e3\u00bcbeck": "Technical University of L\u00c3\u00bcbeck"
        },
        {
            "international_max_planck_research_school_for_molecular_biology": "International Max Planck Research School for Molecular Biology"
        },
        {
            "university_of_music_and_theater_in_leipzig": "University of Music and Theater in Leipzig"
        },
        {
            "hochschule_geisenheim_university": "Hochschule Geisenheim University"
        },
        {
            "deutsch-franz\u00e3\u00b6sische_hochschule": "Deutsch-Franz\u00c3\u00b6sische Hochschule"
        },
        {
            "university_of_kempten": "University of Kempten"
        },
        {
            "stralsund_university_of_applied_sciences": "Stralsund University of Applied Sciences"
        },
        {
            "offenbach_am_main_college_of_design": "Offenbach am Main College of Design"
        },
        {
            "university_of_hof": "University of Hof"
        },
        {
            "music_academy_in_stuttgart": "Music Academy in Stuttgart"
        },
        {
            "international_max_planck_research_school_on_the_life_course": "International Max Planck Research School on the Life Course"
        },
        {
            "munich_academy_of_fine_arts": "Munich Academy of Fine Arts"
        },
        {
            "akad_universities_scientific_university_lahr": "Akad Universities Scientific University Lahr"
        },
        {
            "university_of_biberach": "University of Biberach"
        },
        {
            "university_of_education_schw\u00e3\u00a4bisch_gm\u00e3\u00bcnd": "University of Education Schw\u00c3\u00a4bisch Gm\u00c3\u00bcnd"
        },
        {
            "cologne_business_school": "Cologne Business School"
        },
        {
            "university_of_applied_sciences_trier_environment_campus_birkenfeld": "University of Applied Sciences Trier Environment Campus Birkenfeld"
        },
        {
            "university_of_music_detmold": "University of Music Detmold"
        },
        {
            "hochschule_ulm": "Hochschule Ulm"
        },
        {
            "catholic_university_of_north_rhine-westphalia": "Catholic University of North Rhine-Westphalia"
        },
        {
            "hochschule_ravensburg_weingarten_university_of_applied_sciences": "Hochschule Ravensburg Weingarten University of Applied Sciences"
        },
        {
            "academy_of_music_hanns_eisler_berlin": "Academy of Music Hanns Eisler Berlin"
        },
        {
            "musikhochschule_freiburg": "Musikhochschule Freiburg"
        },
        {
            "munich_business_school": "Munich Business School"
        },
        {
            "berlin_technical_university": "Berlin technical university"
        },
        {
            "cologne_university_of_music_and_dance": "Cologne University of Music and Dance"
        },
        {
            "schmalkalden_university_of_applied_sciences": "Schmalkalden University of Applied Sciences"
        },
        {
            "university_of_applied_sciences_saarland": "University of Applied Sciences Saarland"
        },
        {
            "ivanovo_state_polytechnic_university": "Ivanovo State Polytechnic University"
        },
        {
            "beihai_vocational_college": "Beihai Vocational College"
        },
        {
            "university_of_prince_mugrin": "University of Prince Mugrin"
        },
        {
            "university_of_muhammadiyah_cirebon": "University of Muhammadiyah Cirebon"
        },
        {
            "georgetown_university_in_qatar": "Georgetown University in Qatar"
        },
        {
            "tianjin_university_of_sport": "Tianjin University of Sport"
        },
        {
            "istanbul_rumeli_university": "Istanbul Rumeli University"
        },
        {
            "advanced_technology_institute": "Advanced Technology Institute"
        },
        {
            "national_university_philippines": "National University Philippines"
        },
        {
            "khairun_university": "Khairun University"
        },
        {
            "anhui_vocational_college_of_electronics_and_information_technology": "Anhui Vocational College of Electronics and Information Technology"
        },
        {
            "north-western_state_medical_university_(saint_petersburg_state_medical_academy)": "North-Western State Medical University (Saint Petersburg State Medical Academy)"
        },
        {
            "university_center_of_technology_and_digital_art": "University Center of Technology and Digital Art"
        },
        {
            "minin_university_(minin_nizhny_novgorod_state_pedagogical_university)": "Minin University (Minin Nizhny Novgorod State Pedagogical University)"
        },
        {
            "ozark_christian_college": "Ozark Christian College"
        },
        {
            "webber_international_university": "Webber International University"
        },
        {
            "brunswick_community_college": "Brunswick Community College"
        },
        {
            "los_angeles_southwest_college": "Los Angeles Southwest College"
        },
        {
            "northeast_alabama_community_college": "Northeast Alabama Community College"
        },
        {
            "adler_graduate_school": "Adler Graduate School"
        },
        {
            "harbin_far_east_institute_of_technology": "Harbin Far East Institute of Technology"
        },
        {
            "hefei_college_of_finance_&_economics": "Hefei College of Finance & Economics"
        },
        {
            "hunan_petrochemical_vocational_technology_college": "Hunan PetroChemical Vocational Technology College"
        },
        {
            "east_java_veteran_national_development_university": "East Java Veteran National Development University"
        },
        {
            "tibet_traditional_medical_college": "Tibet Traditional Medical College"
        },
        {
            "jiangxi_metallurgical_vocational_and_technical_college": "Jiangxi Metallurgical Vocational and Technical College"
        },
        {
            "rheinische_university_of_applied_sciences_cologne": "Rheinische University of Applied Sciences Cologne"
        },
        {
            "black_river_technical_college": "Black River Technical College"
        },
        {
            "cloud_county_community_college": "Cloud County Community College"
        },
        {
            "melbourne_polytechnic": "Melbourne Polytechnic"
        },
        {
            "anhui_institute_of_international_business": "Anhui Institute of International Business"
        },
        {
            "fuzhou_anglo-chinese_college": "Fuzhou Anglo-Chinese College"
        },
        {
            "guangxi_college_of_sports_and_physical_education": "Guangxi College of Sports and Physical Education"
        },
        {
            "zhejiang_college_of_sports": "Zhejiang College of Sports"
        },
        {
            "sun_yat-sen_university_southern_college": "Sun Yat-sen University Southern College"
        },
        {
            "shazhou_professional_institute_of_technology": "Shazhou Professional Institute of Technology"
        },
        {
            "city_vocational_college_of_jiangsu": "City Vocational College of Jiangsu"
        },
        {
            "agroecological_zhitomir_national_university": "Agroecological Zhitomir National University"
        },
        {
            "valley_forge_military_college": "Valley Forge Military College"
        },
        {
            "takasaki_city_university_of_economics": "Takasaki City University of Economics"
        },
        {
            "lijiang_teachers_college": "Lijiang Teachers College"
        },
        {
            "global_institute_of_software_technology": "Global Institute of Software Technology"
        },
        {
            "inner_mongolia_vocational_&_technical_college": "Inner Mongolia Vocational & Technical College"
        },
        {
            "zhaoqing_medical_college": "Zhaoqing Medical College"
        },
        {
            "baoji_vocational_technology_college": "Baoji Vocational Technology College"
        },
        {
            "inesem_business_school": "INESEM Business School"
        },
        {
            "university_of_arts_in_pozna\u00e5\u201e": "University of Arts in Pozna\u00c5\u201e"
        },
        {
            "savannah_technical_college": "Savannah Technical College"
        },
        {
            "south_louisiana_community_college": "South Louisiana Community College"
        },
        {
            "mountain_empire_community_college": "Mountain Empire Community College"
        },
        {
            "wyoming_technical_institute": "Wyoming Technical Institute"
        },
        {
            "kunitachi_college_of_music": "Kunitachi College of Music"
        },
        {
            "beijing_hospitality_institute": "Beijing Hospitality Institute"
        },
        {
            "caraga_state_university": "Caraga State University"
        },
        {
            "cangzhou_normal_university": "Cangzhou Normal University"
        },
        {
            "khorramshahr_university_of_marine_science_&_technology": "Khorramshahr University of Marine Science & Technology"
        },
        {
            "nanjing_institute_of_visual_arts": "Nanjing Institute of Visual Arts"
        },
        {
            "varna_university_of_management": "Varna University of Management"
        },
        {
            "ukrainian_academy_of_printing": "Ukrainian Academy of Printing"
        },
        {
            "kentucky_christian_university": "Kentucky Christian University"
        },
        {
            "keyano_college": "Keyano College"
        },
        {
            "helene_fuld_college_of_nursing": "Helene Fuld College of Nursing"
        },
        {
            "spartanburg_methodist_college": "Spartanburg Methodist College"
        },
        {
            "rowan_cabarrus_community_college": "Rowan Cabarrus Community College"
        },
        {
            "mayland_community_college": "Mayland Community College"
        },
        {
            "college_of_post_and_telecommunication_wuhan_institute_of_technology": "College of Post and Telecommunication Wuhan Institute of Technology"
        },
        {
            "jiangxi_institute_of_fashion_technology": "Jiangxi Institute of Fashion Technology"
        },
        {
            "government_college_for_women_university_sialkot": "Government College for Women University Sialkot"
        },
        {
            "toraighyrov_university": "Toraighyrov University"
        },
        {
            "sandberg_institute.": "Sandberg Institute."
        },
        {
            "saint_cloud_technical_&_community_college": "Saint Cloud Technical & Community College"
        },
        {
            "simpson_university": "Simpson University"
        },
        {
            "rust_college": "Rust College"
        },
        {
            "mindanao_state_university_marawi_city": "Mindanao State University Marawi City"
        },
        {
            "shaheed_benazir_bhutto_university": "Shaheed Benazir Bhutto University"
        },
        {
            "chengdu_college_of_arts_&_sciences": "Chengdu College of Arts & Sciences"
        },
        {
            "guangxi_industrial_vocational_and_technical_college": "Guangxi Industrial Vocational and Technical College"
        },
        {
            "premier_university_chittagong": "Premier University Chittagong"
        },
        {
            "international_islamic_academy_of_uzbekistan": "International Islamic Academy of Uzbekistan"
        },
        {
            "institute_for_cognitive_science_studies": "Institute for Cognitive Science Studies"
        },
        {
            "blackhawk_technical_college": "Blackhawk Technical College"
        },
        {
            "brewton_parker_college": "Brewton Parker College"
        },
        {
            "atlanta_technical_college": "Atlanta Technical College"
        },
        {
            "lamar_institute_of_technology": "Lamar Institute of Technology"
        },
        {
            "zane_state_college": "Zane State College"
        },
        {
            "international_university_of_kyrgyzstan": "International University of Kyrgyzstan"
        },
        {
            "insurance_vocational_college": "Insurance Vocational College"
        },
        {
            "anhui_finance_&_trade_vocational_college": "Anhui Finance & Trade Vocational College"
        },
        {
            "xi'an_medical_college": "Xi'an Medical College"
        },
        {
            "sichuan_film_and_television_university": "Sichuan Film and Television University"
        },
        {
            "paristech_institute_of_science_and_technology_of_paris": "ParisTech Institute of Science and Technology of Paris"
        },
        {
            "private_university_of_applied_sciences_gottingen_studium_station": "Private University of Applied Sciences Gottingen Studium Station"
        },
        {
            "columbia_gorge_community_college": "Columbia Gorge Community College"
        },
        {
            "sainte_anne_university": "Sainte Anne University"
        },
        {
            "sauk_valley_community_college": "Sauk Valley Community College"
        },
        {
            "yunnan_economics_trade_and_foreign_affairs_college": "Yunnan Economics Trade and Foreign Affairs College"
        },
        {
            "karlshochschule_international_university_(merkur_international_university_of_karlsruhe)": "Karlshochschule International University (Merkur International University of Karlsruhe)"
        },
        {
            "art_academy_dusseldorf": "Art Academy Dusseldorf"
        },
        {
            "hochschule_ruhr_west": "Hochschule Ruhr West"
        },
        {
            "dresden_university_of_fine_arts": "Dresden University of Fine Arts"
        },
        {
            "dual_university_of_baden-w\u00e3\u00bcrttemberg_dhbw_karlsruhe": "Dual University of Baden-W\u00c3\u00bcrttemberg DHBW Karlsruhe"
        },
        {
            "university_of_albstadt-sigmaringen": "University of Albstadt-Sigmaringen"
        },
        {
            "university_of_education_weingarten": "University of Education Weingarten"
        },
        {
            "ludwigshafen_university_of_economics_and_society": "Ludwigshafen University of Economics and Society"
        },
        {
            "technical_university_of_bingen": "Technical University of Bingen"
        },
        {
            "hfh_hamburger_mernhochschule": "HFH Hamburger Mernhochschule"
        },
        {
            "university_of_philosophy_munich": "University of Philosophy Munich"
        },
        {
            "academy_fashion_&_design": "Academy Fashion & Design"
        },
        {
            "hamburg_school_of_business_administration": "Hamburg School of Business Administration"
        },
        {
            "philosophical_theological_university_sankt_georgen": "Philosophical theological university Sankt Georgen"
        },
        {
            "frankfurt_university_of_music_and_performing_arts": "Frankfurt University of Music and Performing Arts"
        },
        {
            "merz_academy_college_of_design": "Merz Academy College of Design"
        },
        {
            "german_international_school_of_management_and_administration": "German International School of Management and Administration"
        },
        {
            "german_film_and_television_academy": "German Film and Television Academy"
        },
        {
            "university_of_television_and_film_munich": "University of Television and Film Munich"
        },
        {
            "hamm-lippstadt_university_of_applied_sciences": "Hamm-Lippstadt University of Applied Sciences"
        },
        {
            "dual_university_of_baden-w\u00e3\u00bcrttemberg_dhwb_ravensburg": "Dual University of Baden-W\u00c3\u00bcrttemberg DHWB Ravensburg"
        },
        {
            "european_university_of_applied_sciences": "European University of Applied Sciences"
        },
        {
            "mobile_university_(srh_spanisher_riedlingen)": "Mobile University (SRH Spanisher Riedlingen)"
        },
        {
            "robert_schumann_hochschule_d\u00e3\u00bcsseldorf": "Robert Schumann Hochschule D\u00c3\u00bcsseldorf"
        },
        {
            "media_design_university_of_design_and_computer_science": "Media Design University of Design and Computer Science"
        },
        {
            "university_of_design_schw\u00e3\u00a4bisch_gmund": "University of Design Schw\u00c3\u00a4bisch Gmund"
        },
        {
            "dresden_university_of_music_carl_maria_von_weber": "Dresden University of Music Carl Maria Von Weber"
        },
        {
            "university_of_speyer": "University of Speyer"
        },
        {
            "wilhelm_buchner_university_darmstadt": "Wilhelm Buchner University Darmstadt"
        },
        {
            "psychological_university_of_berlin": "Psychological University of Berlin"
        },
        {
            "hochschule_ansbach": "Hochschule Ansbach"
        },
        {
            "university_of_music_and_theater_in_rostock": "University of Music and Theater in Rostock"
        },
        {
            "nordhausen_university_of_applied_sciences": "Nordhausen University of Applied Sciences"
        },
        {
            "university_of_applied_sciences_for_applied_management": "University of Applied Sciences for Applied Management"
        },
        {
            "university_of_health_sciences": "University of Health Sciences"
        },
        {
            "university_of_music_wuerzburg": "University of Music Wuerzburg"
        },
        {
            "catholic_foundation_hochschule_m\u00e3\u00bcnchen": "Catholic Foundation Hochschule M\u00c3\u00bcnchen"
        },
        {
            "staedelschule_state_university_for_fine_arts": "Staedelschule State University for Fine Arts"
        },
        {
            "fachhochschulen_der_wirtschaft": "Fachhochschulen der Wirtschaft"
        },
        {
            "popakademie_baden_w\u00e3\u00bcrttemberg_university_of_popular_music_and_music_business": "Popakademie Baden W\u00c3\u00bcrttemberg University of Popular Music and Music Business"
        },
        {
            "state_university_for_music_and_performing_arts_mannheim": "State University for Music and Performing Arts Mannheim"
        },
        {
            "muthesius_art_school": "Muthesius art school"
        },
        {
            "university_of_media_communication_and_economics": "University of Media Communication and Economics"
        },
        {
            "karlsruhe_university_of_music": "Karlsruhe University of Music"
        },
        {
            "musikhochschule_lubeck": "Musikhochschule Lubeck"
        },
        {
            "iu_dual_studies": "Iu Dual Studies"
        },
        {
            "bremen_university_of_the_arts": "Bremen University of the Arts"
        },
        {
            "quadriga_hochschule_berlin": "Quadriga Hochschule Berlin"
        },
        {
            "diploma_private_university_society": "Diploma private university society"
        },
        {
            "business_and_information_technology_school_bits_germany": "Business and Information Technology School BiTS Germany"
        },
        {
            "technical_university_of_applied_sciences_georg_agr\u00e3\u00adcola_bochum": "Technical University of Applied Sciences Georg Agr\u00c3\u00adcola Bochum"
        },
        {
            "fachhochschule_westkuste": "Fachhochschule Westkuste"
        },
        {
            "academy_of_dramatic_arts_ernst_busch_berlin": "Academy of Dramatic Arts Ernst Busch Berlin"
        },
        {
            "technical_academy_esslingen": "Technical Academy Esslingen"
        },
        {
            "bard_college_berlin": "Bard College Berlin"
        },
        {
            "university_of_telecommunications_leipzig": "University of Telecommunications Leipzig"
        },
        {
            "ist_university_for_management": "Ist university for management"
        },
        {
            "code_university_of_applied_sciences": "CODE University of Applied Sciences"
        },
        {
            "academy_of_fine_arts_nurnberg": "Academy of Fine Arts Nurnberg"
        },
        {
            "state_music_hochschule_trossingen": "State Music Hochschule Trossingen"
        },
        {
            "evangelical_university_of_rhineland-westphalia-lippe": "Evangelical University of Rhineland-Westphalia-Lippe"
        },
        {
            "university_of_professional_education_heidelberg": "University of Professional Education Heidelberg"
        },
        {
            "kehl_university": "Kehl University"
        },
        {
            "protestant_university_for_social_work_dresden": "Protestant university for social work Dresden"
        },
        {
            "frankfurt_institute_for_advanced_studies": "Frankfurt Institute for Advanced Studies"
        },
        {
            "university_of_forestry_rottenburg": "University of Forestry Rottenburg"
        },
        {
            "european_high_school_hamburg": "European high school Hamburg"
        },
        {
            "catholic_university_of_applied_sciences_freiburg_university_of_social_sciences": "Catholic University of Applied Sciences Freiburg University of Social Sciences"
        },
        {
            "saar_university_of_fine_arts_": "saar university of fine arts "
        },
        {
            "dual_university_of_baden-w\u00e3\u00bcrttemberg_dhwb_villingen-schwenningen": "Dual University of Baden-W\u00c3\u00bcrttemberg DHWB Villingen-Schwenningen"
        },
        {
            "dresden_international_university": "Dresden International University"
        },
        {
            "berlin_school_of_business_and_innovation": "Berlin School of Business and Innovation"
        },
        {
            "b_i_b_international_college": "B I B International College"
        },
        {
            "university_of_applied_sciences_europe": "University of Applied Sciences Europe"
        },
        {
            "catholic_university_for_social_system_berlin": "Catholic university for social system Berlin"
        },
        {
            "evangelical_university_of_freiburg": "Evangelical University of Freiburg"
        },
        {
            "allensbach_university_of_konstanz": "Allensbach University of Konstanz"
        },
        {
            "dual_university_of_baden_w\u00e3\u00bcrttemberg_dhbw_heidenheim": "Dual University of Baden W\u00c3\u00bcrttemberg DHBW Heidenheim"
        },
        {
            "evangelical_university_nurnberg": "Evangelical university Nurnberg"
        },
        {
            "university_of_music_nuremberg": "University of Music Nuremberg"
        },
        {
            "catholic_university_of_mainz": "Catholic University of Mainz"
        },
        {
            "evangelical_university_of_ludwigsburg": "Evangelical University of Ludwigsburg"
        },
        {
            "evangelical_university_of_darmstadt": "Evangelical University of Darmstadt"
        },
        {
            "apollon_university_of_the_healthcare_industry": "Apollon university of the healthcare industry"
        },
        {
            "palucca_university_of_dance_dresden": "Palucca University of Dance Dresden"
        },
        {
            "university_of_administration_and_finance_ludwigsburg": "University of Administration and Finance Ludwigsburg"
        },
        {
            "professional_academy_thuringia_dresden": "Professional Academy Thuringia Dresden"
        },
        {
            "bbw_university_of_applied_sciences": "BBW University of Applied Sciences"
        },
        {
            "german_graduate_school_of_management_&_law": "German Graduate School of Management & Law"
        },
        {
            "university_of_music_saar": "University of Music Saar"
        },
        {
            "accadis_hochschule_bad_homburg": "Accadis Hochschule Bad Homburg"
        },
        {
            "dual_university_of_gera-eisenach": "Dual University of Gera-Eisenach"
        },
        {
            "art_academy_m\u00e3\u00bcnster": "Art Academy M\u00c3\u00bcnster"
        },
        {
            "university_of_jewish_studies": "University of Jewish Studies"
        },
        {
            "scientific_and_medical_institute_universitat_tubingen": "Scientific and Medical Institute Universitat Tubingen"
        },
        {
            "state_academy_of_fine_arts_karlsruhe": "State Academy of Fine Arts Karlsruhe"
        },
        {
            "provadis_school_of_international_management_&_technology": "PROVADIS School of International Management & Technology"
        },
        {
            "university_of_kassel_international_management_school": "University of Kassel International Management School"
        },
        {
            "brandenburg_medical_school_theodor_fontane": "Brandenburg Medical School Theodor Fontane"
        },
        {
            "university_of_the_federal_employment_agency_hdba": "University of the Federal Employment Agency HdBA"
        },
        {
            "srh_university_in_north_rhine-westphalia": "SRH University in North Rhine-Westphalia"
        },
        {
            "srh_hochschule_fur_gesundheit": "SRH Hochschule fur Gesundheit"
        },
        {
            "heidelberger_academy_of_sciences": "Heidelberger Academy of Sciences"
        },
        {
            "augustana_university": "Augustana University"
        },
        {
            "hochschule_bund": "Hochschule Bund"
        },
        {
            "hornemann_institute": "Hornemann Institute"
        },
        {
            "university_of_applied_sciences_for_public_administration_and_justice": "University of Applied Sciences for public administration and justice"
        },
        {
            "german_adult_educational_work_(hochschule_bamberg)": "German adult educational work (Hochschule Bamberg)"
        },
        {
            "institute_of_history_of_medicine_the_robert_bosch_foundation": "Institute of History of Medicine The Robert Bosch Foundation"
        },
        {
            "dpfa_university_of_saxony": "DPFA University of Saxony"
        },
        {
            "ebz_business_school": "EBZ Business School"
        },
        {
            "professional_academy_leipzig": "Professional Academy Leipzig"
        },
        {
            "institute_for_law_and_finance_goethe_university": "Institute for Law and Finance Goethe University"
        },
        {
            "professional_academy_riesa": "Professional Academy Riesa"
        },
        {
            "private_university_of_economics_and_technology_vechta-diepholz-oldenburg": "Private University of Economics and Technology Vechta-Diepholz-Oldenburg"
        },
        {
            "flieder_university_of_applied_sciences_dusseldorf": "Flieder University of Applied Sciences Dusseldorf"
        },
        {
            "dhgs_german_university_of_health_and_sport": "DHGS German University of Health and Sport"
        },
        {
            "university_of_economics_for_management_hdwm": "University of Economics for Management HdWM"
        },
        {
            "university_of_applied_sciences_dresden": "University of Applied Sciences Dresden"
        },
        {
            "university_of_applied_sciences_of_diakonie": "University of Applied Sciences of Diakonie"
        },
        {
            "ib_hochschule_berlin": "IB Hochschule Berlin"
        },
        {
            "university_of_applied_sciences_for_the_economy_hannover": "University of Applied Sciences for the Economy Hannover"
        },
        {
            "university_of_arts_in_social_affairs_ottersberg_(university_of_applied_sciences_ottersberg)": "University of Arts in Social Affairs Ottersberg (University of Applied Sciences Ottersberg)"
        },
        {
            "theological_university_of_elstal": "Theological University of Elstal"
        },
        {
            "university_of_catholic_church_music_and_music_pedagogy_regensburg": "University of Catholic Church Music and Music Pedagogy Regensburg"
        },
        {
            "victoria_international_university": "Victoria International University"
        },
        {
            "philosophical_theological_university_sankt_augustin": "Philosophical theological university Sankt Augustin"
        },
        {
            "leibniz_fachhochschule_hannover": "Leibniz Fachhochschule Hannover"
        },
        {
            "philosophical_theological_university_m\u00e3\u00bcnster": "Philosophical theological university M\u00c3\u00bcnster"
        },
        {
            "theological_faculty_fulda": "Theological Faculty Fulda"
        },
        {
            "administration_and_business_academy": "Administration and Business Academy"
        },
        {
            "evangelical_university_(university_for_social_work_&_diakonie)": "Evangelical University (University for Social Work & Diakonie)"
        },
        {
            "professional_academy_saxony_at_the_breitenbrunn": "Professional Academy Saxony at the Breitenbrunn"
        },
        {
            "theological_seminar_reutlingen": "Theological Seminar Reutlingen"
        },
        {
            "university_of_church_music_in_heidelberg": "University of Church Music in Heidelberg"
        },
        {
            "evangelische_kirchenmusikhochschule_halle_an_der_saale": "Evangelische Kirchenmusikhochschule Halle an der Saale"
        },
        {
            "university_of_applied_sciences_north_rhine-westphalia": "University of Applied Sciences North Rhine-Westphalia"
        },
        {
            "university_of_public_administration_rhineland-palatinate": "University of Public Administration Rhineland-Palatinate"
        },
        {
            "professional_academy_rhein-main": "Professional Academy Rhein-Main"
        },
        {
            "church_music_university_in_t\u00e3\u00bcbingen": "Church Music University in T\u00c3\u00bcbingen"
        },
        {
            "university_of_police_in_baden-w\u00e3\u00bcrttemberg": "University of Police in Baden-W\u00c3\u00bcrttemberg"
        },
        {
            "international_max_planck_research_school_mathematics_in_the_sciences": "International Max Planck Research School Mathematics in the Sciences"
        },
        {
            "university_of_public_administration_bremen": "University of Public Administration Bremen"
        },
        {
            "european_college_of_liberal_arts_ecla": "European College of Liberal Arts ECLA"
        },
        {
            "university_of_applied_sciences_for_administration_and_services_in_schleswig_holstein": "University of Applied Sciences for Administration and Services in Schleswig Holstein"
        },
        {
            "ibs_it_&_business_school_oldenburg": "IBS IT & Business School Oldenburg"
        },
        {
            "international_school_of_new_media_university_of_lubeck": "International School of New Media University of Lubeck"
        },
        {
            "welfenakademie": "WelfenAkademie"
        },
        {
            "bundesbank_hochschule_-_startseite": "Bundesbank Hochschule - Startseite"
        },
        {
            "wannsee_schule": "Wannsee Schule"
        },
        {
            "wildau_institute_of_technology": "Wildau Institute of Technology"
        },
        {
            "brand_university_of_applied_sciences": "Brand University of Applied Sciences"
        },
        {
            "university_of_applied_sciences_schwetzingen": "University of Applied Sciences Schwetzingen"
        },
        {
            "iss_international_business_school_of_service_management": "ISS International Business School of Service Management"
        },
        {
            "vwa_university": "VWA University"
        },
        {
            "lutheran_theological_university_oberursel": "Lutheran theological university Oberursel"
        },
        {
            "university_of_church_music_rottenburg": "University of Church Music Rottenburg"
        },
        {
            "leibniz_academy_hannover": "Leibniz Academy Hannover"
        },
        {
            "university_of_applied_sciences_schw\u00e3\u00a4bisch_hall_university_of_design": "University of Applied Sciences Schw\u00c3\u00a4bisch Hall University of Design"
        },
        {
            "professional_academy_melle": "Professional Academy Melle"
        },
        {
            "university_of_music_nuremberg_augsburg": "University of Music Nuremberg Augsburg"
        },
        {
            "international_max_planck_research_school_for_demography": "International Max Planck Research School for Demography"
        },
        {
            "international_max_planck_research_school_for_dynamical_processes_in_atoms_molecules_and_solids": "International Max Planck Research School for Dynamical Processes in Atoms Molecules and Solids"
        },
        {
            "administration_and_economic_academy_aachen": "Administration and Economic Academy Aachen"
        },
        {
            "accadis_international_school_bad_homburg": "Accadis International School Bad Homburg"
        },
        {
            "arden_university,_study_center_berlin": "Arden University, Study Center Berlin"
        },
        {
            "esslingen_university_of_applied_sciences": "Esslingen University of Applied Sciences"
        },
        {
            "eu_business_school": "EU Business School"
        },
        {
            "friedrich-alexander-universit\u00e3\u00a4t_erlangen-n\u00e3\u00bcrnberg_(fau)": "Friedrich-Alexander-Universit\u00c3\u00a4t Erlangen-N\u00c3\u00bcrnberg (FAU)"
        },
        {
            "gisma_business_school": "GISMA Business School"
        },
        {
            "gisma_business_school": "GISMA Business School"
        },
        {
            "heilbronn_university": "Heilbronn University"
        },
        {
            "icn_berlin": "ICN Berlin"
        },
        {
            "international_school_of_management_(ism)": "International School of Management (ISM)"
        },
        {
            "mannheim_business_school": "Mannheim Business School"
        },
        {
            "new_european_college": "New European College"
        },
        {
            "pfh_private_university_of_applied_sciences": "PFH Private University of Applied Sciences"
        },
        {
            "rhine-waal_university_of_applied_sciences": "Rhine-Waal University of Applied Sciences"
        },
        {
            "south_westphalia_university_of_applied_sciences": "South Westphalia University of Applied Sciences"
        },
        {
            "srh_berlin_university_of_applied_sciences": "SRH Berlin University of Applied Sciences"
        },
        {
            "technical_university_of_munich": "Technical University of Munich"
        }
    ],
    "finland": [
        {
            "university_of_helsinki": "University of Helsinki"
        },
        {
            "aalto_university": "Aalto University"
        },
        {
            "university_of_turku": "University of Turku"
        },
        {
            "university_of_oulu": "University of Oulu"
        },
        {
            "tampere_university_(tut+uot)": "Tampere University (TUT+UoT)"
        },
        {
            "jyv\u00e3\u00a4skyl\u00e3\u00a4_university": "Jyv\u00c3\u00a4skyl\u00c3\u00a4 University"
        },
        {
            "university_of_eastern_finland": "University of Eastern Finland"
        },
        {
            "lappeenranta_university_of_technology": "Lappeenranta University of Technology"
        },
        {
            "baba_akadem_university": "Baba Akadem University"
        },
        {
            "university_of_vaasa": "University of Vaasa"
        },
        {
            "hanken_school_of_economics": "Hanken School of Economics"
        },
        {
            "university_of_lapland": "University of Lapland"
        },
        {
            "turku_university_of_applied_sciences": "Turku University of Applied Sciences"
        },
        {
            "university_of_the_arts_helsinki": "University of the Arts Helsinki"
        },
        {
            "united_nations_university_world_institute_for_development_economics_research": "United Nations University World Institute for Development Economics Research"
        },
        {
            "helsinki_metropolia_university_of_applied_sciences_(evtek,_stadia)": "Helsinki Metropolia University of Applied Sciences (Evtek, Stadia)"
        },
        {
            "laurea_university_of_applied_sciences": "Laurea University of Applied Sciences"
        },
        {
            "arcada_polytechnic": "Arcada Polytechnic"
        },
        {
            "novia_university_of_applied_sciences_(sydvast_polytechnic)": "Novia University of Applied Sciences (Sydvast Polytechnic)"
        },
        {
            "jyvaskyla_university_of_applied_sciences": "Jyvaskyla University of Applied Sciences"
        },
        {
            "satakunta_university_of_applied_sciences": "Satakunta University of Applied Sciences"
        },
        {
            "hamk_university_of_applied_sciences": "HAMK University of Applied Sciences"
        },
        {
            "haaga_helia_university_of_applied_sciences": "Haaga Helia University of Applied Sciences"
        },
        {
            "lab_university_of_applied_sciences": "LAB University of Applied Sciences"
        },
        {
            "kokkola_university_consortium_chydenius": "Kokkola University Consortium Chydenius"
        },
        {
            "oulu_university_of_applied_sciences": "Oulu University of Applied Sciences"
        },
        {
            "national_defence_university": "National Defence University"
        },
        {
            "savonia_university_of_applied_sciences": "Savonia University of Applied Sciences"
        },
        {
            "police_college_of_finland": "Police College of Finland"
        },
        {
            "southeast_finland_university_of_applied_sciences_xamk": "Southeast Finland University of Applied Sciences XAMK"
        },
        {
            "lapland_university_of_applied_sciences": "Lapland University of Applied Sciences"
        },
        {
            "seinajoki_university_of_applied_sciences": "Seinajoki University of Applied Sciences"
        },
        {
            "karelia_university_of_applied_sciences": "Karelia University of Applied Sciences"
        },
        {
            "diaconia_university_of_applied_sciences": "Diaconia University of Applied Sciences"
        },
        {
            "kajaani_polytechnic": "Kajaani Polytechnic"
        },
        {
            "humak_university_of_applied_sciences": "HUMAK University of Applied Sciences"
        },
        {
            "centria_university_of_applied_science_(central_ostrobothnia)": "Centria University of Applied Science (Central Ostrobothnia)"
        },
        {
            "vamk_university_of_applied_sciences_(vaasa_polytechnic)": "VAMK University of Applied Sciences (Vaasa Polytechnic)"
        },
        {
            "turku_centre_for_computer_science_(universities_of_turku_region)": "Turku Centre for Computer Science (Universities of Turku Region)"
        },
        {
            "ba_school_of_business_and_finance": "BA School of Business and Finance"
        },
        {
            "aland_polytechnic": "Aland Polytechnic"
        },
        {
            "turku_conservatory": "Turku Conservatory"
        },
        {
            "helsinki_conservatory_of_music": "Helsinki Conservatory of Music"
        }
    ],
    "hong kong": [
        {
            "chinese_university_of_hong_kong": "Chinese University of Hong Kong"
        },
        {
            "university_of_hong_kong": "University of Hong Kong"
        },
        {
            "city_university_of_hong_kong": "City University of Hong Kong"
        },
        {
            "hong_kong_baptist_university": "Hong Kong Baptist University"
        },
        {
            "education_university_of_hong_kong_(hong_kong_institute_of_education)": "Education University of Hong Kong (Hong Kong Institute of Education)"
        },
        {
            "hong_kong_university_of_science_&_technology": "Hong Kong University of Science & Technology"
        },
        {
            "lingnan_university.": "Lingnan University."
        },
        {
            "open_university_of_hong_kong": "Open University of Hong Kong"
        },
        {
            "hong_kong_shue_yan_university": "Hong Kong Shue Yan University"
        },
        {
            "chu_hai_college_of_higher_education,_zhuhai_college,_hong_kong": "Chu Hai College of Higher Education, Zhuhai College, Hong Kong"
        },
        {
            "caritas_institute_of_higher_education_(caritas_francis_hsu_college)": "Caritas Institute of Higher Education (Caritas Francis Hsu College)"
        },
        {
            "hong_kong_academy_for_performing_arts": "Hong Kong Academy for Performing Arts"
        },
        {
            "hang_seng_university_of_hong_kong": "Hang Seng University of Hong Kong"
        },
        {
            "hong_kong_art_school": "Hong Kong Art School"
        },
        {
            "kaplan_higher_education_(asia_pacific_management_institute)_hong_kong": "Kaplan Higher Education (Asia Pacific Management Institute) Hong Kong"
        },
        {
            "caritas_bianchi_college_of_careers": "Caritas Bianchi College of Careers"
        },
        {
            "university_iof_chicago_hong_kong": "University iof Chicago Hong Kong"
        },
        {
            "ccc_kung_lee_college": "CCC Kung Lee College"
        }
    ],
    "norway": [
        {
            "university_of_oslo": "University of Oslo"
        },
        {
            "norwegian_university_of_science_&_technology": "Norwegian University of Science & Technology"
        },
        {
            "university_of_bergen": "University of Bergen"
        },
        {
            "university_of_tromso": "University of Tromso"
        },
        {
            "norwegian_university_of_life_sciences": "Norwegian University of Life Sciences"
        },
        {
            "university_of_stavanger": "University of Stavanger"
        },
        {
            "university_of_agder": "University of Agder"
        },
        {
            "oslo_metropolitan_university": "Oslo Metropolitan University"
        },
        {
            "university_of_south_eastern_norway": "University of South Eastern Norway"
        },
        {
            "bi_norwegian_business_school": "BI Norwegian Business School"
        },
        {
            "western_norway_university_of_applied_sciences": "Western Norway University of Applied Sciences"
        },
        {
            "norwegian_school_of_economics": "Norwegian School of Economics"
        },
        {
            "north_university": "North University"
        },
        {
            "ostfold_university_college": "Ostfold University College"
        },
        {
            "norwegian_university_for_sport_and_physical_education": "Norwegian University for Sport and Physical Education"
        },
        {
            "inland_norway_university_of_applied_sciences": "Inland Norway University of Applied Sciences"
        },
        {
            "university_centre_in_svalbard": "University Centre in Svalbard"
        },
        {
            "molde_university_college": "Molde University College"
        },
        {
            "kristiania_university_college": "Kristiania University College"
        },
        {
            "vid_specialized_university": "VID Specialized University"
        },
        {
            "volda_university_college": "Volda University College"
        },
        {
            "norwegian_defence_university_college": "Norwegian Defence University College"
        },
        {
            "oslo_school_of_architecture": "Oslo School of Architecture"
        },
        {
            "oslo_national_academy_of_the_arts": "Oslo National Academy of the Arts"
        },
        {
            "lovisenberg_diaconal_university_college": "Lovisenberg Diaconal University College"
        },
        {
            "norwegian_state_academy_of_music": "Norwegian State Academy of Music"
        },
        {
            "null": null
        },
        {
            "nla_university_college": "NLA University College"
        },
        {
            "nki_online_studies": "NKI Online Studies"
        },
        {
            "diakonhjemmet_university_college": "Diakonhjemmet University College"
        },
        {
            "norwegian_gestalt_institute": "Norwegian Gestalt Institute"
        },
        {
            "bergen_school_of_architecture": "Bergen School of Architecture"
        },
        {
            "bjorknes_private_school": "Bjorknes Private School"
        },
        {
            "atlantis_medical_college": "Atlantis Medical College"
        },
        {
            "rudolf_steiner_college_of_education": "Rudolf Steiner College of Education"
        },
        {
            "ansgar_bible_school": "Ansgar Bible School"
        }
    ],
    "sweden": [
        {
            "uppsala_university": "Uppsala University"
        },
        {
            "lund_university": "Lund University"
        },
        {
            "karolinska_institute": "Karolinska Institute"
        },
        {
            "royal_institute_of_technology": "Royal Institute of Technology"
        },
        {
            "university_of_gothenburg": "University of Gothenburg"
        },
        {
            "stockholm_university": "Stockholm University"
        },
        {
            "linkoping_university": "Linkoping University"
        },
        {
            "chalmers_university_of_technology": "Chalmers University of Technology"
        },
        {
            "umea_university": "Umea University"
        },
        {
            "swedish_university_of_agricultural_sciences": "Swedish University of Agricultural Sciences"
        },
        {
            "lulea_university_of_technology": "Lulea University of Technology"
        },
        {
            "orebro_university": "Orebro University"
        },
        {
            "linnaeus_university": "Linnaeus University"
        },
        {
            "university_of_karlstad": "University of Karlstad"
        },
        {
            "m\u00e3\u00a4lardalen_university": "M\u00c3\u00a4lardalen University"
        },
        {
            "malmo_university": "Malmo University"
        },
        {
            "halmstad_university": "Halmstad University"
        },
        {
            "stockholm_school_of_economics": "Stockholm School of Economics"
        },
        {
            "mid-sweden_university": "Mid-Sweden University"
        },
        {
            "university_college_of_boras": "University College of Boras"
        },
        {
            "blekinge_institute_of_technology": "Blekinge Institute of Technology"
        },
        {
            "university_of_skovde": "University of Skovde"
        },
        {
            "university_of_gavle": "University of Gavle"
        },
        {
            "sodertorns_university_college": "Sodertorns University College"
        },
        {
            "dalarna_university_college": "Dalarna University College"
        },
        {
            "university_west": "University West"
        },
        {
            "jonkoping_university": "Jonkoping university"
        },
        {
            "kristianstad_university_college": "Kristianstad University College"
        },
        {
            "lund_university_lund_institute_of_technology": "Lund University Lund Institute of Technology"
        },
        {
            "albanova_university_center_royal_institute_of_technology_+_stockholm_university": "AlbaNova University Center Royal Institute of Technology + Stockholm University"
        },
        {
            "swedish_national_defence_college": "Swedish National Defence College"
        },
        {
            "world_maritime_university": "World Maritime University"
        },
        {
            "university_college_of_arts_crafts_and_design_konstfack": "University College of Arts Crafts and Design Konstfack"
        },
        {
            "stockholm_university_of_the_arts": "Stockholm University of the Arts"
        },
        {
            "royal_university_college_of_music_stockholm": "Royal University College of Music Stockholm"
        },
        {
            "beckman's_design_college": "Beckman's design college"
        },
        {
            "royal_university_college_of_fine_arts": "Royal University College of Fine Arts"
        },
        {
            "swedish_school_of_sports_and_health_sciences": "Swedish School of Sports and Health Sciences"
        },
        {
            "sophiahemmet_university_college": "Sophiahemmet University College"
        },
        {
            "red_cross_university_college_rkh": "Red Cross University College RKH"
        },
        {
            "johannelund_theological_seminary": "Johannelund Theological Seminary"
        },
        {
            "erica_foundation": "Erica Foundation"
        },
        {
            "stockholms_free_university": "Stockholms Free University"
        }
    ],
    "israel": [
        {
            "tel_aviv_university": "Tel Aviv University"
        },
        {
            "hebrew_university_of_jerusalem": "Hebrew University of Jerusalem"
        },
        {
            "technion_israel_institute_of_technology": "Technion Israel Institute of Technology"
        },
        {
            "weizmann_institute_of_science": "Weizmann Institute of Science"
        },
        {
            "ben_gurion_university_of_the_negev": "Ben Gurion University of the Negev"
        },
        {
            "bar_ilan_university": "Bar Ilan University"
        },
        {
            "university_of_haifa": "University of Haifa"
        },
        {
            "interdisciplinary_center_herzliya": "Interdisciplinary Center Herzliya"
        },
        {
            "ariel_university": "Ariel University"
        },
        {
            "open_university_of_israel": "Open University of Israel"
        },
        {
            "jerusalem_college_of_technology": "Jerusalem College of Technology"
        },
        {
            "ruppin_academic_center": "Ruppin Academic Center"
        },
        {
            "location_braud_college": "Location Braud College"
        },
        {
            "sapir_college": "Sapir College"
        },
        {
            "beit_berl_college": "Beit Berl College"
        },
        {
            "western_galilee_college": "Western Galilee College"
        },
        {
            "sami_shamoon_college_of_engineering": "Sami Shamoon College of Engineering"
        },
        {
            "shenkar_school_of_engineering_&_design": "Shenkar School of Engineering & Design"
        },
        {
            "tel_hai_academic_college": "Tel Hai Academic College"
        },
        {
            "oranim_academic_college": "Oranim Academic College"
        },
        {
            "what_academic_college.": "What academic college."
        },
        {
            "afeka_tel_aviv_college_of_engineering": "AFEKA Tel Aviv College of Engineering"
        },
        {
            "bezalel_academy_of_art_and_design_jerusalem": "Bezalel Academy of Art and Design Jerusalem"
        },
        {
            "mofet_institute": "Mofet Institute"
        },
        {
            "kinneret_college_on_the_sea_of_galilee": "Kinneret College on the Sea of Galilee"
        },
        {
            "jerusalem_academy_of_music_and_dance": "Jerusalem Academy of Music and Dance"
        },
        {
            "jerusalem_college_of_engineering": "Jerusalem College of Engineering"
        },
        {
            "galillee_college": "Galillee College"
        },
        {
            "academic_center_for_business_law": "Academic Center for Business Law"
        },
        {
            "david_yellin_academic_college_of_education": "David Yellin Academic College of Education"
        },
        {
            "hadassah_academic_college": "Hadassah Academic College"
        },
        {
            "jerusalem_university_college": "Jerusalem University College"
        },
        {
            "sha\u00e2\u20ac\u2122arei_mishpat_college": "Sha\u00e2\u20ac\u2122arei Mishpat College"
        },
        {
            "schechter_institute_of_jewish_studies": "Schechter Institute of Jewish Studies"
        },
        {
            "carmel_academic_center": "Carmel Academic Center"
        }
    ],
    "italy": [
        {
            "university_of_bologna": "University of Bologna"
        },
        {
            "university_of_rome_la_sapienza": "University of Rome La Sapienza"
        },
        {
            "university_of_padua": "University of Padua"
        },
        {
            "university_of_milan": "University of Milan"
        },
        {
            "university_of_naples_federico_ii": "University of Naples Federico II"
        },
        {
            "university_of_pisa_(university_of_pisa)": "University of Pisa (University of Pisa)"
        },
        {
            "university_of_turin": "University of Turin"
        },
        {
            "politecnico_di_milano": "Politecnico di Milano"
        },
        {
            "university_of_florence": "University of Florence"
        },
        {
            "university_of_genova": "University of Genova"
        },
        {
            "university_of_milan_bicocca": "University of Milan Bicocca"
        },
        {
            "university_of_rome_tor_vergata": "University of Rome Tor Vergata"
        },
        {
            "university_of_trento": "University of Trento"
        },
        {
            "polytechnic_of_turin": "Polytechnic of Turin"
        },
        {
            "the_university_of_pavia": "The University of Pavia"
        },
        {
            "universit\u00e3\u00a0_cattolica_del_sacro_cuore": "Universit\u00c3\u00a0 Cattolica del Sacro Cuore"
        },
        {
            "university_of_catania": "University of Catania"
        },
        {
            "university_of_perugia": "University of Perugia"
        },
        {
            "university_of_calabria": "University of Calabria"
        },
        {
            "university_of_bari_aldo_moro": "University of Bari Aldo Moro"
        },
        {
            "university_of_palermo": "University of Palermo"
        },
        {
            "university_of_cagliari": "University of Cagliari"
        },
        {
            "university_of_parma": "University of Parma"
        },
        {
            "university_of_salerno": "University of Salerno"
        },
        {
            "university_of_siena": "University of Siena"
        },
        {
            "university_of_modena_and_reggio_emilia": "University of Modena and Reggio Emilia"
        },
        {
            "university_of_verona": "University of Verona"
        },
        {
            "university_of_trieste": "University of Trieste"
        },
        {
            "university_of_rome_tre": "University of Rome Tre"
        },
        {
            "university_of_ferrara": "University of Ferrara"
        },
        {
            "university_of_udine": "University of Udine"
        },
        {
            "university_of_messina": "University of Messina"
        },
        {
            "university_of_brescia": "University of Brescia"
        },
        {
            "marche_polytechnic_university": "Marche Polytechnic University"
        },
        {
            "university_of_salento_(university_of_lecce)": "University of Salento (University of Lecce)"
        },
        {
            "international_school_for_advanced_studies": "International School for Advanced Studies"
        },
        {
            "university_of_l'aquila": "University of L'Aquila"
        },
        {
            "upper_normal_school_of_pisa": "Upper Normal School of Pisa"
        },
        {
            "ca_'foscari_university_of_venice": "Ca 'Foscari University of Venice"
        },
        {
            "university_of_campania_luigi_vanvitelli": "University of Campania Luigi Vanvitelli"
        },
        {
            "university_of_gabriele_d'annunzio_chieti_and_pescara": "University of Gabriele D'Annunzio Chieti and Pescara"
        },
        {
            "libera_universit\u00e3\u00a0_di_bolzano": "Libera Universit\u00c3\u00a0 di Bolzano"
        },
        {
            "commercial_university_luigi_bocconi_milan": "Commercial University Luigi Bocconi Milan"
        },
        {
            "university_of_sassari": "University of Sassari"
        },
        {
            "university_of_eastern_piedmont_amedeo_avogadro": "University of Eastern Piedmont Amedeo Avogadro"
        },
        {
            "university_of_insubria_varese_and_como": "University of Insubria Varese and Como"
        },
        {
            "polytechnic_of_bari": "Polytechnic of Bari"
        },
        {
            "university_of_urbino_carlo_bo": "University of Urbino Carlo Bo"
        },
        {
            "european_university_institute": "European University Institute"
        },
        {
            "university_of_camerino": "University of Camerino"
        },
        {
            "university_of_tuscia": "University of Tuscia"
        },
        {
            "sant'anna_school_of_advanced_studies_pisa": "Sant'Anna School of Advanced Studies Pisa"
        },
        {
            "university_of_basilicata": "University of Basilicata"
        },
        {
            "university_of_naples_parthenope_(naval_university_institute)": "University of Naples Parthenope (Naval University Institute)"
        },
        {
            "university_of_bergamo": "University of Bergamo"
        },
        {
            "university_of_foggia": "University of Foggia"
        },
        {
            "humanity_university": "Humanity university"
        },
        {
            "university_of_the_magna_graecia_of_catanzaro": "University of the Magna Graecia of Catanzaro"
        },
        {
            "university_of_sannio": "University of Sannio"
        },
        {
            "university_of_mediterranean_studies_of_reggio_calabria": "University of Mediterranean Studies of Reggio Calabria"
        },
        {
            "free_international_university_of_social_studies_luiss_guido_carli": "Free International University of Social Studies Luiss Guido Carli"
        },
        {
            "university_vita_health_san_raffaele": "University Vita Health San Raffaele"
        },
        {
            "gran_sasso_science_institute": "Gran Sasso Science Institute"
        },
        {
            "iuav_university_of_venice_(venice_architecture_university_institute)": "IUAV University of Venice (Venice Architecture University Institute)"
        },
        {
            "university_of_teramo": "University of Teramo"
        },
        {
            "university_of_macerata_(university_of_macerata)": "University of Macerata (University of Macerata)"
        },
        {
            "university_of_niccol\u00e3\u00b2_cusano_(university_of_human_sciences)": "University of Niccol\u00c3\u00b2 Cusano (University of Human Sciences)"
        },
        {
            "university_of_enna_kore": "University of Enna Kore"
        },
        {
            "university_of_cassino": "University of Cassino"
        },
        {
            "university_of_studies_guglielmo_marconi": "University of Studies Guglielmo Marconi"
        },
        {
            "rome_luma_university_(free_university_maria_ss_university)": "Rome luma university (free University Maria Ss university)"
        },
        {
            "high_school_iuss_university_institute_of_upper_studies_of_pavia": "High school IUSS University Institute of Upper Studies of Pavia"
        },
        {
            "university_of_rome_foro_italico_(motor_sciences_university_institute)": "University of Rome Foro Italico (Motor Sciences University Institute)"
        },
        {
            "free_university_of_languages_\u00e2\u20ac\u2039\u00e2\u20ac\u2039and_communication_(iulm_modern_language_university_institute)": "Free University of Languages \u00e2\u20ac\u2039\u00e2\u20ac\u2039and Communication (IULM Modern Language University Institute)"
        },
        {
            "university_of_naples_l'orientale_(oriental_university_of_naples)": "University of Naples L'Orientale (Oriental University of Naples)"
        },
        {
            "uninettuno_international_telematic_university": "UNINETTUNO International Telematic University"
        },
        {
            "university_for_foreigners_of_perugia": "University for foreigners of Perugia"
        },
        {
            "link_campus_university": "Link Campus University"
        },
        {
            "university_of_gastronomic_sciences_colorno": "University of Gastronomic Sciences Colorno"
        },
        {
            "edmund_mach_foundation_of_san_michele_all'adige_(agricultural_institute)": "Edmund Mach Foundation of San Michele All'Adige (Agricultural Institute)"
        },
        {
            "institute_of_advanced_studies_of_lucca_imt": "Institute of Advanced Studies of Lucca Imt"
        },
        {
            "carlo_cattaneo_university_(free_university_carlo_cattaneo_liuc)": "Carlo Cattaneo University (Free University Carlo Cattaneo Liuc)"
        },
        {
            "john_cabot_university": "John Cabot University"
        },
        {
            "universit\u00e3\u00a0_della_valle_d'aosta": "Universit\u00c3\u00a0 della Valle d'Aosta"
        },
        {
            "ecampus_telematics_university": "Ecampus Telematics University"
        },
        {
            "pontifical_gregorian_university": "Pontifical Gregorian University"
        },
        {
            "pontifical_university_of_the_holy_cross": "Pontifical University of the Holy Cross"
        },
        {
            "salesian_pontifical_university": "Salesian Pontifical University"
        },
        {
            "european_university_of_rome": "European University of Rome"
        },
        {
            "university_of_studies_sister_orsola_benincasa_naples": "University of Studies Sister Orsola Benincasa Naples"
        },
        {
            "higher_institute_for_faenza_art_industries": "Higher Institute for Faenza Art Industries"
        },
        {
            "national_academy_of_santa_cecilia": "National Academy of Santa Cecilia"
        },
        {
            "istituto_marangoni_fashion_and_design_school": "Istituto Marangoni Fashion and Design School"
        },
        {
            "pontifical_university_antonianum": "Pontifical University Antonianum"
        },
        {
            "university_for_foreigners_in_siena": "University for foreigners in Siena"
        },
        {
            "american_academy_in_rome": "American Academy in Rome"
        },
        {
            "pontifical_lateran_university": "Pontifical Lateran University"
        },
        {
            "new_academy_of_fine_arts_in_milan": "New Academy of Fine Arts in Milan"
        },
        {
            "domus_academy": "Domus Academy"
        },
        {
            "european_school_of_oncology": "European School of Oncology"
        },
        {
            "academy_of_fine_arts_of_brera": "Academy of Fine Arts of Brera"
        },
        {
            "polimoda_fashion_design_school": "POLIMODA Fashion Design School"
        },
        {
            "american_university_of_rome": "American University of Rome"
        },
        {
            "mib_school_of_management": "MIB School of Management"
        },
        {
            "free_mediterranean_university_jean_monnet_lum": "Free Mediterranean University Jean Monnet Lum"
        },
        {
            "experimental_cinematography_center_national_school_of_cinema_national_film": "Experimental Cinematography Center National School of Cinema National Film"
        },
        {
            "venice_international_university": "Venice International University"
        },
        {
            "conservatory_of_g_verdi_music_in_milan": "Conservatory of G Verdi Music in Milan"
        },
        {
            "milano_civic_schools_foundation_of_milan": "Milano Civic Schools Foundation of Milan"
        },
        {
            "pegaso_telematic_university": "Pegaso Telematic University"
        },
        {
            "pontifical_university_s_anselmo": "Pontifical University s Anselmo"
        },
        {
            "carrara_academy_of_bergamo": "Carrara Academy of Bergamo"
        },
        {
            "design_polytechnic_school": "Design Polytechnic School"
        },
        {
            "italian_academy": "Italian Academy"
        },
        {
            "university_of_rome_international_studies_(free_university_of_san_pio_v)": "University of Rome International Studies (Free University of San Pio V)"
        },
        {
            "universitas_mercatorum_telematics_university_of_italian_chambers_of_commerce": "Universitas Mercatorum Telematics University of Italian Chambers of Commerce"
        },
        {
            "athenaeum_pontifical_queen_of_the_apostles": "Athenaeum Pontifical Queen of the Apostles"
        },
        {
            "santa_cecilia_music_conservatory": "Santa Cecilia Music Conservatory"
        },
        {
            "academy_of_fine_arts_of_bologna": "Academy of Fine Arts of Bologna"
        },
        {
            "pontifical_biblical_institute": "Pontifical Biblical Institute"
        },
        {
            "applied_art_institute_and_torino_design": "Applied Art Institute and Torino Design"
        },
        {
            "rome_university_of_fine_arts_(free_academy_of_fine_arts_in_rome)": "Rome University of Fine Arts (Free Academy of Fine Arts in Rome)"
        },
        {
            "academy_of_fine_arts_of_venice": "Academy of Fine Arts of Venice"
        },
        {
            "san_pietro_music_conservatory_in_majella": "San Pietro Music Conservatory in Majella"
        },
        {
            "rome_business_school": "Rome Business School"
        },
        {
            "conservatory_of_music_giuseppe_verdi_in_como": "Conservatory of Music Giuseppe Verdi in Como"
        },
        {
            "florence_institute_of_design_international": "Florence Institute of Design International"
        },
        {
            "pontifical_university_san_tommaso_d'aquino_(angelicum_university)": "Pontifical University San Tommaso d'Aquino (Angelicum University)"
        },
        {
            "academy_of_fine_arts_santagiulia_brescia": "Academy of Fine Arts SantaGiulia Brescia"
        },
        {
            "telematic_university_italian_university_line_florence": "Telematic University Italian University Line Florence"
        },
        {
            "higher_institute_for_artistic_industries_of_urbino": "Higher Institute for Artistic Industries of Urbino"
        },
        {
            "academy_of_fine_arts_of_carrara": "Academy of Fine Arts of Carrara"
        },
        {
            "free_academy_of_fine_arts_brescia": "Free Academy of Fine Arts Brescia"
        },
        {
            "seraphicum_pontifical_theological_faculty": "Seraphicum Pontifical Theological Faculty"
        },
        {
            "conservatory_in_casella_l'aquila": "Conservatory in Casella L'Aquila"
        },
        {
            "bologna_giovanni_battista_martini_conservatory": "Bologna Giovanni Battista Martini Conservatory"
        },
        {
            "higher_institute_for_firenze_artistic_industries": "Higher Institute for Firenze Artistic Industries"
        },
        {
            "lucky_giustino_telematic_university": "Lucky Giustino Telematic University"
        },
        {
            "florence_design_academy": "Florence Design Academy"
        },
        {
            "istituto_modartech": "Istituto Modartech"
        },
        {
            "high_school_for_linguistic_mediators_carlo_bo": "High school for linguistic mediators Carlo Bo"
        },
        {
            "academy_of_fine_arts_of_urbino": "Academy of Fine Arts of Urbino"
        },
        {
            "istituto_patristico_augustinianum_patristicum": "Istituto Patristico Augustinianum Patristicum"
        },
        {
            "music_conservatory_niccolo_piccinni_bari": "Music Conservatory Niccolo Piccinni Bari"
        },
        {
            "fine_arts_academy_of_frosinone": "Fine Arts Academy of Frosinone"
        },
        {
            "ligustica_academy_of_fine_arts_in_turin": "Ligustica Academy of Fine Arts in Turin"
        },
        {
            "national_dance_academy": "National Dance Academy"
        },
        {
            "music_conservatory_lucio_campiani_mantova": "Music Conservatory Lucio Campiani Mantova"
        },
        {
            "tito_schipa_lecce_music_conservatory": "Tito Schipa Lecce Music Conservatory"
        },
        {
            "dicampus_s_san_pellegrino": "Dicampus s San Pellegrino"
        },
        {
            "conservatory_in_vivaldi_alessandria": "Conservatory in Vivaldi Alessandria"
        },
        {
            "telematic_university_leonardo_da_vinci": "Telematic University Leonardo da Vinci"
        },
        {
            "pontifical_institute_of_christian_archeology": "Pontifical Institute of Christian Archeology"
        },
        {
            "upper_institute_of_architecture_and_design_isad_milan": "Upper Institute of Architecture and Design Isad Milan"
        },
        {
            "musical_institute_vincenzo_bellini_caltanissetta": "Musical Institute Vincenzo Bellini Caltanissetta"
        },
        {
            "academy_of_fine_arts_of_naples": "Academy of Fine Arts of Naples"
        },
        {
            "higher_institute_for_conservation_and_restoration": "Higher Institute for Conservation and Restoration"
        },
        {
            "alfonsiana_academy_superior_institute_of_moral_theology": "Alfonsiana Academy Superior Institute of Moral Theology"
        },
        {
            "conservatory_of_castelfranco_veneto_agostino_steffani": "Conservatory of Castelfranco Veneto Agostino Steffani"
        },
        {
            "music_conservatory_l_perosi_campobasso": "Music Conservatory L Perosi Campobasso"
        },
        {
            "academy_of_fine_arts_of_lecce": "Academy of Fine Arts of Lecce"
        },
        {
            "superior_institute_of_musical_studies_braga": "Superior Institute of Musical Studies Braga"
        },
        {
            "guido_cantelli_music_conservatory": "Guido Cantelli Music Conservatory"
        },
        {
            "state_music_conservatory_f_venezze_rovigo": "State Music Conservatory F Venezze Rovigo"
        },
        {
            "conservatory_in_brescia_luca_marenzio": "Conservatory in Brescia Luca Marenzio"
        },
        {
            "istituto_universitario_olandese_di_storia_dell'arte": "Istituto Universitario Olandese di Storia dell'Arte"
        },
        {
            "academy_of_fine_arts_reggio_calabria": "Academy of Fine Arts Reggio Calabria"
        },
        {
            "conservatory_of_latin_music_ottorino_respighi": "Conservatory of Latin Music Ottorino Respighi"
        },
        {
            "academy_of_fine_arts_of_rome": "Academy of Fine Arts of Rome"
        },
        {
            "musical_institute_g_paisiello_taranto": "Musical Institute G Paisiello Taranto"
        },
        {
            "high_school_for_linguistic_mediators_gregory_vii": "High school for linguistic mediators Gregory VII"
        },
        {
            "academy_of_belle_lorenzo_from_viterbo": "Academy of Belle Lorenzo from Viterbo"
        },
        {
            "pontifical_east_institute": "Pontifical East Institute"
        },
        {
            "sabina_university": "Sabina University"
        },
        {
            "high_school_for_language_mediators_palermo": "High school for Language Mediators Palermo"
        },
        {
            "conservatory_of_music_luisa_d'anunzio_pescara": "Conservatory of Music Luisa d'Anunzio Pescara"
        },
        {
            "academy_of_fine_arts_of_catania": "Academy of Fine Arts of Catania"
        },
        {
            "academy_fine_arts_ravenna_abaravenna": "Academy Fine Arts Ravenna Abaravenna"
        },
        {
            "accademia_del_lusso": "Accademia del Lusso"
        },
        {
            "collegio_carlo_alberto": "Collegio Carlo Alberto"
        },
        {
            "istituto_europeo_di_design_(ied)": "Istituto Europeo Di Design (IED)"
        },
        {
            "istituto_europeo_di_design_(ied)": "Istituto Europeo Di Design (IED)"
        },
        {
            "johns_hopkins_university": "Johns Hopkins University"
        },
        {
            "milano_fashion_institute": "Milano Fashion Institute"
        },
        {
            "poli.design_-_milan_(italy)": "POLI.design - Milan (Italy)"
        },
        {
            "politecnico_di_milano": "Politecnico di Milano"
        },
        {
            "sda_bocconi_school_of_management": "SDA Bocconi School of Management"
        },
        {
            "swiss_school_of_management": "Swiss School of Management"
        },
        {
            "universit\u00e3\u00a0_cattolica_del_sacro_cuore": "Universit\u00c3\u00a0 Cattolica del Sacro Cuore"
        }
    ],
    "south korea": [
        {
            "seoul_national_university": "Seoul National University"
        },
        {
            "korea_advanced_institute_of_science_&_technology_kaist": "Korea Advanced Institute of Science & Technology KAIST"
        },
        {
            "sungkyunkwan_university": "Sungkyunkwan University"
        },
        {
            "yonsei_university": "Yonsei University"
        },
        {
            "hanyang_university": "Hanyang University"
        },
        {
            "pohang_university_of_science_&_technology": "Pohang University of Science & Technology"
        },
        {
            "kyungpook_(kyungbook)_national_university": "Kyungpook (Kyungbook) National University"
        },
        {
            "kyung_hee_university": "Kyung Hee University"
        },
        {
            "pusan_national_university_(miryang)": "Pusan National University (Miryang)"
        },
        {
            "chung_ang_university": "Chung Ang University"
        },
        {
            "ulsan_national_institute_of_science_&_technology_unist": "Ulsan National Institute of Science & Technology UNIST"
        },
        {
            "ewha_womens_university": "Ewha Womens University"
        },
        {
            "konkuk_university": "Konkuk University"
        },
        {
            "university_of_ulsan": "University of Ulsan"
        },
        {
            "inha_university": "Inha University"
        },
        {
            "sejong_university": "Sejong University"
        },
        {
            "ajou_university": "Ajou University"
        },
        {
            "kangwon_national_university_(samcheok_national_university)": "Kangwon National University (Samcheok National University)"
        },
        {
            "chonnam_national_university_(yosu)": "Chonnam National University (Yosu)"
        },
        {
            "sogang_university": "Sogang University"
        },
        {
            "gwangju_institute_of_science_&_technology": "Gwangju Institute of Science & Technology"
        },
        {
            "chungnam_national_university": "Chungnam National University"
        },
        {
            "dongguk_university": "Dongguk University"
        },
        {
            "yeungnam_university": "Yeungnam University"
        },
        {
            "chungbuk_national_university": "Chungbuk National University"
        },
        {
            "pukyong_national_university": "Pukyong National University"
        },
        {
            "korea_university": "Korea University"
        },
        {
            "catholic_university_of_korea": "Catholic University of Korea"
        },
        {
            "university_of_seoul": "University of Seoul"
        },
        {
            "dankook_university": "Dankook University"
        },
        {
            "gyeongsang_national_university": "Gyeongsang National University"
        },
        {
            "kookmin_university": "Kookmin University"
        },
        {
            "soongsil_university": "Soongsil University"
        },
        {
            "inje_university": "Inje University"
        },
        {
            "sookmyung_women's_university": "Sookmyung Women's University"
        },
        {
            "hallym_university": "Hallym University"
        },
        {
            "kongju_national_university": "Kongju National University"
        },
        {
            "gachon_university": "Gachon University"
        },
        {
            "wonkwang_university": "Wonkwang University"
        },
        {
            "daegu_gyeongbuk_institute_of_science_&_technology": "Daegu Gyeongbuk Institute of Science & Technology"
        },
        {
            "soonchunhyang_university": "Soonchunhyang University"
        },
        {
            "incheon_national_university_(university_of_incheon)": "Incheon National University (University of Incheon)"
        },
        {
            "jeju_national_university_(cheju)": "Jeju National University (Cheju)"
        },
        {
            "kwangwoon_university": "Kwangwoon University"
        },
        {
            "chosun_university": "Chosun University"
        },
        {
            "hankuk_university_of_foreign_studies": "Hankuk University of Foreign Studies"
        },
        {
            "seoul_national_university_of_science_&_technology": "Seoul National University of Science & Technology"
        },
        {
            "hongik_university": "Hongik University"
        },
        {
            "myongji_university": "Myongji University"
        },
        {
            "kumoh_national_institute_of_technology": "Kumoh National Institute of Technology"
        },
        {
            "keimyung_university": "Keimyung University"
        },
        {
            "hanbat_national_university": "Hanbat National University"
        },
        {
            "daegu_university": "Daegu University"
        },
        {
            "university_of_science_&_technology_daejeon": "University of Science & Technology Daejeon"
        },
        {
            "korea_aerospace_university_(hankuk_aviation_university)": "Korea Aerospace University (Hankuk Aviation University)"
        },
        {
            "kunsan_national_university": "Kunsan National University"
        },
        {
            "changwon_national_university": "Changwon National University"
        },
        {
            "mokpo_national_university": "Mokpo National University"
        },
        {
            "kyonggi_university": "Kyonggi University"
        },
        {
            "handong_global_university": "Handong Global University"
        },
        {
            "sang_myung_university": "Sang Myung University"
        },
        {
            "catholic_university_of_daegu": "Catholic University of Daegu"
        },
        {
            "gangneung-wonju_national_university_(kangnung_wonju)": "Gangneung-Wonju National University (Kangnung Wonju)"
        },
        {
            "cha_university": "Cha University"
        },
        {
            "korea_national_university_of_transportation": "Korea National University of Transportation"
        },
        {
            "dong-a_university": "Dong-A University"
        },
        {
            "sunchon_national_university": "Sunchon National University"
        },
        {
            "korea_maritime_university": "Korea Maritime University"
        },
        {
            "sungshin_women's_university": "Sungshin Women's University"
        },
        {
            "korea_university_of_technology_and_education_koreatech": "Korea University of Technology and Education KoreaTech"
        },
        {
            "dong_eui_university": "Dong Eui University"
        },
        {
            "dongseo_university": "Dongseo University"
        },
        {
            "sun_moon_university": "Sun Moon University"
        },
        {
            "eulji_university_(seoul_health_college)": "Eulji University (Seoul Health College)"
        },
        {
            "kyungnam_university": "Kyungnam University"
        },
        {
            "seoul_women's_university": "Seoul Women's University"
        },
        {
            "korea_polytechnic_university": "Korea Polytechnic University"
        },
        {
            "suwon_university": "Suwon University"
        },
        {
            "kyungsung_university": "Kyungsung University"
        },
        {
            "hankyong_national_university": "Hankyong National University"
        },
        {
            "hansung_university": "Hansung University"
        },
        {
            "duksung_women's_university": "Duksung Women's University"
        },
        {
            "hoseo_university": "Hoseo University"
        },
        {
            "hannam_university": "Hannam University"
        },
        {
            "andong_national_university": "Andong National University"
        },
        {
            "konyang_university": "Konyang University"
        },
        {
            "silla_university": "Silla University"
        },
        {
            "cheongju_university": "Cheongju University"
        },
        {
            "jeonju_university": "Jeonju University"
        },
        {
            "gyeongnam_national_university_of_science_&_technology_(jinju_national_university)": "Gyeongnam National University of Science & Technology (Jinju National University)"
        },
        {
            "jeonbuk_national_university": "Jeonbuk National University"
        },
        {
            "woosuk_university": "Woosuk University"
        },
        {
            "woosong_university_(technical_college)": "Woosong University (Technical College)"
        },
        {
            "sangji_university": "Sangji University"
        },
        {
            "daegu_haany_university_(kyungsan_university)": "Daegu Haany University (Kyungsan University)"
        },
        {
            "catholic_kwandong_university": "Catholic Kwandong University"
        },
        {
            "korea_national_open_university": "Korea National Open University"
        },
        {
            "sungkyul_university": "Sungkyul University"
        },
        {
            "sahmyook_university": "Sahmyook University"
        },
        {
            "dongshin_university": "Dongshin University"
        },
        {
            "kyungil_university": "Kyungil University"
        },
        {
            "dongduk_women's_university": "Dongduk Women's University"
        },
        {
            "tongmyong_university": "Tongmyong University"
        },
        {
            "mokwon_university": "Mokwon University"
        },
        {
            "pai_chai_university": "Pai Chai University"
        },
        {
            "hanseo_university": "Hanseo University"
        },
        {
            "korea_institute_for_advanced_study": "Korea Institute for Advanced Study"
        },
        {
            "seokyeong_university": "Seokyeong University"
        },
        {
            "semyung_university": "Semyung University"
        },
        {
            "korea_national_university_of_education": "Korea National University of Education"
        },
        {
            "joongbu_university": "Joongbu University"
        },
        {
            "catholic_university_of_pusan": "Catholic University of Pusan"
        },
        {
            "seowon_university": "Seowon University"
        },
        {
            "daejin_university": "Daejin University"
        },
        {
            "academy_of_korean_studies": "Academy of Korean Studies"
        },
        {
            "yeungjin_university": "Yeungjin University"
        },
        {
            "seoul_national_university_of_education": "Seoul National University of Education"
        },
        {
            "dongyang_technical_college": "Dongyang Technical College"
        },
        {
            "mokpo_national_maritime_university": "Mokpo National Maritime University"
        },
        {
            "gongju_national_university_of_education": "Gongju National University of Education"
        },
        {
            "catholic_sangji_college": "Catholic Sangji College"
        },
        {
            "korea_nazarene_university": "Korea Nazarene University"
        },
        {
            "pusan_university_of_foreign_studies": "Pusan University of Foreign Studies"
        },
        {
            "yuhan_university": "Yuhan University"
        },
        {
            "anyang_university": "Anyang University"
        },
        {
            "gyeongin_national_university_of_education": "Gyeongin National University of Education"
        },
        {
            "torch_trinity_graduate_university": "Torch Trinity Graduate University"
        },
        {
            "yong-in_university": "Yong-In University"
        },
        {
            "myongji_college": "Myongji College"
        },
        {
            "kdi_school_of_public_policy_and_management": "KDI School of Public Policy and Management"
        },
        {
            "korea_national_university_of_arts": "Korea National University of Arts"
        },
        {
            "changshin_university": "Changshin University"
        },
        {
            "yong-in_songdam_college": "Yong-In Songdam College"
        },
        {
            "taejon_catholic_university": "Taejon Catholic University"
        },
        {
            "kyungbuk_college": "Kyungbuk College"
        },
        {
            "namseoul_university": "Namseoul University"
        },
        {
            "honam_university": "Honam University"
        },
        {
            "korean_bible_university": "Korean Bible University"
        },
        {
            "daelim_college": "Daelim College"
        },
        {
            "kangnam_university": "Kangnam University"
        },
        {
            "mokpo_science_university": "Mokpo Science University"
        },
        {
            "busan_institute_of_science_&_technology_(pusan_info-tech_college)": "Busan Institute of Science & Technology (Pusan Info-Tech College)"
        },
        {
            "busan_health_university": "Busan Health University"
        },
        {
            "hanshin_university": "Hanshin University"
        },
        {
            "chinju_national_university_of_education": "Chinju National University of Education"
        },
        {
            "doowon_technical_college": "Doowon Technical College"
        },
        {
            "kyungdong_university": "Kyungdong University"
        },
        {
            "korea_polytechnics": "Korea Polytechnics"
        },
        {
            "sehan_university_(daebul_university)": "Sehan University (Daebul University)"
        },
        {
            "youngsan_university": "Youngsan University"
        },
        {
            "yes": "yes"
        },
        {
            "kookje_university": "Kookje University"
        },
        {
            "geumgang_university": "Geumgang University"
        },
        {
            "yeojoo_institute_of_technology": "Yeojoo Institute of Technology"
        },
        {
            "sung_kong_hoe_university": "Sung Kong Hoe University"
        },
        {
            "saekyung_college": "Saekyung College"
        },
        {
            "korea_university_of_media_arts_(kongju_communication_arts_college)": "Korea University of Media Arts (Kongju Communication Arts College)"
        },
        {
            "guangju_university": "Guangju University"
        },
        {
            "kosin_university": "Kosin University"
        },
        {
            "hansei_university": "Hansei University"
        },
        {
            "presbyterian_university_and_theological_seminary": "Presbyterian University and Theological Seminary"
        },
        {
            "kyungb_hee_cyber_university": "Kyungb Hee Cyber University"
        },
        {
            "seoul_institute_of_the_arts": "Seoul Institute of The Arts"
        },
        {
            "howon_university": "Howon University"
        },
        {
            "hyupsung_university": "Hyupsung University"
        },
        {
            "chodang_university": "Chodang University"
        },
        {
            "korean_academy_of_science_&_technology": "Korean Academy of Science & Technology"
        },
        {
            "open_cyber_university": "Open Cyber University"
        },
        {
            "seoul_teological_university": "Seoul Teological University"
        },
        {
            "pyeongtaek_university": "Pyeongtaek University"
        },
        {
            "yeungnam_university_college": "Yeungnam University College"
        },
        {
            "nambu_university": "Nambu University"
        },
        {
            "chungwoon_university": "Chungwoon University"
        },
        {
            "university_of_brain_education": "university of brain education"
        },
        {
            "busan_gyeongsang_college": "Busan Gyeongsang College"
        },
        {
            "shingu_college": "Shingu College"
        },
        {
            "gyeongju_university": "Gyeongju University"
        },
        {
            "far_east_university_korea": "Far East University Korea"
        },
        {
            "bucheon_college": "Bucheon College"
        },
        {
            "suwon_science_college": "Suwon Science College"
        },
        {
            "honam_theological_university_&_seminary": "Honam Theological University & Seminary"
        },
        {
            "kaywon_school_of_art_and_design": "Kaywon School of Art and Design"
        },
        {
            "korea_national_sport_university": "Korea National Sport University"
        },
        {
            "chongshin_university": "Chongshin University"
        },
        {
            "daegu_health_college": "Daegu Health College"
        },
        {
            "busan_national_university_of_education": "Busan National University of Education"
        },
        {
            "gwangju_national_university_of_education": "Gwangju National University of Education"
        },
        {
            "korea_national_police_university": "Korea National Police University"
        },
        {
            "korean_national_university_of_cultural_heritage": "Korean National University of Cultural Heritage"
        },
        {
            "songwon_university": "Songwon University"
        },
        {
            "dong-ah_broadcasting_college": "Dong-Ah Broadcasting College"
        },
        {
            "jeonju_technical_college": "Jeonju Technical College"
        },
        {
            "shinhan_university": "Shinhan University"
        },
        {
            "baehwa_women's_university": "Baehwa Women's university"
        },
        {
            "masan_university": "Masan University"
        },
        {
            "cyber_hankuk_university_of_foreign_studies": "Cyber Hankuk University of Foreign Studies"
        },
        {
            "daegu_cyber_university": "Daegu Cyber University"
        },
        {
            "korea_baptist_theological_university": "Korea Baptist Theological University"
        },
        {
            "korea_soongsil_cyber_university": "Korea Soongsil Cyber University"
        },
        {
            "hyejeon_college": "Hyejeon College"
        },
        {
            "seoul_cultural_arts_university_(hansung_digital_university)": "Seoul Cultural Arts University (Hansung Digital University)"
        },
        {
            "suwon_womens_university": "Suwon Womens University"
        },
        {
            "kyungwoon_university": "Kyungwoon University"
        },
        {
            "kyungmin_college": "Kyungmin College"
        },
        {
            "shinsung_university": "Shinsung University"
        },
        {
            "dongnam_health_college": "Dongnam Health College"
        },
        {
            "international_university_of_korea": "International University of Korea"
        },
        {
            "asia_united_theological_university": "Asia United Theological University"
        },
        {
            "ulsan_college": "Ulsan College"
        },
        {
            "yeonsung_university_(anyang_technical_college": "Yeonsung University (Anyang Technical College"
        },
        {
            "kyungbok_university": "Kyungbok University"
        },
        {
            "yonam_college_of_agriculture": "Yonam College of Agriculture"
        },
        {
            "soongeui_womens_college": "Soongeui Womens College"
        },
        {
            "paekche_art_college": "Paekche Art College"
        },
        {
            "seoul_university_of_venture_&_information": "Seoul University of Venture & Information"
        },
        {
            "hanil_university_&_presbyterian_theological_seminary": "Hanil University & Presbyterian Theological Seminary"
        },
        {
            "korea_tourism_college": "Korea Tourism College"
        },
        {
            "sohae_college": "Sohae College"
        },
        {
            "taegu_science_university": "Taegu Science University"
        },
        {
            "chungbuk_health_&_science_university_(juseong_university)": "Chungbuk Health & Science University (Juseong University)"
        },
        {
            "seojeong_college": "Seojeong College"
        },
        {
            "cheongju_national_university_of_education": "Cheongju National University of Education"
        },
        {
            "yeungjin_cyber_college": "Yeungjin Cyber College"
        },
        {
            "dong-a_in_jae_university": "Dong-A In Jae University"
        },
        {
            "youngnam_theological_university_&_seminary": "Youngnam Theological University & Seminary"
        },
        {
            "methodist_theological_university": "Methodist Theological University"
        },
        {
            "hallym_polytechnic_university_(hallym_college_of_information_&_industry)": "Hallym Polytechnic University (Hallym College of Information & Industry)"
        },
        {
            "mokpo_catholic_university": "Mokpo Catholic University"
        },
        {
            "daedong_college": "Daedong College"
        },
        {
            "busan_(pusan)_women's_college": "Busan (Pusan) Women's College"
        },
        {
            "kwangshin_university": "Kwangshin University"
        },
        {
            "gangneung_yeongdong_university": "Gangneung Yeongdong University"
        },
        {
            "dong_seoul_university": "Dong Seoul University"
        },
        {
            "wonkwang_health_science_university": "Wonkwang Health Science University"
        },
        {
            "daeshin_university_(taeshin_christian_university)": "Daeshin University (Taeshin Christian University)"
        },
        {
            "jeonbuk_science_college": "Jeonbuk Science College"
        },
        {
            "yonam_institute_of_digital_technology": "Yonam Institute of Digital Technology"
        },
        {
            "suseong_college_(daegu_polytechnic_college)": "Suseong College (Daegu Polytechnic College)"
        },
        {
            "seoyeng_university_(seokang_college)": "Seoyeng University (Seokang College)"
        },
        {
            "world_cyber_college": "World Cyber College"
        },
        {
            "agricultural_cooperative_college": "Agricultural Cooperative College"
        },
        {
            "dongkang_college": "Dongkang College"
        },
        {
            "suncheon_first_college": "Suncheon First College"
        },
        {
            "joong_ang_sungha_university": "Joong Ang Sungha University"
        },
        {
            "seoul_womens_college_of_nursing": "Seoul Womens College of Nursing"
        },
        {
            "gwangju_health_university": "Gwangju Health University"
        },
        {
            "andong_science_college": "Andong Science College"
        },
        {
            "sahmyook_health_university": "Sahmyook Health University"
        },
        {
            "tongwon_university": "Tongwon University"
        },
        {
            "hapdong_theological_seminary": "Hapdong Theological Seminary"
        },
        {
            "songho_college": "Songho College"
        },
        {
            "seoul_jangsin_university": "Seoul Jangsin University"
        },
        {
            "u1_university": "U1 University"
        },
        {
            "gangdong_university_(keukdong_college)": "Gangdong University (Keukdong College)"
        },
        {
            "gangwon_state_university": "Gangwon State University"
        },
        {
            "gumi_college": "Gumi College"
        },
        {
            "daegu_technical_university": "Daegu Technical University"
        },
        {
            "hanyoung_theological_university": "Hanyoung Theological University"
        },
        {
            "shin_ansan_university": "Shin Ansan University"
        },
        {
            "shingyeong_university": "Shingyeong University"
        },
        {
            "pohang_university": "Pohang University"
        },
        {
            "suwon_catholic_university": "Suwon Catholic University"
        },
        {
            "seoul_arts_(hansung_technical_college)": "Seoul Arts (Hansung Technical College)"
        },
        {
            "choonhae_college_of_health_sciences": "Choonhae College of Health Sciences"
        },
        {
            "shinheung_college": "Shinheung College"
        },
        {
            "changwon_moonsung_university": "Changwon Moonsung University"
        },
        {
            "jinju_health_college": "Jinju Health College"
        },
        {
            "international_design_school_for_advanced_studies": "International Design School for Advanced Studies"
        },
        {
            "kangwon_tourism_college": "Kangwon Tourism College"
        },
        {
            "ajou_motor_college": "Ajou Motor College"
        },
        {
            "youngnam_foreign_language_college": "Youngnam Foreign Language College"
        },
        {
            "inha_technical_college": "Inha Technical College"
        },
        {
            "kyeongdo_provincial_college": "Kyeongdo Provincial College"
        },
        {
            "chungbuk_provincial_university": "Chungbuk Provincial University"
        },
        {
            "jesus_university_(margaret_pritchard_university)": "Jesus University (Margaret Pritchard University)"
        },
        {
            "busan_presbyterian_university_(busan_jangsin_university)": "Busan Presbyterian University (Busan Jangsin University)"
        },
        {
            "korea_national_university_of_welfare": "Korea National University of Welfare"
        },
        {
            "daeduk_university": "Daeduk University"
        },
        {
            "jeonnam_state_university": "Jeonnam State University"
        },
        {
            "university_of_gyeongnam_geochang": "University of Gyeongnam Geochang"
        },
        {
            "kunsan_college_of_nursing": "Kunsan College of Nursing"
        },
        {
            "jeju_tourism_university": "Jeju Tourism University"
        },
        {
            "chungnam_state_university": "Chungnam State University"
        },
        {
            "chunnam_techno_university": "Chunnam Techno University"
        },
        {
            "chosun_nursing_college": "Chosun Nursing College"
        },
        {
            "koguryeo_college_(naju_college)": "Koguryeo College (Naju College)"
        },
        {
            "gukje_cyber_university": "Gukje Cyber University"
        },
        {
            "jeonju_(chonju)_national_university_of_education": "Jeonju (Chonju) National University of Education"
        },
        {
            "woongji_accounting_&_tax_college": "Woongji Accounting & Tax College"
        },
        {
            "kwangju_catholic_university": "Kwangju Catholic University"
        },
        {
            "christian_college_of_nursing": "Christian College of Nursing"
        },
        {
            "songgok_college": "Songgok College"
        },
        {
            "gyeongbuk_college_of_health": "Gyeongbuk College of Health"
        },
        {
            "gwangyang_health_sciences_university": "Gwangyang Health Sciences University"
        },
        {
            "seoul_social_welfare_graduate_university": "Seoul Social Welfare Graduate University"
        },
        {
            "korea_university_of_international_studies": "Korea University of International Studies"
        }
    ],
    "spain": [
        {
            "barcelona_university": "Barcelona University"
        },
        {
            "autonomous_university_of_barcelona": "Autonomous University of Barcelona"
        },
        {
            "university_of_valencia": "University of Valencia"
        },
        {
            "university_of_granada": "University of Granada"
        },
        {
            "autonomous_university_of_madrid": "Autonomous University of Madrid"
        },
        {
            "sevilla_university": "Sevilla University"
        },
        {
            "polytechnic_university_of_catalonia_barcelonatech": "Polytechnic University of Catalonia BarcelonaTech"
        },
        {
            "pompeu_fabra_university": "Pompeu Fabra University"
        },
        {
            "polytechnic_university_of_valencia": "Polytechnic University of Valencia"
        },
        {
            "polytechnic_university_of_madrid": "Polytechnic University of Madrid"
        },
        {
            "zaragoza's_university": "Zaragoza's University"
        },
        {
            "malaga_university": "Malaga University"
        },
        {
            "university_of_salamanca": "University of Salamanca"
        },
        {
            "oviedo_university": "Oviedo University"
        },
        {
            "university_of_santiago_de_compostela": "University of Santiago de Compostela"
        },
        {
            "university_of_murcia": "University of Murcia"
        },
        {
            "castilla-la_mancha_university": "Castilla-La Mancha university"
        },
        {
            "university_carlos_iii_of_madrid": "University Carlos III of Madrid"
        },
        {
            "universitat_d'alacant": "Universitat d'Alacant"
        },
        {
            "rovira_i_virgili_university": "Rovira i Virgili University"
        },
        {
            "universitat_jaume_i": "Universitat Jaume I"
        },
        {
            "university_of_cantabria": "University of cantabria"
        },
        {
            "national_university_of_distance_education_uned": "National University of Distance Education UNED"
        },
        {
            "university_of_alcal\u00e3\u00a1.": "University of Alcal\u00c3\u00a1."
        },
        {
            "university_of_girona": "University of Girona"
        },
        {
            "university_of_la_laguna": "University of La Laguna"
        },
        {
            "king_juan_carlos_university": "King Juan Carlos University"
        },
        {
            "university_of_extremadura": "University of Extremadura"
        },
        {
            "cadiz_university": "Cadiz University"
        },
        {
            "open_university_of_catalonia": "Open University of Catalonia"
        },
        {
            "university_of_a_coru\u00e3\u00b1a": "University of A Coru\u00c3\u00b1a"
        },
        {
            "university_of_las_palmas_de_gran_canaria": "University of Las Palmas de Gran Canaria"
        },
        {
            "miguel_hern\u00e3\u00a1ndez_university": "Miguel Hern\u00c3\u00a1ndez University"
        },
        {
            "jaen_university": "Jaen University"
        },
        {
            "pablo_de_olavide_university": "Pablo de Olavide University"
        },
        {
            "university_of_la_rioja": "University of La Rioja"
        },
        {
            "complutense_university_of_madrid": "Complutense University of Madrid"
        },
        {
            "public_university_of_navarre": "Public university of Navarre"
        },
        {
            "polytechnic_university_of_cartagena": "Polytechnic University of Cartagena"
        },
        {
            "university_of_deusto": "University of Deusto"
        },
        {
            "university_of_the_balearic_islands": "University of the Balearic Islands"
        },
        {
            "university_of_the_basque_country": "University of the Basque Country"
        },
        {
            "ramon_llull_university": "Ramon Llull University"
        },
        {
            "ramon_llull_university_esade_business_school_barcelona": "Ramon Llull University ESADE Business School Barcelona"
        },
        {
            "university_of_burgos": "University of Burgos"
        },
        {
            "catholic_university_san_antonio_de_murcia": "Catholic University San Antonio de Murcia"
        },
        {
            "university_of_navarra": "university of Navarra"
        },
        {
            "ie_business_school": "IE Business School"
        },
        {
            "iese_business_school_university_of_navarra": "IESE Business School University of Navarra"
        },
        {
            "international_university_of_catalonia": "International University of Catalonia"
        },
        {
            "university_of_valladolid": "University of Valladolid"
        },
        {
            "university_of_cordoba": "University of Cordoba"
        },
        {
            "university_of_vic_central_university_of_catalonia": "University of Vic Central University of Catalonia"
        },
        {
            "university_of_vigo": "University of Vigo"
        },
        {
            "catholic_university_of_valencia_san_vicente_martir": "Catholic University of Valencia San Vicente Martir"
        },
        {
            "university_of_almeria": "University of Almeria"
        },
        {
            "mondragon_university": "Mondragon University"
        },
        {
            "university_of_leon": "University of Leon"
        },
        {
            "university_of_lleida": "University of Lleida"
        },
        {
            "ceu_cardinal_herrera_university": "Ceu Cardinal Herrera University"
        },
        {
            "university_of_huelva.": "University of Huelva."
        },
        {
            "camilo_university_jos\u00e3\u00a9_cela_madrid": "Camilo University Jos\u00c3\u00a9 Cela Madrid"
        },
        {
            "san_jorge_university": "San Jorge University"
        },
        {
            "pontifical_comillas_university": "Pontifical Comillas University"
        },
        {
            "european_university_of_madrid": "European University of Madrid"
        },
        {
            "esic_superior_school_of_commercial_management_and_marketing": "ESIC Superior School of Commercial Management and Marketing"
        },
        {
            "pontifical_university_of_salamanca": "Pontifical University of Salamanca"
        },
        {
            "tecnocampus_matar\u00e3\u00b3-maresme_(polytechnic_university_school)": "Tecnocampus Matar\u00c3\u00b3-Maresme (Polytechnic University School)"
        },
        {
            "ramon_llull_iqs_university": "Ramon Llull IQS University"
        },
        {
            "atlantic_european_university": "Atlantic European University"
        },
        {
            "francisco_university_of_vitoria": "Francisco University of Vitoria"
        },
        {
            "european_university_miguel_de_cervantes": "European University Miguel de Cervantes"
        },
        {
            "navija_university": "Navija University"
        },
        {
            "loyola_andalucia_university": "Loyola Andalucia University"
        },
        {
            "school_of_high_direction_and_administration_eada": "School of High Direction and Administration EADA"
        },
        {
            "international_universidad_isabel_i_de_castilla_(international_private_university_of_burgos)": "International Universidad Isabel I de Castilla (International Private University of Burgos)"
        },
        {
            "alfonso_university_x_el_sabio": "Alfonso University X El Sabio"
        },
        {
            "cunef_university": "Cunef University"
        },
        {
            "valencian_international_university": "Valencian International University"
        },
        {
            "la_salle_university_center_(higher_center_for_university_studies)": "La Salle University Center (Higher Center for University Studies)"
        },
        {
            "catholic_university_of_avila": "Catholic University of Avila"
        },
        {
            "international_university_of_andalusia": "International University of Andalusia"
        },
        {
            "university_defense_center": "University Defense Center"
        },
        {
            "villanueva_university": "Villanueva University"
        },
        {
            "ramon_llull_university_la_salle_campus_barcelona": "Ramon Llull University La Salle Campus Barcelona"
        },
        {
            "school_of_industrial_organization": "School of Industrial Organization"
        },
        {
            "international_university_men\u00e3\u00a9ndez_pelayo_uimp": "International University Men\u00c3\u00a9ndez Pelayo Uimp"
        },
        {
            "abat_oliba_ceu_university": "Abat Oliba CEU University"
        },
        {
            "financial_studies_center": "Financial Studies Center"
        },
        {
            "barcelona_graduate_school_of_economics": "Barcelona Graduate School of Economics"
        },
        {
            "school_of_business_administration_barcelona": "School of Business Administration Barcelona"
        },
        {
            "san_pablo_ceu_university": "San Pablo Ceu University"
        },
        {
            "imf_academic_institution": "IMF Academic Institution"
        },
        {
            "andalusian_school_of_public_health": "Andalusian School of Public Health"
        },
        {
            "salesiana_university_school_of_sarria": "Salesiana University School of Sarria"
        },
        {
            "elisava_higher_school_of_design": "ELISAVA Higher School of Design"
        },
        {
            "basque_culinary_center": "Basque Culinary Center"
        },
        {
            "university_schools_gimbernat": "University Schools Gimbernat"
        },
        {
            "barcelona_institute_of_international_studies": "Barcelona Institute of International Studies"
        },
        {
            "ramon_llull_university_pere_tarr\u00e3\u00a9s_faculty_of_social_education_and_social_work": "Ramon Llull University Pere Tarr\u00c3\u00a9s Faculty of Social Education and Social Work"
        },
        {
            "obs_business_school": "OBS Business School"
        },
        {
            "center_for_political_and_constitutional_studies": "Center for Political and Constitutional Studies"
        },
        {
            "university_center_of_defense_marin": "University Center of Defense Marin"
        },
        {
            "ramon_llull_blanquerna_university": "Ramon Llull Blanquerna University"
        },
        {
            "university_foundation_of_the_bages": "University Foundation of the Bages"
        },
        {
            "school_superior_of_music_of_catalonia": "School Superior of Music of Catalonia"
        },
        {
            "network_vives_of_universities": "Network Vives of Universities"
        },
        {
            "eserp_business_school_school_high_college_foundation": "ESERP Business School School High College Foundation"
        },
        {
            "european_school_of_management_and_empresa_eude": "European School of Management and Empresa Eude"
        },
        {
            "bau_higher_school_of_design": "BAU Higher School of Design"
        },
        {
            "superior_conservatory_liceu": "Superior Conservatory Liceu"
        },
        {
            "escola_massana_art_and_design_barcelona": "Escola Massana Art and Design Barcelona"
        },
        {
            "higher_school_of_design": "Higher School of Design"
        },
        {
            "mediterranean_agronomic_institute_of_zaragoza": "Mediterranean Agronomic Institute of Zaragoza"
        },
        {
            "school_of_cinema_and_audiovisuals_of_catalonia": "School of Cinema and Audiovisuals of Catalonia"
        },
        {
            "university_research_institute_in_north_american_studies_benjamin_franklin": "University Research Institute in North American Studies Benjamin Franklin"
        },
        {
            "united_international_business_schools": "United International Business Schools"
        },
        {
            "school_of_design_and_art": "School of Design and Art"
        },
        {
            "lci_barcelona_school_design": "LCI Barcelona School Design"
        },
        {
            "university_of_vic_central_university_of_catalonia_campus_umanresa": "University of Vic Central University of Catalonia Campus Umanresa"
        },
        {
            "university_school_of_nursing_and_occupational_therapy_uab": "University School of Nursing and Occupational Therapy UAB"
        },
        {
            "cardinal_university_center_cisneros_university_of_alcal\u00e3\u00a1": "Cardinal University Center Cisneros University of Alcal\u00c3\u00a1"
        },
        {
            "school_of_art_and_superior_of_design_of_valencia": "School of Art and Superior of Design of Valencia"
        },
        {
            "musikene_top_center_of_basque_country_music": "Musikene Top Center of Basque Country Music"
        },
        {
            "university_foundation_san_pablo_ceu_andaluc\u00e3\u00ada": "University Foundation San Pablo Ceu Andaluc\u00c3\u00ada"
        },
        {
            "cesine_university_center": "Cesine University Center"
        },
        {
            "real_university_center_mar\u00e3\u00ada_cristina_ucm": "Real University Center Mar\u00c3\u00ada Cristina UCM"
        },
        {
            "school_of_osteopathia_de_madrid": "School of Osteopathia de Madrid"
        },
        {
            "ealde_business_school": "EALDE Business School"
        },
        {
            "ces_don_bosco_higher_education_center_in_humanities_and_education_sciences": "CES Don Bosco Higher Education Center in Humanities and Education Sciences"
        },
        {
            "general_university_institute_guti\u00e3\u00a9rrez_mellado_uned": "General University Institute Guti\u00c3\u00a9rrez Mellado UNED"
        },
        {
            "formatic_university_school_barcelona": "Formatic University School Barcelona"
        },
        {
            "postgraduate_studies_center_for_business_administration_upm": "Postgraduate Studies Center for Business Administration UPM"
        },
        {
            "center_for_garrigues_studies": "Center for Garrigues Studies"
        },
        {
            "university_school_computer_computer_engineering": "University School Computer Computer Engineering"
        },
        {
            "ernest_lluch_uimp_center": "Ernest Lluch UIMP Center"
        },
        {
            "superior_conservatory_of_aragon_music": "Superior Conservatory of Aragon Music"
        },
        {
            "ceste_international_business_school": "CESTE International Business School"
        },
        {
            "iusc_higher_studies_center": "IUSC Higher Studies Center"
        },
        {
            "school_of_hotels_of_the_balearic_islands_uib": "School of Hotels of the Balearic Islands UIB"
        },
        {
            "superior_conservatory_of_music_manuel_castillo_sevilla": "Superior Conservatory of Music Manuel Castillo Sevilla"
        },
        {
            "vertex_business_school": "Vertex Business School"
        },
        {
            "mediterranean_school_of_tourism": "Mediterranean School of Tourism"
        },
        {
            "spain_business_school": "Spain Business School"
        },
        {
            "esco-school_of_communication_and_granada_marketing": "ESCO-School of Communication and Granada Marketing"
        },
        {
            "school_audiovisual_performance_and_multimedia_eram": "School Audiovisual Performance and Multimedia Eram"
        },
        {
            "international_management_school": "International Management School"
        },
        {
            "superior_conservatory_of_music_manuel_massotti_murcia": "Superior Conservatory of Music Manuel Massotti Murcia"
        },
        {
            "college_for_international_studies_endicott_international": "College for International Studies Endicott International"
        },
        {
            "faculty_of_theology_of_catalonia": "Faculty of Theology of Catalonia"
        },
        {
            "european_forum_navarra_business_school": "European Forum Navarra Business School"
        },
        {
            "institute_of_financial_and_business_training": "Institute of Financial and Business Training"
        },
        {
            "institute_carlos_iii_juan_march_of_social_sciences": "Institute Carlos III Juan March of Social Sciences"
        },
        {
            "superior_institute_of_religious_sciences_of_barcelona": "Superior Institute of Religious Sciences of Barcelona"
        },
        {
            "c3s_business_school": "C3S Business School"
        },
        {
            "university_school_of_osuna_us": "University School of Osuna Us"
        },
        {
            "legal_practice_school": "Legal practice school"
        },
        {
            "cesma_business_school": "CESMA Business School"
        },
        {
            "european_institute_campus_stellae": "European Institute Campus Stellae"
        },
        {
            "aquora_business_education": "Aquora Business Education"
        },
        {
            "university_school_of_physiotherapy_of_the_national_organization_of_spaniards": "University School of Physiotherapy of the National Organization of Spaniards"
        },
        {
            "top_art_institute": "Top Art Institute"
        },
        {
            "middle_atlantic_university": "Middle Atlantic University"
        },
        {
            "culinary_institute_of_barcelona": "Culinary Institute of Barcelona"
        },
        {
            "university_superior_studies_center_of_galicia": "University Superior Studies Center of Galicia"
        },
        {
            "university_campus_of_the_mediterranean_vilanova_i_la_geltr\u00e3\u00ba": "University Campus of the Mediterranean Vilanova i la Geltr\u00c3\u00ba"
        },
        {
            "barcelona_executive_business_school_bebs": "Barcelona Executive Business School BEBS"
        },
        {
            "faculty_of_granada_theology": "Faculty of Granada Theology"
        },
        {
            "universidad_polytechnic_of_madrid_department_of_architectural_projects": "Universidad Polytechnic of Madrid Department of Architectural Projects"
        },
        {
            "universidad_fernando_pessoa_canarias": "Universidad Fernando Pessoa Canarias"
        },
        {
            "balearic_tourism_school": "Balearic Tourism School"
        },
        {
            "university_school_of_magisterio_complutense_university_of_madrid": "University School of Magisterio Complutense University of Madrid"
        },
        {
            "technical_school_lea-artibai": "Technical School Lea-Artibai"
        },
        {
            "institute_of_dance_alicia_alonso_universidad_rey_juan_carlos": "Institute of Dance Alicia Alonso Universidad Rey Juan Carlos"
        },
        {
            "school_of_osteopathy_school_foundation_of_barcelona": "School of Osteopathy School Foundation of Barcelona"
        },
        {
            "faculty_of_seut_theology": "Faculty of Seut Theology"
        },
        {
            "superior_center_for_theology_of_assemblies_of_god": "Superior Center for Theology of Assemblies of God"
        },
        {
            "faculty_of_valencia_theology": "Faculty of Valencia Theology"
        },
        {
            "university_center_for_health_sciences_san_rafael_nebrija": "University Center for Health Sciences San Rafael Nebrija"
        },
        {
            "european_school_of_management_tenerife": "European School of Management Tenerife"
        },
        {
            "university_school_of_the_chamber_of_commerce_of_bilbao": "University School of the Chamber of Commerce of Bilbao"
        },
        {
            "fiac_cicles": "FIAC Cicles"
        },
        {
            "adventist_faculty_of_theology": "Adventist Faculty of Theology"
        },
        {
            "university_of_granada_department_of_international_international_law_and_international_relations": "University of Granada Department of International International Law and International Relations"
        },
        {
            "industrial_business_school": "Industrial Business School"
        },
        {
            "facultad_antoni_gaud\u00e3\u00ad_of_history_of_the_church,_archeology_and_christian_arts": "Facultad Antoni Gaud\u00c3\u00ad of History of the Church, Archeology and Christian Arts"
        },
        {
            "pmm_business_school": "PMM Business School"
        },
        {
            "efem_school_of_business_training": "EFEM School of Business Training"
        },
        {
            "university_school_of_labor_relations_in_lleida": "University School of Labor Relations in Lleida"
        },
        {
            "european_superior_institute_of_barcelona_iseb": "European Superior Institute of Barcelona ISEB"
        },
        {
            "ceu_university_school_of_magisterio_de_vigo": "Ceu University School of Magisterio de Vigo"
        },
        {
            "esco_euniversitas": "ESCO eUNIVERSITAS"
        },
        {
            "university_center_of_nursing_san_juan_de_dios": "University Center of Nursing San Juan de Dios"
        },
        {
            "faculty_of_medicine_university_of_vic": "Faculty of Medicine University of Vic"
        },
        {
            "university_of_las_hesperides": "University of Las Hesperides"
        },
        {
            "barcelona_campus_-_geneva_business_school": "Barcelona Campus - Geneva Business School"
        },
        {
            "carlos_iii_university_of_madrid": "Carlos III University of Madrid"
        },
        {
            "eu_business_school": "EU Business School"
        },
        {
            "fundesem_business_school": "Fundesem Business School"
        },
        {
            "marbella_international_university_centre": "Marbella International University Centre"
        },
        {
            "ucam_-_catholic_university_of_murcia": "UCAM - Catholic University of Murcia"
        }
    ],
    "taiwan": [
        {
            "national_taiwan_university": "National Taiwan University"
        },
        {
            "national_tsing_hua_university_taiwan": "National Tsing Hua University Taiwan"
        },
        {
            "national_cheng_if_university": "National Cheng If University"
        },
        {
            "national_central_university": "National Central University"
        },
        {
            "national_ming_university": "National Ming University"
        },
        {
            "national_taiwan_normal_university": "National Taiwan Normal University"
        },
        {
            "national_sun_yat_sen_university": "National Sun Yat Sen University"
        },
        {
            "national_taiwan_university_of_science_&_technology": "National Taiwan University of Science & Technology"
        },
        {
            "national_chung_hsing_university": "National Chung Hsing University"
        },
        {
            "taipei_medical_university": "Taipei Medical University"
        },
        {
            "china_medical_university_taiwan": "China Medical University Taiwan"
        },
        {
            "chang_gung_university": "Chang Gung University"
        },
        {
            "national_taipei_university_of_technology": "National Taipei University of Technology"
        },
        {
            "tamkang_university": "Tamkang University"
        },
        {
            "kaohsiung_medical_university": "Kaohsiung Medical University"
        },
        {
            "national_chengchi_university": "National Chengchi University"
        },
        {
            "fu_jen_catholic_university": "Fu Jen Catholic University"
        },
        {
            "feng_chia_university": "Feng Chia University"
        },
        {
            "tunghai_university": "Tunghai University"
        },
        {
            "yuan_ze_university": "Yuan Ze University"
        },
        {
            "national_taiwan_ocean_university": "National Taiwan Ocean University"
        },
        {
            "chung_yuan_christian_university": "Chung Yuan Christian University"
        },
        {
            "the_national_ming_chiao_tung_university": "The National Ming Chiao Tung University"
        },
        {
            "national_kaohsiung_university_of_science_and_technology": "National Kaohsiung University of Science and Technology"
        },
        {
            "ming_chuan_university": "Ming Chuan University"
        },
        {
            "national_yunlin_university_of_science_&_technology": "National Yunlin University of Science & Technology"
        },
        {
            "i_shou_university": "I Shou University"
        },
        {
            "national_changhua_university_of_education": "National Changhua University of Education"
        },
        {
            "national_taipei_university": "National Taipei University"
        },
        {
            "national_pingtung_university_of_science_&_technology": "National Pingtung University of Science & Technology"
        },
        {
            "providence_university": "Providence University"
        },
        {
            "tzu_chi_university": "Tzu Chi University"
        },
        {
            "national_ilan_university": "National Ilan University"
        },
        {
            "southern_taiwan_university_of_science_&_technology": "Southern Taiwan University of Science & Technology"
        },
        {
            "chung_shan_medical_university": "Chung Shan Medical University"
        },
        {
            "ming_chi_university_of_technology": "Ming Chi University of Technology"
        },
        {
            "soochow_university_taiwan": "Soochow University Taiwan"
        },
        {
            "national_dong_hwa_university_(hualien_university_of_education)": "National Dong Hwa University (Hualien University of Education)"
        },
        {
            "national_university_of_tainan": "National University of Tainan"
        },
        {
            "university_of_taipei_(taipei_municipal_university_of_education)": "University of Taipei (Taipei Municipal University of Education)"
        },
        {
            "national_taipei_university_of_education": "National Taipei University of Education"
        },
        {
            "national_kaohsiung_normal_university": "National Kaohsiung Normal University"
        },
        {
            "national_taichung_university": "National Taichung University"
        },
        {
            "national_taichung_university_of_science_&_technology": "National Taichung University of Science & Technology"
        },
        {
            "national_formosa_university": "National Formosa University"
        },
        {
            "da_yeh_university": "Da Yeh University"
        },
        {
            "kun_shan_university": "Kun Shan University"
        },
        {
            "tatung_university": "Tatung University"
        },
        {
            "chia_nan_university_of_pharmacy_&_science": "Chia Nan University of Pharmacy & Science"
        },
        {
            "hung_kuang_university": "Hung Kuang University"
        },
        {
            "national_taipei_university_of_nursing_and_health_sciences": "National Taipei University of Nursing and Health Sciences"
        },
        {
            "shih_chien_university": "Shih Chien University"
        },
        {
            "chang_gung_university_of_science_&_technology": "Chang Gung University of Science & Technology"
        },
        {
            "cheng_shiu_university": "Cheng Shiu University"
        },
        {
            "aletheia_university": "Aletheia University"
        },
        {
            "national_pingtung_university": "National Pingtung University"
        },
        {
            "national_taipei_university_of_business": "National Taipei University of Business"
        },
        {
            "shih_hsin_university": "Shih Hsin University"
        },
        {
            "chienkuo_technology_university": "Chienkuo Technology University"
        },
        {
            "ming_hsin_university_of_science_&_technology": "Ming Hsin University of Science & Technology"
        },
        {
            "ling_tung_university.": "Ling Tung University."
        },
        {
            "jinwen_university_of_science_&_technology": "Jinwen University of Science & Technology"
        },
        {
            "national_taiwan_university_of_sport": "National Taiwan University of Sport"
        },
        {
            "national_taiwan_university_of_arts": "National Taiwan University of Arts"
        },
        {
            "mackay_junior_college_of_medicine_nursing_and_management": "Mackay Junior College of Medicine Nursing and Management"
        },
        {
            "hsiuping_university_of_science_&_technology": "Hsiuping University of Science & Technology"
        },
        {
            "china_university_of_science_&_technology_(china_institute_of_technology)_taiwan": "China University of Science & Technology (China Institute of Technology) Taiwan"
        },
        {
            "chien_hsin_university_of_science_&_technology_(ching_yun_university)": "Chien Hsin University of Science & Technology (Ching Yun University)"
        },
        {
            "taipei_national_university_of_the_arts": "Taipei National University of the Arts"
        },
        {
            "nan_kai_university_of_technology": "Nan Kai University of Technology"
        },
        {
            "wenzao_ursuline_university_of_languages": "Wenzao Ursuline University of Languages"
        },
        {
            "china_university_of_technology_taiwan": "China University of Technology Taiwan"
        },
        {
            "central_police_university": "Central Police University"
        },
        {
            "saint_john's_university_taiwan": "Saint John's University Taiwan"
        },
        {
            "open_university_of_kaohsiung": "Open University of Kaohsiung"
        },
        {
            "national_open_university": "National Open University"
        },
        {
            "tainan_university_of_technology": "Tainan University of Technology"
        },
        {
            "chihlee_institute_of_technology": "Chihlee Institute of Technology"
        },
        {
            "national_taiwan_college_of_performing_arts": "National Taiwan College of Performing Arts"
        },
        {
            "tungnan_university": "Tungnan University"
        },
        {
            "national_taiwan_sport_university": "National Taiwan Sport University"
        },
        {
            "chung_chou_university_of_science_&_technology": "Chung Chou University of Science & Technology"
        },
        {
            "hwa_hsia_university_of_technology": "Hwa Hsia University of Technology"
        },
        {
            "wufeng_university": "Wufeng University"
        },
        {
            "national_penghu_university_of_science_&_technology": "National Penghu University of Science & Technology"
        },
        {
            "taipei_chengshih_university_of_science_&_technology_(technology_&_science_institute_of_northern_taiwan)": "Taipei Chengshih University of Science & Technology (Technology & Science Institute of Northern Taiwan)"
        },
        {
            "tung_fang_design_institute": "Tung Fang Design Institute"
        },
        {
            "tatung_institute_of_technology": "Tatung Institute of Technology"
        },
        {
            "dahan_institute_of_technology": "Dahan Institute of Technology"
        },
        {
            "cardinal_tien_college_of_healthcare_and_management": "Cardinal Tien College of Healthcare and Management"
        },
        {
            "ching_kuo_institue_of_management_&_health": "Ching Kuo Institue of Management & Health"
        },
        {
            "hsing_wu_university": "Hsing Wu University"
        },
        {
            "nanya_institute_of_technology": "Nanya Institute of Technology"
        },
        {
            "lan_yang_institute_of_technology": "Lan Yang Institute of Technology"
        },
        {
            "lee_ming_institute_of_technology": "Lee Ming Institute of Technology"
        },
        {
            "taiwan_police_college": "Taiwan Police College"
        },
        {
            "national_tainan_junior_college_of_nursing": "National Tainan Junior College of Nursing"
        },
        {
            "kang-ning_junior_college_of_medical_care_and_management": "Kang-Ning Junior College of Medical Care and Management"
        },
        {
            "hungkuo_delin_university_of_technology": "HungKuo Delin University of Technology"
        },
        {
            "army_academy_r_o_c": "Army Academy R O C"
        },
        {
            "ta_hwa_university_of_science_&_technology": "Ta Hwa University of Science & Technology"
        }
    ],
    "new zealand": [
        {
            "university_of_auckland": "University of Auckland"
        },
        {
            "university_of_otago": "University of Otago"
        },
        {
            "university_of_canterbury": "University of Canterbury"
        },
        {
            "massey_university": "Massey University"
        },
        {
            "university_of_waikato": "University of Waikato"
        },
        {
            "auckland_university_of_technology": "Auckland University of Technology"
        },
        {
            "lincoln_university_canterbury": "Lincoln University Canterbury"
        },
        {
            "victoria_university_of_wellington": "Victoria University of Wellington"
        },
        {
            "unitec_new_zealand": "Unitec New Zealand"
        },
        {
            "otago_polytechnic": "Otago Polytechnic"
        },
        {
            "eastern_institute_of_technology_hawke's_bay_and_east": "Eastern Institute of Technology Hawke's Bay and East"
        },
        {
            "waikato_institute_of_technology": "Waikato Institute of Technology"
        },
        {
            "manukau_institute_of_technology": "Manukau Institute of Technology"
        },
        {
            "open_polytechnic_of_new_zealand": "Open Polytechnic of New Zealand"
        },
        {
            "universal_college_of_learning": "Universal College of Learning"
        },
        {
            "ara_institute_of_canterbury_(formerly_christchurch_polytechnic_institute_of_technology)": "Ara Institute of Canterbury (formerly Christchurch Polytechnic Institute of Technology)"
        },
        {
            "auckland_institute_of_studies": "Auckland Institute of Studies"
        },
        {
            "nelson_marlborough_institute_of_technology": "Nelson Marlborough Institute of Technology"
        },
        {
            "southern_institute_of_technology": "Southern Institute of Technology"
        },
        {
            "whitireia_and_weltec": "whitireia and weltec"
        },
        {
            "wellington_institute_of_technology": "Wellington Institute of Technology"
        },
        {
            "art_institute_of_technology": "Art Institute of Technology"
        },
        {
            "media_design_school_auckland": "Media Design School Auckland"
        },
        {
            "northtec_(northland_polytechnic)": "NorthTec (Northland Polytechnic)"
        },
        {
            "western_institute_of_technology_at_taranaki": "Western Institute of Technology at Taranaki"
        },
        {
            "the_university_of_new_zealand": "The University of New Zealand"
        },
        {
            "yoobee_colleges": "Yoobee Colleges"
        },
        {
            "nzma": "NZMA"
        },
        {
            "aspire2_international": "Aspire2 International"
        },
        {
            "whitecliffe_college_of_arts_and_design": "Whitecliffe College of Arts and Design"
        },
        {
            "wait_polytechnic": "Wait polytechnic"
        },
        {
            "laidlaw_college": "Laidlaw College"
        },
        {
            "awanuiarangi_university": "Awanuiarangi University"
        },
        {
            "the_university_of_raukawa": "The University of Raukawa"
        },
        {
            "vision_college_new_zealand": "Vision College New Zealand"
        },
        {
            "newton_college_of_business_and_technology": "Newton College of Business and Technology"
        },
        {
            "agi_education": "AGI Education"
        },
        {
            "atmc_new_zealand": "ATMC New Zealand"
        },
        {
            "new_zealand_institute_of_studies": "New Zealand Institute of Studies"
        },
        {
            "abacus_institute": "Abacus Institute"
        },
        {
            "atc_new_zealand": "ATC New Zealand"
        },
        {
            "kauri_academy": "Kauri Academy"
        },
        {
            "stellaris": "Stellaris"
        },
        {
            "massey_university": "Massey University"
        }
    ],
    "mexico": [
        {
            "national_autonomous_university_of_mexico": "National Autonomous University of Mexico"
        },
        {
            "research_and_advanced_studies_center_of_ipn_cinvestav": "Research and Advanced Studies Center of IPN Cinvestav"
        },
        {
            "tecnol\u00e3\u00b3gico_de_monterrey.": "Tecnol\u00c3\u00b3gico de Monterrey."
        },
        {
            "national_polytechnic_institute": "national polytechnic institute"
        },
        {
            "university_of_guadalajara": "University of Guadalajara"
        },
        {
            "autonomous_metropolitan_university": "Autonomous Metropolitan University"
        },
        {
            "benem\u00e3\u00a9rita_autonomous_university_of_puebla": "Benem\u00c3\u00a9rita Autonomous University of Puebla"
        },
        {
            "autonomous_university_of_nuevo_le\u00e3\u00b3n": "Autonomous University of Nuevo Le\u00c3\u00b3n"
        },
        {
            "autonomous_university_of_san_luis_potos\u00e3\u00ad": "Autonomous University of San Luis Potos\u00c3\u00ad"
        },
        {
            "veracruz_university": "Veracruz University"
        },
        {
            "university_of_guanajuato": "university of guanajuato"
        },
        {
            "ibeoamerican_university": "Ibeoamerican University"
        },
        {
            "universidad_michoacana_de_san_nicol\u00e3\u00a1s_de_hidalgo": "Universidad Michoacana de San Nicol\u00c3\u00a1s de Hidalgo"
        },
        {
            "autonomous_university_of_yucatan": "Autonomous University of Yucatan"
        },
        {
            "university_of_colima": "University of Colima"
        },
        {
            "autonomous_university_of_queretaro": "Autonomous University of Queretaro"
        },
        {
            "autonomous_university_of_the_state_of_hidalgo": "Autonomous University of the State of Hidalgo"
        },
        {
            "university_of_the_americas_puebla": "University of the Americas Puebla"
        },
        {
            "autonomous_university_of_sinaloa": "Autonomous University of Sinaloa"
        },
        {
            "autonomous_technological_institute_of_mexico": "Autonomous Technological Institute of Mexico"
        },
        {
            "autonomous_university_of_ciudad_juarez": "Autonomous University of Ciudad Juarez"
        },
        {
            "panamericana_universidad_mexico": "Panamericana Universidad Mexico"
        },
        {
            "autonomous_university_of_aguascalientes": "Autonomous University of Aguascalientes"
        },
        {
            "autonomous_mexico_state_university": "Autonomous Mexico State University"
        },
        {
            "autonomous_university_of_chiapas": "Autonomous University of Chiapas"
        },
        {
            "national_technology_of_mexico": "National Technology of Mexico"
        },
        {
            "autonomous_university_of_zacatecas": "Autonomous University of Zacatecas"
        },
        {
            "autonomous_university_of_baja_california": "Autonomous University of Baja California"
        },
        {
            "autonomous_university_of_guerrero": "Autonomous University of Guerrero"
        },
        {
            "university_of_sonora": "University of Sonora"
        },
        {
            "autonomous_agraria_antonio_narro_university": "Autonomous Agraria Antonio Narro University"
        },
        {
            "autonomous_university_of_mexico_city": "Autonomous University of Mexico City"
        },
        {
            "juarez_universidad_of_the_state_of_durango": "Juarez Universidad of the State of Durango"
        },
        {
            "autonomous_popular_university_of_the_state_of_puebla": "Autonomous Popular University of the State of Puebla"
        },
        {
            "universidad_aut\u00e3\u00b3noma_del_estado_de_morelos": "Universidad Aut\u00c3\u00b3noma del Estado de Morelos"
        },
        {
            "national_school_of_anthropology_and_history": "National School of Anthropology and History"
        },
        {
            "autonomous_university_of_tlaxcala": "Autonomous University of Tlaxcala"
        },
        {
            "national_institute_of_anthropology_and_history": "National Institute of Anthropology and History"
        },
        {
            "network_of_universities_anahuac": "Network of universities Anahuac"
        },
        {
            "autonomous_university_of_baja_california_sur": "Autonomous University of Baja California Sur"
        },
        {
            "autonomus_university_of_campeche": "Autonomus University of Campeche"
        },
        {
            "university_of_monterrey": "University of Monterrey"
        },
        {
            "technological_university_of_mixteca": "Technological University of Mixteca"
        },
        {
            "autonomous_university_of_coahuila": "Autonomous University of Coahuila"
        },
        {
            "university_of_sciences_and_arts_of_chiapas": "University of Sciences and Arts of Chiapas"
        },
        {
            "tautonomous_university_of_chihuahua": "tAutonomous University of Chihuahua"
        },
        {
            "autonomous_university_of_carmen": "Autonomous University of Carmen"
        },
        {
            "technological_institute_of_celaya": "Technological Institute of Celaya"
        },
        {
            "juarez_autonomous_university_of_tabasco": "Juarez Autonomous University of Tabasco"
        },
        {
            "sonora_technological_institute": "Sonora Technological Institute"
        },
        {
            "autonomous_university_chapingo": "Autonomous University Chapingo"
        },
        {
            "flacso_mexico_latin_american_faculty_of_social_sciences": "Flacso Mexico Latin American Faculty of Social Sciences"
        },
        {
            "college_of_mexico": "College of Mexico"
        },
        {
            "economic_research_and_teaching_center": "Economic Research and Teaching Center"
        },
        {
            "autonomous_university_of_tamaulipas": "Autonomous University of Tamaulipas"
        },
        {
            "michoac\u00e3\u00a1n_school": "Michoac\u00c3\u00a1n School"
        },
        {
            "autonomous_university_benito_ju\u00e3\u00a1rez_de_oaxaca": "Autonomous University Benito Ju\u00c3\u00a1rez de Oaxaca"
        },
        {
            "technical_education_center_and_superior_cetys": "Technical education center and superior cetys"
        },
        {
            "universidad_del_mar.": "Universidad del Mar."
        },
        {
            "pochuca_university_of_pachuca": "Pochuca University of Pachuca"
        },
        {
            "university_of_quintana_roo": "University of Quintana Roo"
        },
        {
            "la_salle_university_mexico_city": "La Salle University Mexico City"
        },
        {
            "autonomous_university_of_guadalajara": "Autonomous University of Guadalajara"
        },
        {
            "autonomous_university_of_nayarit": "Autonomous University of Nayarit"
        },
        {
            "polytechnic_university_of_the_state_of_morelos": "Polytechnic University of the State of Morelos"
        },
        {
            "research_center_and_superior_studies_in_social_anthropology": "Research Center and Superior Studies in Social Anthropology"
        },
        {
            "universidad_international_universidad": "Universidad International Universidad"
        },
        {
            "xalapa_unitex_higher_technical_university": "Xalapa Unitex Higher Technical University"
        },
        {
            "national_pedagogical_university_mexico": "National Pedagogical University Mexico"
        },
        {
            "sonora_state_university_(sonora_state_studies_center)": "Sonora State University (Sonora State Studies Center)"
        },
        {
            "technological_institute_of_durango": "Technological Institute of Durango"
        },
        {
            "technological_institute_of_madero_city": "Technological Institute of Madero City"
        },
        {
            "center_for_scientific_research_and_higher_education_of_ensenada": "Center for Scientific Research and Higher Education of Ensenada"
        },
        {
            "universidad_iberoamericana_puebla": "Universidad Iberoamericana Puebla"
        },
        {
            "sonora_college": "Sonora College"
        },
        {
            "university_of_montemorelos": "University of Montemorelos"
        },
        {
            "technological_university_of_mexico": "Technological University of Mexico"
        },
        {
            "papaloapan_university": "Papaloapan University"
        },
        {
            "hermosillo_institute_of_technology": "Hermosillo Institute of Technology"
        },
        {
            "university_of_la_salle_bajio": "University of La Salle Bajio"
        },
        {
            "technological_institute_of_la_laguna": "Technological Institute of La Laguna"
        },
        {
            "technological_institute_of_m\u00e3\u00a9rida": "Technological Institute of M\u00c3\u00a9rida"
        },
        {
            "xochicalco_university": "Xochicalco University"
        },
        {
            "polytechnic_university_of_sinaloa": "Polytechnic University of Sinaloa"
        },
        {
            "technology_research_and_assistance_center_of_the_state_of_jalisco": "Technology Research and Assistance Center of the State of Jalisco"
        },
        {
            "quer\u00e3\u00a9taro_technological_institute": "Quer\u00c3\u00a9taro Technological Institute"
        },
        {
            "polytechnic_university_of_tulancingo": "Polytechnic University of Tulancingo"
        },
        {
            "school_of_san_luis": "School of San Luis"
        },
        {
            "technological_institute_of_tehuacan": "Technological Institute of Tehuacan"
        },
        {
            "superior_school_of_tourism_roberto_ca\u00e3\u00b1edo_mart\u00e3\u00adnez": "Superior School of Tourism Roberto Ca\u00c3\u00b1edo Mart\u00c3\u00adnez"
        },
        {
            "center_for_research_and_technological_development_in_electrochemistry": "Center for Research and Technological Development in Electrochemistry"
        },
        {
            "inter-american_university_for_development_mexico": "Inter-American University for Development Mexico"
        },
        {
            "saltillo_technological_institute": "Saltillo Technological Institute"
        },
        {
            "superior_technological_institute_of_tac\u00e3\u00a1mbaro": "Superior Technological Institute of Tac\u00c3\u00a1mbaro"
        },
        {
            "university_of_the_claustro_de_sor_juana": "University of the Claustro de Sor Juana"
        },
        {
            "technological_institute_of_peace": "Technological Institute of Peace"
        },
        {
            "polytechnic_university_of_aguascalientes": "Polytechnic University of Aguascalientes"
        },
        {
            "technological_university_of_puebla": "Technological University of Puebla"
        },
        {
            "institute_of_communication_and_philosophy": "Institute of Communication and Philosophy"
        },
        {
            "tecmilenio_university": "Tecmilenio University"
        },
        {
            "university_of_the_sierra_sur": "University of the Sierra Sur"
        },
        {
            "polytechnic_university_of_guanajuato": "Polytechnic University of Guanajuato"
        },
        {
            "celaya_university": "Celaya University"
        },
        {
            "nova_spania_university": "Nova Spania University"
        },
        {
            "technological_institute_of_toluca": "Technological Institute of Toluca"
        },
        {
            "technological_institute_of_pachuca": "Technological Institute of Pachuca"
        },
        {
            "online_latin_american_technological_university_utt": "Online Latin American Technological University UTT"
        },
        {
            "technological_institute_of_calkin\u00e3\u008d": "Technological Institute of CALKIN\u00c3\u008d"
        },
        {
            "technological_university_of_quer\u00e3\u00a9taro": "Technological University of Quer\u00c3\u00a9taro"
        },
        {
            "ibero-american_international_university": "Ibero-American International University"
        },
        {
            "technological_institute_of_victoria_city": "Technological Institute of Victoria City"
        },
        {
            "victoria_polytechnic_university": "Victoria Polytechnic University"
        },
        {
            "madero_university": "Madero University"
        },
        {
            "polytechnic_university_of_san_luis_potos\u00e3\u00ad": "Polytechnic University of San Luis Potos\u00c3\u00ad"
        },
        {
            "cife_university_center": "CIFE University Center"
        },
        {
            "technology_of_superior_studies_of_ecatepec": "Technology of superior studies of Ecatepec"
        },
        {
            "culinary_art_school": "Culinary Art School"
        },
        {
            "university_of_the_sierra_juarez": "University of the Sierra Juarez"
        },
        {
            "mediation_institute_of_mexico": "Mediation Institute of Mexico"
        },
        {
            "virtual_upn_national_pedagogical_university": "Virtual UPN National Pedagogical University"
        },
        {
            "technological_institute_of_zacatepec": "Technological Institute of Zacatepec"
        },
        {
            "polytechnic_university_of_puebla": "Polytechnic University of Puebla"
        },
        {
            "mexiquense_college": "Mexiquense College"
        },
        {
            "espinosa_yglesias_studies_center": "Espinosa Yglesias Studies Center"
        },
        {
            "regional_teacher_training_center_of_sonora_creson": "Regional Teacher Training Center of Sonora Creson"
        },
        {
            "poza_technological_institute_rica": "Poza Technological Institute Rica"
        },
        {
            "technological_institute_of_conkal": "Technological Institute of Conkal"
        },
        {
            "universidad_crist\u00e3\u00b3bal_col\u00e3\u00b3n.": "Universidad Crist\u00c3\u00b3bal Col\u00c3\u00b3n."
        },
        {
            "university_of_the_northeast_tampico": "University of the Northeast Tampico"
        },
        {
            "universidad": "Universidad"
        },
        {
            "marist_university_of_m\u00e3\u00a9rida": "Marist University of M\u00c3\u00a9rida"
        },
        {
            "center_for_studies_for_culture_and_arts_house_lamm": "Center for Studies for Culture and Arts House Lamm"
        },
        {
            "technological_institute_of_roque": "Technological Institute of Roque"
        },
        {
            "technological_university_of_leon": "Technological University of leon"
        },
        {
            "superior_design_studies_center_of_monterrey": "Superior Design Studies Center of Monterrey"
        },
        {
            "college_of_public_accountants_of_guadalajara_jalisco": "College of Public Accountants of Guadalajara Jalisco"
        },
        {
            "the_college_of_tlaxcala": "The College of Tlaxcala"
        },
        {
            "research_center_in_geography_and_geomatics": "Research center in Geography and Geomatics"
        },
        {
            "technological_university_of_cancun": "Technological University of Cancun"
        },
        {
            "model_university": "Model University"
        },
        {
            "eloisa_university_institute_pink_pattern": "ELOISA University Institute Pink Pattern"
        },
        {
            "autonomous_university_of_west_uadeo": "Autonomous University of West Uadeo"
        },
        {
            "university_center_uteg.": "University center UTEG."
        },
        {
            "superior_technological_institute_of_atememe": "Superior Technological Institute of Atememe"
        },
        {
            "the_college_of_veracruz": "The College of Veracruz"
        },
        {
            "la_salle_pachuca_university": "La Salle Pachuca University"
        },
        {
            "university_of_london": "University of London"
        },
        {
            "pontificia_university_of_mexico": "Pontificia University of Mexico"
        },
        {
            "escuela_libre_de_derecho": "Escuela Libre de Derecho"
        },
        {
            "university_of_communication": "University of Communication"
        },
        {
            "basque_university_of_quiroga": "Basque University of Quiroga"
        },
        {
            "vizcaya_university_of_las_americas": "Vizcaya University of Las Americas"
        },
        {
            "national_pedagogical_pedagogical_university_of_chihuahua": "National Pedagogical Pedagogical University of Chihuahua"
        },
        {
            "technological_university_of_chihuahua": "Technological University of Chihuahua"
        },
        {
            "technological_university_of_san_juan_del_r\u00e3\u00ado": "Technological University of San Juan del R\u00c3\u00ado"
        },
        {
            "interamerican_university": "Interamerican University"
        },
        {
            "professional_development_university": "Professional Development University"
        },
        {
            "mayan_intercultural_university_of_quintana_roo": "Mayan Intercultural University of Quintana Roo"
        },
        {
            "universidad_del_caribe.": "Universidad del Caribe."
        },
        {
            "superior_technological_institute_of_san_andr\u00e3\u00a9s_tuxtla": "Superior Technological Institute of San Andr\u00c3\u00a9s Tuxtla"
        },
        {
            "hebraica_university": "Hebraica University"
        },
        {
            "university_of_the_environment": "University of the Environment"
        },
        {
            "mexican_university": "Mexican University"
        },
        {
            "university_of_the_center_of_mexico": "University of the center of Mexico"
        },
        {
            "university_of_the_valley_of_puebla": "University of the Valley of Puebla"
        },
        {
            "latin_university": "Latin University"
        },
        {
            "university_center_enrique_d\u00e3\u00adaz_de_le\u00e3\u00b3n": "University Center Enrique D\u00c3\u00adaz de Le\u00c3\u00b3n"
        },
        {
            "university_of_xalapa_campus_souls": "University of Xalapa Campus souls"
        },
        {
            "urupan_technological_institute_of_uruapan": "Urupan Technological Institute of Uruapan"
        },
        {
            "upn_jalisco_national_pedagogical_university": "UPN Jalisco National Pedagogical University"
        },
        {
            "cuauht\u00e3\u00a9moc_university_steam_aguascalientes": "Cuauht\u00c3\u00a9moc University Steam Aguascalientes"
        },
        {
            "pedro_de_gano_university_(alfonso_reyes_university)": "Pedro de Gano University (Alfonso Reyes University)"
        },
        {
            "la_salle_cancun_university": "La Salle Cancun University"
        },
        {
            "kino_university": "Kino University"
        },
        {
            "technological_institute_of_nuevo_laredo": "Technological Institute of Nuevo Laredo"
        },
        {
            "technological_institute_of_matamoros": "Technological Institute of Matamoros"
        },
        {
            "technological_institute_of_mexicali": "Technological Institute of Mexicali"
        },
        {
            "university_of_east_puebla": "University of East Puebla"
        },
        {
            "chihuahuense_center_of_graduate_studies": "Chihuahuense center of graduate studies"
        },
        {
            "technological_university_of_the_mayan_riviera": "Technological University of the Mayan Riviera"
        },
        {
            "technological_institute_of_campeche": "Technological Institute of Campeche"
        },
        {
            "marist_university_of_queretaro": "Marist University of Queretaro"
        },
        {
            "linda_vista_mexico_university": "Linda Vista Mexico University"
        },
        {
            "university_senior_studies_center_of_san_angel": "University Senior Studies Center of San Angel"
        },
        {
            "high_school_of_yucatan_arts": "High School of Yucatan Arts"
        },
        {
            "university_of_latin_america": "University of Latin America"
        },
        {
            "olmeca_university": "Olmeca University"
        },
        {
            "centro_universitario_angloamericano": "Centro Universitario Angloamericano"
        },
        {
            "upper_studies_center_north": "Upper Studies Center North"
        },
        {
            "northern_regional_university": "Northern Regional University"
        },
        {
            "benem\u00e3\u00a9rita_and_centenary_normal_school_of_the_state_of_san_luis_potos\u00e3\u00ad": "Benem\u00c3\u00a9rita and Centenary Normal School of the State of San Luis Potos\u00c3\u00ad"
        },
        {
            "universidad_iberoamericana_le\u00e3\u00b3n": "Universidad Iberoamericana Le\u00c3\u00b3n"
        },
        {
            "technological_institute_of_nuevo_le\u00e3\u00b3n": "Technological Institute of Nuevo Le\u00c3\u00b3n"
        },
        {
            "university_of_the_pedregal": "University of the Pedregal"
        },
        {
            "technological_university_of_the_mezquital_valley": "Technological University of the Mezquital Valley"
        },
        {
            "technological_university_of_nezahualc\u00e3\u00b3yotl": "Technological University of Nezahualc\u00c3\u00b3yotl"
        },
        {
            "university_of_the_ci\u00e3\u00a9nega_of_the_state_of_michoac\u00e3\u00a1n_de_ocampo": "University of the Ci\u00c3\u00a9nega of the State of Michoac\u00c3\u00a1n de Ocampo"
        },
        {
            "university_of_the_gulf_of_mexico": "University of the Gulf of Mexico"
        },
        {
            "university_of_the_valle_de_cuernavaca": "University of the Valle de Cuernavaca"
        },
        {
            "institute_of_higher_education_and_sexological_research": "Institute of Higher Education and Sexological Research"
        },
        {
            "la_salle_morelia_university": "La Salle Morelia University"
        },
        {
            "superior_institute_of_architecture_and_design": "Superior Institute of Architecture and Design"
        },
        {
            "university_of_the_americas_mexico_city": "University of the Americas Mexico City"
        },
        {
            "uniso_xxi_century_university": "Uniso XXI Century University"
        },
        {
            "polytechnic_university_of_baja_california": "Polytechnic University of Baja California"
        },
        {
            "state_university_of_ecatepec_valley": "State University of Ecatepec Valley"
        },
        {
            "itaca_university": "Itaca University"
        },
        {
            "university_of_tepeyac": "University of Tepeyac"
        },
        {
            "top_technological_institute_of_white_land": "Top Technological Institute of White Land"
        },
        {
            "university_of_tijuana.": "University of Tijuana."
        },
        {
            "sur_university": "Sur University"
        },
        {
            "polytechnic_university_of_santa_rosa_j\u00e3\u00a1uregui": "Polytechnic University of Santa Rosa J\u00c3\u00a1uregui"
        },
        {
            "michoacan_institute_of_imced_education_sciences": "Michoacan Institute of IMCED Education Sciences"
        },
        {
            "north_american_mexico_university": "North American Mexico University"
        },
        {
            "technological_university_of_oaxaca_central_valleys": "Technological University of Oaxaca Central Valleys"
        },
        {
            "cat\u00e3\u00b3lica_universidad_de_culiacan": "Cat\u00c3\u00b3lica Universidad de Culiacan"
        },
        {
            "technological_institute_of_minatitl\u00e3\u00a1n": "Technological Institute of Minatitl\u00c3\u00a1n"
        },
        {
            "university_of_business_development_and_pedagogical": "University of Business Development and Pedagogical"
        },
        {
            "cesun_university": "CESUN University"
        },
        {
            "university_communication_center": "University Communication Center"
        },
        {
            "technological_university_of_tlaxcala": "Technological University of Tlaxcala"
        },
        {
            "free_faculty_of_monterrey_law": "Free Faculty of Monterrey Law"
        },
        {
            "center_elee_of_psychological_activities": "Center Elee of psychological activities"
        },
        {
            "technological_institute_of_guzm\u00e3\u00a1n_city": "Technological Institute of Guzm\u00c3\u00a1n City"
        },
        {
            "northern_university": "Northern University"
        },
        {
            "american_university_of_acapulco": "American University of Acapulco"
        },
        {
            "university_of_the_isthmus_mexico": "University of the Isthmus Mexico"
        },
        {
            "la_salle_cuernavaca_university": "La Salle Cuernavaca University"
        },
        {
            "mondrag\u00e3\u00b3n_mexico_university": "Mondrag\u00c3\u00b3n Mexico University"
        },
        {
            "polytechnic_university_of_zacatecas": "Polytechnic University of Zacatecas"
        },
        {
            "technological_university_of_the_northern_region_of_guerrero": "Technological University of the Northern Region of Guerrero"
        },
        {
            "technological_institute_of_puerto_vallarta": "Technological Institute of Puerto Vallarta"
        },
        {
            "technological_institute_jos\u00e3\u00a9_mario_molina_pasquel_and_henr\u00e3\u00adquez": "Technological Institute Jos\u00c3\u00a9 Mario Molina Pasquel and Henr\u00c3\u00adquez"
        },
        {
            "university_of_north_america": "University of North America"
        },
        {
            "pedagogical_university_of_durango": "Pedagogical University of Durango"
        },
        {
            "technological_institute_of_tlajomulco": "Technological Institute of Tlajomulco"
        },
        {
            "instituuto_panamero_de_alter_direcci\u00e3\u00b3n_de_empresa_meets": "Instituuto Panamero de Alter Direcci\u00c3\u00b3n de Empresa meets"
        },
        {
            "lasallist_university_benavente": "Lasallist University Benavente"
        },
        {
            "technological_university_of_tabasco": "Technological University of tabasco"
        },
        {
            "universidad_jos\u00e3\u00a9_vasconcelos_de_oaxaca": "Universidad Jos\u00c3\u00a9 Vasconcelos de Oaxaca"
        },
        {
            "technological_university_of_huejotzingo": "Technological University of Huejotzingo"
        },
        {
            "north_technology_university_of_guanajuato": "North Technology University of Guanajuato"
        },
        {
            "technological_university_tula_tepeji": "Technological University Tula Tepeji"
        },
        {
            "technologic_university_of_the_metropolitan_zone_of_guadalajara": "Technologic University of the Metropolitan Zone of Guadalajara"
        },
        {
            "school_of_journalism_carlos_septien_garcia": "School of Journalism Carlos Septien Garcia"
        },
        {
            "inter-american_north_university": "Inter-American North University"
        },
        {
            "mesoamerican_university_of_san_agustin": "Mesoamerican University of San Agustin"
        },
        {
            "technological_university_of_san_luis_potos\u00e3\u00ad": "Technological University of San Luis Potos\u00c3\u00ad"
        },
        {
            "university_center_of_ciudad_ju\u00e3\u00a1rez": "University Center of Ciudad Ju\u00c3\u00a1rez"
        },
        {
            "institute_of_marketing_and_advertising": "Institute of Marketing and Advertising"
        },
        {
            "football_university_and_sports_sciences": "Football University and Sports Sciences"
        },
        {
            "leonardo_bravo_institute": "Leonardo Bravo Institute"
        },
        {
            "univerididad_paris_paaccioli": "Univerididad Paris Paaccioli"
        },
        {
            "technological_university_of_salamanca": "Technological University of Salamanca"
        },
        {
            "technological_university_of_the_center_of_veracruz": "Technological University of the Center of Veracruz"
        },
        {
            "north_technological_university_of_uescalientes": "North Technological University of Uescalientes"
        },
        {
            "technological_institute_of_west_of_the_state_of_hidalgo": "Technological Institute of West of the State of Hidalgo"
        },
        {
            "arturo_rosenblueth_foundation_for_the_advancement_of_science": "Arturo Rosenblueth Foundation for the Advancement of Science"
        },
        {
            "university_center_doctor_emilio_c\u00e3\u00a1rdenas": "University Center Doctor Emilio C\u00c3\u00a1rdenas"
        },
        {
            "institute_of_higher_studies_of_the_center": "Institute of Higher Studies of the Center"
        },
        {
            "indigenous_intercultural_university_of_michoac\u00e3\u00a1n": "Indigenous Intercultural University of Michoac\u00c3\u00a1n"
        },
        {
            "cedva_group_educational_center": "CEDVA Group Educational Center"
        },
        {
            "technological_university_of_tecamachco": "Technological University of Tecamachco"
        },
        {
            "tecnological_university_of_the_zacatecas_state": "TECNOLOGICAL University of the Zacatecas state"
        },
        {
            "university_of_the_sierra_sonora": "University of the Sierra Sonora"
        },
        {
            "la_salle_laguna_university": "La Salle Laguna University"
        },
        {
            "swiss_institute_of_gastronomy_and_hospitality": "Swiss Institute of Gastronomy and Hospitality"
        },
        {
            "technological_university_of_tamaulipas_norte": "Technological University of Tamaulipas Norte"
        },
        {
            "intercultural_university_of_the_state_of_puebla": "Intercultural University of the State of Puebla"
        },
        {
            "technological_institute_of_ciudad_valles": "Technological Institute of Ciudad Valles"
        },
        {
            "institute_of_higher_studies_of_tourism": "Institute of Higher Studies of Tourism"
        },
        {
            "technological_institute_of_san_juan_del_r\u00e3\u00ado": "Technological Institute of San Juan del R\u00c3\u00ado"
        },
        {
            "top_technological_institute_of_comalcalco": "Top Technological Institute of Comalcalco"
        },
        {
            "digital_university_of_mexico_state": "Digital University of Mexico State"
        },
        {
            "mexican_anglo_university_center": "Mexican Anglo University Center"
        },
        {
            "xicotepec_university_of_ju\u00e3\u00a1rez_technological_university": "Xicotepec University of Ju\u00c3\u00a1rez Technological University"
        },
        {
            "po\u00e3\u00a9njamo_polytechnic_university": "Po\u00c3\u00a9njamo Polytechnic University"
        },
        {
            "superior_technological_institute_of_xalapa": "Superior Technological Institute of Xalapa"
        },
        {
            "intercultural_university_of_the_state_of_tabasco": "Intercultural University of the State of Tabasco"
        },
        {
            "university_of_the_orizaba_valley": "University of the Orizaba Valley"
        },
        {
            "technological_institute_of_superior_studies_of_los_cabos": "Technological Institute of Superior Studies of Los Cabos"
        },
        {
            "technological_institute_of_nogales": "Technological Institute of Nogales"
        },
        {
            "universidad_am\u00e3\u00a9rica_latina": "Universidad Am\u00c3\u00a9rica Latina"
        },
        {
            "private_university_of_the_state_of_morelos": "Private University of the State of Morelos"
        },
        {
            "university_of_the_toluca_valley": "University of the Toluca Valley"
        },
        {
            "normal_school_of_state_professor_jes\u00e3\u00bas_manuel_bustamante_mango": "Normal School of State Professor Jes\u00c3\u00bas Manuel Bustamante Mango"
        },
        {
            "institute_of_gestalt_therapy_of_valencia": "Institute of Gestalt Therapy of Valencia"
        },
        {
            "technology_of_higher_studies_of_chimalhuac\u00e3\u00a1n": "Technology of Higher Studies of Chimalhuac\u00c3\u00a1n"
        },
        {
            "la_salle_salt_university": "La Salle Salt University"
        },
        {
            "normal_school_specialization_of_the_state_of_sinaloa": "Normal School Specialization of the State of Sinaloa"
        },
        {
            "technological_university_of_huasteca_hidalguense": "Technological University of Huasteca Hidalguense"
        },
        {
            "maya_world_university": "Maya World University"
        },
        {
            "superior_technological_institute_of_san_luis_potos\u00e3\u00ad": "Superior Technological Institute of San Luis Potos\u00c3\u00ad"
        },
        {
            "benito_juarez_university": "Benito Juarez University"
        },
        {
            "salesian_university": "Salesian University"
        },
        {
            "audiovisual_media_center": "Audiovisual Media Center"
        },
        {
            "university_of_la_canada": "University of La Canada"
        },
        {
            "mexican_university_veracruz.": "Mexican University Veracruz."
        },
        {
            "university_of_specialties": "University of specialties"
        },
        {
            "multicultural_university_cudec.": "Multicultural University Cudec."
        },
        {
            "university_institute_of_the_state_of_mexico": "University Institute of the State of Mexico"
        },
        {
            "pedagogical_university_of_the_state_of_sinaloa_ups": "Pedagogical University of the State of Sinaloa Ups"
        },
        {
            "sound_technological_university_of_sonora": "Sound Technological University of Sonora"
        },
        {
            "technological_university_of_southwest_guanajuato": "Technological University of Southwest Guanajuato"
        },
        {
            "technological_university_of_campeche": "Technological University of Campeche"
        },
        {
            "technological_university_of_torre\u00e3\u00b3n": "Technological University of Torre\u00c3\u00b3n"
        },
        {
            "southeast_university_institute": "Southeast University Institute"
        },
        {
            "da_vinci_university": "Da Vinci University"
        },
        {
            "spanish-american_euro_university": "Spanish-American Euro University"
        },
        {
            "universidad_obrera_de_mexico": "Universidad Obrera de Mexico"
        },
        {
            "technological_university_of_coahuila": "Technological University of Coahuila"
        },
        {
            "technological_university_of_iz\u00e3\u00bacar_de_matamoros": "Technological University of Iz\u00c3\u00bacar de Matamoros"
        },
        {
            "hipocrates_university": "Hipocrates University"
        },
        {
            "university_of_the_fort_valley": "University of the Fort Valley"
        },
        {
            "navojoa_university": "Navojoa University"
        },
        {
            "technological_institute_of_teziutl\u00e3\u00a1n": "Technological Institute of Teziutl\u00c3\u00a1n"
        },
        {
            "technological_institute_of_zit\u00e3\u00a1cuaro": "Technological Institute of Zit\u00c3\u00a1cuaro"
        },
        {
            "national_institute_of_public_health_insp": "National Institute of Public Health INSP"
        },
        {
            "quetzalc\u00e3\u00b3atl_university_irapuato": "Quetzalc\u00c3\u00b3atl University Irapuato"
        },
        {
            "technological_university_of_santa_catarina": "Technological University of Santa Catarina"
        },
        {
            "durango_santander_university": "Durango Santander University"
        },
        {
            "polytechnic_university_of_altamira": "Polytechnic University of Altamira"
        },
        {
            "superior_coacalco_studies_technology": "Superior Coacalco Studies Technology"
        },
        {
            "mexiquense_university": "Mexiquense University"
        },
        {
            "universidad_justo_sierra.": "Universidad Justo Sierra."
        },
        {
            "technological_institute_of_parral": "Technological Institute of Parral"
        },
        {
            "technological_institute_of_tuxtla_guti\u00e3\u00a9rrez": "Technological Institute of Tuxtla Guti\u00c3\u00a9rrez"
        },
        {
            "university_of_east_valladolid": "University of East Valladolid"
        },
        {
            "westhill_university": "Westhill University"
        },
        {
            "university_of_matehuala_campus_salinas": "University of Matehuala Campus Salinas"
        },
        {
            "technological_institute_of_chilpancingo": "Technological Institute of Chilpancingo"
        },
        {
            "university_partenon_de_cozumel": "University Partenon de Cozumel"
        },
        {
            "university_of_the_veracruz_univer_colima": "University of the Veracruz Univer Colima"
        },
        {
            "superior_studies_center_monte_phoenix": "Superior Studies Center Monte Phoenix"
        },
        {
            "university_of_hermosillo.": "University of Hermosillo."
        },
        {
            "mexico_international_university": "Mexico International University"
        },
        {
            "mesoamerican_university_oaxaca": "Mesoamerican University Oaxaca"
        },
        {
            "institute_of_legal_sciences_of_puebla": "Institute of Legal Sciences of Puebla"
        },
        {
            "institute_of_fiscal_and_administrative_studies": "Institute of Fiscal and Administrative Studies"
        },
        {
            "superior_technological_institute_of_motul": "Superior Technological Institute of Motul"
        },
        {
            "albert_einstein_mexico_university": "Albert Einstein Mexico University"
        },
        {
            "university_center_for_humanistic_integration_a.c.": "University Center for Humanistic Integration A.C."
        },
        {
            "superior_college_of_gastronomy": "Superior College of Gastronomy"
        },
        {
            "advanced_studies_center_of_the_americas": "Advanced Studies Center of the Americas"
        },
        {
            "instituto_miguel_\u00e3\u0081ngelo.": "Instituto Miguel \u00c3\u0081ngelo."
        },
        {
            "technological_institute_of_cerro_azul": "Technological Institute of Cerro Azul"
        },
        {
            "helenic_cultural_institute": "Helenic cultural institute"
        },
        {
            "realistic_university_of_mexico": "Realistic University of Mexico"
        },
        {
            "polytechnic_university_of_ramos_arizpe": "Polytechnic University of Ramos Arizpe"
        },
        {
            "technological_university_of_bahia_de_banderas": "Technological University of Bahia de Banderas"
        },
        {
            "technological_university_of_parral": "Technological University of Parral"
        },
        {
            "technological_university_el_estropho": "Technological University El Estropho"
        },
        {
            "technological_institute_of_the_costa_grande": "Technological Institute of the Costa Grande"
        },
        {
            "interdisciplinary_research_center_on_regional_development": "Interdisciplinary Research Center on Regional Development"
        },
        {
            "metropolitan_university_latin_campus": "Metropolitan University Latin Campus"
        },
        {
            "higher_technological_institute_of_cananea": "Higher Technological Institute of Cananea"
        },
        {
            "national_pedagogical_university_upn_quer\u00e3\u00a9taro": "National Pedagogical University UPN Quer\u00c3\u00a9taro"
        },
        {
            "college_of_graduate_studies_of_the_city_of_mexico": "College of graduate studies of the city of Mexico"
        },
        {
            "university_motolinia_del_valle": "University Motolinia del Valle"
        },
        {
            "private_university_of_irapuato": "Private University of Irapuato"
        },
        {
            "villasunction_university": "Villasunction University"
        },
        {
            "regional_university_center_of_totonacapan": "Regional University Center of Totonacapan"
        },
        {
            "moreels_center_of_the_arts": "MOREELS CENTER OF THE ARTS"
        },
        {
            "international_gastronomic_college": "International Gastronomic College"
        },
        {
            "technological_university_of_matamoros": "Technological University of Matamoros"
        },
        {
            "north_technological_university_of_coahuila": "North Technological University of Coahuila"
        },
        {
            "puebla_university_institute": "Puebla University Institute"
        },
        {
            "free_school_of_puebla_law": "Free School of Puebla Law"
        },
        {
            "gestalt_studies_center_for_design": "Gestalt Studies Center for Design"
        },
        {
            "hidalgo_university_center": "Hidalgo University Center"
        },
        {
            "superior_technological_institute_of_villa_la_sale_huimanguillo": "Superior Technological Institute of Villa La Sale Huimanguillo"
        },
        {
            "benem\u00e3\u00a9rito_normal_institute_of_general_state_juan_c_bonilla": "Benem\u00c3\u00a9rito Normal Institute of General State Juan C Bonilla"
        },
        {
            "university_center_of_coatzacoalcos": "University Center of Coatzacoalcos"
        },
        {
            "institute_of_philosophy": "Institute of Philosophy"
        },
        {
            "tlaxco_technological_institute": "Tlaxco Technological Institute"
        },
        {
            "corregidora_technological_university": "Corregidora Technological University"
        },
        {
            "conservatory_of_music_of_the_state_of_mexico": "Conservatory of Music of the State of Mexico"
        },
        {
            "gestalt_university_of_america": "Gestalt University of America"
        },
        {
            "marist_university_of_san_luis_potos\u00e3\u00ad": "Marist University of San Luis Potos\u00c3\u00ad"
        },
        {
            "agricultural_high_college_of_the_state_of_guerrero": "Agricultural High College of the State of Guerrero"
        },
        {
            "mexican_couple_institute": "Mexican Couple Institute"
        },
        {
            "alva_edison_university": "Alva Edison University"
        },
        {
            "superior_technological_institute_of_sinaloa": "Superior Technological Institute of Sinaloa"
        },
        {
            "polytechnic_university_of_tapachula": "Polytechnic University of Tapachula"
        },
        {
            "university_of_ecatepec.": "University of Ecatepec."
        },
        {
            "university_of_la_veracruz_univer_plannel_nayarit": "University of La Veracruz Univer Plannel Nayarit"
        },
        {
            "european_university_puebla.": "European University Puebla."
        },
        {
            "technological_university_of_escuinapa": "Technological University of Escuinapa"
        },
        {
            "centenaria_and_benem\u00e3\u00a9rita_normal_school_of_the_state_of_quer\u00e3\u00a9taro_andr\u00e3\u00a9s_balvanera": "Centenaria and Benem\u00c3\u00a9rita Normal School of the State of Quer\u00c3\u00a9taro Andr\u00c3\u00a9s Balvanera"
        },
        {
            "inter-american_university_center_system_ceuni": "Inter-American University Center System Ceuni"
        },
        {
            "la_salle_victoria_university": "La Salle Victoria University"
        },
        {
            "technological_institute_of_tlaxcala_altiplano": "Technological Institute of Tlaxcala Altiplano"
        },
        {
            "universidad_xicotetepetl": "Universidad Xicotetepetl"
        },
        {
            "javier_barros_sierra_foundation": "Javier Barros Sierra Foundation"
        },
        {
            "state_institute_for_normal_education_of_nayarit_professor_and_bachelor_francisco_ben\u00e3\u00adtez_silva": "State Institute for Normal Education of Nayarit Professor and Bachelor Francisco Ben\u00c3\u00adtez Silva"
        },
        {
            "superior_technological_institute_of_cosamaloapan": "Superior Technological Institute of Cosamaloapan"
        },
        {
            "technological_institute_of_monclova": "Technological Institute of Monclova"
        },
        {
            "english_franco_university_institute_of_mexico": "English Franco University Institute of Mexico"
        },
        {
            "central_university_of_mexico": "Central University of Mexico"
        },
        {
            "university_cultural_center": "University Cultural Center"
        },
        {
            "physical_education_school_and_sports": "Physical Education School and Sports"
        },
        {
            "technological_institute_of_\u00e3\u0081lvaro_obreg\u00e3\u00b3n": "Technological Institute of \u00c3\u0081lvaro Obreg\u00c3\u00b3n"
        },
        {
            "school_free_of_psychology": "School free of psychology"
        },
        {
            "liceo_universidad_pedro_de_gante": "Liceo Universidad Pedro de Gante"
        },
        {
            "technological_institute_of_altamirano_city": "Technological Institute of Altamirano City"
        },
        {
            "superior_school_of_mexican_folk_dance_cacatl": "Superior School of Mexican Folk dance CACATL"
        },
        {
            "universidad_continental_justo_sierra_unicon": "Universidad Continental Justo Sierra UNICON"
        },
        {
            "international_university": "International university"
        },
        {
            "university_of_the_sierra": "University of the Sierra"
        },
        {
            "victoria_university": "Victoria University"
        },
        {
            "technology_of_baja_california_tbc_university": "Technology of Baja California TBC University"
        },
        {
            "continental_valley_university": "Continental Valley University"
        },
        {
            "top_normal_school_of_chiapas": "Top Normal School of Chiapas"
        },
        {
            "international_institute_of_tamaulipas_superior_studies": "International Institute of Tamaulipas Superior Studies"
        },
        {
            "banking_commercial_institute": "Banking Commercial Institute"
        },
        {
            "technological_institute_of_tlahuac_ii": "Technological Institute of Tlahuac II"
        },
        {
            "liceo_superior_studios_quer\u00e3\u00a9taro": "Liceo Superior Studios Quer\u00c3\u00a9taro"
        },
        {
            "university_technology_of_mexico": "University Technology of Mexico"
        },
        {
            "institute_of_superior_studies_benito_ju\u00e3\u00a1rez_garc\u00e3\u00ada": "Institute of Superior Studies Benito Ju\u00c3\u00a1rez Garc\u00c3\u00ada"
        },
        {
            "center_for_studies_and_guestaltic_research": "Center for Studies and Guestaltic Research"
        },
        {
            "emmanuel_kant_university_center": "Emmanuel Kant University Center"
        },
        {
            "unipuebla": "UNIPUEBLA"
        },
        {
            "technological_university_of_chetumal": "Technological University of Chetumal"
        },
        {
            "technological_university_of_culiac\u00e3\u00a1n": "Technological University of Culiac\u00c3\u00a1n"
        },
        {
            "technological_university_of_guaymas": "Technological University of Guaymas"
        },
        {
            "saint_angel_university_center": "Saint Angel University Center"
        },
        {
            "institute_of_superior_studies_in_architecture_and_design": "Institute of Superior Studies in Architecture and Design"
        },
        {
            "fresnillo_institute_of_fresnillo": "Fresnillo Institute of Fresnillo"
        },
        {
            "technological_institute_of_the_los_llanos_region": "Technological Institute of the Los Llanos Region"
        },
        {
            "university_institute_of_oaxaca": "University Institute of Oaxaca"
        },
        {
            "hispanic_american_educational_complex": "Hispanic American Educational Complex"
        },
        {
            "study_center_of_graduate_cep": "Study Center of Graduate Cep"
        },
        {
            "universidad_chapultepec": "Universidad Chapultepec"
        },
        {
            "institute_of_ucic_superior_studies": "Institute of UCIC Superior Studies"
        },
        {
            "spanish_pedagogical_institute_english_ipae": "Spanish Pedagogical Institute English IPAE"
        },
        {
            "upn_mexicali_national_pedagogical_university": "UPN Mexicali National Pedagogical University"
        },
        {
            "institute_for_training_and_development_volkswagen": "Institute for Training and Development Volkswagen"
        },
        {
            "san_carlos_university": "San Carlos University"
        },
        {
            "university_center_valle_de_m\u00e3\u00a9xico_uaem": "University Center Valle de M\u00c3\u00a9xico Uaem"
        },
        {
            "technological_institute_of_iztapalapa_iii": "Technological Institute of Iztapalapa III"
        },
        {
            "high_milpa_technological_institute": "High Milpa Technological Institute"
        },
        {
            "reynosa_technological_institute": "Reynosa Technological Institute"
        },
        {
            "felipe_carrillo_school_center_puerto": "Felipe Carrillo School Center Puerto"
        },
        {
            "national_pedagogical_university_upn_zitacuaro": "National Pedagogical University UPN ZITACUARO"
        },
        {
            "durango_technological_university": "Durango Technological University"
        },
        {
            "technological_university_of_the_coahuila_center_region": "Technological University of the Coahuila Center Region"
        },
        {
            "autonomous_university_of_black_stones": "Autonomous University of Black Stones"
        },
        {
            "jos\u00e3\u00a9_vasconcelos_university": "Jos\u00c3\u00a9 Vasconcelos University"
        },
        {
            "normal_bachelor_school_in_special_education": "Normal Bachelor School in Special Education"
        },
        {
            "normal_tlalnepantla_school": "Normal Tlalnepantla School"
        },
        {
            "university_of_london_campus_san_juan_del_r\u00e3\u00ado": "University of London Campus San Juan del R\u00c3\u00ado"
        },
        {
            "hern\u00e3\u00a1n_cort\u00e3\u00a9s_university": "Hern\u00c3\u00a1n Cort\u00c3\u00a9s University"
        },
        {
            "institute_of_fine_arts_of_the_state_of_baja_california": "Institute of Fine Arts of the State of Baja California"
        },
        {
            "puebloan_institute_of_higher_studies": "Puebloan Institute of Higher Studies"
        },
        {
            "technological_institute_of_huejutla": "Technological Institute of Huejutla"
        },
        {
            "tepexi_institute_of_rodr\u00e3\u00adguez_tepexi": "Tepexi Institute of Rodr\u00c3\u00adguez Tepexi"
        },
        {
            "professional_studies_center_pitman": "Professional Studies Center Pitman"
        },
        {
            "polytechnic_university_of_the_gulf_of_mexico": "Polytechnic University of the Gulf of Mexico"
        },
        {
            "universidad_franco_mexicana_ufram": "Universidad Franco Mexicana UFRAM"
        },
        {
            "family_therapy_institute": "Family Therapy Institute"
        },
        {
            "higher_studies_center_ctm_room_sierra_o'reilly": "Higher Studies Center CTM Room Sierra O'Reilly"
        },
        {
            "dance_school_gloria_campobello": "Dance School Gloria Campobello"
        },
        {
            "columbia_university_center": "Columbia University Center"
        },
        {
            "city_college_of_science_&_commerce_multan": "city College of Science & Commerce Multan"
        },
        {
            "uruapan_polytechnic_university": "Uruapan Polytechnic University"
        },
        {
            "mayab_technological_university": "Mayab Technological University"
        },
        {
            "institute_of_communication_arts_and_humanities_of_monterrey": "Institute of Communication Arts and Humanities of Monterrey"
        },
        {
            "quevedo_university_center": "Quevedo University Center"
        },
        {
            "superior_technological_institute_of_perote": "Superior Technological Institute of Perote"
        },
        {
            "patria_university": "Patria University"
        },
        {
            "technological_university_linares": "Technological University Linares"
        },
        {
            "institute_of_superior_studies_in_engineering": "Institute of Superior Studies in Engineering"
        },
        {
            "centro_educativo_jaltepec": "Centro Educativo Jaltepec"
        },
        {
            "baj\u00e3\u00ado_business_university_center": "Baj\u00c3\u00ado Business University Center"
        },
        {
            "private_school_normal_superior_benito_ju\u00e3\u00a1rez": "Private School Normal Superior Benito Ju\u00c3\u00a1rez"
        },
        {
            "univa_santiago_valley_university": "Univa Santiago Valley University"
        },
        {
            "zimapan_mining_technological_university": "Zimapan mining technological university"
        },
        {
            "cultural_institute_paideia": "Cultural Institute Paideia"
        },
        {
            "senior_studies_division_of_the_institute_march_18": "Senior Studies Division of the Institute March 18"
        },
        {
            "tlalnepantla_ateneo.": "Tlalnepantla Ateneo."
        },
        {
            "institute_las_americas_de_nayarit": "Institute Las Americas de Nayarit"
        },
        {
            "university_of_the_north_unip_pacific": "University of the North Unip Pacific"
        },
        {
            "polytechnic_university_of_the_sea_and_the_sierra": "Polytechnic University of the Sea and the Sierra"
        },
        {
            "private_institute_of_southern_mexico": "Private Institute of Southern Mexico"
        },
        {
            "superior_studies_center_for_specialized_education": "Superior Studies Center for Specialized Education"
        },
        {
            "international_school_of_higher_education": "International School of Higher Education"
        },
        {
            "national_pedagogical_university_upn_ciudad_carmen": "National Pedagogical University UPN Ciudad Carmen"
        },
        {
            "agustinian_studies_center_fray_andr\u00e3\u00a9s_de_urdaneta": "Agustinian Studies Center Fray Andr\u00c3\u00a9s de Urdaneta"
        },
        {
            "jave_studies_center": "Jave Studies Center"
        },
        {
            "intercontinental_superior_studies_center": "Intercontinental Superior Studies Center"
        },
        {
            "free_school_of_sinaloa_law": "Free School of Sinaloa Law"
        },
        {
            "ibero-american_technological_university": "Ibero-American Technological University"
        },
        {
            "universidad_iberomexicana_de_hidalgo": "Universidad Iberomexicana de Hidalgo"
        },
        {
            "institute_of_higher_studies_of_the_gulf_of_mexico": "Institute of Higher Studies of the Gulf of Mexico"
        },
        {
            "jannette_klein_universidad": "Jannette Klein Universidad"
        },
        {
            "ttop_normal_school_of_madero_citytop_normal_school_of_madero_city": "tTop Normal School of Madero CityTop Normal School of Madero City"
        },
        {
            "university_sor_juana_in\u00e3\u00a9s_de_la_cruz": "University Sor Juana In\u00c3\u00a9s de la Cruz"
        },
        {
            "bilingual_intercultural_indigenous_indigenous_school_jacinto_canek": "Bilingual Intercultural Indigenous Indigenous School Jacinto Canek"
        },
        {
            "national_pedagogical_university_upn_poza_rica": "National Pedagogical University UPN Poza Rica"
        },
        {
            "washington_uniwac_university": "Washington UNIWAC University"
        },
        {
            "university_of_the_arts_of_the_cultural_institute_of_aguascalientes_ica": "University of the Arts of the Cultural Institute of Aguascalientes Ica"
        },
        {
            "school_of_gastronomy_mar\u00e3\u00ada_reyna": "School of Gastronomy Mar\u00c3\u00ada Reyna"
        },
        {
            "normal_school_of_physical_education_of_the_state_of_mexico": "Normal School of Physical Education of the State of Mexico"
        },
        {
            "superior_school_of_veterinary_medicine_and_zootecnia": "Superior School of Veterinary Medicine and Zootecnia"
        }
    ],
    "austria": [
        {
            "university_of_vienna": "University of Vienna"
        },
        {
            "university_of_innsbruck": "University of Innsbruck"
        },
        {
            "medical_university_vienna": "medical university Vienna"
        },
        {
            "technical_university_of_graz": "Technical University of Graz"
        },
        {
            "karl_franzens_university_of_graz": "Karl Franzens University of Graz"
        },
        {
            "johannes_kepler_university_linz": "Johannes Kepler University Linz"
        },
        {
            "university_of_foreign_culture_vienna": "University of Foreign Culture Vienna"
        },
        {
            "medical_university_innsbruck": "Medical University Innsbruck"
        },
        {
            "medical_university_of_graz": "Medical University of Graz"
        },
        {
            "vienna_university_of_economics_and_business": "Vienna University of Economics and Business"
        },
        {
            "veterinary_university_of_vienna": "Veterinary University of Vienna"
        },
        {
            "alps_adria_university_klagenfurt": "Alps Adria University Klagenfurt"
        },
        {
            "technical_university_of_vienna": "technical University of Vienna"
        },
        {
            "university_of_leoben": "University of Leoben"
        },
        {
            "danube_university_krems_university_of_training": "Danube University Krems University of Training"
        },
        {
            "paracelsus_medical_private_university": "Paracelsus Medical Private University"
        },
        {
            "paris_lodron_university_salzburg": "Paris Lodron University Salzburg"
        },
        {
            "university_of_applied_sciences_salzburg": "University of Applied Sciences Salzburg"
        },
        {
            "university_of_applied_sciences_upper_austria": "University of Applied Sciences Upper Austria"
        },
        {
            "sigmund_freud_private_university_of_vienna": "Sigmund Freud Private University of Vienna"
        },
        {
            "university_of_applied_sciences_technikum_vienna": "University of Applied Sciences Technikum Vienna"
        },
        {
            "institute_of_sciaence_and_technology_ist_austria": "Institute of Sciaence and Technology IST Austria"
        },
        {
            "private_university_of_health_sciences_medical_informatics_and_technology": "Private University of Health Sciences Medical Informatics and Technology"
        },
        {
            "university_of_applied_sciences_joanneum": "University of Applied Sciences Joanneum"
        },
        {
            "mci_management_center_innsbruck": "MCI Management Center Innsbruck"
        },
        {
            "university_of_applied_sciences_technikum_carinthia": "University of Applied Sciences Technikum Carinthia"
        },
        {
            "university_of_music_and_performing_art_vienna": "University of music and performing art Vienna"
        },
        {
            "webster_university_vienna": "Webster University Vienna"
        },
        {
            "university_of_applied_sciences_saint_polten": "University of Applied Sciences Saint Polten"
        },
        {
            "university_of_applied_sciences_vorarlberg": "University of Applied Sciences Vorarlberg"
        },
        {
            "university_of_applied_art_vienna": "University of Applied Art Vienna"
        },
        {
            "university_of_applied_sciences_wiener_neustadt": "University of Applied Sciences Wiener Neustadt"
        },
        {
            "university_of_applied_sciences_krems": "University of Applied Sciences Krems"
        },
        {
            "college_degree_courses_burgenland": "College degree courses Burgenland"
        },
        {
            "vorarlberger_landesconservatorium": "Vorarlberger LandesConservatorium"
        },
        {
            "academy_of_fine_arts_wien": "Academy of Fine Arts Wien"
        },
        {
            "institute_of_higher_studies_vienna": "Institute of Higher Studies Vienna"
        },
        {
            "modul_university": "MODUL University"
        },
        {
            "art_university_graz": "Art University Graz"
        },
        {
            "university_of_mozarteum_salzburg": "University of Mozarteum Salzburg"
        },
        {
            "university_of_applied_sciences_of_the_bfi_vienna": "University of Applied Sciences of the BFI Vienna"
        },
        {
            "university_of_applied_sciences_campus_vienna": "University of Applied Sciences Campus Vienna"
        },
        {
            "catholic_educational_university_equipment_carinthia_in_klagenfurt": "Catholic Educational University Equipment Carinthia in Klagenfurt"
        },
        {
            "university_of_applied_sciences_kufstein": "University of Applied Sciences Kufstein"
        },
        {
            "fhwien_studien\u00e3\u00a4nge_der_wkw": "FHWien Studien\u00c3\u00a4nge der WKW"
        },
        {
            "pedagogical_university_of_lower_austria": "Pedagogical University of Lower Austria"
        },
        {
            "music_and_art_private_university_of_the_city_of_vienna": "Music and Art Private University of the City of Vienna"
        },
        {
            "art_university_linz": "Art University Linz"
        },
        {
            "anton_bruckner_private_university": "Anton Bruckner Private University"
        },
        {
            "diplomatic_academy_vienna": "Diplomatic Academy Vienna"
        },
        {
            "pedagogical_university_of_applied_sciences_vienna": "Pedagogical University of Applied Sciences Vienna"
        },
        {
            "university_of_applied_sciences_campus_graz": "University of Applied Sciences Campus Graz"
        },
        {
            "new_design_university": "New Design University"
        },
        {
            "catholic_theological_private_university_linz": "Catholic Theological Private University Linz"
        },
        {
            "private_educational_university_hochschulfstiftung_diocese_innsbruck": "Private Educational University Hochschulfstiftung Diocese Innsbruck"
        },
        {
            "pedagogical_university_of_styria": "Pedagogical University of Styria"
        },
        {
            "church_educational_university_of_education_/_krems": "Church Educational University of Education / Krems"
        },
        {
            "educational_university_of_diocese_linz": "Educational University of Diocese Linz"
        },
        {
            "karl_landstein_private_university_of_health_sciences": "Karl Landstein Private University of Health Sciences"
        },
        {
            "pedagogical_college_of_carinthia": "Pedagogical College of Carinthia"
        },
        {
            "tgm_institute_of_technology": "TGM Institute of Technology"
        },
        {
            "pedagogical_university_of_salzburg": "Pedagogical University of Salzburg"
        },
        {
            "educational_university_of_tyrol": "Educational University of Tyrol"
        },
        {
            "salzburg_management_business_school": "Salzburg Management Business School"
        },
        {
            "college_of_agriculture_and_environmental_education_vienna": "College of Agriculture and Environmental Education Vienna"
        },
        {
            "lauder_business_school": "Lauder Business School"
        },
        {
            "umit_center_for_health_professions_tirol": "UMIT Center for Health Professions Tirol"
        },
        {
            "jam_music_lab_private_university": "Jam Music Lab Private University"
        },
        {
            "international_theological_institute": "International Theological Institute"
        },
        {
            "private_educational_university_foundation_burgenland": "Private Educational University Foundation Burgenland"
        },
        {
            "danube_private_university": "Danube Private University"
        },
        {
            "holztechnikum_kuchl": "Holztechnikum Kuchl"
        },
        {
            "bertha_of_suttner_private_university_st_p\u00e3\u00b6lten": "Bertha of Suttner Private University St P\u00c3\u00b6lten"
        },
        {
            "gustav_mahler_private_university_of_music": "Gustav Mahler Private University of Music"
        }
    ],
    "portugal": [
        {
            "university_of_porto": "University of Porto"
        },
        {
            "lisbon_university": "Lisbon University"
        },
        {
            "coimbra_university": "Coimbra University"
        },
        {
            "minho's_university": "Minho's university"
        },
        {
            "aveiro_university": "Aveiro University"
        },
        {
            "university_of_beira_interior": "University of Beira Interior"
        },
        {
            "polytechnic_institute_of_porto": "Polytechnic Institute of Porto"
        },
        {
            "algarve_university": "Algarve University"
        },
        {
            "university_of_after_mounts_and_high_douro": "University of After Mounts and High Douro"
        },
        {
            "iscte_university_institute_of_lisbon": "ISCTE University Institute of Lisbon"
        },
        {
            "portuguese_catholic_university": "Portuguese Catholic University"
        },
        {
            "new_university_of_lisbon": "New University of Lisbon"
        },
        {
            "polytechnic_institute_of_bragan\u00e3\u00a7a": "Polytechnic Institute of Bragan\u00c3\u00a7a"
        },
        {
            "university_of_the_azores": "University of the Azores"
        },
        {
            "polytechnic_institute_of_lisbon": "Polytechnic Institute of Lisbon"
        },
        {
            "polytechnic_institute_of_leiria": "Polytechnic Institute of Leiria"
        },
        {
            "universidade_lus\u00e3\u00b3fona_de_humanidades_e_tecnologias": "Universidade Lus\u00c3\u00b3fona de Humanidades e Tecnologias"
        },
        {
            "superior_institute_of_applied_psychology": "Superior Institute of Applied Psychology"
        },
        {
            "polytechnic_institute_of_castelo_branco": "Polytechnic Institute of Castelo Branco"
        },
        {
            "open_university": "Open university"
        },
        {
            "polytechnic_institute_of_viana_do_castelo": "Polytechnic Institute of Viana do Castelo"
        },
        {
            "polytechnic_institute_of_viseu": "Polytechnic Institute of Viseu"
        },
        {
            "polytechnic_institute_of_set\u00e3\u00babal": "Polytechnic Institute of Set\u00c3\u00babal"
        },
        {
            "cooperativa_de_teaching_higher,_polytechnic_and_university": "Cooperativa de Teaching Higher, Polytechnic and University"
        },
        {
            "polytechnic_institute_of_cavado_and_ave": "Polytechnic Institute of Cavado and Ave"
        },
        {
            "guardian_polytechnic_institute": "Guardian Polytechnic Institute"
        },
        {
            "polytechnic_institute_of_take": "Polytechnic Institute of Take"
        },
        {
            "polytechnic_institute_of_santar\u00e3\u00a9m": "Polytechnic Institute of Santar\u00c3\u00a9m"
        },
        {
            "fernando_pessoa": "Fernando Pessoa"
        },
        {
            "portucalense_university_infante_d_henrique": "Portucalense University Infante D Henrique"
        },
        {
            "polytechnic_institute_of_coimbra": "Polytechnic Institute of Coimbra"
        },
        {
            "coimbra_nursing_school": "Coimbra Nursing School"
        },
        {
            "beja_polytechnic_institute": "Beja Polytechnic Institute"
        },
        {
            "superior_institute_of_maia": "Superior Institute of Maia"
        },
        {
            "polytechnic_institute_of_portalegre": "Polytechnic Institute of Portalegre"
        },
        {
            "lusiada_university_of_lisbon": "Lusiada University of Lisbon"
        },
        {
            "porto_nursing_school": "Porto Nursing School"
        },
        {
            "atlantic_university": "Atlantic University"
        },
        {
            "piaget_institute": "Piaget Institute"
        },
        {
            "high_school_of_health_egas_moniz": "High School of Health Egas Moniz"
        },
        {
            "higher_institute_of_engineering_of_lisbon": "Higher Institute of Engineering of Lisbon"
        },
        {
            "autonomous_university_of_lisbon": "Autonomous University of Lisbon"
        },
        {
            "superior_institute_of_engineering_of_coimbra": "Superior Institute of Engineering of Coimbra"
        },
        {
            "nautical_school_infante_dom_henrique": "Nautical School Infante Dom Henrique"
        },
        {
            "lisbon_high_school_of_nursing": "Lisbon High School of Nursing"
        },
        {
            "superior_institute_miguel_torga": "Superior Institute Miguel Torga"
        },
        {
            "lusofona_university_of_porto": "Lusofona University of Porto"
        },
        {
            "school_of_arts_and_matosinhos_design": "School of Arts and Matosinhos Design"
        },
        {
            "military_academy_portugal": "Military Academy Portugal"
        },
        {
            "coimbra_agrarian_high_school": "Coimbra Agrarian High School"
        },
        {
            "estoril_hospitality_and_tourism_school": "Estoril Hospitality and Tourism School"
        },
        {
            "superior_institute_of_education_and_sciences_isec_lisboa": "Superior Institute of Education and Sciences ISEC Lisboa"
        },
        {
            "high_school_of_imobiliar_activities": "High School of Imobiliar Activities"
        },
        {
            "institute_of_accounting_and_management_of_coimbra": "Institute of Accounting and Management of Coimbra"
        },
        {
            "alcitude_health_school_of_alcohol": "Alcitude Health School of Alcohol"
        },
        {
            "school_of_education_paula_frassinetti": "School of Education Paula Frassinetti"
        },
        {
            "lusophone_university_of_humanities_and_technologies": "Lusophone University of Humanities and Technologies"
        },
        {
            "lus\u00e3\u00adada_universities_of_lisbon": "Lus\u00c3\u00adada Universities of Lisbon"
        },
        {
            "vasco_da_gama_college_school": "Vasco da Gama College School"
        },
        {
            "higher_artistic_school_of_porto": "Higher Artistic School of Porto"
        },
        {
            "higher_polytechnic_institute_of_gaya": "Higher Polytechnic Institute of Gaya"
        },
        {
            "superior_institute_bissaya_barreto": "Superior Institute Bissaya Barreto"
        },
        {
            "metropolitan_academy_conservatory_school": "Metropolitan Academy Conservatory School"
        },
        {
            "superior_institute_of_administration_and_management": "Superior Institute of Administration and Management"
        },
        {
            "lus\u00e3\u00adada_universities_of_porto": "Lus\u00c3\u00adada Universities of Porto"
        },
        {
            "superior_institute_of_business_sciences_and_tourism": "Superior Institute of Business Sciences and Tourism"
        },
        {
            "higher_school_gallaecia": "Higher School Gallaecia"
        },
        {
            "institute_for_fafe_superiors": "Institute for Fafe Superiors"
        },
        {
            "superior_institute_of_between_douro_and_vouga": "Superior Institute of Between Douro and Vouga"
        },
        {
            "portalegre_health_school": "Portalegre Health School"
        },
        {
            "institute_of_information_sciences_and_administration": "Institute of Information Sciences and Administration"
        },
        {
            "gaia_regional_conservatory_foundation": "Gaia Regional Conservatory Foundation"
        },
        {
            "high_health_institute_of_high_ave": "High Health Institute of High Ave"
        },
        {
            "esscvp_superior_school_of_portuguese_red_cross_-_lisbon": "Esscvp Superior School of Portuguese Red Cross - Lisbon"
        },
        {
            "superior_institute_of_management_and_languages_\u00e2\u20ac\u2039\u00e2\u20ac\u2039of_madeira": "Superior Institute of Management and Languages \u00e2\u20ac\u2039\u00e2\u20ac\u2039of Madeira"
        },
        {
            "institute_of_educational_sciences": "Institute of Educational Sciences"
        },
        {
            "superior_institute_of_porto_social_service": "Superior Institute of Porto Social Service"
        },
        {
            "institute_superior_manuel_teixeira_gomes_ismat": "Institute Superior Manuel Teixeira Gomes Ismat"
        },
        {
            "school_of_education_of_portalegre": "School of Education of Portalegre"
        },
        {
            "independent_university_uni": "Independent university uni"
        },
        {
            "upper_atlantic_business_school": "Upper Atlantic Business School"
        },
        {
            "polytechnic_teaching_of_lusophone_ipluso": "Polytechnic teaching of Lusophone Ipluso"
        },
        {
            "superior_institute_of_pa\u00e3\u00a7os_de_brand\u00e3\u00a3o": "Superior Institute of Pa\u00c3\u00a7os de Brand\u00c3\u00a3o"
        },
        {
            "institute_of_business_communication": "Institute of Business Communication"
        },
        {
            "institute_of_police_sciences_and_internal_security": "Institute of Police Sciences and Internal Security"
        },
        {
            "saint_joseph_of_cluny_nursing_school": "Saint Joseph of Cluny Nursing School"
        },
        {
            "school_of_nursing_san_francisco_das_miseric\u00e3\u00b3dias": "School of Nursing San Francisco das Miseric\u00c3\u00b3dias"
        }
    ],
    "czech republic": [
        {
            "charles_university_in_prague": "Charles University in Prague"
        },
        {
            "masaryk_university_in_brno": "Masaryk University in Brno"
        },
        {
            "czech_technical_university_in_prague": "Czech Technical University in Prague"
        },
        {
            "palack\u00e3\u00bd_university_of_olomouc": "Palack\u00c3\u00bd University of Olomouc"
        },
        {
            "brno_university_of_technology": "Brno University of Technology"
        },
        {
            "technical_university_of_ostrava": "Technical University of Ostrava"
        },
        {
            "university_of_south_bohemia_in_\u00e4\u0153esk\u00e3\u00a9_bud\u00e4\u203ajovice": "University of South Bohemia in \u00c4\u0152esk\u00c3\u00a9 Bud\u00c4\u203ajovice"
        },
        {
            "czech_university_of_life_sciences_prague": "Czech University of Life Sciences Prague"
        },
        {
            "university_of_chemistry_and_technology_prague": "University of Chemistry and Technology Prague"
        },
        {
            "university_of_west_bohemia": "University of West Bohemia"
        },
        {
            "tomas_bata_university_in_zl\u00e3\u00adn": "Tomas Bata University in Zl\u00c3\u00adn"
        },
        {
            "mendel_university_brno": "Mendel University Brno"
        },
        {
            "university_of_economics_prague": "University of Economics Prague"
        },
        {
            "university_of_ostrava": "University of Ostrava"
        },
        {
            "university_of_hradec_kralove": "University of Hradec Kralove"
        },
        {
            "university_of_pardubice": "University of Pardubice"
        },
        {
            "technical_university_of_liberec": "Technical University of Liberec"
        },
        {
            "jan_evangelista_purkyn\u00e4\u203a_university_in_\u00e3\u0161st\u00e3\u00ad_nad_labem": "Jan Evangelista Purkyn\u00c4\u203a University in \u00c3\u0161st\u00c3\u00ad nad Labem"
        },
        {
            "silesian_university_opava": "Silesian University Opava"
        },
        {
            "university_of_defence_czech_republic": "University of Defence Czech Republic"
        },
        {
            "university_of_veterinary_and_pharmaceutical_sciences_brno": "University of Veterinary and Pharmaceutical Sciences Brno"
        },
        {
            "metropolitan_university_prague_(university_of_public_administration_and_international_relations_in_prague)": "Metropolitan University Prague (University of Public Administration and International Relations in Prague)"
        },
        {
            "institute_of_technology_and_business": "Institute of Technology and Business"
        },
        {
            "jan\u00e3\u00a1\u00e4\u008dek_academy_of_music_and_performing_arts": "Jan\u00c3\u00a1\u00c4\u008dek Academy of Music and Performing Arts"
        },
        {
            "academy_of_arts_architecture_and_design_in_prague": "Academy of Arts Architecture and Design in Prague"
        },
        {
            "university_of_economics_and_management": "University of Economics and Management"
        },
        {
            "anglo-american_university": "Anglo-American University"
        },
        {
            "university_of_new_york_in_prague": "University of New York in Prague"
        },
        {
            "academy_of_fine_arts_prague": "Academy of Fine Arts Prague"
        },
        {
            "academy_of_performing_arts_in_prague": "Academy of Performing Arts in Prague"
        },
        {
            "film_and_tv_school_of_academy_of_performing_arts_in_prague": "Film and TV School of Academy of Performing Arts in Prague"
        },
        {
            "business_school_ostrava": "Business School Ostrava"
        },
        {
            "cevro_institute": "CEVRO Institute"
        },
        {
            "\u00e5\u00a0koda_auto_university": "\u00c5\u00a0koda Auto University"
        },
        {
            "academy_of_performing_arts_in_prague_music_and_dance_faculty": "Academy of Performing Arts in Prague Music and Dance faculty"
        },
        {
            "college_of_business_studies_in_prague": "College of Business Studies in Prague"
        },
        {
            "banking_institute_college_of_banking": "Banking Institute College of Banking"
        },
        {
            "moravian_college": "Moravian College"
        },
        {
            "institute_of_hospitality_management_in_prague": "Institute of Hospitality Management in Prague"
        },
        {
            "ambis_vysok\u00e3\u00a1_\u00e5\u00a1kola": "Ambis Vysok\u00c3\u00a1 \u00c5\u00a1kola"
        },
        {
            "unicorn_university": "Unicorn University"
        },
        {
            "architectural_institute_in_prague_archip": "Architectural Institute in Prague ARCHIP"
        },
        {
            "palestra_prague": "Palestra Prague"
        },
        {
            "film_academy_of_miroslav_in_p\u00e3\u00adsek": "Film Academy of Miroslav in P\u00c3\u00adsek"
        },
        {
            "logistics_college": "Logistics College"
        },
        {
            "international_baptist_theological_seminary_of_the_european_baptist_federation": "International Baptist Theological Seminary of the European Baptist Federation"
        },
        {
            "newton_university": "Newton University"
        },
        {
            "college_of_business_and_hotel_brno": "College of Business and Hotel Brno"
        },
        {
            "prigo_university": "Prigo University"
        },
        {
            "college_of_health": "College of Health"
        },
        {
            "european_polytechnic_institute": "European Polytechnic Institute"
        },
        {
            "university_of_international_&_public_relations_prague": "University of International & Public Relations Prague"
        },
        {
            "akcent_college_prague": "AKCENT College Prague"
        }
    ],
    "greece": [
        {
            "national_and_kapodistrian_university_of_athens": "National and Kapodistrian University of Athens"
        },
        {
            "aristotle_university_of_thessaloniki": "Aristotle University of Thessaloniki"
        },
        {
            "national_technical_university_of_athens": "National Technical University of Athens"
        },
        {
            "university_of_patras_(incl_university_of_western_greece)": "University of Patras (incl University of Western Greece)"
        },
        {
            "university_of_thessaly": "University of Thessaly"
        },
        {
            "democritus_university_of_thrace": "Democritus University of Thrace"
        },
        {
            "university_of_the_aegean": "University of the Aegean"
        },
        {
            "harokopio_university_of_athens": "Harokopio University of Athens"
        },
        {
            "athens_university_of_economics_and_business": "Athens University of Economics and Business"
        },
        {
            "technical_university_of_crete": "Technical University of Crete"
        },
        {
            "university_of_piraeus": "University of Piraeus"
        },
        {
            "agricultural_university_of_athens": "Agricultural University of Athens"
        },
        {
            "university_of_macedonia": "University of Macedonia"
        },
        {
            "university_of_west_attica": "University of West Attica"
        },
        {
            "university_of_peloponnese": "University of Peloponnese"
        },
        {
            "ionian_university": "Ionian University"
        },
        {
            "university_of_western_macedonia": "University of Western Macedonia"
        },
        {
            "panteion_university_of_political_and_social_sciences": "Panteion University of Political and Social Sciences"
        },
        {
            "american_college_of_greece": "American College of Greece"
        },
        {
            "school_of_pedagogical_and_technological_education": "School of Pedagogical and Technological Education"
        },
        {
            "metropolitan_college": "Metropolitan College"
        },
        {
            "hellenic_army_academy": "Hellenic Army Academy"
        },
        {
            "american_school_of_classical_studies_at_athens": "American School of Classical Studies at Athens"
        },
        {
            "aegean_omiros_college": "Aegean Omiros College"
        },
        {
            "hellenic_naval_academy": "Hellenic Naval Academy"
        },
        {
            "american_college_of_thessaloniki": "American College of Thessaloniki"
        },
        {
            "athens_school_of_fine_arts": "Athens School of Fine Arts"
        },
        {
            "city_unity_college": "City Unity College"
        },
        {
            "conservatoire_of_northern_greece": "Conservatoire of Northern Greece"
        },
        {
            "mediterranean_college": "Mediterranean College"
        },
        {
            "new_york_college": "New York College"
        },
        {
            "athens_information_technology": "Athens Information Technology"
        },
        {
            "city_college_university_of_york_europe_campus": "CITY College University of York Europe Campus"
        },
        {
            "bca_business_studies": "Bca Business Studies"
        },
        {
            "act_art_&_design": "Act Art & Design"
        },
        {
            "vakalo_art_&_design_college": "Vakalo Art & Design College"
        },
        {
            "icbs_business_school": "ICBS Business School"
        },
        {
            "swiss_business_school_for_international_hotel_&_tourism_management_education_(alpine_center)": "Swiss Business School for International Hotel & Tourism Management Education (Alpine Center)"
        },
        {
            "state_conservatory_of_thessaloniki": "State Conservatory of Thessaloniki"
        },
        {
            "dei_college": "DEI College"
        },
        {
            "institution_francophone_detodes": "Institution Francophone Detodes"
        },
        {
            "athens_school_of_management": "Athens School of Management"
        },
        {
            "greek_bible_college": "Greek Bible College"
        }
    ],
    "russia": [
        {
            "lomonosov_moscow_state_university": "Lomonosov Moscow State University"
        },
        {
            "saint_petersburg_state_university": "Saint Petersburg State University"
        },
        {
            "national_research_university_higher_school_of_economics": "National Research University Higher School of Economics"
        },
        {
            "national_nuclear_research_university_(moscow_state_engineering_physics_institute)": "National Nuclear Research University (Moscow State Engineering Physics Institute)"
        },
        {
            "tomsk_state_university": "Tomsk State University"
        },
        {
            "novosibirsk_state_university": "Novosibirsk State University"
        },
        {
            "peter_the_great_st.petersburg_polytechnic_university": "Peter the Great St.Petersburg Polytechnic University"
        },
        {
            "moscow_institute_of_physics_and_technology": "Moscow Institute of Physics and Technology"
        },
        {
            "kazan_(volga_region)_federal_university": "Kazan (Volga Region) Federal University"
        },
        {
            "tomsk_polytechnic_university": "Tomsk Polytechnic University"
        },
        {
            "itmo_university": "ITMO University"
        },
        {
            "peoples'_friendship_university_of_russia": "Peoples' Friendship University of Russia"
        },
        {
            "ural_federal_university": "Ural Federal University"
        },
        {
            "national_university_of_science_&_technology_misis_(moscow_institute_of_steel_and_alloys)": "National University of Science & Technology MISIS (Moscow Institute of Steel and Alloys)"
        },
        {
            "russian_university_of_economics_g_v_plekhanov": "Russian University of Economics G V Plekhanov"
        },
        {
            "skolkovo_institute_of_science_and_technology_skoltech": "Skolkovo Institute of Science and Technology Skoltech"
        },
        {
            "southern_federal_university_(rostov_state_university)": "Southern Federal University (Rostov State University)"
        },
        {
            "siberian_federal_university": "Siberian Federal University"
        },
        {
            "bauman_moscow_state_technical_university": "Bauman Moscow State Technical University"
        },
        {
            "financial_university_under_the_government_of_the_russian_federation": "Financial University under the Government of the Russian Federation"
        },
        {
            "samara_national_research_university": "Samara National Research University"
        },
        {
            "nizhny_novgorod_state_university": "Nizhny Novgorod State University"
        },
        {
            "south_ural_state_university": "South Ural State University"
        },
        {
            "belgorod_state_university": "Belgorod State University"
        },
        {
            "saratov_state_university": "Saratov State University"
        },
        {
            "far_eastern_federal_university": "Far Eastern Federal University"
        },
        {
            "russian_presidential_academy_of_national_economy_and_public_administration": "Russian Presidential Academy of National Economy and Public Administration"
        },
        {
            "north_eastern_federal_university_mk_ammosova_(yakutsk_state_university)": "North Eastern Federal University MK Ammosova (Yakutsk State University)"
        },
        {
            "altai_state_university": "Altai State University"
        },
        {
            "russian_state_social_university": "Russian State Social University"
        },
        {
            "kazan_state_technical_university_an_tupolev": "Kazan State Technical University AN Tupolev"
        },
        {
            "novosibirsk_state_technical_university": "Novosibirsk State Technical University"
        },
        {
            "moscow_state_institute_of_international_relations": "Moscow State Institute of International Relations"
        },
        {
            "i_m_sechenov_first_moscow_state_medical_university_(sechenov_university)": "I M Sechenov First Moscow State Medical University (Sechenov University)"
        },
        {
            "saint_petersburg_mining_university": "Saint Petersburg Mining University"
        },
        {
            "voronezh_state_university": "Voronezh State University"
        },
        {
            "tyumen_state_university": "Tyumen State University"
        },
        {
            "saint_petersburg_state_electrotechnical_university": "Saint Petersburg State Electrotechnical University"
        },
        {
            "volgograd_state_university": "Volgograd State University"
        },
        {
            "immanuel_kant_state_university_of_russia_(kaliningrad_state_university)": "Immanuel Kant State University of Russia (Kaliningrad State University)"
        },
        {
            "moscow_aviation_institute_national_research_university": "Moscow Aviation Institute National Research University"
        },
        {
            "tomsk_state_university_of_control_systems_and_radioelectronics": "Tomsk State University of Control Systems and Radioelectronics"
        },
        {
            "bashkir_state_university": "Bashkir State University"
        },
        {
            "moscow_power_engineering_institute": "Moscow Power Engineering Institute"
        },
        {
            "russian_state_pedagogical_university_ai_herzen_(gertsenovsky_university)": "Russian State Pedagogical University AI Herzen (Gertsenovsky University)"
        },
        {
            "northern_(arctic)_federal_university_(arkhangelsk_state_technical_university)": "Northern (Arctic) Federal University (Arkhangelsk State Technical University)"
        },
        {
            "petrozavodsk_state_university": "Petrozavodsk State University"
        },
        {
            "togliatti_state_university": "Togliatti State University"
        },
        {
            "orenburg_state_university": "Orenburg State University"
        },
        {
            "perm_state_university": "Perm State University"
        },
        {
            "perm_state_technical_university": "Perm State Technical University"
        },
        {
            "don_state_technical_university": "Don State Technical University"
        },
        {
            "gubkin_russian_state_university_of_oil_and_gas": "Gubkin Russian State University of Oil and Gas"
        },
        {
            "stavropol_state_agrarian_university": "Stavropol State Agrarian University"
        },
        {
            "moscow_technological_university": "Moscow Technological University"
        },
        {
            "moscow_state_university_of_civil_engineering": "Moscow State University of Civil Engineering"
        },
        {
            "penza_state_university": "Penza State University"
        },
        {
            "innopolis_university": "Innopolis University"
        },
        {
            "irkutsk_state_university": "Irkutsk State University"
        },
        {
            "mendeleev_university_of_chemical_technology_of_russia": "Mendeleev University of Chemical Technology of Russia"
        },
        {
            "samara_state_technical_university": "Samara State Technical University"
        },
        {
            "magnitogorsk_state_technical_university": "Magnitogorsk State Technical University"
        },
        {
            "belgorod_state_technological_university_vg_shukhov": "Belgorod State Technological University VG Shukhov"
        },
        {
            "tomsk_state_pedagogical_university": "Tomsk State Pedagogical University"
        },
        {
            "volgograd_state_technical_university": "Volgograd State Technical University"
        },
        {
            "ural_state_university_of_economics": "Ural State University of Economics"
        },
        {
            "kuban_state_agrarian_university": "Kuban State Agrarian University"
        },
        {
            "omsk_state_technical_university": "Omsk State Technical University"
        },
        {
            "ulyanovsk_state_technical_university": "Ulyanovsk State Technical University"
        },
        {
            "altai_state_technical_university": "Altai State Technical University"
        },
        {
            "chelyabinsk_state_university": "Chelyabinsk State University"
        },
        {
            "yaroslavl_state_university": "Yaroslavl State University"
        },
        {
            "moscow_state_regional_university": "Moscow State Regional University"
        },
        {
            "kuban_state_university": "Kuban State University"
        },
        {
            "nizhny_novgorod_state_technical_university": "Nizhny Novgorod State Technical University"
        },
        {
            "irkutsk_state_technical_university": "Irkutsk State Technical University"
        },
        {
            "moscow_pedagogical_state_university": "Moscow Pedagogical State University"
        },
        {
            "saint_petersburg_academic_university_(academic_university)": "Saint Petersburg Academic University (Academic University)"
        },
        {
            "mordovia_state_university": "Mordovia State University"
        },
        {
            "tyumen_industrial_university": "Tyumen Industrial University"
        },
        {
            "vyatka_state_university": "Vyatka State University"
        },
        {
            "kabardino_balkarian_state_university": "Kabardino Balkarian State University"
        },
        {
            "kazan_national_research_technological_university": "Kazan National Research Technological University"
        },
        {
            "russian_state_medical_university": "Russian State Medical University"
        },
        {
            "novosibirsk_state_university_of_economics_and_management": "Novosibirsk State University of Economics and Management"
        },
        {
            "moscow_state_university_of_psychology_and_education": "Moscow State University of Psychology and Education"
        },
        {
            "ivanovo_state_university_of_chemistry_and_technology": "Ivanovo State University of Chemistry and Technology"
        },
        {
            "voronezh_state_technical_university": "Voronezh State Technical University"
        },
        {
            "rostov_state_medical_university": "Rostov State Medical University"
        },
        {
            "southwestern_state_university_kursk": "Southwestern State University Kursk"
        },
        {
            "russian_state_vocational_pedagogical_university": "Russian State Vocational Pedagogical University"
        },
        {
            "tambov_state_technical_university": "Tambov State Technical University"
        },
        {
            "north-caucasus_federal_university_(stavropol_state_university)": "North-Caucasus Federal University (Stavropol State University)"
        },
        {
            "southern_russian_state_technical_university_(novocherkassk_polytechnical_institute)": "Southern Russian State Technical University (Novocherkassk Polytechnical Institute)"
        },
        {
            "udmurt_state_university": "Udmurt State University"
        },
        {
            "tver_state_university": "Tver State University"
        },
        {
            "samara_state_medical_university": "Samara State Medical University"
        },
        {
            "saint_petersburg_state_technological_institute_(technical_university)": "Saint Petersburg State Technological Institute (Technical University)"
        },
        {
            "yuri_gagarin_state_technical_university_of_saratov": "Yuri Gagarin State Technical University of Saratov"
        },
        {
            "far_eastern_state_university_of_railway_transport": "Far Eastern State University of Railway Transport"
        },
        {
            "moscow_institute_of_electronic_technology": "Moscow Institute of Electronic Technology"
        },
        {
            "russian_university_of_transport": "Russian University of Transport"
        },
        {
            "new_economic_school": "New Economic School"
        },
        {
            "ulyanovsk_state_university": "Ulyanovsk State University"
        },
        {
            "russian_state_university_for_the_humanities": "Russian State University for the Humanities"
        },
        {
            "lipetsk_state_technical_university": "Lipetsk State Technical University"
        },
        {
            "kursk_state_university": "Kursk State University"
        },
        {
            "astrakhan_state_university": "Astrakhan State University"
        },
        {
            "ufa_state_aviation_technical_university": "Ufa State Aviation Technical University"
        },
        {
            "kuzbass_state_technical_university": "Kuzbass State Technical University"
        },
        {
            "siberian_state_medical_academy": "Siberian State Medical Academy"
        },
        {
            "kazan_state_medical_university": "Kazan State Medical University"
        },
        {
            "state_university_of_management": "State University of Management"
        },
        {
            "moscow_state_technological_university_stankin": "Moscow State Technological University Stankin"
        },
        {
            "pacific_national_university": "Pacific National University"
        },
        {
            "kazan_state_power_engineering_university": "Kazan State Power Engineering University"
        },
        {
            "moscow_technical_university_of_communications_and_informatics": "Moscow Technical University of Communications and Informatics"
        },
        {
            "russian_state_hydrometeorological_university": "Russian State Hydrometeorological University"
        },
        {
            "syktyvkar_state_university": "Syktyvkar State University"
        },
        {
            "saint_petersburg_state_pavlov_medical_university": "Saint Petersburg State Pavlov Medical University"
        },
        {
            "tula_state_university": "Tula State University"
        },
        {
            "dubna_international_university_for_nature_society_and_man": "Dubna International University for Nature Society and Man"
        },
        {
            "northern_state_medical_university_arkhangelsk": "Northern State Medical University Arkhangelsk"
        },
        {
            "ural_state_pedagogical_university": "Ural State Pedagogical University"
        },
        {
            "omsk_state_university": "Omsk State University"
        },
        {
            "ufa_state_petroleum_technological_university": "Ufa State Petroleum Technological University"
        },
        {
            "saint_petersburg_state_university_of_aerospace_instrumentation": "Saint Petersburg State University of Aerospace Instrumentation"
        },
        {
            "saint_petersburg_state_university_of_architecture_and_civil_engineering": "Saint Petersburg State University of Architecture and Civil Engineering"
        },
        {
            "moscow_polytech": "Moscow Polytech"
        },
        {
            "saint_petersburg_state_university_of_telecommunication": "Saint Petersburg State University of Telecommunication"
        },
        {
            "vladivostock_state_university_of_economics": "Vladivostock State University of Economics"
        },
        {
            "rostov_state_university_of_economics": "Rostov State University of Economics"
        },
        {
            "pedagogical_university_of_moscow_city": "Pedagogical University of Moscow City"
        },
        {
            "izhevsk_state_technical_university": "Izhevsk State Technical University"
        },
        {
            "moscow_state_university_of_medicine_and_dentistry": "Moscow State University of Medicine and Dentistry"
        },
        {
            "saint_petersburg_state_transport_university": "Saint Petersburg State Transport University"
        },
        {
            "buryat_state_university": "Buryat State University"
        },
        {
            "yugra_state_university": "Yugra State University"
        },
        {
            "sochi_state_university_of_tourism_and_spa_industry": "Sochi State University of Tourism and Spa Industry"
        },
        {
            "vladimir_state_university": "Vladimir State University"
        },
        {
            "amur_state_university": "Amur State University"
        },
        {
            "kazan_state_academy_of_architecture_and_civil_engineering": "Kazan State Academy of Architecture and Civil Engineering"
        },
        {
            "bashkir_state_medical_university": "Bashkir State Medical University"
        },
        {
            "tula_state_pedagogical_university": "Tula State Pedagogical University"
        },
        {
            "chuvash_state_university": "Chuvash State University"
        },
        {
            "volgograd_medical_academy": "Volgograd Medical Academy"
        },
        {
            "dagestan_state_university": "Dagestan State University"
        },
        {
            "mari_state_university": "Mari State University"
        },
        {
            "samara_state_academy_of_social_sciences_and_humanities": "Samara State Academy of Social Sciences and Humanities"
        },
        {
            "orel_state_university": "Orel State University"
        },
        {
            "northeastern_state_university": "Northeastern State University"
        },
        {
            "ural_state_medical_university": "Ural State Medical University"
        },
        {
            "kemerovo_state_university": "Kemerovo State University"
        },
        {
            "saint_petersburg_state_university_of_economics": "Saint Petersburg State University of Economics"
        },
        {
            "pushkin_state_university_of_russian_language": "Pushkin State University of Russian Language"
        },
        {
            "moscow_state_technology_and_management_university": "Moscow State Technology and Management University"
        },
        {
            "tambov_state_university": "Tambov State University"
        },
        {
            "belgorod_state_agricultural_academy": "Belgorod State Agricultural Academy"
        },
        {
            "saint_petersburg_state_pediatric_medical_university": "Saint Petersburg State Pediatric Medical University"
        },
        {
            "volga_state_university_of_technology_(mari_state_technical_university)": "Volga State University of Technology (Mari State Technical University)"
        },
        {
            "siberian_state_industrial_university": "Siberian State Industrial University"
        },
        {
            "moscow_state_technical_university_of_civil_aviation": "Moscow State Technical University of Civil Aviation"
        },
        {
            "novgorod_state_university": "Novgorod State University"
        },
        {
            "kursk_state_medical_university": "Kursk State Medical University"
        },
        {
            "novosibirsk_state_medical_university": "Novosibirsk State Medical University"
        },
        {
            "voronezh_state_pedagogical_university": "Voronezh State Pedagogical University"
        },
        {
            "penza_state_university_of_architecture_and_construction": "Penza State University of Architecture and Construction"
        },
        {
            "institute_of_world_economy": "Institute of World Economy"
        },
        {
            "pskov_state_university": "Pskov State University"
        },
        {
            "novosibirsk_state_pedagogical_university": "Novosibirsk State Pedagogical University"
        },
        {
            "samara_state_economic_university": "Samara State Economic University"
        },
        {
            "kazan_innovative_university_v._g._timiryasov_ieml": "Kazan Innovative University V. G. Timiryasov IEML"
        },
        {
            "surgut_state_university": "Surgut State University"
        },
        {
            "cherepovets_state_university": "Cherepovets State University"
        },
        {
            "institute_of_economics_and_management_in_industry": "Institute of Economics and Management in Industry"
        },
        {
            "kuban_state_technological_university": "Kuban State Technological University"
        },
        {
            "ryazan_state_radioengineering_university": "Ryazan State Radioengineering University"
        },
        {
            "novosibirsk_state_agrarian_university": "Novosibirsk State Agrarian University"
        },
        {
            "kosygin_state_university_of_russia": "Kosygin State University of Russia"
        },
        {
            "ivanovo_state_university": "Ivanovo State University"
        },
        {
            "ural_state_university_of_physical_culture": "Ural State University of Physical Culture"
        },
        {
            "leningrad_state_university_a_s_pushkin": "Leningrad State University A S Pushkin"
        },
        {
            "saratov_state_agrarian_university": "Saratov State Agrarian University"
        },
        {
            "novosibirsk_state_university_of_architecture_and_civil_engineering": "Novosibirsk State University of Architecture and Civil Engineering"
        },
        {
            "ivanovo_state_power_university": "Ivanovo State Power University"
        },
        {
            "privolzhskiy_research_medical_university": "Privolzhskiy Research Medical University"
        },
        {
            "bryansk_state_technical_university": "Bryansk State Technical University"
        },
        {
            "moscow_state_university_of_geodesy_and_cartography": "Moscow State University of Geodesy and Cartography"
        },
        {
            "omsk_state_pedagogical_university": "Omsk State Pedagogical University"
        },
        {
            "russian_new_university": "Russian New University"
        },
        {
            "baikal_state_university": "Baikal State University"
        },
        {
            "kurgan_state_university": "Kurgan State University"
        },
        {
            "ulyanovsk_state_pedagogical_university_i_ulyanov": "Ulyanovsk State Pedagogical University I Ulyanov"
        },
        {
            "ural_state_law_academy": "Ural State Law Academy"
        },
        {
            "murmansk_state_technical_university": "Murmansk State Technical University"
        },
        {
            "kuban_state_medical_academy": "Kuban State Medical Academy"
        },
        {
            "khabarovsk_state_academy_of_economics_and_law": "Khabarovsk State Academy of Economics and Law"
        },
        {
            "tomsk_state_university_of_architecture_and_building": "Tomsk State University of Architecture and Building"
        },
        {
            "irkutsk_state_medical_university": "Irkutsk State Medical University"
        },
        {
            "saint_petersburg_state_marine_technical_university": "Saint Petersburg State Marine Technical University"
        },
        {
            "russian_state_academy_of_physical_education": "Russian State Academy of Physical Education"
        },
        {
            "saratov_state_medical_university": "Saratov State Medical University"
        },
        {
            "moscow_university_for_the_humanities": "Moscow University for the Humanities"
        },
        {
            "orenburg_state_medical_academy": "Orenburg State Medical Academy"
        },
        {
            "transbaikal_state_university": "Transbaikal State University"
        },
        {
            "kaliningrad_state_technical_university": "Kaliningrad State Technical University"
        },
        {
            "tver_state_technical_university": "Tver State Technical University"
        },
        {
            "siberian_state_university_of_telecommunications_and_informatics": "Siberian State University of Telecommunications and Informatics"
        },
        {
            "bratsk_state_technical_university": "Bratsk State Technical University"
        },
        {
            "state_university_of_maritime_and_river_fleet_admiral_s_o_makarov": "State University of Maritime and River Fleet Admiral S O Makarov"
        },
        {
            "astrakhan_state_technical_university": "Astrakhan State Technical University"
        },
        {
            "volgograd_state_pedagogical_university": "Volgograd State Pedagogical University"
        },
        {
            "omsk_state_transport_university": "Omsk State Transport University"
        },
        {
            "byisk_technological_institute": "Byisk Technological Institute"
        },
        {
            "institute_of_economics_and_management_in_industry": "Institute of Economics and Management in Industry"
        },
        {
            "yaroslavl_state_technical_university": "Yaroslavl State Technical University"
        },
        {
            "saint_petersburg_state_university_of_technology_and_design": "Saint Petersburg State University of Technology and Design"
        },
        {
            "institute_of_humanities_and_information_technology_superior_moscow": "Institute of Humanities and Information Technology Superior Moscow"
        },
        {
            "academy_of_state_fire_prevention_service_ministry_of_internal_affairs_of_russia": "Academy of State Fire Prevention Service Ministry of Internal Affairs of Russia"
        },
        {
            "siberian_state_transport_university": "Siberian State Transport University"
        },
        {
            "stavropol_state_medical_university": "Stavropol State Medical University"
        },
        {
            "samara_state_university_of_railway_transport": "Samara State University of Railway Transport"
        },
        {
            "volgograd_state_university_of_architecture_and_civil_engineering": "Volgograd State University of Architecture and Civil Engineering"
        },
        {
            "volgograd_institute_of_business": "Volgograd Institute of Business"
        },
        {
            "yaroslavl_state_pedagogical_university": "Yaroslavl State Pedagogical University"
        },
        {
            "omsk_state_agricultural_university_p_a_stolypin": "Omsk State Agricultural University P A Stolypin"
        },
        {
            "capital_financial_and_humanities_academy": "Capital Financial and Humanities Academy"
        },
        {
            "moscow_state_conservatory_tchaikovsky": "Moscow State Conservatory Tchaikovsky"
        },
        {
            "moscow_center_for_continuous_mathematical_education_independent_university_of_moscow": "Moscow Center for Continuous Mathematical Education Independent University of Moscow"
        },
        {
            "southern_russian_state_technical_university_shakhtinsk_institute": "Southern Russian State Technical University Shakhtinsk Institute"
        },
        {
            "all_russian_academy_for_foreign_trade": "All Russian Academy for Foreign Trade"
        },
        {
            "north_ossetian_university": "North Ossetian University"
        },
        {
            "volga_university_vn_tatishchev": "Volga University VN Tatishchev"
        },
        {
            "international_law_institute_ministry_of_justice_of_the_russian_federation": "International Law Institute Ministry of Justice of the Russian Federation"
        },
        {
            "voronezh_state_technological_academy": "Voronezh State Technological Academy"
        },
        {
            "russian_timiryazev_state_agrarian_university": "Russian Timiryazev State Agrarian University"
        },
        {
            "omsk_state_medical_academy": "Omsk State Medical Academy"
        },
        {
            "saint_petersburg_state_university_of_physical_training": "Saint Petersburg State University of Physical Training"
        },
        {
            "unesco_institute_for_information_technologies_in_education": "UNESCO Institute for Information Technologies in Education"
        },
        {
            "european_university_saint_petersburg": "European University Saint Petersburg"
        },
        {
            "institute_of_friendship_of_peoples_of_the_caucasus": "Institute of Friendship of Peoples of the Caucasus"
        },
        {
            "gnessin_state_musical_college": "Gnessin State Musical College"
        },
        {
            "saint_tikhon's_orthodox_university_for_the_humanities": "Saint Tikhon's Orthodox University for the Humanities"
        },
        {
            "don_state_university_of_agriculture": "Don State University of Agriculture"
        },
        {
            "ukhta_state_technical_university": "Ukhta State Technical University"
        },
        {
            "moscow_state_linguistic_university": "Moscow State Linguistic University"
        },
        {
            "biysk_state_padagogical_university_vm_shukshin": "Biysk State Padagogical University VM Shukshin"
        },
        {
            "volgograd_state_academy_of_physical_training": "Volgograd State Academy of Physical Training"
        },
        {
            "russian_academy_of_entrepreneurship": "Russian Academy of Entrepreneurship"
        },
        {
            "gorno_altaisk_state_university": "Gorno Altaisk State University"
        },
        {
            "moscow_state_pedagogical_institute_me_evseveva": "Moscow State Pedagogical Institute ME Evseveva"
        },
        {
            "magnitogorsk_state_conservatory_m_i_glinka": "Magnitogorsk State Conservatory M I Glinka"
        },
        {
            "gnessin_state_musical_college_moscow": "Gnessin State Musical College Moscow"
        },
        {
            "moscow_school_of_social_and_economical_sciences": "Moscow School of Social and Economical Sciences"
        },
        {
            "moscow_state_automobile_and_road_institute": "Moscow State Automobile and Road Institute"
        },
        {
            "ryazan_state_university": "Ryazan state university"
        },
        {
            "russian_academy_of_arts": "Russian Academy of Arts"
        },
        {
            "russian_university_of_cooperation": "Russian University of Cooperation"
        },
        {
            "yakutsk_state_agricultural_academy": "Yakutsk State Agricultural Academy"
        },
        {
            "russian_state_university_of_cinematography_sa_gerasimov": "Russian State University of Cinematography SA Gerasimov"
        },
        {
            "crimean_engineering_and_pedagogical_university_fevzi_yakubov": "Crimean Engineering and Pedagogical University Fevzi Yakubov"
        },
        {
            "moscow_state_academy_of_law": "Moscow State Academy of Law"
        },
        {
            "synergy_university": "Synergy University"
        },
        {
            "gaidar_institute_for_economic_policy_(institute_for_the_economy_in_transition)": "Gaidar Institute for Economic Policy (Institute for the Economy in Transition)"
        },
        {
            "modern_university_for_the_humanities": "Modern University for the Humanities"
        },
        {
            "siberian_state_university_of_geosystems_and_technologies": "Siberian State University of Geosystems and Technologies"
        },
        {
            "nizhny_novgorod_state_university_of_architecture_and_civil_engineering": "Nizhny Novgorod State University of Architecture and Civil Engineering"
        },
        {
            "ulyanovsk_state_agricultural_academy_pa_stolypin": "Ulyanovsk State Agricultural Academy PA Stolypin"
        },
        {
            "moscow_state_academy_of_veterinary_medicine_and_biotechnology": "Moscow State Academy of Veterinary Medicine and Biotechnology"
        },
        {
            "lipetsk_state_pedagogical_university": "Lipetsk State Pedagogical University"
        },
        {
            "saint_petersburg_conservatory_rymsky_korsakov": "Saint Petersburg Conservatory Rymsky Korsakov"
        },
        {
            "saint_petersburg_university_of_humanities_and_social_sciences": "Saint Petersburg University of Humanities and Social Sciences"
        },
        {
            "moscow_architectural_institute": "Moscow Architectural Institute"
        },
        {
            "kursk_academy_of_state_and_municipal_services": "Kursk Academy of State and Municipal Services"
        },
        {
            "admiral_nevelsky_state_maritime_university": "Admiral Nevelsky State Maritime University"
        },
        {
            "moscow_orthodox_theological_academy": "Moscow Orthodox Theological Academy"
        },
        {
            "baltic_state_technical_university": "Baltic State Technical University"
        },
        {
            "academy_of_social_control": "Academy of Social Control"
        },
        {
            "institute_for_advanced_studies_of_the_investigative_committee_\u00f0\u00bef_the_russian_federation": "Institute for Advanced Studies of the Investigative Committee \u00d0\u00bef the Russian Federation"
        },
        {
            "bashkir_state_pedagogical_university_m_akmully": "Bashkir State Pedagogical University M Akmully"
        },
        {
            "tver_state_medical_university": "Tver State Medical University"
        },
        {
            "moscow_financial_and_law_university": "Moscow Financial and Law University"
        },
        {
            "rostov_branch_of_moscow_state_technical_university_of_civil_aviation": "Rostov Branch of Moscow State Technical University of Civil Aviation"
        },
        {
            "irkutsk_branch_of_the_russian_state_university_of_physical_culture,_sport_and_tourism_youth": "Irkutsk Branch of the Russian State University of Physical Culture, Sport and Tourism Youth"
        },
        {
            "amur_state_university_sholom_aleichem": "Amur State University Sholom Aleichem"
        },
        {
            "kostroma_state_university_n_a_nekrasov": "Kostroma State University N A Nekrasov"
        },
        {
            "voronezh_state_agrarian_university": "Voronezh State Agrarian University"
        },
        {
            "adygei_state_university": "Adygei State University"
        },
        {
            "ural_state_academy_of_mining_&_geology": "Ural State Academy of Mining & Geology"
        },
        {
            "altai_state_pedagogical_university": "Altai State Pedagogical University"
        },
        {
            "far_east_state_medical_university": "Far East State Medical University"
        },
        {
            "interregional_open_social_institute": "Interregional Open Social Institute"
        },
        {
            "russian_university_of_theatre_arts": "Russian University of Theatre Arts"
        },
        {
            "state_university_of_land_management": "State University of Land Management"
        },
        {
            "gorky_literature_institute": "Gorky Literature Institute"
        },
        {
            "amur_state_university_of_humanities_and_pedagogy_(komsomolsk-on-amur_state_pedagogical_university)": "Amur State University of Humanities and Pedagogy (Komsomolsk-on-Amur State Pedagogical University)"
        },
        {
            "moscow_international_higher_business_school_mirbis": "Moscow International Higher Business School MIRBIS"
        },
        {
            "irkutsk_state_railway_transport_engineering_university": "Irkutsk State Railway Transport Engineering University"
        },
        {
            "branch_russian_state_university_of_tourism_and_service_in_samara": "Branch Russian State University of Tourism and Service in Samara"
        },
        {
            "russian_christian_humanities_academy": "Russian Christian Humanities Academy"
        },
        {
            "kemerovo_state_university_anzhero-sudzhensk_branch": "Kemerovo State University Anzhero-Sudzhensk Branch"
        },
        {
            "saint_petersburg_state_university_of_film_and_television": "Saint Petersburg State University of Film and Television"
        },
        {
            "international_institute_of_economics_and_law": "International Institute of Economics and Law"
        },
        {
            "perm_state_pharmaceutical_academy": "Perm State Pharmaceutical Academy"
        },
        {
            "saint_petersburg_state_agrarian_university": "Saint Petersburg State Agrarian University"
        },
        {
            "astrakhan_state_medical_academy": "Astrakhan State Medical Academy"
        },
        {
            "russian_university_of_justice": "Russian University of Justice"
        },
        {
            "siberian_state_automobile_and_road_academy": "Siberian State Automobile and Road Academy"
        },
        {
            "practical_psychology_education_institute_imaton": "Practical psychology Education Institute Imaton"
        },
        {
            "novosibirsk_state_university_of_architecture_design_and_arts": "Novosibirsk State University of Architecture Design and Arts"
        },
        {
            "moscow_state_university_witte_(moscow_institute_of_economics,_management_and_law)": "Moscow State University Witte (Moscow Institute of Economics, Management and Law)"
        },
        {
            "ural_state_forest_engineering_university": "Ural State Forest Engineering University"
        },
        {
            "ural_state_academy_of_transport": "Ural State Academy of Transport"
        },
        {
            "russian_legal_academy_of_the_ministry_of_justice": "Russian Legal Academy of the Ministry of Justice"
        },
        {
            "russian_academy_of_business_and_entrepreneurship": "Russian Academy of Business and Entrepreneurship"
        },
        {
            "krasnoyarsk_state_medical_university": "Krasnoyarsk State Medical University"
        },
        {
            "ryazan_state_medical_university_i_p_pavlov": "Ryazan State Medical University I P Pavlov"
        },
        {
            "saint_filaret_orthodox_christian_institute": "Saint Filaret Orthodox Christian Institute"
        },
        {
            "diplomatic_academy_of_ministry_of_foreign_affairs_russian_federation": "Diplomatic Academy of Ministry of Foreign Affairs Russian Federation"
        },
        {
            "bryansk_state_engineering_university_of_technology": "Bryansk State Engineering University of Technology"
        },
        {
            "bashkir_academy_of_public_administration_and_management": "Bashkir Academy of Public Administration and Management"
        },
        {
            "moscow_metropolitan_governance_university": "Moscow Metropolitan Governance University"
        },
        {
            "ural_state_academy_of_architecture_and_art": "Ural State Academy of Architecture and Art"
        },
        {
            "saint_tikhon's_orthodox_university_department_of_computer_science": "Saint Tikhon's Orthodox University Department of Computer Science"
        },
        {
            "moscow_institute_of_psychoanalysis": "Moscow Institute of Psychoanalysis"
        },
        {
            "linguistic_university_of_nizhny_novgorod": "Linguistic University of Nizhny Novgorod"
        },
        {
            "world_technological_university_moscow_technological_institute": "World Technological University Moscow Technological Institute"
        },
        {
            "bryansk_state_agricultural_academy": "Bryansk State Agricultural Academy"
        },
        {
            "michurinsk_state_agrarian_university": "Michurinsk State Agrarian University"
        },
        {
            "chuvash_state_pedagogical_university": "Chuvash State Pedagogical University"
        },
        {
            "saint_petersburg_university_of_the_state_fire_service_of_the_ministry_of_the_russian_federation_for_civil_defense": "Saint Petersburg University of the State Fire Service of the Ministry of the Russian Federation for Civil Defense"
        },
        {
            "russian_state_university_of_tourism_and_service_(moscow_university_of_service)": "Russian State University of Tourism and Service (Moscow University of Service)"
        },
        {
            "branch_of_the_national_research_university_in_volzhsky": "Branch of the National Research University in Volzhsky"
        },
        {
            "murmansk_arctic_state_university": "Murmansk Arctic State University"
        },
        {
            "st_petersburg_state_university_of_management_and_economics_spbume": "St Petersburg State University of Management and Economics SPbUME"
        },
        {
            "russian_state_academy_of_intellectual_property": "Russian State Academy of Intellectual Property"
        },
        {
            "saint_petersburg_academy_of_postgraduate_pedagogical_education": "Saint Petersburg Academy of Postgraduate Pedagogical Education"
        },
        {
            "admiral_ushakov_state_maritime_academy": "Admiral Ushakov State Maritime Academy"
        },
        {
            "moscow_state_institute_of_electronics_and_mathematics": "Moscow State Institute of Electronics and Mathematics"
        },
        {
            "kaluga_state_pedagogical_university": "Kaluga State Pedagogical University"
        },
        {
            "international_university_moscow": "International University Moscow"
        },
        {
            "sakhalin_state_university": "Sakhalin State University"
        },
        {
            "blagoveshchensk_state_pedagogical_university": "Blagoveshchensk State Pedagogical University"
        },
        {
            "saint_petersburg_state_chemical_pharmaceutical_university": "Saint Petersburg State Chemical Pharmaceutical University"
        },
        {
            "nizhnevartovsk_state_university": "Nizhnevartovsk State University"
        },
        {
            "yelets_state_university": "Yelets State University"
        },
        {
            "international_academy_of_business_and_new_technologies": "International Academy of Business and New Technologies"
        },
        {
            "perm_state_agricultural_academy": "Perm State Agricultural Academy"
        },
        {
            "north_caucasus_state_academy": "North Caucasus State Academy"
        },
        {
            "orenburg_state_pedagogical_university": "Orenburg State Pedagogical University"
        },
        {
            "university_of_management_tisbi": "University of Management TISBI"
        },
        {
            "chelyabinsk_state_medical_academy": "Chelyabinsk State Medical Academy"
        },
        {
            "altai_state_agrarian_university": "Altai State Agrarian University"
        },
        {
            "institute_unic": "Institute Unic"
        },
        {
            "public_law_institute": "Public Law Institute"
        },
        {
            "siberian_university_of_consumer_cooperatives": "Siberian University of Consumer Cooperatives"
        },
        {
            "volga_region_state_university_of_service": "Volga Region State University of Service"
        },
        {
            "state_university_of_human_sciences": "State University of Human Sciences"
        },
        {
            "business_school_sinerghia": "Business School Sinerghia"
        },
        {
            "russian_state_open_agrarian_university": "Russian State Open Agrarian University"
        },
        {
            "smolensk_state_university": "Smolensk State University"
        },
        {
            "ulianovsk_higher_school_of_civil_aviation": "Ulianovsk Higher School of Civil Aviation"
        },
        {
            "siberian_fire_and_rescue_academy_branch_saint_petersburg_university_of_emercom_russia": "Siberian Fire and Rescue Academy Branch Saint Petersburg University of EMERCOM Russia"
        },
        {
            "institute_of_education_of_the_irkutsk_region": "Institute of Education of the Irkutsk region"
        },
        {
            "baganova_ballet_academy": "Baganova Ballet Academy"
        },
        {
            "dalrybvtuz_far_eastern_state_technical_university_of_fisheries": "Dalrybvtuz Far Eastern State Technical University of Fisheries"
        },
        {
            "civil_defence_academy_ministry_of_the_russian_federation_for_civil_defense": "Civil Defence Academy Ministry of the Russian Federation for Civil Defense"
        },
        {
            "bryansk_state_university_academician_i_g_petrovskii": "Bryansk State University Academician I G Petrovskii"
        },
        {
            "kazan_state_university_of_culture_and_arts": "Kazan State University of Culture and Arts"
        },
        {
            "voronezh_state_medical_university_n_n_burdenko": "Voronezh State Medical University N N Burdenko"
        },
        {
            "humanities_institute_of_tv_&_radio_broadcasting_m_a_litovchin": "Humanities Institute of TV & Radio Broadcasting M A Litovchin"
        },
        {
            "saint_petersburg_state_academic_institute_of_painting_sculpture_and_architecture_ilya_repin": "Saint Petersburg State Academic Institute of Painting Sculpture and Architecture Ilya Repin"
        },
        {
            "saint_petersburg_state_academy_of_art_and_design_al_stieglitz": "Saint Petersburg State Academy of Art and Design AL Stieglitz"
        },
        {
            "saint_petersburg_university_of_the_ministry_of_internal_affairs_of_the_russian_federation": "Saint Petersburg University of the Ministry of Internal Affairs of the Russian Federation"
        },
        {
            "moscow_state_university_of_culture_and_the_arts": "Moscow State University of Culture and the Arts"
        },
        {
            "penza_state_technological_university": "Penza State Technological University"
        },
        {
            "obninsk_state_technical_university_for_nuclear_power_engineering": "Obninsk State Technical University for Nuclear Power Engineering"
        },
        {
            "biblical_theological_institute_of_saint_andrew_the_apostle": "Biblical Theological Institute of Saint Andrew the Apostle"
        },
        {
            "voronezh_high_technology_institute": "Voronezh High Technology Institute"
        },
        {
            "voronezh_economics_and_law_institute": "Voronezh Economics and Law Institute"
        },
        {
            "shadrinsk_state_pedagogical_institute": "Shadrinsk State Pedagogical Institute"
        },
        {
            "russian_international_academy_of_tourism": "Russian International Academy of Tourism"
        },
        {
            "irkutsk_state_agricultural_academy": "Irkutsk State Agricultural Academy"
        },
        {
            "tyva_state_university": "Tyva State University"
        },
        {
            "vyatka_state_humanities_university": "Vyatka State Humanities University"
        },
        {
            "daghestan_state_technical_university": "Daghestan State Technical University"
        },
        {
            "armavir_state_pedagogical_university": "Armavir State Pedagogical University"
        },
        {
            "far_east_state_agrarian_university": "Far East State Agrarian University"
        },
        {
            "tyumen_state_medical_academy": "Tyumen State Medical Academy"
        },
        {
            "busines_career_institute": "Busines Career Institute"
        },
        {
            "seversk_state_technological_institute": "Seversk State Technological Institute"
        },
        {
            "east_european_institute_of_psychoanalysis": "East European Institute of Psychoanalysis"
        },
        {
            "orel_state_agrarian_university": "Orel State Agrarian University"
        },
        {
            "moscow_state_humanities-economic_institute": "Moscow State Humanities-Economic Institute"
        },
        {
            "siberian_state_university_of_physical_culture_and_sports": "Siberian State University of Physical Culture and Sports"
        },
        {
            "sukachev_institute_of_forest": "Sukachev Institute of Forest"
        },
        {
            "moscow_open_institute": "Moscow Open Institute"
        },
        {
            "russian_state_university_of_economics_plekhanov_irkutsk_branch": "Russian State University of Economics Plekhanov Irkutsk Branch"
        },
        {
            "saratov_state_conservatory": "Saratov State Conservatory"
        },
        {
            "ivanovo_state_medical_academy": "Ivanovo State Medical Academy"
        },
        {
            "military_university_of_russian_ministry_of_defence": "Military University of Russian Ministry of Defence"
        },
        {
            "tyumen_state_agricultural_academy": "Tyumen State Agricultural Academy"
        },
        {
            "voronezh_state_forest_technical_university": "Voronezh State Forest Technical University"
        },
        {
            "academy_of_the_general_prosecutor's_office": "Academy of the General Prosecutor's Office"
        },
        {
            "moscow_state_regional_institute_for_the_humanities": "Moscow State Regional Institute for the Humanities"
        },
        {
            "samara_state_agricultural_academy": "Samara State Agricultural Academy"
        },
        {
            "belgorod_university_of_consumer_cooperatives": "Belgorod University of Consumer Cooperatives"
        },
        {
            "kirov_state_medical_academy": "Kirov State Medical Academy"
        },
        {
            "vologda_state_dairy_academy": "Vologda State Dairy Academy"
        },
        {
            "ural_state_agricultural_academy": "Ural State Agricultural Academy"
        },
        {
            "perm_state_institute_of_art_and_culture": "Perm State Institute of Art and Culture"
        },
        {
            "moscow_state_academic_institute_of_arts_vi_surikov": "Moscow State Academic Institute of Arts VI Surikov"
        },
        {
            "saint_petersburg_academy_of_civil_aviation": "Saint Petersburg Academy of Civil Aviation"
        },
        {
            "rostov_state_conservatory_s_v_rachmaninov": "Rostov State Conservatory S V Rachmaninov"
        },
        {
            "altai_state_medical_university": "Altai State Medical University"
        },
        {
            "moscow_state_institute_of_music_a_g_shnitke": "Moscow State Institute of Music A G Shnitke"
        },
        {
            "ryazan_state_agrotechnological_university_p_a_kostychev": "Ryazan State Agrotechnological University P A Kostychev"
        },
        {
            "institute_of_world_economy_and_information_imei": "Institute of World Economy and Information IMEI"
        },
        {
            "siberian_state_academy_of_water_transport": "Siberian State Academy of Water Transport"
        },
        {
            "saint_petersburg_state_academy_of_veterinary_medicine": "Saint Petersburg State Academy of Veterinary Medicine"
        },
        {
            "volgograd_state_academy_of_agriculture": "Volgograd State Academy of Agriculture"
        },
        {
            "perm_institute_of_economics_and_finance": "Perm Institute of Economics and Finance"
        },
        {
            "izhevsk_state_agricultural_academy": "Izhevsk State Agricultural Academy"
        },
        {
            "university_of_technology_unitech": "University of Technology UNITECH"
        },
        {
            "institute_of_international_trade_&_law": "Institute of International Trade & Law"
        },
        {
            "nizhny_novgorod_state_conservatory_m_i_glinka": "Nizhny Novgorod State Conservatory M I Glinka"
        },
        {
            "kemerovo_agricultural_institute": "Kemerovo Agricultural Institute"
        },
        {
            "south_russian_institute_russian_presidential_academy_of_national_economy_and_public_administration": "South Russian Institute Russian Presidential Academy of National Economy and Public Administration"
        },
        {
            "saint_petersburg_state_institute_of_psychology_and_social_work": "Saint Petersburg State Institute of Psychology and Social Work"
        },
        {
            "chechen_state_university": "Chechen State University"
        },
        {
            "primorskaya_state_academy_of_agriculture": "Primorskaya State Academy of Agriculture"
        },
        {
            "komi_state_pedagogical_university": "Komi State Pedagogical University"
        },
        {
            "moscow_state_academy_of_physical_culture": "Moscow State Academy of Physical Culture"
        },
        {
            "russian_state_institute_of_performing_arts": "Russian State Institute of Performing Arts"
        },
        {
            "saint_petersburg_state_technological_university_of_plant_polymers": "Saint Petersburg State Technological University of Plant Polymers"
        },
        {
            "nizhny_tagil_state_social_pedagogical_academy": "Nizhny Tagil State Social Pedagogical Academy"
        },
        {
            "chita_state_university_of_medicine": "Chita State University of Medicine"
        },
        {
            "moscow_academy_of_economics_and_law": "Moscow Academy of Economics and Law"
        },
        {
            "rvvdku_ryazan_landing_school_vf_margelov": "RVVDKU Ryazan landing School VF Margelov"
        },
        {
            "glazov_state_pedagogical_institute_vg_korolenko": "Glazov State Pedagogical Institute VG Korolenko"
        },
        {
            "kamchatka_state_technical_university": "Kamchatka State Technical University"
        },
        {
            "moscow_institute_of_economics_mei": "Moscow Institute of Economics MEI"
        },
        {
            "kamchatska_state_university_vitus_bering": "Kamchatska State University Vitus Bering"
        },
        {
            "volgograd_state_technical_university_kamyshin_technological_institute": "Volgograd State Technical University Kamyshin Technological Institute"
        },
        {
            "stavropol_state_pedagogical_institute": "Stavropol State Pedagogical Institute"
        },
        {
            "bashkir_state_university_sterlitamak_branch": "Bashkir State University Sterlitamak Branch"
        },
        {
            "academy_of_choral_art": "Academy of Choral Art"
        },
        {
            "novosibirsk_state_conservatory_m_i_glinka": "Novosibirsk State Conservatory M I Glinka"
        },
        {
            "kovrov_state_technological_academy_v_a_degtyarev": "Kovrov State Technological Academy V A Degtyarev"
        },
        {
            "south_ural_state_institute_of_arts_pi_tchaikovsky": "South Ural State Institute of Arts PI Tchaikovsky"
        },
        {
            "institute_of_practical_psychology_and_psychoanalysis": "Institute of Practical Psychology and Psychoanalysis"
        },
        {
            "pacific_state_medical_university": "Pacific State Medical University"
        },
        {
            "kalmic_state_university": "Kalmic State University"
        },
        {
            "national_open_institute_of_russia": "National Open Institute of Russia"
        },
        {
            "samara_university_of_humanities": "Samara University of Humanities"
        },
        {
            "higher_theatre_institute_shchepkin": "Higher Theatre Institute Shchepkin"
        },
        {
            "moscow_institute_of_humanities_and_social_sciences": "Moscow Institute of Humanities and Social Sciences"
        },
        {
            "south_ural_institute_of_management_and_economics_chelyabinsk": "South Ural Institute of Management and Economics Chelyabinsk"
        },
        {
            "institute_of_special_pedagogy_and_psychology": "Institute of Special Pedagogy and Psychology"
        },
        {
            "east_siberian_state_academy_of_culture_and_the_arts": "East Siberian State Academy of Culture and the Arts"
        },
        {
            "air_force_academy_prof_n_e_zhukovsky_and_yuri_gagarin": "Air Force Academy Prof N E Zhukovsky and Yuri Gagarin"
        },
        {
            "samara_medical_institute_reaviz": "Samara Medical Institute REAVIZ"
        },
        {
            "folk_arts_state_higher_school_(institute)": "Folk Arts State Higher School (Institute)"
        },
        {
            "almetyevsk_state_petroleum_institute": "Almetyevsk State Petroleum Institute"
        },
        {
            "irkutsk_state_university_law_institute": "Irkutsk State University Law Institute"
        },
        {
            "musical_pedagogical_institute_m_m_ippolitov-ivanov": "Musical Pedagogical Institute M M Ippolitov-Ivanov"
        },
        {
            "naberezhnye_chelny_institute_of_social_and_educational_technology_and_resources": "Naberezhnye Chelny Institute of Social and Educational Technology and Resources"
        },
        {
            "moscow_institute_of_business_and_law": "Moscow Institute of Business and Law"
        },
        {
            "yaroslavl_state_medical_university": "Yaroslavl State Medical University"
        },
        {
            "international_banking_institute": "International Banking Institute"
        },
        {
            "ural_state_conservatory_(academy)_mp_mussorgsky": "Ural State Conservatory (Academy) MP Mussorgsky"
        },
        {
            "yaroslavl_state_academy_of_agriculture": "Yaroslavl State Academy of Agriculture"
        },
        {
            "baikal_institute_of_business_and_international_management": "Baikal Institute of Business and International Management"
        },
        {
            "academy_of_marketing_and_social_information_technologies_krasnodar": "Academy of Marketing and Social Information Technologies Krasnodar"
        },
        {
            "north_caucasian_mining_and_metallurgical_institute_(state_technological_university)": "North Caucasian Mining and Metallurgical Institute (State Technological University)"
        },
        {
            "omsk_academy_of_the_humanities": "Omsk Academy of the Humanities"
        },
        {
            "pushchino_state_natural_science_institute": "Pushchino State Natural Science Institute"
        },
        {
            "kuban_state_university_slavyansk_on_kuban_branch": "Kuban State University Slavyansk on Kuban Branch"
        },
        {
            "institute_for_economy_and_culture": "Institute for Economy and Culture"
        },
        {
            "moscow_institute_for_the_humanities_princess_er_dashkova": "Moscow Institute for the Humanities Princess ER Dashkova"
        },
        {
            "institute_of_east": "Institute of East"
        },
        {
            "economics_and_law_institute": "Economics and Law Institute"
        },
        {
            "sarov_state_physical_and_technical_institute": "Sarov State Physical and Technical Institute"
        },
        {
            "kursk_institute_of_management_economics_and_business": "Kursk Institute of Management Economics and Business"
        },
        {
            "astrakhan_state_conservatory": "Astrakhan State Conservatory"
        },
        {
            "chechen_state_pedagogical_institute": "Chechen State Pedagogical Institute"
        },
        {
            "south_ural_state_agricultural_university": "South Ural State Agricultural University"
        },
        {
            "dagestan_state_university_of_national_economy": "Dagestan State University of National Economy"
        },
        {
            "smolensk_state_medical_university": "Smolensk State Medical University"
        },
        {
            "kerch_state_maritime_technological_university": "Kerch State Maritime Technological University"
        },
        {
            "vladikavkaz_institute_of_management": "Vladikavkaz Institute of Management"
        },
        {
            "ingush_state_university": "Ingush State University"
        },
        {
            "saint_petersburg_institute_of_hospitality": "Saint Petersburg Institute of Hospitality"
        },
        {
            "intechna_institute_for_new_technologies_in_education": "Intechna Institute for New Technologies in Education"
        },
        {
            "nizhny_novgorod_state_university_of_engineering_and_economics_kniagininsky_university": "Nizhny Novgorod State University of Engineering and Economics Kniagininsky University"
        },
        {
            "institute_of_foreign_languages": "Institute of Foreign Languages"
        },
        {
            "art_theatre_school": "Art Theatre School"
        },
        {
            "institute_of_tourism_and_hospitality": "Institute of Tourism and Hospitality"
        },
        {
            "russian_islamic_institute": "Russian Islamic Institute"
        },
        {
            "institute_of_civilization": "Institute of Civilization"
        },
        {
            "institute_of_psychology_in_moscow_paracelsus": "Institute of Psychology in Moscow Paracelsus"
        },
        {
            "moscow_humanities_technical_academy": "Moscow Humanities Technical Academy"
        },
        {
            "russian_state_university_of_economics_plekhanov_krasnodar_branch": "Russian State University of Economics Plekhanov Krasnodar Branch"
        },
        {
            "russian_orthodox_university": "Russian Orthodox University"
        },
        {
            "vyatka_social_and_economic_institute": "Vyatka Social and Economic Institute"
        },
        {
            "smolensk_state_academy_of_agriculture": "Smolensk State Academy of Agriculture"
        },
        {
            "saint_petersburg_military_institute_of_internal_troops_ministry_of_internal_affairs_russian_federation": "Saint Petersburg Military Institute of Internal Troops Ministry of Internal Affairs Russian Federation"
        },
        {
            "russian_state_university_of_physical_culture_sport_youth_and_tourism": "Russian State University of Physical Culture Sport Youth and Tourism"
        },
        {
            "international_management_institute": "International Management Institute"
        },
        {
            "kurgan_state_agricultural_academy_t_s_maltsev": "Kurgan State Agricultural Academy T S Maltsev"
        },
        {
            "institute_of_engineering_physics": "Institute of Engineering Physics"
        },
        {
            "saint_petersburg_chemical_pharmaceutical_academy": "Saint Petersburg Chemical Pharmaceutical Academy"
        },
        {
            "moscow_engineering_physics_institute_ozersk_branch": "Moscow Engineering Physics Institute Ozersk Branch"
        },
        {
            "kabardino_balkar_state_agricultural_academy_vm_kokov": "Kabardino Balkar State Agricultural Academy VM Kokov"
        },
        {
            "chelyabinsk_law_institute_ministry_of_internal_affairs_of_the_russian_federation": "Chelyabinsk Law Institute Ministry of Internal Affairs of the Russian Federation"
        },
        {
            "international_university_of_fundamental_studies_saint_petersburg": "International University of Fundamental Studies Saint Petersburg"
        },
        {
            "omsk_institute_of_economics": "Omsk Institute of Economics"
        },
        {
            "kuban_social_and_economic_institute": "Kuban Social and Economic Institute"
        },
        {
            "russian_customs_academy_rostov_branch": "Russian Customs Academy Rostov Branch"
        },
        {
            "kemerovo_state_medical_university": "Kemerovo State Medical University"
        },
        {
            "sergo_ordzhonikidze_russian_state_university_for_geological_prospecting_mgri": "Sergo Ordzhonikidze Russian State University for Geological Prospecting MGRI"
        },
        {
            "institute_of_communication_technologies": "Institute of Communication Technologies"
        },
        {
            "military_space_academy_a_f_mozhaisky": "Military Space Academy A F Mozhaisky"
        },
        {
            "russian_medical_military_academy": "Russian Medical Military Academy"
        },
        {
            "krasnodar_state_university_of_culture_and_the_arts": "Krasnodar State University of Culture and the Arts"
        },
        {
            "north_caucasus_branch_of_moscow_technical_university_of_communications_and_informatics": "North Caucasus Branch of Moscow Technical University of Communications and Informatics"
        },
        {
            "institute_of_management_and_law": "Institute of Management and Law"
        },
        {
            "kemerovo_state_university_(branch)_novokuznetsk_institute": "Kemerovo State University (Branch) Novokuznetsk Institute"
        },
        {
            "nizhny_novgorod_commercial_institute": "Nizhny Novgorod Commercial Institute"
        },
        {
            "tver_state_agricultural_academy": "Tver State Agricultural Academy"
        },
        {
            "dagestan_state_medical_university": "Dagestan State Medical University"
        },
        {
            "siberian_institute_of_international_relations_and_area_studies": "Siberian Institute of International Relations and Area Studies"
        },
        {
            "tchaikovsky_state_institute_of_physical_culture": "Tchaikovsky State Institute of Physical Culture"
        },
        {
            "volgograd_institute_of_arts_p_a_serebryakov": "Volgograd Institute of Arts P A Serebryakov"
        },
        {
            "baltic_university_of_ecology_politics_and_law": "Baltic University of Ecology Politics and Law"
        },
        {
            "ural_institute_of_securities_market": "Ural Institute of Securities Market"
        },
        {
            "institute_of_journalism_and_creative_writing": "Institute of Journalism and Creative Writing"
        },
        {
            "russian_state_academy_of_arts_specialized": "Russian State Academy of Arts Specialized"
        },
        {
            "institute_of_european_cultures": "Institute of European Cultures"
        },
        {
            "ekaterinburg_academy_of_contemporary_art_(institute)": "Ekaterinburg Academy of Contemporary Art (Institute)"
        },
        {
            "moscow_regional_social_and_economic_institute": "Moscow Regional Social and Economic Institute"
        },
        {
            "voronezh_state_institute_of_physical_culture": "Voronezh State Institute of Physical Culture"
        },
        {
            "caspian_institute_of_maritime_and_river_transport_branch_of_volga_state_academy_of_water_transport": "Caspian Institute of Maritime and River Transport Branch of Volga State Academy of Water Transport"
        },
        {
            "institute_of_international_relations": "Institute of International Relations"
        },
        {
            "law_institute_st_petersburg": "Law Institute St Petersburg"
        },
        {
            "penza_state_pedagogical_university_vg_belinsky": "Penza State Pedagogical University VG Belinsky"
        },
        {
            "moscow_state_university_of_sport_and_tourism": "Moscow State University of Sport and Tourism"
        },
        {
            "chuvash_state_institute_of_culture_and_the_arts": "Chuvash State Institute of Culture and the Arts"
        },
        {
            "tyumen_state_academy_of_culture_arts_and_social_technologies": "Tyumen State Academy of Culture Arts and Social Technologies"
        },
        {
            "north_caucasian_institute_for_social": "North Caucasian Institute for Social"
        },
        {
            "chita_institute_baikal_state_university_of_economics_and_law": "Chita Institute Baikal State University of Economics and Law"
        },
        {
            "bashkir_institute_of_physical_culture": "Bashkir Institute of Physical Culture"
        },
        {
            "luki_state_academy_of_physical_culture_and_sports": "Luki State Academy of Physical Culture and Sports"
        },
        {
            "moscow_social_and_economic_institute": "Moscow Social and Economic Institute"
        },
        {
            "tyumen_state_institute_of_culture": "Tyumen State Institute of Culture"
        },
        {
            "khanty_mansiysk_state_medical_academy": "Khanty Mansiysk State Medical Academy"
        },
        {
            "branch_of_ufa_state_aviation_technical_university_in_ishimbai": "Branch of Ufa State Aviation Technical University in Ishimbai"
        },
        {
            "southern_russian_institute_for_the_humanities": "Southern Russian Institute for the Humanities"
        },
        {
            "grozny_state_petroleum_technical_university_m_d_millionshchikov": "Grozny State Petroleum Technical University M D Millionshchikov"
        },
        {
            "siberian_institute_of_business_and_information_technology": "Siberian Institute of Business and Information Technology"
        },
        {
            "russian_customs_academy_saint_petersburg_branch": "Russian Customs Academy Saint Petersburg Branch"
        },
        {
            "northwest_institute_(branch)_moscow_state_university_of_law_o_e_kutafin": "Northwest Institute (Branch) Moscow State University of Law O E Kutafin"
        },
        {
            "far_eastern_state_academy": "Far Eastern State Academy"
        },
        {
            "arkhangelsk_pedagogical_college": "Arkhangelsk Pedagogical College"
        },
        {
            "murmansk_academy_of_economics_and_management": "Murmansk Academy of Economics and Management"
        },
        {
            "moscow_islamic_university": "Moscow Islamic University"
        },
        {
            "moscow_university_of_information_technology_moscow_institute_of_architecture_and_engineering": "Moscow University of Information Technology Moscow Institute of Architecture and Engineering"
        },
        {
            "baltic_institute_of_humanities": "Baltic Institute of Humanities"
        },
        {
            "moscow_international_academy": "Moscow International Academy"
        },
        {
            "dubna_international_university_for_nature_society_and_man_ugresha_branch": "Dubna International University for Nature Society and Man Ugresha Branch"
        },
        {
            "saint_petersburg_institute_for_economics_and_management": "Saint Petersburg Institute for Economics and Management"
        },
        {
            "moscow_state_university_of_technology_and_management_c_g_razumovsky_in_lipetsk": "Moscow State University of Technology and Management C G Razumovsky in Lipetsk"
        },
        {
            "volgograd_academy_of_the_ministry_of_internal_affairs_of_the_russian_federation": "Volgograd Academy of the Ministry of Internal Affairs of the Russian Federation"
        },
        {
            "volga_institute_of_economics_pedagogy_and_law": "Volga Institute of Economics Pedagogy and Law"
        },
        {
            "ural_state_university_of_physical_culture_ekaterinburg_branch": "Ural State University of Physical Culture Ekaterinburg Branch"
        },
        {
            "stolichny_humanities_economic_institute": "Stolichny Humanities Economic Institute"
        },
        {
            "institute_of_management_and_market": "Institute of Management and Market"
        },
        {
            "azov_black_sea_engineering_institute_don_state_agrarian_university_in_zernograd": "Azov Black Sea Engineering Institute Don State Agrarian University in Zernograd"
        },
        {
            "novosibirsk_institute_of_law": "Novosibirsk Institute of Law"
        },
        {
            "national_institute_of_business": "National Institute of Business"
        },
        {
            "penza_state_academy_of_agriculture": "Penza State Academy of Agriculture"
        },
        {
            "voronezh_state_institute_of_arts": "Voronezh State Institute of Arts"
        },
        {
            "institute_of_continuing_professional_education_ufa_state_oil_technical_university_in_ufa": "Institute of Continuing Professional Education Ufa State Oil Technical University in Ufa"
        },
        {
            "neftekamsky_branch_of_bashkir_state_university": "Neftekamsky Branch of Bashkir State University"
        },
        {
            "kama_institute_of_humanities_and_engineering_technology": "Kama Institute of Humanities and Engineering Technology"
        },
        {
            "ufa_state_university_of_economics_and_service": "Ufa State University of Economics and Service"
        },
        {
            "military_academy_of_communication": "Military Academy of Communication"
        },
        {
            "state_institute_of_economy_finance_law_and_technology": "State Institute of Economy Finance Law and Technology"
        },
        {
            "institute_for_international_socio-cultural_relations": "Institute for International Socio-cultural Relations"
        },
        {
            "rostov_institute_of_protecting_the_entrepreneur": "Rostov Institute of Protecting the Entrepreneur"
        },
        {
            "regional_open_social_institute_kursk": "Regional Open Social Institute Kursk"
        },
        {
            "international_business_academy": "International Business Academy"
        },
        {
            "chelyabinsk_institute_of_economics_and_law_mv_ladoshina": "Chelyabinsk Institute of Economics and Law MV Ladoshina"
        },
        {
            "kuzbass_state_technical_university_novokuznetsk_branch": "Kuzbass State Technical University Novokuznetsk Branch"
        },
        {
            "international_institute_of_design_and_service": "International Institute of Design and Service"
        },
        {
            "volga-vyatka_institute_(branch)_moscow_state_university_of_law_about_e_kutafin": "Volga-Vyatka Institute (branch) Moscow State University of Law About E Kutafin"
        },
        {
            "novosibirsk_institute_of_humanities": "Novosibirsk Institute of Humanities"
        },
        {
            "egoryevsky_aviation_technical_college_v_p_chkalov_branch_of_moscow_state_technical_university_of_civil_aviation": "Egoryevsky Aviation Technical College V P Chkalov Branch of Moscow State Technical University of Civil Aviation"
        },
        {
            "st_thomas_institute": "St Thomas Institute"
        },
        {
            "volgograd_state_institute_of_arts_and_culture": "Volgograd State Institute of Arts and Culture"
        },
        {
            "military_academy_of_aerospace_defense_g_k_zhukov": "Military Academy of Aerospace Defense G K Zhukov"
        },
        {
            "churapchinsky_state_institute_of_physical_culture_and_sports_i_i_gotovtsev": "Churapchinsky State Institute of Physical Culture and Sports I I Gotovtsev"
        },
        {
            "angarsk_state_technical_university": "Angarsk State Technical University"
        },
        {
            "baikal_institute_of_economics_and_law": "Baikal Institute of Economics and Law"
        },
        {
            "vologda_cooperative_college": "Vologda Cooperative College"
        },
        {
            "dagestan_state_agrarian_university_m_m_dzhambulatov": "Dagestan State Agrarian University M M Dzhambulatov"
        },
        {
            "omsk_academy_of_the_ministry_of_internal_affairs_of_russia": "Omsk Academy of the Ministry of Internal Affairs of Russia"
        },
        {
            "orenburg_state_institute_of_arts_l_&_m_rostropovich": "Orenburg State Institute of Arts L & M Rostropovich"
        },
        {
            "moscow_higher_school_of_economics": "Moscow Higher School of Economics"
        },
        {
            "international_academy_of_ecology,_man_and_nature_protection_sciences": "International Academy of Ecology, Man and Nature Protection Sciences"
        },
        {
            "international_institute_of_computer_technology": "International Institute of Computer Technology"
        },
        {
            "saint_petersburg_humanities_university_of_trade_unions_moscow_branch": "Saint Petersburg Humanities University of Trade Unions Moscow Branch"
        },
        {
            "branch_baikal_state_university_of_economics_and_law_in_ust-ilimsk": "Branch Baikal State University of Economics and Law in Ust-Ilimsk"
        },
        {
            "vladimir_institute_of_business": "Vladimir Institute of Business"
        },
        {
            "academy_of_management_and_production": "Academy of Management and Production"
        },
        {
            "vyborg_branch_of_the_university_of_civil_aviation": "Vyborg Branch of the University of Civil Aviation"
        },
        {
            "tomsk_institute_of_law_and_economics": "Tomsk Institute of Law and Economics"
        },
        {
            "international_institute_of_management_link": "International Institute of Management LINK"
        },
        {
            "voronezh_branch_of_the_moscow_institute_of_economics_and_humanities": "Voronezh Branch of the Moscow Institute of Economics and Humanities"
        },
        {
            "lipetsk_institute_for_cooperation": "Lipetsk Institute for Cooperation"
        },
        {
            "tomsk_institute_of_business": "Tomsk Institute of Business"
        },
        {
            "institute_of_jurisprudence_and_management_of_all_russian_police_association": "Institute of Jurisprudence and Management of all Russian Police Association"
        },
        {
            "barnaul_law_institute_ministry_of_internal_affairs": "Barnaul Law Institute Ministry of Internal Affairs"
        },
        {
            "institute_of_management_and_informatics": "Institute of Management and Informatics"
        },
        {
            "academy_of_management_russian_ministry_of_interior": "Academy of Management Russian Ministry of Interior"
        },
        {
            "altai_state_university_rubtzovsk_industrial_institute": "Altai State University Rubtzovsk Industrial Institute"
        },
        {
            "institute_of_economics_and_management_pyatigorsk": "Institute of Economics and Management Pyatigorsk"
        },
        {
            "new_institute_for_the_humanities": "New Institute for the Humanities"
        },
        {
            "branch_baikal_university_of_economics_and_law_in_bratsk": "Branch Baikal University of Economics and Law in Bratsk"
        },
        {
            "smolensk_state_institute_of_arts": "Smolensk State Institute of Arts"
        },
        {
            "ural_institute_of_international_tourism": "Ural Institute of International Tourism"
        },
        {
            "ufa_law_institute_of_ministry_of_internal_affairs": "Ufa Law Institute of Ministry of Internal Affairs"
        },
        {
            "saint_petersburg_state_university_of_economics_kirov_branch": "Saint Petersburg State University of Economics Kirov Branch"
        },
        {
            "penza_state_university_kuznetsky_institute_of_information_management_and_technology": "Penza State University Kuznetsky Institute of Information Management and Technology"
        },
        {
            "russian_school_of_private_law_ural_branch": "Russian School of Private Law Ural Branch"
        },
        {
            "shahtinskii_branch_of_the_south_russian_humanitarian_institute": "Shahtinskii Branch of the South Russian Humanitarian Institute"
        },
        {
            "volgograd_cooperative_institute": "Volgograd Cooperative Institute"
        },
        {
            "khabarovsk_border_institute_of_the_federal_security_service_of_the_russian_federation": "Khabarovsk Border Institute of the Federal Security Service of the Russian Federation"
        },
        {
            "south_russian_state_technical_university_(novocherkassk_polytechnic_institute)_kamensky_institute": "South Russian State Technical University (Novocherkassk Polytechnic Institute) Kamensky Institute"
        },
        {
            "international_jewish_institute_of_economy_finance_and_law": "International Jewish Institute of Economy Finance and Law"
        },
        {
            "orenburg_state_university_orsk_humanities_and_technology_institute": "Orenburg State University Orsk Humanities and Technology Institute"
        },
        {
            "kolomna_institute_(branch)_ulianov_moscow_state_engineering_university": "Kolomna Institute (Branch) Ulianov Moscow State Engineering University"
        },
        {
            "smolny_institute": "Smolny Institute"
        },
        {
            "east_siberian_institute_of_economics_and_law": "East Siberian Institute of Economics and Law"
        },
        {
            "institute_of_theatrical_arts_pm_yershov": "Institute of Theatrical Arts PM Yershov"
        },
        {
            "frontier_academy_of_federal_security_service_of_the_russian_federation": "Frontier Academy of Federal Security Service of the Russian Federation"
        },
        {
            "omsk_regional_institute": "Omsk Regional Institute"
        },
        {
            "service_institute_and_tourism_don_state_technical_university": "Service Institute and tourism Don State Technical University"
        },
        {
            "kaliningrad_international_business_school": "Kaliningrad International Business School"
        },
        {
            "moscow_institute_of_world_economy_and_international_relations": "Moscow Institute of World Economy and International Relations"
        },
        {
            "technological_institute_of_service": "Technological Institute of Service"
        },
        {
            "kama_institute_of_art_and_design": "Kama Institute of Art and Design"
        },
        {
            "international_management_institute_ivanovo_branch": "International Management Institute Ivanovo Branch"
        },
        {
            "voronezh_institute_of_the_federal_penitentiary_service": "Voronezh Institute of the Federal Penitentiary Service"
        },
        {
            "branch_of_the_don_state_technical_university_in_volgodonsk": "Branch of the Don State Technical University in Volgodonsk"
        },
        {
            "ishim_state_pedagogical_institute_p_p_yershov": "Ishim State Pedagogical Institute P P Yershov"
        },
        {
            "institute_of_international_business": "Institute of International Business"
        },
        {
            "rostov_branch_of_moscow_state_university_of_technology_and_management_cg_razumovsky": "Rostov Branch of Moscow State University of Technology and Management CG Razumovsky"
        },
        {
            "moscow_institute_of_national_and_regional_affairs": "Moscow Institute of National and Regional Affairs"
        },
        {
            "white_sea-onega_branch_of_the_state_university_of_sea_and_river_fleet_of_admiral_so_admiral_makarov_c_o_makarova": "White Sea-Onega Branch of the State University of sea and river fleet of Admiral SO Admiral Makarov C O Makarova"
        },
        {
            "university_of_russian_innovation_in_education": "University of Russian Innovation in Education"
        },
        {
            "tchaikovsky_technological_institute_(branch)_izhevsk_state_technical_university_mikhail_kalashnikov": "Tchaikovsky Technological Institute (Branch) Izhevsk State Technical University Mikhail Kalashnikov"
        },
        {
            "astrakhan_branch_of_the_moscow_state_university_of_economics,_statistics_and_informatics": "Astrakhan Branch of the Moscow State University of Economics, Statistics and Informatics"
        },
        {
            "institute_of_languages_\u00e2\u20ac\u2039\u00e2\u20ac\u2039and_cultures": "Institute of Languages \u00e2\u20ac\u2039\u00e2\u20ac\u2039and Cultures"
        },
        {
            "kuban_institute_of_vocational_education": "Kuban Institute of Vocational Education"
        },
        {
            "yessentuky_institute_of_management_business_and_law": "Yessentuky Institute of Management Business and Law"
        },
        {
            "orenburg_branch_of_the_academy_of_labor_and_social_relations": "Orenburg Branch of the Academy of Labor and Social Relations"
        },
        {
            "branch_moscow_state_engineering_university_in_sec_tuchkovo_moscow_region": "Branch Moscow State Engineering University in Sec Tuchkovo Moscow region"
        },
        {
            "graduate_school_of_business": "Graduate School of Business"
        },
        {
            "zaoksky_christian_institute_of_humanities_and_economics": "Zaoksky Christian Institute of Humanities and Economics"
        },
        {
            "tver_institute_of_ecology_and_law": "Tver Institute of Ecology and Law"
        },
        {
            "academy_of_law_and_administration_of_the_federal_penitentiary_service": "Academy of Law and Administration of the Federal Penitentiary Service"
        },
        {
            "branch_of_saint_petersburg_state_university_of_economics_in_kizlyare": "Branch of Saint Petersburg State University of Economics in Kizlyare"
        },
        {
            "st_petersburg_university_of_humanities": "St Petersburg University of Humanities"
        },
        {
            "branch_of_the_saratov_state_technical_university_gagarin_ya_in_petrovsk": "Branch of the Saratov State Technical University Gagarin YA in Petrovsk"
        },
        {
            "polar_division_of_the_leningrad_state_university_as_pushkin": "Polar Division of the Leningrad State University AS Pushkin"
        },
        {
            "kuban_state_university_branch_in_the_city_of_novorossiysk": "Kuban State University Branch in the city of Novorossiysk"
        },
        {
            "moscow_institute_of_medico_social_rehabilitation": "Moscow Institute of Medico Social Rehabilitation"
        },
        {
            "branch_of_russian_state_humanitarian_university_in_domodedovo": "Branch of Russian State Humanitarian University in Domodedovo"
        },
        {
            "chelyabinsk_multidisciplinary_institute": "Chelyabinsk Multidisciplinary Institute"
        },
        {
            "east_european_institute": "East European Institute"
        },
        {
            "branch_of_the_moscow_state_university_of_technology_and_management_cg_razumovsky_in_volokolamsk": "Branch of the Moscow State University of Technology and Management CG Razumovsky in Volokolamsk"
        },
        {
            "rostov_state_university_of_railway_transport_branch_in_tuapse": "Rostov State University of Railway Transport Branch in Tuapse"
        },
        {
            "moscow_institute_of_public_administration_and_law_of_tyumen": "Moscow Institute of Public Administration and Law of Tyumen"
        },
        {
            "saint_petersburg_state_mining_institute_gv_plekhanova_vorkuta_mountain_institute": "Saint Petersburg State Mining Institute GV Plekhanova Vorkuta Mountain Institute"
        },
        {
            "institute_of_theology_and_international_relations_al-rocha_mammadibira": "Institute of Theology and International Relations al-Rocha Mammadibira"
        },
        {
            "rostov_state_economic_university_branch_in_makhachkala": "Rostov State Economic University Branch in Makhachkala"
        },
        {
            "institute_of_international_accounting_and_control": "Institute of International Accounting and Control"
        },
        {
            "kuzbass_institute_federal_penitentiary_service": "Kuzbass Institute Federal Penitentiary Service"
        },
        {
            "moscow_state_industrial_art_university_sg_stroganov": "Moscow State Industrial Art University SG Stroganov"
        }
    ],
    "slovenia": [
        {
            "university_of_ljubljana": "University of Ljubljana"
        },
        {
            "university_of_maribor": "University of Maribor"
        },
        {
            "university_of_primorska": "University of Primorska"
        },
        {
            "university_of_nova_gorica": "University of Nova Gorica"
        },
        {
            "jo\u00e5\u00beef_stefan_international_postgraduate_school_ljubljana": "Jo\u00c5\u00beef Stefan International Postgraduate School Ljubljana"
        },
        {
            "faculty_of_information_studies_in_novo_mesto": "Faculty of Information Studies in Novo Mesto"
        },
        {
            "faculty_of_management_koper": "Faculty of Management Koper"
        },
        {
            "bled_school_of_management": "Bled School of Management"
        },
        {
            "euro-mediterranean_university_portoro\u00e5\u00be": "Euro-Mediterranean University Portoro\u00c5\u00be"
        },
        {
            "alma_mater_europaea_(european_study_center_maribor": "Alma Mater Europaea (European Study Center Maribor"
        },
        {
            "doba": "DOBA"
        },
        {
            "international_school_for_social_and_business_studies_celje": "International School for Social and Business Studies Celje"
        },
        {
            "faculty_of_organisation_studies_in_novo_mesto": "Faculty of Organisation Studies in Novo Mesto"
        },
        {
            "new_university": "New University"
        },
        {
            "faculty_of_health_care_angele_boskin": "Faculty of Health Care Angele Boskin"
        },
        {
            "university_of_novo_mesto": "University of Novo Mesto"
        },
        {
            "faculty_of_mechanical_engineering_maribor": "Faculty of Mechanical Engineering Maribor"
        },
        {
            "doba_faculty_of_applied_business_and_social_studies_maribor": "DOBA Faculty of Applied Business and Social Studies Maribor"
        },
        {
            "faculty_of_design": "Faculty of Design"
        },
        {
            "graduate_school_of_humanities": "Graduate School of Humanities"
        },
        {
            "institute_and_academy_of_multimedia_iam": "Institute and Academy of Multimedia IAM"
        },
        {
            "college_of_services_ljubljana": "College of Services Ljubljana"
        },
        {
            "environmental_protection_college_velenje": "Environmental Protection College Velenje"
        },
        {
            "mlc_faculty_of_ljubljana": "MLC Faculty of Ljubljana"
        },
        {
            "faculty_for_polymer_technology": "Faculty for Polymer Technology"
        },
        {
            "college_of_accounting_ljubljana": "College of Accounting Ljubljana"
        },
        {
            "academy_of_visual_arts_ljubljana": "Academy of Visual Arts Ljubljana"
        },
        {
            "college_of_manufacturing_engineering": "College of Manufacturing Engineering"
        },
        {
            "fizioterapevtika_higher_education_institution": "Fizioterapevtika Higher Education Institution"
        },
        {
            "high_schools_for_hotel_and_tourism_bled": "High Schools for Hotel and Tourism Bled"
        },
        {
            "ravne_college": "Ravne College"
        }
    ],
    "malaysia": [
        {
            "university_of_malaya": "University of Malaya"
        },
        {
            "universiti_teknologi_malaysia": "Universiti Teknologi Malaysia"
        },
        {
            "universiti_putra_malaysia": "Universiti Putra Malaysia"
        },
        {
            "universiti_sains_malaysia": "Universiti Sains Malaysia"
        },
        {
            "national_university_of_malaysia": "National University of Malaysia"
        },
        {
            "mara_university_of_technology": "MARA University of Technology"
        },
        {
            "international_islamic_university_of_malaysia": "International Islamic University of Malaysia"
        },
        {
            "universiti_malaysia_pahang": "Universiti Malaysia Pahang"
        },
        {
            "technical_university_of_malaysia_melaka": "technical University of Malaysia Melaka"
        },
        {
            "petronas_university_of_technology": "PETRONAS University of Technology"
        },
        {
            "universiti_utara_malaysia": "Universiti Utara Malaysia"
        },
        {
            "university_of_tun_hussein_onn_malaysia": "University of Tun Hussein Onn Malaysia"
        },
        {
            "universiti_malaysia_perlis": "Universiti Malaysia Perlis"
        },
        {
            "sultan_idris_education_university": "Sultan Idris Education University"
        },
        {
            "university_of_nottingham_malaysia": "University of Nottingham Malaysia"
        },
        {
            "multimedia_university": "Multimedia University"
        },
        {
            "tunku_abdul_rahman_university": "Tunku Abdul Rahman University"
        },
        {
            "university_of_tenaga_nasional": "University of Tenaga Nasional"
        },
        {
            "university_of_malaysia_sabah": "University of Malaysia Sabah"
        },
        {
            "universiti_malaysia_terengganu": "Universiti Malaysia Terengganu"
        },
        {
            "international_medical_university": "International Medical University"
        },
        {
            "kuala_lumpur_university": "Kuala Lumpur University"
        },
        {
            "universiti_sains_islam_malaysia": "Universiti Sains Islam Malaysia"
        },
        {
            "ucsi_university": "UCSI University"
        },
        {
            "sultan_zainal_abidin_university_(university_of_darul_iman_malaysia)": "Sultan Zainal Abidin University (University of Darul Iman Malaysia)"
        },
        {
            "curtin_university_sarawak": "Curtin University Sarawak"
        },
        {
            "universiti_malaysia_kelantan": "Universiti Malaysia Kelantan"
        },
        {
            "monash_university_malaysia": "Monash University Malaysia"
        },
        {
            "national_defence_university_of_malaysia": "National Defence University of Malaysia"
        },
        {
            "swinburne_university_of_technology_sarawak_campus": "Swinburne University of Technology Sarawak Campus"
        },
        {
            "segi_university": "SEGi University"
        },
        {
            "universiti_malasia_sarawak": "universiti malasia sarawak"
        },
        {
            "management_and_science_university": "Management and Science University"
        },
        {
            "taylor's_university": "Taylor's University"
        },
        {
            "you_are_international_university": "You are International University"
        },
        {
            "asia_pacific_university_malaysia": "Asia Pacific University Malaysia"
        },
        {
            "lincoln_university_college_malaysia": "Lincoln University College Malaysia"
        },
        {
            "tunku_abdul_rahman_university_college": "Tunku Abdul Rahman University College"
        },
        {
            "sunway_university": "Sunway University"
        },
        {
            "prime_university": "Prime University"
        },
        {
            "help_university": "HELP University"
        },
        {
            "open_university_malaysia": "Open University Malaysia"
        },
        {
            "al_madinah_international_university": "Al Madinah International University"
        },
        {
            "selangor_university": "Selangor University"
        },
        {
            "aimst_university": "AIMST University"
        },
        {
            "manipal_university_college_malaysia": "Manipal University College Malaysia"
        },
        {
            "malaysia_university_of_science_&_technology": "Malaysia University of Science & Technology"
        },
        {
            "cyberjaya_university": "Cyberjaya University"
        },
        {
            "xiamen_university_malaysia_campus": "Xiamen University Malaysia Campus"
        },
        {
            "mahsa_university": "MAHSA University"
        },
        {
            "heriot-watt_university_malaysia": "Heriot-Watt University Malaysia"
        },
        {
            "lake_view_college": "Lake View College"
        },
        {
            "infrastructure_university_of_kuala_lumpur": "Infrastructure University of Kuala Lumpur"
        },
        {
            "iact_college": "IACT College"
        },
        {
            "university_college_of_technology_sarawak": "University College of Technology Sarawak"
        },
        {
            "open_university_insight": "Open University insight"
        },
        {
            "limkokwing_university_of_creative_technology": "Limkokwing University of Creative Technology"
        },
        {
            "tati_university_college": "TATI University College"
        },
        {
            "jesselton_college": "Jesselton College"
        },
        {
            "manipal_international_university": "Manipal International University"
        },
        {
            "quest_international_university_perak": "Quest International University Perak"
        },
        {
            "kuala_lumpur_metropolitan_university_college_klmuc": "Kuala Lumpur Metropolitan University College KLMUC"
        },
        {
            "new_era_university_college": "New Era University College"
        },
        {
            "college_university_college_kpj": "College university college kpj"
        },
        {
            "university_college_of_islam_melaka": "University College of Islam Melaka"
        },
        {
            "international_centre_for_education_in_islamic_finance": "International Centre for Education in Islamic Finance"
        },
        {
            "pushrawi_international_college_of_medical_sciences": "PUSHRAWI INTERNATIONAL COLLEGE OF MEDICAL SCIENCES"
        },
        {
            "southern_university_college": "Southern University College"
        },
        {
            "international_university_of_malaya_wales": "International University of Malaya Wales"
        },
        {
            "university_value": "University value"
        },
        {
            "seberang_perai_polytechnic": "Seberang Perai Polytechnic"
        },
        {
            "international_islamic_university_college_selangor": "International Islamic University College Selangor"
        },
        {
            "asia_school_of_business": "Asia School of Business"
        },
        {
            "asia_e_university": "Asia e University"
        },
        {
            "ftms_college": "FTMS College"
        },
        {
            "tun_abdul_razak_university": "Tun Abdul Razak University"
        },
        {
            "city_university_malaysia": "City University Malaysia"
        },
        {
            "darul_takzim_institute_of_science_and_technology": "Darul Takzim Institute of Science and Technology"
        },
        {
            "universiti_sultan_azlan_shah_usas": "Universiti Sultan Azlan Shah USAS"
        },
        {
            "international_islamic_university_of_sultan_abdul_halim_mu'adzam_shah_unishams": "International Islamic University of Sultan Abdul Halim Mu'adzam Shah Unishams"
        },
        {
            "university_of_reading_malaysia": "University of Reading Malaysia"
        },
        {
            "the_one_academy": "The One Academy"
        },
        {
            "malaysian_institute_of_art": "Malaysian Institute of Art"
        },
        {
            "i-cats_international_college_of_advanced_technology_sarawak": "i-CATS International College of Advanced Technology Sarawak"
        },
        {
            "sarawak_mukah_polytechnic": "Sarawak Mukah Polytechnic"
        },
        {
            "imperia_education_group": "Imperia Education Group"
        },
        {
            "albukhary_international_university": "Albukhary International University"
        },
        {
            "rcsi_&_ucd_malaysia_campus_(penang_medical_college)": "RCSI & UCD Malaysia Campus (Penang Medical College)"
        },
        {
            "berjaya_university_college_of_hospitality": "BERJAYA University College of Hospitality"
        },
        {
            "giatmara": "GIATMARA"
        },
        {
            "kolej_poly_tech_mara": "Kolej Poly Tech Mara"
        },
        {
            "german_malaysian_institute": "German Malaysian Institute"
        },
        {
            "widad_university_college": "Widad University College"
        },
        {
            "first_city_university_college_(former_kbu_international_college)": "First City University College (former KBU International College)"
        },
        {
            "aswara_national_arts_and_heritage_academy": "Aswara National Arts and Heritage Academy"
        },
        {
            "linton_university_college": "Linton University College"
        },
        {
            "university_college_of_aviation_malaysia": "University College of Aviation Malaysia"
        },
        {
            "malaysian_institute_of_cooperative": "Malaysian Institute of Cooperative"
        },
        {
            "asia_metropolitan_university": "Asia Metropolitan University"
        },
        {
            "sultan_abdul_halim_polytechnic": "Sultan Abdul Halim Polytechnic"
        },
        {
            "ungku_omar_polytechnic": "Ungku Omar Polytechnic"
        },
        {
            "kuching_polytechnic_sarawak": "Kuching Polytechnic Sarawak"
        },
        {
            "penang_skills_development_centre": "Penang Skills Development Centre"
        },
        {
            "sultan_haji_polytechnic_ahmad_shah": "Sultan Haji Polytechnic Ahmad Shah"
        },
        {
            "polytechnic_tuanku_syed_sirajuddin": "Polytechnic Tuanku Syed Sirajuddin"
        },
        {
            "stamford_college_series": "Stamford College series"
        },
        {
            "international_islamic_college": "International Islamic College"
        },
        {
            "intec_education_college": "INTEC Education College"
        },
        {
            "malaysian_sea_academy": "Malaysian Sea Academy"
        },
        {
            "polytechnic_ibrahim_sultan": "Polytechnic Ibrahim Sultan"
        },
        {
            "sultan_azlan_shah_polytechnic": "Sultan Azlan Shah Polytechnic"
        },
        {
            "institute_of_lintang_campus_campus_teacher_education": "Institute of Lintang Campus Campus Teacher Education"
        },
        {
            "muadzam_polytechnic_shah": "Muadzam polytechnic shah"
        },
        {
            "crescendo_international_college": "Crescendo International College"
        },
        {
            "metro_polytechnic_betong": "Metro Polytechnic Betong"
        },
        {
            "westminster_international_college_malaysia": "Westminster International College Malaysia"
        },
        {
            "university_malaysia_of_computer_science_&_engineering": "University Malaysia of Computer Science & Engineering"
        },
        {
            "islamic_university_college_pahang_sultan_ahmad_shah": "Islamic University College Pahang Sultan Ahmad Shah"
        },
        {
            "baitumal_professional_college_of_kuala_lumpur": "Baitumal Professional College of Kuala Lumpur"
        },
        {
            "polytechnic_back_island": "Polytechnic Back Island"
        },
        {
            "malaysian_polytechnic_merlimau": "Malaysian Polytechnic Merlimau"
        },
        {
            "sarawak_skills_development_center_ppks": "Sarawak Skills Development Center PPKS"
        },
        {
            "ucsi_college_kuala_lumpur": "UCSI College Kuala Lumpur"
        },
        {
            "sunway_college": "Sunway College"
        },
        {
            "binary_university": "Binary University"
        },
        {
            "despark_leading_automotive_&_aircraft_engineering_college": "Despark Leading Automotive & Aircraft Engineering College"
        },
        {
            "axismatics_professional_institute": "Axismatics Professional Institute"
        },
        {
            "international_college": "International College"
        },
        {
            "central_college_penang": "Central College Penang"
        },
        {
            "methodist_college_kuala_lumpur": "Methodist College Kuala Lumpur"
        },
        {
            "geomatics_university_college": "Geomatics University College"
        },
        {
            "kuala_terengganu_polytechnic": "Kuala Terengganu Polytechnic"
        },
        {
            "port_dickson_polytechnic": "Port Dickson Polytechnic"
        },
        {
            "oriental_nilam_college_of_nursing_and_health_sciences": "Oriental Nilam College of Nursing and Health Sciences"
        },
        {
            "sandakan_polytechnic": "Sandakan Polytechnic"
        },
        {
            "ypc_international_college": "YPC International College"
        },
        {
            "malaysia_theological_seminary": "Malaysia Theological Seminary"
        },
        {
            "jeffrey_cheah_institute_on_southeast_asia": "Jeffrey Cheah Institute on Southeast Asia"
        },
        {
            "international_medical_college": "International Medical College"
        },
        {
            "alfa_international_college": "ALFA International College"
        },
        {
            "methodist_pilley_institute": "Methodist Pilley Institute"
        },
        {
            "sabah_ucsf_university_college": "Sabah UCSF University College"
        },
        {
            "kuantan_metro_polytechnic": "Kuantan Metro Polytechnic"
        },
        {
            "islamic_institute_of_higher_education_perlis": "Islamic Institute of Higher Education Perlis"
        },
        {
            "malay_college_kuala_kangsar": "Malay College Kuala Kangsar"
        },
        {
            "poly-tech_university_college_mara": "Poly-Tech University College Mara"
        },
        {
            "international_university_college_of_technology_twintech": "International University College of Technology Twintech"
        },
        {
            "das_academy_of_art": "Das Academy of Art"
        },
        {
            "institute_of_physical_science_culture_and_arts_ipsca": "Institute of Physical Science Culture and Arts IPSCA"
        },
        {
            "islamic_university_of_malaysia": "Islamic University of Malaysia"
        },
        {
            "vision_college": "Vision College"
        },
        {
            "peninsula_college_malaysia": "Peninsula College Malaysia"
        },
        {
            "universiti_kuala_lumpur_malaysian_institute_of_marine_engineering_technology": "Universiti Kuala Lumpur Malaysian Institute of Marine Engineering Technology"
        },
        {
            "malaysian_flying_academy": "Malaysian Flying Academy"
        },
        {
            "advance_tertiary_college": "Advance Tertiary College"
        },
        {
            "sultan_ismail_petra_international_islamic_college": "Sultan Ismail Petra International Islamic College"
        },
        {
            "metro_polytechnic_tasek_gelugor": "Metro Polytechnic Tasek Gelugor"
        },
        {
            "islamic_studies_college_of_johor_marsah": "Islamic Studies College of Johor Marsah"
        },
        {
            "unifield_international_college": "Unifield International College"
        },
        {
            "unikop_college": "Unikop College"
        },
        {
            "kolej_yayasan_uem": "Kolej Yayasan UEM"
        },
        {
            "segi_college_kuala_lumpur": "SEGI College Kuala Lumpur"
        },
        {
            "sidma_college": "SIDMA College"
        },
        {
            "innovate_international_college": "Innovate International College"
        },
        {
            "university_college_of_pahang_yayasan": "University College of Pahang Yayasan"
        },
        {
            "msu_college": "MSU College"
        },
        {
            "geospatial_science_&_technology_college": "Geospatial Science & Technology College"
        },
        {
            "hulu_terengganu_polytechnic": "Hulu Terengganu Polytechnic"
        },
        {
            "international_institute_of_management_and_technology_iimat": "International Institute of Management and Technology IIMAT"
        },
        {
            "perlis_islamic_university_college_kuips": "Perlis Islamic University College Kuips"
        },
        {
            "elite_international_college": "Elite International College"
        },
        {
            "university_college_of_agroscience_malaysia": "University College of Agroscience Malaysia"
        },
        {
            "vtar_institute_(former_kojadi_institute)": "VTAR Institute (former Kojadi Institute)"
        },
        {
            "esmod_kuala_lumpur": "ESMOD Kuala Lumpur"
        },
        {
            "selayang_community_college": "Selayang Community College"
        },
        {
            "sunway_college_kuching": "Sunway College Kuching"
        },
        {
            "yes_international_college": "YES International College"
        },
        {
            "taj_international_college_tic": "TAJ International College TIC"
        },
        {
            "penang_international_dental_college": "Penang International Dental College"
        },
        {
            "nirwana_college": "Nirwana College"
        },
        {
            "fame_international_college": "FAME International College"
        },
        {
            "polytechnic_mersing_johor": "Polytechnic Mersing Johor"
        },
        {
            "melaka_international_college_of_science_and_technology": "Melaka International College of Science and Technology"
        },
        {
            "kolej_uniti": "Kolej Uniti"
        },
        {
            "city_college": "City College"
        },
        {
            "north_borneo_university_college": "North Borneo University College"
        },
        {
            "institute_cece": "Institute CECE"
        },
        {
            "ranaco_education_&_training_institute": "Ranaco Education & Training Institute"
        },
        {
            "food_institute_of_malaysia": "Food Institute of Malaysia"
        },
        {
            "johor_bahru_metro_polytechnic": "Johor Bahru Metro Polytechnic"
        },
        {
            "klia_college": "KLIA College"
        },
        {
            "mahsa_college_shaba": "MAHSA College Shaba"
        },
        {
            "niit_college": "NIIT College"
        },
        {
            "adventist_college_of_nursing_&_health_sciences": "Adventist College of Nursing & Health Sciences"
        },
        {
            "president_college": "President College"
        },
        {
            "sedamai_college": "SEDAMAI College"
        },
        {
            "life_college": "Life College"
        },
        {
            "asa_college": "ASA College"
        },
        {
            "excel_international_college": "Excel International College"
        },
        {
            "riam_institute_of_technology": "Riam Institute of Technology"
        },
        {
            "laila_taib_college": "Laila Taib College"
        },
        {
            "satt_college": "Satt College"
        },
        {
            "meritus_university": "Meritus University"
        },
        {
            "ijn_college": "IJN College"
        },
        {
            "keris_college": "Keris College"
        },
        {
            "kolej_yayasan_pahang": "Kolej Yayasan Pahang"
        },
        {
            "oasis_college": "Oasis College"
        },
        {
            "mount_royale_college": "Mount Royale College"
        },
        {
            "kings_college_malaysia": "Kings College Malaysia"
        },
        {
            "suria_college_of_hospitality": "Suria College of Hospitality"
        },
        {
            "east_west_international_college": "East West International College"
        },
        {
            "spectrum_international_college_of_technology": "Spectrum International College of Technology"
        },
        {
            "king_melewar_king_teacher_education_institute": "King Melewar King Teacher Education Institute"
        },
        {
            "ans_institute_of_technology": "ANS Institute of Technology"
        },
        {
            "ican_college": "Ican College"
        },
        {
            "isbauk_thinking_skill_college": "ISBAUK Thinking Skill College"
        },
        {
            "netherlands_maritime_university_college": "Netherlands Maritime University College"
        },
        {
            "assunta_college_of_nursing": "Assunta College of Nursing"
        },
        {
            "mea_technology_college": "MEA Technology College"
        },
        {
            "metropoint_college": "Metropoint College"
        },
        {
            "ktdkl_ktdkl's_darulnaim_technology_college": "KTDKL KTDKL's Darulnaim Technology College"
        },
        {
            "ausffield_college": "Ausffield College"
        },
        {
            "newton_college": "Newton College"
        },
        {
            "section_college_city_of_damansara": "Section College City of Damansara"
        }
    ],
    "poland": [
        {
            "jagiellonian_university": "Jagiellonian University"
        },
        {
            "university_of_warsaw": "University of Warsaw"
        },
        {
            "agh_university_of_science_&_technology": "AGH University of Science & Technology"
        },
        {
            "warsaw_university_of_technology": "Warsaw University of Technology"
        },
        {
            "adam_mickiewicz_university_poznan": "Adam Mickiewicz University Poznan"
        },
        {
            "nicolaus_copernicus_university": "Nicolaus Copernicus University"
        },
        {
            "poznan_university_of_technology": "Poznan University of Technology"
        },
        {
            "university_of_wroclaw": "University of Wroclaw"
        },
        {
            "silesian_university_of_technology_in_gliwice": "Silesian University of Technology in Gliwice"
        },
        {
            "medical_university_of_warsaw": "Medical University of Warsaw"
        },
        {
            "wroc\u00e5\u201aaw_university_of_science_and_technology_(wroclaw_university_of_technology)": "Wroc\u00c5\u201aaw University of Science and Technology (Wroclaw University of Technology)"
        },
        {
            "university_of_silesia_in_katowice": "University of Silesia in Katowice"
        },
        {
            "university_of_lodz": "University of Lodz"
        },
        {
            "rzeszow_university_of_technology": "Rzeszow University of Technology"
        },
        {
            "university_of_gdansk": "University of Gdansk"
        },
        {
            "gdansk_university_of_technology": "Gdansk University of Technology"
        },
        {
            "university_of_warmia_and_mazury": "University of Warmia and Mazury"
        },
        {
            "lublin_university_of_technology": "Lublin University of Technology"
        },
        {
            "tadeusz_ko\u00e5\u203aciuszko_cracow_university_of_technology": "Tadeusz Ko\u00c5\u203aciuszko Cracow University of Technology"
        },
        {
            "university_of_zielona_g\u00e3\u00b3ra": "University of Zielona G\u00c3\u00b3ra"
        },
        {
            "medical_university_of_wroclaw": "Medical University of Wroclaw"
        },
        {
            "poznan_university_of_medical_sciences": "Poznan University of Medical Sciences"
        },
        {
            "bialystok_technical_university": "Bialystok Technical University"
        },
        {
            "west_pomeranian_university_of_technology": "West Pomeranian University of Technology"
        },
        {
            "technical_university_of_czestochowa": "Technical University of Czestochowa"
        },
        {
            "medical_university_of_gdansk": "Medical University of Gdansk"
        },
        {
            "university_at_bialystok": "University at Bialystok"
        },
        {
            "maria_curie_sklodowska_university": "Maria Curie Sklodowska University"
        },
        {
            "warsaw_university_of_life_sciences_(agricultural_university)": "Warsaw University of Life Sciences (Agricultural University)"
        },
        {
            "medical_university_of_silesia_in_katowice": "Medical University of Silesia in Katowice"
        },
        {
            "wroc\u00e5\u201aaw_university_of_environmental_and_life_sciences_(agricultural_university)": "Wroc\u00c5\u201aaw University of Environmental and Life Sciences (Agricultural University)"
        },
        {
            "university_of_rzeszow": "University of Rzeszow"
        },
        {
            "opole_university_of_technology": "Opole University of Technology"
        },
        {
            "pedagogical_university_of_cracow": "Pedagogical University of Cracow"
        },
        {
            "warsaw_school_of_economics": "Warsaw School of Economics"
        },
        {
            "poznan_university_of_economics": "Poznan University of Economics"
        },
        {
            "kazimierz_wielki_university_bydgoszcz": "Kazimierz Wielki University Bydgoszcz"
        },
        {
            "cardinal_stefan_wyszynski_university_warsaw": "Cardinal Stefan Wyszynski University Warsaw"
        },
        {
            "medical_university_of_lublin": "Medical University of Lublin"
        },
        {
            "szczecin_university": "Szczecin University"
        },
        {
            "cracow_university_of_economics": "Cracow University of Economics"
        },
        {
            "pomeranian_medical_university": "Pomeranian Medical University"
        },
        {
            "agricultural_university_of_cracow": "Agricultural University of Cracow"
        },
        {
            "technical_university_of_koszalin": "Technical University of Koszalin"
        },
        {
            "wroclaw_university_of_economics": "Wroclaw University of Economics"
        },
        {
            "gdynia_maritime_university": "Gdynia Maritime University"
        },
        {
            "kielce_university_of_technology": "Kielce University of Technology"
        },
        {
            "leon_kozminski_academy_of_entrepreneurship_and_management": "Leon Kozminski Academy of Entrepreneurship and Management"
        },
        {
            "university_of_economics_in_katowice": "University of Economics in Katowice"
        },
        {
            "medical_university_of_lodz": "Medical University of Lodz"
        },
        {
            "university_of_natural_sciences_and_the_humanities_in_siedlce": "University of Natural Sciences and the Humanities in Siedlce"
        },
        {
            "university_of_bielsko-biala": "University of Bielsko-Biala"
        },
        {
            "maritime_university_in_szczecin": "Maritime University in Szczecin"
        },
        {
            "catholic_university_of_lublin": "Catholic University of Lublin"
        },
        {
            "medical_university_of_bialystok": "Medical University of Bialystok"
        },
        {
            "swps_university_of_social_sciences_and_humanities": "SWPS University of Social Sciences and Humanities"
        },
        {
            "university_of_opole": "University of Opole"
        },
        {
            "jan_kochanowski_university_of_humanities_and_sciences_(swietokrzyska_pedagogical_university)": "Jan Kochanowski University of Humanities and Sciences (Swietokrzyska Pedagogical University)"
        },
        {
            "academy_of_physical_education_in_katowice": "Academy of Physical Education in Katowice"
        },
        {
            "pozna\u00e5\u201e_university_of_life_sciences": "Pozna\u00c5\u201e University of Life Sciences"
        },
        {
            "jan_dlugosz_university_in_czestochowa": "Jan Dlugosz University in Czestochowa"
        },
        {
            "academy_of_physical_education_in_wroclaw": "Academy of Physical Education in Wroclaw"
        },
        {
            "medical_centre_of_postgraduate_education": "Medical Centre of Postgraduate Education"
        },
        {
            "university_of_warsaw_faculty_of_mathematics_informatics_and_mechanics": "University of Warsaw Faculty of Mathematics Informatics and Mechanics"
        },
        {
            "the_economic_and_humanities_academy_in_warsaw": "The Economic and Humanities Academy in Warsaw"
        },
        {
            "university_of_warsaw_faculty_of_physics": "University of Warsaw Faculty of Physics"
        },
        {
            "jozef_pilsudski_university_of_physical_education_in_warsaw": "Jozef Pilsudski University of Physical Education in Warsaw"
        },
        {
            "academy_of_physical_education_and_sport_in_gdansk": "Academy of Physical Education and Sport in Gdansk"
        },
        {
            "high_school_of_economics_and_innovation_in_lublin": "High School of Economics and Innovation in Lublin"
        },
        {
            "polish_japanese_institute_of_information_technology_in_warsaw": "Polish Japanese Institute of Information Technology in Warsaw"
        },
        {
            "university_of_information_technology_and_management_in_rzeszow": "University of Information Technology and Management in Rzeszow"
        },
        {
            "naval_academy_in_gdynia": "Naval Academy in Gdynia"
        },
        {
            "radom_university_of_technology_kazimierza_pulaskiego": "Radom University of Technology Kazimierza Pulaskiego"
        },
        {
            "technical_university_of_fire_service": "Technical University of Fire Service"
        },
        {
            "medical_academy_ludwik_rydygier_in_bydgoszcz": "Medical Academy Ludwik Rydygier in Bydgoszcz"
        },
        {
            "eugeniusz_piasecki_university_school_of_physical_education_in_poznan": "Eugeniusz Piasecki University School of Physical Education in Poznan"
        },
        {
            "academy_of_special_education_maria_grzegorzewska": "Academy of Special Education Maria Grzegorzewska"
        },
        {
            "pontifical_university_john_paul_ii": "Pontifical University John Paul II"
        },
        {
            "jagiellonian_university_medical_college_in_cracow": "Jagiellonian University Medical College in Cracow"
        },
        {
            "jesuit_university_of_philosophy_and_education_ignatianum_cracow": "Jesuit University of Philosophy and Education Ignatianum Cracow"
        },
        {
            "vistula_university_(university_of_economics_and_computer_science_in_warsaw)": "Vistula University (University of Economics and Computer Science in Warsaw)"
        },
        {
            "university_of_lower_silesia": "University of Lower Silesia"
        },
        {
            "education_development_center": "Education Development Center"
        },
        {
            "warsaw_school_of_information_technology": "Warsaw School of Information Technology"
        },
        {
            "pomorska_pedagogical_university_in_slupsk": "Pomorska Pedagogical University in Slupsk"
        },
        {
            "wsb_school_of_banking": "WSB School of Banking"
        },
        {
            "lazarski_university": "Lazarski University"
        },
        {
            "polish_institute_of_international_affairs": "Polish Institute of International Affairs"
        },
        {
            "college_of_computer_science_in_lodz": "College of Computer Science in Lodz"
        },
        {
            "state_higher_vocational_school_in_biala_podlaska": "State Higher Vocational School in Biala Podlaska"
        },
        {
            "jan_matejko_academy_of_fine_arts_in_cracow": "Jan Matejko Academy of Fine Arts in Cracow"
        },
        {
            "university_of_warsaw_astronomical_observatory": "University of Warsaw Astronomical Observatory"
        },
        {
            "academy_of_music_in_cracow": "Academy of Music in Cracow"
        },
        {
            "fine_arts_academy_warsaw": "Fine Arts Academy Warsaw"
        },
        {
            "tischner_european_university_in_cracow": "Tischner European University in Cracow"
        },
        {
            "national_film_television_and_theatre_school_in_lodz": "National Film Television and Theatre School in Lodz"
        },
        {
            "higher_school_of_business_national_louis_university_in_nowy_s\u00e4\u2026cz": "Higher School of Business National Louis University in Nowy S\u00c4\u2026cz"
        },
        {
            "andrzej_frycz_modrzewski_krakow_university_college": "Andrzej Frycz Modrzewski Krakow University College"
        },
        {
            "fryderyk_chopin_university_of_music": "Fryderyk Chopin University of Music"
        },
        {
            "kujawy_and_pomorze_university_in_bydgoszcz": "Kujawy and Pomorze University in Bydgoszcz"
        },
        {
            "cosinus_higher_school_in_lodz": "Cosinus Higher School in Lodz"
        },
        {
            "academy_of_music_poznan_ignacy_jana_paderewski": "Academy of Music Poznan Ignacy Jana Paderewski"
        },
        {
            "air_force_institute_of_technology": "Air Force Institute of Technology"
        },
        {
            "fine_arts_academy_wroclaw": "Fine Arts Academy Wroclaw"
        },
        {
            "university_of_humanities_and_economics_in_lodz": "University of Humanities and Economics in Lodz"
        },
        {
            "academy_of_fine_arts_": "Academy of Fine Arts "
        },
        {
            "collegium_civitas_in_warsaw": "Collegium Civitas in Warsaw"
        },
        {
            "academy_of_computer_science_and_management_in_bielsko-bia\u00e5\u201aa": "Academy of Computer Science and Management in Bielsko-Bia\u00c5\u201aa"
        },
        {
            "academy_of_fine_arts_in_gdansk": "Academy of Fine Arts in Gdansk"
        },
        {
            "university_of_economy_in_bydgoszczy": "University of Economy in Bydgoszczy"
        },
        {
            "school_of_banking_and_management_in_cracow": "School of Banking and Management in Cracow"
        },
        {
            "university_of_ecology_and_management_in_warsaw": "University of Ecology and Management in Warsaw"
        },
        {
            "academy_of_music_in_bydgoszcz": "Academy of Music in Bydgoszcz"
        },
        {
            "karol_lipinski_university_of_music_in_wroclaw": "Karol Lipinski University of Music in Wroclaw"
        },
        {
            "university_of_technology_and_economics_helena_chodkowska": "University of Technology and Economics Helena Chodkowska"
        },
        {
            "karol_szymanowski_academy_of_music_in_katowice": "Karol Szymanowski Academy of Music in Katowice"
        },
        {
            "academy_of_music_gdansk_stanislaw_moniuszko": "Academy of Music Gdansk Stanislaw Moniuszko"
        },
        {
            "higher_school_of_safety_in_pozna\u00e5\u201e": "Higher School of Safety in Pozna\u00c5\u201e"
        },
        {
            "state_higher_vocational_school_in_nysa": "State Higher Vocational School in Nysa"
        },
        {
            "grazyna_and_kiejstut_bacewicz_academy_of_music_in_lodz": "Grazyna and Kiejstut Bacewicz Academy of Music in Lodz"
        },
        {
            "collegium_da_vinci_in_pozna\u00e5\u201e": "Collegium Da Vinci in Pozna\u00c5\u201e"
        },
        {
            "business_and_administration_school_in_gdynia": "Business and Administration School in Gdynia"
        },
        {
            "ateneum_higher_school_in_gda\u00e5\u201esk": "Ateneum Higher School in Gda\u00c5\u201esk"
        },
        {
            "west_pomeranian_business_school": "West Pomeranian Business School"
        },
        {
            "higher_school_of_police_in_szczytno": "Higher School of Police in Szczytno"
        },
        {
            "higher_school_of_economics_wroclaw": "Higher School of Economics Wroclaw"
        },
        {
            "bogdan_janski_academy": "Bogdan Janski Academy"
        },
        {
            "state_higher_school_of_vocational_education_in_elblag": "State Higher School of Vocational Education in Elblag"
        },
        {
            "university_of_work_safety_management_in_katowice": "University of Work Safety Management in Katowice"
        },
        {
            "college_of_computer_science_and_business_administration_in_lomza": "College of Computer Science and Business Administration in Lomza"
        },
        {
            "college_of_enterprise_and_administration_in_lublin": "College of Enterprise and Administration in Lublin"
        },
        {
            "pwst_national_academy_of_theater_arts_cracow_/_academy_of_theater_arts_im._stanis\u00e5\u201aaw_wyspia\u00e5\u201eski_in_krakow_ast_krakow": "PWST National Academy of Theater Arts Cracow / Academy of Theater Arts im. Stanis\u00c5\u201aaw Wyspia\u00c5\u201eski in Krakow AST Krakow"
        },
        {
            "szczecin_collegium_collegium_balticum_(stargardinum_stargard_school_in_stargard_szczeci\u00e5\u201eski)": "Szczecin Collegium Collegium Balticum (Stargardinum Stargard School in Stargard Szczeci\u00c5\u201eski)"
        },
        {
            "pozna\u00e5\u201e_school_of_logistics": "Pozna\u00c5\u201e School of Logistics"
        },
        {
            "bialystok_school_of_economics": "Bialystok School of Economics"
        },
        {
            "art_academy_of_szczecin": "Art Academy of Szczecin"
        },
        {
            "school_of_economics_and_law_kielce": "School of Economics and Law Kielce"
        },
        {
            "university_of_business_and_enterprise_in_ostrowiec_\u00e5\u0161wi\u00e4\u2122tokrzyski": "University of Business and Enterprise in Ostrowiec \u00c5\u0161wi\u00c4\u2122tokrzyski"
        },
        {
            "warsaw_school_of_computer_science": "Warsaw School of Computer Science"
        },
        {
            "linguistic_and_technical_university_in_\u00e5\u0161wiecie": "Linguistic and Technical University in \u00c5\u0161wiecie"
        },
        {
            "polonia_university_in_cz\u00e4\u2122stochowa": "Polonia University in Cz\u00c4\u2122stochowa"
        },
        {
            "technical_higher_school_in_katowice": "Technical Higher School in Katowice"
        },
        {
            "radom_academy_of_economics": "Radom Academy of Economics"
        },
        {
            "national_school_of_public_administration_poland": "National School of Public Administration Poland"
        },
        {
            "christian_theological_academy_in_warsaw": "Christian Theological Academy in Warsaw"
        },
        {
            "college_of_foreign_languages_in_czestochowa": "College of Foreign Languages in Czestochowa"
        },
        {
            "higher_vocational_school_in_tarnow": "Higher Vocational School in Tarnow"
        },
        {
            "state_school_of_higher_professional_education_in_konin": "State School of Higher Professional Education in Konin"
        },
        {
            "state_higher_vocational_school_in_legnica": "State Higher Vocational School in Legnica"
        },
        {
            "warsaw_humanistic_higher_school": "Warsaw Humanistic Higher School"
        },
        {
            "state_higher_vocational_school_in_przemysl": "State Higher Vocational School in Przemysl"
        },
        {
            "warszawska_film_school_with_headquarters_in_warsaw": "Warszawska Film School with headquarters in Warsaw"
        },
        {
            "university_of_finance_and_management_in_bia\u00e5\u201aystok": "University of Finance and Management in Bia\u00c5\u201aystok"
        },
        {
            "wa\u00e5\u201abrzych_higher_school_of_management_and_enterprise": "Wa\u00c5\u201abrzych Higher School of Management and Enterprise"
        },
        {
            "state_higher_vocational_school_in_jaroslaw": "State Higher Vocational School in Jaroslaw"
        },
        {
            "university_of_mazovia_in_warsaw": "University of Mazovia in Warsaw"
        },
        {
            "pontifical_faculty_of_theology_in_wroclaw": "Pontifical Faculty of Theology in Wroclaw"
        },
        {
            "state_higher_vocational_school_in_nowy_sacz": "State Higher Vocational School in Nowy Sacz"
        },
        {
            "academy_of_management_and_administration_in_opole": "Academy of Management and Administration in Opole"
        },
        {
            "carpathian_state_university_in_krosno": "Carpathian State University in Krosno"
        },
        {
            "international_school_of_logistics_and_transport_in_wroclaw": "International School of Logistics and Transport in Wroclaw"
        },
        {
            "karkonosze_collegium_in_jelenia_g\u00e3\u00b3ra": "Karkonosze Collegium in Jelenia G\u00c3\u00b3ra"
        },
        {
            "state_higher_vocational_school_in_walbrzych": "State Higher Vocational School in Walbrzych"
        },
        {
            "higher_school_of_finance_and_accountancy_in_sopot": "Higher School of Finance and Accountancy in Sopot"
        },
        {
            "malopolska_school_of_economics_in_tarnow": "Malopolska School of Economics in Tarnow"
        },
        {
            "gdansk_management_college": "Gdansk Management College"
        },
        {
            "university_vincent_pol_in_lublin": "University Vincent Pol in Lublin"
        },
        {
            "polish_virtual_university": "Polish Virtual University"
        },
        {
            "wut_business_school": "WUT Business School"
        },
        {
            "higher_school_of_business_in_warsaw": "Higher School of Business in Warsaw"
        },
        {
            "olsztyn_university": "Olsztyn University"
        },
        {
            "pawel_wlodkowic_university_college_in_plock": "Pawel Wlodkowic University College in Plock"
        },
        {
            "higher_school_of_humanities_association_for_adult_education_in_szczecin": "Higher School of Humanities Association for Adult Education in Szczecin"
        },
        {
            "higher_teacher_education_school_of_the_polish_teachers\u00e2\u20ac\u2122_union_in_warsaw": "Higher Teacher Education School of the Polish Teachers\u00e2\u20ac\u2122 Union in Warsaw"
        },
        {
            "higher_school_of_computer_science_and_management_in_olsztyn": "Higher School of Computer Science and Management in Olsztyn"
        },
        {
            "higher_school_of_strategic_planning_in_d\u00e4\u2026browa_g\u00e3\u00b3rnicza": "Higher School of Strategic Planning in D\u00c4\u2026browa G\u00c3\u00b3rnicza"
        },
        {
            "higher_school_of_foreign_languages_in_warsaw": "Higher School of Foreign Languages in Warsaw"
        },
        {
            "higher_school_of_infrastructure_and_management_in_warsaw": "Higher School of Infrastructure and Management in Warsaw"
        },
        {
            "czestochowa_university_of_management": "Czestochowa University of Management"
        },
        {
            "academy_of_agribusiness_in_lomza": "Academy of Agribusiness in Lomza"
        },
        {
            "european_higher_school_of_computer_science_and_economics_in_warsaw": "European Higher School of Computer Science and Economics in Warsaw"
        },
        {
            "higher_school_of_business_and_health_science_in_lodz": "Higher School of Business and Health Science in Lodz"
        },
        {
            "old_polish_college_in_kielce": "Old Polish College in Kielce"
        },
        {
            "cracow_higher_school_of_the_health_promotion": "Cracow Higher School of the Health Promotion"
        },
        {
            "state_university_of_stanis\u00e5\u201aaw_staszica_in_pi\u00e5\u201aa": "State University of Stanis\u00c5\u201aaw Staszica in Pi\u00c5\u201aa"
        },
        {
            "european_school_of_law_and_administration_in_warsaw": "European School of Law and Administration in Warsaw"
        },
        {
            "evangelical_school_of_theology_in_wroclaw": "Evangelical School of Theology in Wroclaw"
        },
        {
            "state_higher_vocational_school_in_glog\u00e3\u00b3w": "State Higher Vocational School in Glog\u00c3\u00b3w"
        },
        {
            "school_of_rehabilitation_in_warsaw": "School of Rehabilitation in Warsaw"
        },
        {
            "warsaw_medical_university_im._tadeusz_ko\u00e5\u00baluka": "Warsaw Medical University im. Tadeusz Ko\u00c5\u00baluka"
        },
        {
            "bielsko-bia\u00e5\u201aa_school_of_finance_and_law": "Bielsko-Bia\u00c5\u201aa School of Finance and Law"
        },
        {
            "higher_school_of_tourism_and_hotel_industry_in_gda\u00e5\u201esk": "Higher School of Tourism and Hotel Industry in Gda\u00c5\u201esk"
        },
        {
            "higher_school_of_art_and_design_in_lodz": "Higher School of Art and Design in Lodz"
        },
        {
            "silesian_higher_school_of_enterprise_in_chorzow": "Silesian Higher School of Enterprise in Chorzow"
        },
        {
            "college_of_communications_and_management_in_poznan": "College of Communications and Management in Poznan"
        },
        {
            "academy_of_hotel_management_and_catering_industry_poznan": "Academy of Hotel Management and Catering Industry Poznan"
        },
        {
            "academy_of_sport_education_in_warsaw": "Academy of Sport Education in Warsaw"
        },
        {
            "state_higher_vocational_school_in_ciechan\u00e3\u00b3w": "State Higher Vocational School in Ciechan\u00c3\u00b3w"
        },
        {
            "higher_seminarium_rzeszow": "Higher Seminarium Rzeszow"
        },
        {
            "higher_school_of_finance_and_management_in_siedlce": "Higher School of Finance and Management in Siedlce"
        },
        {
            "private_college_of_environmental_protection_in_radom": "Private College of Environmental Protection in Radom"
        },
        {
            "state_higher_vocational_school_in_chelm": "State Higher Vocational School in Chelm"
        },
        {
            "higher_school_of_tourism_and_foreign_languages_in_warsaw": "Higher School of Tourism and Foreign Languages in Warsaw"
        },
        {
            "higher_school_of_public_administration_in_kielce": "Higher School of Public Administration in Kielce"
        },
        {
            "swietokrzyska_university_in_kielce": "Swietokrzyska University in Kielce"
        },
        {
            "school_of_social_sciences_in_lublin": "School of Social Sciences in Lublin"
        },
        {
            "state_higher_vocational_school_in_sulech\u00e3\u00b3w": "State Higher Vocational School in Sulech\u00c3\u00b3w"
        },
        {
            "medical_higher_school_of_silesia_in_katowice": "Medical Higher School of Silesia in Katowice"
        },
        {
            "koszalin_state_higher_vocational_school": "Koszalin State Higher Vocational School"
        },
        {
            "rzesz\u00e3\u00b3w_school_of_engineering_and_economics": "Rzesz\u00c3\u00b3w School of Engineering and Economics"
        },
        {
            "higher_school_of_finance_and_banking_in_radom": "Higher School of Finance and Banking in Radom"
        },
        {
            "school_of_management_and_marketing_in_sochaczew": "School of Management and Marketing in Sochaczew"
        },
        {
            "european_career_school_in_pozna\u00e5\u201e": "European Career School in Pozna\u00c5\u201e"
        },
        {
            "college_of_jagiellonian_toru\u00e5\u201e_university": "College of Jagiellonian Toru\u00c5\u201e University"
        },
        {
            "academy_of_european_integration_in_szczecin": "Academy of European Integration in Szczecin"
        },
        {
            "warsaw_management_school_graduate_and_postgraduate_school": "Warsaw Management School Graduate and Postgraduate School"
        },
        {
            "higher_engineering_school_of_work_safety_and_organization_in_radom": "Higher Engineering School of Work Safety and Organization in Radom"
        },
        {
            "social-economic_higher_school_in_gda\u00e5\u201esk": "Social-Economic Higher School in Gda\u00c5\u201esk"
        },
        {
            "college_of_management_education_in_wroclaw": "College of Management Education in Wroclaw"
        },
        {
            "higher_school_of_public_administration_in_szczecin": "Higher School of Public Administration in Szczecin"
        },
        {
            "medical_college_maria_sk\u00e5\u201aodowska_curie_in_warsaw": "Medical College Maria Sk\u00c5\u201aodowska Curie in Warsaw"
        },
        {
            "higher_school_of_safety_and_security_services_in_warsaw": "Higher School of Safety and Security Services in Warsaw"
        },
        {
            "viamoda_school_in_warsaw": "VIAMODA School in Warsaw"
        },
        {
            "higher_school_of_cosmetology_and_health_science_in_lodz": "Higher School of Cosmetology and Health Science in Lodz"
        },
        {
            "wielkopolska_higher_school_of_social_sciences_and_economics": "Wielkopolska Higher School of Social Sciences and Economics"
        },
        {
            "malopolska_school_of_economics_in_krakow": "Malopolska School of Economics in Krakow"
        },
        {
            "halina_konopacka_higher_school_of_physical_culture_and_tourism_in_pruszk\u00e3\u00b3w": "Halina Konopacka Higher School of Physical Culture and Tourism in Pruszk\u00c3\u00b3w"
        },
        {
            "humanistic_manager_higher_school_milenium_in_gniezno": "Humanistic Manager Higher School Milenium in Gniezno"
        },
        {
            "college_of_social_communication_in_gdynia": "College of Social Communication in Gdynia"
        },
        {
            "non_state_higher_pedagogical_school_in_bia\u00e5\u201aystok": "Non State Higher Pedagogical School in Bia\u00c5\u201aystok"
        },
        {
            "state_higher_vocational_school_in_tarnobrzeg": "State Higher Vocational School in Tarnobrzeg"
        },
        {
            "college_of_physiotherapy_in_wroc\u00e5\u201aaw": "College of Physiotherapy in Wroc\u00c5\u201aaw"
        },
        {
            "legnica_university_of_management": "Legnica University of Management"
        },
        {
            "almamer_university_of_economics_in_warsaw": "Almamer University of Economics in Warsaw"
        },
        {
            "higher_school_of_health_sciences_in_bydgoszczy": "Higher School of Health Sciences in Bydgoszczy"
        },
        {
            "kaszubian-pomeranian_higher_school_in_wejherowo": "Kaszubian-Pomeranian Higher School in Wejherowo"
        },
        {
            "higher_school_of_international_relations_and_social_communication_in_che\u00e5\u201am": "Higher School of International Relations and Social Communication in Che\u00c5\u201am"
        },
        {
            "gda\u00e5\u201esk_college_of_administration": "Gda\u00c5\u201esk College of Administration"
        },
        {
            "kwidzyn_school_of_management": "Kwidzyn School of Management"
        },
        {
            "university_of_computer_engineering_and_telecommunications_in_kielce": "University of Computer Engineering and Telecommunications in Kielce"
        },
        {
            "higher_school_of_physical_education_and_tourism_in_bialystok": "Higher School of Physical Education and Tourism in Bialystok"
        },
        {
            "higher_school_of_technology_and_economics_in_szczecin": "Higher School of Technology and Economics in Szczecin"
        },
        {
            "college_correct": "College correct"
        },
        {
            "higher_school_of_social_education": "Higher School of Social Education"
        },
        {
            "higher_school_uni_terra_in_poznan": "Higher School Uni Terra in Poznan"
        },
        {
            "mazowiecka_public_college_in_p\u00e5\u201aock": "Mazowiecka Public College in P\u00c5\u201aock"
        },
        {
            "higher_school_of_applied_arts_in_szczecin": "Higher School of Applied Arts in Szczecin"
        },
        {
            "higher_school_of_professional_skills_in_pinczow": "Higher School of Professional Skills in Pinczow"
        },
        {
            "higher_artistic_school_in_warsaw": "Higher Artistic School in Warsaw"
        },
        {
            "vocational_higher_school_of_cherishing_of_health_and_beauty_in_poznan": "Vocational Higher School of Cherishing of Health and Beauty in Poznan"
        },
        {
            "collegium_masoviense_higher_studies": "Collegium Masoviense Higher Studies"
        },
        {
            "non-public_higher_medical_school_in_wroclaw": "Non-Public Higher Medical School in Wroclaw"
        },
        {
            "general_humanistic_higher_school_pomerania_in_chojnice": "General Humanistic Higher School Pomerania in Chojnice"
        },
        {
            "tarnowska_college_(ma\u00e5\u201aopolska_school_of_college_in_brzesko)": "Tarnowska College (Ma\u00c5\u201aopolska School of College in Brzesko)"
        },
        {
            "swietokrzyska_higher_school_in_kielce": "Swietokrzyska Higher School in Kielce"
        },
        {
            "koszalin_higher_school_of_humanistic_sciences": "Koszalin Higher School of Humanistic Sciences"
        },
        {
            "higher_seminarium_franciszkanow_lod\u00e5\u00ba": "Higher Seminarium Franciszkanow Lod\u00c5\u00ba"
        },
        {
            "higher_school_of_pedagogics_and_technology_in_konin": "Higher School of Pedagogics and Technology in Konin"
        },
        {
            "higher_school_of_economics_and_management_mielec": "Higher School of Economics and Management Mielec"
        },
        {
            "lublin_higher_school_in_ryki": "Lublin Higher School in Ryki"
        },
        {
            "jan_wyzykowski_university": "Jan Wyzykowski University"
        },
        {
            "higher_school_of_podkarpacie_in_jaslo": "Higher School of Podkarpacie in Jaslo"
        },
        {
            "higher_medical_school_in_k\u00e5\u201aodzko": "Higher Medical School in K\u00c5\u201aodzko"
        },
        {
            "higher_school_of_business_and_management_in_ciechan\u00e3\u00b3w": "Higher School of Business and Management in Ciechan\u00c3\u00b3w"
        },
        {
            "higher_school_of_entrepreneurship_prince_of_kazimierz_kujawski": "Higher School of Entrepreneurship Prince of Kazimierz Kujawski"
        },
        {
            "higher_medical_school_of_podkowa_lesna": "Higher Medical School of Podkowa Lesna"
        },
        {
            "humanistic_higher_school_in_leszno": "Humanistic Higher School in Leszno"
        },
        {
            "wsm_warsaw_faculty_of_management_in_ciechan\u00e3\u00b3w": "WSM Warsaw Faculty of Management in Ciechan\u00c3\u00b3w"
        },
        {
            "higher_school_of_engineering_economy_in_s\u00e5\u201aupsk": "Higher School of Engineering Economy in S\u00c5\u201aupsk"
        },
        {
            "higher_school_of_dentist_engineering_in_ustro\u00e5\u201e": "Higher School of Dentist Engineering in Ustro\u00c5\u201e"
        },
        {
            "federation_of_the_university_of_warsaw_agglomeration": "Federation of the University of Warsaw Agglomeration"
        },
        {
            "college_school_of_economics_and_arts_in_skierniewice": "College School of Economics and Arts in Skierniewice"
        }
    ],
    "saudi arabia": [
        {
            "king_saud_university": "King Saud University"
        },
        {
            "king_abdullah_university_of_science_&_technology": "King Abdullah University of Science & Technology"
        },
        {
            "king_abdulaziz_university": "King Abdulaziz University"
        },
        {
            "king_fahd_university_of_petroleum_&_minerals": "King Fahd University of Petroleum & Minerals"
        },
        {
            "umm_al_qura_university": "Umm Al Qura University"
        },
        {
            "king_khalid_university": "King Khalid University"
        },
        {
            "imam_abdulrahman_bin_faisal_university_(university_of_dammam)": "Imam Abdulrahman Bin Faisal University (University of Dammam)"
        },
        {
            "taibah_university": "Taibah University"
        },
        {
            "king_faisal_university": "King Faisal University"
        },
        {
            "al_imam_muhammad_ibn_saud_islamic_university": "Al Imam Muhammad Ibn Saud Islamic University"
        },
        {
            "taif_university": "Taif University"
        },
        {
            "qassim_university": "Qassim University"
        },
        {
            "null": null
        },
        {
            "majmaah_university": "Majmaah University"
        },
        {
            "jazan_university": "Jazan University"
        },
        {
            "alfaisal_university": "Alfaisal University"
        },
        {
            "prince_sultan_university": "Prince Sultan University"
        },
        {
            "university_of_ha'il": "University of Ha'il"
        },
        {
            "prince_mohammad_bin_fahd_university": "Prince Mohammad Bin Fahd University"
        },
        {
            "najran_university": "Najran University"
        },
        {
            "university_of_jeddah": "University of Jeddah"
        },
        {
            "al_jouf_university": "Al Jouf University"
        },
        {
            "princess_nourah_bint_abdurahman_university": "Princess Nourah BINT ABDURAHMAN University"
        },
        {
            "shaqra_university": "Shaqra University"
        },
        {
            "islamic_university_of_al_madinah": "Islamic University of Al Madinah"
        },
        {
            "effat_university": "Effat University"
        },
        {
            "saudi_electronic_university": "Saudi Electronic University"
        },
        {
            "university_of_northern_border": "University of Northern Border"
        },
        {
            "king_saud_bin_abdulaziz_university_for_health_sciences": "King Saud bin Abdulaziz University for Health Sciences"
        },
        {
            "university_of_bisha": "university of bisha"
        },
        {
            "jubail_industrial_college": "Jubail Industrial College"
        },
        {
            "institute_of_public_administration": "Institute of Public Administration"
        },
        {
            "naif_arab_university_for_security_sciences": "Naif Arab University for Security Sciences"
        },
        {
            "university_of_hafr_al_batin": "University of Hafr Al Batin"
        },
        {
            "royal_commission_yanbu_colleges_&_institutes": "Royal Commission Yanbu Colleges & Institutes"
        },
        {
            "riyadh_elm_university_(riyadh_colleges_of_dentistry_and_pharmacy)": "Riyadh Elm University (Riyadh Colleges of Dentistry and Pharmacy)"
        },
        {
            "dar_al_uloom_university": "Dar Al Uloom University"
        },
        {
            "technical_and_vocational_training_corporation_(college_of_technology_at_riyadh)": "Technical and Vocational Training Corporation (College of Technology at Riyadh)"
        },
        {
            "madinah_institute_for_leadership_and_entrepreneurship": "Madinah Institute for Leadership and Entrepreneurship"
        },
        {
            "university_of_business_and_technology": "University of Business and Technology"
        },
        {
            "fahad_bin_sultan_university": "Fahad Bin Sultan University"
        },
        {
            "batterjee_medical_college": "Batterjee Medical College"
        },
        {
            "ibn_sina_national_college_for_medical_studies": "Ibn Sina National College for Medical Studies"
        },
        {
            "jubail_university_college": "Jubail University College"
        },
        {
            "al_yamamah_university": "Al Yamamah University"
        },
        {
            "arab_open_university_saudi_arabia": "Arab Open University Saudi Arabia"
        },
        {
            "almaarefa_university": "Almaarefa University"
        },
        {
            "dar_al_hekma_university": "Dar Al Hekma University"
        },
        {
            "college_of_telecom_&_information": "College of Telecom & Information"
        },
        {
            "riyadh_college_of_technology": "Riyadh College of Technology"
        },
        {
            "sulaiman_alrajhi_colleges": "Sulaiman Alrajhi Colleges"
        },
        {
            "jeddah_international_college": "Jeddah International College"
        },
        {
            "mustaqbal_university_buraydah": "Mustaqbal University Buraydah"
        },
        {
            "ibn_rushd_college_for_management_sciences": "Ibn Rushd College for Management Sciences"
        },
        {
            "buraydah_private_colleges": "Buraydah Private Colleges"
        }
    ],
    "iran": [
        {
            "university_of_tehran": "University of Tehran"
        },
        {
            "tehran_university_of_medical_sciences": "Tehran University of Medical Sciences"
        },
        {
            "shahid_beheshti_university_of_medical_sciences": "Shahid Beheshti University of Medical Sciences"
        },
        {
            "amirkabir_university_of_technology": "Amirkabir University of Technology"
        },
        {
            "sharif_university_of_technology_tehran": "Sharif University of Technology Tehran"
        },
        {
            "tarbiat_modares_university": "Tarbiat Modares University"
        },
        {
            "ferdowsi_university_of_mashhad": "Ferdowsi University of Mashhad"
        },
        {
            "iran_university_of_science_&_technology_tehran": "Iran University of Science & Technology Tehran"
        },
        {
            "isfahan_university_of_technology": "Isfahan University of Technology"
        },
        {
            "shiraz_university": "Shiraz University"
        },
        {
            "mashhad_university_of_medical_sciences": "Mashhad University of Medical Sciences"
        },
        {
            "tabriz_university_of_medical_sciences": "Tabriz University of Medical Sciences"
        },
        {
            "shahid_beheshti_university": "Shahid Beheshti University"
        },
        {
            "iran_university_of_medical_sciences": "Iran University of Medical Sciences"
        },
        {
            "islamic_azad_university_tehran_science_&_research_branch": "Islamic Azad University Tehran Science & Research Branch"
        },
        {
            "nassir_toosi_university_of_technology": "Nassir Toosi University of Technology"
        },
        {
            "university_of_guilan": "University of Guilan"
        },
        {
            "shahid_chamran_university_of_ahvaz": "Shahid Chamran University of Ahvaz"
        },
        {
            "bu_ali_sina_university_hamedan": "Bu Ali Sina University Hamedan"
        },
        {
            "yazd_university": "Yazd University"
        },
        {
            "urmia_university": "Urmia University"
        },
        {
            "kerman_university_of_medical_sciences": "Kerman University of Medical Sciences"
        },
        {
            "islamic_azad_university_mashhad": "Islamic Azad University Mashhad"
        },
        {
            "babol_noshirvani_university_of_technology_noshirvani_institute_of_technology_babol": "Babol Noshirvani University of Technology Noshirvani Institute of Technology Babol"
        },
        {
            "razi_university_kermanshah": "Razi University Kermanshah"
        },
        {
            "hamedan_university_of_medical_sciences": "Hamedan University of Medical Sciences"
        },
        {
            "kharazmi_university_(tarbiat_moallem_university_tehran)": "Kharazmi University (Tarbiat Moallem University Tehran)"
        },
        {
            "university_of_kurdistan_sanandaj": "University of Kurdistan Sanandaj"
        },
        {
            "sahand_university_of_technology_tabriz": "Sahand University of Technology Tabriz"
        },
        {
            "baqiyatallah_medical_sciences_university": "Baqiyatallah Medical Sciences University"
        },
        {
            "arak_university": "Arak University"
        },
        {
            "kashan_university_of_medical_sciences": "Kashan University of Medical Sciences"
        },
        {
            "zanjan_university_of_medical_sciences": "Zanjan University of Medical Sciences"
        },
        {
            "shahrekord_university": "Shahrekord University"
        },
        {
            "islamic_azad_university_tehran_central": "Islamic Azad University Tehran Central"
        },
        {
            "guilan_university_of_medical_sciences_rasht": "Guilan University of Medical Sciences Rasht"
        },
        {
            "university_of_tabriz": "University of Tabriz"
        },
        {
            "shahid_rajaee_teacher_training_university_tehran": "Shahid Rajaee Teacher Training University Tehran"
        },
        {
            "urmia_university_of_medical_sciences": "Urmia University of Medical Sciences"
        },
        {
            "shiraz_university_of_technology": "Shiraz University of Technology"
        },
        {
            "university_of_lorestan_khorramabad": "University of Lorestan Khorramabad"
        },
        {
            "institute_for_advanced_studies_in_basic_sciences": "Institute for Advanced Studies in Basic Sciences"
        },
        {
            "arak_university_of_medical_sciences": "Arak University of Medical Sciences"
        },
        {
            "payam_noor_university": "Payam Noor University"
        },
        {
            "azarbaijan_shahid_madani_university_tarbiat_moallem": "Azarbaijan Shahid Madani University Tarbiat Moallem"
        },
        {
            "bushehr_university_of_medical_sciences": "Bushehr University of Medical Sciences"
        },
        {
            "birjand_university_of_medical_sciences": "Birjand University of Medical Sciences"
        },
        {
            "zahedan_university_of_medical_sciences": "Zahedan University of Medical Sciences"
        },
        {
            "lorestan_university_of_medical_sciences_khorramabad": "Lorestan University of Medical Sciences Khorramabad"
        },
        {
            "islamic_azad_university_qazvin": "Islamic Azad University Qazvin"
        },
        {
            "babol_university_of_medical_sciences": "Babol University of Medical Sciences"
        },
        {
            "kermanshah_university_of_technology": "Kermanshah University of Technology"
        },
        {
            "ardabil_university_of_medical_sciences": "Ardabil University of Medical Sciences"
        },
        {
            "university_of_isfahan": "University of Isfahan"
        },
        {
            "rafsanjan_university_of_medical_sciences": "Rafsanjan University of Medical Sciences"
        },
        {
            "qazvin_university_of_medical_sciences": "Qazvin University of Medical Sciences"
        },
        {
            "yasuj_university_of_medical_sciences": "Yasuj University of Medical Sciences"
        },
        {
            "golestan_university": "Golestan University"
        },
        {
            "malek_ashtar_university_of_technology_shahinshahr": "Malek Ashtar University of Technology Shahinshahr"
        },
        {
            "golestan_university_of_medical_sciences": "Golestan University of Medical Sciences"
        },
        {
            "university_of_maragheh": "University of Maragheh"
        },
        {
            "university_of_bojnourd": "University of Bojnourd"
        },
        {
            "valiasr_university_of_rafsanjan": "Valiasr University of Rafsanjan"
        },
        {
            "shahrood_medical_science_university": "Shahrood Medical Science University"
        },
        {
            "urmia_university_of_technology": "Urmia University of Technology"
        },
        {
            "sabzevar_university_of_medical_sciences": "Sabzevar University of Medical Sciences"
        },
        {
            "mazandaran_university_of_medical_sciences": "Mazandaran University of Medical Sciences"
        },
        {
            "aja_(army)_university_of_medical_sciences": "Aja (Army) University of Medical Sciences"
        },
        {
            "imam_khomeini_international_university_qazvin": "Imam Khomeini International University Qazvin"
        },
        {
            "fasa_university_of_medical_sciences": "Fasa University of Medical Sciences"
        },
        {
            "islamic_azad_university_lahijan": "Islamic Azad University Lahijan"
        },
        {
            "university_of_imam_hossein": "University of Imam Hossein"
        },
        {
            "shahid_sadoughi_university_of_medical_sciences": "Shahid Sadoughi University of Medical Sciences"
        },
        {
            "sirjan_university_of_technology": "Sirjan University of Technology"
        },
        {
            "islamic_azad_university_sari": "Islamic Azad University Sari"
        },
        {
            "qom_university": "Qom University"
        },
        {
            "north_khorasan_college_of_medical_sciences": "North Khorasan College of Medical Sciences"
        },
        {
            "islamic_azad_university_south_tehran": "Islamic Azad University South Tehran"
        },
        {
            "university_of_sistan_and_baluchestan": "University of Sistan and Baluchestan"
        },
        {
            "islamic_azad_university_shahrekord": "Islamic Azad University Shahrekord"
        },
        {
            "islamic_azad_university_yazd": "Islamic Azad University Yazd"
        },
        {
            "islamic_azad_university_tabriz": "Islamic Azad University Tabriz"
        },
        {
            "allameh_tabatabai_university": "Allameh Tabatabai University"
        },
        {
            "shahed_university": "Shahed University"
        },
        {
            "islamic_azad_university_hamedan": "Islamic Azad University Hamedan"
        },
        {
            "university_of_science_and_culture": "University of Science and Culture"
        },
        {
            "gorgan_university_of_agricultural_sciences_and_natural_resources": "Gorgan University of Agricultural Sciences and Natural Resources"
        },
        {
            "islamic_azad_university_shahreza": "Islamic Azad University Shahreza"
        },
        {
            "shahrekord_university_of_medical_sciences": "Shahrekord University of Medical Sciences"
        },
        {
            "islamic_azad_university_urmia": "Islamic Azad University Urmia"
        },
        {
            "university_of_social_welfare_and_rehabilitation_sciences_tehran": "University of Social Welfare and Rehabilitation Sciences Tehran"
        },
        {
            "hamadan_university_of_technology": "Hamadan University of Technology"
        },
        {
            "jahrom_university_of_medical_sciences": "Jahrom University of Medical Sciences"
        },
        {
            "kurdistan_university_of_medical_sciences_sanandaj": "Kurdistan University of Medical Sciences Sanandaj"
        },
        {
            "university_of_petroleum_technology_abadan": "University of Petroleum Technology Abadan"
        },
        {
            "islamic_azad_university_kerman": "Islamic Azad University Kerman"
        },
        {
            "qom_university_of_medical_sciences": "Qom University of Medical Sciences"
        },
        {
            "islamic_azad_university_dezful": "Islamic Azad University Dezful"
        },
        {
            "university_of_birjand": "University of Birjand"
        },
        {
            "dezful_university_of_medical_sciences": "Dezful University of Medical Sciences"
        },
        {
            "mazandaran_university_of_sciences_and_technology_babol": "Mazandaran University of Sciences and Technology Babol"
        },
        {
            "islamic_azad_university_karaj": "Islamic Azad University Karaj"
        },
        {
            "neyshabur_university_of_medical_sciences": "Neyshabur University of Medical Sciences"
        },
        {
            "alborz_university_of_medical_sciences": "Alborz University of Medical Sciences"
        },
        {
            "islamic_azad_university_saveh": "Islamic Azad University Saveh"
        },
        {
            "torbat_heydarie_university_of_medical_science": "Torbat Heydarie University of Medical Science"
        },
        {
            "islamic_azad_university_qaemshahr": "Islamic Azad University Qaemshahr"
        },
        {
            "islamic_azad_university_tehran_medical_sciences": "Islamic Azad University Tehran Medical Sciences"
        },
        {
            "islamic_azad_university_west_tehran": "Islamic Azad University West Tehran"
        },
        {
            "islamic_azad_university_shahrood": "Islamic Azad University Shahrood"
        },
        {
            "islamic_azad_university_alcohol": "Islamic azad university alcohol"
        },
        {
            "hormozgan_university_of_medical_sciences_city_abbas": "Hormozgan University of Medical Sciences City Abbas"
        },
        {
            "islamic_azad_university_ayatollah_amoli": "Islamic Azad University Ayatollah Amoli"
        },
        {
            "islamic_azad_university_tehran_shahr_rey": "Islamic Azad University Tehran Shahr Rey"
        },
        {
            "gonabad_university_of_medical_sciences": "Gonabad University of Medical Sciences"
        },
        {
            "islamic_azad_university_roudehen": "Islamic Azad University Roudehen"
        },
        {
            "islamic_azad_university_tehran_north": "Islamic Azad University Tehran North"
        },
        {
            "islamic_azad_university_ahar": "Islamic Azad University Ahar"
        },
        {
            "abadan_university_of_medical_sciences": "Abadan University of Medical Sciences"
        },
        {
            "islamic_azad_university_rasht": "Islamic Azad University Rasht"
        },
        {
            "islamic_azad_university_bushehr": "Islamic Azad University Bushehr"
        },
        {
            "university_of_hormozgan_city_abbas": "University of Hormozgan City Abbas"
        },
        {
            "university_of_bonab": "University of Bonab"
        },
        {
            "islamic_azad_university_tonekabon": "Islamic Azad University Tonekabon"
        },
        {
            "the_university_of_technology": "The University of Technology"
        },
        {
            "university_of_jiroft": "University of Jiroft"
        },
        {
            "islamic_azad_university_zanjan": "Islamic Azad University Zanjan"
        },
        {
            "islamic_azad_university_city_abbas": "Islamic Azad University City Abbas"
        },
        {
            "islamic_azad_university_parand": "Islamic Azad University Parand"
        },
        {
            "islamic_azad_university_ardabil": "Islamic Azad University Ardabil"
        },
        {
            "islamic_azad_university_shabestar": "Islamic Azad University Shabestar"
        },
        {
            "islamic_azad_university_sanandaj": "Islamic Azad University Sanandaj"
        },
        {
            "islamic_azad_university_islamshahr": "Islamic Azad University Islamshahr"
        },
        {
            "islamic_azad_university_birjand": "Islamic Azad University Birjand"
        },
        {
            "islamic_azad_university_quchan": "Islamic Azad University Quchan"
        },
        {
            "iran_polymer_and_petrochemical_institute": "Iran Polymer and Petrochemical Institute"
        },
        {
            "international_institute_of_earthquake_engineering_and_seismology": "International Institute of Earthquake Engineering and Seismology"
        },
        {
            "islamic_azad_university_gachsaran": "Islamic Azad University Gachsaran"
        },
        {
            "islamic_azad_university_mahabad": "Islamic Azad University Mahabad"
        },
        {
            "bam_university_of_medical_sciences": "Bam University of Medical Sciences"
        },
        {
            "maragheh_university_of_medical_sciences": "Maragheh University of Medical Sciences"
        },
        {
            "islamic_azad_university_zahedan": "Islamic Azad University Zahedan"
        },
        {
            "islamic_azad_university_semnan": "Islamic Azad University Semnan"
        },
        {
            "islamic_azad_university_damghan": "Islamic Azad University Damghan"
        },
        {
            "islamic_azad_university_damavand": "Islamic Azad University Damavand"
        },
        {
            "islamic_azad_university_garmsar": "Islamic Azad University Garmsar"
        },
        {
            "islamic_azad_university_jahrom": "Islamic Azad University Jahrom"
        },
        {
            "farhangyan_university": "Farhangyan University"
        },
        {
            "islamic_azad_university_gorgan": "Islamic Azad University Gorgan"
        },
        {
            "islamic_azad_university_ilam": "Islamic Azad University Ilam"
        },
        {
            "technical_and_vocational_university_(shahid_shamsipour_university)": "Technical and Vocational University (Shahid Shamsipour University)"
        },
        {
            "islamic_azad_university_abhar": "Islamic Azad University Abhar"
        },
        {
            "university_of_malayer": "University of Malayer"
        },
        {
            "institute_for_materials_and_energy_sharif_university_of_technology": "Institute for Materials and Energy Sharif University of Technology"
        },
        {
            "kerman_graduate_university_of_technology": "Kerman Graduate University of Technology"
        },
        {
            "islamic_azad_university_takestan": "Islamic Azad University Takestan"
        },
        {
            "islamic_azad_university_khoy": "Islamic Azad University Khoy"
        },
        {
            "university_of_gonbad_e_qabus": "University of Gonbad e Qabus"
        },
        {
            "islamic_azad_university_falavarjan": "Islamic Azad University Falavarjan"
        },
        {
            "islamic_azad_university_azadshahr": "Islamic Azad University Azadshahr"
        },
        {
            "islamic_azad_university_myianeh": "Islamic Azad University Myianeh"
        },
        {
            "tabriz_islamic_arts_university": "Tabriz Islamic Arts University"
        },
        {
            "islamic_azad_university": "Islamic Azad University"
        },
        {
            "islamic_azad_university_arsanjan": "Islamic Azad University Arsanjan"
        },
        {
            "art_university_of_isfahan": "Art University of Isfahan"
        },
        {
            "university_of_applied_science_&_technology_tehran": "University of Applied Science & Technology Tehran"
        },
        {
            "jundi_shapur_university_of_technology_dezful": "Jundi Shapur University of Technology Dezful"
        },
        {
            "tafresh_university": "Tafresh University"
        },
        {
            "university_of_art_tehran": "University of Art Tehran"
        },
        {
            "islamic_azad_university_pardis": "Islamic Azad University Pardis"
        },
        {
            "imam_sadiq_university": "Imam Sadiq University"
        },
        {
            "quchan_university_of_technology": "Quchan University of Technology"
        },
        {
            "ardakan_university": "Ardakan University"
        },
        {
            "islamic_azad_university_aliabad_kabad": "Islamic Azad University Aliabad Kabad"
        },
        {
            "islamic_azad_university_omidiyeh": "Islamic Azad University Omidiyeh"
        },
        {
            "birjand_university_of_technology": "Birjand University of Technology"
        },
        {
            "islamic_azad_university_norabad": "Islamic AZAD University Norabad"
        },
        {
            "islamic_azad_university_bojnourd": "Islamic Azad University Bojnourd"
        },
        {
            "al_mustafa_international_university": "Al Mustafa International University"
        },
        {
            "islamic_azad_university_naein": "Islamic Azad University Naein"
        },
        {
            "phase_university": "Phase University"
        },
        {
            "islamic_azad_university_tuyserkan": "Islamic Azad University Tuyserkan"
        },
        {
            "university_of_nishapur": "University of Nishapur"
        },
        {
            "university_of_torbat_heydarieh": "University of Torbat Heydarieh"
        },
        {
            "islamic_azad_university_e_campus": "Islamic Azad University E Campus"
        },
        {
            "road_housing_&_urban_development_research_center": "Road Housing & Urban Development Research Center"
        },
        {
            "islamic_azad_university_mobarakeh": "Islamic Azad University Mobarakeh"
        },
        {
            "islamic_azad_university_chalus": "Islamic Azad University Chalus"
        },
        {
            "islamic_azad_university_kashan": "Islamic Azad University Kashan"
        },
        {
            "sadjad_institute_of_higher_education_mashhad": "Sadjad Institute of Higher Education Mashhad"
        },
        {
            "mazandaran_university_of_science_and_technology": "Mazandaran University of Science and Technology"
        },
        {
            "islamic_azad_university_gonabad": "Islamic Azad University Gonabad"
        },
        {
            "ayatolah_borujerdei_university": "Ayatolah Borujerdei University"
        },
        {
            "industrial_management_institute": "Industrial Management Institute"
        },
        {
            "islamic_sciences_and_culture_academy": "Islamic Sciences and Culture Academy"
        },
        {
            "imam_khomeini_education_and_research_institute": "Imam Khomeini Education and Research Institute"
        },
        {
            "islamic_azad_university_sepidan": "Islamic Azad University Sepidan"
        },
        {
            "amol_university_of_technology": "Amol University of Technology"
        },
        {
            "mofid_university_qom_(ghom)": "Mofid University Qom (Ghom)"
        },
        {
            "islamic_free_university_astara": "Islamic Free University Astara"
        },
        {
            "high_institute_for_research_and_education_in_transfusion_medicine": "High Institute for Research and Education in Transfusion Medicine"
        },
        {
            "islamic_azad_university_abadan": "Islamic Azad University Abadan"
        },
        {
            "islamic_azad_university_marand": "Islamic Azad University Marand"
        },
        {
            "school_of_international_relations": "School of International Relations"
        },
        {
            "islamic_azad_university_andimeshk": "Islamic Azad University Andimeshk"
        },
        {
            "al_zahra_university": "Al Zahra university"
        },
        {
            "buein_zahra_technical_university": "Buein Zahra Technical University"
        },
        {
            "soore_universityity": "Soore universityity"
        },
        {
            "nabi_akram_nstitute_of_higher_education": "Nabi Akram nstitute of Higher Education"
        },
        {
            "islamic_menheb_university": "Islamic menheb University"
        },
        {
            "larestan_university_of_medical_sciences": "Larestan University of Medical Sciences"
        },
        {
            "university_of_more_alborence": "University of more Alborence"
        },
        {
            "islamic_azad_university_shirvan": "Islamic Azad University Shirvan"
        },
        {
            "imam_sadiq_university_women_section": "Imam Sadiq University Women Section"
        },
        {
            "islamic_azad_university_babol": "Islamic Azad University Babol"
        },
        {
            "islamic_azad_university_bonab": "Islamic Azad University Bonab"
        },
        {
            "bahar_university_(institute_of_higher_education)": "Bahar University (Institute of Higher Education)"
        },
        {
            "king_of_university": "King of University"
        },
        {
            "islamic_azad_university_firuzabad": "Islamic Azad University Firuzabad"
        },
        {
            "holy_quran_university_of_science_and_education": "Holy Quran University of Science and Education"
        },
        {
            "arjang_institute_of_higher_education": "Arjang Institute of Higher Education"
        },
        {
            "islamic_azad_university_sarvestan": "Islamic Azad University Sarvestan"
        },
        {
            "university_of_martyr_motahari": "University of Martyr Motahari"
        },
        {
            "nooretouba_virtual_university_tehran": "Nooretouba Virtual University Tehran"
        },
        {
            "islamic_azad_university_fasa": "Islamic Azad University Fasa"
        },
        {
            "tabaran_institute_of_higher_education": "Tabaran Institute of Higher Education"
        },
        {
            "islamic_kar_university": "Islamic Kar University"
        },
        {
            "islamic_azad_university_dehaghan": "Islamic Azad University Dehaghan"
        },
        {
            "shahreza_higher_education_center": "Shahreza Higher Education Center"
        },
        {
            "islamic_azad_university_piece": "Islamic Azad University piece"
        },
        {
            "islamic_azad_university_parsabad_moghan": "Islamic Azad University Parsabad Moghan"
        },
        {
            "bahar_business_school": "Bahar Business School"
        },
        {
            "islamic_azad_university_langaroud": "Islamic Azad University Langaroud"
        },
        {
            "islamic_azad_university_bardsir": "Islamic Azad University Bardsir"
        },
        {
            "hazrat-eyumeh_university_qom": "Hazrat-eyumeh University Qom"
        },
        {
            "islamic_azad_university_north_no": "Islamic Azad University North No"
        },
        {
            "shandiz_institute_of_higher_education": "Shandiz Institute of Higher Education"
        },
        {
            "shariaty_technical_college": "Shariaty Technical College"
        },
        {
            "seraj_university_(palmer_insitute_of_higher_education_tabriz)": "Seraj University (Palmer Insitute of Higher Education Tabriz)"
        },
        {
            "international_university_of_iran": "International University of Iran"
        },
        {
            "islamic_azad_university_savadkooh": "Islamic Azad University Savadkooh"
        },
        {
            "islamic_azad_university_farahan": "Islamic Azad University Farahan"
        },
        {
            "islamic_azad_university_golpayegan": "Islamic Azad University Golpayegan"
        },
        {
            "islamic_azad_university_khorramabad": "Islamic Azad University Khorramabad"
        },
        {
            "islamic_azad_university_rafsanjan": "Islamic Azad University Rafsanjan"
        },
        {
            "islamic_azad_university_ashtian": "Islamic Azad University Ashtian"
        },
        {
            "kosar_university": "Kosar University"
        },
        {
            "tous_institute_of_higher_education": "Tous Institute of Higher Education"
        },
        {
            "islamic_azad_university_abarkouh": "Islamic Azad University Abarkouh"
        },
        {
            "qeshm_institute_of_higher_education_qeshm_&_tehran": "Qeshm Institute of Higher Education Qeshm & Tehran"
        },
        {
            "sadra_university": "Sadra University"
        },
        {
            "valued_institute_of_higher_education": "Valued Institute of Higher Education"
        },
        {
            "science_and_arts_university_(jahad_daneshgahi_university_yazd)": "Science and Arts University (Jahad Daneshgahi University Yazd)"
        },
        {
            "shahab_danesh_university": "Shahab Danesh University"
        },
        {
            "daneshpajoohan_higher_education_institution": "Daneshpajoohan Higher Education Institution"
        },
        {
            "ahl_al-bayt_university": "Ahl al-Bayt University"
        },
        {
            "attar_institute_of_higher_education": "Attar Institute of Higher Education"
        },
        {
            "islamic_azad_university_mahdishahr": "Islamic Azad University Mahdishahr"
        },
        {
            "sabz_institute_of_higher_education": "Sabz Institute of Higher Education"
        },
        {
            "kavian_higher_education_institute": "Kavian Higher Education Institute"
        },
        {
            "mashhad_institute_of_higher_education": "Mashhad Institute of Higher Education"
        },
        {
            "technical_and_professional_university_of_west_azerbaijan_province": "Technical and Professional University of West Azerbaijan Province"
        },
        {
            "alborz_university": "Alborz University"
        },
        {
            "islamic_azad_university_dolatabad": "Islamic Azad University Dolatabad"
        },
        {
            "bam_university": "Bam University"
        },
        {
            "institute_for_higher_education_acecr_khuzestan": "Institute for Higher Education ACECR Khuzestan"
        },
        {
            "varastegan_institute_for_medical_sciences": "Varastegan Institute for Medical Sciences"
        },
        {
            "islamic_azad_university_ardestan": "Islamic Azad University Ardestan"
        },
        {
            "islamic_azad_university_torbat_jaam": "Islamic Azad university Torbat Jaam"
        },
        {
            "islamic_azad_university_taft": "Islamic Azad University Taft"
        },
        {
            "institute_of_higher_education_antunan": "Institute of Higher Education Antunan"
        },
        {
            "islamic_azad_universityb": "Islamic Azad Universityb"
        },
        {
            "islamic_azad_university_saghez": "Islamic Azad University Saghez"
        },
        {
            "faith_jawad_university_college": "Faith Jawad University College"
        },
        {
            "sayyed_jamaleddin_al-abadi_university": "Sayyed Jamaleddin Al-Abadi University"
        },
        {
            "allame_mohaddes_noori_university": "Allame Mohaddes Noori University"
        },
        {
            "institute_of_higher_education_khorasan": "Institute of Higher Education Khorasan"
        },
        {
            "ahrar_rasht_higher_education_institute": "Ahrar Rasht Higher Education Institute"
        },
        {
            "islamic_azad_university_ajabshir": "Islamic Azad University Ajabshir"
        },
        {
            "shahid_sattari_air_university": "Shahid Sattari Air University"
        },
        {
            "alaodoleh_semnani_institute_of_higher_education": "Alaodoleh Semnani Institute of Higher Education"
        },
        {
            "islamic_azad_university_bafgh": "Islamic Azad University Bafgh"
        },
        {
            "khalakha_university_of_medical_sciences": "Khalakha University of Medical Sciences"
        },
        {
            "larestan_university": "Larestan University"
        },
        {
            "rasam_art_higher_education_institute": "Rasam Art Higher Education Institute"
        },
        {
            "sabzevar_university_of_new_technology": "Sabzevar University of New Technology"
        },
        {
            "university_kamal_ol_silk": "University Kamal OL silk"
        },
        {
            "institute_of_higher_education_north_roozbeh": "Institute of Higher Education North Roozbeh"
        },
        {
            "apadana_institute_of_higher_education": "Apadana Institute of Higher Education"
        },
        {
            "naser_khosrow_institute_of_higher_education": "Naser Khosrow Institute of Higher Education"
        },
        {
            "pooyesh_higher_education_institute": "Pooyesh Higher Education Institute"
        },
        {
            "islamic_azad_university_tehran_branch_34": "Islamic Azad University Tehran Branch 34"
        },
        {
            "islamic_azad_university_city_of_gaz": "Islamic Azad University City of Gaz"
        },
        {
            "islamic_azad_university_gonbad_kavoos": "Islamic Azad University Gonbad Kavoos"
        },
        {
            "islamic_azad_university_germs": "Islamic Azad University germs"
        },
        {
            "islamic_azad_university_meymeh": "Islamic Azad University Meymeh"
        },
        {
            "mr._mindamad_university_of_gorgan": "Mr. Mindamad University of Gorgan"
        },
        {
            "modat_higher_education_instittion": "Modat higher education instittion"
        },
        {
            "ferdows_university": "Ferdows University"
        },
        {
            "islamic_azad_university_shahr_e_babak": "Islamic Azad University Shahr e Babak"
        },
        {
            "islamic_azad_university_baft": "Islamic Azad University Baft"
        },
        {
            "roozbeh_institute_of_higher_education": "Roozbeh Institute of Higher Education"
        },
        {
            "islamic_azad_university_behbahan": "Islamic Azad University Behbahan"
        },
        {
            "jihad_kermanshah_university": "Jihad Kermanshah University"
        },
        {
            "islamic_azad_university_multiply": "Islamic Azad University Multiply"
        },
        {
            "roshdiyeh_higher_education_institute_of_tabriz": "Roshdiyeh Higher Education Institute of Tabriz"
        },
        {
            "islamic_azad_university_naghadeh": "Islamic Azad University Naghadeh"
        },
        {
            "islamic_azad_university_hurry": "Islamic Azad University Hurry"
        },
        {
            "islamic_azad_university_komijan": "Islamic Azad University Komijan"
        },
        {
            "islamic_azad_university_beileh_savar_moghan": "Islamic Azad University Beileh Savar Moghan"
        },
        {
            "islamic_azad_university_hashtroud": "Islamic Azad University Hashtroud"
        },
        {
            "islamic_azad_university_farm": "Islamic Azad University Farm"
        },
        {
            "islamic_azad_university_jasb": "Islamic Azad University Jasb"
        },
        {
            "kashmar_jihad_university.": "Kashmar Jihad University."
        },
        {
            "ghiasuddin_jamshidkashani_university": "Ghiasuddin Jamshidkashani University"
        },
        {
            "khoy_school_of_medical_sciences": "Khoy School of Medical Sciences"
        },
        {
            "lamerd_higher_education_center": "Lamerd Higher Education Center"
        },
        {
            "aria_university_of_sciences_and_sustainability": "Aria University of Sciences and Sustainability"
        },
        {
            "islamic_azad_university_harand": "Islamic Azad University Harand"
        },
        {
            "rahman_ramsar_higher_education_institute": "Rahman Ramsar Higher Education Institute"
        },
        {
            "vahdat_torbat_jam_higher_education_institute": "Vahdat Torbat Jam Higher Education Institute"
        },
        {
            "kavosh_institute_of_higher_education": "Kavosh Institute of Higher Education"
        },
        {
            "eghlid_higher_education_center": "Eghlid Higher Education Center"
        },
        {
            "nima_institute_of_higher_education": "Nima Institute of Higher Education"
        },
        {
            "narjes_womens_islamic_seminary": "Narjes Womens Islamic Seminary"
        },
        {
            "islamic_azad_university_beh_shahr": "Islamic Azad University Beh Shahr"
        },
        {
            "sana_institute_of_higher_education": "Sana Institute of Higher Education"
        },
        {
            "sobhesadegh_university": "Sobhesadegh University"
        },
        {
            "institute_of_higher_education_eram_shiraz": "Institute of Higher Education Eram Shiraz"
        },
        {
            "khajeh_nasir_university": "Khajeh Nasir University"
        },
        {
            "payam_noor_university_khaf": "Payam Noor University Khaf"
        },
        {
            "islamic_azad_university_azarshahr": "Islamic Azad University Azarshahr"
        },
        {
            "islamic_azad_university_gerash": "Islamic Azad University Gerash"
        },
        {
            "more_institute_of_higher_education": "More Institute of Higher Education"
        },
        {
            "parsa_institute_of_higher_education": "Parsa Institute of Higher Education"
        },
        {
            "institute_for_higher_education_golpaygan": "Institute for Higher Education Golpaygan"
        },
        {
            "alghadir_university": "Alghadir University"
        },
        {
            "islamic_azad_university_taybad": "Islamic Azad University Taybad"
        },
        {
            "islamic_azad_university_khatam": "Islamic Azad University Khatam"
        }
    ],
    "hungary": [
        {
            "e\u00e3\u00b6tv\u00e3\u00b6s_lor\u00e3\u00a1nd_university_budapest": "E\u00c3\u00b6tv\u00c3\u00b6s Lor\u00c3\u00a1nd University Budapest"
        },
        {
            "budapest_university_of_technology_and_economics": "Budapest University of Technology and Economics"
        },
        {
            "university_of_szeged": "University of Szeged"
        },
        {
            "university_of_debrecen": "University of Debrecen"
        },
        {
            "central_european_university_budapest": "Central European University Budapest"
        },
        {
            "university_of_p\u00e3\u00a9cs": "University of P\u00c3\u00a9cs"
        },
        {
            "corvinus_university_of_budapest": "Corvinus University of Budapest"
        },
        {
            "university_of_pannonia_(university_of_veszprem)": "University of Pannonia (University of Veszprem)"
        },
        {
            "university_of_miskolc": "University of Miskolc"
        },
        {
            "semmelweis_university_budapest": "Semmelweis University Budapest"
        },
        {
            "obuda_university_(budapest_polytechnic)": "obuda University (Budapest Polytechnic)"
        },
        {
            "p\u00e3\u00a1zm\u00e3\u00a1ny_p\u00e3\u00a9ter_catholic_university_budapest": "P\u00c3\u00a1zm\u00c3\u00a1ny P\u00c3\u00a9ter Catholic University Budapest"
        },
        {
            "sz\u00e3\u00a9chenyi_istv\u00e3\u00a1n_university_gy\u00e5\u2018r": "Sz\u00c3\u00a9chenyi Istv\u00c3\u00a1n University Gy\u00c5\u2018r"
        },
        {
            "hungarian_university_of_agriculture_and_life_sciences": "Hungarian University of Agriculture and Life Sciences"
        },
        {
            "university_of_veterinary_medicine_budapest": "University of Veterinary Medicine Budapest"
        },
        {
            "eszterh\u00e3\u00a1zy_k\u00e3\u00a1roly_university_eger": "Eszterh\u00c3\u00a1zy K\u00c3\u00a1roly University Eger"
        },
        {
            "national_university_of_public_service": "National University of Public Service"
        },
        {
            "university_of_sopron_(university_of_west_hungary)": "University of Sopron (University of West Hungary)"
        },
        {
            "university_of_nyiregyhaza": "University of Nyiregyhaza"
        },
        {
            "budapest_business_school": "Budapest Business School"
        },
        {
            "k\u00e3\u00a1roli_g\u00e3\u00a1sp\u00e3\u00a1r_university_of_the_reformed_church_budapest": "K\u00c3\u00a1roli G\u00c3\u00a1sp\u00c3\u00a1r University of the Reformed Church Budapest"
        },
        {
            "moholy-nagy_university_of_art_and_design_budapest": "Moholy-Nagy University of Art and Design Budapest"
        },
        {
            "hungarian_academy_of_fine_arts_budapest": "Hungarian Academy of Fine Arts Budapest"
        },
        {
            "liszt_ferenc_academy_of_music_(university)_budapest": "Liszt Ferenc Academy of Music (University) Budapest"
        },
        {
            "international_business_school_budapest_(budapest_college_of_management)": "International Business School Budapest (Budapest College of Management)"
        },
        {
            "budapest_metropolitan_university": "Budapest Metropolitan University"
        },
        {
            "kodol\u00e3\u00a1nyi_j\u00e3\u00a1nos_university_college_sz\u00e3\u00a9kesfeh\u00e3\u00a9rv\u00e3\u00a1r": "Kodol\u00c3\u00a1nyi J\u00c3\u00a1nos University College Sz\u00c3\u00a9kesfeh\u00c3\u00a9rv\u00c3\u00a1r"
        },
        {
            "andr\u00e3\u00a1ssy_universit\u00e3\u00a4t_budapest_aub": "Andr\u00c3\u00a1ssy Universit\u00c3\u00a4t Budapest AUB"
        },
        {
            "st._athanasius_greek_catholic_college_of_religious_studies": "St. Athanasius Greek Catholic College of Religious Studies"
        },
        {
            "john_von_neumann_university": "John von Neumann University"
        },
        {
            "university_of_physical_education": "University of Physical Education"
        },
        {
            "university_of_drama_and_film_budapest": "University of Drama and Film Budapest"
        },
        {
            "debrecen_reformed_college": "Debrecen Reformed College"
        },
        {
            "e\u00e3\u00b6tv\u00e3\u00b6s_j\u00e3\u00b3zsef_college_baja": "E\u00c3\u00b6tv\u00c3\u00b6s J\u00c3\u00b3zsef College Baja"
        },
        {
            "university_of_dunaujvarosiegyekem": "University of Dunaujvarosiegyekem"
        },
        {
            "jewish_theological_seminary_university_of_jewish_studies_budapest": "Jewish Theological Seminary University of Jewish Studies Budapest"
        },
        {
            "john_wesley_theological_college_budapest": "John Wesley Theological College Budapest"
        },
        {
            "mcdaniel_college_budapest": "McDaniel College Budapest"
        },
        {
            "s\u00e3\u00a1rospatak_theological_academy_of_the_reformed_church": "S\u00c3\u00a1rospatak Theological Academy of the Reformed Church"
        },
        {
            "sapientia_shool_of_theology_budapest": "Sapientia Shool of Theology Budapest"
        },
        {
            "bhaktivedanta_college_budapest": "Bhaktivedanta College Budapest"
        },
        {
            "the_gate_of_the_teaching_buddhist_college_budapest": "The Gate of the Teaching Buddhist College Budapest"
        },
        {
            "szent_bern\u00e3\u00a1t_theological_college_zirc": "Szent Bern\u00c3\u00a1t Theological College Zirc"
        },
        {
            "milton_friedman_university": "Milton Friedman University"
        },
        {
            "theological_college_of_p\u00e3\u00a9cs": "Theological College of P\u00c3\u00a9cs"
        },
        {
            "tomori_p\u00e3\u00a1l_college_kalocsa": "Tomori P\u00c3\u00a1l College Kalocsa"
        },
        {
            "adventist_theological_college_p\u00e3\u00a9cel": "Adventist Theological College P\u00c3\u00a9cel"
        },
        {
            "budapest_contemporary_dance_academy": "Budapest Contemporary Dance Academy"
        },
        {
            "pentecostal_theological_college_budapest": "Pentecostal Theological College Budapest"
        },
        {
            "sola_scriptura_college_of_theology_budapest": "Sola Scriptura College of Theology Budapest"
        },
        {
            "g\u00e3\u00a1l_ferenc_egyetem": "G\u00c3\u00a1l Ferenc Egyetem"
        },
        {
            "archiepiscopal_theological_college_of_veszpr\u00e3\u00a9m": "Archiepiscopal Theological College of Veszpr\u00c3\u00a9m"
        },
        {
            "p\u00e3\u00a1pa_theological_academy_of_the_reformed_church": "P\u00c3\u00a1pa Theological Academy of the Reformed Church"
        },
        {
            "hungarian_academy_of_dance": "Hungarian Academy of Dance"
        },
        {
            "j\u00e3\u00a1nos_brenner_college_of_theology": "J\u00c3\u00a1nos Brenner College of Theology"
        },
        {
            "baptist_theological_seminary_budapest": "Baptist Theological Seminary Budapest"
        },
        {
            "evangelical-lutheran_theological_university_budapest": "Evangelical-Lutheran Theological University Budapest"
        }
    ],
    "estonia": [
        {
            "university_of_tartu": "University of Tartu"
        },
        {
            "tallinn_university_of_technology": "Tallinn University of Technology"
        },
        {
            "tallinn_university": "Tallinn University"
        },
        {
            "estonian_university_of_life_sciences": "Estonian University of Life Sciences"
        },
        {
            "estonian_business_school": "Estonian Business School"
        },
        {
            "national_institute_of_chemical_physics_and_biophysics": "National Institute of Chemical Physics and Biophysics"
        },
        {
            "estonian_academy_of_arts": "Estonian Academy of Arts"
        },
        {
            "estonian_academy_of_music_and_theatre": "Estonian Academy of Music and Theatre"
        },
        {
            "eelk_usutueaduse_instituut.": "Eelk UsuTueaduse Instituut."
        },
        {
            "estonian_information_technology_college": "Estonian Information Technology College"
        },
        {
            "tartu_health_care_college": "Tartu Health Care College"
        },
        {
            "estonian_school_of_diplomacy": "Estonian School of Diplomacy"
        },
        {
            "tallinn_school_of_economics": "Tallinn School of Economics"
        },
        {
            "euro_university": "Euro University"
        },
        {
            "estonian_aviation_academy": "Estonian Aviation Academy"
        },
        {
            "college_of_computer_science": "College of Computer Science"
        },
        {
            "baltic_methodist_theological_seminary": "Baltic Methodist Theological Seminary"
        },
        {
            "theological_seminary_tartu": "Theological Seminary Tartu"
        },
        {
            "higher_school_i_studium": "Higher School I Studium"
        }
    ],
    "serbia": [
        {
            "university_of_belgrade": "University of Belgrade"
        },
        {
            "university_of_novi_sad": "University of Novi Sad"
        },
        {
            "singidunum_university": "Singidunum University"
        },
        {
            "megatrend_university_belgrade": "Megatrend University Belgrade"
        },
        {
            "state_university_of_novi_pazar": "State University of Novi Pazar"
        },
        {
            "educons_university_sremska_kamenica": "Educons University Sremska Kamenica"
        },
        {
            "university_of_defence": "University of Defence"
        },
        {
            "military_academy_serbia": "Military Academy Serbia"
        },
        {
            "university_business_academy_in_novi_sad": "University Business Academy in Novi Sad"
        },
        {
            "alpha_university": "Alpha University"
        },
        {
            "high_business_school_of_vocational_studies_novi_sad": "High Business School of Vocational Studies Novi Sad"
        },
        {
            "metropolitan_university_belgrade": "Metropolitan University Belgrade"
        },
        {
            "university_of_arts": "University of Arts"
        },
        {
            "military_medical_academy_serbia": "Military Medical Academy Serbia"
        },
        {
            "novi_pazar_international_university": "Novi Pazar International University"
        },
        {
            "university_of_criminal_investigation_and_police_studies": "University of Criminal Investigation and Police Studies"
        },
        {
            "european_university_belgrade": "European University Belgrade"
        },
        {
            "metropolitan_university_belgrade_faculty_of_sports_and_tourism_novi_sad": "Metropolitan University Belgrade Faculty of Sports and Tourism Novi Sad"
        },
        {
            "economics_academy_novi_sad": "Economics Academy Novi Sad"
        },
        {
            "union_university_belgrade": "Union University Belgrade"
        },
        {
            "union_university_faculty_of_law": "Union University Faculty of Law"
        },
        {
            "high_technical_school_uzice": "High Technical School Uzice"
        },
        {
            "megatrend_university_faculty_of_management_zajecar": "Megatrend University Faculty of Management Zajecar"
        },
        {
            "singidunum_university_faculty_of_applied_ecology_futura": "Singidunum University Faculty of Applied Ecology Futura"
        }
    ],
    "turkey": [
        {
            "middle_east_technical_university": "Middle East Technical University"
        },
        {
            "istanbul_technical_university": "Istanbul Technical University"
        },
        {
            "hacettepe_university": "Hacettepe University"
        },
        {
            "bosphorus_university": "Bosphorus University"
        },
        {
            "ankara_university": "Ankara University"
        },
        {
            "istanbul_university": "Istanbul University"
        },
        {
            "bilkent_university": "Bilkent University"
        },
        {
            "ege_university": "Ege University"
        },
        {
            "gazi_university": "Gazi University"
        },
        {
            "nine_september_university": "Nine September University"
        },
        {
            "marmara_university": "Marmara University"
        },
        {
            "ataturk_university": "Ataturk University"
        },
        {
            "yildiz_technical_university": "Yildiz Technical University"
        },
        {
            "sabanci_university": "Sabanci University"
        },
        {
            "erciyes_university": "Erciyes University"
        },
        {
            "firat_university": "Firat University"
        },
        {
            "karadeniz_technical_university": "Karadeniz Technical University"
        },
        {
            "selcuk_university": "Selcuk University"
        },
        {
            "eski\u00e5\u00ffehir_osmangazi_university": "Eski\u00c5\u0178ehir Osmangazi University"
        },
        {
            "gebze_technological_university": "Gebze Technological University"
        },
        {
            "kirikkale_university": "Kirikkale University"
        },
        {
            "bah\u00e3\u00a7e\u00e5\u00ffehir_university_bau": "Bah\u00c3\u00a7e\u00c5\u0178ehir University Bau"
        },
        {
            "pamukkale_university": "Pamukkale University"
        },
        {
            "istanbul_aydin_university": "Istanbul Aydin University"
        },
        {
            "mersin_university": "Mersin University"
        },
        {
            "suleyman_demirel_university_turkey": "Suleyman Demirel University Turkey"
        },
        {
            "gaziosmanpa\u00e5\u00ffa_university": "Gaziosmanpa\u00c5\u0178a University"
        },
        {
            "yil_university_of_yil": "Yil University of Yil"
        },
        {
            "mugla_sitki_kocman_university": "Mugla Sitki Kocman University"
        },
        {
            "adnan_menderes_university": "Adnan Menderes University"
        },
        {
            "atilim_university": "Atilim University"
        },
        {
            "istanbul_medipol_university": "Istanbul Medipol University"
        },
        {
            "adiyaman_university": "Adiyaman University"
        },
        {
            "necmettin_erbakan_university_konya": "Necmettin Erbakan University Konya"
        },
        {
            "zonguldak_bulent_ecevit_university": "Zonguldak Bulent Ecevit University"
        },
        {
            "izmir_university_of_economics": "Izmir University of Economics"
        },
        {
            "istanbul_university_of_civilization": "Istanbul University of Civilization"
        },
        {
            "mimar_sinan_fine_arts_university": "Mimar Sinan Fine Arts University"
        },
        {
            "dumlupinar_university": "Dumlupinar University"
        },
        {
            "university_of_anatolia": "University of Anatolia"
        },
        {
            "mustafa_kemal_university": "Mustafa Kemal University"
        },
        {
            "beykent_university": "Beykent University"
        },
        {
            "kadir_has_university": "Kadir Has University"
        },
        {
            "ya\u00e5\u00ffar_university": "Ya\u00c5\u0178ar University"
        },
        {
            "recep_tayyip_erdo\u00e4\u00ffan_university_(rize_university)": "Recep Tayyip Erdo\u00c4\u0178an University (Rize University)"
        },
        {
            "sinop_university": "Sinop University"
        },
        {
            "acibadem_university": "Acibadem University"
        },
        {
            "uskudar_university": "Uskudar University"
        },
        {
            "galatasaray_university": "Galatasaray University"
        },
        {
            "ted_university": "TED University"
        },
        {
            "bezmialem_vakif_university": "Bezmialem Vakif University"
        },
        {
            "abdullah_gul_university_kayseri": "Abdullah Gul University Kayseri"
        },
        {
            "bursa_technical_university": "Bursa Technical University"
        },
        {
            "healh_sciences_university_istanbul": "Healh Sciences University Istanbul"
        },
        {
            "piri_reis_university": "Piri Reis University"
        },
        {
            "maltepe_university": "Maltepe University"
        },
        {
            "istanbul_arel_university": "Istanbul Arel University"
        },
        {
            "t_c_ordu_university": "T C Ordu University"
        },
        {
            "light_university": "Light university"
        },
        {
            "istanbul_culture_university": "Istanbul Culture University"
        },
        {
            "kto_karatay_university": "KTO Karatay University"
        },
        {
            "siirt_university": "Siirt University"
        },
        {
            "yalova_university": "Yalova University"
        },
        {
            "bayburt_university": "Bayburt University"
        },
        {
            "istanbul_commerce_university": "Istanbul Commerce University"
        },
        {
            "osmaniye_korkut_ata_university": "Osmaniye Korkut Ata University"
        },
        {
            "university_of_biruni": "University of Biruni"
        },
        {
            "dicle_university": "Dicle University"
        },
        {
            "ankara_yildirim_beyazit_university": "Ankara Yildirim Beyazit University"
        },
        {
            "istanbul_mef_university": "Istanbul Mef University"
        },
        {
            "ufuk_university": "Ufuk University"
        },
        {
            "geli\u00e5\u00ffim_university": "Geli\u00c5\u0178im University"
        },
        {
            "amasya_university": "Amasya University"
        },
        {
            "hali\u00e3\u00a7_university": "Hali\u00c3\u00a7 University"
        },
        {
            "bilecik_sheikh_liferown_university": "Bilecik Sheikh Liferown University"
        },
        {
            "alt\u00e4\u00b1nba\u00e5\u00ff_university": "Alt\u00c4\u00b1nba\u00c5\u0178 University"
        },
        {
            "istanbul_gedik_university": "Istanbul Gedik University"
        },
        {
            "mardin_proudtained_university": "Mardin Proudtained University"
        },
        {
            "fatih_sultan_mehmet_university": "Fatih Sultan Mehmet University"
        },
        {
            "istinye_university": "Istinye University"
        },
        {
            "bandirma_onyedi_eylul_university": "Bandirma Onyedi Eylul University"
        },
        {
            "ankara_social_sciences_university": "Ankara Social Sciences University"
        },
        {
            "turkish-german_university": "Turkish-German University"
        },
        {
            "konya_teknik_\u00e3\u0153niversitesi": "Konya Teknik \u00c3\u0153niversitesi"
        },
        {
            "\u00e4\u00b0zmir_democracy_university": "\u00c4\u00b0zmir Democracy University"
        },
        {
            "trabzon_university": "Trabzon University"
        },
        {
            "istanbul_esenyurt_university": "Istanbul Esenyurt University"
        },
        {
            "cappadocia_university": "Cappadocia University"
        },
        {
            "toros_university": "Toros University"
        },
        {
            "beykoz_university": "Beykoz University"
        },
        {
            "turkish_air_force_academy": "Turkish Air Force Academy"
        },
        {
            "konya_food_and_agricultural_university": "Konya Food and Agricultural University"
        },
        {
            "lokman_hekim_university": "Lokman Hekim University"
        },
        {
            "university_of_kayseri": "University of Kayseri"
        },
        {
            "naval_academy_turkey": "Naval Academy Turkey"
        },
        {
            "istanbul_29_mayis_university": "Istanbul 29 Mayis University"
        },
        {
            "ostim_technical_university": "Ostim Technical University"
        },
        {
            "ankara_medipol_university": "Ankara Medipol University"
        },
        {
            "eurasian_university": "Eurasian University"
        },
        {
            "istanbul_university-cerrahpasa": "Istanbul University-Cerrahpasa"
        },
        {
            "fenerbahce_university": "Fenerbahce University"
        },
        {
            "istanbul_ayvansaray_university": "Istanbul Ayvansaray University"
        },
        {
            "university_of_turkish_aeronautical_association": "University of Turkish Aeronautical Association"
        },
        {
            "ankara_hac\u00e4\u00b1_bayram_veli_university": "Ankara Hac\u00c4\u00b1 Bayram Veli University"
        },
        {
            "istanbul_\u00e5\u017ei\u00e5\u00ffli_vocational_school": "Istanbul \u00c5\u017ei\u00c5\u0178li Vocational School"
        },
        {
            "gendarmerie_and_coast_guard_academy": "Gendarmerie and Coast Guard Academy"
        },
        {
            "istanbul_urban_university": "Istanbul urban University"
        },
        {
            "alanya_hamdullah_emin_pasa_university": "Alanya Hamdullah Emin Pasa University"
        },
        {
            "\u00e4\u00b0zmir_concept_vocational_school": "\u00c4\u00b0zmir Concept Vocational School"
        },
        {
            "ankara_fine_arts_university": "Ankara Fine Arts University"
        },
        {
            "faruk_sarac_design_vocational_school": "Faruk Sarac Design Vocational School"
        },
        {
            "kahramanmara\u00e5\u00ff_istiklal_university": "Kahramanmara\u00c5\u0178 Istiklal University"
        },
        {
            "kocaeli_health_and_technology_university": "Kocaeli Health and Technology University"
        },
        {
            "rauf_denktas_university": "Rauf Denktas University"
        }
    ],
    "thailand": [
        {
            "chulalongkorn_university": "Chulalongkorn University"
        },
        {
            "mahidol_university": "Mahidol University"
        },
        {
            "chiang_mai_university": "Chiang Mai University"
        },
        {
            "khon_kaen_university": "Khon Kaen University"
        },
        {
            "kasetsart_university": "Kasetsart University"
        },
        {
            "prince_of_songkla_university": "Prince of Songkla University"
        },
        {
            "naresuan_university": "Naresuan University"
        },
        {
            "king_mongkut's_university_of_technology_thonburi": "King Mongkut's University of Technology Thonburi"
        },
        {
            "suranaree_university_of_technology": "Suranaree University of Technology"
        },
        {
            "king_mongkut's_institute_of_technology_ladkrabang": "King Mongkut's Institute of Technology Ladkrabang"
        },
        {
            "king_mongkut's_university_of_technology_north_bangkok": "King Mongkut's University of Technology North Bangkok"
        },
        {
            "asian_institute_of_technology_thailand": "Asian Institute of Technology Thailand"
        },
        {
            "suan_sunandha_rajabhat_university": "Suan Sunandha Rajabhat University"
        },
        {
            "srinakharinwirot_university": "Srinakharinwirot University"
        },
        {
            "burapha_university": "Burapha University"
        },
        {
            "mae_fah_luang_university": "Mae Fah Luang University"
        },
        {
            "university_of_phayao": "University of Phayao"
        },
        {
            "walailak_university": "Walailak University"
        },
        {
            "rangsit_university": "Rangsit University"
        },
        {
            "ubon_ratchathani_university": "Ubon Ratchathani University"
        },
        {
            "maejo_university": "Maejo University"
        },
        {
            "ramkhamhaeng_university": "Ramkhamhaeng University"
        },
        {
            "national_institute_of_development_administration": "National Institute of Development Administration"
        },
        {
            "nakhon_pathom_rajabhat_university": "Nakhon Pathom Rajabhat University"
        },
        {
            "mahasarakham_university": "Mahasarakham University"
        },
        {
            "bangkok_university": "Bangkok University"
        },
        {
            "suan_dusit_university": "Suan Dusit University"
        },
        {
            "silpakorn_university": "Silpakorn University"
        },
        {
            "assumption_university_of_thailand": "Assumption University of Thailand"
        },
        {
            "thaksin_university": "Thaksin University"
        },
        {
            "udon_thani_rajabhat_university": "Udon Thani Rajabhat University"
        },
        {
            "university_of_the_thai_chamber_of_commerce": "University of the Thai Chamber of Commerce"
        },
        {
            "rajamangala_university_of_technology_thanyaburi": "Rajamangala University of Technology Thanyaburi"
        },
        {
            "mahanakorn_university_of_technology": "Mahanakorn University of Technology"
        },
        {
            "yala_rajabhat_university": "Yala Rajabhat University"
        },
        {
            "chiang_mai_rajabhat_university": "Chiang Mai Rajabhat University"
        },
        {
            "rajabhat_maha_sarakham_university": "Rajabhat Maha Sarakham University"
        },
        {
            "sukhothai_thammathirat_open_university": "Sukhothai Thammathirat Open University"
        },
        {
            "pibulsongkram_rajabhat_university": "Pibulsongkram Rajabhat University"
        },
        {
            "rajamangala_university_of_technology_phra_nakhon": "Rajamangala University of Technology Phra Nakhon"
        },
        {
            "phetchaburi_rajabhat_university": "Phetchaburi Rajabhat University"
        },
        {
            "dhurakijpundit_university": "Dhurakijpundit University"
        },
        {
            "sripatum_university": "Sripatum University"
        },
        {
            "rajamangala_university_of_technology_lanna": "Rajamangala University of Technology Lanna"
        },
        {
            "rajamangala_university_of_technology_rattanakosin": "Rajamangala University of Technology Rattanakosin"
        },
        {
            "loei_rajabhat_university": "Loei Rajabhat University"
        },
        {
            "rajamangala_university_of_technology_srivijaya": "Rajamangala University of Technology Srivijaya"
        },
        {
            "rajamutala_university_of_technology_tawan-ok": "Rajamutala University of Technology Tawan-OK"
        },
        {
            "kasem_bundit_university.": "Kasem Bundit University."
        },
        {
            "vidyasirimedhi_institute_of_science_and_technology_vistec": "Vidyasirimedhi Institute of Science and Technology VISTEC"
        },
        {
            "suratthani_rajabhat_university": "Suratthani Rajabhat University"
        },
        {
            "rajamangala_university_of_technology_krungtheb": "Rajamangala University of Technology Krungtheb"
        },
        {
            "stamford_international_university_thailand": "Stamford International University Thailand"
        },
        {
            "buriram_rajabhat_university": "Buriram Rajabhat University"
        },
        {
            "kanchanaburi_rajabhat_university": "Kanchanaburi Rajabhat University"
        },
        {
            "mahachulalongkornrajavidyalaya_university": "Mahachulalongkornrajavidyalaya University"
        },
        {
            "rajamangala_university_of_technology_suvarnabhumi": "Rajamangala University of Technology Suvarnabhumi"
        },
        {
            "western_university": "Western University"
        },
        {
            "payap_university": "Payap University"
        },
        {
            "phuket_rajabhat_university": "Phuket Rajabhat University"
        },
        {
            "chiang_rai_rajabhat_university": "Chiang Rai Rajabhat University"
        },
        {
            "kamphaeng_phet_rajabhat_university": "Kamphaeng Phet Rajabhat University"
        },
        {
            "huachiew_chalermprakiet_university": "Huachiew Chalermprakiet University"
        },
        {
            "phramongkutklao_college_of_medicine": "Phramongkutklao College of Medicine"
        },
        {
            "songkhla_rajabhat_university": "Songkhla Rajabhat University"
        },
        {
            "nakhon_si_thammarat_rajabhat_university": "Nakhon Si Thammarat Rajabhat University"
        },
        {
            "muban_chom_bung_rajabhat_university": "Muban Chom Bung Rajabhat University"
        },
        {
            "phranakhon_si_ayutthaya_rajabhat_university": "Phranakhon Si Ayutthaya Rajabhat University"
        },
        {
            "sakon_nakhon_rajabhat_university": "Sakon Nakhon Rajabhat University"
        },
        {
            "chandrakasem_rajabhat_university": "Chandrakasem Rajabhat University"
        },
        {
            "sisaket_rajabhat_university": "Sisaket Rajabhat University"
        },
        {
            "mahamakut_buddhist_university": "Mahamakut Buddhist University"
        },
        {
            "pathumwan_institute_of_technology": "Pathumwan Institute of Technology"
        },
        {
            "bunditpatanasilpa_institute": "Bunditpatanasilpa Institute"
        },
        {
            "princess_of_naradhiwas_university": "Princess of Naradhiwas University"
        },
        {
            "nakhon_ratchasima_rajabhat_university": "Nakhon Ratchasima Rajabhat University"
        },
        {
            "rajanagarindra_rajabhat_university": "Rajanagarindra Rajabhat University"
        },
        {
            "nakhon_phanom_university": "Nakhon Phanom University"
        },
        {
            "siam_university": "Siam University"
        },
        {
            "rattana_bundit_university.": "Rattana Bundit University."
        },
        {
            "phetchaboon_rajabhat_university": "Phetchaboon Rajabhat University"
        },
        {
            "ubon_ratchathani_rajabhat_university": "Ubon Ratchathani Rajabhat University"
        },
        {
            "princess_galyani_vadhana_institute_of_music": "Princess Galyani Vadhana Institute of Music"
        },
        {
            "king-and_rajabhat_university": "King-and Rajabhat University"
        },
        {
            "kalasin_university": "Kalasin University"
        },
        {
            "bansomdejchaopraya_rajabhat_university": "Bansomdejchaopraya Rajabhat University"
        },
        {
            "fatoni_university": "Fatoni University"
        },
        {
            "civil_aviation_training_center_thailand": "Civil Aviation Training Center Thailand"
        },
        {
            "southeast_asia_university": "Southeast Asia University"
        },
        {
            "hatyai_university": "Hatyai University"
        },
        {
            "vongchavalitkul_university": "Vongchavalitkul University"
        },
        {
            "north_eastern_university": "North Eastern University"
        },
        {
            "krirk_university": "Krirk University"
        },
        {
            "valaya_alongkorn_rajabhat_university": "Valaya Alongkorn Rajabhat University"
        },
        {
            "lampang_rajabhat_university": "Lampang Rajabhat University"
        },
        {
            "uttaradit_rajabhat_university": "Uttaradit Rajabhat University"
        },
        {
            "dhonburi_rajabhat_university": "Dhonburi Rajabhat University"
        },
        {
            "thai_nichi_institute_of_technology": "Thai Nichi Institute of Technology"
        },
        {
            "surindra_rajabhat_university": "Surindra Rajabhat University"
        },
        {
            "siu_international_(shinawatra_university)": "SIU International (Shinawatra University)"
        },
        {
            "panyapiwat_institute_of_management": "Panyapiwat Institute of Management"
        },
        {
            "st_theresa_international_college": "St Theresa International College"
        },
        {
            "nakhon_sawan_rajabhat_university": "Nakhon Sawan Rajabhat University"
        },
        {
            "faculty_of_medicine_vajira_hospital_navamindradhiraj_university": "Faculty of Medicine Vajira Hospital Navamindradhiraj University"
        },
        {
            "cuirtomklao_royal_military_academy": "Cuirtomklao Royal Military Academy"
        },
        {
            "north_chiang_mai_university": "North Chiang Mai University"
        },
        {
            "boromarajonani_college_of_nursing": "Boromarajonani College of Nursing"
        },
        {
            "christian_university_of_thailand": "Christian University of Thailand"
        },
        {
            "dusit_thani_college": "Dusit Thani College"
        },
        {
            "international_buddhist_college": "International Buddhist College"
        },
        {
            "thepsatri_rajabhat_university": "Thepsatri Rajabhat University"
        },
        {
            "chulabhorn_graduate_institute": "Chulabhorn Graduate Institute"
        },
        {
            "ratchatani_university": "Ratchatani University"
        },
        {
            "north_bangkok_university": "North Bangkok University"
        },
        {
            "arsom_silp_institute_of_the_arts": "Arsom Silp Institute of the Arts"
        },
        {
            "royal_thai_navy_academy": "Royal Thai Navy Academy"
        },
        {
            "college_of_asian_scholars": "College of Asian Scholars"
        },
        {
            "saint_louis_college_thailand": "Saint Louis College Thailand"
        },
        {
            "chaiyaphum_rajabhat_university": "Chaiyaphum Rajabhat University"
        },
        {
            "bangkok_thonburi_university": "Bangkok Thonburi University"
        },
        {
            "pathumthani_university": "Pathumthani University"
        },
        {
            "bangkok_suvarnabhumi_university": "Bangkok Suvarnabhumi University"
        },
        {
            "bangkok_school_of_management": "Bangkok School of Management"
        },
        {
            "rajapru_university_(former_ratchaphrueek_college)": "Rajapru University (Former Ratchaphrueek College)"
        },
        {
            "chulabhorn_royal_academy": "Chulabhorn Royal Academy"
        },
        {
            "srinakarinwirot_university_patumwan_demonstration_school": "Srinakarinwirot University Patumwan Demonstration School"
        },
        {
            "navamindradhiraj_university": "Navamindradhiraj University"
        },
        {
            "princess_chulabhorn's_college_phitsanulok": "Princess Chulabhorn's College Phitsanulok"
        },
        {
            "south-east_bangkok_college": "South-East Bangkok College"
        },
        {
            "saengtham_college": "Saengtham College"
        },
        {
            "lampang_vocational_college": "Lampang Vocational College"
        },
        {
            "srisavarindhira_thai_red_cross_institute_of_nursing": "Srisavarindhira Thai Red Cross Institute of Nursing"
        },
        {
            "eastern_university_of_management_and_technology": "Eastern University of Management and Technology"
        },
        {
            "lampang_inter-tech_college": "Lampang Inter-Tech College"
        },
        {
            "university_of_central_thailand_(phakklang_university)": "University of Central Thailand (Phakklang University)"
        },
        {
            "lampang_vocational_college_of_commerce_and_technology": "Lampang Vocational College of Commerce and Technology"
        },
        {
            "siam_technology_college": "Siam Technology College"
        },
        {
            "chiang_mai_university_demonstration_school": "Chiang Mai University Demonstration School"
        },
        {
            "sirindhorn_college_of_public_health": "Sirindhorn College of Public Health"
        },
        {
            "santapol_college_udonthani": "Santapol College Udonthani"
        },
        {
            "thongsuk_college.": "Thongsuk college."
        },
        {
            "chiang_rai_college": "Chiang Rai College"
        },
        {
            "the_royal_thai_air_force_nursing_college": "The Royal Thai Air Force Nursing College"
        },
        {
            "chaloem_kanchana_university.": "Chaloem Kanchana university."
        },
        {
            "phanomwan_college": "Phanomwan College"
        },
        {
            "northern_college": "Northern College"
        },
        {
            "chiang_mai_vocational_college": "Chiang Mai Vocational College"
        },
        {
            "to_kaeo_community_college": "TO KAEO COMMUNITY COLLEGE"
        },
        {
            "pattani_community_college": "Pattani Community College"
        },
        {
            "narathiwat_community_college": "Narathiwat Community College"
        },
        {
            "buriram_community_college": "Buriram Community College"
        },
        {
            "yasothon_community_college": "Yasothon Community College"
        },
        {
            "remo_community_college": "Remo Community College"
        },
        {
            "mahachai_institute_of_automative_technologies": "Mahachai Institute of Automative Technologies"
        }
    ],
    "iceland": [
        {
            "university_of_iceland": "University of Iceland"
        },
        {
            "reykjavik_university": "Reykjavik University"
        },
        {
            "university_of_akureyri": "University of Akureyri"
        },
        {
            "agricultural_university_of_iceland": "Agricultural University of Iceland"
        },
        {
            "h\u00e3\u00b3lar_university_college": "H\u00c3\u00b3lar University College"
        },
        {
            "iceland_academy_of_the_arts": "Iceland Academy of the Arts"
        },
        {
            "bifrost_university": "Bifrost University"
        },
        {
            "university_centre_of_the_westfjords": "University Centre of the Westfjords"
        }
    ],
    "luxembourg": [
        {
            "university_of_luxembourg": "University of Luxembourg"
        },
        {
            "university_of_luxembourg_competence_centre": "University of Luxembourg Competence Centre"
        }
    ],
    "cyprus": [
        {
            "university_of_cyprus": "University of Cyprus"
        },
        {
            "cyprus_university_of_technology": "Cyprus University of Technology"
        },
        {
            "european_university_cyprus": "European University Cyprus"
        },
        {
            "frederick_university": "Frederick University"
        },
        {
            "open_university_of_cyprus": "Open University of Cyprus"
        },
        {
            "neapolis_university_of_pafos": "Neapolis University of Pafos"
        },
        {
            "cyprus_institute": "Cyprus Institute"
        },
        {
            "cyprus_institute_of_neurology_&_genetics_cyprus_school_of_molecular_medicine": "Cyprus Institute of Neurology & Genetics Cyprus School of Molecular Medicine"
        },
        {
            "intercollege.": "Intercollege."
        },
        {
            "cyprus_college": "Cyprus College"
        },
        {
            "university_of_central_lancashire_cyprus": "University of Central Lancashire Cyprus"
        },
        {
            "cyprus_international_institute_of_management": "Cyprus International Institute of Management"
        },
        {
            "american_college_cyprus": "American College Cyprus"
        },
        {
            "p_a_college": "P A College"
        },
        {
            "college_of_tourism_&_hotel_management": "College of Tourism & Hotel Management"
        },
        {
            "city_unity_college_cyprus": "City Unity College Cyprus"
        },
        {
            "mesoyios_college": "Mesoyios College"
        },
        {
            "philips_college_nicosia": "Philips College Nicosia"
        },
        {
            "kes_college": "KES College"
        },
        {
            "casa_college": "Casa College"
        },
        {
            "ledra_college": "Ledra College"
        },
        {
            "atlantis_college": "Atlantis College"
        },
        {
            "limassol_college_cyprus": "Limassol College Cyprus"
        }
    ],
    "croatia": [
        {
            "university_of_zagreb": "University of Zagreb"
        },
        {
            "university_of_split": "University of Split"
        },
        {
            "university_of_rijeka": "University of Rijeka"
        },
        {
            "josip_juraj_strossmayer_university_of_osijek": "Josip Juraj Strossmayer University of Osijek"
        },
        {
            "university_of_zadar": "University of Zadar"
        },
        {
            "university_of_dubrovnik": "University of Dubrovnik"
        },
        {
            "zagreb_school_of_economics_and_management": "Zagreb School of Economics and Management"
        },
        {
            "university_north": "University North"
        },
        {
            "catholic_university_of_croatia_zagreb": "Catholic University of Croatia Zagreb"
        },
        {
            "polytechnic_of_zagreb": "Polytechnic of Zagreb"
        },
        {
            "libetas_international_university": "Libetas International University"
        },
        {
            "rijeka_polytechnic.": "Rijeka Polytechnic."
        },
        {
            "university_of_pula": "University of Pula"
        },
        {
            "health_studies_zagreb": "Health Studies Zagreb"
        },
        {
            "algebra_university_college": "Algebra University College"
        },
        {
            "polytechnic_of_pozega": "Polytechnic of Pozega"
        },
        {
            "polytechnic_of_karlovac": "Polytechnic of Karlovac"
        },
        {
            "polytechnic_velika_gorica": "Polytechnic Velika Gorica"
        },
        {
            "rochester_institute_of_technology_croatia": "Rochester Institute of Technology Croatia"
        },
        {
            "medimurje_college_in_cakovec": "Medimurje College in cakovec"
        },
        {
            "polytechnic_lavoslav_ruzicka_in_vukovar": "Polytechnic Lavoslav Ruzicka in Vukovar"
        },
        {
            "college_security_zagreb": "College Security Zagreb"
        },
        {
            "edward_bernays_college_of_communication_management": "Edward Bernays College of Communication Management"
        },
        {
            "european_business_school_zagreb": "European Business School Zagreb"
        },
        {
            "bjelovar_university_of_applied_sciences": "Bjelovar University of Applied Sciences"
        },
        {
            "polytechnic_nikola_tesla_gospic": "Polytechnic Nikola Tesla Gospic"
        },
        {
            "college_of_information_technology_zagreb": "College of Information Technology Zagreb"
        },
        {
            "polytechnic_in_slavonski_brod": "Polytechnic in Slavonski Brod"
        },
        {
            "college_of_tourism_and_information_technology_in_virovitica": "College of Tourism and Information Technology in Virovitica"
        },
        {
            "evangelical_theological_seminary_in_osijek": "Evangelical Theological Seminary in Osijek"
        },
        {
            "college_for_financial_management_zagreb": "College for Financial Management Zagreb"
        },
        {
            "theological_seminary_matija_vla\u00e4\u008di\u00e4\u2021_illyria": "Theological Seminary Matija Vla\u00c4\u008di\u00c4\u2021 Illyria"
        },
        {
            "istrian_polytechnic": "Istrian Polytechnic"
        }
    ],
    "lebanon": [
        {
            "american_university_of_beirut": "American University of Beirut"
        },
        {
            "lebanese_american_university": "Lebanese American University"
        },
        {
            "lebanese_university": "Lebanese University"
        },
        {
            "saint_joseph_university_of_beirut": "Saint Joseph University of Beirut"
        },
        {
            "university_of_balamand": "University of Balamand"
        },
        {
            "beirut_arab_university": "Beirut Arab University"
        },
        {
            "our_lady_university_lebanon": "Our Lady University Lebanon"
        },
        {
            "lebanese_international_university": "Lebanese International University"
        },
        {
            "university_saint_spirit_of_kaslik": "University Saint Spirit of Kaslik"
        },
        {
            "american_university_of_science_&_technology": "American University of Science & Technology"
        },
        {
            "arab_open_university_lebanon": "Arab Open University Lebanon"
        },
        {
            "haigazian_university": "Haigazian University"
        },
        {
            "rafik_hariri_university": "Rafik Hariri University"
        },
        {
            "american_university_of_technology": "American University of Technology"
        },
        {
            "school_of_business_lebanon": "School of Business Lebanon"
        },
        {
            "jinan_university_lebanon": "Jinan University Lebanon"
        },
        {
            "modern_university_for_business_&_science": "Modern University for Business & Science"
        },
        {
            "university_wisdom": "University wisdom"
        },
        {
            "university_of_balamand_lebanese_academy_of_fine_arts": "University of Balamand Lebanese Academy of Fine Arts"
        },
        {
            "international_college_beirut": "International College Beirut"
        },
        {
            "global_university": "Global University"
        },
        {
            "arts_sciences_&_technology_university_lebanon": "Arts Sciences & Technology University Lebanon"
        },
        {
            "lebanese_german_university": "Lebanese German University"
        },
        {
            "near_east_school_of_theology": "Near East School of Theology"
        },
        {
            "jesus_&_mary_school_rabweh": "Jesus & Mary School Rabweh"
        },
        {
            "al_maaref_university": "Al Maaref University"
        },
        {
            "national_conservatory_of_arts_and_crafts_cnam_lebanon": "National Conservatory of Arts and Crafts CNAM Lebanon"
        },
        {
            "university_of_sciences_&_arts_in_lebanon": "University of Sciences & Arts in Lebanon"
        },
        {
            "libano-french_university": "Libano-French University"
        },
        {
            "lebanese_national_higher_conservatory_of_music": "Lebanese National Higher Conservatory of Music"
        },
        {
            "makassed_university_of_beirut": "Makassed University of Beirut"
        },
        {
            "al_kafaat_university": "Al Kafaat University"
        },
        {
            "muc_university_lebanon": "MUC University Lebanon"
        },
        {
            "holy_family_university_batroun": "Holy Family University Batroun"
        }
    ],
    "indonesia": [
        {
            "university_of_indonesia": "University of Indonesia"
        },
        {
            "gadjah_mada_university": "Gadjah Mada University"
        },
        {
            "ipb_university": "IPB University"
        },
        {
            "ten_november_institute_of_technology": "Ten November Institute of Technology"
        },
        {
            "brawijaya_university": "Brawijaya University"
        },
        {
            "airlangga_university": "Airlangga University"
        },
        {
            "andalas_university": "Andalas University"
        },
        {
            "bina_nusantara_university": "Bina Nusantara University"
        },
        {
            "institut_teknologi_bandung": "Institut Teknologi Bandung"
        },
        {
            "university_of_muhammadiyah_yogyakarta": "University of Muhammadiyah Yogyakarta"
        },
        {
            "university_of_eleven_march_uns_surakarta": "University of Eleven March UNS Surakarta"
        },
        {
            "gunadarma_university": "gunadarma University"
        },
        {
            "hasanuddin_university": "Hasanuddin University"
        },
        {
            "indonesian_education_university": "Indonesian education university"
        },
        {
            "satya_wacana_christian_university": "Satya Wacana Christian University"
        },
        {
            "kuala_shiite_university": "Kuala Shiite University"
        },
        {
            "surabaya_state_electronic_polytechnic": "Surabaya State Electronic Polytechnic"
        },
        {
            "malang_state_university": "Malang State University"
        },
        {
            "yogyakarta_state_university": "Yogyakarta State University"
        },
        {
            "university_of_northern_sumatra": "University of Northern Sumatra"
        },
        {
            "lampung_university": "Lampung University"
        },
        {
            "atma_jaya_university_yogyakarta": "Atma Jaya University Yogyakarta"
        },
        {
            "university_of_dian_nuswantoro": "University of Dian Nuswantoro"
        },
        {
            "ahmad_dahlan_university_yogyakarta": "Ahmad Dahlan University Yogyakarta"
        },
        {
            "university_of_jember": "University of Jember"
        },
        {
            "sriwijaya_university": "Sriwijaya University"
        },
        {
            "padang_state_university": "Padang State University"
        },
        {
            "mulawarman_university": "Mulawarman University"
        },
        {
            "riau_university": "Riau University"
        },
        {
            "university_of_mercu_buana": "University of Mercu Buana"
        },
        {
            "mataram_university": "Mataram University"
        },
        {
            "surabaya_state_university": "Surabaya State University"
        },
        {
            "petra_christian_university": "Petra Christian University"
        },
        {
            "bandung_main_widyatama_university": "Bandung Main Widyatama University"
        },
        {
            "halu_university_oleo_kendari": "Halu University Oleo Kendari"
        },
        {
            "udayana_university": "Udayana University"
        },
        {
            "makassar_public_university": "Makassar public university"
        },
        {
            "general_soedirman_university": "General Soedirman University"
        },
        {
            "universitas_terbuka": "Universitas Terbuka"
        },
        {
            "islamic_university_uin_syarif_hidayatullah_jakarta": "Islamic University UIN Syarif Hidayatullah Jakarta"
        },
        {
            "bengkulu_university": "Bengkulu University"
        },
        {
            "esa_unggul_university_(university_of_indonusa)": "Esa Unggul University (University of Indonusa)"
        },
        {
            "university_of_muhammadiyah_malang": "University of Muhammadiyah Malang"
        },
        {
            "university_of_tadulako.": "University of Tadulako."
        },
        {
            "ganesha_education_university": "Ganesha Education University"
        },
        {
            "sam_ratulangi_university": "Sam Ratulangi University"
        },
        {
            "university_of_medan_area": "University of Medan Area"
        },
        {
            "untar_tarumanagara_university": "Untar Tarumanagara University"
        },
        {
            "state_islamic_university_raden_intan_lampung": "State Islamic University Raden Intan Lampung"
        },
        {
            "lhokseumawe_state_polytechnic": "Lhokseumawe State Polytechnic"
        },
        {
            "indonesian_atma_jaya_catholic_university": "Indonesian Atma Jaya Catholic University"
        },
        {
            "trisakti_university": "Trisakti University"
        },
        {
            "state_university_of_jakarta": "State University of Jakarta"
        },
        {
            "telkom_purwokerto_institute_of_technology_(telkom_purwokerto_telecommunication_technology_college)": "Telkom Purwokerto Institute of Technology (Telkom Purwokerto Telecommunication Technology College)"
        },
        {
            "university_of_surabaya": "University of Surabaya"
        },
        {
            "lambung_mangkurat_university": "Lambung Mangkurat University"
        },
        {
            "muhammadiyah_university_prof._dr._hamka": "Muhammadiyah University Prof. Dr. Hamka"
        },
        {
            "state_islamic_university_of_uin_sunan_kalijaga_yogyakarta": "State Islamic University of UIN Sunan Kalijaga Yogyakarta"
        },
        {
            "parahyangan_catholic_university": "Parahyangan Catholic University"
        },
        {
            "maranatha_christian_university": "Maranatha Christian University"
        },
        {
            "university_of_sanata_dharma_usd_sleman": "University of Sanata Dharma USD Sleman"
        },
        {
            "palangkaraya_university": "Palangkaraya University"
        },
        {
            "indonesian_art_institute_yogyakarta_bantul": "Indonesian art institute Yogyakarta Bantul"
        },
        {
            "universitas_computer_indonesia_unikom": "Universitas Computer Indonesia Unikom"
        },
        {
            "state_islamic_university_uin_sunan_ampel_surabaya": "State Islamic University UIN Sunan Ampel Surabaya"
        },
        {
            "jakarta_telkom_academy": "Jakarta Telkom Academy"
        },
        {
            "pattimura_university": "Pattimura University"
        },
        {
            "state_polytechnic_ujung_pandang": "State Polytechnic Ujung Pandang"
        },
        {
            "indonesian_technocrat_university": "Indonesian Technocrat University"
        },
        {
            "denpasar_indonesian_art_institute": "Denpasar Indonesian Art Institute"
        },
        {
            "christian_university_duta_wacana": "Christian University Duta Wacana"
        },
        {
            "institute_of_informatics_and_business_astratians": "Institute of informatics and business astratians"
        },
        {
            "amikom_university_yogyakarta": "Amikom University Yogyakarta"
        },
        {
            "perbanas_institute": "Perbanas Institute"
        },
        {
            "poltekkes_jakarta_i.": "Poltekkes Jakarta I."
        },
        {
            "malang_ministry_of_health_polytechnic": "Malang Ministry of Health Polytechnic"
        },
        {
            "jember's_polytechnic": "Jember's Polytechnic"
        },
        {
            "national_university": "National University"
        },
        {
            "indonesian_muslim_university_makassar": "Indonesian Muslim University Makassar"
        },
        {
            "islamic_university_of_uin_maulana_malik_ibrahim_malang": "Islamic University of UIN Maulana Malik Ibrahim Malang"
        },
        {
            "bandung_national_institute": "Bandung National Institute"
        },
        {
            "university_of_muhammadiyah_purworejo": "University of Muhammadiyah Purworejo"
        },
        {
            "institute_of_islamic_religious_state_iain_pontianak": "Institute of Islamic Religious State Iain Pontianak"
        },
        {
            "dr_soetomo_university_surabaya": "Dr Soetomo University Surabaya"
        },
        {
            "polytechnic_unfortunate": "Polytechnic unfortunate"
        },
        {
            "fajar_university": "Fajar University"
        },
        {
            "surabaya_state_shipping_polytechnic": "Surabaya State Shipping Polytechnic"
        },
        {
            "university_of_bhayangkara_jakarta_raya": "University of Bhayangkara Jakarta Raya"
        },
        {
            "university_of_al_azhar_indonesia": "University of Al Azhar Indonesia"
        },
        {
            "islamic_university_of_uin_sulthan_thaha_saifuddin_jambi": "Islamic University of UIN Sulthan Thaha Saifuddin Jambi"
        },
        {
            "telkom_technology_institute_surabaya": "Telkom Technology Institute Surabaya"
        },
        {
            "balinese_stikom_institute_of_technology_and_business": "Balinese Stikom Institute of Technology and Business"
        },
        {
            "university_of_muhammadiyah_aceh_unmuha": "University of Muhammadiyah Aceh Unmuha"
        },
        {
            "tanjungpura_university": "Tanjungpura University"
        },
        {
            "university_of_muhammadiyah_semarang.": "University of Muhammadiyah Semarang."
        },
        {
            "swiss_german_university": "Swiss German University"
        },
        {
            "university_of_cokroaminoto_palopo": "University of Cokroaminoto Palopo"
        },
        {
            "surabaya_electrical_and_informatics_engineering_school": "Surabaya Electrical and Informatics Engineering School"
        },
        {
            "university_of_paramadina": "University of Paramadina"
        },
        {
            "surakarta_indonesian_art_institute": "Surakarta Indonesian Art Institute"
        },
        {
            "alma_ata_bantul_university": "Alma ATA Bantul University"
        },
        {
            "bakrie_university": "Bakrie University"
        },
        {
            "pgri_university_yogyakarta_uky_bantul": "PGRI University Yogyakarta Uky Bantul"
        },
        {
            "mahasaraswati_university_denpasar": "Mahasaraswati University Denpasar"
        },
        {
            "sarjanawiyata_tamansiswa_university": "Sarjanawiyata Tamansiswa University"
        },
        {
            "state_islamic_religious_institute_iain_zawiyah_cot_kala_langsa": "State Islamic Religious Institute Iain Zawiyah Cot Kala Langsa"
        },
        {
            "university_of_pgri_madiun.": "University of Pgri Madiun."
        },
        {
            "university_of_warmadewa.": "University of Warmadewa."
        },
        {
            "islamic_university_of_uin_ar_raniry": "Islamic University of UIN Ar Raniry"
        },
        {
            "university_of_muhammadiyah_palembang": "University of Muhammadiyah Palembang"
        },
        {
            "state_islamic_institute_of_state_iain_sheikh_nurjati_cirebon": "State Islamic Institute of State Iain Sheikh Nurjati Cirebon"
        },
        {
            "malahayati_university": "Malahayati University"
        },
        {
            "nusa_cendana_university": "Nusa Cendana University"
        },
        {
            "institute_of_science_and_business_atma_luhur": "Institute of Science and Business Atma Luhur"
        },
        {
            "itn_national_institute_of_technology_malang": "ITN National Institute of Technology Malang"
        },
        {
            "indonesian_stai_islamic_religion_college": "Indonesian Stai Islamic Religion College"
        },
        {
            "pertamina_university": "Pertamina University"
        },
        {
            "university_august_17,_1945_untag_surabaya": "University August 17, 1945 Untag Surabaya"
        },
        {
            "university_august_17,_1945_untag_samarinda": "University August 17, 1945 Untag Samarinda"
        },
        {
            "the_institute_of_islamic_religious_state_iain_palangka_raya": "the Institute of Islamic Religious State Iain Palangka Raya"
        },
        {
            "stmik_gici_high_school_and_computer_management": "STMIK GICI High School and Computer Management"
        },
        {
            "muhammadiyah_university_of_north_sumatra": "Muhammadiyah University of North Sumatra"
        },
        {
            "university_of_wahid_hasyim_is_unwahas": "University of Wahid Hasyim is unwahas"
        },
        {
            "krida_christian_university_of_wacana": "Krida Christian University of Wacana"
        },
        {
            "field_state_polytechnic": "Field State Polytechnic"
        },
        {
            "university_of_proclamation_45_yogyakarta": "University of Proclamation 45 Yogyakarta"
        },
        {
            "bumigora_university": "Bumigora University"
        },
        {
            "university_of_muhammadiyah_riau.": "University of Muhammadiyah Riau."
        },
        {
            "islamic_university_of_uin_walisongo_semarang": "Islamic University of UIN Walisongo Semarang"
        },
        {
            "widya_mandala_catholic_university_surabaya": "Widya Mandala Catholic University Surabaya"
        },
        {
            "islamic_university_of_kalimantan_uniska": "Islamic University of Kalimantan Uniska"
        },
        {
            "lp3i_polytechnic_medan": "LP3I Polytechnic Medan"
        },
        {
            "university_of_darussalam_unidar_ambon": "University of Darussalam Unidar Ambon"
        },
        {
            "institute_of_state_islamic_religious_iain_manado": "Institute of State Islamic Religious IAIN Manado"
        },
        {
            "indonesia_defense_university": "Indonesia Defense University"
        },
        {
            "telkom_technology_institution_jakarta": "Telkom Technology Institution Jakarta"
        },
        {
            "university_of_respati_indonesia_urindo": "University of Respati Indonesia Urindo"
        },
        {
            "islamic_universitas_nahdlatul_ulama_jepara": "Islamic Universitas Nahdlatul Ulama Jepara"
        },
        {
            "yarsi_university": "Yarsi University"
        },
        {
            "tribhuwana_tunggadewi_university": "Tribhuwana Tunggadewi University"
        },
        {
            "university_of_muhammadiyah_jember": "University of Muhammadiyah Jember"
        },
        {
            "bandung_islamic_university": "Bandung Islamic University"
        },
        {
            "islamic_university_of_sultan_agung_semarang": "Islamic University of Sultan Agung Semarang"
        },
        {
            "hkbp_nommensen_university": "HKBP Nommensen University"
        },
        {
            "pontianak_state_polytechnic": "Pontianak State Polytechnic"
        },
        {
            "batam_international_university": "Batam International University"
        },
        {
            "gorontalo_poltekkes": "Gorontalo Poltekkes"
        },
        {
            "caltex_polytechnic_riau_pekanbaru": "Caltex Polytechnic Riau Pekanbaru"
        },
        {
            "dinamika_university": "Dinamika University"
        },
        {
            "college_of_statistics_stis_jakarta": "College of Statistics Stis Jakarta"
        },
        {
            "indonesian_stiki_indonesian_informatics_and_computer_college": "Indonesian Stiki Indonesian Informatics and Computer College"
        },
        {
            "tidar_magelang_university": "Tidar Magelang University"
        },
        {
            "college_of_health_sciences_stikes_dharma_husada_bandung": "College of Health Sciences Stikes Dharma Husada Bandung"
        },
        {
            "bina_darma_university": "Bina Darma University"
        },
        {
            "faculty_of_medicine_unswagati_cirebon": "Faculty of Medicine Unswagati Cirebon"
        },
        {
            "nahdlatul_ulama_university_surabaya": "Nahdlatul Ulama University Surabaya"
        },
        {
            "high_school_information_management_and_computer_stmik_sinar_nusantara_surakarta": "High School Information Management and Computer STMIK Sinar Nusantara Surakarta"
        },
        {
            "semarang_university": "Semarang University"
        },
        {
            "state_islamic_religious_institute_iain_purwokerto": "State Islamic Religious Institute Iain Purwokerto"
        },
        {
            "universitas_islam_malang": "Universitas Islam Malang"
        },
        {
            "lspr_college_of_communications_jakarta": "LSPR College of Communications Jakarta"
        },
        {
            "institute_of_teacher_training_and_education_ikip_pgri_pontianak": "Institute of Teacher Training and Education IKIP PGRI Pontianak"
        },
        {
            "university_of_muhammadiyah_banjarmasin.": "University of Muhammadiyah Banjarmasin."
        },
        {
            "university_of_cenderawasih": "University of Cenderawasih"
        },
        {
            "muhammadiyah_college_of_economics_stiem_jakarta": "Muhammadiyah College of Economics STIEM Jakarta"
        },
        {
            "veteran_national_development_university_yogyakarta": "Veteran National Development University Yogyakarta"
        },
        {
            "university_of_development_tunas": "University of Development Tunas"
        },
        {
            "stfi_sadra_high_school_islamic_philosophy": "Stfi Sadra High School Islamic Philosophy"
        },
        {
            "uniga_garut_university": "Uniga Garut University"
        },
        {
            "university_of_pgri_semarang.": "University of PGRI Semarang."
        },
        {
            "university_of_muhammadiyah_sidoarjo": "University of Muhammadiyah Sidoarjo"
        },
        {
            "widya_mandira_catholic_university": "Widya Mandira Catholic University"
        },
        {
            "college_of_health_sciences_stikes_bth_tasikmalaya": "College of Health Sciences STIKES BTH Tasikmalaya"
        },
        {
            "stmik_isoim_yogricarta": "StMik isoim yogricarta"
        },
        {
            "the_institute_of_islamic_religious_state_iain_kediri": "The Institute of Islamic Religious State Iain Kediri"
        },
        {
            "college_of_economics_stie_atma_bhakti_surakarta": "College of Economics STIE ATMA BHAKTI Surakarta"
        },
        {
            "university_of_muhammadiyah_surabaya": "University of Muhammadiyah Surabaya"
        },
        {
            "muhammadiyah_university_of_east_kalimantan": "Muhammadiyah University of East Kalimantan"
        },
        {
            "university_of_respati_yogyakarta_unriyo_sleman": "University of Respati Yogyakarta Unriyo Sleman"
        },
        {
            "muhammadiyah_pontianak_university": "Muhammadiyah Pontianak University"
        },
        {
            "university_of_muhammadiyah_jakarta": "University of Muhammadiyah Jakarta"
        },
        {
            "akprind_yogyakarta_institute_of_science_and_technology": "AKPRIND Yogyakarta Institute of Science and Technology"
        },
        {
            "gorontalo_state_university": "Gorontalo State University"
        },
        {
            "bosowa_polytechnic_makassar": "Bosowa Polytechnic Makassar"
        },
        {
            "indonesian_hindu_university": "Indonesian Hindu University"
        },
        {
            "banjarmasin_state_polytechnic": "Banjarmasin State Polytechnic"
        },
        {
            "president_university": "President University"
        },
        {
            "university_of_darma_persada_unsada": "University of Darma Persada Unsada"
        },
        {
            "university_of_swadaya_mount_jati_ugj": "University of Swadaya Mount Jati Ugj"
        },
        {
            "muhammadiyah_university_of_makassar.": "Muhammadiyah University of Makassar."
        },
        {
            "university_of_widya_kartika": "University of Widya Kartika"
        },
        {
            "institute_of_state_islamic_religious_iain_metro_lampung": "Institute of State Islamic Religious IAIN Metro Lampung"
        },
        {
            "institute_of_indonesian_cultural_arts_bandung": "Institute of Indonesian Cultural Arts Bandung"
        },
        {
            "high_school_informatics_management_and_akba_makassar_computer": "High school informatics management and akba makassar computer"
        },
        {
            "kutai_kartanegara_university": "Kutai Kartanegara University"
        },
        {
            "islamic_university_uin_imam_bonjol": "Islamic University UIN Imam Bonjol"
        },
        {
            "pictional_polytechnic_ganesha_bandung": "Pictional polytechnic ganesha bandung"
        },
        {
            "indo_global_mandiri_uigm_university": "Indo Global Mandiri Uigm University"
        },
        {
            "state_islamic_religious_institute_iain_salatiga": "State Islamic Religious Institute Iain Salatiga"
        },
        {
            "high_school_health_sciences_stikes_stmih_hang_tuah_pekanbaru": "High School Health Sciences STIKES STMIH Hang Tuah Pekanbaru"
        },
        {
            "veteran_national_development_university_jakarta": "Veteran National Development University Jakarta"
        },
        {
            "university_august_17,_1945_uta'45_jakarta": "University August 17, 1945 Uta'45 Jakarta"
        },
        {
            "putera_batam_university": "Putera Batam University"
        },
        {
            "state_finance_polytechnic_stan_pkn": "State Finance Polytechnic Stan PKN"
        },
        {
            "college_of_economics_stie_financial_banking_and_padang_development": "College of Economics STIE Financial Banking and Padang Development"
        },
        {
            "stp_bandung_tourism_college": "STP Bandung Tourism College"
        },
        {
            "state_islamic_university_uin_raden_fatah": "State Islamic University UIN Raden Fatah"
        },
        {
            "merdeka_university_malang": "Merdeka University Malang"
        },
        {
            "sriwijaya_polytechnic": "Sriwijaya Polytechnic"
        },
        {
            "university_of_muhammadiyah_palu": "University of Muhammadiyah Palu"
        },
        {
            "dehasen_university_of_bengkulu": "Dehasen University of Bengkulu"
        },
        {
            "kanjuruhan_university_malang": "Kanjuruhan University Malang"
        },
        {
            "podomoro_university": "Podomoro University"
        },
        {
            "ngudi_waluyo_university": "Ngudi Waluyo University"
        },
        {
            "aka_bogor_polytechnic": "AKA Bogor Polytechnic"
        },
        {
            "international_university_of_semen_indonesia_uisi": "International University of Semen Indonesia UISI"
        },
        {
            "high_school_of_islamic_science_and_arabic_stiba_makassar": "High School of Islamic Science and Arabic Stiba Makassar"
        },
        {
            "state_islamic_institute_of_islamic_religious_religious": "State Islamic Institute of Islamic Religious Religious"
        },
        {
            "university_of_widya_dharma_klaten": "University of Widya Dharma Klaten"
        },
        {
            "university_of_krisnadwipayana_ukris.": "University of Krisnadwipayana Ukris."
        },
        {
            "indonesian_art_high_school_padang_panjang_sti_padang_panjang_panjang": "Indonesian art high school Padang Panjang Sti Padang Panjang Panjang"
        },
        {
            "batanghari_university": "Batanghari University"
        },
        {
            "university_of_baiturrahmah.": "University of Baiturrahmah."
        },
        {
            "college_of_economics_stie_malangkucecwara_malang": "College of Economics STIE Malangkucecwara Malang"
        },
        {
            "climatology_and_geophysics_meteorology_college_and_geophysics_jakarta": "Climatology and Geophysics Meteorology College and Geophysics Jakarta"
        },
        {
            "indonesian_institute_of_technology": "Indonesian Institute of Technology"
        },
        {
            "university_of_science_and_computer_technology_stekom_semarang": "University of Science and Computer Technology Stekom Semarang"
        },
        {
            "best_jakarta_stp_trisakti_tourism_tourism_school": "Best Jakarta Stp Trisakti Tourism Tourism School"
        },
        {
            "national_institute_of_science_and_technology_istn": "National Institute of Science and Technology istn"
        },
        {
            "university_of_muhammadiyah_palangka_raya": "University of Muhammadiyah Palangka Raya"
        },
        {
            "kadiri_university": "Kadiri University"
        },
        {
            "university_of_wiralodra_unww": "University of wiralodra unww"
        },
        {
            "bandung_manufacturing_polytechnic": "Bandung Manufacturing Polytechnic"
        },
        {
            "university_of_wijaya_putra": "University of Wijaya Putra"
        },
        {
            "universitas_sari_mulia_unism": "Universitas Sari Mulia UNISM"
        },
        {
            "ppm_management": "PPM management"
        },
        {
            "university_of_bunda_mulia_jakarta": "University of Bunda Mulia Jakarta"
        },
        {
            "jayabaya_university": "Jayabaya University"
        },
        {
            "denpasar_poltekkes": "Denpasar Poltekkes"
        },
        {
            "university_of_bina_sarana_informatika": "University of Bina Sarana Informatika"
        },
        {
            "atma_jaya_makassar_university": "Atma Jaya Makassar University"
        },
        {
            "state_islamic_religious_institute_iain_pekalongan": "State Islamic Religious Institute Iain Pekalongan"
        },
        {
            "sampoerna_university": "Sampoerna University"
        },
        {
            "university_of_muhammadiyah_sorong": "University of Muhammadiyah Sorong"
        },
        {
            "institute_of_teacher_training_and_education_ikip_budi_utomo_malang": "Institute of Teacher Training and Education IKIP Budi Utomo Malang"
        },
        {
            "college_of_administrative_sciences_stia_karya_dharma_merauke": "College of Administrative Sciences Stia Karya Dharma Merauke"
        },
        {
            "gresik_university": "Gresik University"
        },
        {
            "uin_antasari_banjarmasin_state_islamic_university": "UIN Antasari Banjarmasin State Islamic University"
        },
        {
            "university_august_17,_1945_untag_semarang": "University August 17, 1945 Untag Semarang"
        },
        {
            "institute_of_teacher_training_and_education_ikip_mataram": "Institute of Teacher Training and Education IKIP Mataram"
        },
        {
            "university_of_dharma_andalas.": "University of Dharma Andalas."
        },
        {
            "the_university_of_pelita_race": "The University of Pelita Race"
        },
        {
            "institute_of_islamic_religious_state_iain_jember": "Institute of Islamic Religious State Iain Jember"
        },
        {
            "puppanic_state_agricultural_polytechnic": "Puppanic State Agricultural Polytechnic"
        },
        {
            "yogyakarta_national_institute_of_technology": "Yogyakarta National Institute of Technology"
        },
        {
            "high_school_health_sciences_stikes_telogorejo_semarang": "High School Health Sciences STIKES Telogorejo Semarang"
        },
        {
            "college_of_economics_stie_widya_gama_lumajang": "College of Economics STIE Widya Gama Lumajang"
        },
        {
            "batam_state_polytechnic": "Batam State Polytechnic"
        },
        {
            "university_of_borobudur.": "University of Borobudur."
        },
        {
            "kuningan_university": "Kuningan University"
        },
        {
            "high_school_of_economic_science_stie_mars_pemalangsiantar": "High School of Economic Science Stie Mars Pemalangsiantar"
        },
        {
            "universitas_bandar_lampung": "Universitas Bandar Lampung"
        },
        {
            "slamet_riyadi_university_surakarta": "Slamet Riyadi University Surakarta"
        },
        {
            "university_of_gajayana_malang": "University of Gajayana Malang"
        },
        {
            "pgri_university_palembang": "PGRI University Palembang"
        },
        {
            "nusantara_university_pgri_kediri": "Nusantara University Pgri Kediri"
        },
        {
            "university_of_siliwangi.": "University of Siliwangi."
        },
        {
            "budi_luhur_university": "Budi Luhur University"
        },
        {
            "riau_health_polytechnic": "Riau Health Polytechnic"
        },
        {
            "langsa_samudra_university": "Langsa Samudra University"
        },
        {
            "uin_mataram_state_islamic_university": "UIN Mataram State Islamic University"
        },
        {
            "bandung_polytechnic": "Bandung Polytechnic"
        },
        {
            "riau_islamic_university": "Riau Islamic University"
        },
        {
            "pamulang_university": "Pamulang University"
        },
        {
            "state_islamic_institute_of_isin_ambon": "State Islamic Institute of Isin Ambon"
        },
        {
            "college_of_economics_stie_perbanas_surabaya": "College of Economics STIE Perbanas Surabaya"
        },
        {
            "the_institute_of_islamic_religious_state_iain_curup": "The Institute of Islamic Religious State Iain Curup"
        },
        {
            "university_of_ibn_khaldun_bogor": "University of Ibn Khaldun Bogor"
        },
        {
            "denpasar_national_education_university": "Denpasar National Education University"
        },
        {
            "the_institute_of_race_technology": "The Institute of Race Technology"
        },
        {
            "nusantara_islamic_university": "Nusantara Islamic University"
        },
        {
            "high_school_informatics_management_and_computer_stmik_banjarbaru": "High school informatics management and computer stmik banjarbaru"
        },
        {
            "darma_agung_university": "Darma Agung University"
        },
        {
            "poltekkes_jakarta_ii": "Poltekkes Jakarta II"
        },
        {
            "padang_polytechnic_(previously_andalas_university_polytechnic)": "Padang Polytechnic (Previously Andalas University Polytechnic)"
        },
        {
            "universitas_pakuan_unpak_bogor": "Universitas Pakuan UNPAK Bogor"
        },
        {
            "muhammadiyah_bengkulu_university": "Muhammadiyah Bengkulu University"
        },
        {
            "north_sumatra_islamic_university": "North Sumatra Islamic University"
        },
        {
            "college_of_shipping_science_stip_jakarta": "College of Shipping Science Stip Jakarta"
        },
        {
            "university_of_batam": "University of Batam"
        },
        {
            "kwik_kian_gie_school_of_business_(institute_business_and_informatics_of_indonesia)": "Kwik Kian Gie School of Business (Institute Business and Informatics of Indonesia)"
        },
        {
            "university_of_stikubank_unisbank_semarang": "University of Stikubank Unisbank Semarang"
        },
        {
            "muhammadiyah_kendari_university": "Muhammadiyah Kendari University"
        },
        {
            "raden_rahmat_islamic_university": "Raden Rahmat Islamic University"
        },
        {
            "bung_hatta_university": "Bung Hatta University"
        },
        {
            "university_of_bale_bandung": "University of Bale Bandung"
        },
        {
            "polytechnic_mataram.": "Polytechnic Mataram."
        },
        {
            "nahdlatul_ulama_sidoarjo_university": "Nahdlatul Ulama Sidoarjo University"
        },
        {
            "university_of_widya_gama_mahakam_samarinda": "University of Widya Gama Mahakam Samarinda"
        },
        {
            "poltekkes_palembang": "Poltekkes Palembang"
        },
        {
            "university_of_ubudiyah_indonesia": "University of Ubudiyah Indonesia"
        },
        {
            "institute_of_state_islamic_religious_iain_datokarama_palu": "Institute of State Islamic Religious IAIN Datokarama Palu"
        },
        {
            "islamic_university_of_uin_smh_banten": "Islamic University of UIN SMH Banten"
        },
        {
            "college_of_science_police_stick_ptik": "College of Science Police Stick PTIK"
        },
        {
            "satya_indonesia_university": "Satya Indonesia University"
        },
        {
            "amikom_purwokerto_university": "Amikom Purwokerto University"
        },
        {
            "university_of_muhammadiyah_mataram": "University of Muhammadiyah Mataram"
        },
        {
            "lamongan_islamic_university": "Lamongan Islamic University"
        },
        {
            "panca_budi_medan_development_university": "Panca Budi Medan Development University"
        },
        {
            "the_institute_of_islamic_religious_state_iain_bengkulu": "The Institute of Islamic Religious State Iain Bengkulu"
        },
        {
            "semarang_state_polytechnic": "Semarang State Polytechnic"
        },
        {
            "field_technography_institute": "Field Technography Institute"
        },
        {
            "pln_institute_of_technology": "PLN Institute of Technology"
        },
        {
            "janabadra_university": "Janabadra University"
        },
        {
            "stmik_el_rahma_yogyakarta_college_and_computer_management_management": "STMIK EL Rahma Yogyakarta College and Computer Management Management"
        },
        {
            "madiun_state_polytechnic": "Madiun State Polytechnic"
        },
        {
            "makassar_islamic_university": "Makassar Islamic University"
        },
        {
            "university_of_wijaya_kusuma_surabaya": "University of Wijaya Kusuma Surabaya"
        },
        {
            "ars_university": "ARS University"
        },
        {
            "university_of_indraprasta_pgri_unindra": "University of Indraprasta Pgri Unindra"
        },
        {
            "i3l_indonesia_international_institute_for_life_sciences": "i3L Indonesia International Institute for Life Sciences"
        },
        {
            "islamic_university_of_sheikh_yusuf_tangerang": "Islamic University of Sheikh Yusuf Tangerang"
        },
        {
            "teacher_training_and_education_sciences_of_bima": "Teacher Training and Education Sciences of Bima"
        },
        {
            "institute_of_teacher_training_and_education_ikip_pgri_jember": "Institute of Teacher Training and Education IKIP PGRI JEMBER"
        },
        {
            "high_school_tourism_stipram_sleman": "High School Tourism Stipram Sleman"
        },
        {
            "institute_of_teacher_training_and_education_ikip_saraswati_tabanan": "Institute of Teacher Training and Education IKIP Saraswati Tabanan"
        },
        {
            "mh_thamrin_university": "MH Thamrin University"
        },
        {
            "nurul_fikri_integrated_technology_college": "Nurul Fikri Integrated Technology College"
        },
        {
            "majalengka_university": "Majalengka University"
        },
        {
            "hang_tuah_university": "Hang Tuah University"
        },
        {
            "nsc_polytechnic_surabaya": "NSC Polytechnic Surabaya"
        },
        {
            "lampung_state_polytechnic": "Lampung State Polytechnic"
        },
        {
            "pancasakti_university_tegal": "Pancasakti University Tegal"
        },
        {
            "merdeka_university_madiun": "Merdeka University Madiun"
        },
        {
            "suryakancana_university": "Suryakancana University"
        },
        {
            "institute_of_technology_science_&_health_pku_muhammadiyah_surakarta": "Institute of Technology Science & Health PKU Muhammadiyah Surakarta"
        },
        {
            "dwijendra_university_denpasar": "Dwijendra University Denpasar"
        },
        {
            "indonesian_christian_university_maluku_ukim_ambon": "Indonesian Christian University Maluku Ukim Ambon"
        },
        {
            "setia_budi_surakarta_university": "Setia Budi Surakarta University"
        },
        {
            "college_of_economics_stie_putra_bangsa_kebumen": "College of Economics STIE Putra Bangsa Kebumen"
        },
        {
            "high_school_information_management_and_computer_stmik_nusa_mandiri": "High School Information Management and Computer STMIK Nusa Mandiri"
        },
        {
            "college_of_health_sciences_stikes_elisabeth_medan": "College of Health Sciences Stikes Elisabeth Medan"
        },
        {
            "ngurah_rai_university": "Ngurah Rai University"
        },
        {
            "nahdlatul_ulama_india_university": "Nahdlatul Ulama India University"
        },
        {
            "hope_polytechnic_joint_tegal": "Hope Polytechnic Joint Tegal"
        },
        {
            "yapis_papua_university_jayapura": "Yapis Papua University Jayapura"
        },
        {
            "mussel_state_polytechnic": "Mussel state polytechnic"
        },
        {
            "sttkd_bantul_high_school_aerospace_technology": "STTKD Bantul High School Aerospace Technology"
        },
        {
            "universitas_panca_bhakti_upb_pontianak": "Universitas Panca Bhakti UPB Pontianak"
        },
        {
            "college_of_health_sciences_stikes_medistra_indonesia": "College of Health Sciences Stikes Medistra Indonesia"
        },
        {
            "university_of_abdurrab_pekanbaru.": "University of Abdurrab Pekanbaru."
        },
        {
            "university_of_surya": "University of Surya"
        },
        {
            "jaya_development_university": "Jaya Development University"
        },
        {
            "faculty_of_social_science_and_political_science": "faculty of Social Science and Political Science"
        },
        {
            "university_of_muhammadiyah_gresik": "University of Muhammadiyah Gresik"
        },
        {
            "university_of_nurtanio_unnur_bandung": "University of Nurtanio Unnur Bandung"
        },
        {
            "institute_of_teacher_training_and_education_ikip_siliwangi": "Institute of Teacher Training and Education IKIP Siliwangi"
        },
        {
            "university_of_prasetiya_mulya.": "University of Prasetiya Mulya."
        },
        {
            "university_of_raharja": "University of Raharja"
        },
        {
            "university_august_17,_1945_untag_banyuwangi": "University August 17, 1945 Untag Banyuwangi"
        },
        {
            "akmil_military_academy": "Akmil Military Academy"
        },
        {
            "stahn_singaraja": "STAHN Singaraja"
        },
        {
            "the_university_of_abeng": "The University of Abeng"
        },
        {
            "islamic_university_45_bekasi": "Islamic University 45 bekasi"
        },
        {
            "siliwangi_university_of_struggle": "Siliwangi University of Struggle"
        },
        {
            "makassar_poltekkes": "Makassar Poltekkes"
        },
        {
            "poltekkes_jambi.": "Poltekkes Jambi."
        },
        {
            "nahdlatul_ulama_purwokerto_university": "Nahdlatul Ulama Purwokerto University"
        },
        {
            "palm_polytechnic_citra_widya_education": "Palm Polytechnic Citra Widya Education"
        },
        {
            "high_school_information_management_and_computer_stmik_pontianak": "High School Information Management and Computer STMIK Pontianak"
        },
        {
            "university_of_muhammadiyah_sukabumi": "University of Muhammadiyah Sukabumi"
        },
        {
            "trisakti_transportation_and_logistics_institute_(high_school_transportation_stmt_trisakti)": "Trisakti Transportation and Logistics Institute (High School Transportation Stmt Trisakti)"
        },
        {
            "college_of_health_sciences_stikes_mus_school_nursing_&_health_sciences_in_surakarta": "College of Health Sciences Stikes Mus School Nursing & Health Sciences in Surakarta"
        },
        {
            "university_of_balikpapan": "University of Balikpapan"
        },
        {
            "prima_indonesia_university": "Prima Indonesia University"
        },
        {
            "college_of_economics_stie_ekuitan_bandung": "College of Economics STIE EKUITAN Bandung"
        },
        {
            "pekalongan_university": "Pekalongan University"
        },
        {
            "college_of_information_management_and_computer_ppkia_pradnya_paramita_malang": "College of Information Management and Computer PPKIA Pradnya Paramita Malang"
        },
        {
            "padang_institute_of_technology": "Padang Institute of Technology"
        },
        {
            "teacher_training_and_education_college_of_stkip_al_hikmah_surabaya": "Teacher Training and Education College of Stkip Al Hikmah Surabaya"
        },
        {
            "adiwangsa_jambi_university": "Adiwangsa Jambi University"
        },
        {
            "university_of_riau_islands": "University of Riau Islands"
        },
        {
            "university_of_al_washliyah_univa_medan": "University of Al Washliyah Univa Medan"
        },
        {
            "university_of_suryadarma_unsurya.": "University of Suryadarma unsurya."
        },
        {
            "university_august_17,_1945_untag_prima_cirebon": "University August 17, 1945 Untag Prima Cirebon"
        },
        {
            "university_of_bhayangkara_surabaya": "University of Bhayangkara Surabaya"
        },
        {
            "bangka_belitung_manufacturing_polytechnic": "Bangka Belitung Manufacturing Polytechnic"
        },
        {
            "universal_university": "Universal University"
        },
        {
            "institusi_perguruan_tinggi_pertiwi": "Institusi Perguruan Tinggi Pertiwi"
        },
        {
            "astra_manufacturing_polytechnic": "Astra Manufacturing Polytechnic"
        },
        {
            "aki_semarang_university": "Aki Semarang University"
        },
        {
            "high_school_textile_technology_sttt_bandung": "High School Textile Technology Sttt Bandung"
        },
        {
            "state_polytechnic_academic_app_leaders_of_jakarta_company": "State Polytechnic Academic App Leaders of Jakarta Company"
        },
        {
            "college_of_health_sciences_stikes_by_husada_semarang": "College of Health Sciences STIKES by Husada Semarang"
        },
        {
            "college_of_economics_stie_mahardhika_surabaya": "College of Economics STIE Mahardhika Surabaya"
        },
        {
            "langlangbuana_university": "Langlangbuana University"
        },
        {
            "stmik_stie_mikroskil": "STMIK STIE Mikroskil"
        },
        {
            "banjarmasin_polytechnic": "Banjarmasin Polytechnic"
        },
        {
            "lasalle_college_indonesia": "LaSalle College Indonesia"
        },
        {
            "bwi_wiyki_features_will": "BWI WIYKI FEATURES WILL"
        },
        {
            "university_of_muhammadiyah_magelang.": "University of Muhammadiyah Magelang."
        },
        {
            "al_azhar_medan_university": "Al Azhar Medan University"
        },
        {
            "trilogy_university": "Trilogy University"
        },
        {
            "nahdlatul_ulama_unu_university_cirebon": "Nahdlatul Ulama Unu University Cirebon"
        },
        {
            "college_of_economics_stie_pertiwi_bekasi": "College of Economics STIE Pertiwi Bekasi"
        },
        {
            "university_of_muhammadiyah_tasikmalaya.": "University of Muhammadiyah Tasikmalaya."
        },
        {
            "islamic_economic_college_of_stei_indonesia": "Islamic Economic College of Stei Indonesia"
        },
        {
            "academy_of_midwifery_akbid_prima_husada": "Academy of midwifery Akbid Prima Husada"
        },
        {
            "college_of_economics_stie_inaba_bandung": "College of Economics STIE INABA BANDUNG"
        },
        {
            "bhakti_kencana_university": "Bhakti Kencana University"
        },
        {
            "academy_of_tourism_akpar_pertiwi": "Academy of Tourism AKPAR PERTIWI"
        },
        {
            "semarang_polytechnic_semarang_central_java": "Semarang Polytechnic Semarang Central Java"
        },
        {
            "college_of_economics_stie_ipwi": "College of Economics STIE IPWI"
        },
        {
            "high_school_management_information_and_computer_stmik_nurdin_hamzah_jambi": "High School Management Information and Computer STMIK Nurdin Hamzah Jambi"
        },
        {
            "mulia_university": "Mulia University"
        },
        {
            "universitas_running.": "Universitas Running."
        },
        {
            "high_school_management_ipmi_international_school": "High School Management IPMI International School"
        },
        {
            "college_of_health_sciences_stikes_binausada_bali": "College of Health Sciences Stikes Binausada Bali"
        },
        {
            "stt_technology_college_of_sapta_taruna": "STT Technology College of Sapta Taruna"
        },
        {
            "teacher_training_and_education_college_of_stkip_adzkia": "Teacher Training and Education College of Stkip Adzkia"
        },
        {
            "teacher_training_and_education_college_of_stkip_pgri_bangkalan": "Teacher Training and Education College of STKIP PGRI Bangkalan"
        },
        {
            "university_of_muhammadiyah_bandung": "University of Muhammadiyah Bandung"
        },
        {
            "islamic_university_djakarta": "Islamic University Djakarta"
        },
        {
            "jember_islamic_university": "Jember Islamic University"
        },
        {
            "university_of_bina_getsempena": "University of Bina Getsempena"
        },
        {
            "college_of_health_sciences_stikes_nani_hasanuddin_makassar": "College of Health Sciences STIKES Nani hasanuddin Makassar"
        },
        {
            "university_of_efarina_unefa": "University of Efarina Unefa"
        },
        {
            "indragiri_islamic_university": "Indragiri Islamic University"
        },
        {
            "stimik_esq_business_school": "STIMIK ESQ Business School"
        },
        {
            "us_islamic_university_syafiiyah": "US Islamic University Syafiiyah"
        },
        {
            "nusa_bangsa_university_unb_bogor": "Nusa Bangsa University Unb Bogor"
        },
        {
            "musi_charitas_catholic_university": "Musi Charitas Catholic University"
        },
        {
            "polytechnic_tasikmalaya.": "Polytechnic Tasikmalaya."
        },
        {
            "banten_polytechnic": "Banten Polytechnic"
        },
        {
            "darussalam_polytechnic": "Darussalam Polytechnic"
        },
        {
            "university_of_methodist_indonesia": "University of Methodist Indonesia"
        },
        {
            "palembang_cadre_university": "Palembang cadre university"
        },
        {
            "high_school_information_management_and_computer_stmik_budi_darma": "High School Information Management and Computer STMIK Budi Darma"
        },
        {
            "institut_seni_budaya_indonesia_isbi_bandung": "Institut Seni Budaya Indonesia ISBI Bandung"
        },
        {
            "umj_islamic_faculty": "UMJ Islamic Faculty"
        },
        {
            "college_of_economics_stie_stmy_majalengka": "College of Economics STIE STMY Majalengka"
        },
        {
            "high_school_information_management_and_computer_stmik_widya_pratama_pekalongan": "High School Information Management and Computer STMIK Widya Pratama Pekalongan"
        },
        {
            "high_school_of_riau_pharmacy_stifar_pekanbaru": "High School of Riau Pharmacy Stifar Pekanbaru"
        },
        {
            "university_of_asahan": "University of Asahan"
        },
        {
            "bung_karno_university": "Bung Karno University"
        },
        {
            "pontianak_polytechnic": "Pontianak Polytechnic"
        },
        {
            "institute_of_college_of_science_al-quri_an_ptiq_jakarta": "Institute of College of Science Al-Quri An PTIQ Jakarta"
        },
        {
            "institute_of_teacher_training_and_education_ikip_pgri_bojonegoro": "Institute of Teacher Training and Education IKIP PGRI Bojonegoro"
        },
        {
            "merdeka_university_surabaya": "Merdeka University Surabaya"
        },
        {
            "health_analyst_academy_borneo_lestari_banjarbaru": "Health Analyst Academy Borneo Lestari Banjarbaru"
        },
        {
            "stmi_jakarta_industry_management_college": "Stmi Jakarta Industry Management College"
        },
        {
            "college_of_health_sciences_stikes_persada_husada_indonesia": "College of Health Sciences Stikes Persada Husada Indonesia"
        },
        {
            "bogor_ibik_business_&_informatics_institute": "Bogor Ibik Business & Informatics Institute"
        },
        {
            "college_of_health_sciences_stikes_muhammadiyah_klaten": "College of Health Sciences STIKES Muhammadiyah Klaten"
        },
        {
            "college_of_communication_science_stikom_nation_dynamics": "College of Communication Science Stikom Nation Dynamics"
        },
        {
            "university_of_nipa_mumamere": "University of Nipa MumaMere"
        },
        {
            "college_of_administrative_sciences_stia_satya_negara": "College of Administrative Sciences Stia Satya Negara"
        },
        {
            "muhammadiyah_university_of_south_tapanuli": "Muhammadiyah University of South Tapanuli"
        },
        {
            "boyolali_university": "Boyolali University"
        },
        {
            "cokroaminoto_university_yogyakarta": "Cokroaminoto University Yogyakarta"
        },
        {
            "bojonegoro_university": "Bojonegoro University"
        },
        {
            "kusuma_husada_university_surakarta": "Kusuma Husada University Surakarta"
        },
        {
            "college_of_health_sciences_stikes_al_irsyad_al_islamiyyah_cilacap": "College of Health Sciences STIKES Al Irsyad Al Islamiyyah Cilacap"
        },
        {
            "gorontalo_university": "Gorontalo University"
        },
        {
            "sekolah_tinggi_agama_islam_stai_mahad_aly_al_hikam": "Sekolah Tinggi Agama Islam STAI Mahad Aly Al Hikam"
        },
        {
            "foreign_language_college_of_stba_international_friendship_asia_stba_pia_city_medan": "Foreign Language College of StbA International Friendship Asia Stba Pia City Medan"
        },
        {
            "sttal_navy_technology_college_of_navy": "Sttal Navy Technology College of Navy"
        },
        {
            "indonesian_institute_of_informatics_ikado": "Indonesian Institute of Informatics Ikado"
        },
        {
            "pharmaceutical_academy_isfi_banjarmasin": "Pharmaceutical Academy Isfi Banjarmasin"
        },
        {
            "madura_islamic_university": "Madura Islamic University"
        },
        {
            "southeast_asian_bible_seminaries": "Southeast Asian Bible Seminaries"
        },
        {
            "college_of_economics_stie_bank_bpd_central_java_semarang": "College of Economics STIE Bank BPD Central Java Semarang"
        },
        {
            "indramayu_state_polytechnic": "Indramayu State Polytechnic"
        },
        {
            "merdeka_university_pasuruan": "Merdeka University Pasuruan"
        },
        {
            "college_of_economics_and_sharia_business_indo_global_mandiri": "College of Economics and Sharia Business Indo Global Mandiri"
        },
        {
            "high_school_information_management_and_computer_stmik_emet_gora_mataram": "High School Information Management and Computer STMIK EMET Gora Mataram"
        },
        {
            "university_of_sangga_buana": "University of Sangga Buana"
        },
        {
            "the_state_christian_institute_iakn_tarutung": "The State Christian Institute IAKN Tarutung"
        },
        {
            "university_of_achmad_yani_banjarmasin": "University of Achmad Yani Banjarmasin"
        },
        {
            "academy_of_accounting_and_management_of_mitra_lampung": "Academy of Accounting and Management of Mitra Lampung"
        },
        {
            "banten_jaya_university": "Banten Jaya University"
        },
        {
            "university_of_badung": "University of Badung"
        },
        {
            "ichsan_gorontalo_university": "Ichsan Gorontalo University"
        },
        {
            "college_of_health_sciences_stikes_guna_sleman_nation": "College of Health Sciences STIKES Guna Sleman Nation"
        },
        {
            "poor_foreign_language_high_school": "Poor foreign language high school"
        },
        {
            "college_of_health_sciences_stikes_yarsi_mataram": "College of Health Sciences Stikes Yarsi Mataram"
        },
        {
            "college_of_economics_stie_nobel_makassar": "College of Economics STIE Nobel Makassar"
        },
        {
            "polytechnic_ganesha_medan": "Polytechnic Ganesha Medan"
        },
        {
            "college_of_economics_muhammadiyah_stiem_palopo": "College of Economics Muhammadiyah Stiem Palopo"
        },
        {
            "college_of_economics_stie_pelita_nusantara": "College of Economics STIE Pelita Nusantara"
        },
        {
            "college_of_health_sciences_stikes_dirgahayu_samarinda": "College of Health Sciences STIKES Dirgahayu Samarinda"
        },
        {
            "contact_ciki_cyypa_karya_informatics": "Contact Ciki Cyypa Karya Informatics"
        },
        {
            "teacher_training_and_education_college_of_stkip_pgri_sidoarjo": "Teacher Training and Education College of STKIP PGRI Sidoarjo"
        },
        {
            "high_school_information_management_and_computer_stmik_bani_saleh_bekasi": "High School Information Management and Computer STMIK Bani Saleh Bekasi"
        },
        {
            "surabaya_shipping_polytechnic": "Surabaya shipping polytechnic"
        },
        {
            "indonusa_surakarta_polytechnic": "Indonusa Surakarta Polytechnic"
        },
        {
            "universitas_tama_jagakarsa_utj": "Universitas Tama Jagakarsa UTJ"
        },
        {
            "stissipol_yaleka_maro": "Stissipol Yaleka Maro"
        },
        {
            "college_of_economics_stie_ama_salatiga": "College of Economics STIE AMA Salatiga"
        },
        {
            "sahid_surakarta_university": "Sahid Surakarta University"
        },
        {
            "ambon_state_polytechnic": "Ambon State Polytechnic"
        },
        {
            "college_of_health_sciences_stikes_bina_husada_palembang_city": "College of Health Sciences Stikes Bina Husada Palembang City"
        },
        {
            "high_school_information_management_and_computer_stmik_stie_asia": "High School Information Management and Computer STMIK STIE Asia"
        },
        {
            "institute_of_health_sciences_ikk_strada_indonesia": "Institute of Health Sciences IKK Strada Indonesia"
        },
        {
            "college_of_health_sciences_stikes_saint_carolus": "College of Health Sciences Stikes Saint Carolus"
        },
        {
            "college_of_economics_stie_bhakti_development": "College of Economics STIE Bhakti Development"
        },
        {
            "sttn_nuclear_technology_college_of_power_agency": "STTN Nuclear Technology College of Power Agency"
        },
        {
            "university_of_muhammadiyah_kudus.": "University of Muhammadiyah Kudus."
        },
        {
            "poltekesos_social_welfare_polytechnic": "Poltekesos Social Welfare Polytechnic"
        },
        {
            "university_of_buddhi_dharma": "University of Buddhi Dharma"
        },
        {
            "tridinanti_palembang_university": "Tridinanti Palembang University"
        },
        {
            "high_school_information_management_and_computer_stmik_dipanegara_makassar": "High School Information Management and Computer STMIK Dipanegara Makassar"
        },
        {
            "nusantara_business_institute_ibn": "Nusantara Business Institute Ibn"
        },
        {
            "institute_of_technology_and_business_itb_ahmad_dahlan": "Institute of Technology and Business ITB Ahmad Dahlan"
        },
        {
            "accounting_academy_of_bina_insani_bekasi": "Accounting Academy of Bina Insani Bekasi"
        },
        {
            "teacher_training_and_education_college_of_stkip_pgri_bandar_lampung": "Teacher Training and Education College of STKIP PGRI Bandar Lampung"
        },
        {
            "adisutjipto_dirgantara_institute_of_technology": "Adisutjipto Dirgantara Institute of Technology"
        },
        {
            "college_of_economics_stie_dwimulya": "College of Economics STIE Dwimulya"
        },
        {
            "academy_of_tourism_dharma_nusantara_sakti_akparda_yogyakarta": "Academy of Tourism Dharma Nusantara Sakti Akparda Yogyakarta"
        },
        {
            "institute_of_batam_technology_iteba": "Institute of Batam Technology Iteba"
        },
        {
            "university_of_sangga_buana_ypkp_bandung": "University of Sangga Buana YPKP Bandung"
        },
        {
            "stei_iqra_annisa_islamic_economic_high_school": "Stei Iqra Annisa Islamic Economic High School"
        },
        {
            "belawan_maritime_academy_in_medan": "Belawan Maritime Academy in Medan"
        },
        {
            "pancasakti_university_of_makassar.": "Pancasakti University of Makassar."
        },
        {
            "university_of_ibn_sina": "University of Ibn Sina"
        },
        {
            "indonesian_christian_university_paulus_makassar": "Indonesian Christian University Paulus Makassar"
        },
        {
            "university_of_muhammadiyah_parepare": "University of Muhammadiyah Parepare"
        },
        {
            "indonesian_law_college": "Indonesian Law College"
        },
        {
            "high_school_information_management_and_computer_stmik_lpkia_bandung": "High School Information Management and Computer STMIK LPKIA Bandung"
        },
        {
            "stimlog_high_school_management_management": "Stimlog High School Management Management"
        },
        {
            "high_school_of_information_management_and_computer_stmik_asia_malang": "High School of Information Management and Computer STMIK Asia Malang"
        },
        {
            "bandung_technology_high_school": "Bandung Technology High School"
        },
        {
            "medistra_lubuk_pakam_campus": "Medistra Lubuk Pakam Campus"
        },
        {
            "university_of_persada_indonesia_y_a_i": "University of Persada Indonesia y a i"
        },
        {
            "stai_muhammadiyah_tulungagung_islamic_college": "Stai Muhammadiyah Tulungagung Islamic College"
        },
        {
            "high_school_health_sciences_stikes_maharani_malang": "High School Health Sciences Stikes Maharani Malang"
        },
        {
            "polytechnic_ministry_of_health_kendari.": "Polytechnic Ministry of Health Kendari."
        },
        {
            "college_of_economics_stie_widya_wiwaha": "College of Economics STIE Widya Wiwaha"
        },
        {
            "university_of_tjut_nyak_dhien": "University of Tjut Nyak Dhien"
        },
        {
            "high_school_of_islamic_religion_standards_stain_teungku_presented_meulaboh_aceh": "High School of Islamic Religion Standards Stain Teungku Presented Meulaboh Aceh"
        },
        {
            "college_of_economics_stie_port_numbay_jayapura": "College of Economics STIE Port Numbay Jayapura"
        },
        {
            "surakarta_national_health_sciences_college_of_stikes": "Surakarta National Health Sciences College of STIKES"
        },
        {
            "cilacap_state_polytechnic": "Cilacap State Polytechnic"
        },
        {
            "subang_university": "Subang University"
        },
        {
            "stimaimmi_college_of_management_sciences": "Stimaimmi College of Management Sciences"
        },
        {
            "high_school_management_information_and_computer_stmik_sumedang": "High School Management Information and Computer STMIK Sumedang"
        },
        {
            "university_of_victory_sorong.": "University of Victory Sorong."
        },
        {
            "teacher_training_and_education_college_of_stkip_subang": "Teacher Training and Education College of Stkip Subang"
        },
        {
            "academy_of_informatics_management_and_computer_trigunadharma_medan_city": "Academy of Informatics Management and Computer Trigunadharma Medan City"
        },
        {
            "college_of_economics_stie_kisenegara_blitar": "College of Economics STIE Kisenegara Blitar"
        },
        {
            "malang_city_polytechnic": "Malang City Polytechnic"
        },
        {
            "high_school_information_management_and_computer_stmik_tasikmalaya": "High School Information Management and Computer STMIK Tasikmalaya"
        },
        {
            "triatma_the_pavents": "Triatma the pavents"
        },
        {
            "poltekkes_jakarta_iii.": "Poltekkes Jakarta III."
        },
        {
            "bandung_law_school_jl_cihampelas_8_bandung": "Bandung Law School Jl Cihampelas 8 Bandung"
        },
        {
            "stia_bandung_college_of_administration": "Stia Bandung College of Administration"
        },
        {
            "college_of_health_sciences_stikes_muhammadiyah_ciamis": "College of Health Sciences STIKES Muhammadiyah Ciamis"
        },
        {
            "college_of_economics_stie_international_tourism": "College of Economics STIE International Tourism"
        },
        {
            "high_school_islamiayah_imam_syafi'i_jember": "High School islamiayah Imam Syafi'i Jember"
        },
        {
            "high_school_information_management_and_computer_stmik_dci_tasikmalaya": "High School Information Management and Computer STMIK DCI Tasikmalaya"
        },
        {
            "stia_lan_makassar_polytechnic": "Stia Lan Makassar Polytechnic"
        },
        {
            "high_school_information_management_and_computer_stmik_widya_utama_purwokerto": "High School Information Management and Computer STMIK Widya Utama Purwokerto"
        },
        {
            "academy_of_atmi_industrial_machinery_techniques_surakarta": "Academy of Atmi Industrial Machinery Techniques Surakarta"
        },
        {
            "padang_polytechnic": "Padang Polytechnic"
        },
        {
            "university_of_muhammadiyah_buton": "University of Muhammadiyah Buton"
        },
        {
            "islamic_college_of_islam_darunnajah": "Islamic College of Islam Darunnajah"
        },
        {
            "pasim_national_university": "Pasim National University"
        },
        {
            "teacher_training_and_education_college_of_stkip_pgri_lubuk_linggau": "Teacher Training and Education College of STKIP PGRI Lubuk Linggau"
        },
        {
            "catholic_university_darma_cendika": "Catholic University Darma Cendika"
        },
        {
            "polytechnic_ati_padang": "Polytechnic Ati Padang"
        },
        {
            "high_school_information_management_and_computer_stmik_palangka_raya": "High School Information Management and Computer STMIK Palangka Raya"
        },
        {
            "ykpn_accounting_academy_yogyakarta": "YKPN Accounting Academy Yogyakarta"
        },
        {
            "akper_ngawi_nursing_academy": "Akper Ngawi Nursing Academy"
        },
        {
            "college_of_economics_stie_pasundan_bandung": "College of Economics STIE Pasundan Bandung"
        },
        {
            "college_of_health_sciences_stikes_baiturrahim_jambi": "College of Health Sciences STIKES Baiturrahim Jambi"
        },
        {
            "high_school_of_national_stia_administration": "High School of National Stia Administration"
        },
        {
            "unity_unitas_university_of_palembang": "Unity Unitas University of Palembang"
        },
        {
            "teacher_training_and_education_college_of_stkip_bina_nation_meulaboh": "Teacher Training and Education College of Stkip Bina Nation Meulaboh"
        },
        {
            "high_school_information_management_and_computer_stmik_likmi_bandung": "High School Information Management and Computer STMIK Likmi Bandung"
        },
        {
            "atlas_nusantara_malang_engineering_college": "Atlas Nusantara Malang Engineering College"
        },
        {
            "university_of_darul_ulum_islamic_center_sudirman": "University of Darul Ulum Islamic Center Sudirman"
        },
        {
            "academy_of_nursing_academy_manggala_husada": "Academy of Nursing Academy Manggala Husada"
        },
        {
            "indonesian_pelita_college": "Indonesian Pelita College"
        },
        {
            "shipping_polytechnic_makassar_makassar_south_sulawesi": "Shipping Polytechnic Makassar Makassar South Sulawesi"
        },
        {
            "yapari_foreign_language_high_school_aba_bandung": "Yapari Foreign Language High School Aba Bandung"
        },
        {
            "high_school_informatics_management_and_computer_stmik_indonesia": "High school informatics management and computer STMIK Indonesia"
        },
        {
            "unika_widya_mandala_madiun": "Unika Widya Mandala Madiun"
        },
        {
            "idb_bali_design_&_business_institute": "IDB Bali Design & Business Institute"
        },
        {
            "college_of_management_sciences_sukma_medan": "College of Management Sciences Sukma Medan"
        },
        {
            "college_of_health_sciences_stikes_aisyiah_palembang": "College of Health Sciences Stikes Aisyiah Palembang"
        },
        {
            "university_of_dayanu_ikhsanuddin_baubau": "University of Dayanu Ikhsanuddin Baubau"
        },
        {
            "college_of_health_sciences_stikes_st_elisabeth": "College of Health Sciences Stikes St Elisabeth"
        },
        {
            "untara_dian_nusantara_university": "Untara Dian Nusantara University"
        },
        {
            "industrial_chemical_technology_polytechnic": "Industrial Chemical Technology Polytechnic"
        },
        {
            "bandung_institute_of_technology_and_science": "Bandung Institute of Technology and Science"
        },
        {
            "sticks_panti_surakarta": "Sticks Panti Surakarta"
        },
        {
            "stba_pertiwi_foreign_high_school": "STBA PERTIWI Foreign High School"
        },
        {
            "college_of_economics_muhammadiyah_stiem_mamuju": "College of Economics Muhammadiyah Stiem Mamuju"
        },
        {
            "university_of_indonesian_putra_unpi_cianjur": "University of Indonesian Putra Unpi Cianjur"
        },
        {
            "college_of_computer_science_stikom_poltek_cirebon": "College of Computer Science Stikom Poltek Cirebon"
        },
        {
            "nahdlatul_university_wathan_mataram_university": "Nahdlatul University Wathan Mataram University"
        },
        {
            "stt_intheos_surakarta_college_of_technology": "STT Intheos Surakarta College of Technology"
        },
        {
            "high_school_tourism_ampta_sleman": "High School Tourism Ampta Sleman"
        },
        {
            "college_of_economics_muhammadiyah_stiem_bandung": "College of Economics Muhammadiyah Stiem Bandung"
        },
        {
            "polytechnic_pajajaran_insan_love_bandung_nation": "Polytechnic Pajajaran Insan Love Bandung Nation"
        },
        {
            "surabaya_flight_polytechnic": "Surabaya Flight Polytechnic"
        },
        {
            "university_of_mpu_tantular": "University of Mpu Tantular"
        },
        {
            "muhammadiyah_jambi_university": "Muhammadiyah Jambi University"
        },
        {
            "stikom_yos_sudarso_college_of_computer_science": "Stikom Yos Sudarso College of Computer Science"
        },
        {
            "iai_bbc_bunga_jaya_for_indonesia_and_world_civilization": "IAI BBC Bunga Jaya for Indonesia and World Civilization"
        },
        {
            "college_of_economics_stie_kertanegara_malang": "College of Economics STIE Kertanegara Malang"
        },
        {
            "stt_reformed_indonesia": "STT Reformed Indonesia"
        },
        {
            "stmik_&_amik_logic_medan": "STMIK & Amik Logic Medan"
        },
        {
            "sttind_padang_industrial_engineering_college": "Sttind Padang Industrial Engineering College"
        },
        {
            "academy_midwifery_akbid_ar_rum_salatiga": "Academy midwifery Akbid ar rum salatiga"
        },
        {
            "bengkulu_ministry_of_health_polytechnic": "Bengkulu Ministry of Health Polytechnic"
        },
        {
            "road_transportation_safety_polytechnic": "Road transportation safety polytechnic"
        },
        {
            "academy_of_nursing_akper_kesdam_bukit_barisan_medan": "Academy of Nursing Akper Kesdam Bukit Barisan Medan"
        },
        {
            "college_of_health_sciences_mitra_husada_medan": "College of Health Sciences Mitra Husada Medan"
        },
        {
            "college_of_economics_stie_ibmt_surabaya": "College of Economics STIE IBMT Surabaya"
        },
        {
            "college_of_economics_stie_amkan_makassar": "College of Economics STIE Amkan Makassar"
        },
        {
            "high_school_risk_management_and_stimra_insurance": "High School Risk Management and Stimra Insurance"
        },
        {
            "catholic_color_school_stk_st_yakobus_merauke": "Catholic Color School Stk St Yakobus Merauke"
        },
        {
            "stt_mandala_bandung_college_of_technology": "STT Mandala Bandung College of Technology"
        },
        {
            "polytechnic_lpp_yogyakarta": "Polytechnic LPP Yogyakarta"
        },
        {
            "stie_stemi_bandung_business_school": "Stie Stemi Bandung Business School"
        },
        {
            "high_school_information_management_and_computer_stmik_ikmi_cirebon": "High School Information Management and Computer STMIK IKMI Cirebon"
        },
        {
            "nahdlatul_ulama_unu_university_surakarta": "Nahdlatul Ulama Unu University Surakarta"
        },
        {
            "tarakanita_stiks": "Tarakanita Stiks"
        },
        {
            "institute_of_islamic_religious_iii_muhammadiyah_bima": "Institute of Islamic Religious III Muhammadiyah Bima"
        },
        {
            "institute_of_islamic_religious_nahdlatul_ulama_i_kebumen_iainu_kebumen": "Institute of Islamic Religious Nahdlatul Ulama I Kebumen Iainu Kebumen"
        },
        {
            "high_school_of_stiper_dharmawacana_metro_lampung_metro": "High School of Stiper Dharmawacana Metro Lampung Metro"
        },
        {
            "akademi_maritim_cirebon_amc": "Akademi Maritim Cirebon AMC"
        },
        {
            "college_of_health_sciences_stikes_muhammadiyah_kendal": "College of Health Sciences STIKES Muhammadiyah Kendal"
        },
        {
            "polytechnic_immigration_polytechnic": "Polytechnic Immigration Polytechnic"
        },
        {
            "academy_of_informatics_management_and_computer_valley_dempo_kota_pagar_alam": "Academy of Informatics Management and Computer Valley Dempo Kota Pagar Alam"
        },
        {
            "midwifery_academy_of_akbid_ummi_khasanah_yogyakarta": "Midwifery Academy of Akbid Ummi Khasanah Yogyakarta"
        },
        {
            "cirebon_staic_islamic_high_school": "Cirebon Staic Islamic High School"
        },
        {
            "college_of_economics_stie_business_indonesia": "College of Economics STIE Business Indonesia"
        },
        {
            "malang_polbangtan": "Malang polbangtan"
        },
        {
            "ikifa_pharmaceutical_academy": "Ikifa Pharmaceutical Academy"
        },
        {
            "akamigas_palembang_polytechnic": "Akamigas Palembang Polytechnic"
        },
        {
            "stah_dharma_nusantara_jakarta": "STAH Dharma Nusantara Jakarta"
        },
        {
            "high_school_of_stai_syamsul_'ulum": "High School of Stai Syamsul 'Ulum"
        },
        {
            "college_of_economics_of_shieve_shiep_april_sumedang": "College of Economics of SHIEVE SHIEP APRIL SUMEDANG"
        },
        {
            "college_of_economics_stie_dr_kh_ez_mutaqien_purwakarta": "College of Economics STIE DR KH EZ Mutaqien Purwakarta"
        },
        {
            "college_of_health_sciences_stikes_alifah_padang": "College of Health Sciences STIKES Alifah Padang"
        },
        {
            "indonesian_college_of_science_management_stimi_banjarmasin": "Indonesian College of Science Management Stimi Banjarmasin"
        },
        {
            "akfar_indonesia_pharmaceutical_academy": "Akfar Indonesia Pharmaceutical Academy"
        },
        {
            "medical_medicine_medical_medicine_medicine_medical_recorder_academy_and_medika_surakarta": "Medical Medicine Medical Medicine Medicine Medical Recorder Academy and Medika Surakarta"
        },
        {
            "nahdlatul_ulama_blitar_university": "Nahdlatul Ulama Blitar University"
        },
        {
            "academy_of_informatics_management_and_computer_amik_jtc_semarang": "Academy of Informatics Management and Computer Amik JTC Semarang"
        },
        {
            "institute_of_islamic_religious_iai_uluwiyah": "Institute of Islamic Religious IAI Uluwiyah"
        },
        {
            "university_of_widya_husada_semarang": "University of Widya Husada Semarang"
        },
        {
            "stai_ali_bin_bin_abi_thalib_surabaya_high_school_high_school": "Stai Ali Bin Bin Abi Thalib Surabaya High School High School"
        },
        {
            "islamic_college_of_islam_stai_al_falah_banjarbaru": "Islamic College of Islam Stai Al Falah Banjarbaru"
        },
        {
            "polbangtan_manokwari": "Polbangtan Manokwari"
        },
        {
            "west_papua_polytechnic": "West Papua Polytechnic"
        },
        {
            "poltekpar_palembang": "POLTEKPAR Palembang"
        },
        {
            "college_of_marine_engineering_stateect_behind_makassar": "College of Marine Engineering Stateect behind Makassar"
        },
        {
            "stt_reformed_international_stt_high_school_international_statt_reformed": "STT Reformed International Stt High School International Statt Reformed"
        },
        {
            "indonesian_tourism_academy_akpindo": "Indonesian tourism academy AKPINDO"
        },
        {
            "mahasaraswati_university_mataram": "Mahasaraswati University Mataram"
        },
        {
            "college_of_health_sciences_stikes_kepanjen_malang": "College of Health Sciences STIKES Kepanjen Malang"
        },
        {
            "hindu_high_school_state_stahn_gde_pudja_mataram": "Hindu High School State Stahn Gde Pudja Mataram"
        },
        {
            "jia_bekasi_foreign_language_college": "Jia Bekasi Foreign Language College"
        },
        {
            "college_of_economics_stie_totalwin_semarang": "College of Economics STIE Totalwin Semarang"
        },
        {
            "sekolah_tinggi_ilmu_kesehatan_stikes_panti_waluya_malang": "Sekolah Tinggi Ilmu Kesehatan STIKES Panti Waluya Malang"
        },
        {
            "college_of_computer_science_stikom_pgri_banyuwangi": "College of Computer Science Stikom PGRI Banyuwangi"
        },
        {
            "high_school_bethel_indonesia_sttbi": "High school bethel indonesia sttbi"
        },
        {
            "polytechnic_tourism_polytechnic_makassar": "Polytechnic Tourism Polytechnic Makassar"
        },
        {
            "college_of_economics_stie_dewantara_bogor": "College of Economics STIE Dewantara Bogor"
        },
        {
            "college_of_economics_stie_yapan_surabaya": "College of Economics STIE Yapan Surabaya"
        },
        {
            "college_of_health_sciences_stikes_mahardika_cirebon": "College of Health Sciences STIKES Mahardika Cirebon"
        },
        {
            "college_of_government_science_stip_ardi_state": "College of Government Science Stip Ardi State"
        },
        {
            "college_of_economics_stie_bisma_lepisi_tangerang": "College of Economics STIE Bisma Lepisi Tangerang"
        },
        {
            "midwifery_academy_akbid_kharisma_husada_binjai": "Midwifery Academy Akbid Kharisma Husada Binjai"
        },
        {
            "high_school_management_information_and_computer_stmik_dian_cipta_scholar_lampung": "High School Management Information and Computer STMIK Dian Cipta Scholar Lampung"
        },
        {
            "college_of_information_management_computer_stikom_uyelindo_kupang": "College of Information Management Computer Stikom Uyelindo Kupang"
        },
        {
            "jayapura_university_of_science_and_technology": "Jayapura University of Science and Technology"
        },
        {
            "stias_mother_of_the_holy_heart": "STIAS Mother of the Holy Heart"
        },
        {
            "islamic_college_of_islam_almawaddah_warrahmah_kolaka": "Islamic College of Islam Almawaddah Warrahmah Kolaka"
        },
        {
            "mahadewa_university": "Mahadewa University"
        },
        {
            "college_of_economics_stie_banten_serang": "College of Economics STIE Banten Serang"
        },
        {
            "college_of_economics_stie_development_tanjungpinang": "College of Economics STIE Development Tanjungpinang"
        },
        {
            "college_of_technical_wiworotomo_purwokerto": "College of Technical Wiworotomo Purwokerto"
        },
        {
            "academy_of_atmi_industrial_machinery_techniques_cikarang": "Academy of ATMI Industrial Machinery Techniques Cikarang"
        },
        {
            "polytechnic_al_islam_bandung": "Polytechnic Al Islam Bandung"
        },
        {
            "indonesian_land_transportation_polytechnic_ptdi_sttd": "Indonesian land transportation polytechnic PTDI STTD"
        },
        {
            "university_of_mh_thamrin_campus_aka": "University of MH Thamrin Campus AKA"
        },
        {
            "college_of_economics_stie_tridharma_bandung": "College of Economics STIE Tridharma Bandung"
        },
        {
            "college_of_economics_stie_widya_manggala": "College of Economics STIE Widya Manggala"
        },
        {
            "high_school_computer_science_technology_superior_cilegon": "High School Computer Science Technology Superior Cilegon"
        },
        {
            "akfar_surabaya_pharmacy_academy": "Akfar Surabaya Pharmacy Academy"
        },
        {
            "academy_of_secretary_and_management_of_taruna_bakti": "Academy of Secretary and Management of Taruna Bakti"
        },
        {
            "college_of_health_sciences_stikes_yarsi_pontianak": "College of Health Sciences Stikes Yarsi Pontianak"
        },
        {
            "college_of_computer_science_stikom_binanaga_bogor": "College of Computer Science Stikom Binanaga Bogor"
        },
        {
            "simplicity_polytechnic_stmi_jakarta": "Simplicity Polytechnic Stmi Jakarta"
        },
        {
            "informatics_management_academy_and_computer_amik_citra_buana_indonesia_sukabumi": "Informatics Management Academy and Computer Amik Citra Buana Indonesia Sukabumi"
        },
        {
            "high_school_information_management_and_computer_stmik_spb_airlangga_samarinda": "High School Information Management and Computer STMIK SPB Airlangga Samarinda"
        },
        {
            "stie_ibek_college_of_economics": "STIE Ibek College of Economics"
        },
        {
            "institute_of_teacher_training_and_education_ikip_pgri_east_kalimantan": "Institute of Teacher Training and Education IKIP PGRI East Kalimantan"
        },
        {
            "high_school_health_sciences_stikes_wira_medika_bali": "High School Health Sciences Stikes Wira Medika Bali"
        },
        {
            "high_school_information_management_and_computer_stmik_dharmapala_riau": "High School Information Management and Computer STMIK Dharmapala Riau"
        },
        {
            "high_school_information_management_and_computer_stmik_light_surya_kediri": "High School Information Management and Computer STMIK Light Surya Kediri"
        },
        {
            "kaltara_university": "Kaltara University"
        },
        {
            "kapuas_sintang_unka_university": "Kapuas Sintang Unka University"
        },
        {
            "university_of_soerjo_ngawi.": "University of Soerjo Ngawi."
        },
        {
            "college_of_economics_stie_cirebon": "College of Economics STIE Cirebon"
        },
        {
            "college_of_pharmacy_stifi_bhakti_pertiwi": "College of Pharmacy Stifi Bhakti Pertiwi"
        },
        {
            "academy_of_the_dawn_blitar_district_putra_community": "Academy of the Dawn Blitar District Putra Community"
        },
        {
            "academy_of_informatics_management_and_computers_amik_serang": "Academy of Informatics Management and Computers Amik Serang"
        },
        {
            "high_school_information_management_and_computer_ppkia_tarakanita_rahmawati_tarakan": "High School Information Management and Computer PPKIA Tarakanita Rahmawati Tarakan"
        },
        {
            "teacher_training_and_education_college_of_stkip_pgri_pasuruan": "Teacher Training and Education College of STKIP PGRI Pasuruan"
        },
        {
            "high_school_information_management_and_computer_stmik_dharma_country_bandung": "High School Information Management and Computer STMIK Dharma Country Bandung"
        },
        {
            "stp_bogor_tourism_college": "STP Bogor Tourism College"
        },
        {
            "fti_engineering_campus_universitas_jayabaya": "FTI Engineering campus Universitas Jayabaya"
        },
        {
            "stienas_national_economics_college_of_banjarmasin": "Stienas National Economics College of Banjarmasin"
        },
        {
            "high_school_of_islamic_religion_stai_nu_pacitan": "High School of Islamic Religion Stai Nu Pacitan"
        },
        {
            "college_of_economics_stie_pasim_sukabumi": "College of Economics STIE PASIM SUKABUMI"
        },
        {
            "stmik_pranata_indonesia": "STMIK Pranata Indonesia"
        },
        {
            "college_of_economics_stie_h_agus_salim_bukittinggi": "College of Economics STIE H Agus Salim Bukittinggi"
        },
        {
            "teacher_training_and_education_college_of_stkip_pgri_trenggalek": "Teacher Training and Education College of Stkip Pgri Trenggalek"
        },
        {
            "stt_hkbp.": "STT HKBP."
        },
        {
            "university_of_muhammadiyah_tangerang_faculty_of_health_sciences": "University of Muhammadiyah Tangerang Faculty of Health Sciences"
        },
        {
            "indonesian_campus": "Indonesian campus"
        },
        {
            "polytechnic_medica_farma_husada_mataram": "Polytechnic Medica Farma Husada Mataram"
        },
        {
            "bitung_marine_and_fisheries_polytechnic": "Bitung Marine and Fisheries Polytechnic"
        },
        {
            "postgraduate_school_of_uika_bogor": "Postgraduate School of UIKA Bogor"
        },
        {
            "university_of_45_mataram.": "University of 45 Mataram."
        },
        {
            "baubau_polytechnic": "Baubau Polytechnic"
        },
        {
            "polytechnic_kutaraja": "Polytechnic Kutaraja"
        },
        {
            "polytechnic_of_the_polytechnic_equator_pontianak": "Polytechnic of the Polytechnic Equator Pontianak"
        },
        {
            "medika_institute_drg_suherman": "Medika Institute Drg Suherman"
        },
        {
            "college_of_economics_stie_cendekia_main_karya": "College of Economics STIE Cendekia Main Karya"
        },
        {
            "southeast_sulawesi_university": "Southeast Sulawesi University"
        },
        {
            "west_nusa_tenggara_university_mataram": "West Nusa Tenggara University Mataram"
        },
        {
            "high_school_health_sciences_stikes_tulungagung": "High School Health Sciences STIKES Tulungagung"
        },
        {
            "college_of_health_sciences_stella_maris_makassar": "College of Health Sciences Stella Maris Makassar"
        },
        {
            "high_school_information_management_and_computer_stmik_samarinda": "High School Information Management and Computer STMIK Samarinda"
        },
        {
            "ama_yogyakarta": "Ama yogyakarta"
        },
        {
            "academy_of_management_of_informatics_and_computers_amik_indonesia": "Academy of Management of Informatics and Computers Amik Indonesia"
        },
        {
            "bandung_practitioner_polytechnic": "Bandung Practitioner Polytechnic"
        },
        {
            "karsa_makassar_pharmacy_academy": "Karsa Makassar Pharmacy Academy"
        },
        {
            "sps_perbanas_postgraduate_school": "SPS Perbanas Postgraduate School"
        },
        {
            "ypiq_west_sumatra_islamic_college": "YPIQ West Sumatra Islamic College"
        },
        {
            "college_of_chemical_analyst_cilegon_tangerang": "College of Chemical Analyst Cilegon Tangerang"
        },
        {
            "indonesian_sties_purwakarta": "Indonesian Sties Purwakarta"
        },
        {
            "stifarm_padang": "STIFARM Padang"
        },
        {
            "high_school_of_law_stih_princess_maharaja_payakumbuh": "High School of Law STIH Princess Maharaja Payakumbuh"
        },
        {
            "college_of_health_sciences_stikes_advait_medika_tabanan": "College of Health Sciences Stikes Advait Medika Tabanan"
        },
        {
            "college_of_health_sciences_stikes_adi_husada": "College of Health Sciences STIKES Adi Husada"
        },
        {
            "akper_dharma_wacana_metro": "AKPER Dharma Wacana Metro"
        },
        {
            "itb_semarang.": "ITB Semarang."
        },
        {
            "akper_sidoarjo_nursing_academy": "Akper Sidoarjo Nursing Academy"
        },
        {
            "high_school_of_communication_science_stikom_prosia": "High School of Communication Science Stikom Prosia"
        },
        {
            "teacher_training_and_education_college_of_stkip_pgri_lumajang": "Teacher Training and Education College of STKIP PGRI Lumajang"
        },
        {
            "high_school_information_management_and_computer_stmik_neumann": "High School Information Management and Computer STMIK Neumann"
        },
        {
            "high_school_of_nation_ambassador_technology": "High School of Nation Ambassador Technology"
        },
        {
            "banjaregara_polytechnic": "Banjaregara Polytechnic"
        },
        {
            "academy_of_midwifery_akbid_al_ikhlas": "Academy of midwifery Akbid Al Ikhlas"
        },
        {
            "academy_secretary_and_management_of_asekma_don_bosco": "Academy Secretary and Management of Asekma Don Bosco"
        },
        {
            "asm_ariyanti": "ASM Ariyanti"
        },
        {
            "academy_of_ykpn_architecture_engineering": "Academy of YKPN Architecture Engineering"
        },
        {
            "stikom_bandung_college_college": "Stikom Bandung College College"
        },
        {
            "college_of_social_sciences_and_political_sciences_stispol_wira_bhakti_denpasar": "College of Social Sciences and Political Sciences Stispol Wira Bhakti Denpasar"
        },
        {
            "ybs_international_technology_college_bandung": "YBS International Technology College Bandung"
        },
        {
            "college_of_economics_stie_unisadhuguna_business_school_ubs": "College of Economics STIE Unisadhuguna Business School UBS"
        },
        {
            "prisma_university": "Prisma University"
        },
        {
            "college_of_economics_stie_jaya_malang_state": "College of Economics STIE Jaya Malang State"
        },
        {
            "college_of_health_sciences_stikes_khariswa_karawang": "College of Health Sciences Stikes Khariswa Karawang"
        },
        {
            "mandala_tourism_academy_bhakti_sukoharjo": "Mandala Tourism Academy Bhakti Sukoharjo"
        },
        {
            "state_islamic_religious_institute_iain_laa_roiba_bogor": "State Islamic Religious Institute Iain Laa Roiba Bogor"
        },
        {
            "academy_of_nursing_akper_kesdam_i_padang": "Academy of Nursing Akper Kesdam I Padang"
        },
        {
            "academy_of_akper_nursing_rs_pgi_cikini": "Academy of Akper Nursing RS PGI Cikini"
        },
        {
            "apeka_karanganyar_livestock_academy": "Apeka Karanganyar Livestock Academy"
        },
        {
            "college_of_economics_stie_private_mandiri_surakarta": "College of Economics STIE Private Mandiri Surakarta"
        },
        {
            "college_of_economics_stie_asia_malang": "College of Economics STIE Asia Malang"
        },
        {
            "college_of_economics_stie_nganjuk_nganjuk": "College of Economics STIE Nganjuk Nganjuk"
        },
        {
            "college_of_economics_stie_wijaya_mulya_surakarta": "College of Economics STIE Wijaya Mulya Surakarta"
        },
        {
            "stifa_makassar_college_of_pharmacy": "Stifa Makassar College of Pharmacy"
        },
        {
            "college_of_health_sciences_stikes_dharma_landbouw_padang": "College of Health Sciences Stikes Dharma Landbouw Padang"
        },
        {
            "college_of_management_science_stim_lasharan_jaya_makassar": "College of Management Science Stim Lasharan Jaya Makassar"
        },
        {
            "yogyakarta_stipy_psychology_college": "Yogyakarta Stipy Psychology College"
        },
        {
            "college_of_social_sciences_and_political_sciences,_yuppentek": "College of Social Sciences and Political Sciences, Yuppentek"
        },
        {
            "surakarta_technology_high_school": "Surakarta Technology High School"
        },
        {
            "unisma_polytechnic_malang": "Unisma Polytechnic Malang"
        },
        {
            "sleman_fire_polytechnic": "Sleman Fire Polytechnic"
        },
        {
            "university_of_musi_rawas.": "University of Musi Rawas."
        },
        {
            "pt_jakarta_aviation_training_centre": "PT Jakarta Aviation Training Centre"
        },
        {
            "polinas_makassar": "Polinas Makassar"
        },
        {
            "triguna_tasikmalaya_polytechnic": "Triguna Tasikmalaya Polytechnic"
        },
        {
            "sulawesi_sulawesi_university_": "Sulawesi Sulawesi University "
        },
        {
            "abk_pmi_jakarta": "ABK PMI Jakarta"
        },
        {
            "midwifery_academy_akbid_karya_bunda_husada_tangerang": "Midwifery Academy AKBID Karya Bunda Husada Tangerang"
        },
        {
            "college_of_economics_stie_bumi_persada_lhokseumawe": "College of Economics STIE Bumi Persada Lhokseumawe"
        },
        {
            "college_of_economics_stie_isti_ekatana_upaweda_yogyakarta": "College of Economics STIE Isti Ekatana Upaweda Yogyakarta"
        },
        {
            "stie_youth_college_of_economics": "STIE Youth College of Economics"
        },
        {
            "college_of_economics_stie_indonesian_banking_padang": "College of Economics STIE Indonesian Banking Padang"
        },
        {
            "college_of_economics_stie_st_pignatelli_surakarta": "College of Economics STIE ST Pignatelli Surakarta"
        },
        {
            "high_school_of_law_stah_tambun_bungai": "High School of Law STAH Tambun Bungai"
        },
        {
            "college_of_health_sciences_stikes_al_islam_yogyakarta": "College of Health Sciences Stikes Al Islam Yogyakarta"
        },
        {
            "college_of_health_sciences_stikes_nusantara_kupang": "College of Health Sciences Stikes Nusantara Kupang"
        },
        {
            "college_of_health_sciences_stikes_tamalatea_makassar": "College of Health Sciences STIKES Tamalatea Makassar"
        },
        {
            "teacher_training_and_education_college_of_stkip_pgri_blitar": "Teacher Training and Education College of STKIP PGRI Blitar"
        },
        {
            "high_school_information_management_and_computer_stmik_borneo": "High School Information Management and Computer STMIK Borneo"
        },
        {
            "high_school_information_management_and_computer_stmik_dharma_wacana_metro": "High School Information Management and Computer STMIK Dharma Wacana Metro"
        },
        {
            "indonesian_taxation_high_school_stpi": "Indonesian Taxation High School STPI"
        },
        {
            "high_school_community_government_stpmd_apmd_yogyakarta": "High school community government stpmd apmd yogyakarta"
        },
        {
            "trisakti_school_of_multimedia": "Trisakti School of Multimedia"
        },
        {
            "dumoga_kotamobagu_university": "Dumoga Kotamobagu University"
        },
        {
            "universitas_perwira_purbalingga_unperba": "Universitas Perwira Purbalingga UNPERBA"
        },
        {
            "stark_college_college_bontang": "Stark College College Bontang"
        },
        {
            "high_school_information_management_and_computer_stmik_ichsan_gorontalo": "High School Information Management and Computer STMIK Ichsan Gorontalo"
        },
        {
            "faculty_of_theology_of_indonesian_christian_university_tomohon": "Faculty of theology of Indonesian Christian University Tomohon"
        },
        {
            "college_of_teacher_training_and_science_of_stkip_persada_equatorial_sintang_sintang": "College of Teacher Training and Science of STKIP Persada Equatorial Sintang Sintang"
        },
        {
            "bandung_a2b_accounting_academy": "Bandung A2B Accounting Academy"
        },
        {
            "tirta_wiyata_engineering_academy": "Tirta Wiyata Engineering Academy"
        },
        {
            "academy_of_midwifery_akbid_borneo_medistra_balikpapan": "Academy of midwifery Akbid Borneo Medistra Balikpapan"
        },
        {
            "yppm_mandiri_pharmaceutical_academy": "YPPM Mandiri Pharmaceutical Academy"
        },
        {
            "null": null
        },
        {
            "null": null
        },
        {
            "null": null
        },
        {
            "akper_wonogiri_nursing_academy": "Akper Wonogiri Nursing Academy"
        },
        {
            "yogyakarta_plantation_community_academy": "Yogyakarta Plantation Community Academy"
        },
        {
            "indraphrasta_tourism_academy": "Indraphrasta Tourism Academy"
        },
        {
            "stba_foreign_language_high_school_has": "Stba Foreign Language High School Has"
        },
        {
            "college_of_economics_stie_lhokseumawe": "College of Economics STIE Lhokseumawe"
        },
        {
            "syariah_syariah_economics_high_school_nahdlatul_ulama_stiesnu_bengkulu": "Syariah Syariah Economics High School Nahdlatul Ulama Stiesnu Bengkulu"
        },
        {
            "college_of_economics_stie_widya_persada": "College of Economics STIE Widya Persada"
        },
        {
            "al-fatah_bengkulu_high_school": "Al-Fatah Bengkulu High School"
        },
        {
            "univterbuka_palembang": "UnivTerbuka Palembang"
        },
        {
            "college_of_health_sciences_stikes_yapika_makassar": "College of Health Sciences Stikes Yapika Makassar"
        },
        {
            "tarbiyah_college_of_science_stit_madina_sragen": "Tarbiyah College of Science Stit Madina Sragen"
        },
        {
            "academy_of_academy_of_akper_yaspen_jakarta": "Academy of Academy of Akper Yaspen Jakarta"
        },
        {
            "indonesian_maritime_academy_medan": "Indonesian Maritime Academy Medan"
        },
        {
            "university_of_dharma_makassar": "University of Dharma Makassar"
        },
        {
            "stei_islamic_college_of_islamic_economics_yogyakarta": "Stei Islamic College of Islamic Economics Yogyakarta"
        },
        {
            "college_of_economics_stie_triguna": "College of Economics STIE Triguna"
        },
        {
            "college_of_economics_stie_kalpataru_bogor": "College of Economics STIE Kalpataru Bogor"
        },
        {
            "high_school_of_economic_science_stie_mulia_singkawang": "High School of Economic Science Stie Mulia Singkawang"
        },
        {
            "high_school_of_islamic_religion_stai_siliwangi_bandung": "High School of Islamic Religion Stai Siliwangi Bandung"
        },
        {
            "high_school_philosophy_stf_widya_sasana": "High School Philosophy STF Widya Sasana"
        },
        {
            "high_school_and_islamic_sciences_starting_riyadlul_ulum": "High School and Islamic Sciences Starting Riyadlul Ulum"
        },
        {
            "college_of_economics_stie_gema_widya_bangsa_bandung": "College of Economics STIE Gema Widya Bangsa Bandung"
        },
        {
            "college_of_economics_stie_ekadharma_indonesia": "College of Economics STIE EKadharma Indonesia"
        },
        {
            "college_of_economics_stie_ibnu_sina_batam": "College of Economics STIE Ibnu Sina Batam"
        },
        {
            "college_of_economics_stie_muhammadiyah_asahan": "College of Economics STIE Muhammadiyah Asahan"
        },
        {
            "college_of_economics_stie_tenggarong": "College of Economics STIE Tenggarong"
        },
        {
            "college_of_health_sciences_stikes_kurnia_jaya_persada_palopo": "College of Health Sciences STIKES Kurnia Jaya Persada Palopo"
        },
        {
            "postgraduate_school_of_the_jakarta_art_institute": "Postgraduate School of the Jakarta Art Institute"
        },
        {
            "poltekes_bhakti_pertiwi_husada_cirebon": "Poltekes Bhakti Pertiwi Husada Cirebon"
        },
        {
            "unizar_medicine_faculty": "Unizar Medicine Faculty"
        },
        {
            "tarbiyah_college_of_science_stit_al_hilal_sigli": "Tarbiyah College of Science Stit Al Hilal Sigli"
        },
        {
            "teacher_training_and_education_college_of_stkip_muhammadiyah_central_aceh": "Teacher Training and Education College of STKIP Muhammadiyah Central Aceh"
        },
        {
            "teacher_training_and_education_college_of_stkip_muhammadiyah_sungai_full": "Teacher Training and Education College of STKIP Muhammadiyah Sungai Full"
        },
        {
            "stt_global_global_high_school_indonesia": "STT Global Global High School Indonesia"
        },
        {
            "sttl_mataram_environment_high_school": "STTL Mataram Environment High School"
        },
        {
            "sumpm_fighants_fighanture_business_school_tegal": "SUMPM FIGHANTS FIGHANTURE BUSINESS SCHOOL TEGAL"
        },
        {
            "cokroaminoto_university": "Cokroaminoto University"
        },
        {
            "islamic_institute_mamba_ulum_iim_surakarta": "Islamic Institute Mamba Ulum Iim Surakarta"
        },
        {
            "institute_of_technology_and_health_avicenna_kendari": "Institute of Technology and Health Avicenna Kendari"
        },
        {
            "stai_islamic_college_of_islam_ddi_parepare": "Stai Islamic College of Islam Ddi Parepare"
        },
        {
            "high_school_of_stai_ibnu_sina_batam": "High School of Stai Ibnu Sina Batam"
        },
        {
            "high_school_islamic_religion_nahdlatul_ulama_stainu_madiun": "High School Islamic Religion Nahdlatul Ulama Stainu Madiun"
        },
        {
            "high_school_of_islamic_religion_stai_yastis_padang": "High School of Islamic Religion Stai Yastis Padang"
        },
        {
            "college_of_law_stih_muhammadiyah_asahan_range": "College of Law STIH Muhammadiyah Asahan Range"
        },
        {
            "academy_of_midwifery_akbid_dharma_praja_bondowoso": "Academy of midwifery Akbid Dharma Praja Bondowoso"
        },
        {
            "nusantara_jaya_tourism_academy": "Nusantara Jaya Tourism Academy"
        },
        {
            "high_school_health_sciences_stikes_pmc": "High School Health Sciences Stikes PMC"
        },
        {
            "teacher_training_and_education_college_of_stkip_arrahmaniyah": "Teacher Training and Education College of Stkip Arrahmaniyah"
        },
        {
            "stt_baptist_calvary_high_school": "STT Baptist Calvary High School"
        },
        {
            "stt_theology_high_school_pelita_truth_medan": "STT Theology High School Pelita Truth Medan"
        },
        {
            "high_school_of_science_tarbiyah_stit_ibnu_sina": "High School of Science Tarbiyah Stit Ibnu Sina"
        },
        {
            "masters_in_management_of_jayabaya_university": "Masters in Management of Jayabaya University"
        },
        {
            "academy_of_art_and_design_asride_iswi_jakarta": "Academy of Art and Design Asride Iswi Jakarta"
        },
        {
            "stt_pelita_hati": "STT Pelita Hati"
        },
        {
            "stai_fatahillah_islamic_college": "Stai Fatahillah Islamic College"
        },
        {
            "college_of_administrative_sciences_stia_yappi_makassar": "College of Administrative Sciences Stia Yappi Makassar"
        },
        {
            "stikes_rspad_gatot_soebroto": "STIKes RSPAD Gatot Soebroto"
        }
    ],
    "slovakia": [
        {
            "comenius_university_in_bratislava": "Comenius University in Bratislava"
        },
        {
            "slovak_university_of_technology_in_bratislava": "Slovak University of Technology in Bratislava"
        },
        {
            "technical_university_of_ko\u00e5\u00a1ice": "Technical University of Ko\u00c5\u00a1ice"
        },
        {
            "slovak_university_of_agriculture_in_nitra": "Slovak University of Agriculture in Nitra"
        },
        {
            "university_of_\u00e5\u00bdilina": "University of \u00c5\u00bdilina"
        },
        {
            "university_of_constantinus_the_philosopher_in_nitra": "University of Constantinus the Philosopher in Nitra"
        },
        {
            "matej_bel_university_in_banska_bystrica": "Matej Bel University in Banska Bystrica"
        },
        {
            "technical_university_in_zvolen": "Technical University in Zvolen"
        },
        {
            "university_of_economics_in_bratislava": "University of Economics in Bratislava"
        },
        {
            "university_of_st_cyril_and_methodius_of_trnava": "University of St Cyril and Methodius of Trnava"
        },
        {
            "university_of_trnava": "University of Trnava"
        },
        {
            "university_of_presov": "University of Presov"
        },
        {
            "slovak_medical_university_in_bratislava": "Slovak Medical University in Bratislava"
        },
        {
            "catholic_university_in_ru\u00e5\u00beomberok": "Catholic University in Ru\u00c5\u00beomberok"
        },
        {
            "university_of_veterinary_medicine_and_pharmacy_in_ko\u00e5\u00a1ice": "University of Veterinary Medicine and Pharmacy in Ko\u00c5\u00a1ice"
        },
        {
            "pan_european_university_(bratislava_school_of_law)": "Pan European University (Bratislava School of Law)"
        },
        {
            "j_selye_university": "J Selye University"
        },
        {
            "international_business_college_ism_slovakia_in_pre\u00e5\u00a1ov": "International Business College ISM Slovakia in Pre\u00c5\u00a1ov"
        },
        {
            "st_elizabeth_college_of_health_and_social_work_in_bratislava": "St Elizabeth College of Health and Social Work in Bratislava"
        },
        {
            "academy_of_fine_arts_and_design_bratislava": "Academy of Fine Arts and Design Bratislava"
        },
        {
            "academy_of_performing_arts_in_bratislava": "Academy of Performing Arts in Bratislava"
        },
        {
            "academy_of_arts_in_banska_bystrica": "Academy of Arts in Banska Bystrica"
        },
        {
            "general_milan_rastislav_\u00e5\u00a0tef\u00e3\u00a1nik_armed_forces_academy_in_liptovsk\u00e3\u00bd_mikul\u00e3\u00a1\u00e5\u00a1": "General Milan Rastislav \u00c5\u00a0tef\u00c3\u00a1nik Armed Forces Academy in Liptovsk\u00c3\u00bd Mikul\u00c3\u00a1\u00c5\u00a1"
        },
        {
            "college_of_public_administration_economics_and_management": "College of Public Administration Economics and Management"
        },
        {
            "security_management_college_in_ko\u00e5\u00a1ice": "Security Management College in Ko\u00c5\u00a1ice"
        },
        {
            "police_academy_in_bratislava": "Police Academy in Bratislava"
        },
        {
            "dti_university": "DTI University"
        },
        {
            "danubius_university_in_sladkovi\u00e4\u008dovo": "Danubius University in Sladkovi\u00c4\u008dovo"
        }
    ],
    "lithuania": [
        {
            "vilnius_university": "Vilnius University"
        },
        {
            "lithuanian_university_of_health_science_(kaunas_university_of_medicine_veterinary_academy)": "Lithuanian University of Health Science (Kaunas University of Medicine Veterinary Academy)"
        },
        {
            "vytautas_magnus_university": "Vytautas Magnus University"
        },
        {
            "mykolas_romeris_university": "Mykolas Romeris University"
        },
        {
            "kaunas_university_of_technology": "Kaunas University of Technology"
        },
        {
            "klaipeda_university": "Klaipeda University"
        },
        {
            "vilnius_gediminas_technical_university": "Vilnius Gediminas Technical University"
        },
        {
            "lithuanian_sports_university": "Lithuanian Sports University"
        },
        {
            "ism_university_of_management_and_economics": "ISM University of Management and Economics"
        },
        {
            "vilnius_academy_of_fine_arts": "Vilnius Academy of Fine Arts"
        },
        {
            "lcc_international_university": "LCC International University"
        },
        {
            "kazimiero_simonavi\u00e4\u008diaus_university": "Kazimiero Simonavi\u00c4\u008diaus University"
        },
        {
            "european_humanities_university": "European Humanities University"
        },
        {
            "lithuanian_academy_of_music_and_theatre": "Lithuanian Academy of Music and Theatre"
        },
        {
            "vilnius_university_of_applied_sciences": "Vilnius University of Applied Sciences"
        },
        {
            "kaunas_college": "Kaunas College"
        },
        {
            "general_jonas_zemaitis_military_academy_of_lithuania": "General Jonas Zemaitis Military Academy of Lithuania"
        },
        {
            "university_of_applied_social_sciences": "University of Applied Social Sciences"
        },
        {
            "klaip\u00e4\u2014da_state_college": "Klaip\u00c4\u2014da State College"
        },
        {
            "studio_quality_assessment_center": "Studio Quality Assessment Center"
        },
        {
            "alytus_college": "Alytus College"
        },
        {
            "marijampol\u00e4\u2014_college": "Marijampol\u00c4\u2014 College"
        },
        {
            "vilnius_business_college": "Vilnius Business College"
        },
        {
            "utena_college": "Utena College"
        },
        {
            "lithuania_business_college": "Lithuania Business College"
        },
        {
            "vilnius_international_school_of_law_and_business": "Vilnius International School of Law and Business"
        },
        {
            "vilnius_college_of_design": "Vilnius College of Design"
        },
        {
            "north_lithuania_college": "North Lithuania College"
        },
        {
            "lithuanian_maritime_academy": "Lithuanian Maritime Academy"
        },
        {
            "st_ignatius_of_loyola_college": "St Ignatius of Loyola College"
        },
        {
            "tel\u00e5\u00a1iai_bishop_vincentas_borisevi\u00e4\u008dius_priest_seminary": "Tel\u00c5\u00a1iai Bishop Vincentas Borisevi\u00c4\u008dius Priest Seminary"
        }
    ],
    "belarus": [
        {
            "belarusian_state_university": "Belarusian State University"
        },
        {
            "belarusian_national_technical_university": "Belarusian National Technical University"
        },
        {
            "gomel_state_university": "Gomel State University"
        },
        {
            "belarusian_state_university_of_informatics_and_radioelectronics": "Belarusian State University of Informatics and Radioelectronics"
        },
        {
            "yanka_kupala_state_university_of_grodno": "Yanka Kupala State University of Grodno"
        },
        {
            "belarusian_state_medical_university": "Belarusian State Medical University"
        },
        {
            "belarusian_state_technological_university": "Belarusian state Technological University"
        },
        {
            "grodno_state_medical_university": "Grodno State Medical University"
        },
        {
            "brest_state_university_as_pushkin": "Brest State University AS Pushkin"
        },
        {
            "polotsk_state_university": "Polotsk State University"
        },
        {
            "brest_state_technical_university": "Brest State Technical University"
        },
        {
            "belarusian_state_economic_university": "Belarusian State Economic University"
        },
        {
            "belarusian_state_pedagogical_university_m_tank": "Belarusian State Pedagogical University M Tank"
        },
        {
            "vitebsk_state_technological_university": "Vitebsk State Technological University"
        },
        {
            "vitebsk_state_medical_university": "Vitebsk State Medical University"
        },
        {
            "vitebsk_state_university_p_m_masherov": "Vitebsk State University P M Masherov"
        },
        {
            "gomel_state_medical_university": "Gomel State Medical University"
        },
        {
            "belarusian-russian_university": "Belarusian-Russian University"
        },
        {
            "gomel_state_technical_university_p_o_sukhoi": "Gomel State Technical University P O Sukhoi"
        },
        {
            "mogilev_state_university_a_kuleshov": "Mogilev State University A Kuleshov"
        },
        {
            "grodno_state_agrarian_university": "Grodno State Agrarian University"
        },
        {
            "belurusian_medical_academy_of_post_graduate_of_education": "belurusian medical academy of post graduate of education"
        },
        {
            "polessky_state_university": "Polessky State University"
        },
        {
            "belarusian_state_university_of_transport": "Belarusian State University of Transport"
        },
        {
            "academy_of_public_administration_of_the_president_of_the_republic_of_belarus": "Academy of Public Administration of the President of the Republic of Belarus"
        },
        {
            "vitebsk_state_academy_of_veterinary_medicine": "Vitebsk State Academy of Veterinary Medicine"
        },
        {
            "belarusian_state_university_of_culture_and_art": "Belarusian State University of Culture and Art"
        },
        {
            "belarusian_trade_and_economics_university_of_consumer_cooperatives": "Belarusian Trade and Economics University of Consumer Cooperatives"
        },
        {
            "mozyr_state_pedagogical_university_i_p_shamyakin": "Mozyr State Pedagogical University I P Shamyakin"
        },
        {
            "university_of_civil_protection": "University of Civil Protection"
        },
        {
            "minsk_state_linguistic_university": "Minsk State Linguistic University"
        },
        {
            "belarusian_state_agrarian_technical_university": "Belarusian State Agrarian Technical University"
        },
        {
            "belarusian_state_university_of_physical_culture": "Belarusian State University of Physical Culture"
        },
        {
            "bip_university_of_law_and_social_and_information_technologies": "BIP University of Law and Social and Information Technologies"
        },
        {
            "federation_of_trade_unions_of_belarus_international_university_mitso": "Federation of Trade Unions of Belarus International University MITSO"
        },
        {
            "mogilev_institute_of_the_ministry_of_internal_affairs_of_the_republic_of_belarus": "Mogilev Institute of the Ministry of Internal Affairs of The Republic of Belarus"
        },
        {
            "academy_of_postgraduate_education": "Academy of Postgraduate Education"
        },
        {
            "minsk_university_of_management": "Minsk University of Management"
        },
        {
            "baranovichy_state_university": "Baranovichy State University"
        },
        {
            "academy_of_the_ministry_of_internal_affairs": "Academy of the Ministry of Internal Affairs"
        },
        {
            "institute_of_entrepreneurial_activity": "Institute of Entrepreneurial Activity"
        },
        {
            "institute_of_border_guard_of_the_republic_of_belarus": "Institute of Border Guard of the Republic of Belarus"
        },
        {
            "institute_of_parliamentarism_and_entrepreneurship": "Institute of Parliamentarism and Entrepreneurship"
        },
        {
            "belarusian_state_academy_of_telecommunications": "Belarusian State Academy of Telecommunications"
        },
        {
            "belarusian_state_academy_of_aviation": "Belarusian State Academy of Aviation"
        },
        {
            "military_academy_of_belarus": "Military Academy of Belarus"
        },
        {
            "belarusian_state_academy_of_music": "Belarusian State Academy of Music"
        },
        {
            "minsk_state_higher_radioengineering_college": "Minsk State Higher Radioengineering College"
        },
        {
            "belarusian_state_university_of_food_and_chemical_technologies": "Belarusian State University of Food and Chemical Technologies"
        }
    ],
    "qatar": [
        {
            "qatar_university": "Qatar University"
        },
        {
            "hamad_bin_khalifa_university": "Hamad Bin Khalifa University"
        },
        {
            "texas_a&m_university_at_qatar": "Texas A&M University at Qatar"
        },
        {
            "weill_cornell_medical_college_in_qatar": "Weill Cornell Medical College in Qatar"
        },
        {
            "carnegie_mellon_university_in_qatar": "Carnegie Mellon University in Qatar"
        },
        {
            "virginia_commonwealth_university_in_qatar": "Virginia Commonwealth University in Qatar"
        },
        {
            "northwestern_university_in_qatar": "Northwestern University in Qatar"
        },
        {
            "doha_institute_for_graduate_studies": "Doha Institute for Graduate Studies"
        },
        {
            "college_of_the_north_atlantic_qatar": "College of the North Atlantic Qatar"
        },
        {
            "hec_paris_in_qatar": "HEC Paris in Qatar"
        },
        {
            "city_university_college_qatar": "City university College Qatar"
        }
    ],
    "united arab emirates": [
        {
            "khalifa_university": "Khalifa University"
        },
        {
            "united_arab_emirates_university": "United Arab Emirates University"
        },
        {
            "university_of_sharjah": "University of Sharjah"
        },
        {
            "american_university_of_sharjah": "American University of Sharjah"
        },
        {
            "zayed_university": "Zayed University"
        },
        {
            "higher_colleges_of_technology": "Higher Colleges of Technology"
        },
        {
            "abu_dhabi_university": "Abu Dhabi University"
        },
        {
            "nyu_abu_dhabi": "NYU Abu Dhabi"
        },
        {
            "american_university_of_ras_al_khaimah_aurak": "American University of Ras al Khaimah AURAK"
        },
        {
            "ajman_university": "Ajman University"
        },
        {
            "university_of_wollongong_in_dubai": "University of Wollongong in Dubai"
        },
        {
            "al_ain_university": "Al Ain University"
        },
        {
            "gulf_medical_university": "Gulf Medical University"
        },
        {
            "british_university_in_dubai": "British University in Dubai"
        },
        {
            "university_of_dubai": "University of Dubai"
        },
        {
            "hamdan_bin_mohammed_smart_university": "Hamdan Bin Mohammed Smart University"
        },
        {
            "american_university_in_dubai": "American University in Dubai"
        },
        {
            "canadian_university_of_dubai": "Canadian University of Dubai"
        },
        {
            "skyline_university_college": "Skyline University College"
        },
        {
            "manipal_university_dubai_campus": "Manipal University Dubai Campus"
        },
        {
            "american_university_in_the_emirates": "American University in the Emirates"
        },
        {
            "emirates_college_for_advanced_education": "Emirates College for Advanced Education"
        },
        {
            "middlesex_university_dubai_campus": "Middlesex University Dubai Campus"
        },
        {
            "rak_medical_&_health_sciences_university": "RAK Medical & Health Sciences University"
        },
        {
            "mohammed_bin_rashid_university_of_medicine_and_health_sciences": "Mohammed Bin Rashid University of Medicine and Health Sciences"
        },
        {
            "amity_university_dubai": "Amity University Dubai"
        },
        {
            "al_ghurair_university": "Al Ghurair University"
        },
        {
            "emirates_academy_of_hospitality_management": "Emirates Academy of Hospitality Management"
        },
        {
            "al_falah_university": "Al Falah University"
        },
        {
            "institute_of_management_technology_dubai": "Institute of Management Technology Dubai"
        },
        {
            "al_khawarizmi_international_college": "Al Khawarizmi International College"
        },
        {
            "dubai_pharmacy_college": "Dubai Pharmacy College"
        },
        {
            "abu_dhabi_polytechnic": "Abu Dhabi Polytechnic"
        },
        {
            "mohamed_bin_zayed_university_of_artificial_intelligence": "Mohamed bin Zayed University of Artificial Intelligence"
        },
        {
            "paris_sorbonne_university_abu_dhabi": "Paris Sorbonne University Abu Dhabi"
        },
        {
            "mohamed_bin_rashid_school_of_government": "Mohamed Bin Rashid School of Government"
        },
        {
            "murdoch_university_dubai": "Murdoch University Dubai"
        },
        {
            "emirates_aviation_university": "Emirates Aviation University"
        },
        {
            "islamic_azad_university_dubai": "Islamic Azad University Dubai"
        },
        {
            "city_university_college_of_ajman": "City University College of Ajman"
        },
        {
            "american_college_of_dubai": "American College of Dubai"
        },
        {
            "rak_college_of_dental_sciences": "RAK College of Dental Sciences"
        },
        {
            "aldar_university_in_dubai": "Aldar University in Dubai"
        },
        {
            "abu_dhabi_school_of_management": "Abu Dhabi School of Management"
        },
        {
            "fatima_college_of_health_sciences": "Fatima College of Health Sciences"
        },
        {
            "jumeira_university": "Jumeira University"
        },
        {
            "university_of_modern_sciences_(biotechnology_university_college)": "University of Modern Sciences (Biotechnology University College)"
        },
        {
            "emirates_college_for_management_&_information_technology_ecmit": "Emirates College for Management & Information Technology ECMIT"
        },
        {
            "college_of_fashion_and_design_cfd_dubai": "College of Fashion and Design CFD Dubai"
        },
        {
            "european_university_college": "European University College"
        },
        {
            "university_of_fujairah": "University of Fujairah"
        },
        {
            "synergy_university_dubai": "Synergy University Dubai"
        },
        {
            "mohammed_v_university_abu_dhabi": "Mohammed V University Abu Dhabi"
        },
        {
            "centre_for_executive_education": "Centre for Executive Education"
        },
        {
            "university_college_of_mother_&_family_science": "University College of Mother & Family Science"
        }
    ],
    "romania": [
        {
            "university_babes_bolyai": "University Babes Bolyai"
        },
        {
            "alexandru_ioan_cuza_university": "Alexandru Ioan Cuza University"
        },
        {
            "university_politehnica_of_bucharest": "University Politehnica of Bucharest"
        },
        {
            "west_university_of_timisoara": "West University of Timisoara"
        },
        {
            "bucharest_academy_of_economic_studies": "Bucharest Academy of Economic Studies"
        },
        {
            "transilvania_university_of_brasov": "Transilvania University of Brasov"
        },
        {
            "gheorghe_asachi_technical_university": "Gheorghe Asachi Technical University"
        },
        {
            "technical_university_of_cluj-napoca_(north_university_of_baia_mare)": "Technical University of Cluj-Napoca (North University of Baia Mare)"
        },
        {
            "carol_davila_university_of_medicine_and_pharmacy": "Carol Davila University of Medicine and Pharmacy"
        },
        {
            "university_of_medicine_and_pharmacy_iuliu_hatieganu_cluj_napoca": "University of Medicine and Pharmacy Iuliu Hatieganu Cluj Napoca"
        },
        {
            "politehnica_university_timisoara": "Politehnica University Timisoara"
        },
        {
            "university_of_medicine_and_pharmacy_gr_t_popa": "University of Medicine and Pharmacy Gr T Popa"
        },
        {
            "danube_de_jos_university_galati": "Danube de Jos University Galati"
        },
        {
            "university_of_craiova": "University of Craiova"
        },
        {
            "university_stefan_cel_mare_of_suceava": "University Stefan Cel Mare of Suceava"
        },
        {
            "university_of_agricultural_sciences_and_veterinary_medicine_cluj_napoca": "University of Agricultural Sciences and Veterinary Medicine Cluj Napoca"
        },
        {
            "university_lucian_blaga_of_sibiu": "University Lucian Blaga of Sibiu"
        },
        {
            "university_of_oradea": "University of Oradea"
        },
        {
            "university_of_medicine_and_pharmacy_victor_babes_timisoara": "University of Medicine and Pharmacy Victor Babes Timisoara"
        },
        {
            "university_of_bucharest": "University of Bucharest"
        },
        {
            "university_oil_gas_ploiesti": "University Oil Gas Ploiesti"
        },
        {
            "university_of_medicine_and_pharmacy_craiova": "University of Medicine and Pharmacy Craiova"
        },
        {
            "university_of_medicine,_pharmacy,_sciences_and_technology_george_emil_palade_of_t\u00e3\u00aergu_mure\u00e8\u2122": "University of Medicine, Pharmacy, Sciences and Technology George Emil Palade of T\u00c3\u00aergu Mure\u00c8\u2122"
        },
        {
            "university_ovidius": "University Ovidius"
        },
        {
            "university_of_pitesti": "University of Pitesti"
        },
        {
            "wisdom_hungarian_university_of_transylvania": "Wisdom Hungarian University of Transylvania"
        },
        {
            "university_valachia_targoviste": "University Valachia Targoviste"
        },
        {
            "university_aurel_vlaicu_arad": "University Aurel Vlaicu Arad"
        },
        {
            "spiru_haret_university": "Spiru Haret University"
        },
        {
            "university_of_bacau": "University of Bacau"
        },
        {
            "university_of_agricultural_sciences_and_veterinary_medicine_bucharest": "University of Agricultural Sciences and Veterinary Medicine Bucharest"
        },
        {
            "banat_university_of_agricultural_sciences_timisoara": "Banat University of Agricultural Sciences Timisoara"
        },
        {
            "christian_university_dimitrie_cantemir": "Christian University Dimitrie Cantemir"
        },
        {
            "university_titu_maiorescu": "University Titu Maiorescu"
        },
        {
            "university_of_petrosani": "University of Petrosani"
        },
        {
            "university_constantin_brancusi_of_targu-jiu": "University Constantin Brancusi of Targu-Jiu"
        },
        {
            "military_technical_academy": "Military Technical Academy"
        },
        {
            "ion_mincu_university_of_architecture_and_urbanism_bucharest": "Ion Mincu University of Architecture and Urbanism Bucharest"
        },
        {
            "romanian_american_university": "Romanian American University"
        },
        {
            "national_institute_for_research_&_development_in_informatics": "National Institute for Research & Development in Informatics"
        },
        {
            "technical_university_of_civil_engineering_of_bucharest": "Technical University of Civil Engineering of Bucharest"
        },
        {
            "national_school_of_political_studies_and_public_administration": "National School of Political Studies and Public Administration"
        },
        {
            "university_of_agricultural_sciences_and_veterinary_medicine_ion_ionescu_de_la_brad": "University of Agricultural Sciences and Veterinary Medicine Ion Ionescu de la Brad"
        },
        {
            "national_institute_for_research_&_development_in_micro-technologies": "National Institute for Research & Development in Micro-Technologies"
        },
        {
            "danubius_university_of_galati": "Danubius University of Galati"
        },
        {
            "vasile_goldis_western_university_of_arad": "Vasile Goldis Western University of Arad"
        },
        {
            "now_university": "Now university"
        },
        {
            "nicolae_balcescu_landforce_military_academy": "Nicolae Balcescu Landforce Military Academy"
        },
        {
            "constanta_maritime_university": "Constanta Maritime University"
        },
        {
            "naval_academy_mircea_cel_batran": "Naval Academy Mircea Cel Batran"
        },
        {
            "national_defence_university_carol_i": "National Defence University Carol I"
        },
        {
            "romano-british_university_of_bucharest": "Romano-British University of Bucharest"
        },
        {
            "george_enescu_university_of_arts": "George Enescu University of Arts"
        },
        {
            "national_university_of_music_bucharest": "National University of Music Bucharest"
        },
        {
            "central_university_library_of_bucharest": "Central University Library of Bucharest"
        },
        {
            "alexandru_ioan_cuza_police_academy": "Alexandru Ioan Cuza Police Academy"
        },
        {
            "caragiale_academy_of_theatrical_arts_and_cinematography": "Caragiale Academy of Theatrical Arts and Cinematography"
        },
        {
            "national_university_of_fine_arts": "National University of Fine Arts"
        },
        {
            "air_force_academy_henri_coanda": "Air Force Academy Henri Coanda"
        },
        {
            "university_tibiscus_timisoara": "University Tibiscus Timisoara"
        },
        {
            "university_of_art_and_design_cluj-napoca": "University of Art and Design Cluj-Napoca"
        },
        {
            "university_nicolae_titulescu": "University Nicolae Titulescu"
        },
        {
            "hyperion_university": "Hyperion University"
        },
        {
            "parts_christian_university": "PARTS Christian University"
        },
        {
            "university_ecological_bucharest": "University Ecological Bucharest"
        },
        {
            "state_university_of_political_and_administrative_studies_faculty_of_communication_and_public_relations": "State University of Political and Administrative Studies Faculty of Communication and Public Relations"
        },
        {
            "emanuel_university_of_oradea": "Emanuel University of Oradea"
        },
        {
            "national_academy_of_information_mihai_viteazul": "National Academy of Information Mihai Viteazul"
        },
        {
            "george_bacovia_university_bacau": "George Bacovia University Bacau"
        },
        {
            "academy_of_music_gheorghe_dima": "Academy of Music Gheorghe Dima"
        },
        {
            "mihail_kogalniceanu_university": "Mihail Kogalniceanu University"
        },
        {
            "constantin_brancoveanu_university_pitesti": "Constantin Brancoveanu University Pitesti"
        },
        {
            "romanian_banking_institute": "Romanian Banking Institute"
        },
        {
            "protestant_theological_institute_kolozsvar": "Protestant Theological Institute Kolozsvar"
        },
        {
            "lumina_university_of_south-east_europe": "Lumina University of South-East Europe"
        },
        {
            "bogdan_voda_university": "Bogdan Voda University"
        },
        {
            "institute_for_business_and_public_administration_bucharest": "Institute for Business and Public Administration Bucharest"
        },
        {
            "academy_of_medical_sciences": "Academy of Medical Sciences"
        },
        {
            "oradea_university": "Oradea University"
        },
        {
            "national_university_of_physical_education_and_sports": "National University of Physical Education and Sports"
        },
        {
            "university_artist": "University artist"
        },
        {
            "academy_of_agricultural_studies_and_forestry_gheorghe_ionescu": "Academy of Agricultural Studies and Forestry Gheorghe Ionescu"
        },
        {
            "athenaeum_university": "Athenaeum University"
        },
        {
            "bioterra_university": "Bioterra University"
        },
        {
            "university_ion_slavici": "University Ion Slavici"
        },
        {
            "universitatea_arrival": "UniversitateA arrival"
        },
        {
            "pentecostal_bucharest_theological_institute": "Pentecostal Bucharest Theological Institute"
        },
        {
            "satu_mare_commercial_academy": "Satu Mare Commercial Academy"
        }
    ],
    "jordan": [
        {
            "university_of_jordan": "University of Jordan"
        },
        {
            "jordan_university_of_science_&_technology": "Jordan University of Science & Technology"
        },
        {
            "al_balqa_applied_university": "Al Balqa Applied University"
        },
        {
            "hashemite_university": "Hashemite University"
        },
        {
            "yarmouk_university": "Yarmouk University"
        },
        {
            "philadelphia_university_at_jordan": "Philadelphia University at Jordan"
        },
        {
            "princess_sumaya_university_for_technology": "Princess Sumaya University for Technology"
        },
        {
            "al_zaytoonah_university": "Al Zaytoonah University"
        },
        {
            "german_jordanian_university": "German Jordanian University"
        },
        {
            "middle_east_university_jordan": "Middle East University Jordan"
        },
        {
            "al_al-bayt_university": "Al Al-Bayt University"
        },
        {
            "mutah_university": "Mutah University"
        },
        {
            "applied_science_private_university": "Applied Science Private University"
        },
        {
            "al_hussein_bin_talal_university": "Al Hussein bin Talal University"
        },
        {
            "university_of_petra": "University of Petra"
        },
        {
            "al_ahliyya_amman_university": "Al Ahliyya Amman University"
        },
        {
            "zarqa_university": "Zarqa University"
        },
        {
            "tafila_technical_university": "Tafila Technical University"
        },
        {
            "al_israel_university_amman": "Al Israel University Amman"
        },
        {
            "jadar_university": "Jadar University"
        },
        {
            "american_university_of_madaba": "American University of Madaba"
        },
        {
            "amman_arab_university": "Amman Arab University"
        },
        {
            "world_islamic_sciences_and_education_university": "World Islamic Sciences and Education University"
        },
        {
            "jerash_private_university": "Jerash Private University"
        },
        {
            "irbid_national_university": "Irbid National University"
        },
        {
            "arab_open_university_jordan": "Arab Open University Jordan"
        },
        {
            "association_of_arab_universities": "Association of Arab Universities"
        },
        {
            "aqaba_university_of_technology": "Aqaba University of Technology"
        },
        {
            "al_hussein_technical_university": "Al Hussein Technical University"
        },
        {
            "ajloun_national_university": "Ajloun National University"
        },
        {
            "institute_of_banking_studies": "Institute of Banking Studies"
        },
        {
            "arab_academy_for_management_banking_and_financial_sciences": "Arab Academy For Management Banking and financial Sciences"
        },
        {
            "jordan_academy_of_music_higher_institute_of_music": "Jordan Academy of Music Higher Institute of Music"
        },
        {
            "queen_noor_civil_aviation_technical_college": "Queen Noor Civil Aviation Technical College"
        }
    ],
    "kazakhstan": [
        {
            "nazarbayev_university": "Nazarbayev University"
        },
        {
            "kazakh_national_university_al_farabi": "Kazakh National University Al Farabi"
        },
        {
            "satbayev_university": "Satbayev University"
        },
        {
            "asfendiyarov_kazakh_national_medical_university": "Asfendiyarov Kazakh National Medical University"
        },
        {
            "ahmet_yesavi_\u00e3\u0153niversitesi_international_kazakh_turkish_university": "Ahmet Yesavi \u00c3\u0153niversitesi International Kazakh Turkish University"
        },
        {
            "east_kazakhstan_technical_university_d_serikbayev": "East Kazakhstan Technical University D Serikbayev"
        },
        {
            "kazakh_national_agrarian_university": "Kazakh National Agrarian University"
        },
        {
            "karaganda_technical_university": "Karaganda Technical University"
        },
        {
            "kazakh_national_pedagogical_university_abay": "Kazakh National Pedagogical University Abay"
        },
        {
            "almaty_technological_university": "Almaty Technological University"
        },
        {
            "suleyman_demirel_university": "Suleyman Demirel University"
        },
        {
            "kazakh_agrotechnical_university": "Kazakh Agrotechnical University"
        },
        {
            "kazakh-british_technical_university": "Kazakh-British Technical University"
        },
        {
            "e_a_buketov_karaganda_university": "E A Buketov Karaganda University"
        },
        {
            "kazakhstan_institute_of_management_economics_and_strategic_research_kimep_university": "Kazakhstan Institute of Management Economics and Strategic Research KIMEP University"
        },
        {
            "auezov_university": "Auezov University"
        },
        {
            "caspian_university": "Caspian University"
        },
        {
            "pavlodar_regional_institute_for_teachers_advanced_studies": "Pavlodar Regional Institute for Teachers Advanced Studies"
        },
        {
            "almaty_management_university": "Almaty Management University"
        },
        {
            "semey_semipalatinsk_state_university_shakarim": "Semey Semipalatinsk State University Shakarim"
        },
        {
            "astana_medical_university": "Astana Medical University"
        },
        {
            "m_kozybaev_north_kazakhstan_university": "M Kozybaev North Kazakhstan University"
        },
        {
            "karaganda_economical_university_of_kazpotrebsoyuz": "Karaganda Economical University of Kazpotrebsoyuz"
        },
        {
            "taraz_innovative-humanities_university": "Taraz Innovative-Humanities University"
        },
        {
            "turan_university": "Turan University"
        },
        {
            "atyrau_engineering_and_humanitarian_institute": "Atyrau Engineering and Humanitarian Institute"
        },
        {
            "atyrau_university_h_dosmukhamedov": "Atyrau University H Dosmukhamedov"
        },
        {
            "almaty_university_of_power_engineering_and_telecommunications_gumarbek_daukeev": "Almaty University of Power Engineering and Telecommunications Gumarbek Daukeev"
        },
        {
            "deutsch-kasachische_universit\u00e3\u00a4t": "Deutsch-Kasachische Universit\u00c3\u00a4t"
        },
        {
            "semey_medical_university": "Semey Medical University"
        },
        {
            "kazakh_national_university_of_arts": "Kazakh National University of Arts"
        },
        {
            "karaganda_medical_university": "Karaganda Medical University"
        },
        {
            "west_kazakhstan_medical_academy_m_ospanov": "West Kazakhstan Medical Academy M Ospanov"
        },
        {
            "international_it_university": "International IT University"
        },
        {
            "arkalyk_pedagogical_institute_i_altynsarin": "Arkalyk Pedagogical Institute I Altynsarin"
        },
        {
            "kazakh-american_free_university": "Kazakh-American Free University"
        },
        {
            "moscow_state_university_kazakh_branch": "Moscow State University Kazakh Branch"
        },
        {
            "financial_academy": "Financial Academy"
        },
        {
            "central_asian_university": "Central Asian University"
        },
        {
            "academy_of_civil_aviation": "Academy of Civil Aviation"
        },
        {
            "zhubanov_university": "Zhubanov University"
        },
        {
            "south_kazakhstan_medical_academy": "South Kazakhstan Medical Academy"
        },
        {
            "kazakh-russian_international_university": "Kazakh-Russian International University"
        },
        {
            "academy_of_logistics_and_transport": "Academy of Logistics and Transport"
        },
        {
            "kazakh_academy_of_sports_&_tourism": "Kazakh Academy of Sports & Tourism"
        },
        {
            "kazakh_university_of_technology_and_business": "Kazakh University of Technology and Business"
        },
        {
            "turan_astana_tau_university": "Turan Astana TAU University"
        },
        {
            "egyptian_university_islamic_culture_nur_mubarak": "Egyptian University Islamic Culture Nur Mubarak"
        },
        {
            "m_otemisov_west_kazakhstan_university": "M Otemisov West Kazakhstan University"
        },
        {
            "miras_university.": "MIRAS UNIVERSITY."
        },
        {
            "kazakh_automobile_road_institute": "Kazakh Automobile Road Institute"
        },
        {
            "national_university_of_defence_named_after_the_first_president_of_the_republic_of_kazakhstan_elbasy": "National University of Defence named after the First President of the Republic of Kazakhstan Elbasy"
        },
        {
            "kazakh_humanities_and_law_innovation_university": "Kazakh Humanities and Law Innovation University"
        },
        {
            "college_of_international_business_academy": "College of International Business Academy"
        },
        {
            "pavlodar_pedagogical_university": "Pavlodar Pedagogical University"
        },
        {
            "kazakhstan_innovation_university": "Kazakhstan Innovation University"
        },
        {
            "atyrau_university_of_oil_and_gas": "Atyrau University of Oil and Gas"
        },
        {
            "m._yesbulatov_almaty_academy_of_the_ministry_of_internal_affairs_of_the_republic_of_kazakhstan": "M. Yesbulatov Almaty Academy of the Ministry of Internal Affairs of the Republic of Kazakhstan"
        },
        {
            "university_of_almaty": "University of Almaty"
        },
        {
            "astana_university": "Astana University"
        },
        {
            "international_medical_school_almaty": "International Medical School Almaty"
        },
        {
            "international_education_corporation": "International Education Corporation"
        },
        {
            "syrdarya_university": "Syrdarya University"
        },
        {
            "baishev_university_|_baishev_university": "Baishev University | Baishev University"
        }
    ],
    "bulgaria": [
        {
            "sofia_university_st_kliment_ohridski": "Sofia University St Kliment Ohridski"
        },
        {
            "plovdiv_university_paisii_hilendarski": "Plovdiv University Paisii Hilendarski"
        },
        {
            "medical_university_sofia": "Medical University Sofia"
        },
        {
            "technical_university_of_sofia": "Technical University of Sofia"
        },
        {
            "medical_university_prof_dr_paraskev_stoyanov_varna": "Medical University Prof Dr Paraskev Stoyanov Varna"
        },
        {
            "new_bulgarian_university": "New Bulgarian University"
        },
        {
            "university_of_chemical_technology_and_metallurgy_sofia": "University of Chemical Technology and Metallurgy Sofia"
        },
        {
            "trakia_university_stara_zagora": "Trakia University Stara Zagora"
        },
        {
            "medical_university_plovdiv": "Medical University Plovdiv"
        },
        {
            "university_of_ruse_angel_kanchev": "University of Ruse Angel Kanchev"
        },
        {
            "south-west_university_neofit_rilski_blagoevgrad": "South-West University Neofit Rilski Blagoevgrad"
        },
        {
            "technical_university_of_varna": "Technical University of Varna"
        },
        {
            "university_of_national_and_world_economy_sofia": "University of National and World Economy Sofia"
        },
        {
            "bourgas_university_prof_assen_zlatarov": "Bourgas University Prof Assen Zlatarov"
        },
        {
            "university_of_architecture_civil_engineering_and_geodesy": "University of Architecture Civil Engineering and Geodesy"
        },
        {
            "university_of_shumen_bishop_konstantin_of_preslav": "University of Shumen Bishop Konstantin of Preslav"
        },
        {
            "university_of_economics_varna": "University of Economics Varna"
        },
        {
            "agricultural_university_plovdiv": "Agricultural University Plovdiv"
        },
        {
            "veliko_tarnovo_university_st_cyril_and_methodius": "Veliko Tarnovo University St Cyril and Methodius"
        },
        {
            "university_of_library_studies_and_information_technologies_sofia": "University of Library Studies and Information Technologies Sofia"
        },
        {
            "university_of_food_technologies_plovdiv": "University of Food Technologies Plovdiv"
        },
        {
            "university_of_mining_and_geology_st_ivan_rislki_sofia": "University of Mining and Geology St Ivan Rislki Sofia"
        },
        {
            "university_of_forestry_sofia": "University of Forestry Sofia"
        },
        {
            "technical_university_of_gabrovo": "Technical University of Gabrovo"
        },
        {
            "american_university_in_bulgaria": "American University in Bulgaria"
        },
        {
            "medical_university_pleven": "Medical University Pleven"
        },
        {
            "academy_of_economics_dimitar_apostolov_tsenov_svishtov": "Academy of Economics Dimitar Apostolov Tsenov Svishtov"
        },
        {
            "varna_free_university_chernorizets_hrabar": "Varna Free University Chernorizets Hrabar"
        },
        {
            "burgas_free_university": "Burgas Free University"
        },
        {
            "vasil_levski_national_military_university_veliko_tarnovo": "Vasil Levski National Military University Veliko Tarnovo"
        },
        {
            "naval_school_nikola_vaptsarov_varna": "Naval School Nikola Vaptsarov Varna"
        },
        {
            "technical_university_of_sofia_branch_plovdiv": "Technical University of Sofia Branch Plovdiv"
        },
        {
            "national_sports_academy_vasil_levski": "National Sports Academy Vasil Levski"
        },
        {
            "vuzf_university_(higher_school_of_insurance_and_finance)": "VUZF University (Higher School of Insurance and Finance)"
        },
        {
            "todor_kableshkov_university_of_transport_sofia": "Todor Kableshkov University of Transport Sofia"
        },
        {
            "national_academy_of_art_sofia": "National Academy of Art Sofia"
        },
        {
            "national_academy_for_theatre_and_film_arts": "National Academy for Theatre and Film Arts"
        },
        {
            "military_medical_academy_sofia": "Military Medical Academy Sofia"
        },
        {
            "national_music_academy_pancho_vladigerov": "National Music Academy Pancho Vladigerov"
        },
        {
            "academy_of_music_dance_and_fine_arts_plovdiv": "Academy of Music Dance and Fine Arts Plovdiv"
        },
        {
            "european_polytechnic_university": "European Polytechnic University"
        },
        {
            "rakovski_national_defence_college": "Rakovski National Defence College"
        },
        {
            "diplomatic_institute_at_the_ministry_of_foreign_affairs_bulgaria": "Diplomatic Institute at the Ministry of Foreign Affairs Bulgaria"
        },
        {
            "agricultural_academy": "Agricultural Academy"
        },
        {
            "university_of_telecommunications_and_posts": "University of Telecommunications and Posts"
        },
        {
            "college_of_management_trade_and_marketing_sofia": "College of Management Trade and Marketing Sofia"
        },
        {
            "university_of_security_and_economics_plovdiv": "University of Security and Economics Plovdiv"
        },
        {
            "theatre_college_luben_groys": "Theatre College Luben Groys"
        }
    ],
    "pakistan": [
        {
            "comsats_institute_of_information_technology_islamabad": "COMSATS Institute of Information Technology Islamabad"
        },
        {
            "university_of_the_punjab": "University of the Punjab"
        },
        {
            "quaid-i-azam_university_islamabad": "Quaid-i-Azam University Islamabad"
        },
        {
            "university_of_agriculture_faisalabad": "University of Agriculture Faisalabad"
        },
        {
            "lahore_university_of_management_sciences": "Lahore University of Management Sciences"
        },
        {
            "government_college_university_faisalabad": "Government College University Faisalabad"
        },
        {
            "university_of_engineering_&_technology_lahore": "University of Engineering & Technology Lahore"
        },
        {
            "bahauddin_zakariya_university": "Bahauddin Zakariya University"
        },
        {
            "university_of_lahore": "University of Lahore"
        },
        {
            "riphah_international_university": "Riphah International University"
        },
        {
            "abdul_wali_khan_university_mardan": "Abdul Wali Khan University Mardan"
        },
        {
            "national_university_of_computer_&_emerging_sciences": "National University of Computer & Emerging Sciences"
        },
        {
            "university_of_engineering_&_technology_taxila": "University of Engineering & Technology Taxila"
        },
        {
            "national_university_of_sciences_&_technology": "National University of Sciences & Technology"
        },
        {
            "mehran_university_of_engineering_&_technology": "Mehran University of Engineering & Technology"
        },
        {
            "government_college_university_lahore": "Government College University Lahore"
        },
        {
            "university_of_arid_agriculture_rawalpindi": "University of Arid Agriculture Rawalpindi"
        },
        {
            "university_of_gujrat": "University of Gujrat"
        },
        {
            "pakistan_institute_of_engineering_&_applied_sciences_islamabad": "Pakistan Institute of Engineering & Applied Sciences Islamabad"
        },
        {
            "agricultural_university_peshawar": "Agricultural University Peshawar"
        },
        {
            "hazara_university": "Hazara University"
        },
        {
            "allama_iqbal_open_university": "Allama Iqbal Open University"
        },
        {
            "university_of_veterinary_and_animal_sciences_lahore": "University of Veterinary and Animal Sciences Lahore"
        },
        {
            "kohat_university_of_science_&_technology": "Kohat University of Science & Technology"
        },
        {
            "forman_christian_college_lahore": "Forman Christian College Lahore"
        },
        {
            "dow_university_of_health_sciences_karachi": "Dow University of Health Sciences Karachi"
        },
        {
            "institute_of_business_administration_sukkur": "Institute of Business Administration Sukkur"
        },
        {
            "gomal_university_dera_ismail_khan": "Gomal University Dera Ismail Khan"
        },
        {
            "islamia_college_peshawar": "Islamia College Peshawar"
        },
        {
            "lahore_college_for_women_university": "Lahore College for Women University"
        },
        {
            "university_of_peshawar": "University of Peshawar"
        },
        {
            "balochistan_university_of_information_technology,_engineering_and_management_sciences": "Balochistan University of Information Technology, Engineering and Management Sciences"
        },
        {
            "university_of_sindh": "University of Sindh"
        },
        {
            "iqra_university_karachi": "Iqra University Karachi"
        },
        {
            "federal_urdu_university_of_arts_sciences_and_technology_fuuast_karachi": "Federal Urdu University of Arts Sciences and Technology FUUAST Karachi"
        },
        {
            "university_of_sargodha": "University of Sargodha"
        },
        {
            "university_of_management_and_technology": "University of Management and Technology"
        },
        {
            "ziauddin_university": "ziauddin university"
        },
        {
            "mohammad_ali_jinnah_university_karachi_campus": "Mohammad Ali Jinnah University Karachi Campus"
        },
        {
            "university_of_balochistan": "University of Balochistan"
        },
        {
            "hamdard_university": "Hamdard University"
        },
        {
            "university_of_health_sciences_lahore": "University of Health Sciences Lahore"
        },
        {
            "pakistan_air_force_karachi_institute_of_economics_&_technology": "Pakistan Air Force Karachi Institute of Economics & Technology"
        },
        {
            "institute_of_business_administration_karachi": "Institute of Business Administration Karachi"
        },
        {
            "sindh_agriculture_university": "Sindh Agriculture University"
        },
        {
            "university_of_central_punjab": "University of Central Punjab"
        },
        {
            "national_college_of_business_administration_&_economics": "National College of Business Administration & Economics"
        },
        {
            "cecos_university": "Cecos University"
        },
        {
            "foundation_university_institute_of_management_and_computer_science": "Foundation University Institute of Management and Computer Science"
        },
        {
            "shifa_tameer_e_millat_university": "Shifa Tameer e Millat University"
        },
        {
            "fatima_jinnah_women_university": "Fatima Jinnah Women University"
        },
        {
            "city_university_of_science_&_information_technology_peshawar": "City University of Science & Information Technology Peshawar"
        },
        {
            "sir_syed_university_of_engineering_&_technology": "Sir Syed University of Engineering & Technology"
        },
        {
            "virtual_university_of_pakistan": "Virtual University of Pakistan"
        },
        {
            "nuclear_institute_for_agriculture_and_biology": "Nuclear Institute for Agriculture and Biology"
        },
        {
            "jinnah_sindh_medical_university": "Jinnah Sindh Medical University"
        },
        {
            "isra_university": "Isra University"
        },
        {
            "khyber_medical_college": "Khyber Medical College"
        },
        {
            "shaheed_zulfikar_ali_bhutto_institute_of_science_&_technology_islamabad": "Shaheed Zulfikar Ali Bhutto Institute of Science & Technology Islamabad"
        },
        {
            "national_university_of_modern_languages_islamabad": "National University of Modern Languages Islamabad"
        },
        {
            "liaquat_university_of_medical_and_health_sciences": "Liaquat University of Medical and Health Sciences"
        },
        {
            "university_of_wah": "University of Wah"
        },
        {
            "quaid-e-awam_university_of_engineering_science_&_technology": "Quaid-e-Awam University of Engineering Science & Technology"
        },
        {
            "university_of_science_&_technology_bannu": "University of Science & Technology Bannu"
        },
        {
            "university_of_haripur": "University of Haripur"
        },
        {
            "national_university_of_medical_sciences_nums_pakistan": "National University of Medical Sciences NUMS Pakistan"
        },
        {
            "comsats_institute_of_information_technology_wah": "COMSATS Institute of Information Technology Wah"
        },
        {
            "baqai_medical_university": "Baqai Medical University"
        },
        {
            "pak_institute_of_management_and_computer_sciences": "Pak Institute of Management and Computer Sciences"
        },
        {
            "hajvey_university_lahore": "Hajvey University Lahore"
        },
        {
            "jinnah_university_for_women": "Jinnah University for Women"
        },
        {
            "lahore_garrison_university": "Lahore Garrison University"
        },
        {
            "khawaja_freed_university_of_engineering_&_information_technology": "Khawaja Freed University of Engineering & Information Technology"
        },
        {
            "sarhad_university_of_science_&_information_technology": "Sarhad University of Science & Information Technology"
        },
        {
            "shah_abdul_latif_university": "Shah Abdul Latif University"
        },
        {
            "al_khawarizmi_institute_of_computer_science": "Al Khawarizmi Institute of Computer Science"
        },
        {
            "muhammad_nawaz_shareef_university_of_agriculture": "Muhammad Nawaz Shareef University of Agriculture"
        },
        {
            "ayub_medical_college": "Ayub Medical College"
        },
        {
            "university_of_swat": "University of Swat"
        },
        {
            "heavy_industries_taxila_education_city_university": "Heavy Industries Taxila Education City University"
        },
        {
            "shaheed_zulfiqar_ali_bhutto_medical_university_islamabad": "Shaheed Zulfiqar Ali Bhutto Medical University Islamabad"
        },
        {
            "khyber_medical_university": "Khyber Medical University"
        },
        {
            "university_of_swabi": "University of Swabi"
        },
        {
            "national_university_of_technology_nutech_islamabad": "National University of Technology NUTECH Islamabad"
        },
        {
            "kinnaird_college_for_women": "Kinnaird College for Women"
        },
        {
            "university_of_okara": "University of Okara"
        },
        {
            "abasyn_university": "Abasyn University"
        },
        {
            "qurtaba_university_of_science_&_information_technology_dera_ismail_khan": "Qurtaba University of Science & Information Technology Dera Ismail Khan"
        },
        {
            "dawood_university_of_engineering_&_technology": "Dawood University of Engineering & Technology"
        },
        {
            "sindh_madresatul_islamic_university": "Sindh Madresatul Islamic University"
        },
        {
            "ghazi_university": "Ghazi University"
        },
        {
            "center_for_advance_studies_in_engineering": "Center for Advance Studies In Engineering"
        },
        {
            "institute_for_art_and_culture_lahore": "Institute for Art and Culture Lahore"
        },
        {
            "habib_university": "Habib University"
        },
        {
            "government_college_women_university_faisalabad": "Government College Women University Faisalabad"
        },
        {
            "college_of_physicians_&_surgeons_pakistan": "College of Physicians & Surgeons Pakistan"
        },
        {
            "bacha_khan_university": "Bacha Khan University"
        },
        {
            "women_university_multan": "Women University Multan"
        },
        {
            "university_of_technology_nowshera": "University of Technology Nowshera"
        },
        {
            "allama_iqbal_medical_college": "Allama Iqbal Medical College"
        },
        {
            "pak-austria_fachhochschule_institute_of_applied_sciences_and_technology_haripur": "Pak-Austria Fachhochschule Institute of Applied Sciences and Technology Haripur"
        },
        {
            "imperial_university_lahore": "Imperial University Lahore"
        },
        {
            "institute_of_engineering_&_technological_training_multan": "Institute of Engineering & Technological Training Multan"
        },
        {
            "national_defence_university_islamabad": "National Defence University Islamabad"
        },
        {
            "usman_institute_of_technology_hamdard_university": "Usman Institute of Technology Hamdard University"
        },
        {
            "institute_of_management_sciences_peshawar": "Institute of Management Sciences Peshawar"
        },
        {
            "jinnah_medical_college_peshawar": "Jinnah Medical College Peshawar"
        },
        {
            "indus_university_karachi": "Indus University Karachi"
        },
        {
            "university_of_south_asia_lahore": "University of South Asia Lahore"
        },
        {
            "pakistan_institute_of_medical_sciences": "Pakistan Institute of Medical Sciences"
        },
        {
            "pakistan_institute_of_nuclear_science_and_technology": "Pakistan Institute of Nuclear Science and Technology"
        },
        {
            "sindh_institute_of_urology_and_transplantation": "Sindh Institute of Urology and Transplantation"
        },
        {
            "shaheed_benazir_bhutto_university_shaheed_benazirabad": "Shaheed Benazir Bhutto University Shaheed Benazirabad"
        },
        {
            "abbottabad_university_of_science_and_technology": "Abbottabad University of Science and Technology"
        },
        {
            "rawalpindi_medical_university": "Rawalpindi Medical University"
        },
        {
            "ilma_university": "ILMA University"
        },
        {
            "beg_nustat_bhutto_women_university_sukkur": "Beg nustat bhutto women university sukkur"
        },
        {
            "university_of_home_economics_lahore": "University of Home Economics Lahore"
        },
        {
            "peshawar_medical_college": "Peshawar Medical College"
        },
        {
            "karachi_institute_of_technology_and_entrepreneurship": "Karachi Institute of Technology and Entrepreneurship"
        },
        {
            "lahore_leads_university": "Lahore Leads University"
        },
        {
            "university_of_sialkot": "University of Sialkot"
        },
        {
            "iqra_national_university_peshawar": "Iqra National University Peshawar"
        },
        {
            "cadet_college_hasan_abdal": "Cadet College Hasan Abdal"
        },
        {
            "dadabhoy_institute_of_higher_edcation_karachi": "Dadabhoy Institute of Higher Edcation Karachi"
        },
        {
            "pakistan_institute_of_fashion_and_design": "Pakistan Institute of Fashion and Design"
        },
        {
            "ali_institute_of_education": "Ali Institute of Education"
        },
        {
            "shaheed_mohtarma_benazir_bhutto_medical_university": "Shaheed Mohtarma Benazir Bhutto Medical University"
        },
        {
            "abdus_salam_school_of_mathematical_sciences_gc_university_lahore": "Abdus Salam School of Mathematical Sciences GC University Lahore"
        },
        {
            "university_of_loralai": "University of Loralai"
        },
        {
            "federal_urdu_university_of_arts_sciences_and_technology_fuuast_islamabad": "Federal Urdu University of Arts Sciences and Technology FUUAST Islamabad"
        },
        {
            "greenwich_university_karachi": "Greenwich University Karachi"
        },
        {
            "health_services_academy_cabinet_division_government_of_pakistan": "Health Services Academy Cabinet Division Government of Pakistan"
        },
        {
            "shalamar_medical_and_dental_college": "Shalamar Medical and Dental College"
        },
        {
            "newports_institute_of_communications_and_economics_karachi": "Newports Institute of Communications and Economics Karachi"
        },
        {
            "national_institute_of_cardiovascular_diseases": "National Institute of Cardiovascular Diseases"
        },
        {
            "institute_of_southern_punjab_multan": "Institute of Southern Punjab Multan"
        },
        {
            "government_sadiq_college_women_university_bahawalpur": "Government Sadiq College Women University Bahawalpur"
        },
        {
            "riphah_international_university_faisalabad": "Riphah International University Faisalabad"
        },
        {
            "akhtar_saeed_medical_and_dental_college": "Akhtar Saeed Medical and Dental College"
        },
        {
            "northern_university_nowshera_cantonment": "Northern University Nowshera Cantonment"
        },
        {
            "jinnah_medical_&_dental_college": "Jinnah Medical & Dental College"
        },
        {
            "jinnah_islamia_college_lahore": "Jinnah Islamia College Lahore"
        },
        {
            "military_college_jhelum": "Military College Jhelum"
        },
        {
            "al_hamd_islamic_university": "Al Hamd Islamic University"
        },
        {
            "sheikh_zayed_medical_college": "Sheikh Zayed Medical College"
        },
        {
            "nfc_institute_of_engineering_&_fertilizer_research": "NFC Institute of Engineering & Fertilizer Research"
        },
        {
            "karshi_university": "Karshi University"
        },
        {
            "rawalpindi_women_university_rawalpindi": "Rawalpindi Women University Rawalpindi"
        },
        {
            "khushal_khan_khattak_university": "Khushal Khan Khattak University"
        },
        {
            "college_of_accounting_and_management_sciences": "College of Accounting and Management Sciences"
        },
        {
            "muslim_youth_university_islamabad": "Muslim Youth University Islamabad"
        },
        {
            "piqc_institute_of_quality_(pakistan_institute_of_quality_control)": "PIQC Institute of Quality (Pakistan Institute of Quality Control)"
        },
        {
            "women_university_swabi": "Women University Swabi"
        },
        {
            "al_kauthar_islamic_university": "Al Kauthar Islamic University"
        },
        {
            "nazeer_hussian_university_karachi": "Nazeer Hussian University Karachi"
        },
        {
            "benazir_bhutto_shaheed_university_lyari": "Benazir Bhutto Shaheed University Lyari"
        },
        {
            "services_institute_of_medical_sciences_lahore": "Services Institute of Medical Sciences Lahore"
        },
        {
            "sir_syed_college_of_medical_sciences_for_girls": "Sir Syed College of Medical Sciences for Girls"
        },
        {
            "university_of_sahiwal": "University of Sahiwal"
        },
        {
            "university_of_chitral": "University of Chitral"
        },
        {
            "brains_post_graduate_college": "Brains Post Graduate College"
        },
        {
            "national_centre_of_excellence_in_analytical_chemistry_university_of_sindh": "National Centre of Excellence in Analytical Chemistry University of Sindh"
        },
        {
            "university_of_engineering_&_technology_uet_mardan": "University of Engineering & Technology UET Mardan"
        },
        {
            "army_public_college_of_management_and_sciences": "Army Public College of Management and Sciences"
        },
        {
            "islamabad_medical_&_dental_college": "Islamabad Medical & Dental College"
        },
        {
            "wah_medical_college": "Wah Medical College"
        },
        {
            "khyber_girls_medical_college_peshawar": "Khyber Girls Medical College Peshawar"
        },
        {
            "only_international_university": "Only International University"
        },
        {
            "university_college_lahore": "University College Lahore"
        },
        {
            "fatima_jinnah_medical_university_lahore": "Fatima Jinnah Medical University Lahore"
        },
        {
            "altamash_institute_of_dental_medicine": "Altamash Institute of Dental Medicine"
        },
        {
            "punjab_tianjin_university_of_technology_lahore": "Punjab Tianjin University of Technology Lahore"
        },
        {
            "bolan_medical_college": "Bolan Medical College"
        },
        {
            "government_college_university_lahore": "Government College University Lahore"
        },
        {
            "edwardes_college": "Edwardes College"
        },
        {
            "university_of_mianwali": "University of Mianwali"
        },
        {
            "institute_of_industrial_electronics_engineering": "Institute of Industrial Electronics Engineering"
        },
        {
            "global_institute_lahore": "Global Institute Lahore"
        },
        {
            "ahmad_medical_institute": "Ahmad Medical Institute"
        },
        {
            "quaid-ezam_lawn_coolge": "Quaid-ezam lawn coolge"
        },
        {
            "karachi_school_of_art": "Karachi School of Art"
        },
        {
            "women_university_mardan": "Women University Mardan"
        },
        {
            "grafton_college_of_management_sciences_islamabad": "Grafton College of Management Sciences Islamabad"
        },
        {
            "gambat_institute_of_medical_sciences": "Gambat Institute of Medical Sciences"
        },
        {
            "national_skills_university": "National Skills University"
        },
        {
            "saidu_medical_college_swat": "Saidu Medical College Swat"
        },
        {
            "women_medical_college": "Women Medical College"
        },
        {
            "frontier_medical_college_abbottabad": "Frontier Medical College Abbottabad"
        },
        {
            "government_post_graduate_college_sahiwal": "Government Post Graduate College Sahiwal"
        },
        {
            "university_of_jhang": "University of Jhang"
        },
        {
            "times_institute_multan": "Times Institute Multan"
        },
        {
            "liaquat_college_of_medicine_&_dentistry": "Liaquat College of Medicine & Dentistry"
        },
        {
            "muhammad_medical_college": "Muhammad Medical College"
        },
        {
            "hyderabad_institute_of_arts_science_&_technology": "Hyderabad Institute of Arts Science & Technology"
        },
        {
            "aligarh_institute_of_technology": "Aligarh Institute of Technology"
        },
        {
            "pakistan_college_of_law": "Pakistan College of Law"
        },
        {
            "lahore_leads_university": "Lahore Leads University"
        },
        {
            "university_of_sufism_and_modern_sciences_bhitshah_sindh": "University of Sufism and Modern Sciences Bhitshah Sindh"
        },
        {
            "islamia_degree_college_saharanpur": "Islamia Degree College Saharanpur"
        },
        {
            "the_institute_of_legal_studies": "The Institute of Legal Studies"
        },
        {
            "city_college_of_science_&_commerce_multan": "City College of Science & Commerce Multan"
        },
        {
            "misbahudduja_university": "Misbahudduja University"
        },
        {
            "government_college_university_hyderabad": "Government College University Hyderabad"
        },
        {
            "frontier_institute_of_medical_sciences_abbottabad": "Frontier Institute of Medical Sciences Abbottabad"
        },
        {
            "murray_college_(government_murray_college_sialkot)": "Murray College (Government Murray College Sialkot)"
        },
        {
            "salim_habib_university": "Salim Habib University"
        },
        {
            "quaid-e-azam_college_of_accountancy_&_commerce": "Quaid-e-Azam College of Accountancy & Commerce"
        }
    ],
    "oman": [
        {
            "sultan_qaboos_university": "Sultan Qaboos University"
        },
        {
            "dhofar_university": "Dhofar University"
        },
        {
            "sohar_university": "Sohar University"
        },
        {
            "university_of_nizwa": "University of Nizwa"
        },
        {
            "german_university_of_technology_in_oman": "German University of Technology in Oman"
        },
        {
            "higher_college_of_technology": "Higher College of Technology"
        },
        {
            "salalah_college_of_technology": "Salalah College of Technology"
        },
        {
            "college_of_applied_sciences_rustaq": "College of Applied Sciences Rustaq"
        },
        {
            "middle_east_college": "Middle East College"
        },
        {
            "national_university_of_science_and_technology": "National University of Science and Technology"
        },
        {
            "nizwa_college_of_technology": "Nizwa College of Technology"
        },
        {
            "a'sharqiyah_university": "A'Sharqiyah University"
        },
        {
            "al_musanna_college_of_technology": "Al Musanna College of Technology"
        },
        {
            "gulf_college": "Gulf College"
        },
        {
            "ibra_college_of_technology": "Ibra College of Technology"
        },
        {
            "university_of_buraimi": "University of Buraimi"
        },
        {
            "modern_college_of_business_&_science": "Modern College of Business & Science"
        },
        {
            "on_university_college": "On University College"
        },
        {
            "majan_university_college.": "Majan University College."
        },
        {
            "arab_open_university_oman": "Arab Open University Oman"
        },
        {
            "muscat_college": "Muscat College"
        },
        {
            "college_of_banking_and_financial_studies": "College of Banking and Financial Studies"
        },
        {
            "al_buraimi_university_college": "Al Buraimi University College"
        },
        {
            "scientific_college_of_design": "Scientific College of Design"
        },
        {
            "ibri_college_of_technology": "Ibri College of Technology"
        },
        {
            "international_maritime_college_oman": "International Maritime College Oman"
        },
        {
            "muscat_university": "Muscat University"
        },
        {
            "oman_dental_college": "Oman Dental College"
        },
        {
            "global_college_of_engineering_and_technology": "Global College of Engineering and Technology"
        },
        {
            "waljat_college_of_applied_sciences": "Waljat College of Applied Sciences"
        },
        {
            "mazoon_college": "Mazoon College"
        },
        {
            "al_zahra_college_for_women": "Al Zahra College for Women"
        }
    ],
    "ukraine": [
        {
            "national_taras_shevchenko_university_of_kyiv": "National Taras Shevchenko University of Kyiv"
        },
        {
            "sumy_state_university": "Sumy State University"
        },
        {
            "national_technical_university_kharkiv_polytechnical_institute": "National Technical University Kharkiv Polytechnical Institute"
        },
        {
            "national_aerospace_university_kharkiv_aviation_institute": "National Aerospace University Kharkiv Aviation Institute"
        },
        {
            "national_university_of_life_and_environmental_sciences_of_ukraine_(national_agricultural_university)": "National University of Life and Environmental Sciences of Ukraine (National Agricultural University)"
        },
        {
            "dnipro_university_of_technology": "Dnipro University of Technology"
        },
        {
            "kharkiv_national_university_of_radio_electronics": "Kharkiv National University of Radio Electronics"
        },
        {
            "kharkiv_national_university_vn_karazin": "Kharkiv National University VN Karazin"
        },
        {
            "vinnitsa_national_technical_university": "Vinnitsa National Technical University"
        },
        {
            "dnipro_national_university_honchar": "Dnipro National University Honchar"
        },
        {
            "odessa_state_polytechnic_university": "Odessa State Polytechnic University"
        },
        {
            "ternopil_ivan_pul'uj_national_technical_university": "Ternopil Ivan Pul'uj National Technical University"
        },
        {
            "lviv_polytechnic_national_university": "Lviv Polytechnic National University"
        },
        {
            "vinnitsa_national_pirogov_memorial_medical_university": "Vinnitsa National Pirogov Memorial Medical University"
        },
        {
            "kryvyi_rih_national_university": "Kryvyi Rih National University"
        },
        {
            "kyiv_national_university_of_the_technology_and_design": "Kyiv National University of the Technology and Design"
        },
        {
            "west_ukrainian_national_university": "West Ukrainian National University"
        },
        {
            "ternopil_national_medical_university": "Ternopil National Medical University"
        },
        {
            "national_university_of_kyiv_mohyla_academy": "National University of Kyiv Mohyla Academy"
        },
        {
            "ivan_franko_national_university_of_lviv": "Ivan Franko National University of Lviv"
        },
        {
            "o_m_beketov_national_university_of_urban_economy_in_kharkiv": "O M Beketov National University of Urban Economy in Kharkiv"
        },
        {
            "lesya_ukrainka_east_european_national_university": "Lesya Ukrainka East European National University"
        },
        {
            "kharkiv_state_academy_of_railway_transport": "Kharkiv State Academy of Railway Transport"
        },
        {
            "zaporizhzhya_national_university": "Zaporizhzhya National University"
        },
        {
            "donetsk_national_technical_university": "Donetsk National Technical University"
        },
        {
            "kyiv_national_university_of_trade_and_economics": "Kyiv National University of Trade and Economics"
        },
        {
            "ukrainian_state_university_of_chemical_engineering": "Ukrainian State University of Chemical Engineering"
        },
        {
            "nikolaev_state_university_va_suhomlinskogo": "Nikolaev State University VA Suhomlinskogo"
        },
        {
            "national_university_of_food_technologies_kyiv": "National University of Food Technologies Kyiv"
        },
        {
            "odessa_national_i_i_mechnikov_university": "Odessa National I I Mechnikov University"
        },
        {
            "bukovinian_state_medical_university": "Bukovinian State Medical University"
        },
        {
            "lugansk_national_university": "Lugansk National University"
        },
        {
            "national_university_of_water_and_environmental_engineering": "National University of Water and Environmental Engineering"
        },
        {
            "chernivtsi_national_university_y_fedkovych": "Chernivtsi National University Y Fedkovych"
        },
        {
            "vasyl_stefanyk_precarpathian_national_university": "Vasyl Stefanyk Precarpathian National University"
        },
        {
            "kharkiv_national_medical_university": "Kharkiv National Medical University"
        },
        {
            "ivano_frankivsk_national_technical_university_of_oil_and_gas": "Ivano Frankivsk National Technical University of Oil and Gas"
        },
        {
            "tavria_state_agrotechnological_university": "Tavria State Agrotechnological University"
        },
        {
            "chernihiv_national_university_of_technology": "Chernihiv National University of Technology"
        },
        {
            "borys_grinchenko_kyiv_university": "Borys Grinchenko Kyiv University"
        },
        {
            "prydneprovskaya_state_academy_of_construction_and_architecture": "Prydneprovskaya State Academy of Construction and Architecture"
        },
        {
            "kyiv_national_university_of_engineering_and_architecture": "Kyiv National University of Engineering and Architecture"
        },
        {
            "sumy_regional_institute_of_postgraduate_education": "Sumy Regional Institute of Postgraduate Education"
        },
        {
            "donetsk_national_university": "Donetsk National University"
        },
        {
            "kherson_state_university": "Kherson State University"
        },
        {
            "kyiv_national_economic_university_vadym_hetman": "Kyiv National Economic University Vadym Hetman"
        },
        {
            "national_university_of_pharmacy_ukraine": "National University of Pharmacy Ukraine"
        },
        {
            "national_university_of_civil_defence_of_ukraine": "National University of Civil Defence of Ukraine"
        },
        {
            "rivne_state_humanities_university": "Rivne State Humanities University"
        },
        {
            "ukrainian_medical_stomatological_academy": "Ukrainian Medical Stomatological Academy"
        },
        {
            "vinnitsa_state_pedagogical_university": "Vinnitsa State Pedagogical University"
        },
        {
            "odessa_national_academy_of_telecommunications": "Odessa National Academy of Telecommunications"
        },
        {
            "zaporizhzhe_state_engineering_academy": "Zaporizhzhe State Engineering Academy"
        },
        {
            "melitopol_state_pedagogical_university": "Melitopol State Pedagogical University"
        },
        {
            "national_university_of_physical_education_and_sport_of_ukraine": "National University of Physical Education and Sport of Ukraine"
        },
        {
            "kirovograd_national_technical_university": "Kirovograd National Technical University"
        },
        {
            "mykolayiv_national_agrarian_university": "Mykolayiv National Agrarian University"
        },
        {
            "dnipro_state_university_of_internal_affairs": "Dnipro State University of Internal Affairs"
        },
        {
            "kharkiv_petro_vasylenko_national_technical_university_of_agriculture": "Kharkiv Petro Vasylenko National Technical University of Agriculture"
        },
        {
            "national_pedagogical_university_mp_dragomanov": "National Pedagogical University MP Dragomanov"
        },
        {
            "poltava_national_technical_university_yuri_kondratyuk": "Poltava National Technical University Yuri Kondratyuk"
        },
        {
            "pryazovskyi_state_technical_university": "Pryazovskyi State Technical University"
        },
        {
            "sumy_national_agrarian_university": "Sumy National Agrarian University"
        },
        {
            "kryvyi_rih_state_pedagogical_university": "Kryvyi Rih State Pedagogical University"
        },
        {
            "ivano_frankivsk_national_medical_university": "Ivano Frankivsk National Medical University"
        },
        {
            "national_university_odessa_maritime_academy": "National University Odessa Maritime Academy"
        },
        {
            "odessa_state_university_of_internal_affairs": "Odessa State University of Internal Affairs"
        },
        {
            "mariupol_state_university": "Mariupol State University"
        },
        {
            "kharkiv_state_technical_university_of_construction_and_architecture": "Kharkiv State Technical University of Construction and Architecture"
        },
        {
            "khmelnytsky_regional_institute_of_postgraduate_education": "Khmelnytsky Regional Institute of Postgraduate Education"
        },
        {
            "alfred_nobel_university_dnipro_(university_of_economics_and_law)": "Alfred Nobel University Dnipro (University of Economics and Law)"
        },
        {
            "donbas_state_academy_of_engineering": "Donbas State Academy of Engineering"
        },
        {
            "bila_tserkva_state_agrarian_university": "Bila Tserkva State Agrarian University"
        },
        {
            "university_of_banking_of_the_national_bank_of_ukraine_kyiv": "University of Banking of the National Bank of Ukraine Kyiv"
        },
        {
            "national_university_odessa_academy_of_law": "National University Odessa Academy of Law"
        },
        {
            "kyiv_national_university_trade_and_economics_chernivtsi_trade_and_economic_institute": "Kyiv National University Trade and Economics Chernivtsi Trade and Economic Institute"
        },
        {
            "transcarpathian_art_institute": "Transcarpathian Art Institute"
        },
        {
            "berdyansk_state_pedagogical_university": "Berdyansk State Pedagogical University"
        },
        {
            "donetsk_state_university_of_management": "Donetsk State University of Management"
        },
        {
            "ukrainian_people's_academy": "Ukrainian People's Academy"
        },
        {
            "kherson_state_agricultural_university": "Kherson State Agricultural University"
        },
        {
            "stepan_gzhytskyi_national_university_of_veterinary_medicine_and_biotechnologies_lviv": "Stepan Gzhytskyi National University of Veterinary Medicine and Biotechnologies Lviv"
        },
        {
            "open_international_university_of_human_development_ukraine": "Open International University of Human Development Ukraine"
        },
        {
            "odessa_national_medical_university": "Odessa National Medical University"
        },
        {
            "khmelnitsky_national_university": "Khmelnitsky National University"
        },
        {
            "kyiv_national_linguistic_university": "Kyiv National Linguistic University"
        },
        {
            "academy_of_labour,_social_relations_and_tourism": "Academy of Labour, Social Relations and Tourism"
        },
        {
            "vinnitsa_trade_and_economic_institute": "Vinnitsa Trade and Economic institute"
        },
        {
            "national_academy_of_the_national_guard_of_ukraine": "National Academy of the National Guard of Ukraine"
        },
        {
            "pavlo_tychyna_uman_state_pedagogical_university": "Pavlo Tychyna Uman State Pedagogical University"
        },
        {
            "donetsk_state_medical_university": "Donetsk State Medical University"
        },
        {
            "kyiv_school_of_economics": "Kyiv School of Economics"
        },
        {
            "yaroslav_mudryi_national_law_university": "Yaroslav Mudryi National Law University"
        },
        {
            "lviv_university_of_trade_and_economics": "Lviv University of Trade and Economics"
        },
        {
            "national_university_of_fine_arts_and_architecture": "National University of Fine Arts and Architecture"
        },
        {
            "lviv_medical_university": "Lviv Medical University"
        },
        {
            "academy_of_design_and_arts_kharkiv": "Academy of Design and Arts Kharkiv"
        },
        {
            "national_university_of_ostroh_academy": "National University of Ostroh Academy"
        },
        {
            "poltava_state_pedagogical_university_korolenko": "Poltava State Pedagogical University Korolenko"
        },
        {
            "ternopil_v_hnatiuk_national_pedagogical_university": "Ternopil V Hnatiuk National Pedagogical University"
        },
        {
            "odessa_national_university_of_economics": "Odessa National University of Economics"
        },
        {
            "kharkiv_national_university_of_economics": "Kharkiv National University of Economics"
        },
        {
            "dnipro_national_university_of_railway_transport_named_after_academician_v._lazaryan_(dniprovsky_national_university_of_railway_transport_named_after_academician_v._lazaryan": "Dnipro National University of Railway Transport Named After Academician V. Lazaryan (Dniprovsky National University of Railway Transport named after Academician V. Lazaryan"
        },
        {
            "kyiv_national_university_of_culture_and_arts": "Kyiv National University of Culture and Arts"
        },
        {
            "poltava_university_of_economics_and_trade": "Poltava University of Economics and Trade"
        },
        {
            "kamyanets_podilskiy_national_university": "Kamyanets Podilskiy National University"
        },
        {
            "zaporozhye_state_medical_university": "Zaporozhye State Medical University"
        },
        {
            "dneprodzerzhinsk_state_technical_university": "Dneprodzerzhinsk State Technical University"
        },
        {
            "ukrainian_national_forestry_university": "Ukrainian National Forestry University"
        },
        {
            "kyiv_national_trade_and_economic_university": "Kyiv National Trade and Economic University"
        },
        {
            "kyiv_institute_of_international_economics_and_business": "Kyiv Institute of International Economics and Business"
        },
        {
            "interregional_academy_of_personnel_management": "Interregional Academy of Personnel Management"
        },
        {
            "berdyansk_university_of_management_and_business": "Berdyansk University of Management and Business"
        },
        {
            "national_university_of_defense_of_ukraine": "National University of Defense of Ukraine"
        },
        {
            "national_university_of_shipbuilding_admiral_makarov": "National University of Shipbuilding Admiral Makarov"
        },
        {
            "training_institute_of_the_state_employment_service_of_ukraine": "Training Institute of the State Employment Service of Ukraine"
        },
        {
            "southern_national_pedagogical_university_kd_ushinskogo": "Southern National Pedagogical University KD Ushinskogo"
        },
        {
            "lviv_state_university_of_physical_culture": "Lviv State University of Physical Culture"
        },
        {
            "kharkiv_national_university_of_arts_i_p_kotlyarevsky": "Kharkiv National University of Arts I P Kotlyarevsky"
        },
        {
            "kyiv_medical_university_of_uafm": "Kyiv Medical University of UAFM"
        },
        {
            "kharkiv_national_pedagogical_university": "Kharkiv National Pedagogical University"
        },
        {
            "poltava_state_agrarian_academy": "Poltava State Agrarian Academy"
        },
        {
            "academy_army_hetman_sahaidachny": "Academy Army Hetman Sahaidachny"
        },
        {
            "state_university_of_telecommunications": "State University of Telecommunications"
        },
        {
            "ukrainian_engineering_and_pedagogical_academy_kharkiv": "Ukrainian Engineering and Pedagogical Academy Kharkiv"
        },
        {
            "central_ukrainian_state_pedagogical_university_v_vynnychenko": "Central Ukrainian State Pedagogical University V Vynnychenko"
        },
        {
            "national_university_of_management_kyiv": "National University of Management Kyiv"
        },
        {
            "bukovinian_university": "Bukovinian University"
        },
        {
            "petro_mohyla_black_sea_national_university": "Petro Mohyla Black Sea National University"
        },
        {
            "odessa_national_maritime_university": "Odessa National Maritime University"
        },
        {
            "international_management_institute_mim_kyiv": "International Management Institute MIM Kyiv"
        },
        {
            "v_i_vernadsky_taurida_national_university_kyiv": "V I Vernadsky Taurida National University Kyiv"
        },
        {
            "drohobych_ivan_franko_state_pedagogical_university": "Drohobych Ivan Franko State Pedagogical University"
        },
        {
            "lviv_state_university_of_life_safety": "Lviv State University of Life Safety"
        },
        {
            "dnipro_state_agrarian_university": "Dnipro State Agrarian University"
        },
        {
            "kharkiv_state_university_of_food_technology_and_trade": "Kharkiv State University of Food Technology and Trade"
        },
        {
            "transcarpatian_hungarian_college_ferenc_rakoczi_ii": "Transcarpatian Hungarian College Ferenc Rakoczi II"
        },
        {
            "odessa_state_agrarian_university": "Odessa State Agrarian University"
        },
        {
            "lviv_academy_of_arts": "Lviv Academy of Arts"
        },
        {
            "mukachevo_state_university": "Mukachevo State University"
        },
        {
            "kyiv_international_university": "Kyiv International University"
        },
        {
            "education_management_university": "Education Management University"
        },
        {
            "donetsk_university_institute_of_law_e_o_didorenka": "Donetsk University Institute of Law E O Didorenka"
        },
        {
            "academy_of_advocacy_of_ukraine": "Academy of Advocacy of Ukraine"
        },
        {
            "oleksandr_dovzhenko_hlukhiv_national_pedagogical_university": "Oleksandr Dovzhenko Hlukhiv National Pedagogical University"
        },
        {
            "donbas_state_pedagogical_university": "Donbas State Pedagogical University"
        },
        {
            "kiev_institute_of_business_and_technology": "Kiev Institute of Business and Technology"
        },
        {
            "conservatory_odessa_musical_academy": "Conservatory Odessa Musical Academy"
        },
        {
            "kyiv_national_university_of_theater_film_and_television_i_k_karpenko_kary": "Kyiv National University of Theater Film and Television I K Karpenko Kary"
        },
        {
            "ukrainian_humanities_institute_bucha": "Ukrainian Humanities Institute Bucha"
        },
        {
            "eastern_university_of_economics_and_management": "Eastern University of Economics and Management"
        },
        {
            "lviv_institute_of_management": "Lviv Institute of Management"
        },
        {
            "donbas_national_academy_of_civil_engineering_and_architecture": "Donbas National Academy of Civil Engineering and Architecture"
        },
        {
            "national_academy_of_statistics,_accounting_and_auditing": "National Academy of Statistics, Accounting and Auditing"
        },
        {
            "national_academy_of_security_service_of_ukraine": "National Academy of Security Service of Ukraine"
        },
        {
            "kyiv_orthodox_theological_academy": "Kyiv Orthodox Theological Academy"
        },
        {
            "kyiv_institute_of_music_r_m_glier": "Kyiv Institute of Music R M Glier"
        },
        {
            "khmelnitsky_institute_of_social_technology_university_of_ukraine": "Khmelnitsky Institute of Social Technology University of Ukraine"
        },
        {
            "kharkiv_state_academy_of_zooveterinary": "Kharkiv State Academy of Zooveterinary"
        },
        {
            "kherson_academy_of_continuous_education_of_kherson_regional_council": "Kherson Academy of Continuous Education of Kherson Regional Council"
        },
        {
            "kyiv_university_of_tourism,_economics_and_law": "Kyiv University of Tourism, Economics and Law"
        },
        {
            "institute_of_oriental_studies_and_international_relations_kharkiv_collegium": "Institute of Oriental Studies and International Relations Kharkiv Collegium"
        },
        {
            "national_academy_of_the_public_prosecutor_of_ukraine": "National Academy of the Public Prosecutor of Ukraine"
        },
        {
            "donetsk_national_medical_university": "Donetsk National Medical University"
        },
        {
            "khmelnitsky_university_of_economics_and_business": "Khmelnitsky University of Economics and Business"
        },
        {
            "ukrainian_academy_of_public_administration": "Ukrainian Academy of Public Administration"
        },
        {
            "mystetskyy_institute_of_art_and_design_modeling_salvador_dali": "Mystetskyy Institute of Art and Design Modeling Salvador Dali"
        },
        {
            "international_university_of_economics_and_humanities_acad_stepan_demyanchuk": "International University of Economics and Humanities Acad Stepan Demyanchuk"
        },
        {
            "dnipro_conservatoire_glinka": "Dnipro Conservatoire Glinka"
        },
        {
            "odessa_state_academy_of_civil_engineering_and_architecture": "Odessa State Academy of Civil Engineering and Architecture"
        },
        {
            "lviv_institute_of_economy_and_tourism": "Lviv Institute of Economy and Tourism"
        },
        {
            "donetsk_university_of_economics_and_law": "Donetsk University of Economics and Law"
        },
        {
            "kyiv_cooperative_institute_of_business_and_law": "Kyiv Cooperative Institute of Business and Law"
        },
        {
            "dnipro_medical_institute_of_traditional_and_alternative_medicine": "Dnipro Medical Institute of Traditional and Alternative Medicine"
        },
        {
            "postgraduate_institute_ukrsteno": "Postgraduate Institute UKRSTENO"
        },
        {
            "dnipro_humanitarian_university": "Dnipro Humanitarian University"
        },
        {
            "kharkiv_state_academy_of_physical_culture": "Kharkiv State Academy of Physical Culture"
        },
        {
            "ukrainian_institute_of_stock_market": "Ukrainian Institute of Stock Market"
        },
        {
            "university_of_modern_knowledge": "University of Modern Knowledge"
        },
        {
            "kyiv_state_institute_of_decorative_and_applied_art_and_design_michael_boychuk": "Kyiv State Institute of Decorative and Applied Art and Design Michael Boychuk"
        },
        {
            "khmelnytsky_cooperative_trade_and_economic_institute": "Khmelnytsky Cooperative Trade and Economic Institute"
        },
        {
            "kyiv_economic_institute_of_management": "Kyiv Economic Institute of Management"
        },
        {
            "institute_of_chemical_technology_vostochnoukraynskyy_national_university_vladimir_dal": "Institute of Chemical Technology Vostochnoukraynskyy National University Vladimir Dal"
        },
        {
            "bila_institute_of_economics_and_management": "Bila Institute of Economics and Management"
        },
        {
            "institute_of_ecology_of_economics_and_law": "Institute of Ecology of Economics and Law"
        },
        {
            "kyiv_municipal_academy_of_circus_and_variety_arts": "Kyiv Municipal Academy of Circus and Variety Arts"
        },
        {
            "kirovograd_institute_of_human_development": "Kirovograd Institute of Human Development"
        },
        {
            "kozhedub_air_force_university": "Kozhedub Air Force University"
        },
        {
            "dnipro_state_institute_of_physical_culture_and_sports": "Dnipro State Institute of Physical Culture and Sports"
        },
        {
            "state_environmental_academy_of_postgraduate_education_and_management": "State Environmental Academy of Postgraduate Education and Management"
        },
        {
            "kiev_college_of_civil_engineering,_architecture_and_design": "Kiev College of Civil Engineering, Architecture and Design"
        },
        {
            "international_academy_of_ecology_and_medicine": "International Academy of Ecology and Medicine"
        }
    ],
    "philippines": [
        {
            "university_of_the_philippines": "University of the Philippines"
        },
        {
            "university_of_the_philippines_diliman": "University of the Philippines Diliman"
        },
        {
            "ateneo_de_manila_university": "Ateneo de Manila University"
        },
        {
            "university_of_santo_tomas": "University of Santo Tomas"
        },
        {
            "mindanao_state_university_iligan_institute_of_technology": "Mindanao State University Iligan Institute of Technology"
        },
        {
            "university_of_san_carlos": "University of San Carlos"
        },
        {
            "mapua_institute_of_technology": "Mapua Institute of Technology"
        },
        {
            "central_mindanao_university": "Central Mindanao University"
        },
        {
            "silliman_university": "Silliman University"
        },
        {
            "asian_institute_of_management": "Asian Institute of Management"
        },
        {
            "university_of_the_philippines_in_the_visayas": "University of the Philippines in the Visayas"
        },
        {
            "central_luzon_state_university": "Central Luzon State University"
        },
        {
            "mindanao_state_university": "Mindanao State University"
        },
        {
            "saint_louis_university_baguio_city": "Saint Louis University Baguio City"
        },
        {
            "polytechnic_university_of_the_philippines": "Polytechnic University of the Philippines"
        },
        {
            "saint_mary's_university_of_bayombong": "Saint Mary's University of Bayombong"
        },
        {
            "university_of_san_jose_recoletos": "University of San Jose Recoletos"
        },
        {
            "visayas_state_university": "Visayas State University"
        },
        {
            "batangas_state_university": "Batangas State University"
        },
        {
            "cebu_normal_university": "Cebu Normal University"
        },
        {
            "university_of_the_philippines_bathrooms": "University of the Philippines Bathrooms"
        },
        {
            "west_visayas_state_university": "West Visayas State University"
        },
        {
            "far_eastern_university_phillipines": "Far Eastern University Phillipines"
        },
        {
            "nueva_ecija_university_of_science_&_technology": "Nueva Ecija University of Science & Technology"
        },
        {
            "cebu_technological_university": "Cebu Technological University"
        },
        {
            "university_of_southern_mindanao": "University of Southern Mindanao"
        },
        {
            "ateneo_de_davao_university": "Ateneo de Davao University"
        },
        {
            "lyceum_of_the_philippines_university_batangas": "Lyceum of the Philippines University Batangas"
        },
        {
            "technological_university_of_the_philippines": "Technological University of the Philippines"
        },
        {
            "benguet_state_university": "Benguet State University"
        },
        {
            "isabela_state_university": "Isabela State University"
        },
        {
            "lyceum_of_the_philippines_university": "Lyceum of the Philippines University"
        },
        {
            "university_of_zamboanga.": "University of Zamboanga."
        },
        {
            "technological_institute_of_the_philippines": "Technological Institute of the Philippines"
        },
        {
            "university_of_southeastern_philippines": "University of Southeastern Philippines"
        },
        {
            "asia_pacific_college": "Asia Pacific College"
        },
        {
            "university_of_science_and_technology_of_southern_philippines_ustp": "University of Science and Technology of Southern Philippines USTP"
        },
        {
            "cavite_state_university": "Cavite State University"
        },
        {
            "bulacan_state_university": "Bulacan State University"
        },
        {
            "pangasinan_state_university.": "Pangasinan State University."
        },
        {
            "bicol_university.": "Bicol University."
        },
        {
            "university_of_the_philippines_mindanao": "University of the Philippines Mindanao"
        },
        {
            "university_of_mindanao": "University of Mindanao"
        },
        {
            "jose_rizal_university": "Jose Rizal University"
        },
        {
            "xavier_university_ateo_de_cagayan": "Xavier University Ateo de Cagayan"
        },
        {
            "adamson_university": "Adamson University"
        },
        {
            "adventist_international_institute_of_advanced_studies": "Adventist International Institute of Advanced Studies"
        },
        {
            "university_of_manila_mendiola": "University of Manila Mendiola"
        },
        {
            "holy_angel_university": "Holy Angel University"
        },
        {
            "university_of_bohol": "University of Bohol"
        },
        {
            "central_philippine_university": "Central Philippine University"
        },
        {
            "system_technology_institute": "System Technology Institute"
        },
        {
            "university_of_the_east": "University of the East"
        },
        {
            "tarlac_state_university": "Tarlac State University"
        },
        {
            "samar_state_university": "Samar State University"
        },
        {
            "university_of_san_agustin": "University of San Agustin"
        },
        {
            "msc_institute_of_technology": "MSC Institute of Technology"
        },
        {
            "from_the_college_of_saint_benilde": "From the College of Saint Benilde"
        },
        {
            "de_la_salle_lipa": "De La Salle Lipa"
        },
        {
            "rizal_technological_university": "Rizal Technological University"
        },
        {
            "western_mindanao_state_university": "Western Mindanao State University"
        },
        {
            "anteno_de_varito_university": "Anteno de Varito University"
        },
        {
            "manila_central_university": "Manila Central University"
        },
        {
            "fatima_university": "Fatima University"
        },
        {
            "athenaeum_of_naga_university": "Athenaeum of Naga University"
        },
        {
            "adventist_university_of_the_philippines": "Adventist University of the Philippines"
        },
        {
            "mariano_marcos_state_university": "Mariano Marcos State University"
        },
        {
            "university_of_the_cordilleras_(baguio_colleges_foundation)": "University of the Cordilleras (Baguio Colleges Foundation)"
        },
        {
            "cagayan_state_university": "Cagayan State University"
        },
        {
            "university_of_baguio": "University of Baguio"
        },
        {
            "university_of_the_city_of_manila": "University of the City of Manila"
        },
        {
            "philippine_normal_university": "Philippine Normal University"
        },
        {
            "san_difference_university": "San Difference University"
        },
        {
            "university_of_perpetual_help_system_dalta": "University of Perpetual Help System Dalta"
        },
        {
            "philippine_women's_university": "Philippine Women's University"
        },
        {
            "angeles_university_foundation": "Angeles University Foundation"
        },
        {
            "university_of_the_philippines_baguio": "University of the Philippines Baguio"
        },
        {
            "leyte_normal_university": "Leyte Normal University"
        },
        {
            "from_the_home_salle_sciences_institute": "From the Home Salle Sciences Institute"
        },
        {
            "ama_computer_university": "AMA Computer University"
        },
        {
            "philippine_military_academy": "Philippine Military Academy"
        },
        {
            "university_of_asia_and_the_pacific": "University of Asia and the Pacific"
        },
        {
            "university_of_the_immaculate_conception": "University of the Immaculate Conception"
        },
        {
            "university_of_the_philippines_cebu": "University of the Philippines Cebu"
        },
        {
            "bukidnon_state_university": "Bukidnon State University"
        },
        {
            "emilio_aguinaldo_college": "Emilio Aguinaldo College"
        },
        {
            "university_of_northern_philippines": "University of Northern Philippines"
        },
        {
            "information_and_communications_technology_academy": "Information and Communications Technology Academy"
        },
        {
            "phinma_education": "PHINMA Education"
        },
        {
            "arellano_university": "Arellano University"
        },
        {
            "eastern_visayas_state_university": "Eastern Visayas State University"
        },
        {
            "cebu_doctors'_university": "Cebu Doctors' University"
        },
        {
            "laguna_state_polytechnic_university": "Laguna State Polytechnic University"
        },
        {
            "university_of_batangas": "University of Batangas"
        },
        {
            "new_era_university": "New Era University"
        },
        {
            "san_sebastian_college_manila": "San Sebastian College Manila"
        },
        {
            "southwestern_university_cebu_philippines": "Southwestern University Cebu Philippines"
        },
        {
            "university_of_the_east_ramon_magsaysay": "University of the East Ramon Magsaysay"
        },
        {
            "araneta_university_room": "Araneta University Room"
        },
        {
            "trinity_university_of_asia_(trinity_college_of_quezon_city)": "Trinity University of Asia (Trinity College of Quezon City)"
        },
        {
            "far_eastern_university_institute_of_medicine": "Far Eastern University Institute of Medicine"
        },
        {
            "romblon_state_university": "Romblon State University"
        },
        {
            "misamis_university": "Misamis University"
        },
        {
            "saint_paul_university_manila": "Saint Paul University Manila"
        },
        {
            "philippine_merchant_marine_academy": "Philippine Merchant Marine Academy"
        },
        {
            "northwestern_university_laoag_city": "Northwestern University Laoag City"
        },
        {
            "don_mariano_marcos_memorial_state_university": "Don Mariano Marcos Memorial State University"
        },
        {
            "palawan_state_university": "Palawan State University"
        },
        {
            "camarines_sur_polytechnic_colleges": "Camarines Sur Polytechnic Colleges"
        },
        {
            "san_carlos_college.": "San Carlos College."
        },
        {
            "university_of_luzon": "University of Luzon"
        },
        {
            "saint_paul_university_philippines": "Saint Paul University Philippines"
        },
        {
            "far_eastern_university_east_asia_college": "Far Eastern University East Asia College"
        },
        {
            "university_of_rizal_system": "University of Rizal System"
        },
        {
            "asian_institute_of_journalism_and_communication": "Asian Institute of Journalism and Communication"
        },
        {
            "university_of_saint_louis_tuguegarao": "University of Saint Louis Tuguegarao"
        },
        {
            "informatics_computer_institute": "Informatics Computer Institute"
        },
        {
            "the_university_ozamiz": "The University Ozamiz"
        },
        {
            "chiang_kai_shek_college": "Chiang Kai Shek College"
        },
        {
            "university_of_the_visayas": "University of the Visayas"
        },
        {
            "liceo_de_cagayan_university": "Liceo de Cagayan University"
        },
        {
            "university_of_perpetual_help_system_laguna": "University of Perpetual Help System Laguna"
        },
        {
            "ifugao_state_university": "Ifugao State University"
        },
        {
            "aklan_state_university.": "Aklan State University."
        },
        {
            "virgin_milagros_university_foundation": "Virgin Milagros University Foundation"
        },
        {
            "asian_institute_of_maritime_studies": "Asian Institute of Maritime Studies"
        },
        {
            "davao_medical_school_foundation": "Davao Medical School Foundation"
        },
        {
            "father_saturnino_urios_university": "Father Saturnino Urios University"
        },
        {
            "davao_doctors_college": "Davao Doctors College"
        },
        {
            "filamer_christian_university": "Filamer Christian University"
        },
        {
            "st_paul_university_iloilo": "St Paul University Iloilo"
        },
        {
            "wesleyan_university_philippines": "Wesleyan University Philippines"
        },
        {
            "university_of_sto_tomas_legazpi_bicol_dominican_university": "University of Sto Tomas Legazpi Bicol Dominican University"
        },
        {
            "st._paul_university_quezon_city": "St. Paul University Quezon City"
        },
        {
            "mindanao_state_university_general_santos": "Mindanao State University General Santos"
        },
        {
            "saint_louis_college": "Saint Louis College"
        },
        {
            "notre_dame_of_dadiangas_university": "Notre Dame of Dadiangas University"
        },
        {
            "iloilo_science_and_technology_university_(western_visayas_college_of_science_&_technology)": "Iloilo Science and Technology University (Western Visayas College of Science & Technology)"
        },
        {
            "new_vizcaya_state_university": "New Vizcaya State University"
        },
        {
            "cebu_institute_of_medicine": "Cebu Institute of Medicine"
        },
        {
            "camarines_norte_state_college": "Camarines Norte State College"
        },
        {
            "holy_cross_of_davao_college": "Holy Cross of Davao College"
        },
        {
            "riverside_college": "Riverside College"
        },
        {
            "brokenshire_college": "Brokenshire College"
        },
        {
            "san_sebastian_college_recoletos_de_cavite": "San Sebastian College Recoletos de Cavite"
        },
        {
            "philippine_national_police_academy": "Philippine National Police Academy"
        },
        {
            "ilocos_sur_polytechnic_state_college": "Ilocos Sur Polytechnic State College"
        },
        {
            "bohol_island_state_university_(central_visayas_state_college_of_agriculture)": "Bohol Island State University (Central Visayas State College of Agriculture)"
        },
        {
            "school_san_agustin_bacolod": "School San Agustin Bacolod"
        },
        {
            "first_asia_institute_of_technology_and_humanities": "First Asia Institute of Technology and Humanities"
        },
        {
            "bagui_central_university": "Bagui Central University"
        },
        {
            "saint_paul_university_pasig": "Saint Paul University Pasig"
        },
        {
            "divine_word_college_of_legazpi": "Divine Word College of Legazpi"
        },
        {
            "asian_theological_seminary_philippines": "Asian Theological Seminary Philippines"
        },
        {
            "patts_college_of_aeronautics": "PATTS College of Aeronautics"
        },
        {
            "negros_oriental_state_university_(central_visayas_polytechnic_college)": "Negros Oriental State University (Central Visayas Polytechnic College)"
        },
        {
            "iligan_computer_institute": "Iligan Computer Institute"
        },
        {
            "saint_paul_university_dumaguete": "Saint Paul University Dumaguete"
        },
        {
            "university_of_iloilo": "University of Iloilo"
        },
        {
            "college_of_the_holy_spirit": "College of the Holy Spirit"
        },
        {
            "college_of_development_communication": "College of Development Communication"
        },
        {
            "southern_leyte_state_university": "Southern Leyte State University"
        },
        {
            "tarlac_agricultural_university": "Tarlac Agricultural University"
        },
        {
            "southern_luzon_state_university_(polytechnic_college)": "Southern Luzon State University (Polytechnic College)"
        },
        {
            "western_philippines_university": "Western Philippines University"
        },
        {
            "abe_international_business_college": "Abe International Business College"
        },
        {
            "baliuag_university.": "Baliuag University."
        },
        {
            "saint_luke's_college_of_medicine": "Saint Luke's College of Medicine"
        },
        {
            "central_philippine_adventist_college": "Central Philippine Adventist College"
        },
        {
            "icct_colleges": "ICCT Colleges"
        },
        {
            "national_college_of_business_and_arts_ncba": "National College of Business and Arts NCBA"
        },
        {
            "st_paul_university_surigao": "St Paul University Surigao"
        },
        {
            "mountain_view_college_phillipines": "Mountain View College Phillipines"
        },
        {
            "far_eastern_university_cavite": "Far Eastern University Cavite"
        },
        {
            "center_for_industrial_technology_and_enterprise": "Center for Industrial Technology and Enterprise"
        },
        {
            "the_university_philippines_consolation": "The University Philippines Consolation"
        },
        {
            "zamboanga_state_college_of_marine_sciences_and_technology": "Zamboanga State College of Marine Sciences and Technology"
        },
        {
            "aurora_state_college_of_technology": "Aurora State College of Technology"
        },
        {
            "southeast_asia_interdisciplinary_development_institute": "Southeast Asia Interdisciplinary Development Institute"
        },
        {
            "dagupan_college": "Dagupan College"
        },
        {
            "asian_social_institute_manila": "Asian Social Institute Manila"
        },
        {
            "capiz_state_university": "Capiz State University"
        },
        {
            "university_of_pangasinan.": "University of Pangasinan."
        },
        {
            "saint_joseph_institute_of_technology": "Saint Joseph Institute of Technology"
        },
        {
            "university_of_eastern_philippines": "University of Eastern Philippines"
        },
        {
            "northwest_samar_state_university": "Northwest Samar State University"
        },
        {
            "international_graduate_school_of_leadership_(international_school_of_theology_asia)": "International Graduate School of Leadership (International School of Theology Asia)"
        },
        {
            "saint_michael's_college_of_laguna": "Saint Michael's College of Laguna"
        },
        {
            "iloilo_doctors'_college": "Iloilo Doctors' College"
        },
        {
            "national_college_of_science_&_technology": "National College of Science & Technology"
        },
        {
            "alliance_graduate_school": "Alliance Graduate School"
        },
        {
            "panpacific_university_north_philippines": "PanPacific University North Philippines"
        },
        {
            "bulacan_agricultural_state_college": "Bulacan Agricultural State College"
        },
        {
            "cebu_aeronautical_technical_school_(computer_arts_and_technological_college_legazpi_city)": "Cebu Aeronautical Technical School (Computer Arts and Technological College Legazpi City)"
        },
        {
            "southville_sisfu.": "Southville Sisfu."
        },
        {
            "philippine_college_of_criminology": "Philippine College of Criminology"
        },
        {
            "university_of_northeastern_philippines": "University of Northeastern Philippines"
        },
        {
            "dipolog_medical_center_college_foundation": "Dipolog Medical Center College Foundation"
        },
        {
            "university_of_caloocan_city": "University of Caloocan City"
        },
        {
            "nyk-tdg_maritime_academy": "NYK-TDG Maritime Academy"
        },
        {
            "catanduanes_state_university": "Catanduanes State University"
        },
        {
            "comteq_computer_and_business_college": "Comteq Computer and Business College"
        },
        {
            "abra_state_institute_of_science_&_technology": "Abra State Institute of Science & Technology"
        },
        {
            "samson_college_of_science_&_technology": "Samson College of Science & Technology"
        },
        {
            "san_pedro_college_of_business_administration": "San Pedro College of Business Administration"
        },
        {
            "siena_college_of_taytay": "Siena College of Taytay"
        },
        {
            "don_honorio_ventura_technological_state_university": "Don Honorio Ventura Technological State University"
        },
        {
            "biliran_province_state_university": "Biliran Province State University"
        },
        {
            "mindanao_state_university_tawi-tawi_college_of_technology_and_oceanography": "Mindanao State University Tawi-Tawi College of Technology and Oceanography"
        },
        {
            "manual_l_quezon_university": "Manual l quezon University"
        }
    ],
    "latvia": [
        {
            "university_of_latvia": "University of Latvia"
        },
        {
            "riga_technical_university": "Riga Technical University"
        },
        {
            "riga_stradins_university_(medical_academy_of_latvia)": "Riga Stradins University (Medical Academy of Latvia)"
        },
        {
            "latvia_university_of_agriculture": "Latvia University of Agriculture"
        },
        {
            "daugavpils_university": "Daugavpils University"
        },
        {
            "vidzeme_university_college": "Vidzeme University College"
        },
        {
            "liepaja_university_(liepaja_pedagogical_higher_school)": "Liepaja University (Liepaja Pedagogical Higher School)"
        },
        {
            "stockholm_school_of_economics_in_riga": "Stockholm School of Economics in Riga"
        },
        {
            "baltic_international_academy": "Baltic International Academy"
        },
        {
            "school_of_business_administration_turiba": "School of Business Administration Turiba"
        },
        {
            "transport_and_telecommunications_institute_riga": "Transport and Telecommunications Institute Riga"
        },
        {
            "rezekne_higher_school": "Rezekne Higher School"
        },
        {
            "art_academy_of_latvia": "Art Academy of Latvia"
        },
        {
            "riga_graduate_school_of_law": "Riga Graduate School of Law"
        },
        {
            "riseba_university_of_business_arts_and_technology": "RISEBA University of Business Arts and Technology"
        },
        {
            "lutera_akademija": "Lutera Akademija"
        },
        {
            "ventspils_university_college": "Ventspils University College"
        },
        {
            "j\u00e4\u0081zeps_v\u00e4\u00abtols_latvian_academy_of_music": "J\u00c4\u0081zeps V\u00c4\u00abtols Latvian Academy of Music"
        },
        {
            "information_systems_management_institute": "Information Systems Management Institute"
        },
        {
            "national_academy_of_defence_of_the_republic_of_latvia": "National Academy of Defence of the Republic of Latvia"
        },
        {
            "riga_business_school_at_riga_technical_university": "Riga Business School at Riga Technical University"
        },
        {
            "eka_the_university_of_culture_and_economics": "EKA The University of Culture and Economics"
        },
        {
            "riga_aeronautical_institute": "Riga Aeronautical Institute"
        },
        {
            "latvian_maritime_academy": "Latvian Maritime Academy"
        },
        {
            "alberta_college": "Alberta College"
        },
        {
            "latvian_christian_academy": "Latvian Christian Academy"
        },
        {
            "international_higher_school_of_practical_psychology": "International Higher School of Practical Psychology"
        },
        {
            "riga_stradins_university_red_cross_medical_college": "Riga Stradins University Red Cross Medical College"
        },
        {
            "riga_technical_college": "Riga Technical College"
        },
        {
            "college_of_business_administration_latvia": "College of Business Administration Latvia"
        },
        {
            "jekabpils_agrobusiness_college": "Jekabpils Agrobusiness College"
        },
        {
            "riga_civil_engineering_college": "Riga Civil Engineering College"
        },
        {
            "riga_1_medicine_college": "Riga 1 Medicine College"
        },
        {
            "riga_art_and_media_school": "Riga Art and Media School"
        },
        {
            "higher_education_council": "Higher Education Council"
        },
        {
            "riga_college_of_accounting_and_finance": "Riga College of Accounting and Finance"
        },
        {
            "cosmetology_college_starptautisk\u00e4\u0081": "Cosmetology College Starptautisk\u00c4\u0081"
        },
        {
            "latvian_business_college": "Latvian Business College"
        },
        {
            "daugauplis_medicine_college": "Daugauplis Medicine College"
        },
        {
            "christian_leadership_college": "Christian Leadership College"
        }
    ],
    "georgia": [
        {
            "ivane_javakhishvili_tbilisi_state_university": "Ivane Javakhishvili Tbilisi State University"
        },
        {
            "ilia_state_university": "Ilia State University"
        },
        {
            "tbilisi_state_medical_university": "Tbilisi State Medical University"
        },
        {
            "university_of_georgia": "University of Georgia"
        },
        {
            "batumi_shota_rustaveli_state_university": "Batumi Shota Rustaveli State University"
        },
        {
            "international_black_sea_university": "International Black Sea University"
        },
        {
            "caucasus_international_university": "Caucasus International University"
        },
        {
            "the_oddal_state_university_to_race": "The oddal state University to race"
        },
        {
            "samtskhe_javakheti_state_university": "Samtskhe Javakheti State University"
        },
        {
            "zurab_zhvania_georgian_institute_of_public_affairs": "Zurab Zhvania Georgian Institute of Public Affairs"
        },
        {
            "business_academy_of_georgia": "Business Academy of Georgia"
        },
        {
            "sokhumi_state_university": "Sokhumi State University"
        },
        {
            "petre_shotadze_tbilisi_medical_academy": "Petre Shotadze Tbilisi Medical Academy"
        },
        {
            "tbilisi_state_academy_of_arts": "Tbilisi State Academy of Arts"
        },
        {
            "grigol_robakidze_university_tbilisi": "Grigol Robakidze University Tbilisi"
        },
        {
            "in_cooperadishvili_tbilisi_state_conservatoire": "In cooperadishvili Tbilisi State conservatoire"
        },
        {
            "business_and_technology_university_tblisi": "Business and Technology University Tblisi"
        },
        {
            "gori_university.": "Gori University."
        },
        {
            "ilia_chavchavadze_georgian_national_university": "Ilia Chavchavadze Georgian National University"
        },
        {
            "georgian_american_university": "Georgian American University"
        },
        {
            "georgian_aviation_university": "Georgian Aviation University"
        },
        {
            "shota_meskhia_zugdidi_state_teaching_university": "Shota Meskhia Zugdidi State Teaching University"
        },
        {
            "east_european_university": "East European University"
        },
        {
            "you_will_pick_up_university": "You will pick up University"
        },
        {
            "batumi_arts_teaching_university": "Batumi Arts Teaching University"
        },
        {
            "sulkhan-saba_orbeliani_humanities_university": "Sulkhan-Saba Orbeliani Humanities University"
        },
        {
            "university_geomedi": "University Geomedi"
        },
        {
            "bau_international_university_batumi": "BAU International University Batumi"
        },
        {
            "georgian_state_teaching_university_of_physical_education_and_sport": "Georgian State Teaching University of Physical Education and Sport"
        },
        {
            "tbilisi_theological_academy_and_seminary_of_apostolic_autocephalous_orthodox_church_of_georgia": "Tbilisi Theological Academy and Seminary of Apostolic Autocephalous Orthodox Church of Georgia"
        },
        {
            "batumi_high_maritime_engineering_school": "Batumi High Maritime Engineering School"
        },
        {
            "saint_king_tamar_university_of_georgian_patriarchate": "Saint King Tamar University of Georgian Patriarchate"
        }
    ],
    "vietnam": [
        {
            "ton_duc_thang_university": "Ton Duc Thang University"
        },
        {
            "duy_tan_university": "Duy Tan University"
        },
        {
            "vnuhcm_university_of_technology": "VNUHCM University of Technology"
        },
        {
            "ho_chi_minh_city_university_of_industry": "Ho Chi Minh City University of Industry"
        },
        {
            "university_of_danang": "University of Danang"
        },
        {
            "hanoi_university_of_science_and_technology": "Hanoi University of Science and Technology"
        },
        {
            "hue_university": "Hue University"
        },
        {
            "university_of_economics_ho_chi_minh_city": "University of Economics Ho Chi Minh City"
        },
        {
            "ho_chi_minh_city_open_university": "Ho Chi Minh City Open University"
        },
        {
            "hanoi_medical_university": "Hanoi Medical University"
        },
        {
            "can_tho_university": "Can Tho University"
        },
        {
            "national_economics_university": "National Economics University"
        },
        {
            "ho_chi_minh_city_university_of_technology_and_education": "Ho Chi Minh City University of Technology and Education"
        },
        {
            "vinh_university": "Vinh University"
        },
        {
            "n\u00e3\u00b4ng_l\u00e3\u00a2m_university": "N\u00c3\u00b4ng L\u00c3\u00a2m University"
        },
        {
            "hung_yen_university_of_technology_and_education": "Hung Yen University of Technology and Education"
        },
        {
            "thuyloi_university": "Thuyloi University"
        },
        {
            "thu_dau_mot_university": "Thu Dau Mot University"
        },
        {
            "nha_trang_university": "Nha Trang University"
        },
        {
            "hanoi_university_of_civil_engineering": "Hanoi University of Civil Engineering"
        },
        {
            "ho_chi_minh_city_medicine_and_pharmacy_university": "Ho Chi Minh City Medicine and Pharmacy University"
        },
        {
            "quy_nhon_university": "Quy Nhon University"
        },
        {
            "dalat_university": "Dalat University"
        },
        {
            "vnuhcm_international_university": "VNUHCM International University"
        },
        {
            "vnuhcm_university_of_science": "VNUHCM University of Science"
        },
        {
            "rmit_university_vietnam": "RMIT University Vietnam"
        },
        {
            "thai_nguyen_university": "Thai Nguyen University"
        },
        {
            "nguyen_tat_thanh_university": "Nguyen Tat Thanh University"
        },
        {
            "tra_vinh_university": "Tra Vinh University"
        },
        {
            "vnuhcm_university_of_information_technology": "VNUHCM University of Information Technology"
        },
        {
            "saigon_university": "Saigon University"
        },
        {
            "an_giang_university": "An Giang University"
        },
        {
            "hong_duc_university": "Hong Duc University"
        },
        {
            "ho_chi_minh_city_university_of_technology_hutech_/_ho_chi_minh_city_university_of_technology_hutech": "Ho Chi Minh City University of Technology HUTECH / Ho Chi Minh City University of Technology Hutech"
        },
        {
            "thai_nguyen_university_of_agriculture_and_forestry": "Thai Nguyen University of Agriculture and Forestry"
        },
        {
            "vietnamese-german_university": "Vietnamese-German University"
        },
        {
            "thai_nguyen_university_of_technology": "Thai Nguyen University of Technology"
        },
        {
            "university_of_transport_and_communications": "University of Transport and Communications"
        },
        {
            "university_of_economics_and_law": "University of Economics and Law"
        },
        {
            "foreign_trade_university": "Foreign Trade University"
        },
        {
            "tay_bac_university": "Tay Bac University"
        },
        {
            "dong_thap_university": "Dong Thap University"
        },
        {
            "ho_chi_minh_city_university_of_foreign_languages_and_information_technology": "Ho Chi Minh City University of Foreign Languages and Information Technology"
        },
        {
            "ho_chi_minh_city_university_of_food_industry_hcm": "Ho Chi Minh City University of Food Industry HCM"
        },
        {
            "van_lang_university": "Van Lang University"
        },
        {
            "vietnam_academy_of_social_sciences": "Vietnam Academy of Social Sciences"
        },
        {
            "hanoi_pedagogical_university_n\u00e2\u00b02": "Hanoi Pedagogical University N\u00c2\u00b02"
        },
        {
            "vnuhcm_university_of_social_sciences_and_humanities": "VNUHCM University of Social Sciences and Humanities"
        },
        {
            "hue_college_of_medicine": "Hue College of Medicine"
        },
        {
            "hue_college_of_economics": "Hue College of Economics"
        },
        {
            "hanoi_university_of_law": "Hanoi University of Law"
        },
        {
            "vietnam_maritime_university": "Vietnam Maritime University"
        },
        {
            "hoa_sen_university": "Hoa Sen University"
        },
        {
            "ho_chi_minh_city_university_of_law": "Ho Chi Minh City University of Law"
        },
        {
            "thai_nguyen_university_of_sciences_in_vietnam": "Thai Nguyen University of Sciences in Vietnam"
        },
        {
            "dong_a_university": "Dong A University"
        },
        {
            "banking_academy_of_vietnam": "Banking Academy of Vietnam"
        },
        {
            "ho_chi_minh_city_university_of_education": "Ho Chi Minh City University of Education"
        },
        {
            "hanoi_open_university": "Hanoi Open University"
        },
        {
            "dong_nai_university_of_technology": "Dong Nai University of Technology"
        },
        {
            "ho_chi_minh_city_university_of_economics_and_finance_uef": "Ho Chi Minh City University of Economics and Finance UEF"
        },
        {
            "hanoi_university_of_culture": "Hanoi University of Culture"
        },
        {
            "diplomatic_academy_of_vietnam": "Diplomatic Academy of Vietnam"
        },
        {
            "hai_phong_private_university": "Hai Phong Private University"
        },
        {
            "hanoi_university_of_pharmacy": "Hanoi University of Pharmacy"
        },
        {
            "quangbinh_university": "Quangbinh University"
        },
        {
            "ho_chi_minh_city_university_of_transportation": "Ho Chi Minh City University of Transportation"
        },
        {
            "saigon_international_university": "Saigon International University"
        },
        {
            "pace_institute_of_leadership_and_management": "PACE Institute of Leadership and Management"
        },
        {
            "fulbright_university_vietnam": "Fulbright University Vietnam"
        },
        {
            "tan_tao_university": "Tan Tao University"
        },
        {
            "van_hien_university": "Van Hien University"
        },
        {
            "asian_institute_of_technology_center_in_vietnam_aitcv": "Asian Institute of Technology Center in Vietnam AITCV"
        },
        {
            "pham_ngoc_thach_university_of_medicine": "Pham Ngoc Thach University of Medicine"
        },
        {
            "hong_bang_international_university": "Hong Bang International University"
        },
        {
            "saigon_institute_of_technology_saigontech": "Saigon Institute of Technology SaigonTech"
        },
        {
            "hai_phong_medical_university": "Hai Phong Medical University"
        },
        {
            "national_academy_of_public_administration": "National Academy of Public Administration"
        },
        {
            "hue_college_of_teacher_training": "Hue College of Teacher Training"
        },
        {
            "central_library_vietnam_national_university_ho_chi_minh_city": "Central Library Vietnam National University Ho Chi Minh City"
        },
        {
            "vietnam_aviation_academy": "Vietnam Aviation Academy"
        },
        {
            "thai_binh_university_of_pharmacy": "Thai Binh University of Pharmacy"
        },
        {
            "tay_nguyen_university": "Tay Nguyen University"
        },
        {
            "can_tho_university_of_technology": "can tho University of Technology"
        },
        {
            "trade_union_university": "Trade Union University"
        },
        {
            "hanoi_university_of_industrial_fine_arts": "Hanoi University of Industrial Fine Arts"
        },
        {
            "dong_thap_community_college": "Dong Thap Community College"
        },
        {
            "vietnam_national_academy_of_music_hanoi_conservatory_of_music": "Vietnam National Academy of Music Hanoi Conservatory of Music"
        },
        {
            "vietnam_air_and_air_defense_forces_academy": "Vietnam Air and Air Defense Forces Academy"
        },
        {
            "vietnam_naval_academy": "Vietnam Naval Academy"
        },
        {
            "binh_duong_university_of_economics_and_technology": "Binh Duong University of Economics and Technology"
        },
        {
            "phu_xuan_university": "Phu Xuan University"
        },
        {
            "van_xuan_university_of_technology": "Van Xuan University of Technology"
        },
        {
            "quang_trung_university": "Quang Trung University"
        },
        {
            "ocean_university_vietnam": "Ocean University Vietnam"
        },
        {
            "phuongnam_economics_technics_college": "Phuongnam Economics Technics College"
        }
    ],
    "north macedonia": [
        {
            "ss_cyril_and_methodius_university_skopje": "Ss Cyril and Methodius University Skopje"
        },
        {
            "south_east_european_university": "South East European University"
        },
        {
            "state_university_of_tetovo": "State University of Tetovo"
        },
        {
            "saint_clement_of_ohrid_university_of_bitola": "Saint Clement of Ohrid University of Bitola"
        },
        {
            "international_balkan_university": "International Balkan University"
        },
        {
            "university_american_college_skopje": "University American College Skopje"
        },
        {
            "american_university_of_europe_(fon_university)": "American University of Europe (FON University)"
        },
        {
            "international_slavic_university_g_r_derzhavin": "International Slavic University G R Derzhavin"
        },
        {
            "university_for_tourism_and_management_skopje": "University for Tourism and Management Skopje"
        },
        {
            "international_university_of_struga": "International University of Struga"
        },
        {
            "school_of_journalism_and_public_relations": "School of Journalism and Public Relations"
        },
        {
            "integrated_business_faculty_(neocom)_skopje": "Integrated Business Faculty (NEOCOM) Skopje"
        },
        {
            "international_vision_university": "International Vision University"
        },
        {
            "euro_college_kumanovo.": "Euro College Kumanovo."
        },
        {
            "business_academy_smilevski_skopje": "Business Academy Smilevski Skopje"
        },
        {
            "european_university_north_macedonia": "European University North Macedonia"
        }
    ],
    "bosnia and herzegovina": [
        {
            "university_of_sarajevo": "University of Sarajevo"
        },
        {
            "university_of_banja_luka": "University of Banja Luka"
        },
        {
            "international_university_of_sarajevo": "International University of Sarajevo"
        },
        {
            "university_of_tuzla": "University of Tuzla"
        },
        {
            "university_of_east_sarajevo": "University of East Sarajevo"
        },
        {
            "international_burch_university_sarajevo": "International BURCH University Sarajevo"
        },
        {
            "university_of_mostar": "University of Mostar"
        },
        {
            "university_of_zenica": "University of Zenica"
        },
        {
            "sarajevo_school_of_science_&_technology": "Sarajevo School of Science & Technology"
        },
        {
            "university_of_bihac": "University of Bihac"
        },
        {
            "european_university_of_kallos_tuzla": "European University of Kallos Tuzla"
        },
        {
            "european_university_brcko": "European University Brcko"
        },
        {
            "university_dzemal_bijedic_mostar": "University Dzemal Bijedic Mostar"
        },
        {
            "international_university_of_travnik": "International University of Travnik"
        },
        {
            "paneuropean_apeiron_university_banja_luka": "Paneuropean Apeiron University Banja Luka"
        },
        {
            "university_vitez_in_travnik": "University Vitez in Travnik"
        },
        {
            "slobomir_p_university": "Slobomir P University"
        },
        {
            "banja_luka_college.": "Banja Luka College."
        },
        {
            "pim_university_of_business_engineering_and_management_banja_luka": "PIM University of Business Engineering and Management Banja Luka"
        },
        {
            "sarajevo_film_academy": "Sarajevo Film Academy"
        },
        {
            "university_sinergija": "University Sinergija"
        },
        {
            "banja_luka_college_of_communications": "Banja Luka College of Communications"
        },
        {
            "ipi_academy_of_tuzla.": "IPI Academy of Tuzla."
        },
        {
            "american_university_in_bosnia_and_herzegovina": "American University in Bosnia and Herzegovina"
        },
        {
            "university_college_ceps_center_for_business_studies_kiseljak": "University College CEPS Center for Business Studies Kiseljak"
        },
        {
            "high_business_technical_school_doboj": "High Business Technical School Doboj"
        },
        {
            "university_of_modern_sciences_ckm": "University of Modern Sciences CKM"
        },
        {
            "university_of_travnik_faculty_of_business_economics": "University of Travnik Faculty of Business Economics"
        },
        {
            "higher_school_of_finance_and_accounting_finra_tuzla": "Higher School of Finance and Accounting FINra Tuzla"
        },
        {
            "college_for_tourism_and_management_konjic": "College for Tourism and Management Konjic"
        }
    ],
    "bangladesh": [
        {
            "university_of_dhaka": "University of Dhaka"
        },
        {
            "bangladesh_university_of_engineering_and_technology": "Bangladesh University of Engineering and Technology"
        },
        {
            "shahjalal_university_of_science_&_technology": "Shahjalal University of Science & Technology"
        },
        {
            "north_south_university_bangladesh": "North South University Bangladesh"
        },
        {
            "rajshahi_university": "Rajshahi University"
        },
        {
            "bangladesh_agricultural_university": "Bangladesh Agricultural University"
        },
        {
            "jahangirnagar_university": "Jahangirnagar University"
        },
        {
            "brac_university": "Brac University"
        },
        {
            "university_of_chittagong": "University of Chittagong"
        },
        {
            "independent_university_bangladesh": "Independent University Bangladesh"
        },
        {
            "daffodil_international_university": "Daffodil International University"
        },
        {
            "khulna_university": "Khulna University"
        },
        {
            "rajshahi_university_of_engineering_and_technology": "Rajshahi University of Engineering and Technology"
        },
        {
            "khulna_university_of_engineering_&_technology": "Khulna University of Engineering & Technology"
        },
        {
            "chittagong_university_of_engineering_and_technology": "Chittagong University of Engineering and Technology"
        },
        {
            "east_west_university_bangladesh": "East West University Bangladesh"
        },
        {
            "american_international_university_bangladesh": "American International University Bangladesh"
        },
        {
            "ahsanullah_university_of_science_&_technology": "Ahsanullah University of Science & Technology"
        },
        {
            "jagannath_university": "Jagannath University"
        },
        {
            "mawlana_bhasani_science_&_technology_university": "Mawlana Bhasani Science & Technology University"
        },
        {
            "dhaka_university_of_engineering_&_technology": "Dhaka University of Engineering & Technology"
        },
        {
            "sher-e-bangla_agricultural_university": "Sher-e-Bangla Agricultural University"
        },
        {
            "international_islamic_university_chittagong": "International Islamic University Chittagong"
        },
        {
            "islamic_university_kushtia": "Islamic University Kushtia"
        },
        {
            "patuakhali_science_&_technology_university": "Patuakhali Science & Technology University"
        },
        {
            "bangabandhu_sheikh_mujib_medical_university": "Bangabandhu Sheikh Mujib Medical University"
        },
        {
            "university_of_asia_pacific_bangladesh": "University of Asia Pacific Bangladesh"
        },
        {
            "sylhet_agricultural_university_(sher-e-bangla_agricultural_university)": "Sylhet Agricultural University (Sher-e-Bangla Agricultural University)"
        },
        {
            "united_international_university": "United International University"
        },
        {
            "military_institute_of_science_&_technology": "Military Institute of Science & Technology"
        },
        {
            "hajee_mohammad_danesh_science_&_technology_university_dinajpur": "Hajee Mohammad Danesh Science & Technology University Dinajpur"
        },
        {
            "noakhali_university_of_science_&_technology": "Noakhali University of Science & Technology"
        },
        {
            "bangabandhu_sheikh_mujibur_rahman_agricultural_university": "Bangabandhu Sheikh Mujibur Rahman Agricultural University"
        },
        {
            "southeast_university": "Southeast University"
        },
        {
            "university_of_liberal_arts_bangladesh": "University of Liberal Arts Bangladesh"
        },
        {
            "international_university_of_business_agriculture_and_technology_university_of_dhaka": "International University of Business Agriculture and Technology University of Dhaka"
        },
        {
            "islamic_university_of_technology": "Islamic University of Technology"
        },
        {
            "begum_rokeya_university_rangpur": "Begum Rokeya University Rangpur"
        },
        {
            "chittagong_veterinary_and_animal_sciences_university": "Chittagong Veterinary and Animal Sciences University"
        },
        {
            "green_university_of_bangladesh": "Green University of Bangladesh"
        },
        {
            "stamford_university_bangladesh": "Stamford University Bangladesh"
        },
        {
            "comilla_university": "Comilla University"
        },
        {
            "world_university_of_bangladesh": "World University of Bangladesh"
        },
        {
            "national_university_bangladesh": "National University Bangladesh"
        },
        {
            "bangladesh_university_of_professionals": "Bangladesh University of Professionals"
        },
        {
            "northern_university_bangladesh": "Northern University Bangladesh"
        },
        {
            "jessore_university_of_science_&_technology": "Jessore University of Science & Technology"
        },
        {
            "primeasia_university": "Primeasia University"
        },
        {
            "pabna_university_of_science_&_technology": "Pabna University of Science & Technology"
        },
        {
            "dhaka_international_university": "Dhaka International University"
        },
        {
            "university_of_science_&_technology_chittagong": "University of Science & Technology Chittagong"
        },
        {
            "southern_university_bangladesh": "Southern University Bangladesh"
        },
        {
            "east_delta_university": "East Delta University"
        },
        {
            "asian_university_for_women": "Asian University for Women"
        },
        {
            "bangladesh_university_of_business_and_technology": "Bangladesh University of Business and Technology"
        },
        {
            "uttara_university": "Uttara University"
        },
        {
            "dhaka_medical_college": "Dhaka Medical College"
        },
        {
            "university_of_information_technology_&_sciences": "University of Information Technology & Sciences"
        },
        {
            "bangabandah_sheikh_in_jubur_rahman_science_and_technology_university": "Bangabandah Sheikh in Jubur Rahman Science and Technology University"
        },
        {
            "manarat_international_university": "Manarat International University"
        },
        {
            "barisal_university": "Barisal University"
        },
        {
            "bangladesh_open_university": "Bangladesh Open University"
        },
        {
            "state_university_of_bangladesh": "State University of Bangladesh"
        },
        {
            "leading_university": "Leading University"
        },
        {
            "city_university_bangladesh": "City University Bangladesh"
        },
        {
            "university_of_dhaka_institute_of_statistical_research_and_training": "University of Dhaka Institute of Statistical Research and Training"
        },
        {
            "bangladesh_university_of_textiles": "Bangladesh University of Textiles"
        },
        {
            "university_of_development_alternative": "University of Development Alternative"
        },
        {
            "presidency_university_bangla_desh": "Presidency University Bangla Desh"
        },
        {
            "metropolitan_university_sylhet": "Metropolitan University Sylhet"
        },
        {
            "eastern_university_bangla_desh": "Eastern University Bangla Desh"
        },
        {
            "gono_university": "Gono University"
        },
        {
            "chittagong_medical_college": "Chittagong Medical College"
        },
        {
            "sylhet_international_university": "Sylhet International University"
        },
        {
            "asian_university_of_bangladesh": "Asian University of Bangladesh"
        },
        {
            "rajshahi_medical_university": "Rajshahi Medical University"
        },
        {
            "atish_dipankar_university_of_science_&_technology": "Atish Dipankar University of Science & Technology"
        },
        {
            "bangabandhu_sheikh_mujibur_rahman_maritime_university": "Bangabandhu Sheikh Mujibur Rahman Maritime University"
        },
        {
            "sir_salimullah_medical_college": "Sir Salimullah Medical College"
        },
        {
            "mymensingh_medical_college": "Mymensingh Medical College"
        },
        {
            "islamic_arabic_university": "Islamic Arabic University"
        },
        {
            "shanto_mariam_university_of_creative_technology": "Shanto Mariam University of Creative Technology"
        },
        {
            "varendra_university.": "Varendra University."
        },
        {
            "prime_university": "Prime University"
        },
        {
            "sonargaon_university": "Sonargaon University"
        },
        {
            "royal_university_of_dhaka": "Royal University of Dhaka"
        },
        {
            "rangamati_science_and_technology_university": "Rangamati Science and Technology University"
        },
        {
            "victoria_university_of_bangladesh": "Victoria University of Bangladesh"
        },
        {
            "north_western_university_khulna": "North Western University Khulna"
        },
        {
            "dhaka_city_college": "Dhaka City College"
        },
        {
            "people's_university_of_bangladesh": "People's University of Bangladesh"
        },
        {
            "sylhet_mag_osmani_medical_college": "Sylhet Mag Osmani Medical College"
        },
        {
            "armed_forces_medical_college_dhaka": "Armed Forces Medical College Dhaka"
        },
        {
            "bangladesh_university_of_health_sciences": "Bangladesh University of Health Sciences"
        },
        {
            "canadian_university_of_bangladesh": "Canadian University of Bangladesh"
        },
        {
            "army_university_of_engineering_and_technology_qadirabad": "Army University of Engineering and Technology Qadirabad"
        },
        {
            "bangabandhu_sheikh_mujibur_rahman_digital_university": "Bangabandhu Sheikh Mujibur Rahman Digital University"
        },
        {
            "the_millenium_university": "The Millenium University"
        },
        {
            "queens_university": "Queens University"
        },
        {
            "ibais_university": "IBAIS University"
        },
        {
            "feni_university": "Feni University"
        },
        {
            "rajshahi_medical_college": "Rajshahi Medical College"
        },
        {
            "central_women's_university": "Central Women's University"
        },
        {
            "fareast_international_university": "Fareast International University"
        },
        {
            "north_east_university_bangladesh": "North East University Bangladesh"
        },
        {
            "central_university_of_science_and_technology": "Central University of Science and Technology"
        },
        {
            "north_east_medical_college": "North East Medical College"
        },
        {
            "hamdard_university_bangladesh": "Hamdard University Bangladesh"
        },
        {
            "jahurul_islam_medical_college": "Jahurul Islam Medical College"
        },
        {
            "dhaka_national_medical_college": "Dhaka National Medical College"
        },
        {
            "khwaja_yunus_ali_university": "Khwaja Yunus Ali University"
        },
        {
            "bangamata_sheikh_fojilatunnesa_mujib_science_and_technological_university": "Bangamata sheikh fojilatunnesa mujib science and technological university"
        },
        {
            "university_of_global_village": "University of Global Village"
        },
        {
            "ishakha_international_university_bangladesh": "Ishakha International University Bangladesh"
        },
        {
            "german_university_bangladesh": "German University Bangladesh"
        },
        {
            "north_bengal_international_university": "North Bengal International University"
        },
        {
            "ibrahim_medical_college": "Ibrahim Medical College"
        },
        {
            "the_international_university_of_scholars": "The International University of Scholars"
        },
        {
            "tmss_medical_college": "TMSS Medical College"
        },
        {
            "first_capital_university_of_bangladesh": "First Capital University of Bangladesh"
        },
        {
            "ccn_university_of_science_&_technology": "CCN University of Science & Technology"
        },
        {
            "chittagong_medical_university": "Chittagong Medical University"
        },
        {
            "britannia_university": "Britannia University"
        }
    ],
    "sri lanka": [
        {
            "university_of_colombo": "University of Colombo"
        },
        {
            "university_of_peradeniya": "University of Peradeniya"
        },
        {
            "university_of_ruhuna": "University of Ruhuna"
        },
        {
            "university_of_sri_jayewardenepura": "University of Sri Jayewardenepura"
        },
        {
            "university_of_moratuwa": "University of Moratuwa"
        },
        {
            "university_of_kelaniya": "University of Kelaniya"
        },
        {
            "university_of_jaffna": "University of Jaffna"
        },
        {
            "rajarata_university": "Rajarata University"
        },
        {
            "wayamba_university_of_sri_lanka": "wayamba university of sri lanka"
        },
        {
            "sabaragamuwa_university": "Sabaragamuwa University"
        },
        {
            "open_university_of_sri_lanka": "Open University of Sri Lanka"
        },
        {
            "general_sir_john_kotelawala_defence_university": "General Sir John Kotelawala Defence University"
        },
        {
            "south_eastern_university_of_sri_lanka": "South Eastern University of Sri Lanka"
        },
        {
            "eastern_university_of_sri_lanka": "Eastern University of Sri Lanka"
        },
        {
            "uva_wellaza_university": "UVA Wellaza University"
        },
        {
            "sri_lanka_institute_of_information_technology": "Sri Lanka Institute of Information Technology"
        },
        {
            "national_institute_of_fundamental_studies_sri_lanka": "National Institute of Fundamental Studies Sri Lanka"
        },
        {
            "university_grants_commission_sri_lanka": "University Grants Commission Sri Lanka"
        },
        {
            "sri_lanka_technological_campus": "Sri Lanka Technological Campus"
        },
        {
            "informatics_institute_of_technology_sri_lanka": "Informatics Institute of Technology Sri Lanka"
        },
        {
            "national_institute_of_education_sri_lanka": "National Institute of Education Sri Lanka"
        },
        {
            "esoft_metro_campus": "ESOFT Metro Campus"
        },
        {
            "national_institute_of_business_management": "National Institute of Business Management"
        },
        {
            "sri_lanka_institute_of_advanced_technological_education": "Sri Lanka Institute of Advanced Technological Education"
        },
        {
            "nsbm_green_university": "NSBM Green University"
        },
        {
            "asia_pacific_institute_of_information_technology_sri_lanka": "Asia Pacific Institute of Information Technology Sri Lanka"
        },
        {
            "horizon_campus": "Horizon Campus"
        },
        {
            "anc_education": "ANC Education"
        },
        {
            "buddhist_&_pali_university_of_sri_lanka": "Buddhist & Pali University of Sri Lanka"
        },
        {
            "sri_lanka_institute_of_architects": "Sri Lanka Institute of Architects"
        },
        {
            "american_college_of_higher_education": "American College of Higher Education"
        },
        {
            "royal_institute_of_colombo": "Royal Institute of Colombo"
        },
        {
            "sri_lanka_press_institute": "Sri Lanka Press Institute"
        },
        {
            "ocean_university_of_sri_lanka": "Ocean University of Sri Lanka"
        },
        {
            "aquinas_college_of_higher_studies_colombo": "Aquinas College of Higher Studies Colombo"
        },
        {
            "institute_of_engineering_technology": "Institute of Engineering Technology"
        },
        {
            "bms_business_management_school": "BMS Business Management School"
        },
        {
            "idm_nations_campus": "IDM Nations Campus"
        },
        {
            "imperial_institute_of_higher_education": "Imperial Institute of Higher Education"
        },
        {
            "sri_lanka_international_buddhist_academy_siba": "Sri Lanka International Buddhist Academy SIBA"
        },
        {
            "sliit_academy": "SLIIT Academy"
        },
        {
            "northshore_college": "Northshore College"
        },
        {
            "iesl_college_of_engineering": "IESL College of Engineering"
        },
        {
            "iuhs_campus": "IUHS Campus"
        },
        {
            "postgraduate_institute_of_humanities_and_social_sciences_university_of_peradeniya": "Postgraduate Institute of Humanities and Social Sciences University of Peradeniya"
        },
        {
            "nagananda_international_institute_for_buddhist_studies": "Nagananda International Institute for Buddhist Studies"
        }
    ],
    "kuwait": [
        {
            "college_of_technological_studies": "College of Technological Studies"
        },
        {
            "american_university_of_the_middle_east": "American University of the Middle East"
        },
        {
            "gulf_university_for_science_&_technology": "Gulf University for Science & Technology"
        },
        {
            "kuwait_university": "Kuwait University"
        },
        {
            "american_university_of_kuwait": "American University of Kuwait"
        },
        {
            "arab_open_university_kuwait": "Arab Open University Kuwait"
        },
        {
            "australian_college_of_kuwait": "Australian College of Kuwait"
        },
        {
            "american_college_of_the_middle_east": "American College of the Middle East"
        },
        {
            "kuwait_international_law_school": "Kuwait International Law School"
        },
        {
            "box_hill_college_for_women": "Box Hill College for Women"
        },
        {
            "kuwait_institute_for_medical_specialization": "Kuwait Institute for Medical Specialization"
        },
        {
            "msm_kuwait": "MSM Kuwait"
        }
    ],
    "bahrain": [
        {
            "university_of_bahrain": "University of Bahrain"
        },
        {
            "arabian_gulf_university": "Arabian Gulf University"
        },
        {
            "ahlia_university": "Ahlia University"
        },
        {
            "applied_science_university_of_bahrain": "Applied Science University of Bahrain"
        },
        {
            "gulf_university": "Gulf University"
        },
        {
            "arab_open_university_bahrain": "Arab Open University Bahrain"
        },
        {
            "royal_university_for_women": "Royal University for Women"
        },
        {
            "bahrain_polytechnic": "Bahrain Polytechnic"
        },
        {
            "bahrain_institute_of_banking_and_finance": "Bahrain Institute of Banking and Finance"
        },
        {
            "ama_international_university_of_bahrain": "Ama International University of Bahrain"
        },
        {
            "university_college_of_bahrain": "University College of Bahrain"
        },
        {
            "omega_college": "Omega College"
        }
    ],
    "iraq": [
        {
            "university_of_baghdad": "University of Baghdad"
        },
        {
            "mustansiriyah_university": "Mustansiriyah University"
        },
        {
            "university_of_kufa": "University of Kufa"
        },
        {
            "university_of_mosul": "University of Mosul"
        },
        {
            "university_of_sulaimani": "University of Sulaimani"
        },
        {
            "tikrit_university": "Tikrit University"
        },
        {
            "university_of_anbar": "University of Anbar"
        },
        {
            "salahaddin_university_erbil": "Salahaddin University Erbil"
        },
        {
            "university_of_babylon": "University of Babylon"
        },
        {
            "al_furat_al_awsat_technical_university": "Al Furat Al Awsat Technical University"
        },
        {
            "kerbala_university/_university_of_karbala": "Kerbala University/ University of Karbala"
        },
        {
            "al_nahrain_university": "Al Nahrain University"
        },
        {
            "university_of_human_development": "University of Human Development"
        },
        {
            "university_of_duhok": "University of Duhok"
        },
        {
            "diyala_university.": "Diyala University."
        },
        {
            "university_of_technology_iraq": "University of Technology Iraq"
        },
        {
            "hawler_medical_university": "Hawler Medical University"
        },
        {
            "university_of_referee": "University of Referee"
        },
        {
            "middle_technical_university": "Middle Technical University"
        },
        {
            "northern_technical_university": "Northern Technical University"
        },
        {
            "al_qadisiyah_university": "Al Qadisiyah University"
        },
        {
            "university_of_basrah": "University of Basrah"
        },
        {
            "at_mustaqbal_university_college": "At Mustaqbal University College"
        },
        {
            "bilad_al_rafidain_university_college": "Bilad Al Rafidain University College"
        },
        {
            "al_rasheed_university_college": "Al Rasheed University College"
        },
        {
            "soran_university": "Soran University"
        },
        {
            "al_safwa_university_college": "Al Safwa University College"
        },
        {
            "university_of_kurdistan_helwer": "University of Kurdistan Helwer"
        },
        {
            "koya_university": "Koya University"
        },
        {
            "kirkurk_university": "Kirkurk University"
        },
        {
            "university_of_your": "University of your"
        },
        {
            "tishk_international_university_(ishik_university)": "Tishk International University (Ishik University)"
        },
        {
            "american_university_of_iraq_sulaimani": "American University of Iraq Sulaimani"
        },
        {
            "university_of_misan.": "University of Misan."
        },
        {
            "sulaimani_polytechnic_university": "Sulaimani Polytechnic University"
        },
        {
            "university_of_information_technology_&_communication": "University of Information Technology & Communication"
        },
        {
            "cihan_university_erbil": "Cihan University Erbil"
        },
        {
            "duhok_polytechnic_university": "Duhok Polytechnic University"
        },
        {
            "al_iraqia_university": "Al Iraqia University"
        },
        {
            "university_of_samarra": "University of Samarra"
        },
        {
            "university_of_raparin": "University of Raparin"
        },
        {
            "university_of_garmian": "University of Garmian"
        },
        {
            "dijlah_university_college": "Dijlah University College"
        },
        {
            "lebanese_french_university_lfu_erbil": "Lebanese French University LFU Erbil"
        },
        {
            "ninevah_university": "Ninevah University"
        },
        {
            "al_mansour_university_college": "Al Mansour University College"
        },
        {
            "charmo_university": "Charmo University"
        },
        {
            "american_university_kurdistan": "American University Kurdistan"
        },
        {
            "knowledge_university": "Knowledge University"
        },
        {
            "al_esraa_university_college": "Al Esraa University College"
        },
        {
            "madenat_alelem_university_college": "Madenat Alelem University College"
        },
        {
            "university_of_alkafeel": "University of Alkafeel"
        },
        {
            "al_rafidain_university_college": "Al Rafidain University College"
        },
        {
            "alkarkh_university_for_science": "AlKarkh University for Science"
        },
        {
            "imam_ja'afar_al-sadiq_university_": "imam ja'afar Al-Sadiq University "
        },
        {
            "university_of_halabja": "University of Halabja"
        },
        {
            "baghdad_college_for_economic_sciences_university": "Baghdad College for Economic Sciences University"
        },
        {
            "at_the_farabi_university_college": "At the Farabi University College"
        },
        {
            "al_bayan_university": "Al Bayan University"
        },
        {
            "cihan_university_campus_sulaimaniya": "Cihan University Campus Sulaimaniya"
        },
        {
            "university_of_hamdaniya": "University of Hamdaniya"
        },
        {
            "jabir_ibn_hayyan_medical_university": "Jabir ibn Hayyan Medical University"
        },
        {
            "ibn_sina_university_of_medical_and_pharmaceutical_sciences": "Ibn Sina University of Medical and Pharmaceutical Sciences"
        },
        {
            "at_the_noor_university_college": "At the Noor University College"
        },
        {
            "al-ma'moon_university_college": "AL-MA'MOON UNIVERSITY COLLEGE"
        },
        {
            "al_turath_university": "Al Turath University"
        },
        {
            "southern_technical_university": "Southern Technical University"
        },
        {
            "imam_al_kadhum_college": "Imam Al Kadhum College"
        },
        {
            "al_maarif_university_colege": "Al Maarif University Colege"
        },
        {
            "basrah_university_for_oil_and_gas": "Basrah University for Oil and Gas"
        },
        {
            "location_university_duhok": "Location University Duhok"
        },
        {
            "iraq_university_college": "Iraq University College"
        },
        {
            "international_university_of_erbil": "International University of Erbil"
        },
        {
            "al_kut_university_college": "Al Kut University College"
        },
        {
            "american_university_of_iraq_baghdad": "American University of Iraq Baghdad"
        },
        {
            "uruk_private_university": "Uruk Private University"
        }
    ],
    "montenegro": [
        {
            "university_of_montenegro": "University of Montenegro"
        },
        {
            "university_of_donja_gorica": "University of Donja Gorica"
        },
        {
            "mediterranean_university_montenegro": "Mediterranean University Montenegro"
        }
    ],
    "armenia": [
        {
            "yerevan_state_university": "Yerevan State University"
        },
        {
            "american_university_of_armenia": "American University of Armenia"
        },
        {
            "russian_armenian_state_university": "Russian Armenian State University"
        },
        {
            "yerevan_state_medical_university": "Yerevan State Medical University"
        },
        {
            "armenian_state_university_of_economics": "Armenian State University of Economics"
        },
        {
            "national_polytechnic_university_of_armenia": "National Polytechnic University of Armenia"
        },
        {
            "yerevan_state_linguistic_university": "Yerevan State Linguistic University"
        },
        {
            "armenian_national_agricultural_university": "Armenian National Agricultural University"
        },
        {
            "armenian_state_pedagogical_university": "Armenian State Pedagogical University"
        },
        {
            "national_university_of_architecture_and_construction_of_armenia": "National University of Architecture and Construction of Armenia"
        },
        {
            "french_university_in_armenia": "French University in Armenia"
        },
        {
            "eurasia_international_university": "Eurasia International University"
        },
        {
            "gavar_state_university": "Gavar State University"
        },
        {
            "yerevan_state_academy_of_fine_arts": "Yerevan State Academy of Fine Arts"
        },
        {
            "mehrabyan_medical_institute": "Mehrabyan Medical Institute"
        },
        {
            "shirak_m_nalbandyan_state_university": "Shirak M Nalbandyan State University"
        },
        {
            "armenian_state_institute_of_physical_culture": "Armenian State Institute of Physical Culture"
        },
        {
            "yerevan_state_institute_of_theater_and_cinema": "Yerevan State Institute of Theater and Cinema"
        },
        {
            "goris_state_university": "Goris State University"
        },
        {
            "northern_university": "Northern University"
        },
        {
            "urartu_university_of_psychology_and_sociology": "Urartu University of Psychology and Sociology"
        },
        {
            "yerevan_movses_khorenatsi_university": "Yerevan Movses Khorenatsi University"
        },
        {
            "university_of_economy_and_law_after_avetik_mkrtchyan": "University of Economy and Law after Avetik Mkrtchyan"
        },
        {
            "mkhitar_gosh_university": "Mkhitar Gosh University"
        },
        {
            "yerevan_haybusak_university": "Yerevan Haybusak University"
        }
    ],
    "mongolia": [
        {
            "national_university_of_mongolia": "National University of Mongolia"
        },
        {
            "mongolian_university_of_science_&_technology": "Mongolian University of Science & Technology"
        },
        {
            "khovd_university": "Khovd University"
        },
        {
            "mongolian_universityy_of_life_sciences_(mongolian_state_university_of_agriculture)": "Mongolian Universityy of Life Sciences (Mongolian State University of Agriculture)"
        },
        {
            "gurvan_tamir_deed_surguul": "Gurvan Tamir Deed Surguul"
        },
        {
            "mongolian_state_university_of_education": "Mongolian State University of Education"
        },
        {
            "university_of_the_humanities": "University of the Humanities"
        },
        {
            "mongolian_university_of_arts_and_culture": "Mongolian University of Arts and Culture"
        },
        {
            "german-mongolian_institute_for_resources_and_technology": "German-Mongolian Institute for Resources and Technology"
        },
        {
            "otgontenger_university": "Otgontenger University"
        },
        {
            "etugen_university": "Etugen University"
        },
        {
            "citi_university": "Citi University"
        },
        {
            "school_of_information_and_telecommunication_technology": "School of Information and Telecommunication Technology"
        },
        {
            "shihihutug_university": "Shihihutug University"
        },
        {
            "governing_university": "Governing University"
        },
        {
            "mandakh_university": "Mandakh University"
        },
        {
            "san_university": "San University"
        },
        {
            "university_of_new_medicine": "University of New Medicine"
        },
        {
            "darkhan_institute": "Darkhan Institute"
        },
        {
            "mongolian_national_university_of_economics": "Mongolian National University of Economics"
        }
    ],
    "nepal": [
        {
            "kathmandu_university": "Kathmandu University"
        },
        {
            "tribhuvan_university": "Tribhuvan University"
        },
        {
            "b_p_koirala_institute_of_health_sciences": "B P Koirala Institute of Health Sciences"
        },
        {
            "pokhara_university": "Pokhara University"
        },
        {
            "patan_academy_of_health_sciences": "Patan Academy of Health Sciences"
        },
        {
            "tribhuvan_university_institute_of_engineering": "Tribhuvan University Institute of Engineering"
        },
        {
            "tribhuvan_university_institute_of_medicine": "Tribhuvan University Institute of Medicine"
        },
        {
            "agriculture_and_forestry_university": "Agriculture and Forestry University"
        },
        {
            "nepal_engineering_college": "Nepal Engineering College"
        },
        {
            "tribhuvan_university_institute_of_agriculture_and_animal_science": "Tribhuvan University Institute of Agriculture and Animal Science"
        },
        {
            "nepal_medical_college_&_nepal_medical_college_teaching_hospital": "Nepal Medical College & Nepal Medical College Teaching Hospital"
        },
        {
            "kantipur_engineering_college": "Kantipur Engineering College"
        },
        {
            "mid_western_university": "Mid Western University"
        },
        {
            "advanced_college_of_engineering_nepal": "Advanced College of Engineering Nepal"
        },
        {
            "national_medical_college_birgunj": "National Medical College Birgunj"
        },
        {
            "nepal_college_of_travel_and_tourism_management": "Nepal College of Travel and Tourism Management"
        },
        {
            "dibyahbumi_multiple_college": "Dibyahbumi Multiple College"
        }
    ],
    "moldova": [
        {
            "state_university_of_moldova": "State University of Moldova"
        },
        {
            "technical_university_of_moldova": "Technical University of Moldova"
        },
        {
            "balti_state_university_alecu_russo": "Balti State University Alecu Russo"
        },
        {
            "free_international_university_of_moldova": "Free International University of Moldova"
        },
        {
            "moldova_cooperative_trade_university": "Moldova Cooperative Trade University"
        },
        {
            "academy_of_economic_studies_from_moldova": "Academy of Economic Studies from Moldova"
        },
        {
            "state_university_of_tiraspol": "State University of Tiraspol"
        },
        {
            "taras_shevchenko_transnistria_state_university": "Taras Shevchenko Transnistria State University"
        },
        {
            "chisinau_state_pedagogical_university_ion_creanga": "Chisinau State Pedagogical University Ion Creanga"
        },
        {
            "cahul_state_university_bogdan_petriceicu_hasdeu": "Cahul State University Bogdan Petriceicu Hasdeu"
        },
        {
            "state_agricultural_university_of_moldova": "State Agricultural University of Moldova"
        },
        {
            "state_university_of_comrat": "State University of Comrat"
        },
        {
            "academy_of_music_theatre_and_fine_arts": "Academy of Music Theatre and Fine Arts"
        },
        {
            "moldova_university_of_european_studies": "Moldova University of European Studies"
        },
        {
            "police_academy_\u00e5\u017etefan_cel_mare": "Police Academy \u00c5\u017etefan cel Mare"
        },
        {
            "slavic_university_of_the_republic_of_moldova": "Slavic University of the Republic of Moldova"
        },
        {
            "university_of_academy_of_sciences_of_moldova": "University of Academy of Sciences of Moldova"
        },
        {
            "university_of_european_political_and_economic_studies_constantin_stere": "University of European Political and Economic Studies Constantin Stere"
        },
        {
            "imi-nova_international_management_institute": "Imi-Nova International Management Institute"
        },
        {
            "taraklian_state_university_gregory_tsamblak": "Taraklian State University Gregory Tsamblak"
        }
    ],
    "liechtenstein": [
        {
            "university_of_liechtenstein": "University of Liechtenstein"
        },
        {
            "international_academy_of_philosophy_liechtenstein": "International Academy of Philosophy Liechtenstein"
        },
        {
            "private_university_in_principality_of_liechtenstein": "Private University in Principality of Liechtenstein"
        }
    ],
    "syria": [
        {
            "damascus_university": "Damascus University"
        },
        {
            "tishreen_university": "Tishreen University"
        },
        {
            "university_of_aleppo": "University of Aleppo"
        },
        {
            "al_baath_university": "Al Baath University"
        },
        {
            "higher_institute_of_applied_science_and_damascus_technology": "Higher Institute of Applied Science and Damascus Technology"
        },
        {
            "arab_international_university_damascus": "Arab International University Damascus"
        },
        {
            "syrian_private_university_(international_private_university_for_science_&_technology)": "Syrian Private University (International Private University for Science & Technology)"
        },
        {
            "syrian_virtual_university": "Syrian Virtual University"
        },
        {
            "university_of_kalamoon": "University of Kalamoon"
        },
        {
            "al_andalus_university_for_medical_sciences": "Al Andalus University for Medical Sciences"
        },
        {
            "hama_university": "Hama University"
        },
        {
            "international_university_for_science_&_technology": "International University for Science & Technology"
        },
        {
            "alsham_private_university": "Alsham Private University"
        },
        {
            "academy_of_health_sciences": "Academy of Health Sciences"
        },
        {
            "al_jazeera_university": "Al Jazeera University"
        },
        {
            "al_wataniya_private_university": "Al Wataniya Private University"
        },
        {
            "al_hawash_private_university": "Al Hawash Private University"
        },
        {
            "wadi_international_university": "Wadi International University"
        },
        {
            "higher_institute_of_business_administration": "Higher Institute of Business Administration"
        },
        {
            "yarmouk_private_university": "Yarmouk Private University"
        },
        {
            "antioch_syrian_university": "Antioch Syrian University"
        },
        {
            "syrian_international_academy": "Syrian International Academy"
        },
        {
            "idlib_university": "Idlib University"
        }
    ],
    "brunei": [
        {
            "university_of_brunei_darussalam": "University of Brunei Darussalam"
        },
        {
            "brunei_university_of_technology": "Brunei University of Technology"
        },
        {
            "islamic_university_sultan_sharif_ali": "Islamic University Sultan Sharif Ali"
        },
        {
            "admiral_college_of_business": "Admiral College of Business"
        },
        {
            "institute_of_brunei_technical_education": "Institute of Brunei Technical Education"
        }
    ],
    "uzbekistan": [
        {
            "national_university_of_uzbekistan": "National University of Uzbekistan"
        },
        {
            "andijan_state_medical_institute": "Andijan State Medical Institute"
        },
        {
            "tashkent_state_university_of_economics": "Tashkent State University of Economics"
        },
        {
            "jizzakh_state_pedagogical_institute": "Jizzakh State Pedagogical Institute"
        },
        {
            "tashkent_university_of_information_technologies_muhammad_al_khwarizmi": "Tashkent University of Information Technologies Muhammad Al Khwarizmi"
        },
        {
            "tashkent_state_technical_university_islam_karimov": "Tashkent State Technical University Islam Karimov"
        },
        {
            "tashkent_state_pedagogical_university": "Tashkent State Pedagogical University"
        },
        {
            "samarkand_state_university": "Samarkand State University"
        },
        {
            "tashkent_medical_academy": "Tashkent Medical Academy"
        },
        {
            "samarkand_state_medical_institute": "Samarkand State Medical Institute"
        },
        {
            "bukhara_state_university": "Bukhara State University"
        },
        {
            "tashkent_institute_of_textile_and_light_industry": "Tashkent Institute of Textile and Light Industry"
        },
        {
            "tashkent_institute_of_irrigation_and_agriculture_mechanization_engineers": "Tashkent Institute of Irrigation and Agriculture Mechanization Engineers"
        },
        {
            "tashkent_state_university_of_law": "Tashkent State University of Law"
        },
        {
            "karschi_engineering_economic_institute": "Karschi Engineering Economic Institute"
        },
        {
            "tashkent_pediatric_medicine_institute": "Tashkent Pediatric Medicine Institute"
        },
        {
            "karakalpak_state_university": "Karakalpak State University"
        },
        {
            "urgench_state_university": "Urgench State University"
        },
        {
            "andijan_state_university": "Andijan State University"
        },
        {
            "westminster_international_university_in_tashkent": "Westminster International University in Tashkent"
        },
        {
            "termez_state_university": "Termez State University"
        },
        {
            "tashkent_institute_of_railway_technology": "Tashkent Institute of Railway Technology"
        },
        {
            "nukus_state_pedagogical_insititute": "Nukus State Pedagogical Insititute"
        },
        {
            "bukhara_state_medical_institute_abu_ali_ibn_sin": "Bukhara State Medical Institute Abu Ali ibn Sin"
        },
        {
            "tashkent_financial_institute": "Tashkent Financial institute"
        },
        {
            "bukhara_engineering_technological_institute": "Bukhara Engineering Technological Institute"
        },
        {
            "uzbekistan_state_university_of_world_languages": "Uzbekistan State University of World Languages"
        },
        {
            "chirchiq_state_pedagogical_institute_of_tashkent_region": "Chirchiq State Pedagogical Institute of Tashkent Region"
        },
        {
            "guliston_state_university": "Guliston State University"
        },
        {
            "kokand_state_pedagogical_institute": "Kokand State Pedagogical Institute"
        },
        {
            "state_conservatory_of_uzbekistan": "State Conservatory of Uzbekistan"
        },
        {
            "andijan_machine_building_institute": "Andijan Machine Building Institute"
        },
        {
            "tashkent_university_of_information_technologies_nukus_branch": "Tashkent University of Information Technologies Nukus Branch"
        },
        {
            "national_institute_of_arts_and_design_kamoliddi_bezhod": "National Institute of Arts and Design Kamoliddi Bezhod"
        },
        {
            "tashkent_chemical_technological_institute": "Tashkent Chemical Technological Institute"
        },
        {
            "navoiy_state_mining_institute": "Navoiy State Mining Institute"
        },
        {
            "tashkent_university_of_information_technologies_samarkand_branch": "Tashkent University of Information Technologies Samarkand Branch"
        },
        {
            "karshi_state_university": "Karshi State University"
        },
        {
            "samarkand_institute_of_economics_and_service": "Samarkand Institute of Economics and Service"
        },
        {
            "silk_road_international_tourism_university": "Silk Road International Tourism University"
        },
        {
            "samarkand_state_architectural_and_civil_engineering_institute": "Samarkand State Architectural and Civil Engineering Institute"
        },
        {
            "namangan_state_university": "Namangan State University"
        },
        {
            "state_institute_of_arts_and_culture_of_uzbekistan": "State Institute of Arts and Culture of Uzbekistan"
        },
        {
            "banking_and_finance_academy_of_uzbekistan": "Banking and Finance Academy of Uzbekistan"
        },
        {
            "tashkent_medical_academy_urgench_branch": "Tashkent Medical Academy Urgench Branch"
        },
        {
            "samarkand_institute_of_veterinary_medicine": "Samarkand Institute of Veterinary Medicine"
        },
        {
            "uzbek_state_institute_of_physical_culture": "Uzbek State Institute of Physical Culture"
        },
        {
            "tashkent_state_higher_school_of_national_dance_and_choreography": "Tashkent State Higher School of National Dance and Choreography"
        }
    ],
    "albania": [
        {
            "university_of_tirana": "University of Tirana"
        },
        {
            "agricultural_university_of_tirana": "Agricultural University of Tirana"
        },
        {
            "epoka_university": "Epoka University"
        },
        {
            "polytechnic_university_of_tirana": "Polytechnic University of Tirana"
        },
        {
            "university_europian_i_tiranes": "University Europian i Tiranes"
        },
        {
            "university_of_new_york_tirana": "University of New York Tirana"
        },
        {
            "university_luarasi": "University Luarasi"
        },
        {
            "canadian_institute_of_technology": "Canadian Institute of Technology"
        },
        {
            "academy_of_sports_and_physical_education_vojo_kushi": "Academy of Sports and Physical Education Vojo Kushi"
        },
        {
            "university_polis": "University POLIS"
        },
        {
            "university_pavaresia": "University Pavaresia"
        },
        {
            "university_of_medicine_tirana": "University of Medicine Tirana"
        },
        {
            "academy_of_albanological_studies": "Academy of Albanological Studies"
        },
        {
            "universiteti_metropolitan_tirana": "Universiteti Metropolitan Tirana"
        },
        {
            "academy_of_film_&_multimedia_marubi": "Academy of Film & Multimedia MARUBI"
        },
        {
            "academy_of_applied_studies_reald": "Academy of Applied Studies Reald"
        },
        {
            "aldent_university": "Aldent University"
        },
        {
            "mediterranean_university_of_albania": "Mediterranean University of Albania"
        },
        {
            "qiriazi_university_college": "Qiriazi University College"
        },
        {
            "professional_college_of_tirana": "Professional College of Tirana"
        },
        {
            "university_college_wisdom": "University College Wisdom"
        }
    ],
    "kyrgyzstan": [
        {
            "kyrgyz_turkish_manas_university": "Kyrgyz Turkish Manas University"
        },
        {
            "american_university_of_central_asia": "American University of Central Asia"
        },
        {
            "kyrgyz_russian_slavic_university": "Kyrgyz Russian Slavic University"
        },
        {
            "kyrgyz_state_medical_academy": "Kyrgyz State Medical Academy"
        },
        {
            "osh_state_university": "Osh State University"
        },
        {
            "international_academy_of_management_law_finance_and_business": "International Academy of Management Law Finance and Business"
        },
        {
            "issyk-kul_state_university_k_tynystanov": "Issyk-Kul State University K Tynystanov"
        },
        {
            "osh_technological_university": "Osh Technological University"
        },
        {
            "kyrgyz_state_technical_university": "Kyrgyz State Technical University"
        },
        {
            "osce_academy_in_bishkek": "OSCE Academy in Bishkek"
        },
        {
            "osh_state_university_medical_faculty": "Osh State University Medical Faculty"
        },
        {
            "kyrgyz_state_university_of_construction_transport_and_architecture": "Kyrgyz State University of Construction Transport and Architecture"
        },
        {
            "international_university_k_sh_toktamatov": "International University K Sh Toktamatov"
        },
        {
            "international_university_of_central_asia": "International University of Central Asia"
        },
        {
            "kyrgyz_state_law_academy": "Kyrgyz State Law Academy"
        },
        {
            "kyrgyz_economic_university": "Kyrgyz Economic University"
        },
        {
            "ala_too_international_university_(international_ataturk_alaturo_university)": "Ala Too International University (International Ataturk Alaturo University)"
        },
        {
            "talas_state_university": "Talas State University"
        },
        {
            "osh_state_juridical_institute": "Osh State Juridical Institute"
        },
        {
            "kyrgyz_state_university_of_culture_and_arts_b_beishenalieva": "Kyrgyz State University of Culture and Arts B Beishenalieva"
        }
    ],
    "yemen": [
        {
            "sana'a_university.": "Sana'a University."
        },
        {
            "taiz_university": "Taiz University"
        },
        {
            "hodeidah_university": "Hodeidah University"
        },
        {
            "university_of_aden": "University of Aden"
        },
        {
            "thamar_university": "Thamar University"
        },
        {
            "hadhramout_university": "Hadhramout University"
        },
        {
            "ibb_university": "Ibb University"
        },
        {
            "al_razi_university": "Al Razi University"
        },
        {
            "al_nasser_university": "Al Nasser University"
        },
        {
            "queen_arwa_university": "Queen Arwa University"
        },
        {
            "saba_university.": "Saba University."
        },
        {
            "al_andalus_university_for_science_&_technology": "Al Andalus University for Science & Technology"
        },
        {
            "future_university_yemen": "Future University Yemen"
        },
        {
            "national_university": "National university"
        },
        {
            "university_of_modern_sciences_sana'a": "University of Modern Sciences Sana'a"
        },
        {
            "al_saeeda_university": "Al Saeeda University"
        },
        {
            "al_jazeera_university_ibb": "Al Jazeera University Ibb"
        },
        {
            "albaydaa_university": "Albaydaa University"
        },
        {
            "yemenia_university": "Yemenia University"
        },
        {
            "university_of_the_union": "University of the Union"
        },
        {
            "yemeni_university_of_jordan": "Yemeni University of Jordan"
        }
    ],
    "cambodia": [
        {
            "institute_of_technology_of_cambodia": "Institute of Technology of Cambodia"
        },
        {
            "royal_university_of_phnom_penh": "Royal University of Phnom Penh"
        },
        {
            "royal_university_of_agriculture_cambodia": "Royal University of Agriculture Cambodia"
        },
        {
            "university_of_health_science": "University of Health Science"
        },
        {
            "national_polytechnic_institute_of_cambodia": "National Polytechnic Institute of Cambodia"
        },
        {
            "university_of_puthisastra": "University of Puthisastra"
        },
        {
            "university_of_cambodia": "University of Cambodia"
        },
        {
            "open_institute": "Open Institute"
        },
        {
            "international_university_cambodia": "International University Cambodia"
        },
        {
            "national_university_of_management": "National University of Management"
        },
        {
            "royal_university_of_law_and_economics": "Royal University of Law and Economics"
        },
        {
            "paragon_international_university": "Paragon International University"
        },
        {
            "asia_euro_university": "Asia Euro University"
        },
        {
            "american_university_of_phnom_penh": "American University of Phnom Penh"
        },
        {
            "svay_rieng_university": "Svay Rieng University"
        },
        {
            "cambodian_university_for_specialties": "Cambodian University for Specialties"
        },
        {
            "university_of_southeast_asia": "University of Southeast Asia"
        },
        {
            "vanda_institute_of_accounting": "Vanda Institute of Accounting"
        },
        {
            "angkor_university": "Angkor University"
        },
        {
            "iic_university_of_technology": "IIC University of Technology"
        },
        {
            "university_of_management_and_economics_ume": "University of Management and Economics UME"
        },
        {
            "national_technical_training_institute": "National Technical Training Institute"
        },
        {
            "mean_chey_university": "Mean Chey University"
        },
        {
            "phnom_penh_international_university_(asean_university)": "Phnom Penh International University (Asean University)"
        },
        {
            "kirirom_institute_of_technology": "Kirirom Institute of Technology"
        },
        {
            "life_university_cambodia": "Life University Cambodia"
        },
        {
            "dewey_international_of_cambodia": "Dewey International of Cambodia"
        },
        {
            "economics_and_finance_institute": "Economics and Finance Institute"
        },
        {
            "the_royal_school_of_administration": "The Royal School of Administration"
        },
        {
            "industrial_technical_institute": "Industrial Technical Institute"
        }
    ],
    "greenland": [
        {
            "university_of_greenland": "University of Greenland"
        }
    ],
    "faroe islands": [
        {
            "university_of_the_faroe_islands": "University of the Faroe Islands"
        }
    ],
    "laos": [
        {
            "national_university_of_laos": "National University of Laos"
        },
        {
            "university_of_health_sciences_lao": "University of Health Sciences Lao"
        },
        {
            "souphanouvong_university": "Souphanouvong University"
        }
    ],
    "san marino": [
        {
            "university_of_the_republic_of_san_marino": "University of the Republic of San Marino"
        }
    ],
    "bhutan": [
        {
            "royal_university_of_bhutan": "Royal University of Bhutan"
        },
        {
            "royal_university_of_bhutan_paro_college_of_education": "Royal University of Bhutan Paro College of Education"
        },
        {
            "royal_thimphu_college": "Royal Thimphu College"
        },
        {
            "royal_university_of_bhutan_college_of_science_&_technology": "Royal University of Bhutan College of Science & Technology"
        },
        {
            "royal_university_of_bhutan_sherubtse_college": "Royal University of Bhutan Sherubtse College"
        },
        {
            "royal_university_of_bhutan_college_of_natural_resources": "Royal University of Bhutan College of Natural Resources"
        },
        {
            "jigme_namgyel_engineering_college_(jigme_namgyel_polytechnic)": "Jigme Namgyel Engineering College (Jigme Namgyel Polytechnic)"
        }
    ],
    "afghanistan": [
        {
            "kabul_university": "Kabul University"
        },
        {
            "polytechnical_university_of_kabul": "Polytechnical University of Kabul"
        },
        {
            "the_other_university": "The Other University"
        },
        {
            "nangarhar_university": "Nangarhar University"
        },
        {
            "herat_university": "Herat University"
        },
        {
            "carney_university": "Carney University"
        },
        {
            "kateb_university": "Kateb University"
        },
        {
            "maiwand_institute_of_higher_education": "Maiwand institute of Higher Education"
        },
        {
            "sharq_university": "Sharq University"
        },
        {
            "salam_university": "Salam University"
        },
        {
            "sheikh_zayed_university_(khost_university)": "Sheikh Zayed University (Khost University)"
        },
        {
            "rana_university_(rana_institute_of_higher_education)": "Rana University (Rana Institute of Higher Education)"
        },
        {
            "jawzjan_university": "Jawzjan University"
        },
        {
            "kandahar_university": "Kandahar University"
        },
        {
            "karwan_university": "Karwan University"
        },
        {
            "dunya_university_of_afghanistan": "Dunya University of Afghanistan"
        },
        {
            "bakhtar_university": "Bakhtar University"
        },
        {
            "ghalib_university": "Ghalib University"
        },
        {
            "dawat_university": "Dawat University"
        },
        {
            "ghazni_university": "Ghazni University"
        },
        {
            "maryam_institute_of_higher_education": "Maryam Institute of Higher Education"
        },
        {
            "fanoos_institute_of_higher_education": "Fanoos Institute of Higher Education"
        },
        {
            "university_of_gharjistan": "University of Gharjistan"
        },
        {
            "asia_higher_education_institution": "Asia Higher Education Institution"
        },
        {
            "kabul_education_university_shaheed_rabbani": "Kabul Education University Shaheed Rabbani"
        },
        {
            "faryab_university": "Faryab University"
        }
    ],
    "macau": [
        {
            "university_of_saint_joseph_(macau_inter-university_institute)": "University of Saint Joseph (Macau Inter-University Institute)"
        }
    ],
    "myanmar": [
        {
            "university_of_yangon": "University of Yangon"
        },
        {
            "yezin_agricultural_university": "Yezin Agricultural University"
        },
        {
            "yangon_technological_university": "Yangon Technological University"
        },
        {
            "national_management_degree_college": "National Management Degree College"
        },
        {
            "technological_university_mandalay": "Technological University Mandalay"
        },
        {
            "dagon_university_myanmar": "Dagon University Myanmar"
        },
        {
            "university_of_veterinary_science_yezin": "University of Veterinary Science Yezin"
        },
        {
            "university_of_information_technology_yangon": "University of Information Technology Yangon"
        },
        {
            "yangon_university_of_economics": "Yangon University of Economics"
        },
        {
            "mandalay_university_of_medicine": "Mandalay University of Medicine"
        },
        {
            "university_of_dental_medicine_yangon": "University of Dental Medicine Yangon"
        },
        {
            "university_of_medicine_2_yangon": "University of Medicine 2 Yangon"
        },
        {
            "myeik_university": "Myeik University"
        },
        {
            "university_of_computer_studies_mandalay": "University of Computer Studies Mandalay"
        },
        {
            "yangon_university_of_distance_education": "Yangon University of Distance Education"
        },
        {
            "parami_institute.": "Parami Institute."
        },
        {
            "university_of_dental_medicine_mandalay": "University of Dental Medicine Mandalay"
        },
        {
            "myanmar_maritime_university": "Myanmar Maritime University"
        },
        {
            "university_of_nursing_yangon": "University of Nursing Yangon"
        },
        {
            "east_yangon_university": "East Yangon University"
        },
        {
            "computer_university_mandalay": "Computer University Mandalay"
        },
        {
            "yangon_university_of_education": "Yangon University of Education"
        },
        {
            "auston_college_myanmar": "Auston College Myanmar"
        },
        {
            "state_pariyatti_sasana_university": "State Pariyatti Sasana University"
        },
        {
            "university_of_computer_studies_monywa": "University of Computer Studies Monywa"
        },
        {
            "university_of_computer_studies_maubin": "University of Computer Studies Maubin"
        },
        {
            "technological_university_pakokku": "Technological University Pakokku"
        },
        {
            "technological_university_loikaw": "Technological University Loikaw"
        },
        {
            "technological_university_monywa": "Technological University Monywa"
        },
        {
            "technological_university_taunggyi": "Technological University Taunggyi"
        }
    ],
    "republic of ireland": [
        {
            "royal_college_of_physicians_of_ireland": "Royal College of Physicians of Ireland"
        }
    ],
    "egypt": [
        {
            "french_institute_of_eastern_archeology_of_cairo": "French Institute of Eastern Archeology of Cairo"
        }
    ],
    "palestine": [
        {
            "biblical_and_archeological_school_of_jerusalem": "Biblical and Archeological School of Jerusalem"
        }
    ],
    "east timor": [
        {
            "national_university_timor_lorosa'e": "National University Timor Lorosa'e"
        },
        {
            "specifies_of_institute_of_technology": "Specifies of Institute of Technology"
        }
    ],
    "tajikistan": [
        {
            "tajik_national_university": "Tajik National University"
        },
        {
            "russian-tajik_slavonic_university": "Russian-Tajik Slavonic University"
        },
        {
            "tajik_agrarian_university_shirinsho_shotemur": "Tajik Agrarian University Shirinsho Shotemur"
        },
        {
            "khujand_state_university_academician_bobojon_ghafurov": "Khujand State University Academician Bobojon Ghafurov"
        },
        {
            "moscow_stae_university_dushanbe": "Moscow Stae University Dushanbe"
        },
        {
            "tajik_state_university_of_commerce": "Tajik State University of Commerce"
        },
        {
            "institute_of_economy_and_trade_of_the_tsuc_in_khujand_city": "Institute of Economy and Trade of the TSUC in Khujand City"
        },
        {
            "tajik_institute_of_entrepreneurship_and_service": "Tajik Institute of Entrepreneurship and Service"
        },
        {
            "danghara_state_university": "Danghara State University"
        },
        {
            "tajik_institute_of_art_and_culture_mirzo_tursunzade": "Tajik Institute of Art and Culture Mirzo Tursunzade"
        }
    ],
    "azerbaijan": [
        {
            "nakhchivan_state_university": "Nakhchivan State University"
        }
    ],
    "maldives": [
        {
            "maldives_national_university": "Maldives National University"
        },
        {
            "villa_college": "Villa College"
        },
        {
            "cyryx_college": "Cyryx College"
        },
        {
            "mandhu_college": "Mandhu College"
        }
    ],
    "andorra": [
        {
            "university_of_andorra": "University of Andorra"
        },
        {
            "universitat_oberta_de_la_salle_andorra": "Universitat Oberta de la Salle Andorra"
        }
    ],
    "bermuda": [
        {
            "bermuda_college.": "Bermuda College."
        }
    ],
    "tyne": [
        {
            "newcastle_university": "Newcastle University"
        }
    ]
}