import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import Loader from "../../reusable/Loader"
import StudentPortalMobileSidebar from "../../reusable/StudentPortalSidebar";
import StudentPortalTopbar from "../../reusable/StudentPortalTopbar";

import { useGetToken } from "../../hooks/useGetToken";
import useScreens from "../../hooks/useScreens";

import { logout } from "../../../store/slice/StudentSlice";

const Profile = () => {

    // router navigations
    const navigate = useNavigate();
    const location = useLocation();

    const { isMobile } = useScreens();
    const dispatch = useDispatch();

    const { studentToken } = useGetToken();
    const { isLoggedOut, loading } = useSelector((state) => state.student.logout);


    const [isOpen, setIsOpen] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (!studentToken && typeof studentToken !== "undefined") {
            navigate("/student-auth");
        }
        if (
            studentToken &&
            studentToken?.length > 0 &&
            typeof studentToken !== "undefined"
        ) {
            setIsPageLoading(false);
        }
    }, [studentToken]);

    const handleLogout = () => {
        if (studentToken) {
            dispatch(logout(studentToken));
        }
    };

    useEffect(() => {
        if (isLoggedOut) {
            localStorage.removeItem("studenttoken");
            navigate("/student-auth");
        }
    }, [isLoggedOut]);

    return (
        <div className="w-full">

            {isPageLoading ||
                loading ||
                !studentToken ||
                typeof studentToken === "undefined" ? (
                <Loader />
            ) : (
                <>
                    {!isMobile && <StudentPortalTopbar />}
                    {isMobile && (
                        <StudentPortalMobileSidebar isOpen={isOpen} onClose={onClose} />
                    )}
                    <div className="w-full h-full tabanddesk:bg-[#F1F4FA] tabanddesk:p-[24px] min-h-[calc(100vh-64px)]">
                        <div className="bg-white  min-h-[calc(100vh-112px)] rounded-md tabanddesk:p-[24px]">
                            <div className="mobile:flex mobile:items-center mobile:p-[24px]">
                                <img
                                    className="tabanddesk:hidden mr-5"
                                    onClick={onOpen}
                                    src="../menu.svg"
                                    alt="Menu Icon"
                                />
                                <h1 className="font-gola text-2xl font-bold">Profile</h1>
                            </div>

                            <div className="w-full flex justify-center items-center h-full tabanddesk:mt-[70px] mobile:mt-[30px]">
                                <div
                                    className="rounded-md p-[20px] desktop:w-[50%] tablet:w-[80%] mobile:w-full"
                                    style={{ border: !isMobile ? "1px solid #E4E4E4" : 0 }}
                                >
                                    <div className="flex justify-center my-5 relative">
                                        <img width={"125px"} src="/default_profile.svg" alt="" />
                                        <div className="absolute tabanddesk:right-[250px] mobile:right-[100px] bottom-0 flex items-center justify-center w-[40px] h-[40px] rounded-full bg-primary-color">
                                            <img width={20} src="/camera_icon.svg" alt="" />{" "}
                                        </div>
                                    </div>
                                    <div className="border-b-[1px] border-[#E4E4E4] pb-5 flex items-center justify-between">
                                        <div>
                                            <p className="font-gola text-[14px] text-[#8B8B8B] font-normal">
                                                Name
                                            </p>
                                            <p className="font-gola text-base font-bold text-[#2F2F2F] mt-1">
                                                Nikhil Mathew
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className="h-10 ml-5 font-gola border border-[#D5D5D5] text-[#0057E4] bg-[#F5F9FF] font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
                                        >
                                            Edit
                                        </button>
                                    </div>
                                    <div className="flex items-center justify-between border-b-[1px] border-[#E4E4E4] pb-5 pt-5">
                                        <div>
                                            <p className="font-gola text-[14px] text-[#8B8B8B] font-normal">
                                                Email address
                                            </p>
                                            <p className="font-gola text-base font-bold text-[#2F2F2F] mt-1">
                                                nikhilmathew@gmail.com
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <img src="menu_dots.svg" alt="" />
                                            <button
                                                type="button"
                                                className="h-10 ml-5 font-gola border border-[#D5D5D5] text-[#0057E4] bg-[#F5F9FF] font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between border-b-[1px] border-[#E4E4E4] pb-5 pt-5">
                                        <div>
                                            <p className="font-gola text-[14px] text-[#8B8B8B] font-normal">
                                                Phone number
                                            </p>
                                            <p className="font-gola text-base font-bold text-[#2F2F2F] mt-1">
                                                +91 9787665432
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className="h-10 ml-5 font-gola border border-[#D5D5D5] text-[#0057E4] bg-[#F5F9FF] font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
                                        >
                                            Edit
                                        </button>
                                    </div>
                                    <div className="flex items-center justify-between border-b-[1px] border-[#E4E4E4] pb-5 pt-5">
                                        <div>
                                            <span className="font-gola text-base font-bold text-[#2F2F2F]">
                                                Change Passoword
                                            </span>
                                        </div>
                                        <img src="rightIconGray.svg" alt="" />
                                    </div>
                                    <div className="flex items-center justify-between pb-5 pt-5">
                                        <div>
                                            <span
                                                onClick={handleLogout}
                                                className="cursor-pointer font-gola text-base font-bold text-[#0057E4]"
                                            >
                                                Log Out
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Profile;
