import React from "react";

function RenderFiles({ messageData, uploadProgress }) {


  const { type, data_urls, file_name } = messageData;

  const renderLoader = (filename) => {

    if (uploadProgress) {

      if (uploadProgress[filename]) {

        const { processed_bytes, total_size } = uploadProgress[filename];
        const percentage = Math.round((processed_bytes / total_size) * 100);

        return (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${percentage}%` }}
              aria-valuenow={percentage}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {`${percentage}%`}
            </div>
          </div>
        );
      }
    }
    return null;
  };


  // TRUNCATE STRING //
  const truncateString = (str, num) => {

    if (str.length <= num) {

      return str;

    }

    return str.slice(0, num) + "...";

  };

  const handleFileDownload = (dataUrl, filename, fileType) => {

    const supportedFileTypes = ["xlsx","xls","vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                "csv", "pdf", "json", "jpeg", "jpg", "png", "gif"]
    
    if (supportedFileTypes.includes(fileType)) {

      const link = document.createElement("a");
      link.href = `${dataUrl}`;
      link.download = filename || `file.${fileType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }else {

      console.error("Unsupported file type");
      return;

    }

  };


  // ONCLICK IMAGE DOWNLOAD //
  // const handleImageClick = (dataUrl, filename) => {

  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = dataUrl;
  //   downloadLink.download = filename;
  //   downloadLink.click();

  // };

  const getFileIcon = (fileType) => {

    switch (fileType) {

      case "xlsx":
      case "xls":
        return "xls.svg";

      case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xls.svg"

      case "csv":
        return "csv.svg";

      case "pdf":
        return "pdf.svg";

      case "json":
        return "json.svg";

      case "jpeg":
        return "jpeg.svg"
      
      case "jpg":
        return "jpeg.svg"

      case "png":
        return "png.svg"

      case "gif":
        return "gif.png";  

      case "mp3":
        return "audio.svg";  

      case "mp4":
      case "avi":
        return "video.svg";  
      default:
        return "file.svg";  

    }

  };

  return (
    <div className="student_app_chat_files">


      {/* {type && data_urls && ["png", "jpg", "jpeg", "gif"].includes(type) && (
        <div style={{ maxWidth: "100%", maxHeight: "250px", height: "250px", cursor: "pointer" }}>
          <img
            key={file_name}
            src={`${data_urls}`}
            alt={`${file_name}`}
            style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto", cursor: "pointer" }}
            onClick={() =>
              handleImageClick(
                `${data_urls}`,
                file_name
              )
            }
          />
           
        </div>
      )} */}


      {type && data_urls && ["png", "jpg", "jpeg", "gif"].includes(type) && (

        <div className="file-box">
          <div
            className="file-info"
            style={{
              maxWidth: "100%",
              maxHeight: "300px",
              cursor: "pointer",
            }}
            onClick={() => handleFileDownload(data_urls, file_name, type)}
          >
            <div className="file-icon">
              <img src={getFileIcon(type)} alt={`${type}`} />
            </div>
            <div className="file-name">{truncateString(file_name, 20)}</div>
          </div>
        </div>


      )}

      {type && data_urls && type === "mp3" && (
        <audio key={file_name} controls style={{ maxWidth: "100%" }}>
          <source src={`${data_urls}`} type="audio/mp3" />
          Your browser does not support the audio tag.
        </audio>
      )}
      
      {type && data_urls && type === "mp4" && (
        <video
          key={file_name}
          controls
          style={{ maxWidth: "100%", maxHeight: "300px" }}
        >
          <source src={`${data_urls}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {type &&
        data_urls &&
        ["xlsx", "xls", "csv", "pdf", "json"].includes(type) && (
          <div className="file-box">
            <div
              className="file-info"
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                cursor: "pointer",
              }}
              onClick={() => handleFileDownload(data_urls, file_name, type)}
            >
              <div className="file-icon">
                <img src={getFileIcon(type)} alt={`${type}`} />
              </div>
              <div className="file-name">{truncateString(file_name, 20)}</div>
            </div>
          </div>
        )}

      {type && data_urls && type === "avi" && (
        <video
          key={file_name}
          controls
          style={{ maxWidth: "100%", maxHeight: "300px" }}
        >
          <source
            src={`${data_urls}`}
            type="video/avi"
          />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default RenderFiles;
