import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

import { useToast } from "@chakra-ui/react";

import { countryData } from "../../../../utils/countryCode";

import Select from "react-select";

function StudentForm() {

  const [isOpen, setIsOpen] = useState(true);


  const toast = useToast();

  const [formData, setFormData] = useState({

    full_name: "",
    country: "",


  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [showError, setShowError] = useState(false);

  const [allUniverisities, setAllUniversities] = useState([])

  const [loading, setLoading] = useState(false)


  useEffect(() => {
    console.log(formData, 'formdata')
  }, [formData])

  useEffect(() => {

    fetch("https://fmgpy.znapay.in/get/all/universities", {

      method: "GET",

      headers: {

        "Content-Type": "application/json",

      },


    }).then(response => {

      if (!response.ok) {

        throw new Error("Network response was not ok");

      }

      return response.json();
    }).then(data => {

      setAllUniversities(data)

    }).catch(err => {

      return

    })


  }, [])

  const handleSubmit = () => {

     
    if (!formData.country || !formData.full_name) {

       
      setShowError(true);

      return

    }  
    

    const studentId = localStorage.getItem("studentId");

    setLoading(true)



    fetch(`https://fmgpy.znapay.in/add_application/${studentId}`, {

      method: "POST",

      headers: {

        "Content-Type": "application/json",

      },

      body: JSON.stringify(formData),

    })
      .then((response) => {

        if (!response.ok) {

          throw new Error("Network response was not ok");

        }

        return response.json();
      })
      .then((data) => {

        setIsSubmitted(true);
        setIsOpen(false);
        setLoading(false);

        toast({

          title: data?.msg,
          status: "success",
          duration: 3000,
          isClosable: true,

        });


      })
      .catch((err) => {

        return;

      });
  };



  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
      size="sm"
    >
      <ModalOverlay opacity={isSubmitted ? 1 : 0.5} />
      <ModalContent className = "modal_content" maxW={{ base: "90%", sm: "80%", md: "70%", lg: "40%" }}>

        {!isSubmitted && (
          <ModalBody
            className="modal_body"
            maxHeight="fit-content"

            css={{
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "gray",
              },

            }}
          >
            <div className="main_form_content">
              <div className="w-full flex justify-center items-center h-full">
                <div className="rounded-cd justify-center w-full">
                  <div className="rounded-f-lg tablet:mb-5 mobile:mb-5 desktop:mb-5   mobile:pl-6 mobile:pr-6 tablet:pl-pad74 tablet:pr-pad74 bg-custom-color">
                    <p className="text-center w-full font-gola text-1.5xl tablet:pt-5  desktop:pt-5 mobile:pt-5 mobile:mb-8  pb-5 font-bold text-white leading-snug">
                      Name & Country Please !!! 
                    </p>
                  </div>
                  <div className="form_inputs">
                     

                    <div className="flex justify-center flex-col items-start w-ful mb-2 pt-0.8">

                      <input
                        onChange={(e) =>

                          setFormData({

                            ...formData,
                            full_name: e.target.value,

                          })

                        }
                        type="text"
                        id="website-admin"
                        className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 mb-1"
                        placeholder="Full Name"
                      />
                    </div>

                    <div className="flex justify-center flex-col items-start w-full mt-6 pt-0.8">

                       

                      <Select

                        required
                        className="text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm  w-ful mb-1"
                        options={countryData.map((state) => ({
                          value: state.name,
                          label: state.name,
                        }))}

                        onChange={(e) =>

                          setFormData({

                            ...formData,
                            country: e.value,

                          })

                        }

                        isSearchable={true}
                        placeholder="Select Country"
                      />
                    </div>


                    <div className="flex items-center justify-betwee pt-5 mobile:min-w-[200px] mb-3">
                      <button
                        

                        onClick={handleSubmit}
                        type="button"
                        // className={`h-10 mt-3 w-full font-gola text-white ${!formData.country ||
                        //   !formData.full_name
                        //     ? "bg-slate-500 cursor-not-allowed"
                        //     : "bg-primary-color"
                        //   } font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none`}

                        className="h-10 mt-3 w-full font-gola cursor-allowed text-white bg-primary-color font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
                      >
                        {loading ? (
                          <div className="flex justify-center w-full" role="status">
                            <svg
                              aria-hidden="true"
                              className="w-6 h-6 text-gray-200 animate-spin fill-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        ) : (
                          "Submit"
                        )}

                        {showError && (
                          <p className="text-red-500 mt-2">Please fill out all required fields.</p>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        )}

      </ModalContent>
    </Modal>
  );
}

export default StudentForm;





