import React, { useState } from "react";
import "./chat.css";

import { CloseButton } from "@chakra-ui/react";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";

function RenderFileChange({
  files,
  closePreview,
  fileRef,
  setfileData,
  handleSend,
  setMessageInput,
  messageInput,
  handleFileClick,
  handleFileChange,
}) {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const currentFile = files[selectedFileIndex] || {};
  const { dataUrl, filename, filetype } = currentFile;

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "xlsx":
        return "xls.svg";
      case "xls":
        return "xls.svg";
      case "csv":
        return "csv.svg";
      case "pdf":
        return "pdf.svg";
      case "jpeg":
        return "jpeg.svg"
      case "png":
        return "png.svg"
      case "json":
        return "json.svg";
      case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xls.svg"
      default:
        return "folder.svg";
    }
  };

  const handleStateChanges = () => {
    closePreview(true);
    setfileData([]);
  };

  return (
    <>
      <div className="file-preview">
        <div className="container">
          <div className="close-button">
            <CloseButton onClick={handleStateChanges} />
          </div>
          {files.length > 0 && (
            <>
              <div className="header">
                <div className="title">{truncateString(filename, 20)}</div>
              </div>
              <div className="content">
                <img
                  src={getFileIcon(filetype.split("/")[1])}
                  alt={`${filetype.split("/")[1]}`}
                />

                <div className="caption">
                  <h1>No Preview Available</h1>
                </div>
                <div className="caption">
                  {truncateString(filetype.split("/")[1], 20)}
                </div>
              </div>
              <div className={`render_caption_input ${isFocused ? 'caption_focused' : ''}`}>
               
                <input
                  type="text"
                  placeholder="Add a caption"
                  value={messageInput}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onChange={(e) => setMessageInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSend();
                    }
                  }}
                />
              </div>
            </>
          )}
          <div className="footer">
            <div className="file_icon"> 
            <button className="add_icon">
              <AddIcon sx={{ color: "#25d366" }} onClick={handleFileClick} />
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                ref={fileRef}
                onChange={handleFileChange}
              />
            </button>
            {files.length > 1 &&
              files.map((file, index) => (
                <button
                  key={index}
                  className="file_button add_icon"
                  onClick={() => setSelectedFileIndex(index)}
                >
                  <img
                    src={getFileIcon(file.filetype.split("/")[1])}
                    alt={`${file.filetype.split("/")[1]}`}
                  />
                  
                </button>
              ))}
              </div>
            <button className="send_icon">
              <SendIcon sx={{ color: "white" }} onClick={handleSend} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default RenderFileChange;
