/* eslint-disable eqeqeq */

import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import io from "socket.io-client";
import "./chat.css";
import StudentForm from "../student/student-on-boarding/studentform/StudentForm";

import { v4 as uuidv4 } from "uuid";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Notifier from "../bank-notifier/BankNotifier";
import { ArrowBackIcon } from "@chakra-ui/icons";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import WelcomePage from "./WelcomePage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RenderFileChange from "./RenderFileChange";
import debounce from 'lodash/debounce';
import { useToast } from "@chakra-ui/react";
import ChatUserList from "./ChatUserList";
import ChatMessageBox from "./ChatMessageBox";

import { addItem, getItems, deleteAllMessages, deleteMessagesById } from "../db/db";

//Get unread message Count


const getUnreadMessageCount = async (setUnreadMessageCount) => {

  const URL = "https://fmgpy.znapay.in/unread/message/count";

  const token = localStorage.getItem("token");

  const response = await axios.get(URL, {

    headers: {

      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",

    },

  });

  setUnreadMessageCount(response.data);
};

// Debounced version of the function
const debouncedFetchUnreadMessageCount = debounce(getUnreadMessageCount, 2000);






const Chat = () => {

  const fileInputRef = useRef(null);

  const [isClickable, setisClickable] = useState(false);
  const [users, setUsers] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [activeUserName, setActiveUserName] = useState(null);

  const [socketMessages, setSocketMessages] = useState([]);
  const [userMesssages, setUserMessages] = useState([]);
  const [searchedMessageId, setSearchedMessageId] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [unreadMessageCount, setUnreadMessageCount] = useState([]);

  const [isEmoticon, setisEmoticon] = useState(false);


  const [fileInfo, setFilesInfo] = useState([]);
  const [isSessionValid, setIsSessionValid] = useState(false);
  const [closePreview, setClosePreview] = useState(false);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [fileData, setfileData] = useState([]);
  const [filteredMessage, setFilteredMessage] = useState([]);
  const [user_id, setId] = useState(null);
  const [isApplication, setApplication] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [notifyAlert, setnotifyAlert] = useState(false);
  const [userRole, setUserRole] = useState("");
  const lastMessageIdRef = useRef(null);
  const activeUserRef = useRef(null);
  const [roomUUID, setroomUUID] = useState("");
  const [userLogo, setUserLogo] = useState("");
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [loggedOut, setIsloggedOut] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);

  const socket = useRef(null);
  const reconnectTimeoutRef = useRef(null);

  const [isSocketConnected, setIsSocketConnected] = useState(false);

  const navigate = useNavigate();

  const toast = useToast();


  useEffect(() => {
    let socketConnection;

    const reconnectionTimeref = reconnectTimeoutRef.current;

    const initializeSocket = () => {
      if (reconnectionTimeref) {
        clearTimeout(reconnectionTimeref);
      }

      socketConnection = io("https://fmgpy.znapay.in/", {

        reconnection: false,
        query: { user_id: user_id }

      });

      socketConnection.on("connect", () => {

        console.log("Connection Established ...")

        setIsSocketConnected(true);

      });

      socketConnection.on("disconnect", (reason) => {

        setIsSocketConnected(false);
        socketReconnectionHandler();

        console.log(`Disconnected: ${reason}`);

      });

      socketConnection.on("connect_error", (error) => {

        setIsSocketConnected(false);
        socketReconnectionHandler();

        console.error("Connection error:", error);

      });

      // Handle reconnection attempts
      socketConnection.on("reconnect_attempt", (attemptNumber) => {

        console.log(`Reconnection attempt #${attemptNumber}`);

      });

      // Handle successful reconnection
      socketConnection.on("reconnect", (attemptNumber) => {

        console.log(`Successfully reconnected after attempt #${attemptNumber}`);

      });

      // Handle reconnection errors
      socketConnection.on("reconnect_error", (error) => {

        setIsSocketConnected(false);
        socketReconnectionHandler();
        console.error("Reconnection error:", error);

      });

      // Save the socket instance in ref
      socket.current = socketConnection;

    };

    // initalizing socket connection
    initializeSocket();

    return () => {

      if (reconnectionTimeref) {

        clearTimeout(reconnectionTimeref);

      }

      socketConnection.off("connect");
      socketConnection.off("reconnect_attempt");
      socketConnection.off("reconnect");
      socketConnection.off("reconnect_error");
      socketConnection.off("disconnect");
      socketConnection.off("connect_error");

    };

  }, [isSessionValid]);

  // handle socket reconnection mechaninsm

  const socketReconnectionHandler = () => {

    const RECONNECT_DELAY = 6000;

    console.log(
      `Attempting to reconnect in ${RECONNECT_DELAY / 1000} seconds...`
    );

    // Set up reconnection attempt with delay
    reconnectTimeoutRef.current = setTimeout(() => {

      if (socket) {

        socket.current.connect();

      }

    }, RECONNECT_DELAY);
  };






  // get unread message count on load

  useEffect(() => {

    // Call the debounced function on component mount
    user_id && debouncedFetchUnreadMessageCount(setUnreadMessageCount);

    // Optionally clean up debounced function on unmount

    return () => {

      debouncedFetchUnreadMessageCount.cancel();

    };

  }, [user_id]);

  // check user session is valid or not

  const fetchUserSessions = async () => {

    console.log("sessions ...")

    try {

      const STORAGE_KEYS = [
        "token",
        "id",
        "phone",
        "studentId",
        "bankerId",
        "email",
      ];

      const token = localStorage.getItem("token");

      const Userid = user_id;

      const URL = `https://fmgpy.znapay.in/auth/user/session/${Userid}`;

      const response = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",

        },

      });

      const results = response.data;

      if (results.success === false) {

        STORAGE_KEYS.forEach((key) => {

          localStorage.removeItem(key);

        });

        const role = localStorage.getItem("role");

        if (role === "banker") {

          navigate("/banker-auth");

        }

        if (role === "user") {

          navigate("/student-auth");

        }

      }

      if (results.success === true) {

        setIsSessionValid(true);

      }

    } catch (error) {

      console.log(error, "Verification error");

    }

  };

  useEffect(() => {

    const token = localStorage.getItem("token");


    if (user_id && token) {

      isSessionValid === false && fetchUserSessions();

    }

  }, [user_id]);

  //formatting date
  const messageDataFormatter = (data) => {
    const categorizedMessages = {};

    if (data) {
      data.forEach((message) => {
        const dateObj = new Date(message.created_at);

        const formattedDate = dateObj.toLocaleDateString("en-IN", {
          timeZone: "Asia/Kolkata",
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

        // If the category for this date doesn't exist yet, create it
        if (!categorizedMessages[formattedDate]) {
          categorizedMessages[formattedDate] = [];
        }

        const gmtTimestamp = new Date(message.created_at);

        // Convert GMT to IST (UTC+5:30)

        gmtTimestamp.setHours(gmtTimestamp.getHours() + 5);
        gmtTimestamp.setMinutes(gmtTimestamp.getMinutes() + 30);

        // Format the date in dd/mm/yy HH:MM:SS format
        const istDate = gmtTimestamp.toLocaleString("en-IN", {
          hour: "2-digit",
          minute: "2-digit",
        });

        const formattedTime = istDate;

        message.received_time = `${formattedTime}`;

        categorizedMessages[formattedDate].push(message);
      });
    }

    return categorizedMessages;
  };

  useEffect(() => { }, [closePreview]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (!token) {
      // window.location.reload();

      if (role === "user") {
        navigate("/student-auth");
      }

      if (role === "banker") {
        navigate("/banker-auth");
      }
    }
  }, [loggedOut]);

  // socket LISTEN TO RETRIVE MESSAGES //

  const handleMessagesResponse = (data) => {

    if (data.messages && data.messages.length > 0) {

      // Extract new messages from the response
      const newMessages = data.messages;

      // Filter out duplicates based on message_id
      const uniqueNewMessages = newMessages.filter(
        (newMsg) =>
          !socketMessages.some((msg) => msg.message_id === newMsg.message_id)
      );

      // Append new messages to the existing messages
      setSocketMessages((prevMessages) => {

        // Combine previous messages with new unique messages
        const combinedMessages = [...prevMessages, ...uniqueNewMessages];

        // Sort messages by created_at to ensure correct order
        const sortedMessages = combinedMessages.sort(

          (a, b) => new Date(a.created_at) - new Date(b.created_at)

        );

        return sortedMessages;

      });

      // Update lastMessageIdRef to the latest message ID
      // Assuming messages are sorted in descending order and the first message is the latest
      const newLastMessageId = newMessages[0].id;
      lastMessageIdRef.current = newLastMessageId;

    }

    setIsLoadingMessages(false);
  };

  //socket EMIT TO FETCH USER MESSAGES //

  const fetchUserMessage = async () => {
    try {

      setIsLoadingMessages(true);

      const response = await axios.post(

        "https://fmgpy.znapay.in/get_user_messages",

        {

          sender_id: user_id,
          receiver_id: activeUser,
          last_message_id: lastMessageIdRef.current,
          per_page: 25,

        }

      );

      handleMessagesResponse(response.data);

    } catch (err) {

      toast({
        title: "Error Fetching Messages !",
        status: "error",
        duration: 2000,
        isClosable: true,
      });

    }

  };

  // RUN ONCE TO FETCH MESSAGE'S //

  useEffect(() => {

    activeUser && fetchUserMessage();

  }, [activeUser]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSearchedMessage = async (messageId) => {

    try {

      const response = await axios.post(

        "https://fmgpy.znapay.in/get_message_by_id",
        { sender_id: user_id, receiver_id: activeUser, message_id: messageId }

      );

      handleMessagesResponse(response.data);

    } catch (err) {

      console.log("Error Fetching Messages Based On Search...", err);

    }

  };

  useEffect(() => {

    searchedMessageId && fetchSearchedMessage(searchedMessageId);



  }, [searchedMessageId]);

  //handle message read

  const handleUserApiRequest = async (receiver_id, sender_id, callback) => {

    try {

      const response = await axios.post(

        "https://fmgpy.znapay.in/api/unread_messages_count",
        {
          receiver_id: receiver_id,
          sender_id: sender_id
        }

      );

      const results = response.data;


      if (results.success === true) {

        callback(response.data.unread_counts)

      }
    } catch (error) {

      console.error("Failed to send API request:", error);

    }

  };


  // FILE  SELECTION HANDLER //
  const handleFileClick = async () => {

    setClosePreview(false);

    fileInputRef.current.click();

  };

  // ON FILE CHANGE //
  const handleFileChange = async (e) => {

    const newFiles = Array.from(fileInputRef.current.files);

    setFilesInfo((prevFiles) => [...prevFiles, ...newFiles]);

    const filePromises = newFiles.map((file) => {

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve({
            filename: file.name,
            dataUrl: reader.result.split(",")[1],
            filetype: file.type,
          });
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    const fileInfoData = await Promise.all(filePromises);

    setfileData((prevFileData) => [...prevFileData, ...fileInfoData]);

    setUploadProgress(Array.from(e.target.files).map(() => 0));
  };

  //render component on change of showmessagebox //
  useEffect(() => { }, [showMessageBox]);

  const verifyUser = async () => {
    const studentId = localStorage.getItem("studentId");

    const URL = `https://fmgpy.znapay.in/get_application/${studentId}`;

    const headers = {
      "Content-Type": "application/json",
    };

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(URL, requestOptions);

      if (response.ok) {
        const data = await response.json();

        if (data?.success === true) {
          setApplication(true);
        }

        if (data?.success === false) {
          setApplication(false);
        }
      }
    } catch {
      return;
    }
  };

  // VERIFY STUDENT //
  useEffect(() => {
    const id = localStorage.getItem("studentId");

    if (id) {

      isSessionValid && verifyUser();

    }

  }, [isSessionValid]);

  // SETTING USER ROLES AND ID //

  useEffect(() => {

    const role = localStorage.getItem("role");

    setUserRole(role);

    if (role === "banker") {

      const id = localStorage.getItem("bankerId");

      id && setId(parseInt(id));

    }

    if (role === "user") {

      const id = localStorage.getItem("studentId");

      id && setId(parseInt(id));

    }
  }, []);



  // RESIZING WINDOWS //
  useEffect(() => {

    const handleResize = () => {

      setWindowWidth(window.innerWidth);

    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post("https://fmgpy.znapay.in/get_all_users", {
        token: token,
      });

      setUsers(response?.data?.users_list);
    } catch (error) {
      toast({

        title: "Error Fetching Users",
        status: "error",
        duration: 2000,
        isClosable: true,

      });
    }
  };

  useEffect(() => {

    isSessionValid && fetchUsers();

  }, [isSessionValid]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const handleMessage = (message) => {

  //   console.log(message,'message456')

  //   if (message.receiver_id === user_id || message.sender_id === user_id) {

  //     setShouldScrollToBottom(true);

  //     debouncedFetchUnreadMessageCount(setUnreadMessageCount);

  //     setSocketMessages((prevMessages) => {

  //       if (
  //         prevMessages.some((msg) => msg.message_id === message.message_id)
  //       ) {
  //         return prevMessages;
  //       }

  //       const updatedMessages = [...prevMessages, message];

  //       return updatedMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  //     });

  //     setUserMessages((prevMessages) => {
  //       if (
  //         prevMessages.some((msg) => msg.message_id === message.message_id)
  //       ) {
  //         return prevMessages;
  //       }

  //       const updatedMessages = [...prevMessages, message];

  //       return updatedMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  //     });


  //   }
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMessage = (message) => {
    console.log(message, 'message456');

    if (message.receiver_id === user_id || message.sender_id === user_id) {
      setShouldScrollToBottom(true);
      debouncedFetchUnreadMessageCount(setUnreadMessageCount);

      setSocketMessages((prevMessages) => {
        // Check if a message with the same ID already exists
        const messageIndex = prevMessages.findIndex((msg) => msg.message_id === message.message_id);

        if (messageIndex !== -1) {
          // Replace the existing message
          return [
            ...prevMessages.slice(0, messageIndex),
            message,
            ...prevMessages.slice(messageIndex + 1)
          ].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        }

        // Add the new message if it doesn't exist
        return [...prevMessages, message].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      });

      setUserMessages((prevMessages) => {
        // Check if a message with the same ID already exists
        const messageIndex = prevMessages.findIndex((msg) => msg.message_id === message.message_id);

        if (messageIndex !== -1) {
          // Replace the existing message
          return [
            ...prevMessages.slice(0, messageIndex),
            message,
            ...prevMessages.slice(messageIndex + 1)
          ].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        }

        // Add the new message if it doesn't exist
        return [...prevMessages, message].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      });
    }
  };



  useEffect(() => {

    if (!isSocketConnected) return;

    socket.current.on("message", handleMessage);

    return () => {

      socket.current.off("message", handleMessage);

    };

  }, [handleMessage, isSocketConnected]);

  useEffect(() => {


    if (socketMessages.length > 0) {


      let filteredSocketMessages = socketMessages.filter(
        (message) =>
          (message.sender_id == user_id && message.receiver_id == activeUser) ||
          (message.sender_id == activeUser && message.receiver_id == user_id)
      );

      // Create a set to track seen message_ids
      let seenMessageIds = new Set();

      // Filter again to remove messages with duplicate message_id
      filteredSocketMessages = filteredSocketMessages.filter((message) => {
        if (seenMessageIds.has(message.message_id)) {
          return false;
        } else {
          seenMessageIds.add(message.message_id);
          return true;
        }
      });

      const messageData = messageDataFormatter(filteredSocketMessages);

      // Update filteredMessage state
      setFilteredMessage(messageData);

    }

  }, [socketMessages]);

  const fileToUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result); // Read file as URL
      reader.onerror = (error) => reject(error);
    });
  };

  // CONVERT ALL SELECTED FILES TO BASE64 URL

  const convertFilesToBase64 = async (files) => {
    const base64Promises = files.map(async (file) => {
      const base64Data = await fileToUrl(file);

      return {
        file_name: file.name,
        size: file.size,
        type: file.type.split("/")[1],
        data_urls: base64Data,
      };
    });

    return Promise.all(base64Promises);
  };

  // handling back button
  const handleBack = () => {
    setShowMessageBox(false);
  };

  // FORMATTED TIME //

  const formattedMessageTime = () => {
    let currentDate = new Date();

    // Extract date components
    let year = currentDate.getUTCFullYear();
    let month = String(currentDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    let day = String(currentDate.getUTCDate()).padStart(2, "0");
    let hours = String(currentDate.getUTCHours()).padStart(2, "0");
    let minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
    let seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");

    // Construct YYYY-MM-DD HH:mm:ss format
    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  };

  // create secret key

  const generateSecretKey = () => {

    return uuidv4();

  };

  // get secret key

  const getSecretKey = async () => {

    const response = await axios.get(

      `https://fmgpy.znapay.in/get_secret_key/${user_id}`

    );

    return response.data;
  };

  const addSecretKey = async (secret_key) => {

    try {



      const response = await axios.post(

        `https://fmgpy.znapay.in/add_secret_key`,
        {
          secret_key: secret_key,
          user_id: user_id,
        }
      );

      if (response) {
        return response.data;
      }
    } catch (err) {
      console.log("Error Occured While Adding A Secret Key !", err);
    }
  };

  const addItemToDb = async (message) => {

    // Check if user has a secret_key
    let secretKey;

    const response = await getSecretKey();

    secretKey = response?.secret_key;

    if (!secretKey) {

      const key = await generateSecretKey();

      const response = await addSecretKey(key);

    }

    message && (await addItem(message, secretKey));
  };

  const getDbMessages = async (secretKey) => {

    return await getItems(secretKey);

  };



  // SEND UNSEND MESSAGES //

  useEffect(() => {

    const fetchData = async () => {

      try {

        let response;

        // Fetch the secret key if user_id is present
        if (user_id) {

          response = await getSecretKey(); // Await the promise

        }

        const secret_key = response?.secret_key;

        if (isSocketConnected && secret_key) {

          const messages = await getDbMessages(secret_key); // Await the promise



          if (messages && isSocketConnected) {

            // Emit the unsent messages to the socket
            socket.current.emit("unsent_messages", {

              unsent_messages: messages,

            });

            await deleteAllMessages()

          }
        }
      } catch (error) {
        console.error("Error fetching data or emitting socket event:", error);
      }
    };

    // Call the async function
    fetchData();

  }, [isSocketConnected]);

  useEffect(() => {
    try {

      if (isClickable) {

        const senderId = user_id;
        const receiverId = activeUser;
        let clickable = isClickable;
        const text = "Please fill required details";
        const room_uuid = roomUUID;

        sendTextMessage(room_uuid, senderId, receiverId, text, clickable);

      }

    } catch (err) {

      console.log("Error Sending Message !!!");

    } finally {

      setisClickable(false);

    }

  }, [isClickable]);

  //SEND TEXT MESSAGES //

  const sendTextMessage = (
    room_uuid,
    senderId,
    receiverId,
    text,
    clickable
  ) => {
    try {
      // GENERATE A UNIQUE ID //

      const generateUniqueId = () => {

        return uuidv4();

      };

      // MESSAGES STRUCTURE //

      const messagesToSend = {

        sender_id: senderId,
        receiver_id: receiverId,
        room_uuid: room_uuid,
        clickable: clickable,
        text: text,
        created_at: formattedMessageTime(),
        file: null,
        message_id: generateUniqueId(),

      };

      if (isSocketConnected) {

        setSocketMessages((prevMessages) => [...prevMessages, messagesToSend]);
        setUserMessages((prevMessages) => [...prevMessages, messagesToSend]);

        socket.current.emit("send_message", messagesToSend);

      } else {

        console.log("message Unsent Due to Socket Disconnection !");

        setSocketMessages((prevMessages) => [...prevMessages, messagesToSend]);
        setUserMessages((prevMessages) => [...prevMessages, messagesToSend]);

        addItemToDb(messagesToSend);
      }

      setMessageInput("");
    } catch (err) {
      console.log("Error Sending A Message ...");
    }
  };

  //SEND FILES MESSAGE

  const sendFileMessage = async (senderId, receiverId, files) => {
    try {
      let fileInfoData = await convertFilesToBase64(files);

      const generateUniqueId = () => {
        return uuidv4();
      };

      const messagesToSend = fileInfoData.map((file) => ({
        sender_id: senderId,
        receiver_id: receiverId,
        text: null,
        created_at: formattedMessageTime(),
        file: file,
        message_id: generateUniqueId(),
      }));

      setSocketMessages((prevMessages) => [...prevMessages, ...messagesToSend]);
      setUserMessages((prevMessages) => [...prevMessages, ...messagesToSend]);

      const formData = new FormData();

      formData.append("sender_id", senderId);
      formData.append("receiver_id", receiverId);
      formData.append("room_uuid", roomUUID);

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
        formData.append("message_ids[]", messagesToSend[i].message_id);
      }

      await axios.post("https://fmgpy.znapay.in/upload_files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log("An Error Uploading File ...", err);
    } finally {
      setfileData([]);
      setFilesInfo([]);
    }
  };

  //HANDLE MESSAGE SEND
  const handleSend = async () => {
    try {
      const senderId = user_id;
      const receiverId = activeUser;
      let clickable = isClickable;
      const text = messageInput;
      const files = fileInfo;
      const room_uuid = roomUUID;

      // DO NOT SEND MESSAGE OR FILES IF EITHER IS EMPTY
      if (!text && text.length === 0 && files?.length === 0) {
        return;
      }

      // SEND TEXT MESSAGE
      if (text) {
        sendTextMessage(room_uuid, senderId, receiverId, text, clickable);
      }

      //SEND FILES AS MESSAGE

      if (files.length > 0) {
        sendFileMessage(senderId, receiverId, files);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setClosePreview(true);
      setShouldScrollToBottom(true);
      setisEmoticon(false);
    }
  };

  // CREATE CHAT ROOM //
  const createChatRoom = async () => {
    // API To create_room
    const response = await axios.post("https://fmgpy.znapay.in/create_room", {
      uniqueChatUser1: user_id,
      uniqueChatUser2: activeUserRef.current,
    });

    if (response.data) {
      const room_uuid = response?.data?.roomUUID;

      setroomUUID(room_uuid);
    }
  };

  useEffect(() => {

    // Join the room when roomUUID changes
    if (roomUUID && user_id && isSocketConnected) {

      socket.current.emit("join_room", { user: user_id, room_uuid: roomUUID });

    }

  }, [roomUUID, user_id, isSocketConnected]);

  useEffect(() => {
    if (roomUUID) {

      socket.current.on("room_joined", (data) => {

        console.log(data, "joined");

      });

      return () => {
        // Clean up socket event listener when roomUUID changes
        socket.current.off("room_joined");
      };
    }
  }, [roomUUID]);

  return (
    <div className="student_app_chat_notifier ">
      <div className="student_app_chat_container">
        {windowWidth > 786 ? (
          <>
            <ChatUserList
              unreadMessageCount={unreadMessageCount}
              userMesssages={userMesssages}
              socket={socket}
              users={users}
              currentUser={user_id}
              log={setIsloggedOut}
              activeUser={activeUser}
              handleUserClick={(
                userId,
                username,
                userLogo,
                searchedMessageId
              ) => {
                setShouldScrollToBottom(true);
                setActiveUser(userId);
                activeUserRef.current = userId;
                setActiveUserName(username);
                setUserLogo(userLogo);
                lastMessageIdRef.current = null;
                setSearchedMessageId(searchedMessageId);
                createChatRoom();

                socket.current.emit("initiate_conversation", {
                  sender_id: user_id,
                  receiver_id: userId,
                });
              }}
              handleUserApiRequest={handleUserApiRequest}
              setUnreadMessageCount={setUnreadMessageCount}
              user_id={user_id}
              setActiveUser={setActiveUser}
            />
            {!activeUser ? (
              <>
                <WelcomePage />
              </>
            ) : (
              <>
                <ChatMessageBox
                  setisClickable={setisClickable}
                  loggedOut={loggedOut}
                  userMesssages={userMesssages}
                  isLoadingMessages={isLoadingMessages}
                  handleUserApiRequest={handleUserApiRequest}
                  setUnreadMessageCount={setUnreadMessageCount}
                  setShouldScrollToBottom={setShouldScrollToBottom}
                  shouldScrollToBottom={shouldScrollToBottom}
                  uploadProgress={uploadProgress}
                  fetchUserMessage={fetchUserMessage}
                  userLogo={userLogo}
                  activeUser={activeUser}
                  setnotifyAlert={setnotifyAlert}
                  role={userRole}
                  activeUserName={activeUserName}
                  messageInput={messageInput}
                  notificationIcon={<NotificationsIcon />}
                  renderFileChange={
                    fileData.length > 0 &&
                    closePreview === false && (
                      <RenderFileChange
                        files={fileData}
                        closePreview={setClosePreview}
                        fileRef={fileInputRef}
                        setfileData={setfileData}
                        handleSend={handleSend}
                        setMessageInput={setMessageInput}
                        messageInput={messageInput}
                        handleFileClick={handleFileClick}
                        handleFileChange={handleFileChange}
                        isEmoticon={isEmoticon}
                      />
                    )
                  }
                  setMessageInput={setMessageInput}
                  fileRef={fileInputRef}
                  handleSend={handleSend}
                  input={
                    <>
                      <AttachFileIcon onClick={handleFileClick} />
                      <input
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </>
                  }
                  filteredMessage={filteredMessage}
                  user_id={user_id}
                  searchedMessageId={searchedMessageId}
                  isEmoticon={isEmoticon}
                  setisEmoticon={setisEmoticon}
                />
              </>
            )}

            {isApplication === false && userRole == "user" && <StudentForm />}
          </>
        ) : (
          <>
            {!showMessageBox ? (
              <ChatUserList
                unreadMessageCount={unreadMessageCount}
                userMesssages={userMesssages}
                socket={socket}
                users={users}
                currentUser={user_id}
                log={setIsloggedOut}
                handleUserClick={(
                  userId,
                  username,
                  userLogo,
                  searchedMessageId
                ) => {
                  setActiveUser(userId);
                  activeUserRef.current = userId;
                  setActiveUserName(username);
                  lastMessageIdRef.current = null;
                  setShowMessageBox(true);
                  setUserLogo(userLogo);
                  setSearchedMessageId(searchedMessageId);
                  createChatRoom();
                  setShouldScrollToBottom(true);

                  socket.current.emit("initiate_conversation", {
                    sender_id: user_id,
                    receiver_id: userId,
                  });
                }}
                handleUserApiRequest={handleUserApiRequest}
                setUnreadMessageCount={setUnreadMessageCount}
                user_id={user_id}
                setActiveUser={setActiveUser}
              />
            ) : (
              <ChatMessageBox
                setisClickable={setisClickable}
                loggedOut={loggedOut}
                userMesssages={userMesssages}
                isLoadingMessages={isLoadingMessages}
                handleUserApiRequest={handleUserApiRequest}
                setUnreadMessageCount={setUnreadMessageCount}
                setShouldScrollToBottom={setShouldScrollToBottom}
                shouldScrollToBottom={shouldScrollToBottom}
                uploadProgress={uploadProgress}
                fetchUserMessage={fetchUserMessage}
                userLogo={userLogo}
                backIcon={<ArrowBackIcon onClick={handleBack} />}
                activeUser={activeUser}
                setnotifyAlert={setnotifyAlert}
                role={userRole}
                activeUserName={activeUserName}
                messageInput={messageInput}
                notificationIcon={<NotificationsIcon />}
                renderFileChange={
                  fileData.length > 0 &&
                  closePreview === false && (
                    <RenderFileChange
                      files={fileData}
                      closePreview={setClosePreview}
                      fileRef={fileInputRef}
                      setfileData={setfileData}
                      handleSend={handleSend}
                      handleFileUpload={handleSend}
                      setMessageInput={setMessageInput}
                      messageInput={messageInput}
                      handleFileClick={handleFileClick}
                      handleFileChange={handleFileChange}
                    />
                  )
                }
                setMessageInput={setMessageInput}
                handleSend={handleSend}
                input={
                  <>
                    <AttachFileIcon onClick={handleFileClick} />
                    <input
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </>
                }
                filteredMessage={filteredMessage}
                user_id={user_id}
                searchedMessageId={searchedMessageId}
                isEmoticon={isEmoticon}
                setisEmoticon={setisEmoticon}
              />
            )}
            {isApplication === false && userRole == "user" && <StudentForm />}
          </>
        )}
      </div>
      <div className="student_app_notifier">
        {notifyAlert === true && (
          <Notifier
            bankerId={user_id}
            studentId={activeUser}
            studentName={activeUserName}
            notifyAlert={setnotifyAlert}
          />
        )}
      </div>
    </div>
  );
};

export default Chat;
