import axios, { isAxiosError } from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// user initial states
const initialState = {
  updateUserProfile: {
    loading: false,
    isProfileUpdated: false,
    accessToken: "",
    error: null,
  },

  GetUserProfile: {
    loading: false,
    isUserVerified: false,
    userProfile: null,
  },

  verifyUserEmail: {
    loading: false,
    isEmailVerified: false,
  },
};

export const GetUserProfile = createAsyncThunk(
  "GetUserProfile",
  async ({ token }, thunkApi) => {
    try {
      const response = await axios.get(
        `https://fmgpy.znapay.in/users/me`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

//verify email

export const verifyUserEmail = createAsyncThunk(
  "verifyUserEmail",
  async ({ tk }, thunkApi) => {
    try {
      const verificationToken = "9c63c117515cb3e189afa80d4d32eb50ebcff24488595fb0111a5cc30527b777";
      const data = { token: verificationToken };

      const response = await axios.post(
        `https://fmgpy.znapay.in/users/me/verify-email`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: tk,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

//updating user profile thunk
export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async ({ first_name, last_name, email, password, id }, thunkApi) => {
    const data = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
    };

    try {
      const response = await axios.put(
        `https://fmgpy.znapay.in/update/users/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

       localStorage.setItem("token",response.data.access_token)

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,

  reducers: {
    resetUpdateUserProfileStatus(state) {
      state.updateUserProfile.isProfileUpdated = false;
    },
  },

  extraReducers(builder) {
    builder.addCase(updateUserProfile.pending, (state) => {
      state.updateUserProfile.loading = true;
    });

    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.updateUserProfile.loading = false;
      state.updateUserProfile.isProfileUpdated = true;
      state.updateUserProfile.accessToken = action.payload.access_token
      console.log(action.payload.accessToken)
    });

    builder.addCase(updateUserProfile.rejected, (state) => {
      state.updateUserProfile.loading = false;
    });

    builder.addCase(GetUserProfile.pending, (state) => {
      state.GetUserProfile.loading = true;
    });
    builder.addCase(GetUserProfile.fulfilled, (state, action) => {
      state.GetUserProfile.loading = false;
      state.GetUserProfile.isUserVerified = true;
      state.GetUserProfile.userProfile = action.payload;
    });
    builder.addCase(GetUserProfile.rejected, (state, action) => {
      state.GetUserProfile.loading = false;
    });

    builder.addCase(verifyUserEmail.pending, (state) => {
      state.verifyUserEmail.loading = true;
    });
    builder.addCase(verifyUserEmail.fulfilled, (state, action) => {
      state.verifyUserEmail.loading = false;
      state.verifyUserEmail.isEmailVerified = true;
    });
    builder.addCase(verifyUserEmail.rejected, (state, action) => {
      state.verifyUserEmail.loading = false;
    });
  },
});

// exporting profile actions for components
export const { resetUpdateUserProfileStatus } = ProfileSlice.actions;

export default ProfileSlice.reducer;
