import React, { useEffect, useRef, useState, useCallback } from "react";

import { Avatar } from "@chakra-ui/react";
import SendIcon from "@mui/icons-material/Send";
import EmoticonPicker from "../emoji/EmoticonPicker";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import RenderFiles from "./RenderFiles";
import axios from "axios";
import debounce from 'lodash/debounce';
import "./chat.css";
import parse from "html-react-parser";
import StudentAdditionalForm from '../student/student-on-boarding/studentform/StudentAdditionalForm'
import Linker from "./Linker";

const ChatMessageBox = ({
  setisClickable,
  loggedOut,
  userMesssages,
  isLoadingMessages,
  handleUserApiRequest,
  setUnreadMessageCount,
  setShouldScrollToBottom,
  shouldScrollToBottom,
  uploadProgress,
  fetchUserMessage,
  userLogo,
  backIcon,
  activeUser,
  setnotifyAlert,
  role,
  activeUserName,
  messageInput,
  notificationIcon,
  renderFileChange,
  setMessageInput,
  fileRef,
  handleSend,
  input,
  filteredMessage,
  user_id,
  searchedMessageId,
  isEmoticon,
  setisEmoticon,
}) => {
  const handleNotificationIconClick = () => {
    setnotifyAlert(true);
  };

  const chatContainerRef = useRef(null);
  const messagesLoadedRef = useRef(false); // Ref to track if messages have been loaded

  const [isOnline, setisOnline] = useState(false);
  const [lastSeen, setlastSeen] = useState("");
  const [ispopupOpen, setisPopupOpen] = useState(false)
  const [messageId, setMessageId] = useState("")

  const inputRef = useRef(null);

  const [scrollPosition, setScrollPosition] = useState(0);

  const getMessageContent = (message) => {
    const messageText = message.text ? parse(message.text) : "";

    return messageText;
  };





  useEffect(() => {
    if (activeUser) {
      inputRef.current.focus();
    }
  }, [activeUser]);

  const handlePopupTrigger = () => {
    setisClickable(true);
  };

  const handleEmoticon = () => {
    setisEmoticon((prev) => !prev);
  };

  const handleClick = (msg) => {


    if (msg.clickable) {

      setisPopupOpen(true);
      setMessageId(msg.message_id)

    }


  };

  // Use layout effect to handle message scrolling and styling when searched

  useEffect(() => {

    const scrollToSearchedMessage = () => {
      if (
        searchedMessageId &&
        chatContainerRef.current &&
        messagesLoadedRef.current
      ) {
        const messageElement = chatContainerRef.current.querySelector(
          `#message-${searchedMessageId}`
        );

        if (messageElement) {
          messageElement.scrollIntoView({ behavior: "smooth", block: "start" });
          messageElement.classList.add("highlighted-message");

          setTimeout(() => {
            messageElement.classList.remove("highlighted-message");
          }, 500);
        } else {
          // If messageElement is not found, try scrolling again after a short delay
          setTimeout(scrollToSearchedMessage, 100);
        }
      }
    };

    // Call the function to scroll to message
    scrollToSearchedMessage();

  }, [searchedMessageId, messagesLoadedRef.current]);

  // unread messages on active user

  useEffect(() => {

    handleUserApiRequest(activeUser, user_id, setUnreadMessageCount);

  }, [filteredMessage]);

  //SET FOCUS ON INPUT

  // useEffect(() => { }, [activeUser]);

  // Function to scroll chat container to the bottom
  const scrollToBottom = () => {

    if (chatContainerRef.current) {

      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;

    }

  };

  //scroll to bottom for new messages

  // const scrollToBottomMessages = () => {

  //   if (chatContainerRef.current) {

  //     const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;

  //     const scrollDifference = scrollHeight - (scrollTop + clientHeight);

  //     const scrollStep = () => {

  //       // Incrementally increase scrollTop towards scrollHeight
  //       chatContainerRef.current.scrollTop += Math.ceil(scrollDifference / 10);

  //       // Check if reached or surpassed scrollHeight
  //       if (chatContainerRef.current.scrollTop >= scrollHeight - clientHeight) {

  //         chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
  //         return;

  //       }

  //       // Request next animation frame
  //       requestAnimationFrame(scrollStep);

  //     };

  //     // Start the scrolling animation
  //     scrollStep();

  //   }

  // };

  // only render on usermessages changes
  useEffect(() => {

    if (shouldScrollToBottom) {

      scrollToBottom();

    }

  }, [activeUser, userMesssages]);

  // Effect to scroll chat container to the bottom when necessary
  useEffect(() => {

    if (shouldScrollToBottom) {

      scrollToBottom();

    }

  }, [filteredMessage]);

  // Function to handle scroll events
  const handleScroll = () => {

    const currentPosition = chatContainerRef.current.scrollTop;

    // Fetch more messages when scrolling to the top
    if (currentPosition === 0) {


      fetchUserMessage();

    }

    // Disable auto-scroll to bottom when user scrolls up
    setShouldScrollToBottom(false);

  };

  // Effect to add scroll event listener
  useEffect(() => {

    if (chatContainerRef.current) {

      chatContainerRef.current.addEventListener("scroll", handleScroll);

    }

    return () => {

      if (chatContainerRef.current) {

        chatContainerRef.current.removeEventListener("scroll", handleScroll);

      }

    };

  }, []);





  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUserData = useCallback(

    debounce((user) => {
      axios
        .post(`https://fmgpy.znapay.in/get/curent_user`, { activeUser: user })
        .then((response) => {
          setisOnline(response.data.is_online);
          setlastSeen(response.data.last_seen);
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    }, 500), // Adjust the delay as needed
    []
  );

  useEffect(() => {

    if (activeUser) {

      fetchUserData(activeUser);

    }

  }, [activeUser]);

  // Set messagesLoaded flag when filteredMessage is populated
  useEffect(() => {
    if (filteredMessage) {
      messagesLoadedRef.current = true;
    }
  }, [filteredMessage]);

  const handleEmoticonSelect = (emoticon) => {

    if (emoticon) {

      setMessageInput((prev) => prev + emoticon);

      if (inputRef.current) {

        inputRef.current.blur();

      }

    }

  };

  return (
    <>
      <div className="student_app_chat_message_box">
        {ispopupOpen && <StudentAdditionalForm messageId={messageId} />}
        {activeUser && (
          <div className="student_app_message_input_parent">
            <div className="student_app_chat_message_box_header">
              <div className="student_app_chat_message_box_profile">
                <div className="student_app_chat_message_box_back_btn">
                  {backIcon}
                </div>
                <div className="student_app_chat_message_box_profile_name">
                  <div className="student_app_chat_user_profile_section">
                    {activeUser && userLogo ? (
                      <Avatar
                        size="md"
                        className="student_app_chat_profile"
                        src={"/" + userLogo}
                        alt="bank logo"
                      />
                    ) : (
                      <Avatar
                        size="md"
                        name={activeUserName}
                        src={activeUserName}
                      />
                    )}
                    <div className="student_app_chat_status">

                      <div className="student_app_chat_username">{activeUserName}</div>


                      <div className="student_app_chat_timer">
                        {isOnline ? (
                          <><p className="isOnline"> Online </p></>
                        ) : (
                          <><p>Offline</p></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="student_chat_app_notification_ico">
                  {role === "banker" && (
                    <div onClick={handleNotificationIconClick}>
                      {notificationIcon}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {renderFileChange ? (
              <div className="student_chat_app_file_preview">
                {renderFileChange}
              </div>
            ) : (
              <>
                <div
                  className="student_app_message_viewer"
                  id="messages-container"
                  ref={chatContainerRef}
                >
                  <div className="app_chat_message_content">
                    {isLoadingMessages && (
                      <div className="flex justify-center w-full" role="status">
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 text-gray-200 animate-spin fill-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#fff"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#25d366"
                          />
                        </svg>
                      </div>
                    )}

                    <div className="app_chat_messages">
                      {filteredMessage &&
                        Object.keys(filteredMessage).map((dateKey, index) => (
                          <div className="student_app_session_date" key={index}>
                            <h3>{dateKey}</h3>
                            {filteredMessage[dateKey].map(
                              (message, messageIndex) => (
                                <div
                                  className="app_chat_messages messages"
                                  key={messageIndex}
                                  id={`message-${message.message_id}`}
                                >
                                  {message.sender_id == user_id ? (
                                    <div className="app_chat_sender_message message sent">
                                      <div
                                        className={`app_chat_message_content ${message.clickable
                                          ? "clickable-text linked-text"
                                          : ""
                                          }`}
                                      >
                                        <div className="app_chat_message_text" onClick={() => handleClick(message)}>
                                          {/* {message?.text && message.text.includes("<div>") ? (
                                        <>
                                          <Linker>{parse(message.text)}</Linker>

                                        </>
                                      ) : (
                                        <>
                                          <Linker>{message.text}</Linker>
                                        </>
                                      )} */}
                                          {message.text && (
                                            <Linker>
                                              {getMessageContent(message)}
                                            </Linker>
                                          )}
                                        </div>
                                      </div>

                                      {message?.file ? (
                                        <RenderFiles
                                          messageData={message.file}
                                          uploadProgress={uploadProgress}
                                        />
                                      ) : (
                                        <RenderFiles
                                          messageData={message}
                                          uploadProgress={null}
                                        />
                                      )}

                                      <div className="app_chat_receiver_date">
                                        {message.received_time}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="app_chat_receiver_message">
                                      <div
                                        className={`app_chat_message_content ${message.clickable
                                          ? "clickable-text linked-text"
                                          : ""
                                          }`}
                                      >
                                        <div className="app_chat_message_text" onClick={() => handleClick(message)}>
                                          {/* {message?.text && message.text.includes("<div>") ? (
                                        <>
                                          <Linker>{parse(message.text)}</Linker>
                                        </>
                                      ) : (
                                        <>
                                          <Linker>{message.text}</Linker>
                                        </>
                                      )} */}

                                          {message.text && (
                                            <Linker>
                                              {getMessageContent(message)}
                                            </Linker>
                                          )}
                                        </div>
                                      </div>
                                      {message?.file ? (
                                        <RenderFiles
                                          messageData={message.file}
                                          uploadProgress={uploadProgress}
                                        />
                                      ) : (
                                        <RenderFiles
                                          messageData={message}
                                          uploadProgress={null}
                                        />
                                      )}
                                      <div className="app_chat_receiver_date">
                                        {message.received_time}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="student_chat_app_input_container">
                  {role === "banker" && (
                    <div
                      className="banker-float-bubble"
                      onClick={handlePopupTrigger}
                    >
                      <p>Send Registration Form</p>
                    </div>
                  )}
                  <div
                    className="student_app_emoticon"
                    onClick={handleEmoticon}
                  >
                    <EmojiEmotionsIcon />
                  </div>

                  <div className="student_app_input_attach">{input}</div>

                  <input
                    ref={inputRef}
                    placeholder="Type a message ..."
                    type="text"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        handleSend();
                      }
                    }}
                  />
                  <div className="student_app_chat_send">
                    <SendIcon onClick={handleSend} />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {isEmoticon && (
        <div className="emoticon-picker-container">
          <EmoticonPicker onSelect={handleEmoticonSelect} />
        </div>
      )}
    </>
  );
};

export default ChatMessageBox;
