// ChatUserList.js
import React, { memo, useEffect, useState, useCallback } from "react";
import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import dateTimeConverter from "./Utils";
import { debounce } from 'lodash'; 
import PermMediaIcon from "@mui/icons-material/PermMedia";
import axios from "axios";

import "./chat.css";

const ChatUserList = ({
  unreadMessageCount,
  userMesssages,
  users,
  currentUser,
  log,
  activeUser,
  handleUserClick,
  handleUserApiRequest,
  setUnreadMessageCount,
  user_id,
  setActiveUser,
}) => {
  const [userMessageList, setUserMessageList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [sortedUsers, setSortedUsers] = useState(users);
  const [isFocused, setIsFocused] = useState(false);


  // TRUNCATE STRING //

  const truncateString = (str, num) => {
    if (str) {
      if (str.length <= num) {
        return str;
      }

      return str.slice(0, num) + "...";
    }
  };

  // LOGOUT USER //

  const logoutUser = async () => {

    const userId = localStorage.getItem("studentId");
    const bankerId = localStorage.getItem("bankerId");

    try {
      if (userId) {
        // User logout
        await axios.post("https://fmgpy.znapay.in/update_status", {
          user_id: userId,
          role: "user",
        });
      }

      if (bankerId) {
        // Banker logout
        await axios.post("https://fmgpy.znapay.in/update_status", {
          banker_id: bankerId,
          role: "banker",
        });
      }

      // Clear local storage items
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("is_online");
      localStorage.removeItem("last_seen");
      localStorage.removeItem("bankerId")
      localStorage.removeItem("studentId")
      log(true);

      console.log("Logout successful");

    } catch (error) {

      console.error("Failed to update status:", error);

    }

  };

  // GET SEARCH PARAMS //

  // const getSearchParam = async (searchParam) => {
  //   try {
  //     const response = await axios.get(
  //       `https://fmgpy.znapay.in/search?query=${searchParam}&user_id=${user_id}`
  //     );

  //     if (response?.data?.messages) {
  //       setUserMessageList(response.data.messages);
  //     } else {
  //       setUserMessageList([]);
  //     }
  //   } catch (error) {
  //     setUserMessageList([]);
  //   }
  // };


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetSearchParam = useCallback(
    debounce(async (searchParam) => {
      try {
        const response = await axios.get(
          `https://fmgpy.znapay.in/search?query=${searchParam}&user_id=${user_id}`
        );

        if (response?.data?.messages) {
          setUserMessageList(response.data.messages);
        } else {
          setUserMessageList([]);
        }
      } catch (error) {
        setUserMessageList([]);
      }
    }, 2000), // Adjust debounce delay as needed
    [user_id]
  );

  const renderSearchedUsers = async (e) => {
    
    const searchParam = e.target.value;

    // setInputValue(searchParam.trim()); // Trim whitespace from input
    setInputValue(searchParam);

    if (searchParam.trim() === "") {

      setUserMessageList([]);

    } else {

      debouncedGetSearchParam(searchParam);

    }

  };


  // EXTRACT HTM TAGS FROM STRING //

  const removeAllHtmlTags = (text) => {

    return text.replace(/<[^>]+>/g, '');

  }


  //SORT USER BASED ON MESSAGE //

  // useEffect(() => {


  //   console.log(users,'users')

  //   const sortedAndMapped = [...users]
  //     .sort((a, b) => {
  //       const lastMessageA = userMesssages
  //         .filter((msg) => msg.receiver_id === a.id || msg.sender_id === a.id)
  //         .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))[0];

  //       const lastMessageB = userMesssages
  //         .filter((msg) => msg.receiver_id === b.id || msg.sender_id === b.id)
  //         .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))[0];

  //       if (!lastMessageA && !lastMessageB) return 0;
  //       if (!lastMessageA) return 1;
  //       if (!lastMessageB) return -1;

  //       return new Date(lastMessageB.created_at) - new Date(lastMessageA.created_at);
  //     })
  //     .map((user) => {
  //       const lastMessage = userMesssages
  //         .filter((msg) => msg.receiver_id === user.id || msg.sender_id === user.id)
  //         .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))[0];

  //       const unreadCount = unreadMessageCount.find((count) => count.id === user.id)?.count || 0;


  //       return {
  //         ...user,
  //         data_urls: lastMessage ? lastMessage.data_urls : user.data_urls,
  //         text: lastMessage ? lastMessage.text : user.text,
  //         file: lastMessage ? lastMessage.file : null,
  //         unread_count: unreadCount,
  //       };
  //     });



  //   setSortedUsers(sortedAndMapped);

  // }, [users,  userMesssages]);



  useEffect(() => {
    console.log(users, userMesssages, 'users');

    // Check if userMesssages is available
    const hasUserMessages = userMesssages && userMesssages.length > 0;

    const sortedAndMapped = [...users]
      .sort((a, b) => {
        if (hasUserMessages) {
          // Sort based on the last message if userMesssages is available
          const lastMessageA = userMesssages
            .filter((msg) => msg.receiver_id === a.id || msg.sender_id === a.id)
            .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))[0];

          const lastMessageB = userMesssages
            .filter((msg) => msg.receiver_id === b.id || msg.sender_id === b.id)
            .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))[0];

          if (!lastMessageA && !lastMessageB) return 0;
          if (!lastMessageA) return 1;
          if (!lastMessageB) return -1;

          return new Date(lastMessageB.created_at) - new Date(lastMessageA.created_at);
        } else {
          // Fallback to sorting by user creation date if no messages are available
          return new Date(b.created_at) - new Date(a.created_at);
        }
      })
      .map((user) => {
        const lastMessage = hasUserMessages
          ? userMesssages
            .filter((msg) => msg.receiver_id === user.id || msg.sender_id === user.id)
            .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))[0]
          : null;

        const unreadCount = unreadMessageCount.find((count) => count.id === user.id)?.count || 0;

        console.log(lastMessage,'lastMessage')

        return {
          ...user,
          data_urls: lastMessage ? lastMessage.data_urls : user.data_urls,
          text: lastMessage ? lastMessage.text : user.text,
          file: lastMessage ? lastMessage.file : null,
          unread_count: unreadCount,
        };
      });

    setSortedUsers(sortedAndMapped);

  }, [users, userMesssages, unreadMessageCount]);













  // HIGHLITING SEARCHED TEXT //

  const highlightSearchText = (text, searchParam) => {

    if (!searchParam) return text; // If searchParam is empty, return original text

    const parts = text.split(new RegExp(`(${searchParam})`, "gi"));

    return parts.map((part, index) =>
      part.toLowerCase() === searchParam.toLowerCase() ? (
        <span key={index} style={{ color: "#25d366", fontWeight: 600 }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="student_app_chat_user_list_container">
      <div className="student_app_chat_user_header_section">
        <div className="student_app_chat_header_new_chat">
          <div className="student_app_chat_header">
            <h5>Chats</h5>
          </div>
          <div className="student_app_chat_profile">
            <Menu>
              <MenuButton>
                <Avatar size="sm" />
              </MenuButton>
              <MenuList>
                <MenuGroup title="Profile">
                  <MenuDivider />
                  <MenuItem onClick={logoutUser}>Logout</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </div>
        </div>
        <div className={`student_app_chat_search ${isFocused ? 'focused' : ''}`}>
          <SearchIcon w={5} h={5} className="student_app_search_icon" />
          <input
            type="text"
            value={inputValue}
            placeholder="Search Users, Messages ..."
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={renderSearchedUsers}
          />
        </div>
      </div>

      {/* Render searched users or sorted users */}
      {inputValue.trim() !== "" && userMessageList.length > 0 ? (
        <>
          {userMessageList.map((user, index) => (
            <div
              key={index}
              onClick={() => {
                handleUserClick(
                  user.id,
                  user.username,
                  user.image_key,
                  user.message_id
                );
                handleUserApiRequest(user.id, currentUser, setUnreadMessageCount);
              }}
              className={`student_app_chat_user_detail ${user.id === activeUser ? "active" : ""
                }`}
            >
              <div className="student_app_chat_user_name_profile">
                <div className="student_app_chat_profile">
                  {user && user.image_key ? (
                    <Avatar
                      size="md"
                      className="student_app_chat_profile"
                      name={user.username}
                      src={"/" + user.image_key}
                      alt="Profile"
                    />
                  ) : (
                    <Avatar
                      size="md"
                      name={user.username}
                      src={user.username}
                      alt="Profile"
                    />
                  )}
                </div>
                <div className="student_app_chat_profile_trunc_msg">
                  <div className="student_app_chat_profile_name">
                    <h5>{user?.username}</h5> 
                  </div>
                  <div className="student_app_chat_msg">
                    {highlightSearchText(truncateString(removeAllHtmlTags(user?.text)), inputValue)}
                  </div>
                </div>
              </div>
              <div className="student_app_chat_time_stamp_message_unread">
                {dateTimeConverter(user?.received_at)}
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {sortedUsers.length <= 0 ? (
            <>
              <div className="flex justify-center w-full space-x-4" role="status">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#25d366"
                  />
                </svg>
                <p className="text-md font-semibold fetching_user animate-pulse">Fetching Users ...</p>
              </div>
            </>
          ) : (
            <>
              {sortedUsers.map((user, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleUserClick(user.id, user.username, user.image_key);
                    handleUserApiRequest(user.id, currentUser, setUnreadMessageCount);
                  }}
                  className={`student_app_chat_user_detail ${user.id === activeUser ? "active" : ""
                    }`}
                >
                  <div className="student_app_chat_user_name_profile">
                    <div className="student_app_chat_profile">
                      {user && user.image_key ? (
                        <Avatar
                          size="md"
                          className="student_app_chat_profile"
                          name={user.username}
                          src={"/" + user.image_key}
                          alt="Profile"
                        />
                      ) : (
                        <Avatar
                          size="md"
                          name={user.username}
                          src={user.username}
                          alt="Profile"
                        />
                      )}
                    </div>
                    <div className="student_app_chat_profile_trunc_msg">
                      <div className="student_app_chat_profile_name">
                        <h5>
                          {user.username}
                        </h5>
                      </div>
                      <div className="student_app_chat_msg font-gola font-bold text-black last-message">
                        {user.data_urls || (user.file && user.file.data_urls) ? (
                          <PermMediaIcon style={{ width: "15px" }} />
                        ) : (
                          user.text && <>{truncateString(removeAllHtmlTags(user.text), 30)}</>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="student_app_chat_time_stamp_message_unread">
                    <div className="student_app_chat_unread_count">

                      {user.unread_count > 0 && (
                        <span className="unread-count-badge">
                          {user.unread_count}
                        </span>
                      )}
                    </div>
                    <div className="student_app_chat_created_at">
                      {user && user.created_at ? (
                        <>{dateTimeConverter(user.created_at)}</>
                      ) : (
                        <>{dateTimeConverter(new Date())}</>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ChatUserList;
