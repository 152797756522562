import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { isAxiosError } from "axios";
import { useGetToken } from "../../hooks/useGetToken";
import OtpInputHandler from "../../reusable/OtpInputHandler";
import { validateOTP, studentLogin } from "../../../store/slice/StudentSlice";
import { resetOtpSentStatus } from "../../../store/slice/StudentSlice";

function StudentOtpVerification() {

  // EVENT DISPATCHER //
  const dispatch = useDispatch();

  // getting state values
  const { isOTPVerifying } = useSelector((state) => state.student.auth);

  const [isOTPVerified, setOtpVerified] = useState(false);

  const [otpInput, setOtpInput] = useState("");
  const [studentId, setstudentId] = useState(0);
  const [mobileNo, setMobileNo] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [validationMsg, setValidationMsg] = useState("")

  // ROUTER NAVIGATIONS //
  const navigate = useNavigate();


  // HOOK TO GET STUDENT ID FROM LOCAL STORAGE //
  const { studentToken } = useGetToken();


  // HANDLE OTP //
  const handleOTPInput = (e) => {

    setIsClicked(false)
    setValidationMsg("")
    const removeSpacesFromInput = e.replace(/\s+/g, "");
    setOtpInput(removeSpacesFromInput);

  };

  // FETCH ID FROM LOCAL STORAGE //

  useEffect(() => {

    const id = localStorage.getItem("studentId");
    setMobileNo(localStorage.getItem("phone"));
    setstudentId(id);

  }, []);


  // API CALL TO VALIDATE OTP //
  const handleValidateOTP = async (e) => {




    e.preventDefault();

    if (otpInput.length === 6) {

      if (studentId) {

        const URL = `https://fmgpy.znapay.in/verify/otp/${studentId}`;


        const headers = {

          "Content-Type": "application/json",

        };

        try {

          const response = await axios.post(URL, { otp_recieved: otpInput },
            {
              headers,
            }

          );

          if (response.data) {

            localStorage.setItem("token", response?.data?.access_token)

            setOtpVerified(true)

          }

        } catch (err) {

          setOtpVerified(false)
          const errMsg = err.response?.data?.msg
          setValidationMsg(errMsg)

        }
      }
    } else {

      setIsClicked(true);

    }

  };

  //  // IF OTP GETS VERIFIED NAVIGATE TO CHAT //


  useEffect(() => {

    if (isOTPVerified === true) {


      dispatch(resetOtpSentStatus())

      navigate("/chat");

    }
  }, [dispatch, isOTPVerified, navigate]);





  useEffect(() => {

    const token = localStorage.getItem("token")

    if (token) {

      navigate("/chat")

    }

  }, [navigate])



  // RESENT OTP //
  function resendOTP() {

    const phone = localStorage.getItem("phone")


    if (phone) {

      dispatch(

        studentLogin({

          phone: Array.isArray(phone) ? phone[0] : phone,

        })

      );

    }

  }

  return (
    <>
      <div className=" w-screen h-screen bg-light-color relative flex items-center justify-center overflow-hidden">
        <img
          style={{ zIndex: 99 }}
          className="desktop:absolute mobile:hidden tablet:hidden -bottom-[15%] -right-[40%]"
          src="/bg1.svg"
          alt="Background"
        />
        <div
          style={{ zIndex: 100 }}
          className="desktop:min-w-[996px] mobile:h-[450px] desktop:max-w-[996px] tablet:w-9/12 mobile:w-11/12 desktop:w-custom996 desktop:h-4/5 tablet:max-h-[700px] shadow-2xl bg-white rounded-2xl flex"
        >
          <div className="desktop:relative overflow-hidden w-2/5 tablet:hidden mobile:hidden bg-primary-color h-full rounded-l-lg">
            <h1 className="font-gola text-2xl font-bold text-white desktop:pl-10 pt-10 leading-snug mr-14">
              Welcome To <br></br>Fund My Grad <br></br>Student Portal ...
            </h1>
            <p className="opacity-100 font-gola text-base text-white pl-10 mt-6 mr-14 font-normal">
              we turn the gruelling loan process on it's head by letting the
              banks come to you rather than you going around to each and every
              bank to check for details
            </p>
            <img
              className="desktop:absolute top-2 -right-0 opacity-60"
              src="/bg4.svg"
              alt="Background 2"
            />
            <img
              className="desktop:absolute -bottom-[5%] -left-[35%]"
              src="/bg3.svg"
              alt="Background 2"
            />
            <img
              className="desktop:absolute bottom-10 right-10"
              src="/bg2.svg"
              alt="Background 2"
            />
          </div>
          <div className="desktop:w-3/5 tablet:w-full mobile:w-full desktop:pl-pad74 desktop:pr-pad74 flex flex-col desktop:justify-center tablet:justify-start">
            <div className="rounded-t-lg tablet:mb-5 mobile:mb-5 tablet:h-[210px] mobile:pl-6 mobile:pr-6 tablet:pl-pad74 tablet:pr-pad74 bg-primary-color desktop:hidden">
              <h1 className="font-gola text-1.5xl tablet:pt-5  mobile:pt-5 mobile:mb-8 font-bold text-white leading-snug">
                Welcome To Fund My Grad
              </h1>
            </div>
            <form
              onSubmit={handleValidateOTP}
              className="tablet:pl-pad74 tablet:pr-pad74 mobile:pl-6 mobile:pr-6"
            >
              <span className="text-2xl font-semibold">Type in the secret code 😊</span>
              <div className="tabanddesk:flex mobile:flex-col item-center mt-5">
                <p className="text-sm	font-normal text-subtext opacity-70 ">
                  Enter the OTP we sent to +91{mobileNo} &nbsp;
                </p>
                <h6 className="text-sm mobile:mt-2 text-primary-color font-semibold">
                  <a href="/student-auth">Change Number</a>
                </h6>
              </div>
              <div className="mt-5">
                <OtpInputHandler
                  value={otpInput}
                  valueLength={6}
                  onChange={handleOTPInput}
                />
              </div>
              <div className="font-gola mt-3 text-red-600">

                {isClicked &&
                  otpInput.length < 6 &&
                  "Please provide 6 digits OTP"}

                {!isOTPVerified && validationMsg && otpInput.length === 6 && (
                  <p>{validationMsg}</p>
                )}

              </div>
              <button
                type="submit"
                className={`h-10 mt-6 w-full font-gola text-white ${isClicked && otpInput.length < 6
                  ? "bg-slate-500 cursor-not-allowed"
                  : "bg-primary-color"
                  } font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none`}
              >
                {isOTPVerifying ? (
                  <div className="flex justify-center w-full" role="status">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-200 animate-spin fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  "Verify"
                )}
              </button>
              <div className="flex w-full justify-end mt-3">
                <span
                  onClick={resendOTP}
                  className="cursor-pointer font-gola font-semibold text-base text-primary-color"
                >
                  Resend OTP
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentOtpVerification;
