import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import StudentAuthSlice from './slice/StudentSlice';
import ProfileSlice from './slice/ProfileSlice';
import CommonSlice from './slice/CommonSlice';
import ApplicationSlice from './slice/ApplicationSlice';
import BankerSlice  from './slice/BankerSlice';

const store = configureStore({
    reducer: {
        student: StudentAuthSlice,
        profile: ProfileSlice,
        common: CommonSlice,
        application: ApplicationSlice,
        banker: BankerSlice

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export default store