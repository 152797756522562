import axios, { isAxiosError } from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  onboardingForm: {
    applicationId: "",
    createApplication: {
      loading: false,
      stepCompleted: false,
    },
    createCourseDetails: {
      loading: false,
      stepCompleted: false,
    },
    createLoanDetails: {
      loading: false,
      stepCompleted: false,
    },
    createCoApplicantDetails: {
      loading: false,
      stepCompleted: false,
    },
    createEducationalDetails: {
      loading: false,
      stepCompleted: false,
    },
    createTestScores: {
      loading: false,
      stepCompleted: false,
    },

    createWorkExperience: {
      loading: false,
      stepCompleted: false,
    },
  },
  getApplication: {
    loading: false,
    applicationData: null,
    error: null,
  },

  getAllApplication: {
    loading: false,
    applicationsData: [],
    error: null,
  },
};

export const createApplication = createAsyncThunk(
  "student/createApplication",
  async ({ status_uuid, token }, thunkApi) => {
    const data = {
      admission_status_uuid: status_uuid,
    };

    try {
      const response = await axios.post(
        `https://fmgpy.znapay.in/applications`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data) {
        localStorage.setItem("applicationId", response.data.application_uuid);
      }
      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const getAllApplication = createAsyncThunk(
  "getAllApplication",
  async ({ token }, thunkApi) => {
    try {
      console.log(token,'token')
      const response = await axios.get(
        `https://fmgpy.znapay.in/applications`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const createCoApplicantDetails = createAsyncThunk(
  "student/createCoApplicantDetails",
  async (
    { applicationId, relation_id, income_type_id, income, phone, email, token },
    thunkApi
  ) => {
    const data = {
      relation_uuid: relation_id,
      income_type_uuid: income_type_id,
      income: income,
      phone: phone,
      email: email,
    };
    try {
      const response = await axios.post(
        `https://fmgpy.znapay.in/applications/${applicationId}/coapplicant`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const createCourseDetails = createAsyncThunk(
  "student/createCourseDetails",
  async (
    {
      applicationId,
      start_date,
      university_id,
      program_id,
      course,
      course_duration,
      token,
    },
    thunkApi
  ) => {
    const data = {
      start_date: start_date,
      university_uuid: university_id,
      program_uuid: program_id,
      course: course,
      course_duration: course_duration,
    };
    try {
      const response = await axios.post(
        `https://fmgpy.znapay.in/applications/${applicationId}/course`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const createEducationalDetails = createAsyncThunk(
  "student/createEducationalDetails",
  async (
    {
      applicationId,
      institution,
      program_id,
      course,
      score_type_id,
      score,
      backlogs,
      token,
    },
    thunkApi
  ) => {
    const data = {
      institution_name: institution,
      program_uuid: program_id,
      course: course,
      score_type_uuid: score_type_id,
      score: score,
      backlogs: backlogs,
    };
    try {
      const response = await axios.post(
        `https://fmgpy.znapay.in/applications/${applicationId}/educational`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const createWorkExperience = createAsyncThunk(
  "student/createWorkExperience",
  async ({ applicationId, work_experience, work_salary, token }, thunkApi) => {
    const data = {
      experience: work_experience,
      salary: work_salary,
    };

    try {
      const response = await axios.post(
        `https://fmgpy.znapay.in/applications/${applicationId}/work`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;

        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const getApplication = createAsyncThunk(
  "getApplication",
  async ({ application_id, token }, thunkApi) => {
    try {
      const response = await axios.get(
        `https://fmgpy.znapay.in/applications/${application_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const createTestScores = createAsyncThunk(
  "student/createTestScores",
  async ({ applicationId, scoreList, token }, thunkApi) => {
    try {
      const responses = [];
      await Promise.all(
        scoreList.map(async (score) => {
          try {
            const data = score;
            const response = await axios.post(
              `https://fmgpy.znapay.in/applications/${applicationId}/test-scores`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              }
            );
            responses.push(response.data);
          } catch (error) {
            if (isAxiosError(error) && error.response) {
              const message = error.response.data.message;
              // You can handle errors here if needed
            }
          }
        })
      );
      return responses;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const createLoanDetails = createAsyncThunk(
  "student/createLoanDetails",
  async ({ applicationId, requirement, pincode_id, token }, thunkApi) => {
    const data = {
      requirement: requirement,
      pincode_uuid: pincode_id,
    };
    try {
      const response = await axios.post(
        `https://fmgpy.znapay.in/applications/${applicationId}/loan`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const createCollateralDetails = createAsyncThunk(
  "student/createCollateralDetails",
  async (
    { applicationId, collateral_id, collateral_value, token },
    thunkApi
  ) => {
    const data = {
      collateral_type_uuid: collateral_id,
      value: collateral_value,
    };
    try {
      const response = await axios.post(
        `https://fmgpy.znapay.in/applications/${applicationId}/collateral`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const ApplicationSlice = createSlice({
  name: "application",
  initialState,

  reducers: {
    resetCreateApplicationStatus(state) {
      state.onboardingForm.createApplication.stepCompleted = false;
    },

    resetCreateCourseDetailsStatus(state) {
      state.onboardingForm.createCourseDetails.stepCompleted = false;
    },

    resetCreateLoanDetailsStatus(state) {
      state.onboardingForm.createLoanDetails.stepCompleted = false;
    },

    resetCreateCoApplicantDetailsStatus(state) {
      state.onboardingForm.createCoApplicantDetails.stepCompleted = false;
    },

    resetCreateEducationalDetailsStatus(state) {
      state.onboardingForm.createEducationalDetails.stepCompleted = false;
    },

    resetCreateTestScoresStatus(state) {
      state.onboardingForm.createTestScores.stepCompleted = false;
    },

    resetCreateWorkExperienceStatus(state) {
      state.onboardingForm.createWorkExperience.stepCompleted = false;
    },

    resetUpdateUserProfileStatus(state) {
      state.updateUserProfile.isProfileUpdated = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(createApplication.pending, (state) => {
      state.onboardingForm.createApplication.loading = true;
    });
    builder.addCase(createApplication.fulfilled, (state, action) => {
      state.onboardingForm.createApplication.loading = false;
      state.onboardingForm.createApplication.stepCompleted = true;
      state.onboardingForm.applicationId = action.payload.id;
    });
    builder.addCase(createApplication.rejected, (state) => {
      state.onboardingForm.createApplication.loading = false;
    });
    builder.addCase(createCourseDetails.pending, (state) => {
      state.onboardingForm.createCourseDetails.loading = true;
    });
    builder.addCase(createCourseDetails.fulfilled, (state, action) => {
      state.onboardingForm.createCourseDetails.loading = false;
      state.onboardingForm.createCourseDetails.stepCompleted = true;
    });
    builder.addCase(createCourseDetails.rejected, (state) => {
      state.onboardingForm.createCourseDetails.loading = false;
    });

    builder.addCase(createLoanDetails.pending, (state) => {
      state.onboardingForm.createLoanDetails.loading = true;
    });
    builder.addCase(createLoanDetails.fulfilled, (state, action) => {
      state.onboardingForm.createLoanDetails.loading = false;
      state.onboardingForm.createLoanDetails.stepCompleted = true;
    });
    builder.addCase(createLoanDetails.rejected, (state) => {
      state.onboardingForm.createLoanDetails.loading = false;
    });

    builder.addCase(createCoApplicantDetails.pending, (state) => {
      state.onboardingForm.createCoApplicantDetails.loading = true;
    });
    builder.addCase(createCoApplicantDetails.fulfilled, (state, action) => {
      state.onboardingForm.createCoApplicantDetails.loading = false;
      state.onboardingForm.createCoApplicantDetails.stepCompleted = true;
    });
    builder.addCase(createCoApplicantDetails.rejected, (state) => {
      state.onboardingForm.createCoApplicantDetails.loading = false;
    });
    builder.addCase(createEducationalDetails.pending, (state) => {
      state.onboardingForm.createEducationalDetails.loading = true;
    });
    builder.addCase(createEducationalDetails.fulfilled, (state, action) => {
      state.onboardingForm.createEducationalDetails.loading = false;
      state.onboardingForm.createEducationalDetails.stepCompleted = true;
    });
    builder.addCase(createEducationalDetails.rejected, (state) => {
      state.onboardingForm.createEducationalDetails.loading = false;
    });

    builder.addCase(createTestScores.pending, (state) => {
      state.onboardingForm.createTestScores.loading = true;
    });
    builder.addCase(createTestScores.fulfilled, (state, action) => {
      state.onboardingForm.createTestScores.loading = false;
      state.onboardingForm.createTestScores.stepCompleted = true;
    });
    builder.addCase(createTestScores.rejected, (state) => {
      state.onboardingForm.createTestScores.loading = false;
    });

    builder.addCase(createWorkExperience.pending, (state) => {
      state.onboardingForm.createWorkExperience.loading = true;
    });
    builder.addCase(createWorkExperience.fulfilled, (state, action) => {
      state.onboardingForm.createWorkExperience.loading = false;
      state.onboardingForm.createWorkExperience.stepCompleted = true;
    });
    builder.addCase(createWorkExperience.rejected, (state) => {
      state.onboardingForm.createWorkExperience.loading = false;
    });

    builder.addCase(getApplication.pending, (state) => {
      state.getApplication.loading = true;
    });
    builder.addCase(getApplication.fulfilled, (state, action) => {
      state.getApplication.loading = false;
      state.getApplication.applicationData = action.payload;
    });
    builder.addCase(getApplication.rejected, (state) => {
      state.getApplication.loading = false;
    });

    builder.addCase(getAllApplication.pending, (state) => {
      state.getAllApplication.loading = true;
    });

    builder.addCase(getAllApplication.fulfilled, (state, action) => {
      state.getAllApplication.loading = false;
      state.getAllApplication.applicationsData = action.payload.applications;
    });

    builder.addCase(getAllApplication.rejected, (state) => {
      state.getAllApplication.loading = false;
    });
  },
});

// export reducer actions for components

export const {
  resetCreateApplicationStatus,
  resetCreateCourseDetailsStatus,
  resetCreateLoanDetailsStatus,
  resetCreateCoApplicantDetailsStatus,
  resetCreateEducationalDetailsStatus,
  resetCreateTestScoresStatus,
  resetCreateWorkExperienceStatus,
  resetUpdateUserProfileStatus,
} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
