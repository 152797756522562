import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate  } from "react-router-dom";
import { Helmet } from "react-helmet";

///student import start
import StudentAuth from "../student/studentauth/StudentAuth";
import StudentOtpVerification from "../student/studentauth/StudentOtpVerification";
import SetupProfile from "../student/studentprofile/SetupProfile";
import Profile from "../student/studentprofile/Profile";

import University from "../student/student-on-boarding/universities/University";
import Offers from "../student/student-on-boarding/Offers";

import BankerAuth from "../banker/BankerAuth"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ChatRenderer from "../chatBar/Chat";




///student import end

///admin import start

//admin import end

function RouteComponentRenderer() {

   
  const checkToken = () => {

    const token = localStorage.getItem("token")


    console.log(token,'token')
    return token

  }

  const navigate = useNavigate()


  const [isSessionValid, setIsSessionValid] = useState(false)

  
 

   console.log("routercomponent")




   

  return (
    <Routes>

      <Route
        path="/banker-auth"
        element={
          <>
            <Helmet>
              <title>Banker Login</title>
            </Helmet>
            <BankerAuth />
          </>
        }
      />

      <Route
        path="/student-auth"
        element={
          <>
            <Helmet>
              <title>Student Login</title>
            </Helmet>
            <StudentAuth />
          </>
        }
      />

      <Route
        path="/"
        element={
          <>
            <Helmet>
              <title>Student Login</title>
            </Helmet>
            <StudentAuth />
          </>
        }
      />

      <Route
        path="/student-otp-verification"
        element={
          <>
            <Helmet>
              <title>Student OTP Verification</title>
            </Helmet>
            <StudentOtpVerification />
          </>
        }
      />

      <Route
        path="/setup-profile"
        element={
          <>
            <Helmet>
              <title>Setup Profile</title>
            </Helmet>
            <SetupProfile />
          </>
        }
      />

      <Route
        path="/select-university"
        element={
          <>
            <Helmet>
              <title>Select University</title>
            </Helmet>
            <University />
          </>
        }
      />

      <Route
        path="/studentprofile"
        element={
          <>
            <Helmet>
              <title>Student Profile</title>
            </Helmet>
            <Profile />
          </>
        }
      />

 
      {/* { token ? (
        <Route
        path="/chat"
        element={
          <>
            <Helmet>
              <title>Fundmygrad - Chat</title>
            </Helmet>
            <ChatRenderer />
          </>
        }
      />
       ):(
        <Route
        path="/student-auth"
        element={
          <>
            <Helmet>
              <title>Student Login</title>
            </Helmet>
            <StudentAuth />
          </>
        }
      />
       )} */}



      <Route path="/chat" element={
          checkToken() ?  (
          <>
            <Helmet>
              <title>Fundmygrad - Chat</title>
            </Helmet>
            <ChatRenderer />
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-undef
          <Navigate  to="/student-auth" />
        )
      } />

      <Route
        path="/offers"
        element={
          <>
            <Helmet>
              <title>Loan Offers</title>
            </Helmet>
            <Offers />
          </>
        }
      />
    </Routes>
  );
}

export default RouteComponentRenderer;
