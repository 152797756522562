import { useEffect, useState } from "react";

export function useGetToken() {
  const [studentToken, setStudnetToken] = useState(undefined);
  const [studentId, setApplicationId] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token");
      const studentId = localStorage.getItem("studentId");
      token ? setStudnetToken(token) : setStudnetToken("");
      studentId && setApplicationId(studentId);
    }
  }, []);

  return { studentToken, studentId };
}
