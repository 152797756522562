import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';

const EmoticonPicker = ({ onSelect }) => {

  const handleEmojiClick = (emojiObject, event) => {

     
    onSelect(emojiObject.emoji);  

  };


  return (
    <div>
      <EmojiPicker   style={{width: "100%"}} onEmojiClick={handleEmojiClick} open={true} />
    </div>
  );

}

EmoticonPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
};


export default EmoticonPicker