// import React from 'react';
// import './chat.css';

// const Linker = ({ children }) => {

//     // Function to determine if a word is a URL
//     const isUrl = (word) => {
//         const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
//         return word.match(urlPattern);
//     };

//     // Function to convert URLs to HTML anchor tags
//     const addMarkup = (word) => {
//         return isUrl(word) ? 
//             `<a href="${word.startsWith('http') ? word : 'http://' + word}" class="link-green" target="_blank" rel="noopener noreferrer">${word}</a>` :  
//             word;
//     };

//     // Check if children is a string
//     const renderContent = () => {

//         if (typeof children === 'string') {

//             const words = children.split(' ');
//             const formattedWords = words.map(w => addMarkup(w));
//             const html = formattedWords.join(' ');
//             return <span dangerouslySetInnerHTML={{ __html: html }} />;

//         } else {

//             // Handle cases where children is not a string
//             return <span>{children}</span>;
            
//         }

//     };

//     return renderContent();
// };

// export default Linker;




import React from 'react';
import './chat.css';

const Linker = ({ children }) => {

    // Function to determine if a word is a URL or an email
    const isUrlOrEmail = (word) => {
        // Regex for URLs
        const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
        // Regex for emails
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

        if (emailPattern.test(word)) {
            return 'email';
        } else if (urlPattern.test(word)) {
            return 'url';
        }
        return null;
    };

    // Function to convert URLs or emails to HTML anchor or mailto tags
    const addMarkup = (word) => {
        const type = isUrlOrEmail(word);

        if (type === 'email') {
            return `<a href="mailto:${word}" class="link-green">${word}</a>`;
        } else if (type === 'url') {
            return `<a href="${word.startsWith('http') ? word : 'http://' + word}" class="link-green" target="_blank" rel="noopener noreferrer">${word}</a>`;
        } else {
            return word;
        }
    };

    // Check if children is a string
    const renderContent = () => {
        if (typeof children === 'string') {
            const words = children.split(' ');
            const formattedWords = words.map(w => addMarkup(w));
            const html = formattedWords.join(' ');
            return <span dangerouslySetInnerHTML={{ __html: html }} />;
        } else {
            // Handle cases where children is not a string
            return <span>{children}</span>;
        }
    };

    return renderContent();
};

export default Linker;
