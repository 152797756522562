import axios, { isAxiosError } from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {

    getUniversity: {
        loading: false,
        getUniversityData: [],
        error: null,
    }, 
    getOffers: {
        loading: false,
        getOffersData: [],
        isOffers: false,
    },

     
};
 

 

export const getUniversity = createAsyncThunk(
    "getUniversity",
    async (
        thunkApi
    ) => {
        try {
            const response = await axios.get(
                `https://fmgpy.znapay.in/get/all/universities`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            return response.data;

        } catch (error) {
            if (isAxiosError(error) && error.response) {
                const message = error.response.data.message;
                return thunkApi.rejectWithValue(message);
            }
        }
    }
);
 

 

export const getOffers = createAsyncThunk(
    "getOffers",
    async (universityId, thunkApi) => {
        try {
            const response = await axios.get(
                `https://fmgpy.znapay.in/get/all/offers/${universityId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            return response.data;

        } catch (error) {
            if (isAxiosError(error) && error.response) {
                const message = error.response.data.message;
                return thunkApi.rejectWithValue(message);
            }
        }
    }
);
 
 

const CommonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {

        resetCreateProgramStatus(state) {
            state.createProgram.isProgramCreated = false;
        },
        resetCreateUniversityStatus(state) {
            state.createProgram.isProgramCreated = false;
        },
         
    },
    extraReducers(builder) {

        builder.addCase(getUniversity.pending, (state) => {
            state.getUniversity.loading = true;
             
        });

        builder.addCase(getUniversity.fulfilled, (state, action) => {
            state.getUniversity.loading = false;
            state.getUniversity.getUniversityData = action.payload
        });

        builder.addCase(getUniversity.rejected, (state) => {
            state.getUniversity.loading = false;
        });


        builder.addCase(getOffers.pending, (state) => {
            state.getOffers.loading = true;
             
        });

        builder.addCase(getOffers.fulfilled, (state, action) => {
            state.getOffers.loading = false;
            state.getOffers.isOffers = true
            state.getOffers.getOffersData = action.payload.bank_data
             
        });

        builder.addCase(getOffers.rejected, (state) => {
            state.getOffers.loading = false;
            state.getOffers.isOffers = false
        });
         
    },
});

export const { resetCreateProgramStatus, resetCreateUniversityStatus } = CommonSlice.actions;

export default CommonSlice.reducer;
