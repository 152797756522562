import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "../src/styles/globals.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";

import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

// chakra extended theme
const theme = extendTheme({
  components: {
    Drawer: {
      parts: ["dialog", "header", "body"],
      variants: {
        mobileDrawer: {
          dialog: {
            minW: "370px",
            maxW: "720px",
          },
        },
        tabletDrawer: {
          dialog: {
            minW: "720px",
            maxW: "1034px",
          },
        },
        desktopDrawer: {
          dialog: {
            minW: "1034px",
            maxW: "1034px",
          },
        },
        secondary: {
          dialog: {
            minW: "1000px",
            maxW: "1000px",
          },
        },
        sidebardrawer: {
          dialog: {
            minW: "80px",
            maxW: "80px",
          },
        },
        sidebardrawer1: {
          dialog: {
            minW: "130px",
            maxW: "130px",
          },
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
