import axios, { isAxiosError } from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
 

// Initial state values of student slicers
const initialState = {
  auth: {
    isOTPSending: false,
    isOTPSent: false,
    isOTPVerifying: false,
    isOTPVerified: false,
    otpSentToken: "",
    otpValidatedToken: "",
  },

  checkUserSession: {
    loading: false,
    sessionChecked: false,
    sessionData: null,
    error: null,
  },

  logout: {
    loading: false,
    isLoggedOut: false,
    error: null,
  },
};

//student login thunk
//Initiates a api call and sets a studentOtpToken

export const studentLogin = createAsyncThunk(
  "student/login",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `https://fmgpy.znapay.in/login`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
       
      response.data && localStorage.setItem("studentId", response.data.id);
      response.data && localStorage.setItem("phone", data.phone);
      response.data && localStorage.setItem("role", response.data.role);
      
      return response.data;
  
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

// validate OTP thunk
export const validateOTP = createAsyncThunk(
  "student/validateOTP",
  async ({ otp, studentId}, thunkApi) => {

    const data = {
      
      otp: otp,

    };
     
    const headers = {

      "Content-Type": "application/json",

    };

    try {

       
      const response = await axios.post(
        `https://fmgpy.znapay.in/verify/otp/${studentId}`,
        data,
        {
          headers,
        }
      );
       
      console.log(response.data,'response.data')
      return response.data;

    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        console.log(message,'mess123')
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

//logout thunk

export const logout = createAsyncThunk(
  "student/logout",
  async (token, thunkApi) => {
    try {
      const response = await axios.delete(
        `https://fmgpy.znapay.in/sessions`,
        {
          headers: { "Content-Type": "application/json", Authorization: token },
        }
      );
      return response;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
      }
    }
  }
);

export const checkUserSession = createAsyncThunk(
  "student/checkUserSession",
  async (token, thunkApi) => {
    try {
      const response = await axios.get(
        `https://fmgpy.znapay.in/sessions`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const message = error.response.data.message;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

// slicers

export const StudentSlice = createSlice({
  name: "student",
  initialState,

  reducers: {
    resetOtpSentStatus(state) {
      state.auth.isOTPSent = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(studentLogin.pending, (state) => {
      state.auth.isOTPSending = true;
    });

    builder.addCase(studentLogin.fulfilled, (state, action) => {
      state.auth.isOTPSending = false;
      state.auth.isOTPSent = true;
    });

    builder.addCase(studentLogin.rejected, (state) => {
      state.auth.isOTPSent = false;
    });

    builder.addCase(validateOTP.pending, (state) => {
      state.auth.isOTPVerifying = true;
    });

    builder.addCase(validateOTP.fulfilled, (state, action) => {
      state.auth.isOTPVerifying = false;
      state.auth.isOTPVerified = true;
      state.auth.otpValidatedToken = action.payload;
    });

    builder.addCase(validateOTP.rejected, (state) => {
      state.auth.isOTPVerifying = false;
    });

    builder.addCase(logout.pending, (state) => {
      state.logout.loading = true;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      state.logout.loading = false;
      if (action.payload.status === 200) {
        state.logout.isLoggedOut = true;
      }
    });

    builder.addCase(logout.rejected, (state, action) => {
      state.logout.loading = false;
    });

    builder.addCase(checkUserSession.pending, (state) => {
      state.checkUserSession.loading = true;
    });

    builder.addCase(checkUserSession.fulfilled, (state, action) => {
      state.checkUserSession.loading = false;
      state.checkUserSession.sessionData = action.payload;
      state.checkUserSession.sessionChecked = true;
    });

    builder.addCase(checkUserSession.rejected, (state, action) => {
      state.checkUserSession.loading = false;
      state.checkUserSession.error = action.payload;
    });
  },
});

// export reducer actions for components

export const { resetOtpSentStatus } = StudentSlice.actions;

export default StudentSlice.reducer;
