import axios, { isAxiosError } from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// Initial state values of student slicers
let initialState = {

    bankerLogin: {
        loading: false,
        isLoggedIn: false,
        error: null,
    },

    notifier: {
        loading:false,
        isNotified: false,
        error: null
    },

    logout: {
        loading: false,
        isLoggedOut: false,
        error: null,
    },

};

//banker login thunk

export const bankerLogin = createAsyncThunk(
    "banker/login",
    async (data, thunkApi) => {
        console.log(data,'data')
        try {
            const response = await axios.post(
                `https://fmgpy.znapay.in/login`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log(response.data)
            response.data && localStorage.setItem("bankerId", response.data.id)
            response.data && localStorage.setItem("token", response.data.access_token);
            response.data && localStorage.setItem("role", response.data.role);

            return response.data;

        }catch (error) {

            if (error) {

                const message = error.response.data.message;

                return thunkApi.rejectWithValue(message);
            }
        }
    }
);


//banker notifier
export const bankerNotifier = createAsyncThunk(
    "banker/notifier",
    async (data, thunkApi) => {
        console.log(data,'data')
        try {
            const response = await axios.post(
                `https://fmgpy.znapay.in/add/remainder`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log(response.data)
            return response.data;

        } catch (error) {
            if (isAxiosError(error) && error.response) {
                const message = error.response.data.message;
                return thunkApi.rejectWithValue(message);
            }
        }
    }
)


export const updateNotifier = createAsyncThunk(
    "banker/updateNotifier",
    async (data, thunkApi) => {
        console.log(data,'data')
        try {
            const response = await axios.post(
                `https://fmgpy.znapay.in/add/remainder`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log(response.data)
            return response.data;

        } catch (error) {
            if (isAxiosError(error) && error.response) {
                const message = error.response.data.message;
                return thunkApi.rejectWithValue(message);
            }
        }
    }
)

//logout thunk

export const logout = createAsyncThunk(
    "banker/logout",
    async (token, thunkApi) => {
        try {
            const response = await axios.delete(
                `https://fmgpy.znapay.in/sessions`,
                {
                    headers: { "Content-Type": "application/json", Authorization: token },
                }
            );
            return response;
        } catch (error) {

            console.log(error)
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 401) {
               
                    console.log("unauthorised ....")
                }
            }
        }
    }
);



// slicers

export const BankerSlice = createSlice({
    name: "banker",
    initialState,

    reducers: {

        resetIsNotified(state) {
            state.notifier.isNotified = false;
        },

        resetError(state) {
            state.bankerLogin.error = null
        }
    },
    extraReducers: (builder) => {

        builder.addCase(bankerLogin.pending, (state) => {
            state.bankerLogin.loading  = true;
        });

        builder.addCase(bankerLogin.fulfilled, (state, action) => {
            state.bankerLogin.loading = false;
            state.bankerLogin.isLoggedIn = true;
        });

        builder.addCase(bankerLogin.rejected, (state, action) => {
            console.log('error', action.payload)
            state.bankerLogin.isLoggedIn = false;
            state.bankerLogin.loading = false;
            state.bankerLogin.error = action.payload
        });



        builder.addCase(logout.pending, (state) => {
            state.logout.loading = true;
        });

        builder.addCase(logout.fulfilled, (state, action) => {
            state.logout.loading = false;
            if (action.payload.status === 200) {
                state.logout.isLoggedOut = true;
            }
        });

        builder.addCase(logout.rejected, (state, action) => {
            state.logout.loading = false;
        });

        builder.addCase(bankerNotifier.pending, (state) => {
            state.notifier.loading = true;
        });

        builder.addCase(bankerNotifier.fulfilled, (state, action) => {

            state.notifier.loading = false;

            state.notifier.isNotified = true;
        });

        builder.addCase(bankerNotifier.rejected, (state, action) => {
            state.notifier.loading = false;
        });

    },
});

// export reducer actions for components
export const { resetIsNotified, resetError } = BankerSlice.actions;

export default BankerSlice.reducer;
