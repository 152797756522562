export const countryData = [
    {
        "name": "Afghanistan"
    },
    {
        "name": "Albania"
    },
    {
        "name": "Andorra"
    },
    {
        "name": "Argentina"
    },
    {
        "name": "Armenia"
    },
    {
        "name": "Australia"
    },
    {
        "name": "Austria"
    },
    {
        "name": "Azerbaijan"
    },
    {
        "name": "Bahrain"
    },
    {
        "name": "Bangladesh"
    },
    {
        "name": "Belarus"
    },
    {
        "name": "Belgium"
    },
    {
        "name": "Bermuda"
    },
    {
        "name": "Bhutan"
    },
    {
        "name": "Bosnia and Herzegovina"
    },
    {
        "name": "Brunei"
    },
    {
        "name": "Bulgaria"
    },
    {
        "name": "Cambodia"
    },
    {
        "name": "Canada"
    },
    {
        "name": "Chile"
    },
    {
        "name": "China"
    },
    {
        "name": "Costa Rica"
    },
    {
        "name": "Croatia"
    },
    {
        "name": "Cyprus"
    },
    {
        "name": "Czech Republic"
    },
    {
        "name": "Czechia"
    },
    {
        "name": "Denmark"
    },
    {
        "name": "Dubai"
    },
    {
        "name": "East Timor"
    },
    {
        "name": "Ecuador"
    },
    {
        "name": "Egypt"
    },
    {
        "name": "England"
    },
    {
        "name": "Estonia"
    },
    {
        "name": "Europe"
    },
    {
        "name": "Faroe Islands"
    },
    {
        "name": "Finland"
    },
    {
        "name": "France"
    },
    {
        "name": "Geneva"
    },
    {
        "name": "Georgia"
    },
    {
        "name": "Germany"
    },
    {
        "name": "Greece"
    },
    {
        "name": "Greenland"
    },
    {
        "name": "Guyana"
    },
    {
        "name": "Hong Kong"
    },
    {
        "name": "Hungary"
    },
    {
        "name": "Iceland"
    },
    {
        "name": "Indonesia"
    },
    {
        "name": "Iran"
    },
    {
        "name": "Iraq"
    },
    {
        "name": "Ireland"
    },
    {
        "name": "Israel"
    },
    {
        "name": "Italy"
    },
    {
        "name": "Japan"
    },
    {
        "name": "Jordan"
    },
    {
        "name": "Kazakhstan"
    },
    {
        "name": "Kuwait"
    },
    {
        "name": "Kyrgyzstan"
    },
    {
        "name": "Laos"
    },
    {
        "name": "Latvia"
    },
    {
        "name": "Lebanon"
    },
    {
        "name": "Liechtenstein"
    },
    {
        "name": "Lithuania"
    },
    {
        "name": "London"
    },
    {
        "name": "Macau"
    },
    {
        "name": "Malaysia"
    },
    {
        "name": "Maldives"
    },
    {
        "name": "Malta"
    },
    {
        "name": "Marbella"
    },
    {
        "name": "Mauritius"
    },
    {
        "name": "Mexico"
    },
    {
        "name": "Moldova"
    },
    {
        "name": "Mongolia"
    },
    {
        "name": "Montenegro"
    },
    {
        "name": "Myanmar"
    },
    {
        "name": "Nepal"
    },
    {
        "name": "Netherlands"
    },
    {
        "name": "New Zealand"
    },
    {
        "name": "North Macedonia"
    },
    {
        "name": "Norway"
    },
    {
        "name": "Oatar"
    },
    {
        "name": "Oman"
    },
    {
        "name": "Pakistan"
    },
    {
        "name": "Palestine"
    },
    {
        "name": "Pennsylvaniaa"
    },
    {
        "name": "Peru"
    },
    {
        "name": "Philippines"
    },
    {
        "name": "Poland"
    },
    {
        "name": "Portugal"
    },
    {
        "name": "Qatar"
    },
    {
        "name": "Romania"
    },
    {
        "name": "Russia"
    },
    {
        "name": "San Marino"
    },
    {
        "name": "Saudi Arabia"
    },
    {
        "name": "Scotland"
    },
    {
        "name": "Serbia"
    },
    {
        "name": "Singapore"
    },
    {
        "name": "Slovakia"
    },
    {
        "name": "Slovenia"
    },
    {
        "name": "South Africa"
    },
    {
        "name": "South Korea"
    },
    {
        "name": "Spain"
    },
    {
        "name": "Sri Lanka"
    },
    {
        "name": "Sweden"
    },
    {
        "name": "Switzerland"
    },
    {
        "name": "Syria"
    },
    {
        "name": "Tablisi"
    },
    {
        "name": "Taiwan"
    },
    {
        "name": "Tajikistan"
    },
    {
        "name": "Tanzania"
    },
    {
        "name": "Thailand"
    },
    {
        "name": "Turkey"
    },
    {
        "name": "Tyne"
    },
    {
        "name": "Ukraine"
    },
    {
        "name": "United Arab Emirates"
    },
    {
        "name": "United Kingdom"
    },
    {
        "name": "United States of America"
    },
    {
        "name": "Uruguay"
    },
    {
        "name": "Uzbekistan"
    },
    {
        "name": "Venezuela"
    },
    {
        "name": "Vietnam"
    },
    {
        "name": "West Indies"
    },
    {
        "name": "Yemen"
    }
]