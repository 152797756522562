import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { getUniversity , getOffers} from  "../../../../store/slice/CommonSlice";


const University = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { getUniversityData, loading } = useSelector((state) => state.common.getUniversity);

    const { isOffers } =  useSelector((state) => state.common.getOffers);

    const [selectedUniversity, setSelectedUniversity] = useState("");
    
    const [isSubmitted, setIsSubmitted] = useState(false);



    useEffect(() => {
        dispatch(getUniversity())
    },[dispatch])

    const handleDataSubmit = () => {
        selectedUniversity && dispatch(getOffers(selectedUniversity))
        setIsSubmitted(true);
    };

    useEffect(() =>{
         
        isOffers &&  navigate("/offers")
         
    },[isOffers, navigate])

    const isDisabled = !selectedUniversity




    const handleSelectedUniversity = (e) => {
        setSelectedUniversity(e.value);
    };

    return (
        <div className=" w-screen h-screen bg-light-color relative flex items-center justify-center overflow-hidden">
        <img
          style={{ zIndex: 99 }}
          className="desktop:absolute mobile:hidden tablet:hidden -bottom-[15%] -right-[40%]"
          src="/bg1.svg"
          alt="Background"
        />
        <div
          style={{ zIndex: 100 }}
          className="desktop:min-w-[996px] mobile:h-[450px] desktop:max-w-[996px] tablet:w-9/12 mobile:w-11/12 desktop:w-custom996 desktop:h-4/5 tablet:max-h-[700px] shadow-2xl bg-white rounded-2xl flex"
        >
          <div className="desktop:relative overflow-hidden w-2/5 tablet:hidden mobile:hidden bg-primary-color h-full rounded-l-lg">
            <h1 className="font-gola text-2xl font-bold text-white desktop:pl-10 pt-10 leading-snug mr-14">
              Welcome to fundmygrad student portal
            </h1>
            <p className="opacity-100 font-gola text-base text-white pl-10 mt-6 mr-14 font-normal">
              we turn the gruelling loan process on it's head by letting the
              banks come to you rather than you going around to each and every
              bank to check for details
            </p>
            <img
              className="desktop:absolute top-2 -right-0 opacity-60"
              src="/bg4.svg"
              alt="Background 2"
            />
            <img
              className="desktop:absolute -bottom-[5%] -left-[35%]"
              src="/bg3.svg"
              alt="Background 2"
            />
            <img
              className="desktop:absolute bottom-10 right-10"
              src="/bg2.svg"
              alt="Background 2"
            />
          </div>
          <div className="desktop:w-3/5 tablet:w-full mobile:w-full desktop:pl-pad74 desktop:pr-pad74 flex flex-col desktop:justify-center tablet:justify-start">
            <div className="rounded-t-lg tablet:mb-5 mobile:mb-5 tablet:h-[210px] mobile:pl-6 mobile:pr-6 tablet:pl-pad74 tablet:pr-pad74 bg-primary-color desktop:hidden">
              <h1 className="font-gola text-2xl tablet:pt-5  mobile:pt-5 mobile:mb-8 font-bold text-white leading-snug">
                Welcome To Fund My Grad
              </h1>
               
            </div>
            <div className="w-full">
                <h1 className="text-2xl font-gola font-semibold text-button-text">
                    University Details
                </h1>
                <p className="text-sm font-gola font-normal opacity-50 mt-2">
                    This will help us tailor fundmygrad for you
                </p>

                <div>
                    <p className="text-sm	font-normal text-subtext opacity-70 mt-3">
                        Select University
                    </p>

                    <div style={{ height: "42px" }} className="flex mt-1.5">
                        <Select
                            onChange={handleSelectedUniversity}
                            className="basic-multi-select"
                            classNamePrefix={
                                isSubmitted && !selectedUniversity
                                    ? "error-custom_select custom_select"
                                    : "custom_select"
                            }
                            name="University"
                            options={getUniversityData.map((university) => ({
                                value: university.id,
                                label: university.university_name,
                            }))}
                        />
                    </div>
                </div>






                <div className="w-full flex justify-between">

                    <button
                        disabled={isSubmitted && isDisabled}
                        onClick={handleDataSubmit}
                        type="button"
                        className={`h-10 max-w-custom120 mt-6 w-full font-gola text-white ${isSubmitted && isDisabled
                            ? "bg-slate-500 cursor-not-allowed"
                            : "bg-primary-color"
                            } font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none`}
                    >
                        {loading ? (
                            <div className="flex justify-center w-full" role="status">
                                <svg
                                    aria-hidden="true"
                                    className="w-6 h-6 text-gray-200 animate-spin fill-white"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                            </div>
                        ) : (
                            "Get Offers"
                        )}
                    </button>
                </div>
            </div>
          </div>
        </div>
      </div>
         
    );
};

export default University;
