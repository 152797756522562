const dateTimeConverter = (dateTime) => {

    const gmtTimestamp = new Date(dateTime);

    // Convert GMT to IST (UTC+5:30)
    gmtTimestamp.setHours(gmtTimestamp.getHours() + 5);
    gmtTimestamp.setMinutes(gmtTimestamp.getMinutes() + 30);

    // Format the date in dd/mm/yy HH:MM:SS format
    const istDate = gmtTimestamp.toLocaleString("en-IN", {

        day: "2-digit",
        month: "short",
        year: "2-digit",

    });

    return istDate
}

export default dateTimeConverter