import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";

import { useGetToken } from "../../hooks/useGetToken";

import useScreens from "../../hooks/useScreens";
import { resetUpdateUserProfileStatus, updateUserProfile, GetUserProfile, verifyUserEmail } from "../../../store/slice/ProfileSlice";



const SetupProfile = () => {
    const { isMobile } = useScreens();

    // router navigations
    const navigate = useNavigate();
     

    const { studentId  } = useGetToken();

    //alert
    const toast = useToast();

    const [token, setToken] = useState("")

    // event dispatcher for modifying state
    const dispatch = useDispatch();

    //get state value
    const { loading, isProfileUpdated} = useSelector((state) => state.profile.updateUserProfile);
    const { isEmailVerified } = useSelector((state) => state.profile.verifyUserEmail)
     
     
    //initial profile data
    const [profileDetails, setProfileDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password:""

    });

     
    // update profile data
    const handleProfileUpdate = () => {
         
        
        if (studentId) {
            dispatch(
                updateUserProfile({
                    first_name: profileDetails.first_name,
                    last_name: profileDetails.last_name,
                    email: profileDetails.email,
                    password:profileDetails.password,
                    id: studentId
                })
            );
        }
    };

     

     
    // successfull completion of profile update
    useEffect(() => {
        if (isProfileUpdated) {

            navigate("/select-university");

            toast({
                title: "Profile Updated Successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });     
        }
    }, [isProfileUpdated, navigate, toast]);

    return (
        <div className="w-full">

            <div className=" w-screen h-screen bg-light-color relative flex items-center justify-center overflow-hidden">
                <img
                    style={{ zIndex: 99 }}
                    className="desktop:absolute mobile:hidden tablet:hidden -bottom-[15%] -right-[40%]"
                    src="/bg1.svg"
                    alt="Background"
                />
                <div
                    style={{ zIndex: 100 }}
                    className="desktop:min-w-[996px]  desktop:max-w-[996px]  tablet:w-9/12 mobile:w-11/12 desktop:h-4/5 tablet:max-h-[700px] shadow-2xl bg-white rounded-2xl flex"
                >
                    <div className="desktop:relative overflow-hidden w-2/5 tablet:hidden mobile:hidden bg-primary-color h-full rounded-l-lg">
                        <h1 className="font-gola text-2xl font-bold text-white desktop:pl-10 pt-10 leading-snug mr-14">
                            Welcome to fund my grad student portal
                        </h1>
                        <p className="opacity-100 font-gola text-base text-white pl-10 mt-6 mr-14 font-normal">
                            we turn the gruelling loan process on it's head by letting the
                            banks come to you rather than you going around to each and every
                            bank to check for details
                        </p>
                        <img
                            className="desktop:absolute top-2 -right-0 opacity-60"
                            src="/bg4.svg"
                            alt="Background 2"
                        />
                        <img
                            className="desktop:absolute -bottom-[5%] -left-[35%]"
                            src="/bg3.svg"
                            alt="Background 2"
                        />
                        <img
                            className="desktop:absolute bottom-10 right-10"
                            src="/bg2.svg"
                            alt="Background 2"
                        />
                    </div>
                    <div className="w-full w-3/5 flex justify-center items-center h-full mobile:m-[30px]">
                    
                        <div className="rounded-md justify-center py-[10px] px-10 w-full">
                        <div className="flex justify-center my-5 relative">
                                <p className="text-left w-full font-gola text-lg font-bold">
                                    Setup your profile
                                </p>
                            </div>

                            <div className="flex justify-center flex-col items- start w-full pt-0.8">
                                <p className="font-gola text-[12px] text-[#8B8B8B] font-normal mb-2">
                                    First Name
                                </p>
                                <input
                                    onChange={(e) =>
                                        setProfileDetails({
                                            ...profileDetails,
                                            first_name: e.target.value,
                                        })
                                    }
                                    type="text"
                                    id="website-admin"
                                    className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 "
                                    placeholder="Ram"
                                />
                            </div>
                            <div className="flex justify-center flex-col items-start w-full pt-0.8">
                                <p className="font-gola text-[12px] text-[#8B8B8B] font-normal mb-2">
                                    Last Name
                                </p>
                                <input
                                    onChange={(e) =>
                                        setProfileDetails({
                                            ...profileDetails,
                                            last_name: e.target.value,
                                        })
                                    }
                                    type="text"
                                    id="website-admin"
                                    className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  "
                                    placeholder="Kumar"
                                />
                            </div>
                            <div className="flex justify-center flex-col items-start w-ful pt-0.8">
                                <p className="font-gola text-[12px] text-[#8B8B8B] font-normal mb-2">
                                    Email
                                </p>
                                <input
                                    onChange={(e) =>
                                        setProfileDetails({
                                            ...profileDetails,
                                            email: e.target.value,
                                        })
                                    }
                                    type="text"
                                    id="website-admin"
                                    className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  "
                                    placeholder="ram@gmail.com"
                                />
                            </div>
                            <div className="flex justify-center flex-col items-start w-ful pt-0.8">
                                <p className="font-gola text-[12px] text-[#8B8B8B] font-normal mb-2">
                                    password</p>
                                <input
                                    onChange={(e) =>
                                        setProfileDetails({
                                            ...profileDetails,
                                            password: e.target.value,
                                        })
                                    }
                                    type="password"
                                    id="website-admin"
                                    className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  "
                                    placeholder="Enter Password"
                                />
                            </div>
                            <div className="flex items-center justify-betwee pt-5 mobile:min-w-[200px] mb-5">
                                <button
                                    disabled={
                                        !profileDetails.first_name ||
                                        !profileDetails.last_name ||
                                        !profileDetails.email || 
                                        !profileDetails.password
                                    }
                                    onClick={handleProfileUpdate}
                                    type="button"
                                    className={`h-10 mt-3 w-full font-gola text-white ${!profileDetails.first_name ||
                                            !profileDetails.last_name ||
                                            !profileDetails.email||
                                            !profileDetails.password
                                            ? "bg-slate-500 cursor-not-allowed"
                                            : "bg-primary-color"
                                        } font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none`}
                                >
                                    {loading ? (
                                        <div className="flex justify-center w-full" role="status">
                                            <svg
                                                aria-hidden="true"
                                                className="w-6 h-6 text-gray-200 animate-spin fill-white"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                        </div>
                                    ) : (
                                        "Setup Profile"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetupProfile;
