import { openDB } from 'idb';

// eslint-disable-next-line no-unused-vars
import CryptoJS from 'crypto-js';

const dbName = "fmg"

const storeName = "fmgMessages"


// Message Encryption Hnadler //
const encryptMessages = (data, secretKey) => {

    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();

}

// MessageDecryption Handler //
const decryptMessages = (cipherText, secretKey) => {

    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

}

// DataBase Initializer //
export const initializeDb = async () => {

    const db = await openDB(dbName, 1, {

        upgrade(db) {
           
          db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });

        },

      });

      return db;
}




// Add items to db
export const addItem = async (messages, secretKey) => {

    console.log(messages,'messages123')
    const db = await initializeDb();
    const encryptedItem = encryptMessages(messages, secretKey);
    console.log(encryptedItem, "encryptedItem")
    await db.add(storeName, {encryptedData: encryptedItem });

};

// Get Items From the db

export const getItems = async (secretKey) => {

    const db = await initializeDb();

    const items = await db.getAll(storeName);

    return items.map(item => (
        decryptMessages(item.encryptedData, secretKey)
    ));


};


// Clear All Messages

export const deleteAllMessages = async () => {

    const db = await initializeDb();
    await db.clear(storeName); 
};


// Clear Messages Based On Id


export const deleteMessagesById = async (id) => {

    const db = await initializeDb();
    await db.delete(storeName, id);

};

 
  