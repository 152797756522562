import React from 'react'

const WelcomePage = () => {
    return (

        <div className='student_app_chat_welcome_page'>
            <div className='student_app_chat_welcome_page_logo'>
                <img src='https://fundmygrad.com/new_logo.svg' alt='logo' />
                <h1>Fundmygrad Web Chat</h1>
                <h5>Send and Receive Messages On The Go 🚀🚀</h5>
                
            </div>
        </div>

    )
}

export default WelcomePage
